import React, {useCallback, useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import usePaymentBillingPolicy from '@core/payment/widgets/billingPolicy/utils/usePaymentBillingPolicy';
import {paymentBillingPolicyData} from '@core/payment/widgets/billingPolicy/constants/propTypes';
import NotifierAboutAgreement$ from '@core/payment/widgets/billingPolicy/utils/notifierAboutAgreement';
import scrollIntoView from '@core/utils/scroll/scrollIntoView';
import usePaymentProcessingStatus from '@core/payment/common/utils/usePaymentProcessingStatus';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';
import useWalletAllowedMethods from '@core/payment/common/utils/useWalletAllowedMethods';
import formatLinksAsArray from '@core/payment/widgets/billingPolicy/utils/formatLinksAsArray';
import useSiteName from '@core/application/utils/useSiteName';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {PlaceholderBar} from '@phoenix/ui';

import useAdditionalTerms from '../utils/useAdditionalTerms';
import PaymentCommonPolicyLayout from '../components/PaymentCommonPolicyLayout';
import usePaymentButtonTitle from '../../buttons/utils/usePaymentButtonTitle';
import {
  PaymentBillingPolicyLink,
  PaymentConsentAgreementLink,
  PaymentPrivacyPolicyLink,
  PaymentRefundPolicyLink,
  PaymentTermsOfUseLink,
} from '../components/PaymentBillingPolicyLinks';
import FooterRow from '../../footer/components/FooterRow';

/**
 * @desc Component render additional terms (ex., billing policy, privacy policy etc.)
 */
const PaymentBillingPolicyInner = ({
  data,
  withCheckbox,
  isAdditionalTermsChecked,
  setIsAdditionalTermsChecked,
  billingPolicyDefaultValue,
  paymentButtonTitle: paymentButtonTitleFromProps,
}) => {
  const ref = useRef();
  const [animated, setAnimated] = useState(false);
  const {methods} = useWalletAllowedMethods();
  const {siteName} = useSiteName();
  const paymentButtonTitleFromServer = usePaymentButtonTitle({
    walletMethods: methods,
    skip: Boolean(paymentButtonTitleFromProps),
  });
  const paymentButtonTitle =
    paymentButtonTitleFromProps || paymentButtonTitleFromServer;
  const processingStatus = usePaymentProcessingStatus();

  const handleCheckboxChange = useCallback(
    (_event, _value, isChecked) => {
      setIsAdditionalTermsChecked(isChecked);
      setAnimated(true);
    },
    [setAnimated, setIsAdditionalTermsChecked],
  );

  useEffect(() => {
    setIsAdditionalTermsChecked(billingPolicyDefaultValue);
  }, [billingPolicyDefaultValue, setIsAdditionalTermsChecked]);

  // Reset checkbox default value on decline
  useEffect(() => {
    if (processingStatus === PROCESSING_STATUS.FAILED) {
      setIsAdditionalTermsChecked(billingPolicyDefaultValue);
    }
  }, [
    processingStatus,
    billingPolicyDefaultValue,
    setIsAdditionalTermsChecked,
  ]);

  useEffect(() => {
    const subscription = NotifierAboutAgreement$.subscribe((notified) => {
      setAnimated(notified);

      if (notified) {
        scrollIntoView({currentElem: ref.current});
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const {
    translateMessageEntityMap: {billingPolicyText, billingPolicyLinkText},
    isShowRefundPolicy,
    showPrivacyPolicy,
    showConsentAgreement,
    url,
  } = data;

  const links = formatLinksAsArray({
    billingPolicyText,
    billingPolicyLinkText,
    billingPolicyLink: url,
    isShowRefundPolicy,
    showConsentAgreement,
    showPrivacyPolicy,
    title: paymentButtonTitle,
    siteName,
    paymentBillingPolicyLink: PaymentBillingPolicyLink,
    paymentPrivacyPolicyLink: PaymentPrivacyPolicyLink,
    paymentRefundPolicyLink: PaymentRefundPolicyLink,
    paymentTermsOfUseLink: PaymentTermsOfUseLink,
    paymentConsentAgreementLink: PaymentConsentAgreementLink,
  });

  return (
    <div ref={ref}>
      <PaymentCommonPolicyLayout
        animated={animated}
        checked={isAdditionalTermsChecked}
        onCheckboxChange={handleCheckboxChange}
        withCheckbox={withCheckbox}
        data-test="paymentBillingPolicy"
      >
        {links}
      </PaymentCommonPolicyLayout>
    </div>
  );
};

PaymentBillingPolicyInner.propTypes /* remove-proptypes */ = {
  ...paymentBillingPolicyData,
};

const PaymentBillingPolicy = ({paymentButtonTitle}) => {
  const {data, loading, error} = usePaymentBillingPolicy();
  const {
    withCheckbox,
    isAdditionalTermsChecked,
    setIsAdditionalTermsChecked,
    billingPolicyDefaultValue,
    loading: additionalTermsLoading,
    error: additionalTermsError,
  } = useAdditionalTerms();

  if (loading || additionalTermsLoading) {
    return (
      <FooterRow>
        <PlaceholderBar />
      </FooterRow>
    );
  }

  if (error || additionalTermsError) {
    return (
      <FooterRow>
        <ErrorBoundary
          errorSize={ErrorBoundary.SIZE.SMALL}
          error={error || additionalTermsError}
        />
      </FooterRow>
    );
  }

  if (!data) return null;

  return (
    <FooterRow>
      <PaymentBillingPolicyInner
        data={data}
        withCheckbox={withCheckbox}
        isAdditionalTermsChecked={isAdditionalTermsChecked}
        setIsAdditionalTermsChecked={setIsAdditionalTermsChecked}
        billingPolicyDefaultValue={billingPolicyDefaultValue}
        paymentButtonTitle={paymentButtonTitle}
      />
    </FooterRow>
  );
};

PaymentBillingPolicy.propTypes /* remove-proptypes */ = {
  paymentButtonTitle: PropTypes.string,
};

export default PaymentBillingPolicy;
