import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {TextMuted} from '@phoenix/typography';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {Button} from '@phoenix/ui';
import {ButtonType} from '@phoenix/ui/constants';

// TODO remove if will need to get discount from API
const DISCOUNT = 90;

const LifetimeOfferPopup = ({onClick}) => {
  return (
    <PopupNotice
      icon="alert"
      title={t('lifetimeOfferPopup', 'title.problem_with_payment')}
      description={
        <TextMuted>
          {t('lifetimeOfferPopup', 'text.get_our_best_discount')}
        </TextMuted>
      }
      actions={[
        <Button
          onClick={onClick}
          trackingName="getDiscount"
          inline
          fullWidth
          type={ButtonType.PRIMARY}
          data-test="lifetimeOfferGetDiscountLink"
        >
          {t('lifetimeOfferPopup', 'button.get_discount', {
            '{discount}': DISCOUNT,
          })}
        </Button>,
      ]}
    />
  );
};

LifetimeOfferPopup.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
};

export default LifetimeOfferPopup;
