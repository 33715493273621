import PersonalIdentityService from '../PersonalIdentityService';
import changePersonalIdentitySubscription from './changePersonalIdentitySubscription';
import setPopupLaterByAccountStatusChangeSubscription from './setPopupLaterByAccountStatusChangeSubscription';
import setPopupLaterByRouteChangeSubscription from './setPopupLaterByRouteChangeSubscription';

let subscription;

/** Starts subscriptions for personalIdentity data */
const startPersonalIdentityListeners = async (client) => {
  if (subscription) return;
  subscription = true;
  changePersonalIdentitySubscription({
    client,
    onPersonalIdentityChanged: PersonalIdentityService.onStatusChange,
  });
  setPopupLaterByAccountStatusChangeSubscription(client);
  setPopupLaterByRouteChangeSubscription(client);
};

export default startPersonalIdentityListeners;
