import type {FC} from 'react';
import React from 'react';

import {PlaceholderBlock, Row} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';

import css from '../styles/PhotosListAsRoulette.css';

const PhotosListAsRoulettePlaceholder: FC = () => (
  <Row className={css.avatars} align={Align.CENTER}>
    <div className={css.photo}>
      <PlaceholderBlock square rounded />
    </div>
    <div className={css.photo}>
      <PlaceholderBlock square rounded />
    </div>
    <div className={css.photo}>
      <PlaceholderBlock square rounded />
    </div>
  </Row>
);

export default PhotosListAsRoulettePlaceholder;
