import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Link} from '@phoenix/typography';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

const handleOpenFooterDNSMPI = (e) => {
  StaticPagePopupHelper.openPopup(StaticPagePopupHelper.PAGES.DNSMPI, e);
};

const FooterDNSMPI = ({text, ...props}) => (
  /* Add empty div wrapper for removing block style from link and prevent adding two footer links in one row */
  <div>
    <Link
      small
      muted
      underline
      data-bypass=""
      data-test="footerDNSMPI"
      trackingName="dnsmpi"
      onClick={handleOpenFooterDNSMPI}
      {...props}
    >
      {text || t('popupStaticPage', 'title.DNSMPI')}
    </Link>
  </div>
);

FooterDNSMPI.propTypes /* remove-proptypes */ = {
  text: PropTypes.string,
};

export default FooterDNSMPI;
