import React from 'react';
import PropTypes from 'prop-types';

import {Bar, Spacing} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';

import CoregistrationPopupFooter from '../containers/CoregistrationPopupFooter';
import css from '../styles/CoregistrationPopupFooterLayout.css';

const CoregistrationPopupFooterLayout = ({footerText}) => (
  <Bar withoutTop>
    <Spacing>
      <TextMuted className={css.text} align={Align.CENTER} small>
        <CoregistrationPopupFooter text={footerText} />
      </TextMuted>
    </Spacing>
  </Bar>
);

CoregistrationPopupFooterLayout.propTypes /* remove-proptypes */ = {
  footerText: PropTypes.string.isRequired,
};

export default CoregistrationPopupFooterLayout;
