import React from 'react';
import get from 'lodash/get';

import logger from '@core/logger';
import getHistory from '@core/application/utils/getHistory';
import {getClientInstance} from '@core/graphql/client';
import PopupPriority from '@core/popup/constants/PopupPriority';
import {readFromLocalStorage, writeToLocalStorage} from '@core/utils/storage';
import isPayUrl from '@core/utils/url/isPayUrl';
import PopupService from '@core/popup/utils/PopupService';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import isInRouteList from '@core/utils/routing/isInRouteList';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';
import getDefaultPage from '@core/utils/routing/getDefaultPage';

import PERSONAL_IDENTITY_QUERY from './graphql/queries/personalIdentity.gql';
import PersonalIdentitySuccessPopup from './containers/PersonalIdentitySuccessPopup';
import PersonalIdentityThanksPopup from './containers/PersonalIdentityThanksPopup';
import PersonalIdentityFailPopup from './containers/PersonalIdentityFailPopup';
import VerificationStatus from './constants/verificationStatus';
import {
  NOT_EXIST,
  APPROVED as DOCUMENT_APPROVED,
  DISAPPROVED as DOCUMENT_DISAPPROVED,
  PENDING_TO_APPROVE,
} from './constants/documentStatus';
import {PERSONAL_IDENTITY_POPUP} from './constants/storageKey';
import {PERSONAL_IDENTITY_PAGE} from './constants/route';
import isVerificationRequired from './utils/isVerificationRequired';

let instance;

/**
 * @param {string} message
 */
const logError = (message) => {
  logger.sendWarning(`[PersonalIdentityService] Wrong data ${message}`);
};

/**
 * @class PersonalIdentityService
 * @description PersonalIdentityService provides:
 *              1) read and write client cache related to personalIdentity functionality
 *              2) show popups
 */
class PersonalIdentityService {
  /**
   * @public
   * @param {boolean} forceServerRequest
   * @return {Promise}
   */
  async getData(forceServerRequest = false) {
    try {
      return getClientInstance().query({
        query: PERSONAL_IDENTITY_QUERY,
        fetchPolicy: forceServerRequest ? 'network-only' : 'cache-first',
      });
    } catch (e) {
      logError(e.message);
      return Promise.resolve({});
    }
  }

  /**
   * @description Set flag to storage that need show popup later,
   *              e.x., on payment route we can't show popup that why
   *              we put in storage and when we leave payment page - show popup
   * @param {boolean} remove - remove from storage
   * @public
   */
  setViewPopupLaterInStorage(remove = false) {
    writeToLocalStorage(PERSONAL_IDENTITY_POPUP, remove ? null : true);
  }

  /**
   * Get flag from storage about popup that need show later
   * @return {boolean || null}
   * @public
   */
  getViewPopupLaterFromStorage() {
    return readFromLocalStorage(PERSONAL_IDENTITY_POPUP);
  }

  /**
   * @private
   * @return {boolean}
   */
  allowedPopup() {
    return this.readData(
      'userFeatures.personalIdentity.isNeedToShowPopup',
      false,
    );
  }

  /**
   * @public
   * @return {boolean}
   */
  isApprovedStatus() {
    return VerificationStatus.APPROVED === this.status;
  }

  /**
   * @public
   * @return {boolean}
   */
  isDisapprovedStatus() {
    return VerificationStatus.DISAPPROVED === this.status;
  }

  /**
   * @public
   * @return {boolean}
   */
  isVerificationRequired() {
    return isVerificationRequired(this.required, this.status);
  }

  /**
   * @public
   * @return {boolean}
   */
  showUploadDocumentButton() {
    return (
      this.isVerificationRequired() &&
      [NOT_EXIST, DOCUMENT_DISAPPROVED].includes(this.documentStatus)
    );
  }

  /**
   * @public
   * @return {number}
   */
  get status() {
    return this.readData(
      'userFeatures.personalIdentity.verificationStatus',
      VerificationStatus.UNAVAILABLE,
    );
  }

  /**
   * @public
   * @return {string}
   */
  get paymentVia() {
    return this.readData('userFeatures.personalIdentity.paymentVia', '');
  }

  /**
   * @public
   * @return {string}
   */
  get documentStatus() {
    return this.readData(
      'userFeatures.personalIdentity.documentStatus',
      NOT_EXIST,
    );
  }

  /**
   * @public
   * @return {boolean}
   */
  get firstDayLogic() {
    return this.readData(
      'userFeatures.personalIdentity.firstDayLogicFlag',
      false,
    );
  }

  /**
   * @public
   * @return {boolean}
   */
  get improvesAllowed() {
    return this.readData(
      'userFeatures.personalIdentity.improvesAllowed',
      false,
    );
  }

  /**
   * @public
   * @return {boolean}
   */
  get required() {
    return this.readData('userFeatures.personalIdentity.required', false);
  }

  /**
   * Read data from cache
   * @private
   * @param {string} resolvePath - path to read exact property
   * @param {any} defaultValue - fallback value if no find property
   * @return {any}
   */
  readData(resolvePath, defaultValue = null) {
    const data = getClientInstance().readQuery({
      query: PERSONAL_IDENTITY_QUERY,
    });
    return get(data, resolvePath, defaultValue);
  }

  /**
   * Write data to cache
   * @private
   * @param {object} data
   */
  writeQuery(data) {
    try {
      getClientInstance().writeQuery({
        query: PERSONAL_IDENTITY_QUERY,
        data: {...data},
      });
    } catch (e) {
      logError(e.message);
    }
  }

  /**
   * @public
   */
  async resetStatus() {
    const {data} = await this.getData();
    const newData = {
      userFeatures: {
        personalIdentity: {
          ...data.userFeatures.personalIdentity,
          documentStatus: PENDING_TO_APPROVE,
          verificationStatus: VerificationStatus.UNAVAILABLE,
        },
        __typename: 'UserFeatures',
      },
    };

    this.writeQuery(newData);
  }

  /**
   * @param {String} documentStatus
   */
  async resetPropertiesAfterShowPopupByStatus(documentStatus) {
    const {data} = await this.getData();
    const newData = {
      userFeatures: {
        personalIdentity: {
          ...data.userFeatures.personalIdentity,
          documentStatus,
          isNeedToShowPopup: 0,
        },
        __typename: 'UserFeatures',
      },
    };

    this.writeQuery(newData);
  }

  /**
   * @public
   */
  onStatusChange = () => {
    if (this.isApprovedStatus() || this.isDisapprovedStatus()) {
      this.showPopupByApproveStatus();
    }
  };

  /**
   * @public
   * @return {boolean}
   */
  showPopupByApproveStatus() {
    if (this.isApprovedStatus()) {
      this.showSuccessPopup();
      return true;
    }

    if (this.isDisapprovedStatus()) {
      this.showFailPopup();
      return true;
    }

    return false;
  }

  /**
   * @public
   */
  showSuccessPopup() {
    PopupService.openPopup(
      <PersonalIdentitySuccessPopup
        onReset={() => {
          this.resetPropertiesAfterShowPopupByStatus(DOCUMENT_APPROVED);
        }}
      />,
      {
        priority: PopupPriority.LOW,
        trackingName: 'personalIdentitySuccess',
        small: true,
        // Success popup should display anyway until the user closes it.
        closeByNavigation: false,
      },
    );
  }

  /**
   * @public
   */
  showFailPopup() {
    PopupService.openPopup(
      <PersonalIdentityFailPopup
        onReset={() => {
          this.resetPropertiesAfterShowPopupByStatus(DOCUMENT_DISAPPROVED);
        }}
      />,
      {
        onClose: () => getHistory().push(PERSONAL_IDENTITY_PAGE),
        small: true,
        trackingName: 'personalIdentityFail',
        priority: PopupPriority.LOW,
      },
    );
  }

  /**
   * @public
   */
  showPopupIfAllowed() {
    if (!this.allowedPopup()) return;
    if (
      !isPayUrl(window.location.pathname) &&
      !isInRouteList(ROUTES_WITH_POPUP, window.location.pathname)
    ) {
      this.showPopupByApproveStatus();
      this.setViewPopupLaterInStorage(true);
      return;
    }

    this.setViewPopupLaterInStorage();
  }

  /**
   * @public
   */
  async showThanksPopup() {
    const url = await getDefaultPage();
    const {
      data: {
        userFeatures: {
          personalIdentity: {improvesAllowed, paymentVia},
        },
      },
      error,
    } = await getClientInstance().query({
      query: PERSONAL_IDENTITY_QUERY,
    });

    const withCreditCard = improvesAllowed && paymentVia;

    PopupService.openPopup(
      <PersonalIdentityThanksPopup
        withCreditCard={withCreditCard}
        paymentVia={paymentVia}
        verifyError={error}
      />,
      {
        trackingName: `PersonalIdentityThanks_${
          withCreditCard ? 'withCreditCard' : ''
        }`,
        small: true,
        priority: PopupPriority.LOW,
        onClose: () => getHistory().push(url),
      },
    );

    // Show header notification after PersonalIdentityThanksPopup
    if (this.isApprovedStatus()) {
      NotificationsService.addNotification({
        type: NotificationsService.TYPES.PERSONAL_IDENTITY,
      });
    }
  }
}

const getInstance = () => {
  if (!instance) instance = new PersonalIdentityService();
  return instance;
};

export default getInstance();
