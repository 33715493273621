import {useQuery} from '@apollo/client';

import MICROFEATURES_AVAILABILITY_DATA_QUERY from '../graphql/queries/microfeaturesAvailabilityData.gql';

/**
 * @param {Object} data
 * @param {boolean} data.skip
 * @return {Object}
 */
const useMicrofeaturesAvailabilityData = ({skip} = {}) => {
  const {loading, error, data} = useQuery(
    MICROFEATURES_AVAILABILITY_DATA_QUERY,
    {
      skip,
    },
  );

  return {
    loading,
    error,
    modelType: data?.microFeatures?.microFeaturesConfiguration?.modelType,
    isUseAlternativeTitles:
      data?.microFeatures?.microFeaturesConfiguration?.isUseAlternativeTitles,
    placements: data?.microFeatures?.placements,
  };
};

export default useMicrofeaturesAvailabilityData;
