import type {FC} from 'react';

import useThemeCompositeFeatureLoader from '../utils/useThemeCompositeFeatureLoader';
import {resolver as chatPageResolver} from './ThemedChatPage';
import {resolver as recipientsPageResolver} from './ThemedRecipientsPage';
import useThemedRecipientWidget from '../utils/useThemedRecipientWidget';
import useThemedChatMessage from '../utils/useThemedChatMessage';

/**
 * Component for preload themed components for mob
 */
const PreloadThemedCompactComponentsPage: FC = () => {
  // only for /chat/with/id
  useThemeCompositeFeatureLoader('chat', 'page', chatPageResolver);

  // only for /chat/recipients
  useThemeCompositeFeatureLoader(
    'messenger',
    'recipients',
    recipientsPageResolver,
  );

  // for /chat/with/id and /chat/recipients
  useThemedRecipientWidget();
  // for /chat/with/id
  useThemedChatMessage();

  return null;
};

export default PreloadThemedCompactComponentsPage;
