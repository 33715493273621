import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {BannedRule, AuthenticatedRule} from '@core/application/accessRules';
import {RedirectToDefaultPage} from '@core/utils/routing';
import isEnabledReactPaymentPage from '@core/payment/common/utils/isEnabledReactPaymentPage';
import BASE_ROUTES from '@core/application/constants/routes';
import {HashedStaticPageRoute} from '@core/application/routes';
import ROUTES_WITH_POPUP, {
  FUNNEL,
} from '@core/application/constants/routesWithPopup';

import {Loader} from '@phoenix/ui';
import BrowserInfoChunk from '@phoenix/browserInfo/BrowserInfoChunk';
import NotFound from '@phoenix/exceptions/components/NotFound';
import SepaDebtorInvoicePage from '@phoenix/sepaDebtor/containers/SepaDebtorInvoicePage';

import ACLRoute from './ACLRoute';
import StaticPageToHash from './StaticPageToHash';
import {
  PersonalIdentityNotVerifiedRule,
  PersonalIdentityVerifiedRule,
  WorldWideSearchRule,
  RoomsAvailableRule,
  ModeratorPageRule,
  PmaServiceRule,
  CancelBillingPageBeforeRemoveRule,
} from '../accessRules';
import {
  AccountChunk,
  CancelBillingChunk,
  RemoveAccountChunk,
  UnsubscribeChunk,
  DiscreetProfileChunk,
  ChatChunk,
  StaticPageChunk,
  FavoritesChunk,
  BrandbookChunk,
  ForbiddenChunk,
  ProfileChunk,
  ProfileMenuChunk,
  NewsFeedChunk,
  MyActivityChunk,
  PasswordRecoveryChunk,
  ContactUsChunk,
  UserChunk,
  MassBlockedChunk,
  PayReactChunk,
  PayCommonChunk,
  BlockedUsersChunk,
  ReportedUsersChunk,
  VideoChatChunk,
  FreeCoinsChunk,
  SearchLivecamChunk,
  LivechatChunk,
  LikeGalleryChunk,
  PersonalIdentityChunk,
  PMAChunk,
  MicroFeaturesChunk,
  PayContractChunk,
  PayCoinsChunk,
  PayVerificationChunk,
  PayAppFunnelNewOfferChunk,
  PayAppFunnelGetTheAppChunk,
  PayAppFunnelChunk,
  RoomsChunk,
  RoomsModeratorChunk,
  TrustedChunk,
  FeedbackChunk,
  PayRemarketingChunk,
  PayTrustPaySepaChunk,
  PaySepaChunk,
  PaySofortChunk,
  PayIdealChunk,
  PayKonbiniChunk,
  PayMessagePageChunk,
  MyStoryChunk,
  StoryChunk,
  WrongDeviceChunk,
  PartnerNetworkChunk,
  PwaNextStepChunk,
  GetTheAppChunk,
  FunnelQuizChunk,
  ReelsChunk,
  WorldWideSearchChunk,
  PayIframePageChunk,
} from '../chunks';
import COMMON_ACL_RULES from '../constants/commonACLRules';
import {EmailUpdateRoute, RetryPermissionRoute} from '../routes';
import ROUTES from '../constants/routes';
import DeferredSearchPage from '../deferredPage/pages/DeferredSearchPage';
import COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS from '../constants/commonACLRulesNoPaymentRestrictions';
import DescriptorChunk from '../chunks/DescriptorChunk';

/**
 * Listing of all routes in terms of application.
 *
 * !!! Attention this file parsed at:
 * @see /scripts/generateRoutesList.js
 * Be carefully if you change file structure
 */
const Routes = () => (
  <Switch>
    <Route exact path={BASE_ROUTES.INDEX} component={RedirectToDefaultPage} />
    <Route path={BASE_ROUTES.FORBIDDEN} component={ForbiddenChunk} />
    {/**
     * Old route redirects (some users with favourites in browser, communications and old mail lists)
     */}
    <Redirect exact from={BASE_ROUTES.MESSENGER} to={BASE_ROUTES.CHAT} />
    <Redirect
      exact
      from={BASE_ROUTES.NEWS_FEED}
      to={BASE_ROUTES.NEWS_FEED_ALL}
    />
    <Redirect
      exact
      from={BASE_ROUTES.CONTACT_US_LEGACY}
      to={BASE_ROUTES.CONTACT_US}
    />
    <Redirect from={ROUTES.NEWS_FEED_VIEW_LEGACY} to={ROUTES.NEWS_FEED_VIEW} />
    <Redirect from={ROUTES.LIKE_GALLERY_LEGACY} to={ROUTES.LIKE_GALLERY} />
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={ROUTES.PAY_TRUST_PAY}
      component={PayTrustPaySepaChunk}
    />
    {/**
     Need remove this route from routing if react payment page is enabled
     Fix for correct redirect between steps on react payment page
     Remarketing payment component declared inside PayReactChunk
     */}
    {!isEnabledReactPaymentPage() && (
      <ACLRoute
        rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
        path={ROUTES.PAY_REMARKETING}
        component={PayRemarketingChunk}
      />
    )}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.PAY_CONTRACT}
      component={PayContractChunk}
    />
    {!isEnabledReactPaymentPage() && (
      <ACLRoute
        rules={COMMON_ACL_RULES}
        path={ROUTES.PAY_COINS}
        component={PayCoinsChunk}
      />
    )}

    {!isEnabledReactPaymentPage() && (
      <ACLRoute
        rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
        path={ROUTES.PAY_VERIFICATION}
        component={PayVerificationChunk}
      />
    )}
    {/**
     * Pay App Funnel routes
     */}
    <ACLRoute
      rules={COMMON_ACL_RULES.filter(
        ({errorCode}) => errorCode !== 'PAID_APP_FUNNEL',
      )}
      path={ROUTES.PAY_APP_FUNNEL_NEW_OFFER}
      component={PayAppFunnelNewOfferChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES.filter(
        ({errorCode}) => errorCode !== 'PAID_APP_FUNNEL',
      )}
      path={ROUTES.PAY_APP_FUNNEL_GET_THE_APP}
      component={PayAppFunnelGetTheAppChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES.filter(
        ({errorCode}) => errorCode !== 'PAID_APP_FUNNEL',
      )}
      path={ROUTES.PAY_APP_FUNNEL}
      component={PayAppFunnelChunk}
    />
    {/**
     * Sepa payment route is used only for mob (for render separate page).
     * On the web sepa payment is made through the route /pay/membership (see PayCommonChunk)
     */}
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={ROUTES.PAY_SEPA}
      component={PaySepaChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={ROUTES.PAY_SOFORT}
      component={PaySofortChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={ROUTES.PAY_IDEAL}
      component={PayIdealChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={ROUTES.PAY_KONBINI}
      component={PayKonbiniChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={ROUTES.PAY_MESSAGE}
      component={PayMessagePageChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES.filter(
        ({errorCode}) => errorCode !== 'PAID_APP_FUNNEL',
      )}
      path={ROUTES.PAY_IFRAME_PP}
      component={PayIframePageChunk}
    />
    <Route
      path={BASE_ROUTES.RETRY_PERMISSION}
      render={(props) => <RetryPermissionRoute loader={Loader} {...props} />}
    />
    {/* Main page flow. See: PayChunk.js for more info */}
    <ACLRoute
      rules={COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS}
      path={BASE_ROUTES.PAY}
      component={isEnabledReactPaymentPage() ? PayReactChunk : PayCommonChunk}
    />
    {/* Profile */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.PROFILE_MENU}
      exact
      component={ProfileMenuChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.PROFILE}
      component={ProfileChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.CANCEL}
      component={CancelBillingChunk}
    />
    <ACLRoute
      rules={[...COMMON_ACL_RULES, CancelBillingPageBeforeRemoveRule]}
      path={ROUTES.REMOVE_ACCOUNT}
      component={RemoveAccountChunk}
    />
    <Route path={BASE_ROUTES.PWA_NEXT_STEP} component={PwaNextStepChunk} />
    {/* Livecam search */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.SEARCH_LIVE_CAM}
      component={SearchLivecamChunk}
    />
    {/* Search */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.SEARCH}
      component={DeferredSearchPage}
      // Prevent search remount when navigating from funnel steps
      fallback={null}
    />
    <ACLRoute
      rules={[...COMMON_ACL_RULES, WorldWideSearchRule]}
      path={ROUTES.WORLD_WIDE}
      component={WorldWideSearchChunk}
      // Prevent search remount when navigating from funnel steps
      fallback={null}
    />
    {/* Target user pages. All those pages are not really connected with each other. So we split all them in different chunks */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={[ROUTES.USER_FROM_PROMOCODE, BASE_ROUTES.USER]}
      component={UserChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={[ROUTES.MY_ACTIVITY_LIKES, ROUTES.MY_ACTIVITY_FAVOURITE]}
      component={MyActivityChunk}
    />
    <Route path={BASE_ROUTES.MASS_BLOCKED} component={MassBlockedChunk} />
    {/* Empty pages, performs some action and redirects somewhere */}
    <Route path={ROUTES.DEBTOR_INVOICE} component={SepaDebtorInvoicePage} />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.EMAIL_UPDATE}
      component={EmailUpdateRoute}
    />
    {/* Account pages */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.ACCOUNT_UNSUBSCRIBE}
      component={UnsubscribeChunk}
    />
    <ACLRoute
      exact
      strict
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.ACCOUNT_BLOCKED}
      component={BlockedUsersChunk}
    />
    <ACLRoute
      exact
      strict
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.ACCOUNT_REPORTED}
      component={ReportedUsersChunk}
    />
    <ACLRoute
      exact
      strict
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.ACCOUNT}
      component={AccountChunk}
    />
    {/* Brandbook */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.BRANDBOOK}
      component={BrandbookChunk}
    />
    {/* Test-only page to see browser info */}
    <Route path="/browserInfo" component={BrowserInfoChunk} />
    {/* Chat */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.CHAT}
      component={ChatChunk}
    />
    {/* Favorites */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.FAVORITES}
      component={FavoritesChunk}
    />
    {/* VideoChat */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.VIDEO_CHAT}
      component={VideoChatChunk}
    />
    {/* Story */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.STORY_MY}
      component={MyStoryChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.PARTNER_NETWORK}
      component={PartnerNetworkChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.STORY_TARGET}
      component={StoryChunk}
    />
    {/**
     * Routes, with popup on top of them, and with default content as foreground.
     * Be aware that popup opening logic also checks ACL rules.
     * @see RouteWithPopupDisplayer.js
     *
     * @todo
     * Instead of search chunk, make logic with previous route or default route from GraphQL API
     */}
    <ACLRoute
      rules={[BannedRule, AuthenticatedRule]}
      path={[...ROUTES_WITH_POPUP, FUNNEL]}
      component={DeferredSearchPage}
    />
    {/* Discreet profiles */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.DISCREET_PROFILES}
      component={DiscreetProfileChunk}
    />
    {/* Reels */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.REELS}
      component={ReelsChunk}
    />
    {/* Like gallery */}
    <ACLRoute
      rules={[...COMMON_ACL_RULES]}
      path={ROUTES.LIKE_GALLERY}
      component={LikeGalleryChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={BASE_ROUTES.NEWS_FEED_FILTER}
      component={NewsFeedChunk}
    />
    {/* Microfeatures */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.MICROFEATURES}
      component={MicroFeaturesChunk}
    />
    {/* Personal identity */}
    <ACLRoute
      rules={[...COMMON_ACL_RULES, PersonalIdentityVerifiedRule]}
      path={ROUTES.PERSONAL_IDENTITY}
      component={PersonalIdentityChunk}
    />
    {/* PMA */}
    <ACLRoute
      rules={[...COMMON_ACL_RULES, PmaServiceRule]}
      path={ROUTES.PMA_SERVICE}
      component={PMAChunk}
    />
    {/* Free coins */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.FREE_COINS}
      component={FreeCoinsChunk}
    />
    {/* Funnel quiz */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.FUNNEL_QUIZ}
      component={FunnelQuizChunk}
    />
    {/* Rooms */}
    <ACLRoute
      rules={[
        ...COMMON_ACL_RULES,
        RoomsAvailableRule,
        PersonalIdentityNotVerifiedRule,
        ModeratorPageRule,
      ]}
      path={BASE_ROUTES.ROOMS_MODERATOR}
      component={RoomsModeratorChunk}
    />
    <ACLRoute
      rules={[
        ...COMMON_ACL_RULES,
        RoomsAvailableRule,
        PersonalIdentityNotVerifiedRule,
      ]}
      path={ROUTES.ROOMS}
      component={RoomsChunk}
    />
    {/* Livechat page. Very similar to search. But accessible only from 'rooms' route */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={ROUTES.LIVE_CHAT}
      component={LivechatChunk}
    />
    <Route path={ROUTES.DESCRIPTOR} component={DescriptorChunk} />
    {/* Support pages. All pages are separated in different chunks because not all functionalities are available at startup */}
    <Route path={ROUTES.FEEDBACK} component={FeedbackChunk} />
    <Route path={BASE_ROUTES.CONTACT_US} component={ContactUsChunk} />
    {/* Trusted page. Original link is '/trusted/trustedVerification' but we can use entire route '/trusted' */}
    <ACLRoute
      rules={COMMON_ACL_RULES}
      path={[ROUTES.TRUSTED_VERIFICATION, ROUTES.TRUSTED_VIA, ROUTES.TRUSTED]}
      component={TrustedChunk}
    />
    {/* Password recovery */}
    <Route
      path={BASE_ROUTES.PASSWORD_RECOVERY}
      component={PasswordRecoveryChunk}
    />
    <ACLRoute
      rules={COMMON_ACL_RULES.filter(
        ({errorCode}) => errorCode !== 'PAID_APP_FUNNEL',
      )}
      path={ROUTES.GET_THE_APP}
      component={GetTheAppChunk}
    />
    {/**
     * Special app route. Should be replaced
     * with moving all such stuff to application entry-point
     */}
    <Route path={ROUTES.WRONG_DEVICE} component={WrongDeviceChunk} />

    {/* All static pages */}
    <Route path={BASE_ROUTES.STATIC_PAGE} component={StaticPageToHash} />
    <HashedStaticPageRoute
      staticPageChunk={StaticPageChunk}
      notFoundComponent={NotFound}
    />
  </Switch>
);

export default Routes;
