import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import Timer from '@core/utils/timer';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';

import {
  ButtonLink,
  Row,
  RowShort,
  Button,
  Overlay,
  Group,
  Tag,
} from '@phoenix/ui';
import {
  ButtonType,
  SpacingSize,
  OverlayType,
  OverlaySaturation,
  Align,
  TagType,
} from '@phoenix/ui/constants';
import {H3, Text, TextMuted} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import PhotoWithWidgets from '@phoenix/user/photo/components/PhotoWithWidgets';

import css from '../styles/ExpandSearchLimitsPopupLayout.css';

const ExpandSearchLimitsPopupLayout = ({
  onLaterClick,
  onTimeOver,
  users,
  expired,
  url,
  duration,
  extra,
}) => {
  const isPhone = usePhoneBreakpoint();
  const history = useHistory();

  const handleClick = useCallback(
    (event) => {
      /**
       * We prevent further propagation of the current event in the capturing and bubbling phases.
       * Because we have got action click on parent element and we will have a double click.
       */
      event.stopPropagation();
      history.push(url);
    },
    [history, url],
  );

  const handleClickOnContent = useCallback(() => {
    history.push(url);
  }, [url, history]);

  return (
    <PopupFrame
      data-test="searchLimitsPopupLayout"
      onClick={isPhone ? handleClickOnContent : null}
      trackingName={isPhone ? 'content' : null}
      content={
        <div
          className={cn(css.wrap, expired && css.expired)}
          data-test="searchLimitsPopupLayoutContent"
        >
          <div className={css.users}>
            {users.map(({id, profile}) => (
              <div key={id} className={css.user}>
                <PhotoWithWidgets
                  userId={id}
                  online={profile.statuses.isOnline}
                  recentlyOnline={profile.statuses.isRecentlyOnline}
                  photo={getPrimaryPhotoData(profile)}
                />
                <Overlay
                  type={OverlayType.GRADIENT_FROM_BOTTOM}
                  saturation={OverlaySaturation.LITE}
                />
                <Text inverse small className={css.login}>
                  {profile.login}
                </Text>
              </div>
            ))}
          </div>
          <RowShort className={css.content}>
            <div className={css.overlay}>
              <Overlay
                inverse
                type={OverlayType.GRADIENT_FROM_BOTTOM}
                saturation={OverlaySaturation.HEAVY}
              />
            </div>
            <Overlay inverse saturation={OverlaySaturation.HEAVY} />
            <RowShort align={Align.CENTER}>
              <H3 data-test="searchLimitsPopupLayoutHeader">
                {expired
                  ? t('extraPopups', 'title.time_passed')
                  : t('extraPopups', 'title.full_of_matches')}
              </H3>
            </RowShort>
            <RowShort align={Align.CENTER}>
              <TextMuted data-test="searchLimitsPopupLayoutMotivation1">
                {expired
                  ? t('extraPopups', 'text.limited_profile_search')
                  : t('extraPopups', 'text.multiplied_number_profiles')}
              </TextMuted>
              <TextMuted data-test="searchLimitsPopupLayoutMotivation2">
                {expired
                  ? t('extraPopups', 'text.upgrade_to_fix', {'{n}': extra})
                  : t('extraPopups', 'text.hurry_up_time_short', {
                      '{n}': extra,
                    })}
              </TextMuted>
            </RowShort>
            <Row align={Align.CENTER}>
              <div className={css.timer}>
                <Tag
                  data-test="searchLimitsPopupLayoutTag"
                  type={expired ? TagType.DANGER : TagType.PRIMARY}
                  icon="clock"
                  large
                >
                  {expired ? (
                    '00:00:00'
                  ) : (
                    <Timer
                      backward
                      initialTime={duration}
                      duration={duration}
                      onTimeOver={onTimeOver}
                    />
                  )}
                </Tag>
              </div>
            </Row>
          </RowShort>
        </div>
      }
      actions={
        expired ? (
          <Button
            onClick={handleClick}
            trackingName="getIt"
            type={ButtonType.PAY}
            fullWidth
            adaptive
            data-test="searchLimitsPopupLayoutExpiredActions"
          >
            {t('extraPopups', 'button.get_it_now')}
          </Button>
        ) : (
          <Group
            space={SpacingSize.SHORT}
            horizontal={!isPhone}
            reverse={!isPhone}
            data-test="searchLimitsPopupLayoutActions"
          >
            <ButtonLink
              href="/search"
              type={ButtonType.PRIMARY}
              fullWidth
              adaptive
            >
              {t('extraPopups', 'button.start_viewing')}
            </ButtonLink>
            <Button
              onClick={onLaterClick}
              trackingName="maybeLater"
              type={ButtonType.FLAT}
              fullWidth
              adaptive
            >
              {t('extraPopups', 'button.maybe_later')}
            </Button>
          </Group>
        )
      }
    />
  );
};

ExpandSearchLimitsPopupLayout.propTypes /* remove-proptypes */ = {
  onLaterClick: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  expired: PropTypes.bool,
  url: PropTypes.string,
  duration: PropTypes.number,
  onTimeOver: PropTypes.func,
  extra: PropTypes.number,
};

export default ExpandSearchLimitsPopupLayout;
