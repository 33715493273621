import React, {lazy} from 'react';

import isCompactViewportCached from '@core/responsive/isCompactViewportCached';

import SplitChunk from '../components/SplitChunk';

const PayVerificationChunk = lazy(() =>
  isCompactViewportCached()
    ? import(
        /* webpackChunkName: 'payVerificationRouteMob' */ '../routes/mob/PayVerificationRoute'
      )
    : import(
        /* webpackChunkName: 'payVerificationRouteWeb' */ '../routes/web/PayVerificationRoute'
      ),
);

export default (props) => (
  <SplitChunk component={PayVerificationChunk} {...props} />
);
