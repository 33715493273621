import BottomNoticeType from '@core/payment/banners/bottomNotice/constants/BottomNoticeType';
import {createTranslationsMap} from '@core/translations';
import t from '@core/translations/translate';

import PersonalIdentityService from '@phoenix/personalIdentity/PersonalIdentityService';

export type BottomNoticeTranslationsResult = {
  title?: string;
  description?: string;
  action?: string;
};

/**
 * Returns translations for the bottom notice based on the type, site name, and whether the text should be split.
 */
const getBottomNoticeTranslations = (
  type: string,
  siteName: string,
): BottomNoticeTranslationsResult => {
  const BOTTOM_NOTICE_TYPE_TO_TRANSLATION_MAP = {
    [BottomNoticeType.DECLINE_ALERT]: createTranslationsMap((translate) => ({
      title: translate('bottomNotice', 'text.title_declineAlert'),
      description: translate('bottomNotice', 'text.description_declineAlert', {
        '{sitename}': siteName,
      }),
      action: translate('bottomNotice', 'text.button_declineAlert'),
    })),
    [BottomNoticeType.MEMBERSHIP_ALERT]: createTranslationsMap((translate) => ({
      title: translate('bottomNotice', 'text.title_membershipAlert'),
      description: translate(
        'bottomNotice',
        'text.description_membershipAlert',
      ),
      action: translate('bottomNotice', 'text.button_membershipAlert'),
    })),
    [BottomNoticeType.FEATURES_ALERT]: createTranslationsMap((translate) => ({
      title: translate('bottomNotice', 'text.title_featuresAlert'),
      description: translate('bottomNotice', 'text.description_featuresAlert', {
        '{sitename}': siteName,
      }),
      action: translate('bottomNotice', 'text.button_featuresAlert'),
    })),
  };

  const translations = BOTTOM_NOTICE_TYPE_TO_TRANSLATION_MAP[type];

  if (
    PersonalIdentityService.isVerificationRequired() &&
    type === BottomNoticeType.DECLINE_ALERT
  ) {
    // Can't just overwrite 'description' since 'createTranslationsMap' creates getters.
    return {
      title: translations.title,
      description: [
        translations.description,
        t('bottomNotice', 'text.additional_declineAlert'),
      ].join(' '),
      action: translations.action,
    };
  }

  return translations;
};

export default getBottomNoticeTranslations;
