import type {Client} from '@core/graphql/types';

import type {QuickStickersQuery} from '../../common/graphql/queries/quickStickers';
import QUICK_STICKERS_QUERY from '../../common/graphql/queries/quickStickers.gql';

const updateQuickStickersSendAmount = (client: Client) => {
  const data = client.readQuery<QuickStickersQuery>({
    query: QUICK_STICKERS_QUERY,
  });

  /**
   * Do nothing if:
   * - there is no data in cache. Maybe on such theme this functionality doesn't exist at all.
   * - amount is null or zero. It means that user have just used all possible stickers
   */
  if (!data || !data.messenger.quickStickersSendAmount) {
    return;
  }

  client.writeQuery<QuickStickersQuery>({
    query: QUICK_STICKERS_QUERY,
    data: {
      ...data,
      messenger: {
        ...data.messenger,
        quickStickersSendAmount: data.messenger.quickStickersSendAmount - 1,
      },
    },
  });
};

export default updateQuickStickersSendAmount;
