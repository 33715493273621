import {useCallback} from 'react';

import {PhotoRequestTypeEnum} from '@core/types';

import PrivatePhotoOverlayType from '../constants/PrivatePhotoOverlayType';
import pmaPhotoRequest from './pmaPhotoRequest';

const usePrivatePhotoRequest = ({photo, photoRequestData, userId}) => {
  const withRequest = photoRequestData?.withRequest ?? true;

  const {isPrivate, isRequested, requestType} = photo?.privateAttributes || {};

  const isAllowed = withRequest && isPrivate;

  const handleClick = useCallback(
    async (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (requestType === PhotoRequestTypeEnum.pmaPhoto) {
        pmaPhotoRequest({userId, photo, photoRequestData});
      }
    },
    [photo, photoRequestData, requestType, userId],
  );

  /**
   * Prevent photo default click event for private album photo.
   * All fundamental logic is implemented in PrivateAlbumPhotoOverlay.
   */
  if (requestType === PhotoRequestTypeEnum.privateAlbum && isAllowed) {
    return {
      isAllowed:
        photoRequestData?.overlayType ===
          PrivatePhotoOverlayType.WITH_REQUEST_BUTTON && !isRequested,
      onClick: null,
    };
  }

  return {
    isAllowed,
    onClick: handleClick,
  };
};

export default usePrivatePhotoRequest;
