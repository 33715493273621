import {getClientInstance} from '@core/graphql/client';
import {USER_PROFILE_ROUTE} from '@core/utils/url/createUrlFor';
import OPEN_PROFILES_ALLOWED_QUERY from '@core/user/profile/current/graphql/queries/openProfilesAllowed.gql';
import getOpenProfileSource from '@core/user/profile/target/utils/getOpenProfileSource';
import {OpenProfileSource} from '@core/types';

import {getUserViewPermissionFragment} from '@phoenix/user/profile/target/utils/updateUserViewPermission';
import markTargetUserProfileAsOpened from '@phoenix/user/profile/target/utils/markTargetUserProfileAsOpened';

/**
 * Get userId from url
 * url example: '/user/view/id/123/fromPromocode/test/partnerName/test';
 * @param {string} url
 * @return {string|undefined}
 */
const getUserIdFromUrl = (url) => {
  return url.match(new RegExp(`^${USER_PROFILE_ROUTE}([^/]+)`))?.[1];
};

/**
 * Get access to watch profile with photo and video permissions if user come from search or chat route
 * @param currentRoute
 * @param nextRoute
 * @return {Promise<void>}
 */
const showTargetUserMediaWithoutPermissions = async ({
  currentRoute = '',
  nextRoute = '',
} = {}) => {
  const userId = getUserIdFromUrl(nextRoute);

  if (!userId) {
    return;
  }

  const source = getOpenProfileSource(currentRoute);

  /**
   * Skip update view permissions if user come not from search or chat route
   */
  if (!source) {
    return;
  }

  const {data: userViewPermissionsData} = getUserViewPermissionFragment(userId);

  const {photo, video} = userViewPermissionsData?.viewPermissions || {};

  /**
   * If allowed one of permission no need update user view permissions
   */
  if (photo || video) {
    return;
  }

  const client = getClientInstance();

  const {data} = await client.query({
    query: OPEN_PROFILES_ALLOWED_QUERY,
  });

  const {fromSearch, fromChat} = data?.userFeatures.openProfile || {};
  const allowedFromSearch =
    source === OpenProfileSource.fromSearch && fromSearch;
  const allowedFromChat = source === OpenProfileSource.fromChat && fromChat;

  if (allowedFromSearch || allowedFromChat) {
    /**
     * Mark target user profile as opened for save permissions for watch photo and video after reload.
     */
    markTargetUserProfileAsOpened({userId, source}).then(() => {
      /**
       * Update cache after success mutation for disable next try
       * User can watch target profile media for free only once from each source
       */
      client.writeQuery({
        query: OPEN_PROFILES_ALLOWED_QUERY,
        data: {
          ...data,
          userFeatures: {
            ...data.userFeatures,
            openProfile: {
              /**
               * ATTENTION! Custom bug.
               * Need for redirect only from one source. Other source redirect will be allowed after page reload.
               * More details: FE-31886
               * Correct realization:
               * fromSearch: allowedFromSearch ? false : fromSearch,
               * fromChat: allowedFromChat ? false : fromChat,
               */
              fromSearch: false,
              fromChat: false,
              __typename: 'openProfile',
            },
          },
        },
      });
    });
  }
};

export default showTargetUserMediaWithoutPermissions;
