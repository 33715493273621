import logger from '@core/logger';
import type ImageGroups from '@core/theming/constants/ImageGroups';

import getThemedImageUrl from './getThemedImageUrl';

const images = require.context('../images', true);

/**
 * Retrieve image by group and identifier for custom theme.
 * Themes are set here:
 * @see src/packages/dating/theming/themes/*
 */
const getThemedImage = (group: ImageGroups, identifier: string) => {
  let themedImage = '';
  try {
    const imagePath = getThemedImageUrl(group, identifier);
    themedImage = images(imagePath);
  } catch (ex) {
    logger.sendError(
      `[getThemedImage] No image for group: ${group} and identifier: ${identifier}`,
    );

    return '/notFound';
  }

  return themedImage;
};

export default getThemedImage;
