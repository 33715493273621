/**
 * There isn't loaded document
 * @type {String}
 */
export const NOT_EXIST = 'notExists';

/**
 * Last loaded document is approved
 * @type {String}
 */
export const APPROVED = 'approved';

/**
 * Last loaded document is disapproved
 * @type {String}
 */
export const DISAPPROVED = 'disapproved';

/**
 * Last loaded document is waiting to approve
 * @type {String}
 */
export const PENDING_TO_APPROVE = 'pendingToApprove';
