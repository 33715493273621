import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PopupPriority from '@core/popup/constants/PopupPriority';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

import EXTRA_BUY_THREE_DAY_QUERY from './graphql/queries/extraBuyThreeDay.gql';
import ExtraBuyThreeDayPopupLayout from './components/ExtraBuyThreeDayPopupLayout';

/**
 * @param processId
 * @param client
 * @returns {Promise<void>}
 */
const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {
        buy3DayGet1Week: {enabled, via, amount, currency},
      },
    },
  } = await client.query({
    query: EXTRA_BUY_THREE_DAY_QUERY,
    variables: {processId},
  });

  if (!enabled) return;

  const url = generatePayUrl({
    stage: PAYMENT_ACTIONS.MEMBERSHIP,
    urlParams: {via},
  });

  PopupService.openPopup(
    <ExtraBuyThreeDayPopupLayout
      url={url}
      amount={amount}
      currency={currency}
    />,
    {
      small: true,
      priority: PopupPriority.LOW,
      trackingName: 'extraBuyThreeDay',
      autoLoadTracking: true,
    },
  );
};

export default action;
