import React from 'react';
import PropTypes from 'prop-types';

import InAppMotivationPopup from './InAppMotivationPopup';
import {DEFAULT} from '../constants/motivationTypes';
import {GOOGLE_TYPE, INTENT_TYPE} from '../utils/formatLink';

const InAppMotivationPopupWithRandomUser = (props) => (
  <InAppMotivationPopup {...props} loading={false} type={DEFAULT} />
);

InAppMotivationPopupWithRandomUser.propTypes /* remove-proptypes */ = {
  linkType: PropTypes.oneOf([GOOGLE_TYPE, INTENT_TYPE]),
  alterBtns: PropTypes.bool,
  loading: PropTypes.bool,
};

export default InAppMotivationPopupWithRandomUser;
