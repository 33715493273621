import type {FC, MouseEvent} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import {BlurredImage, Icon} from '@phoenix/ui';
import {IconSize} from '@phoenix/ui/constants';
import {TextWithIcon} from '@phoenix/typography';

import css from '../styles/BlurredSticker.css';

type BlurredStickerProps = {
  url: string;
  small?: boolean;
  blurLevel?: number;
  text?: string;
  withoutIcon?: boolean;
  handleClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
};

const BlurredSticker: FC<BlurredStickerProps> = ({
  small,
  className,
  text,
  handleClick,
  blurLevel,
  withoutIcon,
  ...props
}) => {
  const blurredImageSettings = {
    level: blurLevel,
    ...props,
  };

  const withText = Boolean(text);
  const withIcon = Boolean(!text && !withoutIcon);

  return (
    <AddBabciaUBTracking trackingName="blurredSticker">
      <div
        onClick={handleClick}
        role="button"
        tabIndex={0}
        className={cn(css.container, className)}
        data-test="blurredSticker"
      >
        <BlurredImage {...blurredImageSettings} />
        {withText && (
          <TextWithIcon icon="fire" className={css.text}>
            {text}
          </TextWithIcon>
        )}
        {withIcon && (
          <Icon
            standard
            type="fire"
            size={small ? IconSize.SIZE_16 : IconSize.SIZE_36}
            className={css.icon}
          />
        )}
      </div>
    </AddBabciaUBTracking>
  );
};

export default BlurredSticker;
