import React from 'react';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';
import {Text} from '@phoenix/typography';

import css from '../styles/SuccessMessage.css';

const SuccessMessage = ({children, ...props}) => (
  <div className={css.success} {...props}>
    <Icon success type="check" className={css.icon} />
    <Text type={Text.TYPE.SUCCESS}>{children}</Text>
  </div>
);

SuccessMessage.propTypes /* remove-proptypes */ = {
  children: PropTypes.string.isRequired,
};

export default SuccessMessage;
