/**
 * @const {string}
 */
export const DESCRIPTION_LIMIT_VIA = 'descriptionslimit';

/**
 * @const {string}
 */
export const ICEBREAKER_ASSIST_LIMIT = 'icebreaker_assist_limit';

/**
 * @const {string}
 */
export const ICEBREAKER_ASSIST_LIMIT_2 = 'icebreaker_assist_limit_2';
