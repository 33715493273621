import getHistory from '@core/application/utils/getHistory';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import getDefaultPage from '@core/utils/routing/getDefaultPage';
import {parsePathname} from '@core/utils/url';

import isCoinsModel from '@phoenix/coins/utils/isCoinsModel';
import processCoinsSuccess from '@phoenix/coins/utils/processCoinsSuccess';

// eslint-disable-next-line consistent-return
const successPaymentPageNavigationInterceptor = async () => {
  const isPaymentCoinModel = await isCoinsModel();

  /**
   * For coin payment model need show coin success popup instead success page
   */
  if (isPaymentCoinModel) {
    const successOrders = PaymentPageSuccessOrders.getNotViewedOrderIds();

    if (successOrders.length) {
      processCoinsSuccess();
    } else {
      const history = getHistory();

      const {
        options: {returnPath, successReturnPath},
      } = parsePathname(history.location);

      const url = successReturnPath || returnPath || (await getDefaultPage());

      history.replace(url);
    }

    return false;
  }
};

export default successPaymentPageNavigationInterceptor;
