import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import ExtraDiscountWithProgressPopupLayout from '../components/ExtraDiscountWithProgressPopupLayout';

const PERCENTAGE_EXPIRES_VALUE = 25;

const ExtraDiscountWithProgressPopup = ({duration, ...props}) => {
  const [percentage, setPercentage] = useState(100);
  const expires = percentage < PERCENTAGE_EXPIRES_VALUE;
  const handleTimeUpdate = useCallback(
    (time) => setPercentage(100 * (time / duration)),
    [duration],
  );

  return (
    <ExtraDiscountWithProgressPopupLayout
      onTimeUpdate={handleTimeUpdate}
      duration={duration}
      percentage={percentage}
      expires={expires}
      {...props}
    />
  );
};

ExtraDiscountWithProgressPopup.propTypes /* remove-proptypes */ = {
  duration: PropTypes.number.isRequired,
};

export default ExtraDiscountWithProgressPopup;
