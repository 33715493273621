import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {VIDEO_SETTINGS} from '@core/mediaUpload/constants/mediaSettings';
import {VIDEO} from '@core/mediaUpload/constants/uploadTypes';
import isPhotoUploadDisabled from '@core/mediaUpload/utils/isPhotoUploadDisabled';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';

import openInAppMotivationPopup from '@phoenix/inAppMotivation/utils/openInAppMotivationPopup';
import {PAID_FUNNEL_PAGE} from '@phoenix/inAppMotivation/constants/motivationTypes';
import getThemeFeature from '@phoenix/theming/utils/getThemeFeature';

import VideoUploadPopup from '../containers/VideoUploadPopup';
import openMediaUploadDisabledPopup from './openMediaUploadDisabledPopup';

/**
 * Open video upload popup
 *
 * @param {Object} props Video upload settings. @see settingsPropType
 */
const openVideoUploadPopup = async ({spacedFooter, ...props} = {}) => {
  // Check for onclick={openVideoUploadPopup} usage
  // Prevent "Warning: This synthetic event is reused for performance reasons..." error
  const validProps = props.nativeEvent ? {} : props;
  const {onClose} = validProps;

  const isPhotoUploadDisabledResult = await isPhotoUploadDisabled();

  if (isPhotoUploadDisabledResult) {
    openMediaUploadDisabledPopup({onClose, mediaType: VIDEO});
    return;
  }

  const isSnapchat =
    getBootstrapParam(IN_APP_NAME) === InAppBrowserName.SNAPCHAT;

  if (isSnapchat) {
    openInAppMotivationPopup({
      motivationType: PAID_FUNNEL_PAGE,
      onClose,
    });

    return;
  }

  const trackingConceptId = await getThemeFeature('mediaUpload', 'appearance');

  PopupService.openPopup(
    <VideoUploadPopup
      settings={{
        ...VIDEO_SETTINGS,
        ...validProps,
        type: VIDEO_SETTINGS.type,
      }}
      spacedFooter={spacedFooter}
    />,
    {onClose, small: true, trackingName: 'videoUpload', trackingConceptId},
  );
};

export default openVideoUploadPopup;
