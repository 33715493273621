import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {RowShort, Bar, Spacing} from '@phoenix/ui';
import {List, ListItem, H4} from '@phoenix/typography';

const ConfirmationImportantNote = ({siteName}) => (
  <Bar>
    <Spacing>
      <RowShort>
        <H4>{t('emailValidationBase', 'text.important')}</H4>
      </RowShort>
      <RowShort>
        <List bulleted small muted>
          <ListItem>
            {t('emailValidationBase', 'title.junk/email_delay')}
          </ListItem>
          <ListItem>
            {t('emailValidationBase', 'text.check_your_folders')}
          </ListItem>
          <ListItem>
            {t('emailValidationBase', 'text.remember_to_mark_email', {
              '{siteName}': siteName,
            })}
          </ListItem>
        </List>
      </RowShort>
    </Spacing>
  </Bar>
);

ConfirmationImportantNote.propTypes /* remove-proptypes */ = {
  siteName: PropTypes.string.isRequired,
};

export default ConfirmationImportantNote;
