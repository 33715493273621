import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import deleteMessage from '@core/messenger/common/utils/deleteMessage';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';

import InappropriateContentSendPopup from '../containers/InappropriateContentSendPopup';
import InappropriateContentSendPopupLayout from '../components/InappropriateContentSendPopupLayout';

type InappropriateContentParams = {
  messageId: string;
  userId: string;
};

const openInappropriateContentSendPopup = ({
  messageId,
  userId,
}: InappropriateContentParams): void => {
  // Delete message if user deliberately closes popup instead of clicking "Send Anyway".
  const onClose = (sourceEventType) => {
    if (sourceEventType !== PopupSourceEvent.EMPTY) {
      deleteMessage(messageId, userId);
    }
  };

  PopupService.openPopup(
    <InappropriateContentSendPopup
      messageId={messageId}
      userId={userId}
      layout={InappropriateContentSendPopupLayout}
    />,
    {
      onClose,
      small: true,
      trackingName: 'inappropriateContentSend',
      autoLoadTracking: true,
    },
  );
};

export default openInappropriateContentSendPopup;
