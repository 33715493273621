import {getClientInstance} from '@core/graphql/client';
import PAYMENT_MODEL_QUERY from '@core/payment/common/graphql/queries/paymentModel.gql';
import PAYMENT_MODELS from '@core/payment/common/constants/paymentModels';

/**
 * Util function for checking is credits model of payment is enabled on site
 */
const isCoinsModel = async () => {
  const {
    data: {
      payment: {model},
    },
  } = await getClientInstance().query({query: PAYMENT_MODEL_QUERY});

  return model === PAYMENT_MODELS.COINS;
};

export default isCoinsModel;
