import createTranslationsMap from '@core/translations/createTranslationsMap';

import {MICROFEATURES} from './microfeatures';

export const POPUP_TITLE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'header.title.microIncognito',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'header.title.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'header.title.priorityMan',
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'header.title.priorityMan.heteroFemale',
    ),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'header.title.superBoost'),
    [MICROFEATURES.FLIRT_ALARM]: t('microfeatures', 'header.title.flirtAlarm'),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'header.title.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'title.topInChat'),
    [MICROFEATURES.MICROFEATURE_OFFER]: t(
      'microfeatures',
      'header.title.microfeatureOffer',
    ),
  }));
export const POPUP_TITLE_ADULT = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'header.title.microIncognito.adult',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'header.title.moreLikes.adult',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'header.title.priorityMan.adult',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'header.title.superBoost.adult',
    ),
  }));
export const POPUP_TITLE_FEMALE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'title.priorityMan.heteroFemale',
    ),
  }));
export const POPUP_TITLE_LESBIAN = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'title.priorityMan.lesbian',
    ),
  }));
export const POPUP_DESCRIPTION = (options) =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'desription.microIncognito',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'desription.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'desription.priorityMan',
      options,
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'desription.priorityMan.heteroFemale',
      options,
    ),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'desription.superBoost'),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'desription.flirtAlarm',
      options,
    ),
    [MICROFEATURES.SEX_ALARM]: t(
      'microfeatures',
      'desription.sexAlarm',
      options,
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'desription.topInChat'),
  }));
export const POPUP_DESCRIPTION_LESBIAN = (options) =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'desription.priorityMan',
      options,
    ),
  }));
export const POPUP_DESCRIPTION_ADULT = (options) =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'desription.microIncognito.adult',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'desription.moreLikes.adult',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'desription.priorityMan.adult',
      options,
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'desription.superBoost.adult',
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'desription.topInChat.adult',
    ),
  }));
export const POPUP_DESCRIPTION_GAY = (options) =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'desription.microIncognito.gay',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'desription.moreLikes.gay'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'desription.priorityMan.gay',
      options,
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'desription.superBoost.gay',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'desription.flirtAlarm.gay',
      options,
    ),
    [MICROFEATURES.SEX_ALARM]: t(
      'microfeatures',
      'desription.sexAlarm.gay',
      options,
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'desription.topInChat.gay'),
  }));
export const POPUP_DESCRIPTION_FEMALE = (options) =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'desription.microIncognito.heteroFemale',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'desription.moreLikes.heteroFemale',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'desription.priorityMan.heteroFemale',
      options,
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'desription.superBoost.heteroFemale',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'desription.flirtAlarm.heteroFemale',
      options,
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'desription.topInChat.heteroFemale',
    ),
  }));

export const POPUP_ACTION = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t('paymentPage', 'button.microIncognito'),
    [MICROFEATURES.MORE_LIKES]: t('paymentPage', 'button.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t('paymentPage', 'button.priorityMan'),
    [MICROFEATURES.SEARCH_QUEEN]: t('paymentPage', 'button.priorityMan'),
    [MICROFEATURES.SUPER_BOOST]: t('paymentPage', 'button.superBoost'),
    [MICROFEATURES.FLIRT_ALARM]: t('paymentPage', 'button.flirtAlarm'),
    [MICROFEATURES.SEX_ALARM]: t('paymentPage', 'button.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t('paymentPage', 'button.topInChat'),
  }));
