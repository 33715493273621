import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';

import {ICONS} from '../constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';
import css from '../styles/MicrofeaturesIconTopInChat.css';

const MicrofeaturesIconTopInChat = ({
  withAnimation = true,
  active = false,
  ...props
}) => (
  <MicrofeaturesIconLayout
    icon={
      <Icon
        className={cn(withAnimation && css.animation, css.icon)}
        type={ICONS[MICROFEATURES.TOP_IN_CHAT]}
        inherit={!active}
      />
    }
    type={MicrofeaturesIconLayout.TYPE.WARNING}
    active={active}
    {...props}
  />
);

MicrofeaturesIconTopInChat.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconTopInChat.propTypes /* remove-proptypes */ = {
  withAnimation: PropTypes.bool,
  active: PropTypes.bool,
};

export default MicrofeaturesIconTopInChat;
