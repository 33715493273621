import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {packagePricePropType} from '@core/payment/widgets/package/constants/propTypes';

import {SpacingShort} from '@phoenix/ui';
import {SpacingDirection} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';

import css from '../styles/PaymentPackageOldPrice.css';

const PaymentPackageOldPrice = ({
  price: {
    pricePerPart: {
      currency,
      formatted: {hi, lo, shortPrice},
    },
  },
  inverse,
}) => (
  <SpacingShort
    direction={SpacingDirection.HORIZONTAL}
    className={css.price}
    stretch={false}
  >
    {currency.prefix && (
      <Text small inverse={inverse} className={css.currency}>
        {currency.prefix}
      </Text>
    )}
    {shortPrice ? (
      <Text className={css.hi} inverse={inverse}>
        {shortPrice}
      </Text>
    ) : (
      <Fragment>
        {hi && (
          <Text className={css.hi} inverse={inverse}>
            {hi}
          </Text>
        )}
        {lo && (
          <Text small inverse={inverse} className={css.lo}>
            {lo}
          </Text>
        )}
      </Fragment>
    )}
    {currency.suffix && (
      <Text small inverse={inverse} className={css.currency}>
        {currency.suffix}
      </Text>
    )}
  </SpacingShort>
);

PaymentPackageOldPrice.propTypes /* remove-proptypes */ = {
  price: packagePricePropType.isRequired,
  inverse: PropTypes.bool,
};

export default PaymentPackageOldPrice;
