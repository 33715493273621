import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import updateCachesAfterPayment from '../../utils/updateCachesAfterPayment';

const updateRemarketingOfferAfterPayment: PaymentProcessStep = async (data) => {
  const {paymentResult} = data;

  if (paymentResult.orderId) {
    await updateCachesAfterPayment(true, ['UserFeatures', 'remarketingPopup']);
  }

  return data;
};

export default updateRemarketingOfferAfterPayment;
