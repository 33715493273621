import COMMON_ACTIONS from '@core/user/actions/constants/Action';

import {ButtonType} from '@phoenix/ui/constants';

/* IF we have more than 3 actions on card we should display only icons without text description */
export const WITH_TEXT_ACTIONS_LIMIT_ON_CARD = 3;

export const DEFAULT_ACTIONS_TYPE = ButtonType.FLAT;
/**
 * Types of the user actions for the different places
 */
export default {
  ...COMMON_ACTIONS,
  VIDEO_CHAT: 'videoChat',
  FAVORITE: 'favorite',
  WEBCAM: 'webcam',
  GO_TO_WEBCAM: 'goToWebcam',
  GIFTS: 'gifts',
  KISS: 'kiss',
};
