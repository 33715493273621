import ChatMessageAppearance from '@core/theming/constants/features/ChatMessageAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change to actual type after migration all chunks on TS FE-43644
 */
type ChatMessageComponent = any;

const resolver: ModuleResolver<ChatMessageAppearance, ChatMessageComponent> = (
  value,
) => {
  switch (value) {
    case ChatMessageAppearance.REGULAR_MESSAGE:
      return import(
        /* webpackChunkName: 'chatMessage' */ '@phoenix/messenger/messages/constants/chatMessageRegular'
      );
    case ChatMessageAppearance.BLURRED_WITH_INFO_ON_BOTTOM:
      return import(
        /* webpackChunkName: 'chatMessageBlurredWithInfoOnBottom' */ '@phoenix/messenger/messages/constants/chatMessageBlurredWithInfoOnBottom'
      );
    case ChatMessageAppearance.REGULAR_MESSAGE_WITH_LIGHT_UPGRADE:
      return import(
        /* webpackChunkName: 'chatMessageLight' */ '@phoenix/messenger/messages/constants/chatMessageRegularWithLightUpgrade'
      );
    case ChatMessageAppearance.REGULAR_MESSAGE_WITH_INCREASED_SPACING:
      return import(
        /* webpackChunkName: 'chatMessageWithIncreasedSpacing' */ '@phoenix/messenger/messages/constants/chatMessageRegularWithIncreasedSpacing'
      );
    case ChatMessageAppearance.REGULAR_MESSAGE_WITH_VIEW_PERMISSION:
      return import(
        /* webpackChunkName: 'regularMessageWithViewPermission' */ '@phoenix/messenger/messages/constants/chatMessageRegularWithViewPermissionCheck'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable chat message based on theme.
 * Returns object of composite components that are used for theming.
 */
const useThemedChatMessage = () =>
  useThemeCompositeFeatureLoader<ChatMessageAppearance, ChatMessageComponent>(
    'messenger',
    'message',
    resolver,
  );

export default useThemedChatMessage;
