import {useLayoutEffect} from 'react';
import throttle from 'lodash/throttle';
import find from 'lodash/find';

import {
  POPUP_ID,
  POPUP_ACTIONS_ID,
  POPUP_SCROLLABLE_VIEW_ID,
} from '@core/popup/constants/popup';
import getSizeBasedOnZoom from '@core/application/utils/getSizeBasedOnZoom';

const THROTTLE_DELAY = 150;
const POPUP_PADDING = 20;

const getClientHeight = (elementId) => {
  // Find visible element height
  const element = find(
    document.querySelectorAll(`#${elementId}`),
    ({clientHeight}) => clientHeight,
  );

  return element ? element.clientHeight : 0;
};

const updateHeight = ({
  fullSize,
  contentId,
  fitContentHeight,
  scrollableViewId,
} = {}) => {
  const actionsHeight = getClientHeight(POPUP_ACTIONS_ID);
  const scrollableView = document.getElementById(scrollableViewId);
  const conceptElement = document.getElementById(contentId);

  if (!scrollableView || !conceptElement) {
    return;
  }

  if (fullSize) {
    const prevScrollTop = scrollableView.scrollTop;
    conceptElement.style.height = null;

    const appHeight = getSizeBasedOnZoom(window.innerHeight);
    const height = appHeight - actionsHeight - POPUP_PADDING;

    if (fitContentHeight) {
      const conceptHeight = getClientHeight(contentId);

      conceptElement.style.height = `${Math.max(height, conceptHeight)}px`;
    } else {
      conceptElement.style.height = `${height}px`;
    }

    scrollableView.scrollTop = prevScrollTop;
  } else {
    const {visibility} = getComputedStyle(conceptElement);
    const conceptHeight = getClientHeight(contentId);
    // detect case when, another popup is open on top of RM popup
    const isHidden = visibility === 'hidden' || conceptHeight === 0;

    scrollableView.style.height = isHidden
      ? ''
      : `${conceptHeight + actionsHeight}px`;
  }
};

export const initPopupContentHeightUpdate = ({
  contentId,
  scrollableViewId = POPUP_SCROLLABLE_VIEW_ID,
  fullSize,
  fitContentHeight,
}) => {
  const config = {
    childList: true,
    subtree: true,
    attributes: true,
    /**
     * Watch attributes to update height manually
     */
    attributeFilter: ['class', 'data-rows-height-observer'],
  };

  const popup = document.getElementById(POPUP_ID);

  updateHeight({contentId, fullSize, fitContentHeight, scrollableViewId});

  const updateHeightThrottled = throttle(() => {
    updateHeight({contentId, fullSize, fitContentHeight, scrollableViewId});
  }, THROTTLE_DELAY);

  const mutationObserver = new MutationObserver(updateHeightThrottled);
  mutationObserver.observe(popup, config);

  window.visualViewport?.addEventListener('resize', updateHeightThrottled, {
    passive: true,
  });

  return () => {
    if (!fullSize) {
      document.getElementById(POPUP_SCROLLABLE_VIEW_ID).style.height = null;
    }
    mutationObserver.disconnect();
    updateHeightThrottled.cancel();
    window.visualViewport?.removeEventListener('resize', updateHeightThrottled);
  };
};

/**
 * this hook is set content height(without card details block) for scrollable element in popup
 * for to make hook work popup content must have some height
 */
const useFixedContentHeight = ({
  skip = false,
  fullSize = false,
  contentId = null,
  fitContentHeight = true,
} = {}) => {
  /* eslint-disable consistent-return, react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    if (!contentId || skip || window.IS_INTEGRATION_TEST_ENVIRONMENT) return;

    return initPopupContentHeightUpdate({
      contentId,
      fullSize,
      fitContentHeight,
    });
    /**
     * Don't add fullSize flag to array deps,
     * it causes re-render on iPad when orientation is changing
     * and height of popup calculates incorrectly
     */
  }, [skip, contentId]);
  /* eslint-enable consistent-return, react-hooks/exhaustive-deps */
};

export default useFixedContentHeight;
