import React from 'react';

import VideoLayout from '@phoenix/gallery/components/VideoLayout';
import WebCamGalleryMotivation from '@phoenix/gallery/components/WebCamGalleryMotivation';

import VideoPreview from '../../video/containers/VideoPreview';

/**
 * Open only first video in "open profile"
 * maintenance.20250129.voloshin.TN_213342
 *
 * @param {boolean} showWebCamMotivation
 * @param {function} processPaidVideoView
 * @param {string} forceWebCamPartner
 * @param {string} openProfileFirstVideoId - will be sent only if functional is available
 * @param {number} forceLevel
 * @param {string} userId
 * @param {Object} video
 * @param {boolean} my
 *
 * @returns {JSX.Element}
 */
const openOnlyFirstVideoRenderer = ({
  showWebCamMotivation,
  processPaidVideoView,
  forceWebCamPartner,
  openProfileFirstVideoId,
  forceLevel,
  userId,
  video,
  my,
  ...props
}) => {
  if (openProfileFirstVideoId && video?.id !== openProfileFirstVideoId) {
    return (
      <VideoPreview
        spacedPlayButton
        withNaughtyModePopup={false}
        video={video}
        my={my}
        userId={userId}
        onClick={processPaidVideoView}
        forceLevel={forceLevel}
      />
    );
  }

  return (
    <VideoLayout
      motivationAfterVideo={
        showWebCamMotivation && (
          <WebCamGalleryMotivation
            userId={userId}
            forceWebCamPartner={forceWebCamPartner}
          />
        )
      }
      userId={userId}
      video={video}
      my={my}
      {...props}
    />
  );
};

export default openOnlyFirstVideoRenderer;
