import {useCallback} from 'react';
import {useQuery} from '@apollo/client';

import {gotoUrl, replaceLocation} from '@core/utils/url';

import BACK_OFFER_TARGET_URL_QUERY from '../graphql/queries/backOfferTargetUrl.gql';

/**
 * @param {Boolean} skip
 * @return {Null|Function}
 */
const useBackOfferPopunder = (skip) => {
  const {data, error} = useQuery(BACK_OFFER_TARGET_URL_QUERY, {
    skip: skip || window.IS_INTEGRATION_TEST_ENVIRONMENT,
  });

  const targetUrl = data?.backOfferTargetUrl?.targetUrl;
  const setActiveNewTab = data?.backOfferTargetUrl?.setActiveNewTab;
  const disabled = error || !targetUrl;

  const callPopunder = useCallback(() => {
    if (setActiveNewTab) {
      gotoUrl(targetUrl, true);
    } else {
      gotoUrl(window.location.href, true);
      replaceLocation(targetUrl);
    }
  }, [setActiveNewTab, targetUrl]);

  return disabled ? null : callPopunder;
};

export default useBackOfferPopunder;
