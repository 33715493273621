import {useParams} from 'react-router-dom';

import getTrustedStatusById from '@core/trusted/utils/getTrustedStatusById';
import useCachedTargetUserData from '@core/user/profile/target/utils/useCachedTargetUserData';

import useTargetUserBaseData from './useTargetUserBaseData';

const useTargetUserData = ({userId}) => {
  const {partnerName} = useParams();

  const {data, loading} = useTargetUserBaseData({
    userId,
    forceWebCamPartner: partnerName,
  });

  const cachedData = useCachedTargetUserData(userId);

  if (!cachedData && loading) {
    return {loading};
  }

  const {
    profile: {
      login,
      location: {city, regionAbbreviation, country} = {},
      age,
      statuses: {
        isModerator,
        isAdmin,
        isWebcamProfile,
        isOnline,
        isRecentlyOnline,
        isHidden,
      } = {},
    } = {},
    trusted: {sv} = {},
    blockUser: {iAm: blockedByMe, heMe: blockedByHim} = {},
    reportUser: {iAm: reportedByMe} = {},
  } = data?.user || cachedData || {};

  const {
    userActivity: {isFavourited} = {},
    profile: {attributes: {professionId} = {}} = {},
  } = data?.user || {};

  return {
    login,
    city,
    regionAbbreviation,
    country,
    age,
    isAdmin,
    isModerator,
    isWebcamProfile,
    isOnline,
    isRecentlyOnline,
    professionId,
    blockedByMe,
    blockedByHim,
    isHidden,
    reportedByMe,
    verifiedStatus: getTrustedStatusById(sv),
    isFavourited,
    user: data?.user,
    loading,
  };
};

export default useTargetUserData;
