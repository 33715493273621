import once from 'lodash/once';

import PERSONAL_IDENTITY_READ_SERVICE_QUERY from '@core/messenger/common/graphql/queries/personalIdentityReadService.gql';

import isVerificationRequired from '@phoenix/personalIdentity/utils/isVerificationRequired';

import createScheduler from './createScheduler';

const PersonalIdentityReadService = {
  getInstance: once(() =>
    createScheduler({
      storageKey: 'personalIdentityDeletionSchedule',
      query: PERSONAL_IDENTITY_READ_SERVICE_QUERY,
      isEnabled: ({
        userFeatures: {
          personalIdentity: {required, verificationStatus},
        },
      }) => isVerificationRequired(required, verificationStatus),
    }),
  ),
};

export default PersonalIdentityReadService;
