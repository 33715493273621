import {GenderTypeEnum} from '@core/types/graphql';

import type {ListUserData} from '../types';

type UserGender = {
  gender: {
    isFemale: boolean;
  };
  orientation: {
    isGay: boolean;
    isLesbian: boolean;
  };
};

const getPhotoGender = ({
  gender,
  orientation: {isGay, isLesbian},
}: UserGender): GenderTypeEnum =>
  isGay || (gender.isFemale && !isLesbian)
    ? GenderTypeEnum.male
    : GenderTypeEnum.female;

/**
 * Util func for adding custom user to avoid placeholders on searchMatch and searchGrid motivations background
 */
const addDefaultUsers = (
  userList: ListUserData[] = [],
  userGender: UserGender,
  userAmount: number,
): ListUserData[] => {
  if (userList.length >= userAmount) return userList;

  const defaultUserList: ListUserData[] = [];
  const missingUsersCount = userAmount - userList.length;
  const profile = {
    gender: getPhotoGender(userGender),
  };

  for (let i = 1; i <= missingUsersCount; i++) {
    const id = `default_userId_${i}`;
    defaultUserList.push({
      id,
      profile: {...profile, id},
    });
  }

  return defaultUserList.concat(userList);
};

export default addDefaultUsers;
