import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyBrandBook = lazy(
  () =>
    import(
      /* webpackChunkName: 'brandbook' */
      '@phoenix/brandbook/components/Brandbook'
    ),
);

export default (props) => <SplitChunk component={LazyBrandBook} {...props} />;
