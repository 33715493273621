import {Action, ViaEnum} from '@core/types';
import PopupService from '@core/popup/utils/PopupService';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';
import getHistory from '@core/application/utils/getHistory';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import {PaymentFlowStatus} from '@core/payment/payProcess/utils/getProcessPaymentFlow';

import openFreeTrialSuccessPaymentPopup from '@phoenix/freeFeaturePopup/utils/openFreeTrialSuccessPaymentPopup';

const showFreeTrialSuccessPaymentCompletePopup: PaymentProcessStep = async (
  data,
) => {
  const {paymentResult, paymentData} = data;
  const {method, prevVia} = paymentData;
  const {status, stockId} = paymentResult;

  if (status) {
    PopupService.closePopup();
    openFreeTrialSuccessPaymentPopup();
  } else {
    getHistory().push(
      generatePayUrl({
        stage: Action.features,
        urlParams: {
          prevVia,
          viaMethod: method,
          stockId,
          skipStepOnBack: false,
          via: ViaEnum.inpage_decline,
        },
      }),
    );

    return {...data, flowStatus: PaymentFlowStatus.ABORTED};
  }

  return data;
};

export default showFreeTrialSuccessPaymentCompletePopup;
