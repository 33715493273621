import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Text, TextMuted} from '@phoenix/typography';

const TrustedVerificationSmsSecondStepInfo = ({inPopup = false}) => {
  return inPopup ? (
    <TextMuted small align={TextMuted.ALIGN.CENTER}>
      {t('paymentTrusted', 'text.verificationCode.popup')}
    </TextMuted>
  ) : (
    <Fragment>
      <Text>{t('paymentTrusted', 'text.sms.step2.help.description')}</Text>
      <Text>
        {t('paymentTrusted', 'text.sms.step2.help.description.step1')}
      </Text>
      <Text>
        {t('paymentTrusted', 'text.sms.step2.help.description.step2')}
      </Text>
    </Fragment>
  );
};

TrustedVerificationSmsSecondStepInfo.propTypes /* remove-proptypes */ = {
  inPopup: PropTypes.bool,
};

export default TrustedVerificationSmsSecondStepInfo;
