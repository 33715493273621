import {useQuery} from '@apollo/client';

import buildFreeCoinsRewardQuery from './buildFreeCoinsRewardQuery';

/**
 * Get reward coins amount for certain purpose
 * @param {string} task (mediaUpload, relogins, profileCompletion)
 * @returns {{reward: null | number, loading: boolean, error?: ApolloError}}
 */
const useFreeCoinsReward = (task) => {
  const {data, loading, error} = useQuery(buildFreeCoinsRewardQuery(task));

  if (data?.credits.rewardForTasks.enabled) {
    return {
      reward: data.credits.rewardForTasks.tasks[task].completed
        ? null
        : data.credits.rewardForTasks.tasks[task].reward,
      loading,
      error,
    };
  }

  return {
    reward: null,
    loading,
    error,
  };
};

export default useFreeCoinsReward;
