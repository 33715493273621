import {useMemo} from 'react';
import {useQuery} from '@apollo/client';

import TARGET_USER_VIEW_PERMISSIONS_QUERY from '../graphql/queries/targetUserViewPermissions.gql';
import type {
  TargetUserViewPermissionsQuery,
  TargetUserViewPermissionsQueryVariables,
} from '../graphql/queries/targetUserViewPermissions';
import TARGET_USER_QUERY from '../graphql/queries/targetUser.gql';
import type {
  TargetUserQuery,
  TargetUserQueryVariables,
} from '../graphql/queries/targetUser';

type UseTargetUserQueryResult = {
  data?: TargetUserQuery & TargetUserViewPermissionsQuery;
  viewPermissions?: TargetUserViewPermissionsQuery['user']['viewPermissions'];
  loading: boolean;
  error?: Error;
};

/**
 * Hook for get target user data.
 * Splitting into two queries in order to preserve and not overwrite media permissions when loading the main query.
 * Media permissions can be overwritten on the client side to display a profile with available videos and photos.
 * @see showTargetUserMediaWithoutPermissions.js
 */
const useTargetUserQuery = ({
  userId,
  ...params
}: TargetUserQueryVariables): UseTargetUserQueryResult => {
  const {
    loading: targetUserLoading,
    data: targetUserData,
    error: targetUserError,
  } = useQuery<TargetUserQuery, TargetUserQueryVariables>(TARGET_USER_QUERY, {
    variables: {
      userId,
      ...params,
    },
  });

  const {
    data: viewPermissionsData,
    loading: viewPermissionsLoading,
    error: viewPermissionsError,
  } = useQuery<
    TargetUserViewPermissionsQuery,
    TargetUserViewPermissionsQueryVariables
  >(TARGET_USER_VIEW_PERMISSIONS_QUERY, {
    variables: {
      userId,
    },
  });

  const data = useMemo(() => {
    return targetUserData && viewPermissionsData
      ? {
          ...targetUserData,
          user:
            targetUserData.user && viewPermissionsData.user
              ? {...targetUserData.user, ...viewPermissionsData.user}
              : null,
        }
      : // eslint-disable-next-line no-undefined
        undefined;
  }, [targetUserData, viewPermissionsData]);

  return {
    data,
    viewPermissions: viewPermissionsData?.user?.viewPermissions,
    loading: targetUserLoading || viewPermissionsLoading,
    error: targetUserError || viewPermissionsError,
  };
};

export default useTargetUserQuery;
