import {string, object} from 'yup';

import t from '@core/translations/translate';

import {FIELD_NAMES} from '../constants/fields';

const getValidationSchema = (isAuthorized) => {
  const rules = {
    [FIELD_NAMES.FIRST_NAME]: string()
      .trim()
      .required(
        t('paymentPage', 'error.is_required', {
          '{attribute}': t('dnsmpi', 'text.firstName'),
        }),
      ),
    [FIELD_NAMES.LAST_NAME]: string()
      .trim()
      .required(
        t('paymentPage', 'error.is_required', {
          '{attribute}': t('dnsmpi', 'text.lastName'),
        }),
      ),
    [FIELD_NAMES.CITY]: string()
      .trim()
      .required(
        t('paymentPage', 'error.is_required', {
          '{attribute}': t('dnsmpi', 'text.city'),
        }),
      ),
    [FIELD_NAMES.ZIP]: string()
      .trim()
      .required(
        t('paymentPage', 'error.is_required', {
          '{attribute}': t('dnsmpi', 'text.zip'),
        }),
      ),
  };

  if (!isAuthorized) {
    rules[FIELD_NAMES.EMAIL] = string()
      .trim()
      .required(t('dnsmpi', 'error.incorrect_email'))
      .email(t('dnsmpi', 'error.incorrect_email'));

    rules[FIELD_NAMES.CAPTCHA] = string()
      .trim()
      .required(
        t('paymentPage', 'error.is_required', {
          '{attribute}': t('dnsmpi', 'text.captcha'),
        }),
      );
  }

  return object().shape(rules);
};

export default getValidationSchema;
