import React, {useState, useEffect} from 'react';

import {gotoUrl} from '@core/utils/url';

import SepaDebtorService from '@phoenix/sepaDebtor/SepaDebtorService';
import NotFound from '@phoenix/exceptions/components/NotFound';
import {Loader} from '@phoenix/ui';

/**
 * We are redirected to this route from email.
 * We need just to get invoice URL and redirect user on it
 * @see Routes.js
 */
const SepaDebtorInvoicePage = () => {
  const [error, setError] = useState(false);

  useEffect(() => {
    SepaDebtorService.getDebtorInvoiceUrl().then((invoiceUrl) => {
      if (invoiceUrl) gotoUrl(invoiceUrl);
      else setError(true);
    });
  }, [error]);

  if (error) return <NotFound />;

  return <Loader fullscreen />;
};

export default SepaDebtorInvoicePage;
