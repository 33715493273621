import React from 'react';

import {Checkbox, SpacingShortest} from '@phoenix/ui';

import css from '../styles/RemarketingToggleSubscriptionCheckbox.css';

const RemarketingToggleSubscriptionCheckbox = (props) => (
  <SpacingShortest onlyLeft className={css.checkbox}>
    <Checkbox
      trackingName="subscription"
      inline
      muted
      smallest
      withMinSize={false}
      checked
      className={css.checkbox}
      {...props}
    />
  </SpacingShortest>
);

export default RemarketingToggleSubscriptionCheckbox;
