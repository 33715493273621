import type {FC} from 'react';
import React from 'react';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';
import type PhotoSize from '@core/user/photo/constants/PhotoSize';

import {IconSize} from '@phoenix/ui/constants';

import usePmaRequestPhoto from '../utils/usePmaRequestPhoto';
import PrivatePhotoOverlayType from '../constants/PrivatePhotoOverlayType';
import type PhotoRequestData from '../types/PhotoRequestData';
import PmaRequestPhotoOverlayLayout from '../components/PmaRequestPhotoOverlayLayout';

const REQUEST_COUNT = 10;

const getOverlayProps = (overlayType: PrivatePhotoOverlayType) => {
  switch (overlayType) {
    case PrivatePhotoOverlayType.NORMAL:
      return {
        showButton: true,
        showIcon: true,
        showDescription: true,
      };
    case PrivatePhotoOverlayType.WITH_REQUEST_BUTTON:
      return {
        showRequestLayout: true,
        showButton: true,
        showIcon: true,
        showDescription: true,
        showRequestCounter: true,
      };
    case PrivatePhotoOverlayType.ICON:
      return {
        showIcon: true,
      };
    case PrivatePhotoOverlayType.DESCRIPTION:
      return {
        showDescription: true,
      };
    case PrivatePhotoOverlayType.WITH_VERIFY_AGE_DESCRIPTION:
      return {
        showVerifyAgeDescription: true,
        showIcon: true,
        showDescription: true,
      };
    case PrivatePhotoOverlayType.WITH_SMALL_ICON:
      return {
        showIcon: true,
        iconSize: IconSize.SIZE_24,
      };
    default:
      return {};
  }
};

type PmaRequestPhotoOverlayProps = {
  size?: PhotoSize;
  photoRequestData: PhotoRequestData;
};

const PmaRequestPhotoOverlay: FC<PmaRequestPhotoOverlayProps> = ({
  size,
  photoRequestData,
}) => {
  const {isRequested, overlayType = PrivatePhotoOverlayType.ICON} =
    photoRequestData;

  const isPhone = usePhoneBreakpoint();

  const {allowedRequestsCount, loading} = usePmaRequestPhoto();

  const {
    showButton,
    showIcon,
    showDescription,
    showRequestLayout,
    showVerifyAgeDescription,
    showRequestCounter,
    iconSize,
  } = getOverlayProps(overlayType);

  if (loading) {
    return null;
  }

  const canShowRequestLayout = showRequestLayout && isRequested;

  const showRequestLimitText =
    showRequestCounter && !allowedRequestsCount && !isRequested;

  const getRequestButtonText = () => {
    if (canShowRequestLayout) {
      return t('privatePhoto', 'button.go_to_chat');
    }

    const needShowCounter =
      REQUEST_COUNT > allowedRequestsCount && showRequestCounter && isPhone;

    if (needShowCounter) {
      return t('privatePhoto', 'button.request_photo_with_count', {
        '{count}': allowedRequestsCount,
      });
    }

    return t('privatePhoto', 'button.request_photo');
  };

  return (
    <PmaRequestPhotoOverlayLayout
      size={size}
      iconSize={iconSize}
      buttonText={getRequestButtonText()}
      showButton={showButton}
      showIcon={showIcon}
      showDescription={showDescription}
      showVerifyAgeDescription={showVerifyAgeDescription}
      showRequestLimitText={showRequestLimitText}
      canShowRequestLayout={canShowRequestLayout}
    />
  );
};

export default PmaRequestPhotoOverlay;
