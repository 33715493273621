import {useMemo} from 'react';
import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';
import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';
import random from 'lodash/random';

import useOrientationAndGender from '@core/user/profile/current/utils/useOrientationAndGender';
import getUsersWithCachedUrl from '@core/user/utils/getUsersWithCachedUrl';
import {RandomUsersRequestTypeEnum} from '@core/types';

import RANDOM_USERS_QUERY from '../graphql/queries/randomUsers.gql';
import addDefaultUsers from './addDefaultUsers';
import type {
  RandomUsersQuery,
  RandomUsersQueryVariables,
} from '../graphql/queries/randomUsers';
import type {ListUserData} from '../types';

// Need to show the same user that was on previous step(-s) on the payment page
let shownUser: ListUserData | null = null;

type UseCachedRandomUsersParams = {
  count: number;
  /**
   * Exclude the user by id from found cached users
   */
  excludeUserId: string | null;
  /**
   * Non-adult photo
   */
  isPrettyPhoto: boolean;
  skip: boolean;
};

const useCachedRandomUsers = ({
  count,
  excludeUserId,
  isPrettyPhoto,
  skip,
}: UseCachedRandomUsersParams): ListUserData[] => {
  return useMemo(() => {
    if (skip) return [];

    if (count === 1 && shownUser) {
      return [shownUser];
    }

    const users = getUsersWithCachedUrl({excludeUserId, isPrettyPhoto});

    const {length} = users;

    if (length) {
      if (count === 1) {
        shownUser = users[random(length - 1)];
        return [shownUser];
      }

      return take(users, count);
    }

    return [];
  }, [count, excludeUserId, isPrettyPhoto, skip]);
};

type UseRandomUsersParams = {
  withDefaultUsers?: boolean;
  skip?: boolean;
  count?: number;
  exact?: boolean;
  type?: RandomUsersRequestTypeEnum;
  excludeUserId?: string | null;
  withCachedUsers?: boolean;
};

type UseRandomUsersResult = {
  loading: boolean;
  error?: ApolloError;
  randomUsers: ListUserData[] | null;
};

const useRandomUsers = ({
  withDefaultUsers = false,
  skip = false,
  count = 1,
  exact = false,
  type = null,
  excludeUserId = null,
  withCachedUsers = false,
}: UseRandomUsersParams = {}): UseRandomUsersResult => {
  const cachedRandomUsers = useCachedRandomUsers({
    count,
    isPrettyPhoto: [
      RandomUsersRequestTypeEnum.NeoBank,
      RandomUsersRequestTypeEnum.randomPretty,
    ].includes(type),
    skip: !withCachedUsers || skip,
    excludeUserId,
  });

  const requiredCachedUsersCount = cachedRandomUsers.length >= count;
  const gender = useOrientationAndGender();
  /**
   * 'no-cache' to display different users for each usage.
   * Do not change to 'network-only'(excluding Payment page) because apollo will merge `resultingUsers`
   * and return more than you need (including previously loaded users).
   */
  const {data, error, loading} = useQuery<
    RandomUsersQuery,
    RandomUsersQueryVariables
  >(RANDOM_USERS_QUERY, {
    fetchPolicy: withCachedUsers ? 'network-only' : 'no-cache',
    skip: skip || requiredCachedUsersCount,
    variables: {
      count,
      type,
    },
  });

  const randomUsers = useMemo(() => {
    let users: ListUserData[] | null = null;

    if (requiredCachedUsersCount) {
      users = cachedRandomUsers;
    }

    const resultingUsers = data?.search?.randomUsers?.resultingUsers;

    if (resultingUsers) {
      users = take(
        uniqBy([...cachedRandomUsers, ...resultingUsers], 'id'),
        count,
      ) as ListUserData[];
    }

    if (withDefaultUsers && users) {
      return addDefaultUsers(users, gender, count);
    }

    if (exact && users?.length !== count) {
      return null;
    }

    return users;
  }, [
    cachedRandomUsers,
    requiredCachedUsersCount,
    count,
    exact,
    data,
    gender,
    withDefaultUsers,
  ]);

  return {
    loading,
    error,
    randomUsers,
  };
};

export default useRandomUsers;
