import React, {useEffect, useRef, useState, forwardRef} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {useQuery} from '@apollo/client';

import useEventCallback from '@core/utils/react/useEventCallback';

import PMA_QUERY from '../graphql/queries/pma.gql';
import PmaCheckboxLayout from '../components/PmaCheckboxLayout';
import savePmaSettings from './savePmaSettings';

export const PMA_PLACES = {
  PHOTO_UPLOAD_FUNNEL: 'photoUploadFunnel',
};

/**
 * PmaCheckboxLayout wrapper to emulate uncontrolled component.
 * TODO: add ability to use Checkbox and PmaCheckboxLayout
 *   as uncontrolled components and remove this wrapper.
 */
const PmaCheckbox = forwardRef((props, ref) => {
  /**
   * Activate the PMA if the user does not uncheck this checkbox.
   * If you need to change default checked state - use
   * \Yii::app()->SmartIcebreaker->isFunnelCheckBoxEnabled
   * And change cfg/icebreakerFunnelCheckBox.yaml
   */
  const [checked, setChecked] = useState(true);

  if (!ref.current) {
    ref.current = {};
  }
  ref.current.checked = checked;

  return (
    <PmaCheckboxLayout
      {...props}
      active={checked}
      onChange={() => setChecked(!checked)}
    />
  );
});

PmaCheckbox.propTypes /* remove-proptypes */ = {
  defaultChecked: PropTypes.bool,
};

/**
 * Checks the PMA settings and logic to determinate is need to show checkbox on specified place.
 * @param {?string} place - where pma checkbox will be placed. No place means no checkbox.
 * @param {object} props - PmaCheckboxLayout props.
 * @return {{loading: boolean, checkbox: JSX.Element}}
 */
const usePmaCheckbox = ({place, ...props}) => {
  const isEnabledForPlace = place === PMA_PLACES.PHOTO_UPLOAD_FUNNEL;

  const {data, loading, client} = useQuery(PMA_QUERY, {
    skip: !isEnabledForPlace,
  });

  const {
    enabled = false,
    activated = false,
    hideCheckbox = false,
  } = get(data, 'userFeatures.pma') || {};

  const isEnabled = !loading && enabled && isEnabledForPlace;

  const checkboxRef = useRef();

  /**
   * The hideCheckbox flag shows that we do not need to draw a checkbox,
   * but we need to send the same data as if there is a checkbox and it is marked.
   */
  const save = useEventCallback(() => {
    if (
      isEnabled &&
      ((checkboxRef.current && checkboxRef.current.checked !== activated) ||
        hideCheckbox)
    ) {
      savePmaSettings(client, {
        activated:
          checkboxRef.current?.checked ||
          (!checkboxRef.current && hideCheckbox),
      });
    }
  });

  /**
   * Save by unmount.
   * It will do nothing if checkbox has not been mounted.
   */
  useEffect(() => save, [save]);

  let checkbox = null;

  if (isEnabled) {
    if (!hideCheckbox) {
      checkbox = (
        /**
         * @todo
         * Why are we returning ReactNode instead of raw data?
         * Additionally, not only markup but a fully functional component. And
         * it's still a utility function. Utility function with a mess inside.
         */
        <PmaCheckbox {...props} ref={checkboxRef} />
      );
    }
  }

  return {loading, checkbox};
};

export default usePmaCheckbox;
