import once from 'lodash/once';

import REMARKETING_POPUP_SETTINGS_SUBSCRIPTION from '../graphql/subscriptions/remarketingPopupSettings.gql';

const startRemarketingSettingsListener = once((client) =>
  client
    .subscribe({query: REMARKETING_POPUP_SETTINGS_SUBSCRIPTION})
    .subscribe(() => {
      // It will update cache automatically, so do nothing here
    }),
);

export default startRemarketingSettingsListener;
