import {TOP_WRAPPER} from '@core/header/constants/header';

/**
 * @return {Number}
 */
const getMarginHeight = () => {
  const styles = window.getComputedStyle(document.getElementById(TOP_WRAPPER));

  return parseInt(styles.marginTop, 10) + parseInt(styles.marginBottom, 10);
};

/**
 *
 * @param {HTMLElement} topWrapper - toolbar element
 * @return {Number} = toolbar height
 */
const getTopWrapperHeight = (topWrapper) => {
  return topWrapper.clientHeight + getMarginHeight();
};

export default getTopWrapperHeight;
