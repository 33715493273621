import React from 'react';
import PropTypes from 'prop-types';

import {packageDataList as packageDataListPropType} from '@core/payment/widgets/additionalPackage/constants/propTypes';

import {Checkbox, Spacing, Group} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import {SpacingSize} from '@phoenix/ui/constants';

import FooterRow from '../../footer/components/FooterRow';
import css from '../../footer/styles/PaymentAdditionalPackage.css';

const PaymentAdditionalPackageLayout = ({
  danger = false,
  spacingSize = SpacingSize.NORMAL,
  handleCheckboxChange,
  isPhone,
  packageDataList,
  muted = true,
}) => (
  <FooterRow>
    <Group space={SpacingSize.SHORT}>
      {packageDataList.map(
        ({isChecked, additionalPackageText, additionalPackageType, ref}) => (
          <div key={additionalPackageType} className={css.wrap} ref={ref}>
            <Spacing size={spacingSize} onlyRight>
              <Checkbox
                danger={danger && !isChecked}
                onChange={(event, value, checked) =>
                  handleCheckboxChange({
                    checked,
                    additionalPackageType,
                    ref,
                  })
                }
                checked={isChecked}
                muted={muted}
                trackingName="additionalPackage"
                data-test={`additionalPackageCheckbox${additionalPackageType}`}
              />
            </Spacing>
            <TextMuted
              small
              align={isPhone ? TextMuted.ALIGN.LEFT : TextMuted.ALIGN.CENTER}
            >
              <span data-test="additionalPackageText">
                {additionalPackageText}
              </span>
            </TextMuted>
          </div>
        ),
      )}
    </Group>
  </FooterRow>
);

PaymentAdditionalPackageLayout.propTypes /* remove-proptypes */ = {
  danger: PropTypes.bool,
  spacingSize: PropTypes.oneOf(Object.values(SpacingSize)),
  isPhone: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  packageDataList: packageDataListPropType,
};

export default PaymentAdditionalPackageLayout;
