import '@core/application/polyfills'; /* Should be imported before all to work */

import React from 'react';
import {createRoot} from 'react-dom/client';

import logger from '@core/logger';

// We should run initialization of UiKit promise as soon as possible
import '@phoenix/ui';
// Should be imported before any getClientInstance() call.
import '@phoenix/graphql/utils/setupClient';

import App from './containers/App';
import css from './styles/App.css';
import createLegacyRouter from './utils/createLegacyRouter';

logger.install();

if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
  /**
   * Turn off native scroll restoration logic to avoid conflicts with custom logic.
   * We also need this to fix a Safari bug when it shows an empty area instead of the page content
   * when returning(with a reload) to a page that has been scrolled down. Reproduced on iOS 13, 14.
   * @type {string}
   */
  window.history.scrollRestoration = 'manual';
}

/**
 * Temporary solution to render App before AppView.
 * Should be removed together with AppView after all services migration to React + GraphQL.
 * @see AppView.initialize - it overrides the window.app
 * @deprecated
 */
window.app = {};

const root = document.getElementById('root');
root.className = css.root;

createRoot(root).render(<App />);

/**
 * App init timestamp. Used for detect app loading time
 * @type {number}
 */
window.appInitTimestamp = Date.now();

/**
 * @deprecated
 */
createLegacyRouter(window.app);
