const ACTION = {
  /**
   * Indicates, that we should show on frontend invitation in video chat.
   * Be carefull - all other types of action will remove notification.
   * @see StickedNotificationsWithData.js
   */
  INVITATION: 'privateVideoInvintation',

  /**
   * Access to video chat (call)
   */
  ACCEPT: 'privateVideoAcsessCall',

  /**
   * Reject video chat
   */
  REJECT: 'privateVideoRejectCall',

  /**
   * User ignored video call and it was finished (missed) by timeout
   */
  MISSED: 'privateVideoStopByTimeout',

  /**
   * Outside event, for stopping video chat
   */
  STOP: 'privateVideoStop',

  /**
   * If video chat was stopped by one of the participants leaves call
   * after call was started.
   */
  END: 'privateVideoEndCall',

  /**
   * Opponent is busy.
   */
  BUSY: 'busyResponse',
};

export default ACTION;
