import React from 'react';

import {Card} from '@phoenix/ui';

import PopupFrameBase from './PopupFrameBase';

/**
 * For avoiding circular dependency we use it as enhanced "base" component.
 * @see PopupFrameBase.js
 */
const PopupFrame = (props) => (
  <PopupFrameBase {...props} actionsWrapper={Card} />
);

export default PopupFrame;
