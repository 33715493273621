import type {FC, ReactNode} from 'react';
import React, {useMemo, useState} from 'react';
import {useApolloClient} from '@apollo/client';
import {useLocation} from 'react-router-dom';
import reduce from 'lodash/reduce';

import useEventCallback from '@core/utils/react/useEventCallback';
import logger from '@core/logger';
import trackRedirectTo from '@core/tracking/babcia/utils/trackRedirectTo';
import openLoadingWindow from '@core/utils/routing/openLoadingWindow';

import {EMPTY_ZONES, DEFAULT_ZONE} from '../constants/zones';
import type {Zone, ConsumerData} from '../types';
import type {
  CoregistrationQuery,
  CoregistrationQueryVariables,
} from '../graphql/queries/coregistration';
import COREGISTRATION_QUERY from '../graphql/queries/coregistration.gql';
import {Provider} from './CoregistrationContext';
import iframeQuery from '../utils/iframeQuery';
import {COREG_BANNER} from '../../banner/constants/supportedBannerTypes';

type State = {
  consumerData: ConsumerData;
};

type CoregistrationProviderProps = {
  children: ReactNode;
};

/**
 * Coregistration functionality provider.
 * Entire application (or part of it), where you need to render
 * fully working Coregistration components, must be wrapped with this provider.
 */
const CoregistrationProvider: FC<CoregistrationProviderProps> = ({
  children,
}) => {
  const {pathname} = useLocation();
  const client = useApolloClient();

  let updateZoneInfo: (zone: string, details: Zone) => void;

  /**
   * @param successZone - zone with success registration
   */
  let markZonesAsOutdated: (successZone?: string | null) => void;

  /**
   * `setState` analog for partial `state.consumerData` update.
   */
  let setConsumerState: (
    data:
      | Partial<ConsumerData>
      | ((data: ConsumerData) => Partial<ConsumerData>),
  ) => void;

  const [state, setState] = useState<State>(() => ({
    consumerData: {
      /**
       * @see DEFAULT_ZONE for each zone structure
       */
      zones: EMPTY_ZONES,
      registrationInProgress: false,

      loadZoneInfo: (zone) => {
        updateZoneInfo(zone, {loading: true, success: null});

        client
          .query<CoregistrationQuery, CoregistrationQueryVariables>({
            query: COREGISTRATION_QUERY,
            variables: {zone},
            // This component`s state is used instead of apollo cache to make complicated invalidation rules:
            // * zones depend on each other so we need refresh all after coregistration using one of them
            // * new random text is used each time we are visit a page(I think to track the most successful ones)
            fetchPolicy: 'no-cache',
          })
          .then(
            ({
              data: {
                userFeatures: {coregistration},
              },
            }) => {
              if (!coregistration || !coregistration.iframeUrl) {
                return {data: {userFeatures: {coregistration}}};
              }

              return new Promise((resolve, reject) => {
                if (!coregistration.iframeUrl) {
                  reject();
                  return;
                }

                iframeQuery(
                  coregistration.systemUrl,
                  coregistration.iframeUrl,
                  reduce(
                    coregistration.validatePostMessageData,
                    (result, rule) => {
                      result[rule.eventName] = result[rule.eventName] || {};
                      result[rule.eventName][rule.propertyName] = rule;
                      return result;
                    },
                    {},
                  ),
                  COREG_BANNER,
                ).then(({data: {eventData}}) => {
                  resolve({
                    data: {
                      userFeatures: {
                        coregistration: {
                          ...coregistration,
                          ...eventData,
                        },
                      },
                    },
                  });
                });
              });
            },
          )
          .then(({data}) =>
            updateZoneInfo(zone, {
              loading: false,
              outdated: false,
              zoneInfo: data.userFeatures.coregistration,
            }),
          );
      },

      clearZoneInfo: (zone) => {
        setConsumerState(({zones}) => ({
          zones: {
            ...zones,
            [zone]: DEFAULT_ZONE,
          },
        }));
      },

      register: ({zone, openInactiveTab, url}) => {
        setConsumerState({registrationInProgress: true});

        let newTab: Window;

        if (openInactiveTab) {
          // if openInactiveTab provided open in new active tab current site, and coreg site in inactive tab
          newTab = window.open(window.location.href);
        } else {
          // Can't just make 'window.open' in callback because all browsers will block this operation silently
          // So we open new window, and when AJAX finishes we redirect to required url
          newTab = openLoadingWindow();
          if (!newTab) {
            logger.sendWarning(`Couldn't open new tab for coregistration`);
            // continue registration in this case too.
          }
        }

        if (newTab) {
          trackRedirectTo({nextPathname: url});

          if (openInactiveTab) {
            window.location.href = url;
          } else {
            newTab.location.href = url;
          }
        }
        markZonesAsOutdated(zone);
      },
    },
  }));

  const resetConsumerData = useEventCallback(() => {
    // consumerData.zones === EMPTY_ZONES by default
    // consumerData.zones === EMPTY_ZONES after reset
    if (state.consumerData.zones !== EMPTY_ZONES) {
      // Reset zones(if present) on url change to fetch new texts.
      // `state` is modified by reference to avoid excessive re-render.
      state.consumerData = {...state.consumerData, zones: EMPTY_ZONES};
    }
  });

  useMemo(resetConsumerData, [resetConsumerData, pathname]);

  setConsumerState = (data) => {
    setState(({consumerData}) => ({
      consumerData: {
        ...consumerData,
        ...(typeof data === 'function' ? data(consumerData) : data),
      },
    }));
  };

  markZonesAsOutdated = (successZone = null) => {
    setConsumerState(({zones}) => {
      const nextZones: Record<string, Zone> = {};
      Object.keys(zones).forEach((zone) => {
        nextZones[zone] = {...zones[zone], outdated: true};
      });

      if (successZone && nextZones[successZone]) {
        nextZones[successZone].success = true;
      }

      return {
        zones: nextZones,
        registrationInProgress: false,
      };
    });
  };

  updateZoneInfo = (zone, details) => {
    setConsumerState(({zones}) => ({
      zones: {
        ...zones,
        [zone]: {...(zones[zone] || DEFAULT_ZONE), ...details},
      },
    }));
  };

  return <Provider value={state.consumerData}>{children}</Provider>;
};

export default CoregistrationProvider;
