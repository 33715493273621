import {useLocation} from 'react-router-dom';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import isPayUrl from '@core/utils/url/isPayUrl';
import HeaderPositionAppearance from '@core/theming/constants/features/HeaderPositionAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

const useHeaderDisplayerData = () => {
  const {pathname} = useLocation();
  const isPhone = usePhoneBreakpoint();
  const {data: themedPosition} = useThemeFeature('header', 'position');

  /**
   * @type {boolean} - if we are on the payment page we need to show notifications under the sub header,
   * on other pages we should show notifications above sub header
   */
  const isPP = isPayUrl(pathname);
  // Since on WEB environment, header attached to bottom looks strange in any case.
  const position = isPhone ? themedPosition : HeaderPositionAppearance.TOP;

  return {
    isPP,
    position,
    topWrapperHeightVariableAvailable:
      position === HeaderPositionAppearance.BOTTOM || isPP,
  };
};

export default useHeaderDisplayerData;
