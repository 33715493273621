import {getClientInstance} from '@core/graphql/client';

import SINGLE_STORY_QUERY from '../../page/graphql/queries/singleStory.gql';

/**
 * Force update cache by calling such method.
 *
 * Also we can't do cache-updates on the fly on front-end
 * since there are heavy sorting algorithms that can't be
 * implemented on front-end without any additional params.
 * So we rely only on back-end making it the only one source
 * of truth.
 *
 * @param {String} userId
 */
const updateMyStoryData = (userId) => {
  getClientInstance().query({
    query: SINGLE_STORY_QUERY,
    variables: {viewedUserId: userId},
    fetchPolicy: 'network-only',
  });
};

export default updateMyStoryData;
