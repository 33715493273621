import {getClientInstance} from '@core/graphql/client';

import removeUserFromLikeGallery from '@phoenix/likeGallery/utils/removeUserFromLikeGallery';

import openPartnerNetworkAlertPopup from './openPartnerNetworkAlertPopup';
import {DISABLED_CV_TO_USER, DISABLED_CV_FROM_USER} from '../constants/errors';
import TARGET_USER_PARTNER_NETWORK_QUERY from '../graphql/queries/targetUserPartnerNetworkInfo.gql';
import PARTNER_NETWORK_CURRENT_USER_INFO_QUERY from '../graphql/queries/currentUserPartnerNetworkInfo.gql';
import AVAILABLE_PARTNER_NETWORK_QUERY from '../graphql/queries/availablePartnerNetworkLogic.gql';

/**
 * @param error
 * @param userId
 */
const handlePartnerNetworkError = async ({error, userId}) => {
  const client = getClientInstance();
  if (error === DISABLED_CV_TO_USER) {
    const {
      data: {
        userFeatures: {
          crossVision: {available: availablePartnerNetwork = false},
        },
      },
    } = await client.query({
      query: AVAILABLE_PARTNER_NETWORK_QUERY,
    });
    if (!availablePartnerNetwork) return;

    openPartnerNetworkAlertPopup();
    /**
     * User will skipped from rendering
     * @see SearchWidgetsDisplayer.js
     */
    client.query({
      query: TARGET_USER_PARTNER_NETWORK_QUERY,
      variables: {userId},
      fetchPolicy: 'network-only',
    });
    removeUserFromLikeGallery(userId);
  } else if (error === DISABLED_CV_FROM_USER) {
    /**
     * In case current user changed partner network logic in other browser tab
     *
     * Search result and like gallery will change automatically
     * @see startSearchCacheListener.js
     * @see startSearchCacheListener.js
     */
    client.query({
      query: PARTNER_NETWORK_CURRENT_USER_INFO_QUERY,
      fetchPolicy: 'network-only',
    });
  }
};

export default handlePartnerNetworkError;
