import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import ConfirmationCoinsContent from '../containers/ConfirmationCoinsContent';
import ConfirmationContentMailNoticeLayoutPlaceholder from '../components/ConfirmationContentMailNoticeLayoutPlaceholder';
import ConfirmationImportantNote from '../components/ConfirmationImportantNote';
import ConfirmationImportantNotePlaceholder from '../components/ConfirmationImportantNotePlaceholder';
import Confirmation from '../containers/Confirmation';

/**
 * Open confirm popup for coins
 * @param {Object} params
 * @param {String} [params.userId]
 * @param {Boolean} [params.skippable]
 * @param {Function} [params.onClose]
 */
const openConfirmationCoinsPopup = ({
  userId,
  skippable = true,
  onClose,
} = {}) => {
  PopupService.openPopup(
    <Confirmation
      userId={userId}
      contentLayoutComponent={ConfirmationCoinsContent}
      contentPlaceholderComponent={
        ConfirmationContentMailNoticeLayoutPlaceholder
      }
      footerLayoutComponent={ConfirmationImportantNote}
      footerPlaceholderComponent={ConfirmationImportantNotePlaceholder}
    />,
    {
      showCloseButton: skippable,
      canCloseByEscButton: skippable,
      onClose,
      small: true,
      trackingName: skippable
        ? 'confirmationCoinsSkippable'
        : 'confirmationCoins',
    },
  );
};

export default openConfirmationCoinsPopup;
