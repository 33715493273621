import {getClientInstance} from '@core/graphql/client';

import PERSONAL_IDENTITY_QUERY from '../graphql/queries/personalIdentity.gql';

/**
 * Update personal identity status
 * @param {string} verificationStatus
 * @param {function} onPersonalIdentityChanged
 * @returns {Promise<null>}
 */
const updatePersonalIdentity = async (
  verificationStatus,
  onPersonalIdentityChanged,
) => {
  const {data} = await getClientInstance().query({
    query: PERSONAL_IDENTITY_QUERY,
  });

  if (data) {
    getClientInstance().writeQuery({
      query: PERSONAL_IDENTITY_QUERY,
      data: {
        userFeatures: {
          personalIdentity: {
            ...data.userFeatures.personalIdentity,
            verificationStatus,
          },
          __typename: 'UserFeatures',
        },
      },
    });

    onPersonalIdentityChanged && onPersonalIdentityChanged();
  }
};

export default updatePersonalIdentity;
