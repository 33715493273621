import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import pullAll from 'lodash/pullAll';
import {combineLatest, EMPTY, timer} from 'rxjs';
import {filter, map, mapTo, switchMap, tap} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import {watchLocalStorage, writeToLocalStorage} from '@core/utils/storage';
import {
  chatDeletionStream,
  deleteChat,
} from '@core/messenger/header/utils/deleteChat';

import SCHEDULER_DATA_QUERY from '../graphql/queries/schedulerData.gql';
import openMessengerWith from './openMessengerWith';

/**
 * Conversation with paid messages will be deleted
 * after this period after first view (in seconds).
 * Exported for test only.
 */
export const UPGRADE_READ_TIMER = 3600;

/**
 * Returns current timestamp in seconds.
 */
const time = () => Math.floor(Date.now() / 1000);

/**
 * Returns timestamp in seconds when record becomes expired.
 */
const createDeletionTimestamp = () => time() + UPGRADE_READ_TIMER;

/**
 * Creates service which allows to schedule something to do later. Stores state in localStorage.
 * @param query - the query to watch service dependencies.
 * @param {Function} isEnabled - is service enabled depending on `query` response data.
 *   If false - the service will automatically cleanup all scheduled records.
 * @param {string} storageKey - localStorage key for schedule. Each service should have unique `storageKey`.
 * @param {Function} getRecordTimestamp - should return timestamp of record created by `formatRecord`.
 * @param {Function} formatRecord - should create storage record based on `timestamp` and `getOrSetTimer` args.
 * @param {Function} onExpire - will be called with expired records.
 *   You should do required actions here and return `userIds` to remove from schedule.
 * @return {Object}
 */
const createScheduler = ({
  query = SCHEDULER_DATA_QUERY,
  isEnabled = (data) =>
    !data.payment.accountStatus.isPaid &&
    !data.messenger.initialData.globalFreeMessages.available,
  storageKey,
  getRecordTimestamp = (record) => record,
  formatRecord = (args) => args.timestamp,
  onExpire = async (records) => {
    await deleteChat({
      client: getClientInstance(),
      ids: Object.keys(records),
      openMessengerWith,
    });
  },
}) => {
  let schedule;

  const writeSchedule = (data) => {
    schedule = isEmpty(data) ? null : data;
    writeToLocalStorage(storageKey, schedule);
  };

  const setRecord = ({userId, ...rest}) => {
    const timestamp = createDeletionTimestamp();

    writeSchedule({
      ...schedule,
      [userId]: formatRecord({timestamp, ...rest}),
    });

    return timestamp;
  };

  /**
   * Cleans up deletion schedule for specified `userIds`.
   * @param {string[]} userIds
   */
  const clearSchedule = (userIds) => {
    return writeSchedule(omit(schedule, userIds));
  };

  const processingIds = [];

  const subscription = combineLatest(
    getClientInstance().watchQuery({query}),
    watchLocalStorage(storageKey).pipe(
      tap((storageData) => {
        schedule = storageData;
      }),
    ),
  )
    .pipe(
      switchMap(([{data}, storageData]) => {
        if (!storageData) {
          return EMPTY;
        }

        if (!isEnabled(data)) {
          writeSchedule(null);
          return EMPTY;
        }

        return timer(0, 1000).pipe(mapTo(storageData));
      }),
      map((storageData) => {
        const now = time();
        return pickBy(
          storageData,
          (value, userId) =>
            getRecordTimestamp(value) <= now && !processingIds.includes(userId),
        );
      }),
      filter((expiredRecords) => !isEmpty(expiredRecords)),
    )
    .subscribe(async (expiredRecords) => {
      const ids = Object.keys(expiredRecords);

      processingIds.push(...ids);
      try {
        const userIdsToDelete = await onExpire(expiredRecords);

        if (userIdsToDelete?.length) {
          clearSchedule(userIdsToDelete);
        }
      } finally {
        pullAll(processingIds, ids);
      }
    });

  // Cleanup schedule for contacts deleted by this service or manually
  const deletionSubscription = chatDeletionStream.subscribe(clearSchedule);

  return {
    // destroy is used for test only.
    destroy() {
      subscription.unsubscribe();
      deletionSubscription.unsubscribe();
    },

    /**
     * Cleanup all timers
     */
    reset() {
      writeSchedule(null);
    },

    /**
     * Remove timer for specific userIds
     */
    clearSchedule,

    /**
     * @param {Object} args
     * @param {string} args.userId
     * @param {string} [args.messageId]
     */
    getOrSetTimer: (args) => {
      return (
        (schedule?.[args.userId] &&
          getRecordTimestamp(schedule[args.userId])) ||
        setRecord(args)
      );
    },
  };
};

export default createScheduler;
