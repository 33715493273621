import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import ROUTES from '@core/application/constants/routes';
import useSelectedAdditionalPackages from '@core/payment/widgets/package/utils/useSelectedAdditionalPackages';
import useEventCallback from '@core/utils/react/useEventCallback';
import PackageTypes from '@core/payment/widgets/package/constants/packageTypes';
import useWalletAllowedMethods from '@core/payment/common/utils/useWalletAllowedMethods';
import t from '@core/translations/translate';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import trackPaymentClick from '@core/payment/common/utils/trackPaymentClick';
import {getPageViewTime} from '@core/payment/payProcess/utils/pageViewTime';
import PAY_BUTTON_TRACKING from '@core/payment/widgets/buttons/constants/payButtonTracking';
import getFormName from '@core/payment/payProcess/utils/getFormName';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import PAYMENT_ONECLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';

import FooterDNSMPI from '@phoenix/footer/components/FooterDNSMPI';
import trackRmPopup from '@phoenix/tracking/rmPopup/trackRmPopup';
import {Text, TextMuted} from '@phoenix/typography';

import payForRemarketingOfferFactory from '../utils/payForRemarketingOfferFactory';
import RemarketingOfferPopupActionsLayout from '../components/RemarketingOfferPopupActionsLayout';
import {
  MEMBERSHIP_RM_VIA,
  FEATURES_RM_VIA,
  REMARKETING_SHOW_POPUP,
  REMARKETING_POPUP,
} from '../constants/remarketingViaList';
import {PAY_CLICK} from '../constants/trackingName';
import PopupType from '../constants/PopupType';

const RemarketingOfferPopupActions = ({
  loading,
  altMethodProcessor,
  paymentPackage,
  returnPathAfterPay = ROUTES.SEARCH,
  footerDNSMPI,
  trackingButtonTitle,
  paymentButtonTitle,
  payButtonInfoText,
  trackingConceptName,
  checkboxChecked,
  isStrictLegalMode,
  oneClickFlow,
}) => {
  const history = useHistory();
  const {packageList} = useSelectedAdditionalPackages();
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);
  const {methods} = useWalletAllowedMethods();

  const {interval, type, discountedPrice, fullPrice, isTrialMembership} =
    paymentPackage;
  const isTypeFeatures = type === PackageTypes.FEATURES;

  const handlePayClick = useEventCallback(
    (e) => {
      e.stopPropagation();

      const {stockId, currency, permissionSetId, paymentMethod} =
        paymentPackage;

      if (loading) {
        return;
      }

      trackPaymentClick({
        action: PAYMENT_ACTIONS.POPUP_MEMBERSHIP,
        isFormHidden: true,
        isOneClickShown: true,
        isButtonActive: !isPaymentProcessing,
        isFrontendValidationPassed: true,
        paymentForm: getFormName({method: paymentMethod}),
        payButton: PAY_BUTTON_TRACKING.DEFAULT,
        timeInterval: getPageViewTime(),
      });

      if (isPaymentProcessing) {
        return;
      }

      setPaymentProcessing(true);

      trackRmPopup({
        popupId: trackingConceptName,
        action: PAY_CLICK,
        buttonTitle: trackingButtonTitle,
      });

      if (
        oneClickFlow !== PAYMENT_ONECLICK_FLOWS.SIMPLE &&
        !altMethodProcessor
      ) {
        const paymentUrl = generatePayUrl({
          stage: isTypeFeatures
            ? PAYMENT_ACTIONS.FEATURES
            : PAYMENT_ACTIONS.MEMBERSHIP,
          urlParams: {
            via: REMARKETING_SHOW_POPUP,
            returnPath: returnPathAfterPay,
            skipStepOnBack: true,
            stockId,
          },
        });

        history.push(paymentUrl);
        return;
      }

      const method = altMethodProcessor || PAYMENT_METHODS.CARD;

      /**
       * TODO ATTENTION there are a lot of fields which server already know
       * Also there are fields related with package:
       * - permissionSetId
       * - currency
       * All this field BU can get from package by stockId
       * Need talk with BU to modify code and don't send it
       */
      payForRemarketingOfferFactory({
        action: isTypeFeatures
          ? PAYMENT_ACTIONS.FEATURES
          : PAYMENT_ACTIONS.MEMBERSHIP,
        activePackage: {paymentMethod: method},
        cancelRemarketingSubscription: checkboxChecked,
        currencyCode: currency,
        hidePaymentForm: 1,
        method,
        packageId: permissionSetId,
        payFromPopup: true,
        popupType: PopupType.REMARKETING_POPUP,
        prevVia: REMARKETING_POPUP,
        // TODO Remove after Backbone will be removed, use 'urlParams' instead
        returnPath: returnPathAfterPay,
        selectedAdditionalPackages: packageList,
        stockId,
        urlParams: {
          returnPath: returnPathAfterPay,
        },
        via: isTypeFeatures ? FEATURES_RM_VIA : MEMBERSHIP_RM_VIA,
      });
    },
    [isPaymentProcessing],
  );

  const getDiscountText =
    isTypeFeatures && discountedPrice && fullPrice ? (
      <div>
        {t('remarketingPopup', 'text.feature_payment', {
          '{discountPrice}': discountedPrice,
          '{fullPrice}': (
            <TextMuted
              inline
              small
              textDecoration={Text.DECORATION.LINE_THROUGH}
            >
              {fullPrice}
            </TextMuted>
          ),
        })}
      </div>
    ) : (
      payButtonInfoText
    );

  return (
    <RemarketingOfferPopupActionsLayout
      isTrialMembership={isTrialMembership}
      paymentButtonTitle={paymentButtonTitle}
      trackingConceptName={trackingConceptName}
      walletMethods={methods}
      cancelRemarketingSubscription={checkboxChecked}
      interval={interval}
      showTitle={type === PackageTypes.MEMBERSHIP}
      isPaymentProcessing={isPaymentProcessing}
      loading={loading}
      onPayClick={handlePayClick}
      discountText={getDiscountText}
      footerDNSMPI={
        isStrictLegalMode && footerDNSMPI ? (
          <FooterDNSMPI underline muted href={footerDNSMPI} />
        ) : null
      }
    />
  );
};

RemarketingOfferPopupActions.propTypes /* remove-proptypes */ = {
  altMethodProcessor: PropTypes.string,
  paymentPackage: PropTypes.objectOf(PropTypes.any).isRequired,
  trackingButtonTitle: PropTypes.string,
  paymentButtonTitle: PropTypes.string.isRequired,
  payButtonInfoText: PropTypes.node,
  footerDNSMPI: PropTypes.string,
  trackingConceptName: PropTypes.string.isRequired,
  returnPathAfterPay: PropTypes.string,
  checkboxChecked: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  isStrictLegalMode: PropTypes.bool.isRequired,
  oneClickFlow: PropTypes.string,
};

export default RemarketingOfferPopupActions;
