import type {FC} from 'react';
import React, {useMemo} from 'react';

import StickedNotificationAppearance from '@core/theming/constants/features/StickedNotificationAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43668
 */
type ThemedStickedNotificationsProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  StickedNotificationAppearance,
  ModuleComponent<ThemedStickedNotificationsProps>
> = (value) => {
  switch (value) {
    case StickedNotificationAppearance.DEFAULT:
      return import(
        /* webpackChunkName: 'stickedNotifications' */ '@phoenix/activity/containers/StickedNotificationsDefault'
      );
    case StickedNotificationAppearance.OVER_SUBHEADER_WITH_PREVIEW:
      return import(
        /* webpackChunkName: 'stickedNotificationsOverSubheaderWithPreview' */ '@phoenix/activity/containers/StickedNotificationsOverSubheaderWithPreview'
      );
    case StickedNotificationAppearance.WITH_PAYMENT_BUTTON:
      return import(
        /* webpackChunkName: 'stickedNotificationsWithPay' */ '@phoenix/activity/containers/StickedNotificationsWithPaymentButton'
      );
    default:
      /**
       * Yes, we have "none" as a appearance param and we should render "nothing" in this case.
       */
      return null;
  }
};

/**
 * Appearance-changeable sticked notifications based on theme.
 * In fact - it displays or not it with lazy load.
 * Proxies all props and adds some module-loading dynamic.
 *
 * Custom cache name is used since the same feature is used in different place and
 * it will cause overwriting.
 * @see ThemedActivityPage.js
 */
const ThemedStickedNotifications: FC<ThemedStickedNotificationsProps> = (
  props,
) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedStickedNotificationsProps,
        StickedNotificationAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="stickedNotification"
      param="appearance"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedStickedNotifications;
