import {Category} from '@core/tracking/babcia/constants/babciaDataTypes';
import sendUBEventToBabcia from '@core/tracking/babcia/utils/sendUBEventToBabcia';
import formatTrackingName from '@core/tracking/babcia/utils/formatTrackingName';

/**
 * @param {'Load'|'Close'} event
 * @param {'Start'|'Complete'} [value]
 * @param {string} trackingName
 * @param {string} [trackingConceptId]
 * @param {string} [debugInfo]
 */
const trackPopupEvent = ({
  event,
  value,
  trackingName,
  trackingConceptId,
  debugInfo,
}) => {
  sendUBEventToBabcia({
    event,
    value,
    category: Category.POPUP,
    label: formatTrackingName(trackingName),
    conceptId: trackingConceptId,
    debugInfo,
  });
};

export default trackPopupEvent;
