/**
 * List of all available microfeatures
 *
 * To add new microfeature:
 * 1. Add new name to MICROFEATURES list (@see constants/microfeatures.js)
 * 2. Add translations (@see utils/getTranslationByType.js)
 * 3. Add icon with animation (@see icons/constants/iconComponents.js and icons/constants/icons.js)
 * 4. Add success popup animation (@see popups/constants/animationComponents.js)
 */
export const MICROFEATURES = {
  // incognito browse of other users
  MICRO_INCOGNITO: 'microIncognito',
  // higher position in like gallery (but not in search)
  MORE_LIKES: 'moreLikes',
  // higher position in search (but not in like gallery)
  PRIORITY_MAN: 'priorityMan',
  // female version of 'priorityMan'
  SEARCH_QUEEN: 'searchQueen',
  // higher position in search and in like gallery
  SUPER_BOOST: 'superBoost',
  // mass message sending from current user
  FLIRT_ALARM: 'flirtAlarm',
  // 'flirtAlarm' with adult messages for not clean traffic sources
  SEX_ALARM: 'sexAlarm',
  // messages from current user are displayed on first position in recipients list
  TOP_IN_CHAT: 'topInChat',
  // 5 features in bundle
  TOP_DATER: 'topDater',
  // 3 features in bundle
  PRIME_DATER: 'primeDater',
  // telegram communication
  TELEGRAM_CONVERSATION: 'telegramConversation',
  // special offer (all features by one price)
  MICROFEATURE_OFFER: 'microfeatureOffer',
};

/**
 * Translations placements list
 */
export const TRANSLATION_TYPES = {
  BANNER_TITLE: 'bannerTitle',
  BANNER_DESCRIPTION: 'bannerDescription',
  POPUP_TITLE: 'popupTitle',
  POPUP_DESCRIPTION: 'popupDescription',
  POPUP_ACTION: 'popupAction',
  SUCCESS_TITLE: 'successTitle',
  SUCCESS_ACTION: 'successAction',
  SUCCESS_DESCRIPTION: 'successDescription',
  SPECIAL_OFFER_TITLE: 'specialOfferTitle',
};

/**
 * Microfeature can be active for one hour or one day (microIncognito)
 */
export const PERIOD = {
  DAY: 'DAY',
  HOUR: 'HOUR',
  UNLIMITED: 'UNLIMITED',
};
