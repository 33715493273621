import type {FC} from 'react';
import React, {useMemo} from 'react';

import ChatPageAppearance from '@core/theming/constants/features/ChatPageAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43645
 */
type ThemedChatPageProps = {
  [key: string]: any;
};

export const resolver: ModuleResolver<
  ChatPageAppearance,
  ModuleComponent<ThemedChatPageProps>
> = (value) => {
  switch (value) {
    case ChatPageAppearance.WITH_TOOLBAR:
      return import(
        /* webpackChunkName: 'ChatPageWithToolbar' */ '@phoenix/messenger/common/containers/ChatPageWithToolbar'
      );
    case ChatPageAppearance.WITH_TOOLBAR_AND_USER_ACTIONS:
      return import(
        /* webpackChunkName: 'ChatPageWithToolbarAndUserActions' */ '@phoenix/messenger/common/containers/ChatPageWithToolbarAndUserActions'
      );
    case ChatPageAppearance.LAYERED:
      return import(
        /* webpackChunkName: 'chatPageLayered' */ '@phoenix/messenger/common/containers/ChatPageLayered'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable chat page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedChatPage: FC<ThemedChatPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<ThemedChatPageProps, ChatPageAppearance>(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="chat"
      param="page"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedChatPage;
