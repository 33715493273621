import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import getPriceWithCurrency from '@core/payment/common/utils/getPriceWithCurrency';

import {Bar, Spacing} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';

import {PERIOD} from '../../common/constants/microfeatures';
import css from '../styles/MicrofeaturesPaymentPopupFooterLayout.css';

const MicrofeaturesPaymentPopupFooterLayout = ({
  amount,
  period,
  currency,
  coins,
}) => {
  const price = coins
    ? t('microfeatures', 'text.coins', {'{value}': coins})
    : getPriceWithCurrency(amount, currency);

  const translationOptions = {
    '{price}': price,
    '{n}': period === PERIOD.DAY ? 24 : 1,
  };

  return (
    <Bar withoutTop>
      <Spacing>
        <TextMuted small align={Align.CENTER}>
          {coins ? (
            t('microfeatures', 'text.coins_costs_refill', translationOptions)
          ) : (
            <Fragment>
              {period === PERIOD.UNLIMITED
                ? t('microfeatures', 'text.feature_costs_unlim', {
                    '{price}': price,
                  })
                : t('microfeatures', 'text.feature_costs', translationOptions)}
              <span className={css.subText}>
                {t('microfeatures', 'text.payment_will_be_charged')}
              </span>
            </Fragment>
          )}
        </TextMuted>
      </Spacing>
    </Bar>
  );
};

MicrofeaturesPaymentPopupFooterLayout.propTypes /* remove-proptypes */ = {
  amount: PropTypes.string,
  period: PropTypes.oneOf(Object.values(PERIOD)),
  currency: PropTypes.shape({
    suffix: PropTypes.string,
    prefix: PropTypes.string,
  }),
  coins: PropTypes.number,
};

export default MicrofeaturesPaymentPopupFooterLayout;
