/* global DEV_TOOLS_ENABLED */

import React, {useMemo, useEffect, useLayoutEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import PopupLoadTrackingContext from '@core/popup/containers/PopupLoadTrackingContext';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import useIsPopupOpenAndAnimatedVar from '@core/popup/utils/useIsPopupOpenAndAnimatedVar';
import {
  Event,
  LoadProgress,
} from '@core/tracking/babcia/constants/babciaDataTypes';

import trackPopupEvent from '../utils/trackPopupEvent';

const noop = () => {};

/**
 * Is used to track popup load completion.
 * Logs error if popup closed without completion tracking.
 * Each popup wrapped with this provider.
 */
const PopupLoadTrackingProvider = ({
  skip,
  autoLoadTracking,
  trackingName,
  trackingConceptId,
  children,
}) => {
  const isPopupOpenAndAnimated = useIsPopupOpenAndAnimatedVar();
  const isPopupOpenAndAnimatedRef = useRef(isPopupOpenAndAnimated);

  isPopupOpenAndAnimatedRef.current = isPopupOpenAndAnimated;

  const state = useMemo(() => {
    if (skip) {
      return {trackLoadComplete: noop};
    }

    const self = {tracked: false};

    self.trackLoadComplete = (debugInfo) => {
      if (!isPopupOpenAndAnimatedRef.current) {
        /**
         * In some cases, 'trackLoadComplete' may be called before the popup animation has finished.
         * So, such cases trackLoadComplete should delayed until the animation has finished.
         *
         * @see GalleryDisplayer.js
         */
        self.delayedTrack = true;

        return;
      }

      if (self.tracked) {
        return;
      }

      self.tracked = true;
      self.delayedTrack = false;

      trackPopupEvent({
        debugInfo,
        event: Event.LOAD,
        value: LoadProgress.COMPLETE,
        trackingName,
        trackingConceptId,
      });
    };

    return self;
  }, [skip, trackingName, trackingConceptId]);

  useEffect(() => {
    if (autoLoadTracking) {
      state.trackLoadComplete('(Auto)');
    }
  }, [autoLoadTracking, state]);

  useEffect(() => {
    if (isPopupOpenAndAnimated && state.delayedTrack) {
      state.trackLoadComplete();
    }
  }, [isPopupOpenAndAnimated, state]);

  useLayoutEffect(() => {
    if (skip) return;

    trackPopupEvent({
      event: Event.LOAD,
      value: LoadProgress.START,
      trackingName,
      trackingConceptId,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      trackPopupEvent({
        event: Event.CLOSE,
        trackingName,
        trackingConceptId,
      });

      // Warn about probably missing completion tracking for dev and rel environment.
      if (DEV_TOOLS_ENABLED && !state.tracked) {
        console.error(
          `[PopupLoadTrackingProvider] Probably missing completion tracking for '${trackingName}' popup. ` +
            'Add `<PopupLoadTracker />` at the moment when popup content completely loaded ' +
            'or use `autoLoadTracking: true` option if popup content instantly visible ' +
            'without loading additional chunks or data.',
        );
      }
    };
  }, [skip, trackingName, trackingConceptId, state]);

  return (
    <PopupLoadTrackingContext.Provider value={state.trackLoadComplete}>
      <BabciaScopedProvider skip={skip || !isPopupOpenAndAnimated}>
        {children}
      </BabciaScopedProvider>
    </PopupLoadTrackingContext.Provider>
  );
};

PopupLoadTrackingProvider.propTypes /* remove-proptypes */ = {
  /**
   * Skip tracking (useful for hidden popups).
   */
  skip: PropTypes.bool,
  /**
   * Automatic 'Load Complete' tracking.
   * Pass `autoLoadTracking: true` option to `PopupService.openPopup` if popup content is static (without loading).
   * Otherwise you shouldn't use this option and
   * should render `<PopupLoadTracker />` inside popup content when loading complete.
   */
  autoLoadTracking: PropTypes.bool,
  trackingName: PropTypes.string.isRequired,
  trackingConceptId: PropTypes.string,
  children: PropTypes.node,
};

export default PopupLoadTrackingProvider;
