import React from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import t from '@core/translations/translate';
import useOneClick from '@core/payment/forms/card/utils/useOneClick';
import PaymentOneClickFlows from '@core/payment/common/constants/paymentOneclickFlows';

import {TextMuted} from '@phoenix/typography';
import {Align} from '@phoenix/ui/constants';

import FooterRow from '../../../widgets/footer/components/FooterRow';

const PaymentCardFormWillChargedInfoInner = ({align, small}) => {
  const {isOneClickUsed, oneClickFlow, loading} = useOneClick();

  if (
    loading ||
    !isOneClickUsed ||
    oneClickFlow !== PaymentOneClickFlows.SIMPLE
  ) {
    return null;
  }

  return (
    <FooterRow>
      <TextMuted small={small} align={align}>
        {t('paymentPage', 'text.You_will_be_charged_in_one_click')}
      </TextMuted>
    </FooterRow>
  );
};

PaymentCardFormWillChargedInfoInner.propTypes /* remove-proptypes */ = {
  align: PropTypes.string,
  small: PropTypes.bool,
};

const PaymentCardFormWillChargedInfo = ({
  oneClickUsed,
  small = true,
  align = Align.CENTER,
}) => {
  if (isNil(oneClickUsed)) {
    return <PaymentCardFormWillChargedInfoInner align={align} small={small} />;
  }

  return (
    <FooterRow>
      <TextMuted small={small} align={align}>
        {t('paymentPage', 'text.You_will_be_charged_in_one_click')}
      </TextMuted>
    </FooterRow>
  );
};

PaymentCardFormWillChargedInfo.propTypes /* remove-proptypes */ = {
  oneClickUsed: PropTypes.bool,
  align: PropTypes.string,
  small: PropTypes.bool,
};

export default PaymentCardFormWillChargedInfo;
