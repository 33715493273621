import React from 'react';
import PropTypes from 'prop-types';

import NavigationButton from '@phoenix/widgets/navigation/NavigationButton';
import {Direction} from '@phoenix/ui/constants';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

const GalleryNavigationButton = ({direction, overlay, onClick}) => {
  const {data: arrowsPosition} = useThemeFeature('gallery', 'arrows');

  return (
    <NavigationButton
      direction={direction}
      inverse
      overlay={overlay}
      onClick={onClick}
      arrowsPosition={arrowsPosition}
    />
  );
};

GalleryNavigationButton.propTypes /* remove-proptypes */ = {
  direction: PropTypes.oneOf(Object.values(Direction)).isRequired,
  onClick: PropTypes.func.isRequired,
  overlay: PropTypes.bool.isRequired,
};

export default GalleryNavigationButton;
