import logger from '@core/logger';
import t from '@core/translations/translate';

import CreditsCurrency from '../constants/CreditsCurrency';

/**
 * Return text for button pay
 * @param {string} currency
 * @returns {null|*|[]}
 */
const getBalanceButtonText = (currency) => {
  switch (currency) {
    case CreditsCurrency.COINS:
      return t('coins', 'button.pay');
    default:
      logger.sendWarning(
        `LOST TRANSLATE: (feature: BuyBalance:ButtonPay, currency: ${currency})`,
      );
  }

  return null;
};

export default getBalanceButtonText;
