import {useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@core/utils/storage';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';

const RELOADED_PAGE_KEY = 'reloadedPage';
const isNeedToCheck =
  getUserAgentParser().getOS().name === 'iOS' &&
  getBootstrapParam(IN_APP_NAME) === InAppBrowserName.FACEBOOK;

const useFacebookReload = (): boolean => {
  const {search} = useLocation();

  const shouldReload = useMemo(() => {
    if (!isNeedToCheck) {
      return false;
    }

    const isFragmentBrowserExist = search.includes('fragmentBrowser');

    return !readFromSessionStorage(RELOADED_PAGE_KEY) && isFragmentBrowserExist;
  }, [search]);

  /**
   * Due to a bug in Facebook's iOS app, we are unable to switch to the default browser via the three-dot menu because
   * the in-app browser remembers the initial url during registration (e.g., /user/register) and does not respond
   * to changes in the url within the in-app environment. To update the url, we perform a one-time page reload,
   * which sets the url to the correct one at the time of the reload.
   */
  useEffect(() => {
    if (shouldReload) {
      writeToSessionStorage(RELOADED_PAGE_KEY, 'true');
      window.location.reload();
    }
  }, [shouldReload]);

  return shouldReload;
};

export default useFacebookReload;
