import React, {Fragment, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import PopupService from '@core/popup/utils/PopupService';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import GDPR from '@phoenix/gdpr/GDPR';
import {Row, Spacing, SpacingLarge} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import {FIRST_STEP_FIELD_NAMES} from '../contants/fields';
import TrustedVerificationSmsFirstStep from './TrustedVerificationSmsFirstStep';
import TrustedVerificationSmsFirstStepLayoutAction from '../components/TrustedVerificationSmsFirstStepLayoutAction';
import TrustedVerificationPopupHeader from '../components/TrustedVerificationPopupHeader';
import openTrustedVerificationExceedStepPopup from '../utils/openTrustedVerificationExceedStepPopup';
import openTrustedVerificationSecondStepPopup from '../utils/openTrustedVerificationSecondStepPopup';

/**
 * @const {String}
 */
const {FIRST_NAME, LAST_NAME, PHONE, LANGUAGE, COUNTRY, COUNTRY_PHONE_CODE} =
  FIRST_STEP_FIELD_NAMES;

const SPACE_GAP_PHONE = 80;
const SPACE_GAPE_WEB = 100;

/**
 * Component for allowing you to call the support
 */
const TrustedVerificationPopup = ({openTrustedVerificationPopup}) => {
  const {data: spacedMotivation, loading} = useThemeFeature(
    'trustedVerificationPopup',
    'spacedMotivation',
  );
  const isPhone = usePhoneBreakpoint();
  /**
   * Need for getting correct value of height of first step
   * for loading gdpr and hiding under scroll
   */
  const [firstStepLoaded, setFirstStepLoaded] = useState(false);
  const contentRef = useRef();
  const spacingGap = isPhone ? SPACE_GAP_PHONE : SPACE_GAPE_WEB;
  // referenceId needed for next step
  const userPersonalData = useRef({
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [PHONE]: '',
    [LANGUAGE]: '',
    [COUNTRY]: '',
    [COUNTRY_PHONE_CODE]: '',
  });

  if (loading) {
    return null;
  }

  return (
    <PopupFrame
      spacedContent={false}
      forceFixedActions
      style={{
        maxHeight: firstStepLoaded
          ? `${contentRef.current.clientHeight + spacingGap}px`
          : 'none',
      }}
      content={
        <Fragment>
          <div ref={contentRef}>
            <TrustedVerificationPopupHeader
              spacedMotivation={spacedMotivation}
              isPhone={isPhone}
            />
            <SpacingLarge withoutBottom>
              <TrustedVerificationSmsFirstStep
                inPopup
                setFirstStepLoaded={setFirstStepLoaded}
                userPersonalData={userPersonalData}
                onNextStep={(referenceId) => {
                  PopupService.closePopup();
                  openTrustedVerificationSecondStepPopup({
                    referenceId,
                    handleChangePhone: openTrustedVerificationPopup,
                  });
                }}
                onError={() => {
                  PopupService.closePopup();
                  openTrustedVerificationExceedStepPopup();
                }}
              />
            </SpacingLarge>
          </div>
          {firstStepLoaded && (
            <SpacingLarge withoutBottom withoutTop>
              <Spacing onlyTop>
                <GDPR muted small />
              </Spacing>
            </SpacingLarge>
          )}
        </Fragment>
      }
      actions={
        <Row align={Align.CENTER}>
          <PopupLoadTracker>
            <TrustedVerificationSmsFirstStepLayoutAction />
          </PopupLoadTracker>
        </Row>
      }
    />
  );
};

TrustedVerificationPopup.propTypes /* remove-proptypes */ = {
  openTrustedVerificationPopup: PropTypes.func.isRequired,
};

export default TrustedVerificationPopup;
