/**
 * Popup types
 * @const
 */
const POPUP_TYPES = {
  NATIVE: 'native',
  CUSTOM: 'custom',
  DATEINFORM: 'dateinform',
};

/**
 * The main track actions
 * @const
 */
const ACTIONS = {
  PROPOSED: 'proposed',
  DENIED: 'denied',
  ACCEPTED: 'accepted',
  CLOSED: 'closed',
  CONFLICTED: 'conflicted',
  BLOCKED: 'blocked',
  SILENT: 'silent',
};

/**
 * Logic types
 * @const
 */
const LOGIC_TYPES = {
  MAIN: 'main',
  ALTER: 'alter',
};

export default {
  POPUP_TYPES,
  ACTIONS,
  LOGIC_TYPES,
};
