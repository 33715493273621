import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {getClientInstance} from '@core/graphql/client';
import PAYMENT_MODEL_QUERY from '@core/payment/common/graphql/queries/paymentModel.gql';
import PAYMENT_MODELS from '@core/payment/common/constants/paymentModels';
import type {PaymentModelQuery} from '@core/payment/common/graphql/queries/paymentModel';
import parsePathname from '@core/utils/url/parsePathname';
import routeVar from '@core/graphql/vars/routeVar';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import isPayUrl from '@core/utils/url/isPayUrl';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

import processCoinsSuccess from '@phoenix/coins/utils/processCoinsSuccess';

/**
 * Packages actions that can be bought once, if buy package and return again to this route - need redirect to success page
 * @type {string[]}
 */
const canBoughtOnceActions = [PAYMENT_ACTIONS.MEMBERSHIP];

/**
 * Hook for check when need redirect to success page.
 */
const useRedirectToSuccessPage = () => {
  const location = useLocation();

  return useMemo(() => {
    const prevRoute = routeVar().previous;

    /**
     * Prevent loop redirect if previous or current page is success page
     */
    if (isPaySuccessUrl(location.pathname) || isPaySuccessUrl(prevRoute)) {
      return null;
    }

    const isPaymentPage = isPayUrl(location.pathname);

    const {
      action,
      options: {
        viaMethod = null,
        returnPath = null,
        via = null,
        successReturnPath = null,
      } = {},
    } = parsePathname(location);

    /**
     * If user has bought a package and go to next payment step, then click the native browser back button
     */
    const hasPurchasedPackage =
      isPaymentPage &&
      canBoughtOnceActions.includes(action) &&
      PaymentPageSuccessOrders.get().find((order) => order.via === action);

    if (hasPurchasedPackage) {
      return generatePayUrl({
        stage: PAYMENT_ACTIONS.SUCCESS,
        urlParams: {via, viaMethod, returnPath, successReturnPath},
      });
    }

    /**
     * If the user has purchased a package and then leave payment page
     * by pressing the browser's native back button, but does not see the success page
     */
    const hasNotViewedOrders =
      !isPaymentPage &&
      isPayUrl(routeVar().previous) &&
      PaymentPageSuccessOrders.hasNotViewedOrders();

    if (hasNotViewedOrders) {
      const data = getClientInstance().readQuery<PaymentModelQuery>({
        query: PAYMENT_MODEL_QUERY,
      });

      if (data?.payment?.model === PAYMENT_MODELS.COINS) {
        processCoinsSuccess();
        return null;
      }

      const {
        options: {returnPath: prevReturnPath},
      } = parsePathname(prevRoute);

      return generatePayUrl({
        stage: PAYMENT_ACTIONS.SUCCESS,
        urlParams: {
          via: PaymentPageSuccessOrders.getNotViewedOrderVia(),
          returnPath: prevReturnPath,
        },
      });
    }

    return null;
  }, [location]);
};

export default useRedirectToSuccessPage;
