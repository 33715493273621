import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyFunnelQuiz = lazy(
  () =>
    import(
      /* webpackChunkName: 'funnelQuiz' */ '@phoenix/funnelQuiz/containers/FunnelQuizPage'
    ),
);

export default (props) => <SplitChunk component={LazyFunnelQuiz} {...props} />;
