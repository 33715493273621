import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import isMobileViewport from '@core/responsive/isMobileViewport';
import PopupPriority from '@core/popup/constants/PopupPriority';

import updateSearchLimit from '@phoenix/search/list/utils/updateSearchLimit';

import EXPAND_SEARCH_LIMITS_QUERY from './graphql/queries/expandSearchLimits.gql';
import ExpandSearchLimitsPopupLayout from './components/ExpandSearchLimitsPopupLayout';
import getTimeLeft from '../../utils/getTimeLeft';

export const USERS_COUNT = 6;

// Export for tests
export const handleUpdate = () => PopupService.closePopup();

/**
 * @param processId
 * @param client
 * @returns {Promise<void>}
 */
const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {
        checkExpandSearchLimit: {enabled, limit, expireAt, users},
      },
    },
  } = await client.query({
    query: EXPAND_SEARCH_LIMITS_QUERY,
    variables: {processId, usersCount: USERS_COUNT},
  });

  if (!enabled) return;

  const time = getTimeLeft(expireAt);

  updateSearchLimit(limit);

  const isMobile = isMobileViewport();

  PopupService.openPopup(
    <ExpandSearchLimitsPopupLayout
      users={users}
      duration={time}
      onLaterClick={handleUpdate}
      onTimeOver={handleUpdate}
    />,
    {
      small: true,
      inverseCloseButton: isMobile,
      flatCoverCloseButton: isMobile,
      priority: PopupPriority.LOW,
      trackingName: 'expandSearchLimits',
      autoLoadTracking: true,
    },
  );
};

export default action;
