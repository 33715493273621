import createTranslationsMap from '@core/translations/createTranslationsMap';

import {MICROFEATURES} from './microfeatures';

export const BANNER_TITLE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t('microfeatures', 'title.microIncognito'),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'title.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t('microfeatures', 'title.priorityMan'),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'title.priorityMan.heteroFemale',
    ),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'title.superBoost'),
    [MICROFEATURES.FLIRT_ALARM]: t('microfeatures', 'title.flirtAlarm'),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'title.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'title.topInChat'),
    [MICROFEATURES.MICROFEATURE_OFFER]: t(
      'microfeatures',
      'title.microfeatureOffer',
    ),
  }));
export const BANNER_TITLE_ADULT = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'title.microIncognito.adult',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'title.moreLikes.adult'),
    [MICROFEATURES.PRIORITY_MAN]: t('microfeatures', 'title.priorityMan.adult'),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'title.superBoost.adult'),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'title.topInChat.adult'),
  }));
export const BANNER_TITLE_GAY = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'title.microIncognito.gay',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'title.moreLikes.gay'),
    [MICROFEATURES.PRIORITY_MAN]: t('microfeatures', 'title.priorityMan.gay'),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'title.superBoost.gay'),
    [MICROFEATURES.FLIRT_ALARM]: t('microfeatures', 'title.flirtAlarm.gay'),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'title.sexAlarm.gay'),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'title.topInChat.gay'),
  }));
export const BANNER_TITLE_LESBIAN = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'title.priorityMan.lesbian',
    ),
  }));

export const BANNER_DESCRIPTION = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'banner.desription.microIncognito',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'banner.desription.moreLikes',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'banner.desription.priorityMan',
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'banner.desription.priorityMan.heteroFemale',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'banner.desription.superBoost',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'banner.desription.flirtAlarm',
    ),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'banner.desription.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'banner.desription.topInChat',
    ),
  }));
export const BANNER_DESCRIPTION_LESBIAN = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'banner.desription.priorityMan',
    ),
  }));
export const BANNER_DESCRIPTION_ADULT = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'banner.desription.microIncognito.adult',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'banner.desription.moreLikes.adult',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'banner.desription.priorityMan.adult',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'banner.desription.superBoost.adult',
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'banner.desription.topInChat.adult',
    ),
  }));
export const BANNER_DESCRIPTION_GAY = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'banner.desription.microIncognito.gay',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'banner.desription.moreLikes.gay',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'banner.desription.priorityMan.gay',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'banner.desription.superBoost.gay',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'banner.desription.flirtAlarm.gay',
    ),
    [MICROFEATURES.SEX_ALARM]: t(
      'microfeatures',
      'banner.desription.sexAlarm.gay',
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'banner.desription.topInChat.gay',
    ),
  }));
export const BANNER_DESCRIPTION_FEMALE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'banner.desription.microIncognito.heteroFemale',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'banner.desription.priorityMan.heteroFemale',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'banner.desription.flirtAlarm.heteroFemale',
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'banner.desription.topInChat.heteroFemale',
    ),
  }));
