import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {RowLarge} from '@phoenix/ui';

import css from '../styles/WebcamWrapper.css';

const WebcamWrapper = forwardRef(({children, className, ...props}, ref) => (
  <RowLarge>
    <div
      ref={ref}
      className={cn(css.container, className)}
      data-test="webcamWrapper"
      {...props}
    >
      {children}
    </div>
  </RowLarge>
));

WebcamWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default WebcamWrapper;
