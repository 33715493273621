import startPageLoadObserver from '@core/tracking/babcia/utils/startPageLoadObserver';
import {
  Event,
  LoadProgress,
} from '@core/tracking/babcia/constants/babciaDataTypes';
import sendUBEventToBabcia from '@core/tracking/babcia/utils/sendUBEventToBabcia';

import initPageVisibilityListeners from './initPageVisibilityListeners';
import initHistorySubscription from './initHistorySubscription';

if (window.FRONTEND_CONFIG?.UBTrackingEnabled) {
  sendUBEventToBabcia({
    event: Event.BOOTSTRAP,
    value: LoadProgress.START,
    timestamp: Date.now() - Math.round(performance.now()),
  });

  startPageLoadObserver();
  initHistorySubscription();
  initPageVisibilityListeners();
}
