import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';

import type {InteractionMessageTrustedStatusSubscription} from '../graphql/subscriptions/trustedStatus';
import TRUSTED_STATUS_SUBSCRIPTION from '../graphql/subscriptions/trustedStatus.gql';

const getTrustedStatusSubscription = once(() =>
  getClientInstance().subscribe<InteractionMessageTrustedStatusSubscription>({
    query: TRUSTED_STATUS_SUBSCRIPTION,
  }),
);

export default getTrustedStatusSubscription;
