import React from 'react';

import CommonCaptcha from '@core/form/captcha';

import CaptchaLayout from '@phoenix/massBlocked/components/CaptchaLayout';

const FormCaptcha = (props) => (
  <CommonCaptcha {...props} captchaLayout={CaptchaLayout} />
);

export default FormCaptcha;
