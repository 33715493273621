import type {FC} from 'react';
import React, {useMemo} from 'react';

import usePaymentBottomNotice from '@core/payment/banners/bottomNotice/utils/usePaymentBottomNotice';

import usePersonalIdentityData from '@phoenix/personalIdentity/utils/usePersonalIdentityData';

import PaymentBottomNoticeLayout from '../components/PaymentBottomNoticeLayout';
import getBottomNoticeTranslations from '../utils/getBottomNoticeTranslations';

const PaymentBottomNoticeDisplayer: FC = () => {
  const noticeData = usePaymentBottomNotice();

  /**
   * We don't need data from such logic directly, but it is used above inside
   * "PersonalIdentityService", that uses data directly from cache. Bad practice,
   * but still working.
   */
  const {loading} = usePersonalIdentityData();

  const translations = useMemo(() => {
    if (!noticeData || loading) return null;
    return getBottomNoticeTranslations(noticeData.kind, noticeData.siteName);
  }, [noticeData, loading]);

  if (!noticeData || !translations || loading) return null;

  return (
    <PaymentBottomNoticeLayout
      title={translations.title}
      description={translations.description}
      action={translations.action}
      {...noticeData}
    />
  );
};

export default PaymentBottomNoticeDisplayer;
