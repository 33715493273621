import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import PLACES from '@core/user/actions/constants/Place';
import GalleryActionsAppearance from '@core/theming/constants/features/GalleryActionsAppearance';

import useGetWebcamProfileData from '@phoenix/user/profile/target/utils/useGetWebcamProfileData';
import ACTIONS from '@phoenix/user/actions/constants/actions';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import useCoinsUserActionsForPlace from '@phoenix/coins/utils/useCoinsUserActionsForPlace';
import {SpacingDirection, SpacingSize} from '@phoenix/ui/constants';

import useActionsWithUserFeatures from '../../../actions/utils/useActionsWithUserFeatures';
import TargetBottomFixedUserActionsLayout from '../components/TargetBottomFixedUserActionsLayout';

const useActionsForTargetGalleryUser = ({
  isModerator,
  isAdmin,
  isVirtualAssistant,
  stickedActionsOnBottom,
  isWebcamProfile,
  place,
}) => {
  const {data: appearance} = useThemeFeature('gallery', 'appearance');
  const isRoundActions =
    GalleryActionsAppearance.WITH_ROUND_ACTIONS === appearance ||
    GalleryActionsAppearance.WITH_ROUND_REVERSED_ACTIONS === appearance;
  const reverse = place === PLACES.TARGET_USER_INFO_BOTTOM;

  const coinsActions = useCoinsUserActionsForPlace(PLACES.USER_INFO);

  const defActions = useMemo(() => {
    if (appearance === GalleryActionsAppearance.NONE) {
      return [];
    }

    if (reverse && isWebcamProfile) {
      return [ACTIONS.WEBCAM, ACTIONS.CHAT];
    }
    if (isWebcamProfile) {
      return [ACTIONS.CHAT, ACTIONS.WEBCAM];
    }
    if (isAdmin) {
      return [ACTIONS.CONTACT_US];
    }
    if (isVirtualAssistant && !window.FRONTEND_CONFIG?.VASplitSessionsEnabled) {
      return [ACTIONS.CHAT];
    }
    if (isModerator) {
      return [ACTIONS.CHAT, ACTIONS.FAVORITE];
    }

    if (stickedActionsOnBottom) {
      return [ACTIONS.CHAT, ACTIONS.LIKE];
    }

    if (coinsActions) {
      return coinsActions;
    }

    return isRoundActions
      ? [ACTIONS.LIKE, ACTIONS.CHAT]
      : [ACTIONS.CHAT, ACTIONS.LIKE, ACTIONS.FAVORITE];
  }, [
    appearance,
    reverse,
    isWebcamProfile,
    isAdmin,
    isVirtualAssistant,
    isModerator,
    stickedActionsOnBottom,
    coinsActions,
    isRoundActions,
  ]);

  const {actions, loading} = useActionsWithUserFeatures(defActions);

  return {actions, loading};
};

const DEFAULT_ACTIONS_TYPES = {};
const DEFAULT_WRAPPER = ({children}) => children;

const TargetBottomFixedUserActions = memo(
  ({
    userId,
    place = PLACES.GALLERY,
    stickedActionsOnBottom,
    actions,
    actionsTypes = DEFAULT_ACTIONS_TYPES,
    transition = true,
    spacingSize,
    spacingDirection,
    wrapper: Wrapper = DEFAULT_WRAPPER,
  }) => {
    const {partnerName} = useParams();
    const {
      loading,
      error,
      isWebcamProfile,
      isModerator,
      isAdmin,
      isVirtualAssistant,
      webcamPlacements,
    } = useGetWebcamProfileData(userId, partnerName);

    const {actions: defaultActions, loading: actionsLoading} =
      useActionsForTargetGalleryUser({
        isWebcamProfile,
        isModerator,
        isAdmin,
        isVirtualAssistant,
        stickedActionsOnBottom,
        place,
      });

    if (loading || actionsLoading || error) return null;

    return (
      <Wrapper>
        <TargetBottomFixedUserActionsLayout
          userId={userId}
          place={place}
          actions={actions || defaultActions}
          actionsTypes={actionsTypes}
          webcamPlacements={webcamPlacements}
          transition={transition}
          spacingSize={spacingSize}
          spacingDirection={spacingDirection}
          fullSize
        />
      </Wrapper>
    );
  },
);

TargetBottomFixedUserActions.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string.isRequired,
  place: PropTypes.oneOf(Object.values(PLACES)),
  stickedActionsOnBottom: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTIONS))),
  actionsTypes: PropTypes.objectOf(PropTypes.string),
  transition: PropTypes.bool,
  spacingSize: PropTypes.oneOf(Object.values(SpacingSize)),
  spacingDirection: PropTypes.oneOf(Object.values(SpacingDirection)),
  wrapper: PropTypes.func,
};

export default TargetBottomFixedUserActions;
