import React, {lazy} from 'react';
import PropTypes from 'prop-types';

import URI from '@core/utils/url';

import SplitChunk from '../components/SplitChunk';

const LazyStory = lazy(
  () =>
    import(
      /* webpackChunkName: 'Story' */ '@phoenix/stories/page/containers/StoryPage'
    ),
);

const StoryChunk = ({
  location: {originalUrl},
  match: {
    params: {id},
  },
}) => (
  <SplitChunk
    component={LazyStory}
    originalUrl={originalUrl}
    id={id || URI(originalUrl).query(true).id}
  />
);

StoryChunk.propTypes /* remove-proptypes */ = {
  location: PropTypes.shape({
    originalUrl: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default StoryChunk;
