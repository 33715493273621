import type {FC} from 'react';
import React, {useMemo} from 'react';

import FavoritesAppearance from '@core/theming/constants/features/FavoritesAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43697
 */
type ThemedFavoritesPageProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  FavoritesAppearance,
  ModuleComponent<ThemedFavoritesPageProps>
> = (value) => {
  switch (value) {
    case FavoritesAppearance.AS_SEARCH_LIST:
      return import(
        /* webpackChunkName: 'favoritesSearch' */ '@phoenix/favorites/components/FavoritesPageAsSearchList'
      );
    case FavoritesAppearance.AS_REGULAR_LIST:
      return import(
        /* webpackChunkName: 'favoritesRegular' */ '@phoenix/favorites/components/FavoritesPageAsRegularList'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable favorites page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedFavoritesPage: FC<ThemedFavoritesPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedFavoritesPageProps,
        FavoritesAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="favorites"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedFavoritesPage;
