import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import PhotoSize from '@core/user/photo/constants/PhotoSize';
import PhotoPreset from '@core/user/photo/constants/PhotoPreset';

import OnlineBadge from '@phoenix/user/widget/components/OnlineBadge';

import MediaCounters from '../../widget/components/MediaCounters';
import type {PhotoProps} from '../containers/Photo';
import Photo from '../containers/Photo';
import type PhotoType from '../types/Photo';
import css from '../styles/PhotoWithWidgets.css';

type PhotoWithWidgetsStaticProps = {
  PRESET: typeof PhotoPreset;
  SIZE: typeof PhotoSize;
};

export type PhotoWithWidgetsProps = {
  className?: string;
  online?: boolean;
  recentlyOnline?: boolean;
  photo: PhotoType;
  inverse?: boolean;
  action?: React.ReactNode;
  photoCount?: number;
  videoCount?: number;
  pendingDeleteShowDescription?: boolean;
  badge?: React.ReactNode;
  badgeOnTopRight?: boolean;
  offsetBadge?: boolean;
  onlineOnTop?: boolean;
  withBlurAnimation?: boolean;
  round?: boolean;
  'data-test'?: string;
} & Partial<PhotoProps>;

const PhotoWithWidgets: FC<PhotoWithWidgetsProps> &
  PhotoWithWidgetsStaticProps = ({
  className,
  online,
  recentlyOnline,
  photo,
  inverse,
  action,
  photoCount,
  videoCount,
  pendingDeleteShowDescription = true,
  badge,
  badgeOnTopRight,
  offsetBadge,
  onlineOnTop = false,
  round = false,
  'data-test': dataTest,
  ...props
}) => {
  const withAction = Boolean(action);
  const withCounters = Boolean(photoCount || videoCount);
  const isBadgeTop = onlineOnTop || !round;

  return (
    <div className={cn(className, css.container)} data-test={dataTest}>
      <OnlineBadge
        className={cn(css.status, isBadgeTop ? css.top : css.bottom)}
        online={online}
        recentlyOnline={recentlyOnline}
        inverse={inverse}
      />
      {badge && (
        <div
          className={cn(
            css.badge,
            offsetBadge && css.offsetBadge,
            badgeOnTopRight && css.badgeOnTopRight,
          )}
        >
          {badge}
        </div>
      )}
      <Photo
        photo={{
          preset: PhotoWithWidgets.PRESET.AVATAR,
          ...photo,
        }}
        {...props}
        round={round}
        pendingDeleteShowDescription={pendingDeleteShowDescription}
      />
      {withAction && <div className={css.action}>{action}</div>}
      {withCounters && (
        <MediaCounters
          className={css.counters}
          videoCount={videoCount}
          photoCount={photoCount}
        />
      )}
    </div>
  );
};

PhotoWithWidgets.PRESET = PhotoPreset;
PhotoWithWidgets.SIZE = PhotoSize;

export default PhotoWithWidgets;
