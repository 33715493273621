/**
 * When target user turned off cross vision functional
 * @type {string}
 */
export const DISABLED_CV_TO_USER = 'DISABLED_CV_TO_USER';

/**
 * When current user turned off cross vision functional
 * @type {string}
 */
export const DISABLED_CV_FROM_USER = 'DISABLED_CV_FROM_USER';
