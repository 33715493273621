import React, {Fragment} from 'react';

import {
  PlaceholderBar,
  PlaceholderCircle,
  Row,
  Spacing,
  Card,
  SpacingShort,
} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';

import css from '../styles/InAppMotivationPopupLayoutPlaceholder.css';

const InAppMotivationPopupLayoutPlaceholder = () => {
  return (
    <Fragment>
      <Card light shadowLevel={0} boundless>
        <SpacingShort>
          <div className={css.content}>
            <Spacing onlyRight stretch={false}>
              <PlaceholderCircle size={60} />
            </Spacing>
            <PlaceholderBar size={6} />
          </div>
        </SpacingShort>
      </Card>
      <Spacing>
        <Row>
          <Row align={Align.BETWEEN}>
            <PlaceholderBar />
          </Row>
        </Row>
        <Row>
          <Row align={Align.BETWEEN}>
            <PlaceholderBar />
          </Row>
        </Row>
        <Row>
          <Row align={Align.BETWEEN}>
            <PlaceholderBar />
          </Row>
        </Row>
        <Row>
          <Row align={Align.BETWEEN}>
            <PlaceholderBar />
          </Row>
        </Row>
      </Spacing>
    </Fragment>
  );
};

export default InAppMotivationPopupLayoutPlaceholder;
