import type {FC} from 'react';
import React from 'react';

import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';

import {IconType, IconSubstrateSize} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {ButtonPrimary, Button, Row, IconSubstrate} from '@phoenix/ui';
import useSkipBlindClick from '@phoenix/remarketing/popunder/utils/useSkipBlindClick';
import PopupWithUserPhotosPlaceholder from '@phoenix/webpush/components/PopupWithUserPhotosPlaceholder';
import useMediaMotivation from '@phoenix/webpush/utils/useMediaMotivation';

import POPUP_VIEW from '../constants/popupView';
import css from '../styles/TargetCustomPopupLayout.css';

type TargetCustomPopupLayoutProps = {
  userId?: string;
  popupType: string;
  onAcceptClick: () => void;
  onDenyClick: () => void;
};

const USERS_AMOUNT = 1;
const getPopupTranslations = (popupType, userName) => {
  switch (popupType) {
    case POPUP_VIEW.SEND_FIRST_MESSAGE:
      return {
        title: t('webPushNotification', 'text.getAlertedWhenResponds', {
          '{screenName}': userName,
        }),
        skip: t('webPushNotification', 'button.notNowSorry'),
        accept: t('webPushNotification', 'button.subscribeToPushes'),
        icon: 'chat',
      };

    case POPUP_VIEW.ADD_FIRST_FAVORITE:
      return {
        title: t('webPushNotification', 'text.reactionToBeingFav', {
          '{screenName}': userName,
        }),
        skip: t('webPushNotification', 'button.noThanks'),
        accept: t('webPushNotification', 'button.getNotified'),
        icon: 'favorite',
      };

    case POPUP_VIEW.ADD_LIKE:
      return {
        title: t('webPushNotification', 'text.likeReturned', {
          '{screenName}': userName,
        }),
        skip: t('webPushNotification', 'button.notYet'),
        accept: t('webPushNotification', 'button.signUp'),
        icon: 'heart',
      };

    case POPUP_VIEW.OPEN_PROFILE:
      return {
        title: t('webPushNotification', 'text.browsingYouToo', {
          '{screenName}': userName,
        }),
        skip: t('webPushNotification', 'button.notNowThanks'),
        accept: t('webPushNotification', 'button.signUpToPushes'),
        icon: 'profile',
      };

    case POPUP_VIEW.FREE_MESSAGE_FINISHED:
      return {
        title: t('webPushNotification', 'text.signUpToBeNotifiedReplyArrives'),
        skip: t('webPushNotification', 'button.notNow'),
        accept: t('webPushNotification', 'button.signUp'),
        icon: 'mail',
      };

    case POPUP_VIEW.CUSTOM_DEFAULT:
      return {
        title: t('webPushNotification', 'text.interested', {
          '{screenName}': userName,
        }),
        skip: t('webPushNotification', 'button.notYet'),
        accept: t('webPushNotification', 'button.signMeUp'),
        icon: null, // According to design we should not add icon for this popup type
      };

    default:
      return {
        title: '',
        skip: '',
        accept: '',
        icon: null,
      };
  }
};

const TargetCustomPopupLayout: FC<TargetCustomPopupLayoutProps> = ({
  userId,
  popupType,
  onAcceptClick,
  onDenyClick,
}) => {
  useSkipBlindClick();
  const isPhone = usePhoneBreakpoint();

  const {media, userName, loading} = useMediaMotivation({
    userId,
    userAmount: USERS_AMOUNT,
    userPhotoOptions: {
      withNaughtyModePopup: false,
      withNaughtyMode: false,
      withDescription: false,
      className: css.preview,
    },
  });

  const {title, skip, accept, icon} = getPopupTranslations(popupType, userName);

  if (loading) {
    return <PopupWithUserPhotosPlaceholder withOnePhoto />;
  }

  return (
    <PopupNotice
      adaptive
      withoutTop={isPhone}
      heading={
        <Row className={css.wrap}>
          {media}
          {icon && (
            <IconSubstrate
              icon={icon}
              size={IconSubstrateSize.SMALL}
              type={IconType.PRIMARY}
              className={css.icon}
            />
          )}
          <PopupLoadTracker />
        </Row>
      }
      title={title}
      actions={[
        <ButtonPrimary onClick={onAcceptClick} trackingName="accept" fullWidth>
          {accept}
        </ButtonPrimary>,
        <Button onClick={onDenyClick} trackingName="skip" fullWidth>
          {skip}
        </Button>,
      ]}
      actionsVertical={isPhone}
      actionsReverse={!isPhone}
      actionClassName={css.actionWrapper}
      data-test="webPushNativeLikePopup"
    />
  );
};

export default TargetCustomPopupLayout;
