import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyRooms = lazy(
  () => import(/* webpackChunkName: 'rooms' */ '@phoenix/rooms'),
);

export const RoomsChunk = (props) => (
  <SplitChunk component={LazyRooms} {...props} />
);
/**
 * Moderator page. Due to the fact, that this page isn't reachable by 99.9% of users,
 * it must be splitted to chunk
 */
const LazyRoomsModerator = lazy(
  () =>
    import(
      /* webpackChunkName: 'roomsModerator' */ '@phoenix/rooms/containers/RoomModeratorPage'
    ),
);

export const RoomsModeratorChunk = (props) => (
  <SplitChunk component={LazyRoomsModerator} {...props} />
);
