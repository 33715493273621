import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';

import Photo from '@phoenix/user/photo/containers/Photo';
import type {ListUserData} from '@phoenix/search/list/types';

import css from '../styles/PhotosListAsRoulette.css';

type PhotosListAsRouletteProps = {
  users: ListUserData[];
  small?: boolean;
  withAnimation?: boolean;
};

/**
 * A special display of the photo widget, where three widgets are displayed,
 * one of which is large and the others are smaller
 * @see PaidFunnelMap
 * @see EmailingFunnelUsersList
 */
const PhotosListAsRoulette: FC<PhotosListAsRouletteProps> = ({
  users,
  withAnimation,
  small,
}) => {
  return (
    <div
      className={cn(css.avatars, small && css.small)}
      data-test="funnelPhotosAvatars"
    >
      {users.map(({profile}) => (
        <div
          key={profile.id}
          className={cn(css.photo, withAnimation && css.animation)}
        >
          <Photo
            round
            photo={getPrimaryPhotoData(profile)}
            userId={profile.id}
            withShadow={false}
          />
        </div>
      ))}
    </div>
  );
};

export default PhotosListAsRoulette;
