import t from '@core/translations/translate';

import {FIELD_NAMES} from '../constants/fields';

/**
 * Validator for checkboxes (can be checked one of all)
 * @param {Boolean} markedAsAllowedInformation
 * @param {Boolean} markedAsAllowedProfiling
 * @param {Boolean} markedAsAllowedAdvertising
 * @return {Object}
 */
const validate = ({
  [FIELD_NAMES.ALLOWED_INFORMATION]: markedAsAllowedInformation,
  [FIELD_NAMES.ALLOWED_PROFILING]: markedAsAllowedProfiling,
  [FIELD_NAMES.ALLOWED_ADVERTISING]: markedAsAllowedAdvertising,
}) => {
  if (
    [
      markedAsAllowedInformation,
      markedAsAllowedProfiling,
      markedAsAllowedAdvertising,
    ].includes(true)
  ) {
    return null;
  }

  const errorText = t('dnsmpi', 'text.please_mark');
  return {
    [FIELD_NAMES.ALLOWED_INFORMATION]: errorText,
    [FIELD_NAMES.ALLOWED_PROFILING]: errorText,
    [FIELD_NAMES.ALLOWED_ADVERTISING]: errorText,
  };
};

export default validate;
