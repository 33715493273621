import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import PersonalIdentityByDemandRequirePopup from '../containers/PersonalIdentityByDemandRequirePopup';

const openPersonalIdentityByDemandRequirePopup = (onClose) => {
  PopupService.openPopup(<PersonalIdentityByDemandRequirePopup />, {
    small: true,
    trackingName: 'personalIdentityByDemandRequire',
    autoLoadTracking: true,
    showCloseButton: false,
    onClose,
  });
};

export default openPersonalIdentityByDemandRequirePopup;
