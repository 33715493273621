import {useEffect, useMemo} from 'react';
import {useQuery} from '@apollo/client';

import {getCookie} from '@core/utils/cookie';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';

import ZONES from '../constants/popunderZone';
import {POPUNDER_COOKIE, EXPIRE_COOKIE_DAY} from '../constants/cookie';
import POPUNDER_ACTION_DELAY_QUERY from '../graphql/queries/popunderActionDelay.gql';
import usePopunder from '../utils/usePopunder';
import popunderActionDelayStarter from '../utils/popunderActionDelayStarter';
import useBlindClickListener from '../utils/useBlindClickListener';
import useIsRouteChanged from '../utils/useIsRouteChanged';

/**
 * Utility component that renders nothing, dedicated for calling pop-under on blind click (e.g. click everywhere on screen).
 * Implemented as components since it should allow to mount/unmount (@see App.js)
 *
 * How it works:
 * - It's available for any kind of users (both paid or free ones).
 * - It works only one time in a day (that why we use cookies)
 * - It triggers if user makes any redirect on site and, after, makes any click on any DOM element.
 */
const BlindClickPopunder = () => {
  const {
    data: popunderActionDelayData,
    error,
    loading,
  } = useQuery(POPUNDER_ACTION_DELAY_QUERY);

  const {data: userData, loading: userLoading} = useQuery(
    CURRENT_USER_ID_QUERY,
  );

  const actionDelays = useMemo(() => {
    return loading || error
      ? []
      : popunderActionDelayData.userFeatures.popunderActionDelay || [];
  }, [popunderActionDelayData, error, loading]);

  // We should start data-retrieving for pop-under only after first redirect.
  const isRouteChanged = useIsRouteChanged();

  const {callPopunder, excludeRoutes, refetch} = usePopunder(
    ZONES.BLIND_CLICK,
    !isRouteChanged || Boolean(getCookie(POPUNDER_COOKIE)),
  );

  /**
   * Call pop-under action delay watcher
   */
  useEffect(() => {
    let runningActions;
    if (!userLoading && userData) {
      runningActions = popunderActionDelayStarter(
        actionDelays,
        refetch,
        userData.myUser.id,
      );
    }

    return () => {
      runningActions?.clear();
    };
  }, [userData, userLoading, refetch, actionDelays]);

  useBlindClickListener({
    callPopunder,
    excludeRoutes,
    cookieName: POPUNDER_COOKIE,
    expires: EXPIRE_COOKIE_DAY,
  });

  return null;
};

export default BlindClickPopunder;
