import {useParams} from 'react-router-dom';

import useGetWebcamProfileData from './useGetWebcamProfileData';
import useTargetUserQuery from './useTargetUserQuery';

const useTargetUserBaseData = ({userId, forceWebCamPartner}) => {
  const {partnerName} = useParams();
  const {
    loading: webcamLoading,
    error: webcamError,
    webcamPlacements,
  } = useGetWebcamProfileData(userId, partnerName);

  const {loading, data, viewPermissions, error} = useTargetUserQuery({
    userId,
    partner: forceWebCamPartner,
  });

  return {
    data,
    viewPermissions,
    webcamPlacements,
    loading: loading || webcamLoading,
    error: error || webcamError,
  };
};

export default useTargetUserBaseData;
