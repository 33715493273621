import type {FC, ReactElement} from 'react';
import React from 'react';
import cn from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';

import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';

import useHeaderAndToolbarVariablesUpdater from '@phoenix/header/utils/useHeaderAndToolbarVariablesUpdater';

import css from '../styles/AnimatedLayeredSystemNotifications.css';

const EASE_ANIMATION = [0.39, 0, 0.17, 0.99];

const ANIMATION_DURATION = getAnimationTime({duration: 0.2});

const variants = {
  show: {
    height: 'var(--toolbarFixedHeight)',
    transition: {ease: EASE_ANIMATION, duration: ANIMATION_DURATION},
  },
  hide: {
    height: 0,
    transition: {ease: EASE_ANIMATION, duration: ANIMATION_DURATION},
  },
};

/**
 * Notifications are place one on another with "layered" effect on close
 */
type AnimatedLayeredSystemNotificationsProps = {
  children: ReactElement[];
  onTop: boolean;
};

const AnimatedLayeredSystemNotifications: FC<
  AnimatedLayeredSystemNotificationsProps
> = ({children, onTop}) => {
  const hasChildren = Boolean(children.length);
  const updaterHeaderAndToolbarVariables =
    useHeaderAndToolbarVariablesUpdater();

  return (
    <motion.div
      data-test="systemNotifications"
      className={cn(css.container, onTop && css.fixedHeight)}
      animate={hasChildren ? variants.show : variants.hide}
    >
      <AnimatePresence>
        {children.map((child) => (
          <motion.div
            className={css.notification}
            key={child.key}
            initial={{y: '-100%'}}
            animate={{y: 0}}
            onAnimationComplete={updaterHeaderAndToolbarVariables}
            exit={{y: '-100%', pointerEvents: 'none'}} // We should avoid unexpected clicks on closing notification
            transition={{ease: EASE_ANIMATION, duration: ANIMATION_DURATION}}
          >
            {child}
          </motion.div>
        ))}
      </AnimatePresence>
    </motion.div>
  );
};

export default AnimatedLayeredSystemNotifications;
