import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import t from '@core/translations/translate';

import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {TextMuted} from '@phoenix/typography';
import {ButtonPrimary} from '@phoenix/ui';

const handleClick = () => PopupService.closePopup(true);

const PartnerNetworkAlertPopup = () => {
  return (
    <PopupNotice
      title={t('partnerNetwork', 'title.ooops')}
      description={
        <TextMuted>
          {t('partnerNetwork', 'text.userDeactivateFeature')}
        </TextMuted>
      }
      actions={[
        <ButtonPrimary
          onClick={handleClick}
          trackingName="closePopup"
          fullWidth
          adaptive
        >
          {t('partnerNetwork', 'button.ok')}
        </ButtonPrimary>,
      ]}
    />
  );
};

export default PartnerNetworkAlertPopup;
