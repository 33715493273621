import once from 'lodash/once';

import FREE_MESSAGE_NOTIFICATION_SUBSCRIPTION from '../graphql/subscriptions/freeMessageNotificationSubscription.gql';
import updateFreeMessageNotification from './updateFreeMessageNotification';

const startUpdateFreeMessageNotificationListener = once((client) => {
  client.subscribe({query: FREE_MESSAGE_NOTIFICATION_SUBSCRIPTION}).subscribe(
    ({
      data: {
        freeMessageNotification: {isAvailable, bannerType, period},
      },
    }) => {
      updateFreeMessageNotification({
        client,
        isAvailable,
        bannerType,
        period,
      });
    },
  );
});

export default startUpdateFreeMessageNotificationListener;
