import PopupService from '@core/popup/utils/PopupService';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PopupPriority from '@core/popup/constants/PopupPriority';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import {getClientInstance} from '@core/graphql/client';
import isPreloadedPaymentData from '@core/payment/common/utils/isPreloadedPaymentData';

import getTimeLeft from '../../utils/getTimeLeft';
import EXTRA_DISCOUNT_QUERY from './graphql/queries/extraDiscount.gql';
import setPaymentDiscount from '../../utils/setPaymentDiscount';
import {
  SEARCH_2X,
  PROFILES_VIEW,
  INTERACTIVE_LIKE,
} from '../../constants/comboContextTypes';
import openExtraDiscountPopup from './utils/openExtraDiscountPopup';
import openExtraDiscountInteractiveLikePopup from './utils/openExtraDiscountInteractiveLikePopup';
import openExpandSearchLimitsPopup from './utils/openExpandSearchLimitsPopup';
import openExtraProfilesViewPopup from './utils/openExtraProfilesViewPopup';
import openExtraDiscountWithProgressPopup from './utils/openExtraDiscountWithProgressPopup';

export const USERS_COUNT = 6;

// Export for tests
export const handleTimeOver = () => PopupService.closePopup();

/**
 * Open extra discount popup
 * @param processId
 * @param client
 * @returns {Promise<void>}
 */
const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {
        discount: {
          default: defaultDiscount,
          extra,
          expiredAt,
          via,
          buttonType,
          holiday,
          users,
        },
      },
    },
  } = await client.query({
    query: EXTRA_DISCOUNT_QUERY,
    variables: {processId, usersCount: USERS_COUNT},
  });

  if (!extra) return;

  const time = getTimeLeft(expiredAt);
  const url = generatePayUrl({
    stage: PAYMENT_ACTIONS.MEMBERSHIP,
    urlParams: {via},
  });

  const popupConfig = {
    small: true,
    priority: PopupPriority.LOW,
    autoLoadTracking: true,
  };

  if (holiday) {
    openExtraDiscountPopup({
      comboContext: buttonType,
      defaultDiscount,
      holiday,
      extra,
      time,
      url,
      onTimeOver: handleTimeOver,
      popupConfig,
    });
  } else if (buttonType === INTERACTIVE_LIKE) {
    openExtraDiscountInteractiveLikePopup({
      url,
      duration: time,
      extra,
      users,
      onTimeOver: handleTimeOver,
      popupConfig,
    });
  } else if (buttonType === SEARCH_2X) {
    openExpandSearchLimitsPopup({
      url,
      users,
      extra,
      popupConfig,
    });
  } else if (buttonType === PROFILES_VIEW) {
    openExtraProfilesViewPopup({
      url,
      users,
      extra,
      popupConfig,
    });
  } else {
    openExtraDiscountWithProgressPopup({
      url,
      duration: time,
      extra,
      onTimeOver: handleTimeOver,
      popupConfig,
    });
  }

  /**
   * Due to the possibility of receiving a discounted pedal during the preload of BasePaymentData,
   * we need to refetch the data with the discount information.
   * This preload is being implemented as part of task FE-40096
   */
  if (isPreloadedPaymentData()) {
    invalidateCacheByTypename(getClientInstance(), 'Payment', 'packagesData');
  }

  setPaymentDiscount(extra, client);
};

export default action;
