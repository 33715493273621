import {filter, share, map, take, buffer, debounceTime} from 'rxjs/operators';

import createPostMessageObservable from './utils/createPostMessageObservable';
import {
  BANNER_NOT_LOADED,
  SMART_ELEMENT_BANNER,
} from './constants/supportedBannerTypes';

/**
 * One banner placement can send multiple postmessage events.
 * We should wait some time to collect them all.
 * @const {number}
 */
const MESSAGE_DEBOUNCE_TIME = 10;

/**
 * Prepare data before setting into state
 * @param {Object} info
 * @param {string} info.height
 * @param {string} info.width
 * @param {string} info.ifrSrc
 * @param {Object} info.zone
 * @returns {Object}
 */

/**
 * Returns formatted data from banner's 'message' event.
 * @param {Array} events
 * @returns {Object}
 */
const formatEventData = (events) => {
  let result = {};

  events.forEach(({data}) => {
    if (
      data.callbackEvent === SMART_ELEMENT_BANNER ||
      data.callbackEvent === BANNER_NOT_LOADED
    ) {
      const {eventData: {content} = {}} = data;
      const contentWidth = content && content.width;
      const {conceptType} = content || {};
      const contentHeight = content && content.height;

      result = {
        ...result,
        // Add width, height and flag from event for displaying iframe
        divId: data.div_id,
        height: contentHeight,
        width: contentWidth,
        conceptType,
        /**
         * Needed to display finally iframe on frontend.
         * If this flag is 'false' it indicates that nothing inside iframe was loaded and we can't show anything :(
         */
        iframeReady:
          parseInt(contentWidth, 10) !== 0 && parseInt(contentHeight, 10) !== 0,
      };
    }
  });

  return result;
};

/**
 * Remarketing functionality service.
 * Provides API to fetch remarketting data for banners
 */
class RemarketingBannerService {
  constructor() {
    /**
     * Url of banner system to compare with window 'message' event's origin.
     * Coming in getInfo response.
     */
    this.bannerSystemUrl = null;

    /**
     * Rules to validate banners window 'message' events.
     * Comes in getInfo response.
     */
    this.validatePostMessageData = null;

    /**
     * RxJS Observable of banner-related window 'message' events.
     */
    this.messageEvents$ = null;
  }

  setMessageParams(bannerSystemUrl, validatePostMessageData) {
    if (this.bannerSystemUrl && this.validatePostMessageData) {
      return;
    }
    this.bannerSystemUrl = bannerSystemUrl;
    this.validatePostMessageData = validatePostMessageData;
  }

  /**
   * For ability to listen 'message' event from banners.
   * @param {string} divId
   * @param {function} callback
   * @return {Subscription}
   */
  listenBannerEvent = (divId, callback) => {
    const messageEvents$ = this.messageEvents().pipe(
      // Since different banner types return different camel-cased or snake-cased name. Shame...
      filter(({data}) => (data.div_id || data.divId) === divId),
      share(),
    );

    return messageEvents$
      .pipe(
        // Since one placement can send multiple post messages, we should collect them in one
        buffer(messageEvents$.pipe(debounceTime(MESSAGE_DEBOUNCE_TIME))),
        map(formatEventData),
        take(1), // for automatic listener remove once event received
      )
      .subscribe(callback);
  };

  /**
   * Returns RxJS Observable object to watch banner-related window 'message' events.
   * @private
   * @return {Observable}
   */
  messageEvents() {
    /**
     * Banner's iframe should tell some additional info using window.postMessage().
     * We should listen window 'message' event to receive this info and then update iframe's visibility and size.
     * Instead of listen 'message' inside each banner we use single listener for all banners.
     */
    if (!this.messageEvents$) {
      this.messageEvents$ = createPostMessageObservable(
        this.bannerSystemUrl,
        this.validatePostMessageData,
      );
    }

    return this.messageEvents$;
  }
}

export default new RemarketingBannerService();
