import fetchData from '@core/application/utils/fetchData';
import logger from '@core/logger';

/**
 * Length of first part of card by design
 */
const BIN_LENGTH = 4;

/**
 * Get formatted card wallet
 * TODO[BB_removed]: Move to graphql
 */
const getCardWallet = async (
  walletToken: string,
  cardWalletType: string,
): Promise<string | null> => {
  return fetchData({
    url: '/api/v1/pay/cardWallet',
    body: {walletToken, cardWalletType},
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === false && res.message) {
        logger.sendError(`[getCardWallet]: Error is ${res.message}`);
        return null;
      }

      if (!res.bin || !res.last4Digits || !res.isPanOnly) {
        return null;
      }

      return `${String(res.bin).slice(0, BIN_LENGTH)} **** **** ${res.last4Digits}`;
    });
};

export default getCardWallet;
