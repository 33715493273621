import {useMemo, useRef, useCallback} from 'react';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

import useEventCallback from '@core/utils/react/useEventCallback';
import removeTypename from '@core/graphql/utils/removeTypename';

import useMicrofeaturesAvailabilityData from './useMicrofeaturesAvailabilityData';
import getMicrofeaturesList from './getMicrofeaturesList';
import useMicrofeaturesData from './useMicrofeaturesData';
import getMicrofeatureData from './getMicrofeatureData';

const useRotationData = ({allowedToStart}) => {
  const rotationDataRef = useRef({});

  const {loading, error, modelType, placements} =
    useMicrofeaturesAvailabilityData({skip: !allowedToStart});

  const {
    loading: listLoading,
    error: listError,
    microfeaturesList,
  } = useMicrofeaturesData(modelType, {skip: !allowedToStart});

  const microfeaturesAvailable =
    allowedToStart &&
    Boolean(modelType) &&
    !loading &&
    !listLoading &&
    !error &&
    !listError;

  const notBoughtFeatures = useMemo(() => {
    return getMicrofeaturesList(modelType, microfeaturesList)
      .map(({activeFrom, featureName}) => {
        if (activeFrom) return null;
        return featureName;
      })
      .filter(Boolean);
  }, [modelType, microfeaturesList]);

  const rotationData = useMemo(() => {
    if (!microfeaturesAvailable) return {};

    const localData = {};
    // 'placements' is a list of available placements for microfeatures banner and features that can be displayed in this placement
    Object.keys(removeTypename(placements)).forEach((placement) => {
      const features = intersection(placements[placement], notBoughtFeatures);

      const cachedCurrentFeatureId =
        rotationDataRef.current[placement]?.currentFeatureId;
      // correct position after payment, in case user bought last feature in rotation order and we need to start from the beginning
      const correctedCurrentFeatureId =
        cachedCurrentFeatureId >= features.length ? 0 : cachedCurrentFeatureId;

      localData[placement] = {
        currentFeatureId:
          // -1 means that placement rotation hasn't started yet
          cachedCurrentFeatureId > -1 ? correctedCurrentFeatureId : -1,
        // only features that are not bought yet
        features,
      };
    });

    rotationDataRef.current = localData;
    return localData;
  }, [microfeaturesAvailable, placements, notBoughtFeatures]);

  // Each placement has a list of features that going to be rotated with rout change
  const iteratePlacement = useEventCallback((placement) => {
    if (!microfeaturesAvailable) return;

    const placementData = rotationData[placement];

    if (!placementData) return;

    // rotationData contains all placements with features that can be displayed in this placement
    const {currentFeatureId} = placementData || {};
    // show next feature or start from the beginning
    placementData.currentFeatureId =
      currentFeatureId < placementData.features.length - 1
        ? currentFeatureId + 1
        : 0;
  });

  const checkPlacementEnabled = useCallback(
    (placement) => {
      if (!microfeaturesAvailable) return false;

      return !isEmpty(rotationData[placement].features);
    },
    [microfeaturesAvailable, rotationData],
  );

  const getFeatureForPlacement = useCallback(
    ({placement} = {}) => {
      if (!microfeaturesAvailable || !checkPlacementEnabled(placement)) {
        return null;
      }

      const placementData = rotationData[placement];
      const {currentFeatureId} = placementData;

      return placementData.features[currentFeatureId];
    },
    [microfeaturesAvailable, checkPlacementEnabled, rotationData],
  );

  const getFeatureData = useCallback(
    ({featureName} = {}) => {
      if (!microfeaturesAvailable) return {};

      return getMicrofeatureData(modelType, microfeaturesList, featureName);
    },
    [modelType, microfeaturesList, microfeaturesAvailable],
  );

  return {
    rotationData,
    notBoughtFeatures,
    iteratePlacement,
    getFeatureForPlacement,
    checkPlacementEnabled,
    microfeaturesAvailable,
    getFeatureData,
  };
};

export default useRotationData;
