import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import EXTRA_PROFILES_VIEW_QUERY from './graphql/queries/extraProfilesView.gql';
import getTimeLeft from '../../utils/getTimeLeft';
import resetProfileViewPermissions from '../../utils/resetProfileViewPermissions';
import ExtraProfilesViewPopupLayout from './components/ExtraProfilesViewPopupLayout';

// Export for tests
export const handleTimeOver = () => PopupService.closePopup();

export const popupConfig = {
  small: true,
  priority: PopupPriority.LOW,
  autoLoadTracking: true,
  trackingName: 'extraProfilesView',
};

const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {checkProfiles},
    },
  } = await client.query({
    query: EXTRA_PROFILES_VIEW_QUERY,
    variables: {
      processId,
    },
  });

  if (!checkProfiles.enabled) return;

  const time = getTimeLeft(checkProfiles.expiredAt);

  PopupService.openPopup(
    <ExtraProfilesViewPopupLayout
      users={checkProfiles.users}
      duration={time}
      onTimeOver={handleTimeOver}
    />,
    popupConfig,
  );

  resetProfileViewPermissions(client);
};

export default action;
