import React, {Children, isValidElement, cloneElement, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import isBoolean from 'lodash/isBoolean';

import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import {FUNNEL_PHOTO_UPLOAD} from '@core/application/constants/routesWithPopup';

import {Actions} from '@phoenix/ui';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import useDeferredPageAvailable from '@phoenix/application/deferredPage/useDeferredPageAvailable';

import css from '../styles/UploadActions.css';

const UploadActions = ({children, fullWidth, ...props}) => {
  const isSmallTablet = useSmallTabletBreakpoint();
  const {setDeferredPageAvailable} = useDeferredPageAvailable();
  const {pathname} = useLocation();
  const isFunnelPhotoUpload = pathname.startsWith(FUNNEL_PHOTO_UPLOAD);

  /**
   * We need track showing upload action on funnel photo upload
   * to determine time between user came to funnel and see action to upload photo
   * @see App.js
   */
  useEffect(() => {
    if (isFunnelPhotoUpload) {
      /**
       * @see DeferredPage.js
       */
      setDeferredPageAvailable(true);
    }
  }, [setDeferredPageAvailable, isFunnelPhotoUpload]);

  return (
    <Actions
      align={Align.CENTER}
      vertical={isSmallTablet}
      itemSpacing={SpacingSize.SHORT}
      {...props}
    >
      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child, {
            className: css.action,
            fullWidth: isBoolean(fullWidth) ? fullWidth : isSmallTablet,
          }),
      )}
    </Actions>
  );
};

UploadActions.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
};

export default UploadActions;
