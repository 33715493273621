import once from 'lodash/once';
import {from} from 'rxjs';
import {filter} from 'rxjs/operators';

import isCardOneClickAllowed from '@core/payment/forms/card/utils/isCardOneClickAllowed';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import PROCESSING_STATUS_QUERY from '@core/payment/common/graphql/queries/processingStatus.gql';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';
import {Source, ViaEnum} from '@core/types';

import CREDITS_BALANCE_INCREASE_SUBSCRIPTION from '@phoenix/credits/graphql/subscriptions/creditsBalanceIncrease.gql';
import CREDITS_BALANCE_QUERY from '@phoenix/credits/graphql/queries/creditsBalance.gql';
import showBalanceHeaderInMessengerVar from '@phoenix/credits/graphql/vars/showBalanceHeaderInMessengerVar';

import SHOW_COINS_BUY_OFFER_BANNER_SUBSCRIPTION from '../graphql/subscriptions/showBuyCoinsMotivationBanner.gql';
import updateShowCoinsBuyOfferBanner from './updateShowCoinsBuyOfferBanner';

const MIN_COINS_BALANCE_VALUE = 50;

const startShowCoinsBuyOfferBannerListener = once(async (client) => {
  // Because of Cache Persist, we need to invalidate the data in order to get the actual data.
  invalidateCacheByTypename(client, 'Payment', 'isOneClickAllowed');

  const isOneClickAllowed = await isCardOneClickAllowed({
    via: ViaEnum.coins_banner_msg,
    source: Source.popup,
    fetchPolicy: 'no-cache',
  });

  if (!isOneClickAllowed) {
    // need to show balance header for messenger
    showBalanceHeaderInMessengerVar(true);
    updateShowCoinsBuyOfferBanner(false);
    return;
  }

  const {
    data: {
      credits: {balance},
    },
  } = await client.query({query: CREDITS_BALANCE_QUERY});

  if (balance) {
    updateShowCoinsBuyOfferBanner(balance <= MIN_COINS_BALANCE_VALUE);
  } else {
    updateShowCoinsBuyOfferBanner(true);
  }

  from(
    client.subscribe({query: SHOW_COINS_BUY_OFFER_BANNER_SUBSCRIPTION}),
  ).subscribe((result) => {
    if (result?.data?.showBuyCoinsMotivationBanner) {
      isCardOneClickAllowed({
        via: ViaEnum.coins_banner_msg,
        source: Source.popup,
        fetchPolicy: 'network-only',
      }).then((isOneClick) => {
        updateShowCoinsBuyOfferBanner(isOneClick);
      });
    }
  });

  client
    .subscribe({
      query: CREDITS_BALANCE_INCREASE_SUBSCRIPTION,
    })
    .subscribe(({data}) => {
      if (data?.credits?.balanceIncrease?.balance > MIN_COINS_BALANCE_VALUE) {
        /**
         * Avoid hiding the banner during the payment processing,
         * because "usePaymentPackages" hook invokes "processingStatus" changing
         */
        const paymentProcessing = from(
          client.watchQuery({query: PROCESSING_STATUS_QUERY}),
        )
          .pipe(
            filter(
              ({data: paymentProcessingData}) =>
                paymentProcessingData?.payment?.processingStatus !==
                PROCESSING_STATUS.PROCESSING,
            ),
          )
          .subscribe(() => {
            updateShowCoinsBuyOfferBanner(false);
            paymentProcessing.unsubscribe();
          });
      }
    });
});

export default startShowCoinsBuyOfferBannerListener;
