import type {FC, ReactNode} from 'react';
import React from 'react';

import type PhotoLevel from '@core/user/photo/constants/PhotoLevel';
import {PhotoRequestTypeEnum} from '@core/types';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {TextMuted} from '@phoenix/typography';
import {ButtonPrimary} from '@phoenix/ui';

import PhotoLayout from './PhotoLayout';
import PrivatePhotoOverlayType from '../constants/PrivatePhotoOverlayType';
import css from '../styles/PhotoRequestPopupLayout.css';

type PhotoRequestPopupLayoutProps = {
  url: string;
  level: PhotoLevel;
  blurLevel: PhotoLevel;
  title: ReactNode;
  buttonText: ReactNode;
  description?: ReactNode;
  onClick: () => void;
  loading?: boolean;
};

const PhotoRequestPopupLayout: FC<PhotoRequestPopupLayoutProps> = ({
  url,
  level,
  blurLevel,
  title,
  buttonText,
  description,
  onClick,
  loading,
}) => {
  return (
    <PopupNotice
      data-test="photoRequestPopupLayout"
      icon={
        <PopupLoadTracker>
          <div className={css.photo}>
            <PhotoLayout
              url={url}
              level={level}
              blurLevel={blurLevel}
              photoRequestData={{
                isPrivate: true,
                overlayType: PrivatePhotoOverlayType.DESCRIPTION,
                requestType: PhotoRequestTypeEnum.pmaPhoto,
              }}
              round
            />
          </div>
        </PopupLoadTracker>
      }
      title={title}
      description={Boolean(description) && <TextMuted>{description}</TextMuted>}
      actions={[
        <ButtonPrimary
          fullWidth
          onClick={onClick}
          trackingName="action"
          loading={loading}
          data-test="photoRequestPopupButton"
        >
          {buttonText}
        </ButtonPrimary>,
      ]}
    />
  );
};

export default PhotoRequestPopupLayout;
