import StaticPagePopupHelperAbstract from '@core/staticPages/utils/StaticPagePopupHelperAbstract';

import StaticPageContent from '@phoenix/staticPages/containers/StaticPageContent';

/**
 * @class StaticPagePopupHelper
 * @classdesc Helper class for opening static page popups.
 */
class StaticPagePopupHelper extends StaticPagePopupHelperAbstract {
  getComponent = () => {
    return StaticPageContent;
  };
}

export default new StaticPagePopupHelper();
