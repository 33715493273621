import URI from '@core/utils/url';
import {getClientInstance} from '@core/graphql/client';
import AUTOLOGIN_URL_QUERY from '@core/user/profile/common/graphql/queries/autologinUrl.gql';

const getTelegramConversationAutologinUrl = async () => {
  const {data} = await getClientInstance().query({query: AUTOLOGIN_URL_QUERY});

  const autologinUrl = data?.userFeatures?.autologinUrl;

  if (!autologinUrl) {
    return null;
  }

  return URI(autologinUrl).addSearch('open', 'telegram').toString();
};

export default getTelegramConversationAutologinUrl;
