import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import payByMethod from '@core/payment/payProcess/utils/payByMethod';
import {addPaymentDataInner} from '@core/payment/payProcess/paymentFlow/methods/addPaymentData';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

const paypalPay: PaymentProcessStep = async (data) => {
  const {paymentData} = data;
  const {
    action,
    via,
    activePackage,
    country,
    siteName,
    userId,
    locale,
    method,
    returnPath,
    selectedAdditionalPackages,
    scenario,
  } = paymentData;

  const payScenario = scenario || PaymentScenario.INITIAL;

  /**
   * @TODO: Think about add 'addPaymentData' method to 'getPayPalFlow' util,
   *        after removing Backbone.
   */
  const additionalPaymentData = await addPaymentDataInner({
    action,
    activePackage,
    method,
    selectedAdditionalPackages,
    returnPath,
    via,
  });

  const result = await payByMethod({
    ...additionalPaymentData,
    action,
    country,
    currencyCode: activePackage.tokenPrice.currencyCode,
    domain: siteName,
    hidePaymentForm: 1,
    locale,
    scenario: payScenario,
    userId,
  });

  // Handling Abort signal inside payByMethod
  if (typeof result === 'string') {
    return {...data, flowStatus: result};
  }

  if (payScenario === PaymentScenario.INITIAL && result.orderId && result.url) {
    return {
      ...data,
      paymentResult: {
        ...result,
        /**
         * @see continueExternalPaymentInAnotherWindow
         */
        isAllowedRedirect: true,
      },
    };
  }

  return {...data, paymentResult: result};
};

export default paypalPay;
