import React from 'react';
import {useQuery} from '@apollo/client';

import t from '@core/translations/translate';

import {ButtonPrimary} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import openInAppMotivationPopup from '@phoenix/inAppMotivation/utils/openInAppMotivationPopup';
import {PAID_FUNNEL_PAGE} from '@phoenix/inAppMotivation/constants/motivationTypes';

import PhotoUploadActionsLayout from '../components/PhotoUploadActions';
import PHOTO_UPLOAD_MOTIVATION_QUERY from '../graphql/queries/photoUploadMotivation.gql';

const PhotoUploadActions = (props) => {
  const {data, loading, error} = useQuery(PHOTO_UPLOAD_MOTIVATION_QUERY);

  if (loading) return null;
  if (error) return <ErrorBoundary error={error} />;

  const {
    userFeatures: {changeBrowserMotivationEnabled},
  } = data;

  const browserChangeAction = changeBrowserMotivationEnabled ? (
    <ButtonPrimary
      onClick={() =>
        openInAppMotivationPopup({
          linkType: null,
          motivationType: PAID_FUNNEL_PAGE,
        })
      }
      trackingName="changeBrowser"
    >
      {t('uploadButtonMessages', 'text.change_browser')}
    </ButtonPrimary>
  ) : null;

  return (
    <PhotoUploadActionsLayout
      browserChangeAction={browserChangeAction}
      {...props}
    />
  );
};

export default PhotoUploadActions;
