import type {DistanceUnits} from '@core/types/graphql';

export type GetExtraUserAttributesFromDataParams = {
  userFeatures?: {
    professionIcons?: {
      enabled?: boolean;
    };
  };
  search?: {
    features?: {
      withTribe?: boolean;
    };
  };
  myUser?: {
    profile?: {
      location?: {
        distanceUnit?: DistanceUnits;
      };
    };
  };
};

type GetExtraUserAttributesFromDataResult = {
  withProfession: boolean;
  withTribe: boolean;
  distanceUnits?: DistanceUnits;
};

/**
 * Get user attributes from incoming user data
 */
const getExtraUserAttributesFromData = (
  data: GetExtraUserAttributesFromDataParams,
): GetExtraUserAttributesFromDataResult => ({
  withProfession: data?.userFeatures?.professionIcons?.enabled || false,
  withTribe: data?.search?.features?.withTribe || false,
  distanceUnits: data?.myUser?.profile?.location?.distanceUnit,
});

export default getExtraUserAttributesFromData;
