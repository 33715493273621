import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Row, RowShort} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {H3, TextMuted} from '@phoenix/typography';
import Logo from '@phoenix/logo/components/Logo';
import CurrentUserPhotoMotivationList from '@phoenix/user/profile/current/containers/CurrentUserPhotoMotivationList';

import FileSelectWrapper from './FileSelectWrapper';

const MediaUploadMotivationUserPhotoList = ({
  accept,
  onFileSelect,
  multiple,
  texts,
}) => (
  <Fragment>
    <Row align={Align.CENTER}>
      <Logo inverse clickable={false} />
    </Row>
    <RowShort align={Align.CENTER}>
      <H3>{texts.title}</H3>
    </RowShort>
    <RowShort align={Align.CENTER}>
      <TextMuted>{texts.description}</TextMuted>
    </RowShort>
    <Row>
      <FileSelectWrapper
        accept={accept}
        multiple={multiple}
        onFileSelect={onFileSelect}
      >
        {({onClick}) => (
          <AddBabciaUBTracking trackingName="uploadPhoto">
            <div
              onClick={onClick}
              align={Align.CENTER}
              role="button"
              tabIndex={0}
            >
              <CurrentUserPhotoMotivationList />
            </div>
          </AddBabciaUBTracking>
        )}
      </FileSelectWrapper>
    </Row>
    {/* To track 'photoUpload' and 'videoUpload' popup load completion */}
    <PopupLoadTracker />
  </Fragment>
);

MediaUploadMotivationUserPhotoList.propTypes /* remove-proptypes */ = {
  accept: PropTypes.string.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  texts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default MediaUploadMotivationUserPhotoList;
