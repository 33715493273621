export const INTENT_TYPE = 'intent';
export const GOOGLE_TYPE = 'googlechromes';

/**
 * @param {null|string} type
 * @param {string} link
 * @return {string|null}
 */
const formatLink = (type, link) => {
  if (!link || link.indexOf('https') !== 0) {
    return null;
  }

  const linkWithoutProtocol = link.slice(link.indexOf(':'), link.length);

  switch (type) {
    case INTENT_TYPE:
      return `${type}${linkWithoutProtocol}#Intent;scheme=https;package=com.android.chrome;end`;
    case GOOGLE_TYPE:
      return type + linkWithoutProtocol;
    default:
      return null;
  }
};

export default formatLink;
