import {getClientInstance} from '@core/graphql/client';

import MICROFEATURES_AVAILABILITY_DATA_QUERY from '../graphql/queries/microfeaturesAvailabilityData.gql';

const getMicrofeaturesModelType = () => {
  const data = getClientInstance().readQuery({
    query: MICROFEATURES_AVAILABILITY_DATA_QUERY,
  });

  return data?.microFeatures.microFeaturesConfiguration?.modelType;
};

export default getMicrofeaturesModelType;
