import {localStorage as storage} from '@core/utils/storage';

/**
 * Used prefix with "React" to avoid conflicts in localStorage with Backbone-handled events.
 * @param {string} currentUserId
 * @returns {string}
 */
const getStorageKey = (currentUserId) => `extraEventReact:${currentUserId}`;

/**
 * Set data to local storage
 * As we store Backbone and React handled events separately but must have only 1 cached event,
 * remove Backbone event first.
 * @param {string} currentUserId
 * @param {object} data
 */
export const cacheData = (currentUserId, data) => {
  storage.removeItem(`extraEventBackbone:${currentUserId}`);

  storage.setItem(getStorageKey(currentUserId), data);
};

/**
 * Remove data from local storage
 * @param {string} currentUserId
 * @private
 */
export const clearCachedData = (currentUserId) => {
  storage.removeItem(getStorageKey(currentUserId));
};

/**
 * Get data from local storage
 * @param {string} currentUserId
 * @private
 */
export const getCacheData = (currentUserId) => {
  return storage.getItem(getStorageKey(currentUserId));
};
