import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Button, Row, Actions} from '@phoenix/ui';
import {H3} from '@phoenix/typography';
import {Align, ButtonType, SpacingSize} from '@phoenix/ui/constants';
import PopupFrame from '@phoenix/popup/components/PopupFrame';

import css from '../styles/PopupWithUserPhotosLayout.css';

const PopupWithUserPhotosLayout = ({
  media,
  text,
  onDeny,
  onAccept,
  withOnePhoto,
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <PopupFrame
      data-test="webPushCustomWithUsersPopup"
      content={
        <PopupLoadTracker>
          <Row className={cn(withOnePhoto && css.wrap)}>{media}</Row>
          <Row>
            <H3 align={H3.ALIGN.CENTER}>{text}</H3>
          </Row>
          <Actions
            vertical={isPhone}
            align={Align.BETWEEN}
            itemSpacing={SpacingSize.SHORT}
            fullWidth
            className={cn(!isPhone && css.reverse)}
          >
            <Button
              data-test="subscribe"
              type={ButtonType.PRIMARY}
              adaptive
              fullWidth
              onClick={onAccept}
              trackingName="accept"
            >
              {t('webPushNotification', 'button.subscribe_now')}
            </Button>
            <Button
              data-test="later"
              fullWidth
              adaptive
              onClick={onDeny}
              trackingName="deny"
            >
              {t('webPushNotification', 'button.do_later')}
            </Button>
          </Actions>
        </PopupLoadTracker>
      }
    />
  );
};

PopupWithUserPhotosLayout.propTypes /* remove-proptypes */ = {
  media: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  onDeny: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  withOnePhoto: PropTypes.bool.isRequired,
};

export default PopupWithUserPhotosLayout;
