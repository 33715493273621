import t from '@core/translations/translate';
import CommunicationRestriction from '@core/messenger/senderForm/constants/CommunicationRestriction';
import showAntiscamBlockAlertVar from '@core/graphql/vars/showAntiscamBlockAlertVar';
import updateRecipientCache from '@core/messenger/common/utils/updateRecipientCache';
import updateVideoCache from '@core/messenger/common/utils/updateVideoCache';
import removeMessageFromCache from '@core/messenger/senderForm/utils/removeMessageFromCache';

import handlePartnerNetworkError from '@phoenix/partnerNetwork/utils/handlePartnerNetworkError';

/**
 * Update after return error after send message
 * @param {string} error
 * @param {string} recipientId
 * @param {string} sendingId
 * @param {string} uploadId
 * @param {string} videoUploadId
 */
const errorProcessingAfterSendMessage = ({
  error,
  recipientId,
  sendingId,
  videoUploadId,
}) => {
  if (error === CommunicationRestriction.ANTISCAM_ALERT) {
    showAntiscamBlockAlertVar(true);
  }

  // Free communication exhausted - update video cache, remove loading video and show decline video placeholder
  if (
    error === CommunicationRestriction.FREE_COMMUNICATION_EXHAUSTED &&
    videoUploadId
  ) {
    updateVideoCache({
      id: videoUploadId,
      error: t('messengerMessages', 'text.video_declined_first'),
    });
    // If error occurred - remove loading marker.
    updateRecipientCache(recipientId, ({messages, lastMessage}) => {
      const recipient = {
        messages: messages.map((m) =>
          m.id === sendingId ? {...m, isSending: false} : m,
        ),
      };

      if (lastMessage.id === sendingId) {
        recipient.lastMessage = {...lastMessage, isSending: false};
      }

      return recipient;
    });
    return;
  }

  handlePartnerNetworkError({error, userId: recipientId});

  // no error tracking because interaction already tracks system problems.
  removeMessageFromCache(recipientId, sendingId, error);
};

export default errorProcessingAfterSendMessage;
