import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';

import cancel24RepeatPopupAction from './cancel24RepeatPopupAction';
import remarketingOfferPopupAction from './remarketingOfferPopupAction';
import inAppMotivationPopupAction from './inAppMotivationPopupAction';
import confirmationForFreeCoinsPopupAction from './confirmationForFreeCoinsPopupAction';
import virtualAssistantPopupAction from './virtualAssistantPopupAction';
import telegramConversationPopupAction from './telegramConversationPopupAction';
import microFeaturesPopupAction from './microFeaturesPopupAction';

/**
 * List of all popup actions that can be called on application bootstrap
 * @see PopupOnBootstrapDisplayer.js
 *
 * Structure of each action should be similar. All modules should be functions and return object
 * with 2 keys - "operations" and "subscription".
 *
 * In "operations" you can describe all RxJS compatible operations, wrapped into "pipe".
 * So you can here transform, change, filter, etc.
 *
 * In "subscription" you can only pass params from "operations" and perform final action - open popup
 *
 * @example
 *
 *   const somePopupAction = (client) => ({
 *     operations: () => pipe(
 *       filter((data) => data),
 *       map((data) => data.foo.bar),
 *     ),
 *     subscription: (value) => {
 *       PopupService.openPopup(<SomePopup value={value} />);
 *     },
 *   })
 *
 */
const popupActions = [
  cancel24RepeatPopupAction,
  remarketingOfferPopupAction,
  confirmationForFreeCoinsPopupAction,
  virtualAssistantPopupAction,
  telegramConversationPopupAction,
  microFeaturesPopupAction,
];

if (getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER) {
  popupActions.push(inAppMotivationPopupAction);
}
export default popupActions;
