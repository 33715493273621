import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import DnsmpiPopup from '../containers/DnsmpiPopup';

const openDnsmpiPopup = () => {
  PopupService.openPopup(<DnsmpiPopup />, {
    small: true,
    trackingName: 'dnsmpi',
  });
};

export default openDnsmpiPopup;
