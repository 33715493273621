import React from 'react';
import PropTypes from 'prop-types';

import {Button} from '@phoenix/ui';

import FileSelectWrapper from './FileSelectWrapper';

const FileButton = ({
  children,
  multiple,
  accept,
  onFileSelect,
  capture,
  trackingName,
  ...props
}) => (
  <FileSelectWrapper
    multiple={multiple}
    accept={accept}
    onFileSelect={onFileSelect}
    capture={capture}
  >
    {({onClick}) => (
      <Button onClick={onClick} trackingName={trackingName} {...props}>
        {children}
      </Button>
    )}
  </FileSelectWrapper>
);

FileButton.propTypes /* remove-proptypes */ = {
  onFileSelect: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  capture: PropTypes.string,
  trackingName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FileButton;
