import type {FC} from 'react';
import React from 'react';

import t from '@core/translations/translate';
import {createUrlFor} from '@core/utils/url';
import PhotoLevel from '@core/user/photo/constants/PhotoLevel';

import {TextMuted} from '@phoenix/typography';
import Link from '@phoenix/widgets/link';
import SEARCH_WIDGET_PHOTO_PRESET from '@phoenix/search/widget/constants/searchWidgetPhotoPreset';
import {IconSize, SpacingSize} from '@phoenix/ui/constants';
import {Icon, Group} from '@phoenix/ui';

import css from '../styles/PhotoGoChattingPreviewBlurred.css';
import Photo from '../containers/Photo';
import type PhotoType from '../types/Photo';

type PhotoGoChattingPreviewBlurredProps = {
  photo: PhotoType;
  userId: string;
  withShadow: boolean;
};

const PhotoGoChattingPreviewBlurred: FC<PhotoGoChattingPreviewBlurredProps> = ({
  photo,
  userId,
  withShadow = true,
  ...props
}) => (
  <div className={css.container}>
    <Photo
      photo={{
        ...photo,
        preset: SEARCH_WIDGET_PHOTO_PRESET,
        forceLevel: PhotoLevel.HIGHLY_BLURRED,
      }}
      withShadow={withShadow}
      showOverlay={false}
      photoRequestData={{
        withRequest: false,
        withOverlay: false,
      }}
      withNaughtyMode={false}
      {...props}
    />
    <Link
      to={createUrlFor.chatWith(userId)}
      trackingName="goChatting"
      className={css.content}
      draggable={false}
    >
      <Group space={SpacingSize.SHORT}>
        <Icon type="eye-off" inverse size={IconSize.SIZE_36} />
        <TextMuted inverse>{t('photoGallery', 'text.go_chatting')}</TextMuted>
      </Group>
    </Link>
  </div>
);

export default PhotoGoChattingPreviewBlurred;
