import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyFreeCoins = lazy(
  () =>
    import(
      /* webpackChunkName: 'freeCoins' */ '@phoenix/freeCoins/containers/FreeCoinsPage'
    ),
);

export default (props) => <SplitChunk component={LazyFreeCoins} {...props} />;
