import {useMemo} from 'react';
import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import type Photo from '../types/Photo';
import PHOTO_DATA_QUERY from '../graphql/queries/photoData.gql';
import type {
  PhotoDataQuery,
  PhotoDataQueryVariables,
} from '../graphql/queries/photoData';
import getPhotoUrl from './getPhotoUrl';

type UsePhotoUrlReturnType = {
  loading: boolean;
  error?: ApolloError;
  url: string | null;
};

const usePhotoUrl = (photo: Photo, skip = false): UsePhotoUrlReturnType => {
  const {data, loading, error} = useQuery<
    PhotoDataQuery,
    PhotoDataQueryVariables
  >(PHOTO_DATA_QUERY, {
    skip,
  });

  const url = useMemo(() => getPhotoUrl(photo, data), [photo, data]);

  return {loading, error, url};
};

export default usePhotoUrl;
