import React from 'react';
import {gql} from '@apollo/client';

import PopupService from '@core/popup/utils/PopupService';

import isVideoChatSupported from '@phoenix/videoChat/utils/isVideoChatSupported';

import BlockedStreamingForUserPopup from '../containers/BlockedStreamingForUserPopup';
import BLOCK_USER_FOR_STREAMING_SUBSCRIPTION from '../graphql/subscriptions/blockUserForStreming.gql';

let startedListeners = false;

/**
 * Starts subscriptions for block user in streaming functional
 * one to one video chat and video rooms
 */

const startBlockStreamingListener = (client, currentUserId) => {
  if (!startedListeners) {
    if (!isVideoChatSupported()) {
      return;
    }
    /**
     * Set in false flag that current user is blocked by recipient
     */
    client.subscribe({query: BLOCK_USER_FOR_STREAMING_SUBSCRIPTION}).subscribe(
      ({
        data: {
          streaming: {blocked},
        },
      }) => {
        if (blocked) {
          client.writeQuery({
            query: gql`
              query {
                myUser {
                  id
                  videoChat {
                    streamId
                  }
                }
              }
            `,
            data: {
              // Disable video chat
              myUser: {
                id: currentUserId,
                videoChat: null,
                __typename: 'UserData',
              },
            },
          });

          PopupService.openPopup(<BlockedStreamingForUserPopup />, {
            small: true,
            trackingName: 'blockedStreamingForUser',
            autoLoadTracking: true,
            showCloseButton: false,
            closeByNavigation: false,
          });
        }
      },
    );
  }

  startedListeners = true;
};

export default startBlockStreamingListener;
