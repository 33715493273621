import CHAT_ROOMS_QUERY from '../graphql/queries/chatRooms.gql';

/**
 * Rooms access rule.
 */
export default {
  errorCode: 'NOT_ALLOWED',
  query: CHAT_ROOMS_QUERY,
  resolver: (data) => !data.chatrooms || data.chatrooms.isEnabled,
};
