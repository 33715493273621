import {from} from 'rxjs';
import {bufferCount, filter, take, map} from 'rxjs/operators';
import last from 'lodash/last';

import {getClientInstance} from '@core/graphql/client';
import ACTIVITY_SUBSCRIPTION from '@core/activity/graphql/subscriptions/interactionActivity.gql';
import {ALL_TYPES} from '@core/messenger/common/constants/messageType';
import {ACTIVITY_TYPES} from '@core/activity/constants/notificationTypes';

import ACTIONS from '../constants/actions';

/**
 * list of activities after which the subscription logic runs
 */
const ACTIVITIES = {
  [ACTIONS.LIKE]: [ACTIVITY_TYPES.LIKE_PROFILE, ACTIVITY_TYPES.ADD_USER_LIKE],
  [ACTIONS.CHAT]: ALL_TYPES,
};

/**
 * @param {string} action
 * @returns {Observable<*>}
 */
const getActivityObservable = (action) =>
  from(
    getClientInstance().subscribe({
      query: ACTIVITY_SUBSCRIPTION,
    }),
  ).pipe(
    filter(
      ({
        data: {
          activity: {msgType},
        },
      }) => ACTIVITIES[action].includes(msgType),
    ),
    bufferCount(3),
    take(1),
    map((eventList) => last(eventList).data.activity.userId),
  );

export default getActivityObservable;
