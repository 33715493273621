import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import ACTION_FOR_COINS, {
  ACTION_FOR_PLACE_WEB,
} from '../constants/userActionsForPlace';
import useCoinsModel from './useCoinsModel';

/**
 * Default actions are for place Search depends on theme and other features
 * @param {string} place
 * @returns {[string, string, string]|[string, string]|[string, string, string]|[]}
 */
const useCoinsUserActionsForPlace = (place) => {
  const {isCoinsModel} = useCoinsModel();
  const isPhone = usePhoneBreakpoint();

  if (isCoinsModel && ACTION_FOR_COINS[place]) {
    if (!isPhone && ACTION_FOR_PLACE_WEB[place]) {
      return ACTION_FOR_PLACE_WEB[place];
    }
    return ACTION_FOR_COINS[place];
  }

  return null;
};

export default useCoinsUserActionsForPlace;
