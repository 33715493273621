import React from 'react';

import {PlaceholderBlock} from '@phoenix/ui';

import MediaWrapper from './MediaWrapper';

const PhotoItemPlaceholder = (props) => (
  <MediaWrapper {...props}>
    <PlaceholderBlock />
  </MediaWrapper>
);

export default PhotoItemPlaceholder;
