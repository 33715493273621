import type {FC} from 'react';
import React from 'react';

import t from '@core/translations/translate';

import {Group, Row} from '@phoenix/ui';
import {H4, List, ListItem, Text} from '@phoenix/typography';
import {SpacingSize} from '@phoenix/ui/constants';

const PaymentKonbiniInfo: FC = () => (
  <Text small type={Text.TYPE.INHERIT} align={Text.ALIGN.LEFT}>
    <Row>
      <Group space={SpacingSize.SHORTEST}>
        <H4>{t('paymentPage', 'title.convenience_direct')}</H4>
        {t('paymentPage', 'text.easy_payment_is possible_at_convenience')}
      </Group>
    </Row>
    <Row>
      <Group space={SpacingSize.SHORTEST}>
        <H4>{t('paymentPage', 'text.how_to_use')}</H4>
        {t('paymentPage', 'text.please_fill_out_the_form_below')}
      </Group>
    </Row>
    <Row>
      <List>
        <ListItem bulleted>
          <Text small type={Text.TYPE.PRIMARY}>
            {t('paymentPage', 'text.note_that_the_reflection')}
          </Text>
        </ListItem>
        <ListItem bulleted>
          <Text small type={Text.TYPE.PRIMARY}>
            {t('paymentPage', 'text.a_separate_commission')}
          </Text>
        </ListItem>
      </List>
    </Row>
  </Text>
);

export default PaymentKonbiniInfo;
