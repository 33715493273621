import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import replaceParams from '@core/translations/utils/replaceParams';
import PaymentPackageDescriptionCommon from '@core/payment/widgets/packageDescription/containers/PaymentPackageDescription';
import useAutoRenewalAgree from '@core/payment/widgets/autoRenewalAgree/utils/useAutoRenewalAgree';
import useWalletAllowedMethods from '@core/payment/common/utils/useWalletAllowedMethods';
import usePaymentPackageDescriptionRule from '@core/payment/widgets/packageDescription/utils/usePaymentPackageDescriptionRule';

import {Checkbox, Group} from '@phoenix/ui';
import {Text, TextMuted} from '@phoenix/typography';
import usePaymentButtonTitle from '@phoenix/payment/widgets/buttons/utils/usePaymentButtonTitle';
import FooterRow from '@phoenix/payment/widgets/footer/components/FooterRow';

import setParamsToDescriptionText from '../utils/setParamsToDescriptionText';
import getDescriptionLinks from '../utils/getDescriptionLinks';

const PaymentPackageDescription = ({
  withCheckbox,
  paymentButtonTitle: paymentButtonTitleFromProps,
  repeatPeriodInWeeks,
}) => {
  const {loading, packageDescriptionRule} = usePaymentPackageDescriptionRule();

  const {
    isAutoRenewalAgreeChecked,
    setIsAutoRenewalAgreeChecked,
    autoRenewalAgreeDefaultValue,
  } = useAutoRenewalAgree();

  const handleCheckboxChange = useCallback(
    (_event, _value, isChecked) => {
      setIsAutoRenewalAgreeChecked(isChecked);
    },
    [setIsAutoRenewalAgreeChecked],
  );

  // Set once default additional package checkbox state when component is init
  useEffect(() => {
    if (withCheckbox && isNil(isAutoRenewalAgreeChecked)) {
      setIsAutoRenewalAgreeChecked(autoRenewalAgreeDefaultValue);
    }
  }, [
    autoRenewalAgreeDefaultValue,
    isAutoRenewalAgreeChecked,
    setIsAutoRenewalAgreeChecked,
    withCheckbox,
  ]);

  const {methods} = useWalletAllowedMethods();
  const paymentButtonTitleFromServer = usePaymentButtonTitle({
    walletMethods: methods,
    skip: Boolean(paymentButtonTitleFromProps),
  });
  const paymentButtonTitle =
    paymentButtonTitleFromProps || paymentButtonTitleFromServer;

  if (loading || !packageDescriptionRule) {
    return null;
  }

  const description = (
    <PaymentPackageDescriptionCommon repeatPeriodInWeeks={repeatPeriodInWeeks}>
      {({strictLegalMode, packageDescriptionsText}) =>
        packageDescriptionsText.map((packageDescriptionText, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <FooterRow key={index}>
            <Text
              data-test="paymentPackageDescription"
              small
              type={!strictLegalMode ? Text.TYPE.MUTED : Text.TYPE.NORMAL}
              align={
                withCheckbox ? TextMuted.ALIGN.LEFT : TextMuted.ALIGN.CENTER
              }
            >
              {replaceParams(
                setParamsToDescriptionText({
                  translateMessage: packageDescriptionText,
                  paymentButtonTitle,
                }),
                getDescriptionLinks(),
              )}
            </Text>
          </FooterRow>
        ))
      }
    </PaymentPackageDescriptionCommon>
  );

  return withCheckbox ? (
    <FooterRow>
      <Group horizontal>
        <Checkbox
          onChange={handleCheckboxChange}
          checked={Boolean(isAutoRenewalAgreeChecked)}
          trackingName="autoRenewalAgree"
          muted
          data-test="autoRenewalAgreeCheckbox"
        >
          {description}
        </Checkbox>
      </Group>
    </FooterRow>
  ) : (
    description
  );
};

PaymentPackageDescription.propTypes /* remove-proptypes */ = {
  withCheckbox: PropTypes.bool,
  paymentButtonTitle: PropTypes.string,
  repeatPeriodInWeeks: PropTypes.bool,
};

export default PaymentPackageDescription;
