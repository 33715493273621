import {getClientInstance} from '@core/graphql/client';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';

import CHANGE_DOCUMENT_STATUS_PERSONAL_IDENTITY_SUBSCRIPTION from '@phoenix/personalIdentity/graphql/subscriptions/personalIdentityDocumentStatusChange.gql';
import {APPROVED as DOCUMENT_APPROVED} from '@phoenix/personalIdentity/constants/documentStatus';

import updatePersonalIdentityDocumentStatus from './updatePersonalIdentityDocumentStatus';

let subscription;
const startDocumentStatusListener = () => {
  if (subscription) return;
  const client = getClientInstance();

  subscription = client
    .subscribe({query: CHANGE_DOCUMENT_STATUS_PERSONAL_IDENTITY_SUBSCRIPTION})
    .subscribe(
      ({
        data: {
          personalIdentity: {documentStatus},
        },
      }) => {
        if (documentStatus === DOCUMENT_APPROVED) {
          NotificationsService.addNotification({
            type: NotificationsService.TYPES.PERSONAL_IDENTITY,
          });
        }
        updatePersonalIdentityDocumentStatus(documentStatus);
      },
    );
};

export default startDocumentStatusListener;
