import React from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Text, H3} from '@phoenix/typography';
import FormCaptcha from '@phoenix/form/captcha/FormCaptcha';
import {FormInput} from '@phoenix/form/input';
import FormCheckbox from '@phoenix/form/checkbox';
import {FormSelect, FormSelectItem} from '@phoenix/form/select';
import {
  Actions,
  ButtonPrimary,
  Button,
  ClosablePopover,
  Group,
  SpacingLarge,
  Spacing,
  Icon,
} from '@phoenix/ui';
import {
  PopperPlacement,
  SpacingSize,
  IconPosition,
  IconSize,
  VerticalAlign,
  Align,
  PopperTrigger,
} from '@phoenix/ui/constants';

import {FIELD_NAMES} from '../constants/fields';
import css from '../styles/DnsmpiPopupLayout.css';
import STATES from '../constants/states';

const DnsmpiPopupLayout = ({
  isSubmitting,
  onSubmit,
  onClose,
  withEmail,
  withCaptcha,
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <SpacingLarge data-test="dnsmpiPopup">
      <Spacing withoutBottom withoutLeft withoutRight>
        <H3>{t('dnsmpi', 'text.complete')}</H3>
      </Spacing>
      <form onSubmit={onSubmit}>
        <Group>
          <FormInput
            name={FIELD_NAMES.FIRST_NAME}
            label={t('dnsmpi', 'text.firstName')}
            placeholder={t('dnsmpi', 'text.enter_firstName')}
          />
          <FormInput
            name={FIELD_NAMES.LAST_NAME}
            label={t('dnsmpi', 'text.lastName')}
            placeholder={t('dnsmpi', 'text.enter_lastName')}
          />
          {withEmail && (
            <FormInput
              name={FIELD_NAMES.EMAIL}
              label={t('dnsmpi', 'text.email')}
              placeholder={t('dnsmpi', 'text.enter_email')}
              iconPosition={IconPosition.RIGHT}
              icon={
                <ClosablePopover
                  usePortal
                  trackingName="help"
                  trigger={PopperTrigger.CLICK}
                  placement={PopperPlacement.BOTTOM}
                  content={
                    <Text className={css.popover}>
                      {t('dnsmpi', 'text.email_hint')}
                    </Text>
                  }
                >
                  <Icon type="help" size={IconSize.SIZE_24} />
                </ClosablePopover>
              }
            />
          )}
          <FormInput
            name={FIELD_NAMES.CITY}
            label={t('dnsmpi', 'text.city')}
            placeholder={t('dnsmpi', 'text.enter_city')}
          />
          <FormSelect
            name={FIELD_NAMES.COUNTRY}
            label={t('dnsmpi', 'text.state')}
            placeholder=""
          >
            {STATES.map((state) => (
              <FormSelectItem value={state} key={state}>
                {state}
              </FormSelectItem>
            ))}
          </FormSelect>
          <FormInput
            name={FIELD_NAMES.ZIP}
            label={t('dnsmpi', 'text.zip')}
            placeholder={t('dnsmpi', 'text.enter_zip')}
          />
          {withCaptcha && (
            <Spacing
              withoutBottom
              withoutLeft
              withoutRight
              size={isPhone ? SpacingSize.NONE : SpacingSize.NORMAL}
            >
              <FormCaptcha name={FIELD_NAMES.CAPTCHA} />
            </Spacing>
          )}
          <Spacing
            withoutBottom
            withoutLeft
            withoutRight
            size={isPhone ? SpacingSize.NONE : SpacingSize.NORMAL}
          >
            <Actions
              spacing={SpacingSize.NORMAL}
              itemSpacing={SpacingSize.SHORT}
              vertical={isPhone}
              align={Align.STRETCH}
              fullWidth
              reverse={isPhone}
            >
              <Button
                disabled={isSubmitting}
                fullWidth
                onClick={onClose}
                trackingName="closePopup"
              >
                {t('dnsmpi', 'text.close')}
              </Button>
              <ButtonPrimary
                disabled={isSubmitting}
                fullWidth
                onClick={onSubmit}
                trackingName="submit"
              >
                {t('dnsmpi', 'text.submit')}
                {/* To track 'dnsmpi' popup load completion */}
                <PopupLoadTracker />
              </ButtonPrimary>
            </Actions>
          </Spacing>
          <FormCheckbox
            name={FIELD_NAMES.ALLOWED_INFORMATION}
            verticalAlign={VerticalAlign.CENTER}
          >
            {t('dnsmpi', 'text.tick_information')}
          </FormCheckbox>
          <FormCheckbox
            name={FIELD_NAMES.ALLOWED_PROFILING}
            verticalAlign={VerticalAlign.CENTER}
          >
            {t('dnsmpi', 'text.tick_profiling')}
          </FormCheckbox>
          <FormCheckbox
            name={FIELD_NAMES.ALLOWED_ADVERTISING}
            verticalAlign={VerticalAlign.CENTER}
          >
            {t('dnsmpi', 'text.tick_advertising')}
          </FormCheckbox>
        </Group>
      </form>
    </SpacingLarge>
  );
};

DnsmpiPopupLayout.propTypes /* remove-proptypes */ = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  withEmail: PropTypes.bool.isRequired,
  withCaptcha: PropTypes.bool.isRequired,
};

export default DnsmpiPopupLayout;
