import React from 'react';

import {ICONS} from '../../icons/constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesPopupAnimationLayout from './MicrofeaturesPopupAnimationLayout';
import css from '../styles/MicrofeaturesAnimationPriorityMan.css';

const ICONS_COUNT = 5;

const MicrofeaturesAnimationPriorityMan = () => (
  <MicrofeaturesPopupAnimationLayout
    iconsCount={ICONS_COUNT}
    iconType={ICONS[MICROFEATURES.PRIORITY_MAN]}
    className={css.icon}
  />
);

export default MicrofeaturesAnimationPriorityMan;
