import type {FC, MouseEvent} from 'react';
import React, {Fragment} from 'react';
import Expire from 'react-expire';

import t from '@core/translations/translate';

import {Link} from '@phoenix/typography';
import openPhotoUploadPopup from '@phoenix/mediaUpload/utils/openPhotoUploadPopup';

import type {SystemNotificationProps} from '../types';
import SystemNotificationLayout from '../components/SystemNotificationLayout';

const createHandle =
  (onClose: (e?: MouseEvent) => void) => (e?: MouseEvent) => {
    onClose?.(e);
    openPhotoUploadPopup();
  };

const SystemNotificationPendingDelete: FC<SystemNotificationProps> = ({
  onClose,
  expire,
  inverse,
  ...props
}) => (
  <Expire onExpire={onClose as () => void} until={expire}>
    <SystemNotificationLayout
      onClose={onClose}
      text={() => (
        <Fragment>
          {` ${t('headerNotification', 'text.your_photo_is_deleting')} `}
          <Link
            inverse={inverse}
            onClick={createHandle(onClose)}
            trackingName="uploadPhoto"
          >
            {t('headerNotification', 'text.upload_photo')}
          </Link>
        </Fragment>
      )}
      inverse={inverse}
      iconType="photo"
      type="danger"
      {...props}
    />
  </Expire>
);

export default SystemNotificationPendingDelete;
