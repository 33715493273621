import React from 'react';

import {Loader} from '@phoenix/ui';
import ThemedFavoritesPage from '@phoenix/theming/components/ThemedFavoritesPage';

const FavoritesChunk = (props) => (
  <ThemedFavoritesPage placeholder={<Loader fullscreen />} {...props} />
);

export default FavoritesChunk;
