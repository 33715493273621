import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import photoPropType from '@core/user/photo/constants/propTypes';

import {H1, H3} from '@phoenix/typography';
import {Group, Spacing, IconSubstrate} from '@phoenix/ui';
import {Align, IconSubstrateSize, SpacingSize} from '@phoenix/ui/constants';
import Photo from '@phoenix/user/photo/containers/Photo';

import css from '../styles/WeekSpeakOfferPopupContent.css';

/**
 * Content for week speak popup
 */
const WeekSpeakOfferPopupContent = ({price, photo}) => (
  <div className={css.wrap}>
    <Spacing onlyTop>
      <Spacing>
        <Group space={SpacingSize.SHORT} align={Align.CENTER}>
          <H1 inverse>{t('coins', 'title.special_offer_week_speak')}</H1>
          <H3 muted inverse>
            {t('coins', 'text.special_offer_week_speak_description', {
              '{price}': price,
            })}
          </H3>
        </Group>
      </Spacing>
    </Spacing>
    {photo && (
      <div className={css.iconsWrap}>
        <IconSubstrate
          className={css.icon}
          icon="chat"
          size={IconSubstrateSize.NORMAL}
          round
          inline
        />
        <Photo
          className={css.photo}
          photo={photo}
          lazy={false}
          size={Photo.SIZE.SMALL}
          round
          my
        />
      </div>
    )}
  </div>
);

WeekSpeakOfferPopupContent.propTypes /* remove-proptypes */ = {
  photo: photoPropType,
  price: PropTypes.string,
};

export default WeekSpeakOfferPopupContent;
