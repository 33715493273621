import TELEGRAM_BOT_REWARD_TASK from '../graphql/queries/telegramBotRewardTask.gql';

const updateTelegramBotRewardTask = (client) => {
  const data = client.readQuery({query: TELEGRAM_BOT_REWARD_TASK});
  if (data) {
    client.writeQuery({
      query: TELEGRAM_BOT_REWARD_TASK,
      data: {
        ...data,
        credits: {
          ...data.credits,
          rewardForTasks: {
            ...data.credits.rewardForTasks,
            tasks: {
              ...data.credits.rewardForTasks.tasks,
              telegramSubscription: {
                ...data.credits.rewardForTasks.tasks.telegramSubscription,
                enabled: true,
              },
            },
          },
        },
      },
    });
  }
};

export default updateTelegramBotRewardTask;
