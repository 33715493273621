import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {ButtonPrimary} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

const SuccessDnsmpiPopup = ({onClick}) => {
  return (
    <PopupNotice
      icon="check"
      title={t('dnsmpi', 'text.success_text')}
      actions={[
        <ButtonPrimary onClick={onClick} fullWidth trackingName="closePopup">
          {t('dnsmpi', 'text.close')}
        </ButtonPrimary>,
      ]}
    />
  );
};

SuccessDnsmpiPopup.propTypes /* remove-proptypes */ = {onClick: PropTypes.func.isRequired};

export default SuccessDnsmpiPopup;
