import logger from '@core/logger';
import t from '@core/translations/translate';

import CreditsCurrency from '../constants/CreditsCurrency';

/**
 * Return text for balance label
 * @param {string} currency
 * @param {number} balance
 * @returns {*|[]}
 */
const getBalanceText = (currency, balance) => {
  switch (currency) {
    case CreditsCurrency.COINS:
      return t('coins', 'text.credit_balance', {'{count}': balance});
    default:
      logger.sendWarning(
        `LOST TRANSLATE: (feature: BuyBalance:TextWithIcon , currency: ${currency})`,
      );
  }

  return null;
};

export default getBalanceText;
