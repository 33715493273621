import type {RegionDictionary, TribeDictionary} from '@core/types';
import t from '@core/translations/translate';
import type Size from '@core/search/widget/constants/size';
import getDistance from '@core/search/widget/utils/getDistance';
import removeTypename from '@core/graphql/utils/removeTypename';
import PhotoLevel from '@core/user/photo/constants/PhotoLevel';
import type PhotoSize from '@core/user/photo/constants/PhotoSize';
import type {PrimaryPhotoData} from '@core/user/photo/types';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import type {TRUSTED_STATUSES} from '@core/trusted/constants/status';
import getTrustedStatusById from '@core/trusted/utils/getTrustedStatusById';

import getTagsFromUser from '@phoenix/user/widget/utils/getTagsFromUser';

import type {SearchListUserData} from '../../list/types';
import SEARCH_WIDGET_PHOTO_PRESET from '../constants/searchWidgetPhotoPreset';

export type SearchUser = SearchListUserData & {isLastUser?: boolean};

type SearchWidgetParams = {
  user: SearchUser;
  restartProgressAutoScroll?: (onlyStop?: boolean) => void;
  distanceUnits: string;
  dictionaryTribes?: TribeDictionary[];
  size: Size;
  overlaySize: PhotoSize;
  forceLevel?: PhotoLevel;
  showExtendedDistance?: boolean;
  isHideCity?: boolean;
  regionList?: RegionDictionary[];
};

type NormalizedSearchWidgetProps = {
  attributes: {professionId?: string};
  restartProgressAutoScroll?: (onlyStop?: boolean) => void;
  geo: SearchUser['profile']['location'];
  distanceUnits: string;
  distanceToMySearch: number;
  distanceToMe?: number;
  description: string | null;
  isBlurred: boolean;
  isLastUser?: boolean;
  age: number | null;
  distance: string;
  tags: string[];
  photo: PrimaryPhotoData;
  videos?: SearchUser['profile']['videos'];
  photoCount: number;
  videoCount: number;
  size: Size;
  overlaySize: PhotoSize;
  key: string;
  verifiedStatus: TRUSTED_STATUSES;
  chatUpLine: string;
  needPayToViewProfile: boolean;
};

/**
 * Util function for reusable normalizing of props required for user widget
 * @see SearchWidgetsDisplayer
 * @see LikeGalleryList
 */
const normalizePropsForSearchWidget = ({
  user,
  restartProgressAutoScroll,
  distanceUnits: defaultDistanceUnits,
  dictionaryTribes,
  size,
  overlaySize,
  forceLevel = PhotoLevel.NORMAL,
  showExtendedDistance = false,
  isHideCity = false,
  regionList = [],
}: SearchWidgetParams): NormalizedSearchWidgetProps => {
  const {
    trusted: {sv},
    profile: {attributes, age, chatUpLine, ...profile},
    isBlurred,
    isLastUser,
    viewPermissions,
  } = user;

  const {isWebcamProfile, isVirtualAssistant} = profile.statuses;
  const {distanceUnit, distanceGroup} = profile.location;
  const distanceToMySearch = distanceGroup?.toMySearch;

  const needPayToViewProfile =
    !isVirtualAssistant && viewPermissions ? viewPermissions.search : false;

  // Need to show distance unit related to target user, otherwise - related to current user
  const distanceUnits =
    isBlurred && distanceToMySearch && distanceUnit
      ? distanceUnit
      : defaultDistanceUnits;

  return removeTypename({
    ...profile,
    attributes,
    professionId: attributes && attributes.professionId,
    restartProgressAutoScroll,
    /**
     * Renamed to 'geo' because 'withRouter' use 'location' prop too.
     * Used for defining distance for scroll tracking (User Behavior)
     * @see SearchUserWidget
     */
    geo: profile.location,
    /**
     * Split data for customized usage in some parts of product
     * @see SearchUserWidgetRoundedWithoutActionsLayout
     */
    distanceUnits,
    distanceToMySearch,
    distanceToMe: distanceGroup?.toMe,
    description: isVirtualAssistant
      ? t('virtualAssistant', 'text.playWithMe')
      : null,
    isBlurred,
    isLastUser,
    age: isVirtualAssistant ? null : age,
    /**
     * Fully readable distance with fallback to location
     * Used in most part of different search widgets.
     */
    distance: getDistance({
      ...profile.location,
      distanceUnits,
      showExtendedDistance,
      isHideCity,
      regionList,
    }),
    tags: getTagsFromUser({
      user,
      dictionaryTribes,
      maxCount: 3,
      onlyFirstTribe: true,
      excludePartnerNetwork: true,
    }),
    photo: getPrimaryPhotoData(
      user.profile,
      SEARCH_WIDGET_PHOTO_PRESET,
      forceLevel,
    ),
    photoCount: isWebcamProfile
      ? profile.photos.livecamProfilePhotoCount
      : profile.photos.count,
    videoCount: isWebcamProfile
      ? profile.videos.livecamProfileVideoCount
      : profile.videos.count,
    size,
    overlaySize,
    key: user.id,
    verifiedStatus: getTrustedStatusById(sv),
    chatUpLine,
    needPayToViewProfile,
  });
};

export default normalizePropsForSearchWidget;
