import {FULL_FUNNEL_PP_AFTER_REG} from '@phoenix/application/constants/marketingProductConfigurationName';

import MARKETING_PRODUCT_CONFIGURATION_QUERY from '../graphql/queries/marketingProductConfiguration.gql';
import {PP_AFTER_REG} from '../constants/accessErrorCode';

/**
 * Payment page after registration without skip.
 */
export default {
  errorCode: PP_AFTER_REG,
  query: MARKETING_PRODUCT_CONFIGURATION_QUERY,
  resolver: (data) =>
    data?.userFeatures?.marketingProductConfigurationName !==
    FULL_FUNNEL_PP_AFTER_REG,
};
