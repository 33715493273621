const STORAGE_KEY = {
  /**
   * The timeout to postpone a logic's start
   * @const
   */
  LOGIC_TIMEOUT: 'Timeout',

  /**
   * Whether a logger is enabled
   * @const
   */
  LOGGER_ENABLED: 'WebPush::Logger::enabled',

  /**
   * The counter to store the denial amount
   * @const
   */
  DENY_COUNT: 'DenyCount',

  /**
   * The counter to store the ignoring amount
   * @const
   */
  IGNORE_COUNT: 'IgnoreCount',

  /**
   * How long time all the logics should be paused for?
   * @const
   */
  PAUSE_EXPIRATION_TIME: 'PauseExpirationTime',
};

export default STORAGE_KEY;
