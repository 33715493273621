import TRACK from '../constants/track';
import WebPushService from '../WebPushService';
import webPushLogger from '../utils/webPushLogger';

/**
 * @class NativeHandler
 */
export default class NativeHandler {
  constructor(options = {}) {
    this.trackingService = options.trackingService;
    this.options = options;
    this.webPush = WebPushService.getInstance(options);

    if (!this.isAllowedPushService()) {
      return;
    }

    this.track();
  }

  /**
   * @public
   * @return {boolean}
   */
  isAllowedPushService() {
    return Boolean(this.webPush);
  }

  /**
   * @public
   * @return {boolean}
   */
  isPermissionDenied() {
    return this.webPush.isPermissionDenied();
  }

  /**
   * @public
   * @return {*|boolean}
   */
  isPermissionDefault() {
    return this.webPush.isPermissionDefault();
  }

  /**
   * @public
   * @return {*|boolean}
   */
  isPermissionGranted() {
    return this.webPush.isPermissionGranted();
  }

  /**
   * @private
   */
  track() {
    if (
      this.webPush.isPermissionDenied() &&
      !this.options.defaultBlocked &&
      !this.trackingService.getStorageItem()
    ) {
      this.trackingService.track(TRACK.ACTIONS.BLOCKED);
      this.trackingService.setStorageItem();

      return;
    }

    if (
      !this.webPush.isPermissionDenied() &&
      this.trackingService.getStorageItem()
    ) {
      this.trackingService.removeStorageItem();
    }
  }

  /**
   * @public
   */
  trySubscribe() {
    this.webPush
      .subscribe()
      .catch((error) => webPushLogger(error, this.options.logicName));
  }
}
