import omit from 'lodash/omit';

import ThreeDSecureProcessor from '@core/payment/payProcess/utils/ThreeDSecureProcessor';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import PaymentPendingPopupLayout from '../../../paymentPending/components/PaymentPendingPopupLayout';
import ThreeDSecureAbortPopup from '../../../widgets/threeDSecure/components/ThreeDSecureAbortPopup';

const openPendingPopupIfNeeded: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;
  const {via} = paymentData;
  const {orderId, withPendingPopup} = paymentResult;

  if (withPendingPopup) {
    const processor = new ThreeDSecureProcessor(
      via,
      orderId,
      PaymentPendingPopupLayout,
      ThreeDSecureAbortPopup,
    ).openPendingPopup();

    const result = await processor.getPromise();

    if (typeof result === 'string') {
      return {
        ...data,
        flowStatus: result,
      };
    }

    const {via: resultVia, ...rest} = result;
    return {
      ...data,
      paymentData: {
        ...data.paymentData,
        via: resultVia,
      },
      paymentResult: {
        ...data.paymentResult,
        ...rest,
      },
    };
  }

  return {...data, paymentResult: omit(paymentResult, 'withPendingPopup')};
};

export default openPendingPopupIfNeeded;
