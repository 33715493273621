import merge from 'lodash/merge';
import {useQuery} from '@apollo/client';

import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';

import PERSONAL_IDENTITY_QUERY from '../graphql/queries/personalIdentity.gql';

/**
 * @description Hook that combine query hooks and return combined data
 * @return {object}
 */
const usePersonalIdentityData = () => {
  const {
    data: {userFeatures: {personalIdentity: verifyData} = {}} = {},
    loading: verifyLoading,
    error,
  } = useQuery(PERSONAL_IDENTITY_QUERY);
  const {
    data: paymentData,
    loading: paymentLoading,
    error: paymentError,
  } = useQuery(PAYMENT_ACCOUNT_STATUS_QUERY);

  if (paymentLoading || paymentError) {
    return {loading: paymentLoading, error: paymentError};
  }

  const {
    payment: {
      accountStatus: {isPaid},
    },
  } = paymentData;

  return {
    data: merge({}, verifyData, {isPaid}),
    loading: verifyLoading || paymentLoading,
    error: error || paymentError,
  };
};

export default usePersonalIdentityData;
