import payFromPopup from '@core/payment/payProcess/paymentFlow/methods/payFromPopup';
import cachePurchasedPackage from '@core/payment/payProcess/paymentFlow/methods/cachePurchasedPackage';
import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';
import resetSelectedAdditionalPackages from '@core/payment/payProcess/paymentFlow/methods/resetSelectedAdditionalPackages';
import addBrowserDetails from '@core/payment/payProcess/paymentFlow/methods/addBrowserDetails';
import updateCSRFToken from '@core/payment/payProcess/paymentFlow/methods/updateCSRFToken';
import externalThreeDSRedirect from '@core/payment/payProcess/paymentFlow/methods/externalThreeDSRedirect';
import type {PaymentFlowMethods} from '@core/payment/payProcess/types/paymentProcess';

import processThreeDSecureAnswer from '../methods/processThreeDSecureAnswer';

const getExchangeCoinsPopupFlow = (): PaymentFlowMethods => {
  return [
    reportStartPayment,
    cachePurchasedPackage,
    addBrowserDetails,
    payFromPopup,
    updateCSRFToken,
    externalThreeDSRedirect,
    processThreeDSecureAnswer,
    reportEndPayment,
    resetSelectedAdditionalPackages,
    processPaymentAnswer,
  ];
};

export default getExchangeCoinsPopupFlow;
