import React from 'react';

import t from '@core/translations/translate';
import PopupService from '@core/popup/utils/PopupService';

import {ButtonPrimary} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

/**
 * @see openTrustedVerificationExceedStepPopup.js
 */
const TrustedVerificationExceedStepLayout = () => {
  return (
    <PopupNotice
      icon="danger"
      title={t('paymentTrusted', 'text.error.oops')}
      description={
        <TextMuted>{t('paymentTrusted', 'text.error.description')}</TextMuted>
      }
      actions={[
        <ButtonPrimary
          fullWidth
          // eslint-disable-next-line react/jsx-handler-names
          onClick={PopupService.closePopup}
          trackingName="tryAgain"
        >
          {t('paymentTrusted', 'button.sms.try_again')}
        </ButtonPrimary>,
      ]}
    />
  );
};

export default TrustedVerificationExceedStepLayout;
