import {pipe} from 'rxjs';
import {filter} from 'rxjs/operators';

import getHistory from '@core/application/utils/getHistory';

import openRemarketingOfferPopup from '@phoenix/remarketing/offerPopup/utils/openRemarketingOfferPopup';
import popupDisplay from '@phoenix/remarketing/offerPopup/utils/popupDisplay';

/**
 * Open remarketing offer in case if is available and if we are on allowed route.
 *
 * @returns {Object} result
 * @returns {Function} result.operations
 * @returns {Function} result.subscription
 */
const remarketingOfferPopupAction = () => {
  let opening = false;

  return {
    operations: () =>
      pipe(
        filter(
          ([{data}]) => !opening && popupDisplay.isAllowed(data.route.current),
        ),
      ),
    subscription: () => {
      opening = true;
      openRemarketingOfferPopup({
        checkShown: false, // because already checked by `popupDisplay.isAllowed` above.
        returnPathAfterPay: getHistory().location.pathname,
      }).then(() => {
        opening = false;
      });
    },
  };
};

export default remarketingOfferPopupAction;
