import type {ReactNode, FC} from 'react';
import type {InViewHookResponse} from 'react-intersection-observer';

import useInventoryTracking from '@phoenix/tracking/inventory/utils/useInventoryTracking';
import type {WebcamData} from '@phoenix/search/list/types';

import useWebCamRegister from '../utils/useWebCamRegister';

type WebCamUserPlacementParams = {
  webCamData: WebcamData;
  onClick?: () => void;
  children: (props: {
    onClick?: () => void;
    key?: string;
    ref: InViewHookResponse['ref'];
    webCamData: WebcamData;
  }) => ReactNode;
  key?: string;
  withPaymentRedirect?: boolean;
};

/**
 * Share webcam user data, provide inventory tracking
 * and open new browser tab with partner webcam link if it needs.
 * Use it for webCam model placements where placement data info related with this model.
 * For example, WebCam model on search.
 */
const WebCamUserPlacement: FC<WebCamUserPlacementParams> = ({
  webCamData,
  onClick,
  children,
  withPaymentRedirect,
  ...props
}) => {
  const register = useWebCamRegister({
    webCamData,
    onClick,
    withPaymentRedirect,
  });
  const {ref, onClick: handleClick} = useInventoryTracking({
    onClick: register,
    placement: webCamData.trackingName,
    attributes: webCamData.trackingAttributes,
    partner: webCamData.partner,
    promocode: webCamData.promoCode,
  });

  return children({...props, onClick: handleClick, ref, webCamData});
};

export default WebCamUserPlacement;
