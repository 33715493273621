import {gotoUrl} from '@core/utils/url';

import openFreeFeaturePopup from '@phoenix/freeFeaturePopup';
import FreeFeaturesNames from '@phoenix/freeFeaturePopup/constants/freeFeatureNames';

import EXTRA_PRIORITY_MAN_QUERY from './graphql/queries/extraPriorityMan.gql';
import getTimeLeft from '../../utils/getTimeLeft';

/**
 * Open extra Priority Man Popup
 * @param config {Object}
 * @param config.processId {string}
 * @param config.client {ApolloClient}
 * @returns {Promise<void>}
 */
const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {
        priorityMan: {begin},
      },
    },
  } = await client.query({
    query: EXTRA_PRIORITY_MAN_QUERY,
    variables: {processId},
  });

  if (!begin.enabled) return;

  const time = getTimeLeft(begin.expireAt);
  const timeInMins = Math.round(time / 60);

  openFreeFeaturePopup({
    name: FreeFeaturesNames.PRIORITY_MAN_START,
    onActionClick: () => gotoUrl('/search'),
    options: {
      time,
      timeInMins,
      withPopupName: true,
    },
  });
};

export default action;
