import getCoinsMatchCommunicationsIds from './getCoinsMatchCommunicationsIds';
import TELEGRAM_BOT_REWARD_TASK from '../graphql/queries/telegramBotRewardTask.gql';

/**
 * Need or not open telegram bot popup
 * @param {object} client
 * @param {string} currentUserId
 * @param {number} maxMatchCount
 * @returns {Object}
 */
/* eslint-disable consistent-return */
const getNeedOpenTelegramBotPopup = async (
  client,
  currentUserId,
  maxMatchCount,
) => {
  const {
    data: {
      credits: {
        rewardForTasks: {enabled, tasks},
      },
    },
  } = await client.query({query: TELEGRAM_BOT_REWARD_TASK});

  const startTelegramSubscriptionEnabled =
    enabled &&
    !tasks.telegramSubscription.completed &&
    tasks.telegramSubscription.enabled;

  const startMatchCommunicationUserIds =
    getCoinsMatchCommunicationsIds(currentUserId);

  return {
    startTelegramSubscriptionEnabled,
    needOpenTelegramBotPopup:
      startMatchCommunicationUserIds.length < maxMatchCount &&
      startTelegramSubscriptionEnabled,
  };
};

export default getNeedOpenTelegramBotPopup;
