import createTranslationsMap from '@core/translations/createTranslationsMap';
import mergeTranslationMaps from '@core/translations/mergeTranslationMaps';

/**
 * Package titles keys
 */
export const PACKAGE_TITLES = {
  ONE_DAY_MEMBERSHIP: '1 day membership',
  THREE_DAY_TRIAL: '3 day trial',
  ONE_MONTH_MEMBERSHIP: '1 Month Membership',
  TWO_MONTHS_MEMBERSHIP: '2 Months Membership',
  THREE_MONTHS_MEMBERSHIP: '3 Months Membership',
  SIX_MONTHS_MEMBERSHIP: '6 Months Membership',
  TWELVE_MONTHS_MEMBERSHIP: '12 Months Membership',
  WEEK_MEMBERSHIP: '1 Week Membership',
  PREMIUM_DATER: 'Premium dater',
  PREMIUM_DATER_PLUS_MOB: 'Premium dater + Mobile Access',
  FOURTEEN_DAYS_TRIAL: '14 day trial',
  FLIRT_ALARM: 'Flirt Alarm',
  PRIME_DATER: 'Prime Dater',
  TOP_DATER: 'Top Dater',
  SUPER_BOOST: 'super boost',
  INCOGNITO: 'Incognito',
  PRIORITY_MAN: 'priority man',
  ONE_DAY_FREE: '1 day free membership',
  TOP_IN_CHAT: 'top in chat',
  SEVEN_DAYS_TRIAL: '7 days trial',
  PRIORITY_WOMAN: 'Search Queen',
  CHATAHOLIC_PACK_PLUS_MOB: 'Chataholic + Mobile Access',
  SEX_ALARM: 'sex alarm',
  MICRO_INCOGNITO: 'micro incognito',
  COMMUNICATION_UPGRADE: 'Communication Upgrade',
  MORE_LIKES: 'more likes',
  ONE_MONTH_REMARKETING_MEMBERSHIP: '1 Months Remarketing Membership',
  WEEK_REMARKETING_MEMBERSHIP: '1 Week Remarketing Membership',
  EXTRA_BENEFITS: 'Extra Benefits',
  EXTRA_SECURITY: 'Extra security',
  EXTRA_SECURITY_PLUS_MOB: 'Extra security + Mobile Access',
  POPULAR: 'POPULAR',
  LOW_COST: 'LOW COST',
  MAX_USE: 'MAX USE',
  PRO_PACK: 'PRO PACK',
  CHATAHOLIC_PACK: 'Chataholic',
  ACTIVATION_FEE: 'Activation fee',
  GIFTED_ONE_DAY: '1day+1day free',
  GIFTED_TWO_DAYS: '3day+2day free',
  GIFTED_THREE_DAYS: '7day+3day free',
  GIFTED_ONE_WEEK: '1month+1week free',
  GIFTED_THREE_WEEKS: '3month+3week free',
  GIFTED_SIX_WEEKS: '6month+6week free',
  GIFTED_TWELVE_WEEKS: '12month+12weeks free',
  FULL_UPGRADE: 'Full Upgrade',
  THREE_DAY_TRIAL_PLUS_ONE_DAY_FREE: '3 day trial + 1 day free',
  THREE_DAY_TRIAL_PLUS_TWO_DAY_FREE: '3 day trial + 2 days free',
  THREE_DAY_PART: '3 day trial_part',
  FIVE_DAYS_TRIAL: '5 day trial',
  ONE_MONTH_MEMBERSHIP_PART: '1 Month Membership_part',
  THREE_MONTHS_MEMBERSHIP_OFFER: '3 Months Membership Offer',
  TWELVE_MONTHS_MEMBERSHIP_OFFER: '12 Months Membership Offer',
  EXTRA_DISCOUNT: 'Extra Discount',
  PRIORITY_MAN_1_DAY_TRIAL: 'Priority Man + 1d trial',
  FIRST_TO_TALK_1_DAY_TRIAL: 'First to Talk + 1d trial',
  FIRST_ALARM_1_DAY_TRIAL: 'Flirt Alarm + 1d trial',
  INCOGNITO_1_DAY_TRIAL: 'Incognito + 1d trial',
  MORE_LIKES_1_DAY_TRIAL: 'More Likes + 1d trial',
  SUPER_BOOST_1_DAY_TRIAL: 'Super Boost + 1d trial',
  MORE_LIKES_OFFER: 'more likes offer',
  PRIORITY_MAN_OFFER: 'priority man offer',
  SUPER_BOOST_OFFER: 'super boost offer',
  SEX_ALARM_OFFER: 'sex alarm offer',
  FLIRT_ALARM_OFFER: 'flirt alarm offer',
  TOP_IN_CHAT_OFFER: 'top in chat offer',
  SEARCH_UPGRADE: 'Search Upgrade',
  ONE_DAY_PLUS_ONE: '1 DAY+1 DAY FREE',
  SEVENTY_FIVE_CREDITS: '75 credits',
  HUNDRED_CREDITS: '100 tn credits',
  HUNDRED_TEN_CREDITS: '110 coins',
  HUNDRED_FIFTY_CREDITS: '150 tn credits',
  HUNDRED_SEVENTY_FIVE_CREDITS: '175 credits',
  TWO_HUNDRED_CREDITS: '220 coins',
  TWO_HUNDRED_SEVENTY_FIVE_CREDITS: '275 credits',
  TWO_HUNDRED_EIGHTY_CREDITS: '280 coins',
  FIVE_HUNDRED_CREDITS: '500 coins',
  FIVE_HUNDRED_FIFTY_CREDITS: '550 credits',
  SEVEN_HUNDRED_TWENTY_COINS: '720 coins',
  SEVEN_HUNDRED_CREDITS: '700 tn credits',
  ONE_THOUSAND_TWO_HUNDRED_SEVENTY_COINS: '1270 coins',
  ONE_THOUSAND_FOUR_HUNDRED_NINETY_COINS: '1490 coins',
  THOUSAND_FIVE_HUNDRED_CREDITS: '1500 tn credits',
  TWO_THOUSAND_SIX_HUNDRED_FORTY_COINS: '2640 coins',
  THREE_THOUSANDS_CREDITS: '3000 tn credits',
  THREE_THOUSAND_THREE_HUNDRED_COINS: '3300 coins',
  FIVE_THOUSAND_FIVE_HUNDRED_COINS: '5500 coins',
  USER_PACKAGE_FEATURES_2: 'User Package (features: 2)',
  USER_PACKAGE_FEATURES_3: 'User Package (features: 3)',
  USER_PACKAGE_FEATURES_4: 'User Package (features: 4)',
  USER_PACKAGE_FEATURES_5: 'User Package (features: 5)',
  USER_PACKAGE_FEATURES_6: 'User Package (features: 6)',
};

/**
 * Payment package translations, used for backbone package translations
 * TODO: Remove when React PP wil be ready
 */
export const BACKBONE_PACKAGES_TITLE_TRANSLATES = createTranslationsMap(
  (t) => ({
    '500_coins_balance_boost': t(
      'paymentPagePackage',
      'title.500_coins_balance_boost',
    ),
    extra_coins_payment: t('paymentPagePackage', 'title.extra_coins_payment'),
    '200_coins_monthly': t('paymentPagePackage', 'title.200_coins_monthly'),
    '7DAY2023': t('paymentPagePackage', 'title.coins_feature'),
    '7DAY2030': t('paymentPagePackage', 'title.coins_gold'),
    '30DAY2023': t('paymentPagePackage', 'title.coins_feature'),
    'Prime Dater': t('paymentPagePackage', 'title.Prime Dater'),
    'Top Dater': t('paymentPagePackage', 'title.Top Dater'),
    Basic_1day: t('paymentPagePackage', 'title.Basic_1day'),
    Classic_3day: t('paymentPagePackage', 'title.Classic_3day'),
    Silver_7day: t('paymentPagePackage', 'title.Silver_7day'),
    Elite_1month: t('paymentPagePackage', 'title.Elite_1month'),
    Gold_3month: t('paymentPagePackage', 'title.Gold_3month'),
    Platinum_6Month: t('paymentPagePackage', 'title.Platinum_6Month'),
    Diamond_12Month: t('paymentPagePackage', 'title.Diamond_12Month'),
    '1DAY1': t('paymentPagePackage', 'title.1DAY1'),
    '10YEAR286': t('paymentPage', 'title.10YEAR286'),
    '28DAY9': t('paymentPagePackage', 'title.28DAY9'),
    '14DAY1': t('paymentPagePackage', 'title.14DAY1'),
    '28DAY3': t('paymentPagePackage', 'title.28DAY3'),
    '28DAY8': t('paymentPagePackage', 'title.Extra Benefits'),
    '28DAY1': t('paymentPagePackage', 'title.28DAY1'),
    '28DAY201': t('paymentPagePackage', 'title.28DAY201'),
    '84DAY1': t('paymentPagePackage', 'title.84DAY1'),
    'Extra Discount': t('paymentPagePackage', 'title.Extra Discount'),
    'Full upgrade': t('paymentPagePackage', 'title.Full upgrade'),
    '1 Month Membership with upper repeat amount': t(
      'paymentPagePackage',
      'title.1 Month Membership with upper repeat amount',
    ),
    '168DAY1': t('paymentPagePackage', 'title.168DAY1'),
    '3DAY1': t('paymentPagePackage', 'title.3DAY1'),
    '7DAY1': t('paymentPagePackage', 'title.7DAY1'),
    '7DAY110': t('paymentPagePackage', 'title.7DAY110'),
    '336DAY1': t('paymentPagePackage', 'title.336DAY1'),
    '4DAY1': t('paymentPagePackage', 'title.4DAY1'),
    '5DAY1': t('paymentPagePackage', 'title.5DAY1'),
    '30DAY1': t('paymentPagePackage', 'title.30DAY1'),
    '7DAY810': t('paymentPagePackage', 'title.7DAY810'),
    '56DAY1': t('paymentPagePackage', 'title.56DAY1'),
    '1 day free membership': t(
      'paymentPagePackage',
      'text.1 day free membership',
    ),
    '1 day trial': t('paymentPagePackage', 'title.1DAY1'),
    '3 day trial': t('paymentPagePackage', 'title.3 day trial'),
    'Lifetime offer Membership': t(
      'paymentPagePackage',
      'title.Lifetime offer Membership',
    ),
    '3 day trial + 1 day free': t(
      'paymentPagePackage',
      'title.3 day trial + 1 day free',
    ),
    '3 day trial + 2 day free': t(
      'paymentPagePackage',
      'title.3 day trial + 2 day free',
    ),
    '3 days remarketing offer': t(
      'paymentPagePackage',
      'title.3 days remarketing offer',
    ),
    '5 day trial': t('paymentPagePackage', 'title.5 day trial'),
    '7 days trial': t('paymentPagePackage', 'title.7 days trial'),
    '14 day trial': t('paymentPagePackage', 'title.14 day trial'),
    '150 tn credits': t('paymentPagePackage', 'title.150 tn credits'),
    '700 tn credits': t('paymentPagePackage', 'title.700 tn credits'),
    '1500 tn credits': t('paymentPagePackage', 'title.1500 tn credits'),
    '3000 tn credits': t('paymentPagePackage', 'title.3000 tn credits'),
    '75 credits': t('paymentPagePackage', 'title.75 credits'),
    '175 credits': t('paymentPagePackage', 'title.175 credits'),
    '275 credits': t('paymentPagePackage', 'title.275 credits'),
    '550 credits': t('paymentPagePackage', 'title.550 credits'),
    '220 coins': t('paymentPagePackage', 'title.220 coins'),
    '500 coins': t('paymentPagePackage', 'title.500 coins'),
    '1270 coins': t('paymentPagePackage', 'title.1270 coins'),
    '2640 coins': t('paymentPagePackage', 'title.2640 coins'),
    '5500 coins': t('paymentPagePackage', 'title.5500 coins'),
    '110 coins': t('paymentPagePackage', 'title.110 coins'),
    '150 coins': t('paymentPagePackage', 'title.150 coins'),
    '280 coins': t('paymentPagePackage', 'title.280 coins'),
    '720 coins': t('paymentPagePackage', 'title.720 coins'),
    '1490 coins': t('paymentPagePackage', 'title.1490 coins'),
    '3300 coins': t('paymentPagePackage', 'title.3300 coins'),
    '6000 coins': t('paymentPagePackage', 'title.6000 coins'),
    '3000 coins': t('paymentPagePackage', 'title.3000 coins'),
    '1500 coins': t('paymentPagePackage', 'title.1500 coins'),
    '600 coins': t('paymentPagePackage', 'title.600 coins'),
    '300 coins': t('paymentPagePackage', 'title.300 coins'),
    '440 coins': t('paymentPagePackage', 'title.440 coins'),
    '1100 coins': t('paymentPagePackage', 'title.1100 coins'),
    '2200 coins': t('paymentPagePackage', 'title.2200 coins'),
    '4400 coins': t('paymentPagePackage', 'title.4400 coins'),
    '15000 coins': t('paymentPagePackage', 'title.15000 coins'),
    '52500 coins': t('paymentPagePackage', 'title.52500 coins'),
    '11000 coins': t('paymentPagePackage', 'title.11000 coins'),
    '38500 coins': t('paymentPagePackage', 'title.38500 coins'),
    '1 Week Membership': t('paymentPagePackage', 'title.1 Week Membership'),
    '1 Week Remarketing Membership': t(
      'paymentPagePackage',
      'title.1 Week Remarketing Membership',
    ),
    '1 Month Membership': t('paymentPagePackage', 'title.1 Month Membership'),
    '1 Months Remarketing Membership': t(
      'paymentPagePackage',
      'title.1 Months Remarketing Membership',
    ),
    remarketingOffer1M: t('paymentPagePackage', 'title.remarketingOffer1M'),
    '3 Months Membership': t('paymentPagePackage', 'title.3 Months Membership'),
    '3 Months Membership Offer': t(
      'paymentPagePackage',
      'title.3 Months Membership Offer',
    ),
    '6 Months Membership': t('paymentPagePackage', 'title.6 Months Membership'),
    'Extra security': t('paymentPagePackage', 'title.Extra security'),
    'Extra Benefits': t('paymentPagePackage', 'title.Extra Benefits'),
    'Extra security + Mobile Access': t(
      'paymentPagePackage',
      'title.Extra security + Mobile Access',
    ),
    '2 Months Membership': t('paymentPagePackage', 'title.2 Months Membership'),
    Chataholic: t('paymentPagePackage', 'title.Chataholic'),
    'Chataholic + Mobile Access': t(
      'paymentPagePackage',
      'title.Chataholic + Mobile Access',
    ),
    'Premium dater': t('paymentPagePackage', 'title.Premium dater'),
    'Premium dater + Mobile Access': t(
      'paymentPagePackage',
      'title.Premium dater + Mobile Access',
    ),
    '12 Months Membership': t(
      'paymentPagePackage',
      'title.12 Months Membership',
    ),
    '1HOUR276': t('paymentPagePackage', 'title.Flirt Alarm'),
    '1 DAY+1 DAY FREE': t('paymentPagePackage', 'title.2DAY1'),
    '1 day FREE': t('paymentPagePackage', 'text.1_day_free'),
    'package_description_premium membership': t(
      'paymentPagePackage',
      'title.package_description_premium membership',
    ),
    'User Package (features: 2)': t(
      'paymentPagePackage',
      'title.User Package (features: 2)',
    ),
    'User Package (features: 3)': t(
      'paymentPagePackage',
      'title.User Package (features: 3)',
    ),
    'User Package (features: 4)': t(
      'paymentPagePackage',
      'title.User Package (features: 4)',
    ),
    'User Package (features: 5)': t(
      'paymentPagePackage',
      'title.User Package (features: 5)',
    ),
    'User Package (features: 6)': t(
      'paymentPagePackage',
      'title.User Package (features: 6)',
    ),
    'Dating Beast': t('paymentPagePackage', 'title.Dating Beast'),
    'Dating Guru': t('paymentPagePackage', 'title.Dating Guru'),
    '1 day trial Starter': t('paymentPagePackage', 'title.1_day_trial_starter'),
    '3 day trial Starter': t('paymentPagePackage', 'title.1_day_trial_starter'),
    '1 Week trial Silver': t('paymentPagePackage', 'title.1_week_trial_silver'),
    '1 Month Membership Silver': t(
      'paymentPagePackage',
      'title.1_week_trial_silver',
    ),
    '1 Month Membership Gold': t(
      'paymentPagePackage',
      'title.1_month_membership_gold',
    ),
    '3 Months Membership Gold': t(
      'paymentPagePackage',
      'title.1_month_membership_gold',
    ),
    '3 Months Membership Platinum': t(
      'paymentPagePackage',
      'title.3_months_membership_platinum',
    ),
    '6 Months Membership Platinum': t(
      'paymentPagePackage',
      'title.3_months_membership_platinum',
    ),
    'LOW COST': t('paymentPagePackage', 'title.low_cost'),
    POPULAR: t('paymentPagePackage', 'title.popular'),
    'MAX USE': t('paymentPagePackage', 'title.max_use'),
    'PRO PACK': t('paymentPagePackage', 'title.pro_pack'),
    'Lifetime Membership': t('paymentPagePackage', 'title.Lifetime Membership'),
    '2DAY1': t('paymentPagePackage', 'title.2DAY1'),
    '100 tn credits': t('paymentPagePackage', 'title.100 credits'),
  }),
);

/**
 * Payment package translations (long), used for backbone package translations
 * TODO: Remove when React PP wil be ready
 */
export const BACKBONE_PACKAGES_LONG_TITLE_TRANSLATES = mergeTranslationMaps(
  BACKBONE_PACKAGES_TITLE_TRANSLATES,
  createTranslationsMap((t) => {
    const oneMonth = t('paymentPage', 'title.1 Month Membership');
    const threeMonth = t('paymentPage', 'title.3 Months Membership');
    const sixMonth = t('paymentPage', 'title.6 Months Membership');
    const twelveMonth = t('paymentPage', 'title.12 Months Membership');
    const oneWeek = t('paymentPage', 'title.1 Week Membership');
    const threeDay = t('paymentPage', 'title.3 day trial');
    const oneDay = t('paymentPage', 'title.1 day free membership');

    return {
      '3 day trial': threeDay,
      '3 Months Membership': threeMonth,
      '6 Months Membership': sixMonth,
      '1 Month Membership': oneMonth,
      '1 day trial': oneDay,
      '1 Week Membership': oneWeek,
      '1 day membership': oneDay,
      '1 Day Membership': oneDay,
      '1 day free membership': oneDay,
      '12 Months Membership': twelveMonth,
      '14 day trial': t('paymentPage', 'title.14 day trial'),
      '3 day trial + 1 day free': t(
        'paymentPage',
        'title.3 day trial + 1 day free',
      ),
      '3 day trial + 2 days free': t(
        'paymentPage',
        'title.3 day trial + 2 days free',
      ),
      '1 Months Remarketing Membership': t(
        'paymentPage',
        'title.1 Months Remarketing Membership',
      ),
      Chataholic: t('paymentPage', 'title.Chataholic'),
      'Premium dater': t('paymentPage', 'title.Premium dater'),
      'Extra Discount': t('paymentPage', 'title.Extra Discount'),
      'Extra security': t('paymentPage', 'title.Extra security'),
      '3DAY1': t('paymentPage', 'title.3DAY1'),
      '7DAY1': t('paymentPage', 'title.7DAY1'),
      '28DAY9': t('paymentPage', 'title.28DAY9'),
      '1YEAR43': t('paymentPage', 'title.1YEAR43'),
      '28DAY3': t('paymentPage', 'title.28DAY3'),
      '28DAY274': t('paymentPage', 'title.28DAY274'),
      '28DAY1': t('paymentPage', 'title.28DAY1'),
      '7DAY110': t('paymentPage', 'title.7DAY110'),
      '1 Week Remarketing Membership': t(
        'paymentPage',
        'title.1 Week Remarketing Membership',
      ),
      '84DAY1': t('paymentPage', 'title.84DAY1'),
      '168DAY1': t('paymentPage', 'title.168DAY1'),
      '28DAY201': t('paymentPage', 'title.28DAY201'),
      '336DAY1': t('paymentPage', 'title.336DAY1'),
      'Chataholic + Mobile Access': t(
        'paymentPage',
        'title.Chataholic + Mobile Access',
      ),
      'Extra security + Mobile Access': t(
        'paymentPage',
        'title.Extra security + Mobile Access',
      ),
      'Premium dater + Mobile Access': t(
        'paymentPage',
        'title.Premium dater + Mobile Access',
      ),
      '14DAY1': t('paymentPage', 'title.14DAY1'),
      '12 Months Membership Offer': t(
        'paymentPage',
        'title.12 Months Membership Offer',
      ),
      '4DAY1': t('paymentPage', 'title.4DAY1'),
      '5DAY1': t('paymentPage', 'title.5DAY1'),
      '3 days remarketing offer': t(
        'paymentPage',
        'title.3 days remarketing offer',
      ),
      '3 Months Membership Offer': t(
        'paymentPage',
        'title.3 Months Membership Offer',
      ),
      '30DAY1': t('paymentPage', 'title.30DAY1'),
      '28DAY110': t('paymentPage', 'title.28DAY110'),
      '7DAY810': t('paymentPage', 'title.7DAY810'),
      '2 Months Membership': t('paymentPage', 'title.2 Months Membership'),
      '56DAY1': t('paymentPage', 'title.56DAY1'),
      remarketingOffer_NM_1: t('paymentPage', 'title.remarketingOffer_NM_1'),
      remarketingOffer_NM_2: t('paymentPage', 'title.remarketingOffer_NM_2'),
      remarketingOffer_NM_3: t('paymentPage', 'title.remarketingOffer_NM_3'),
      '1DAY1': t('paymentPage', 'title.1DAY1'),
      '1DAY210': t('paymentPage', 'title.1DAY210'),
      '1DAY273': t('paymentPage', 'title.1DAY273'),
      '1DAY274': t('paymentPage', 'title.1DAY274'),
      '1DAY275': t('paymentPage', 'title.1DAY275'),
      '1DAY276': t('paymentPage', 'title.1DAY276'),
      '7 days trial': t('paymentPage', 'title.7 days trial'),
      '3 days free membership': t(
        'paymentPage',
        'title.3 days free membership',
      ),
      '150 tn credits': t('paymentPage', 'title.150 tn credits'),
      '700 tn credits': t('paymentPage', 'title.700 tn credits'),
      '1500 tn credits': t('paymentPage', 'title.1500 tn credits'),
      '3000 tn credits': t('paymentPage', 'title.3000 tn credits'),
      '90DAY1': t('paymentPage', 'title.90DAY1'),
      '180DAY1': t('paymentPage', 'title.180DAY1'),
      'more likes offer': t('paymentPage', 'title.moreLikesOffer'),
      'priority man offer': t('paymentPage', 'title.priorityManOffer'),
      'super boost offer': t('paymentPage', 'title.superBoostOffer'),
      'sex alarm offer': t('paymentPage', 'title.sexAlarmOffer'),
      'flirt alarm offer': t('paymentPage', 'title.flirtAlarmOffer'),
      'top in chat offer': t('paymentPage', 'title.topInChatOffer'),
      'Telegram conversation': t('paymentPage', 'title.10YEAR286'),
      '1day+1day free': () =>
        oneDay() + t('paymentPagePackage', 'text.free1D')(),
      '3day+2day free': () =>
        threeDay() + t('paymentPagePackage', 'text.free2D')(),
      '7day+3day free': () =>
        oneWeek() + t('paymentPagePackage', 'text.free3D')(),
      '1month+1week free': () =>
        oneMonth() + t('paymentPagePackage', 'text.free1W')(),
      '3month+3week free': () =>
        threeMonth() + t('paymentPagePackage', 'text.free3W')(),
      '6month+6week free': () =>
        sixMonth() + t('paymentPagePackage', 'text.free6W')(),
      '12month+12weeks free': () =>
        twelveMonth() + t('paymentPagePackage', 'text.free12W')(),
    };
  }),
);

/**
 * Adult payment package translations, used for backbone package translations
 * TODO: Remove when React PP wil be ready
 */
export const BACKBONE_PACKAGES_TITLE_TRANSLATES_ADULT = mergeTranslationMaps(
  BACKBONE_PACKAGES_TITLE_TRANSLATES,
  createTranslationsMap((t) => ({
    'micro incognito': t('paymentPagePackage', 'title.micro incognito.adult'),
    'more likes': t('paymentPagePackage', 'title.more likes.adult'),
    'priority man': t('paymentPagePackage', 'title.priority man.adult'),
    'super boost': t('paymentPagePackage', 'title.super boost.adult'),
    'top in chat': t('paymentPagePackage', 'title.top in chat.adult'),
  })),
);

/**
 * Lesbian payment package translations, used for backbone package translations
 * TODO: Remove when React PP wil be ready
 */
export const BACKBONE_PACKAGES_TITLE_TRANSLATES_LESBIAN = mergeTranslationMaps(
  BACKBONE_PACKAGES_TITLE_TRANSLATES,
  createTranslationsMap((t) => ({
    'priority man': t('paymentPagePackage', 'title.priorityMan.lesbian'),
    'x5-people_female': t('paymentPagePackage', 'title.x5-people_lesbian'),
  })),
);

/**
 * Gay payment package translations, used for backbone package translations
 * TODO: Remove when React PP wil be ready
 */
export const BACKBONE_PACKAGES_TITLE_TRANSLATES_GAY = mergeTranslationMaps(
  BACKBONE_PACKAGES_TITLE_TRANSLATES,
  createTranslationsMap((t) => ({
    'x5-people_female': t('paymentPagePackage', 'title.x5-people_gay'),
  })),
);
