import React from 'react';

import CommonCheckbox from '@core/form/checkbox';

import {Checkbox} from '@phoenix/ui';

const FormCheckbox = (props) => (
  <CommonCheckbox {...props} checkboxComponent={Checkbox} />
);

FormCheckbox.displayName = 'FormCheckboxWrapper';

export default FormCheckbox;
