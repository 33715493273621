import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';
import getHistory from '@core/application/utils/getHistory';
import {localStorage} from '@core/utils/storage';

import {TELEGRAM_POPUP_OPENED} from '@phoenix/microfeatures/telegramConversation/constants/storageKey';

import TelegramConversationMicroFeaturePopup from '../containers/TelegramConversationMicroFeaturePopup';

/**
 * @param {string} placement @see bannersPlacements.js
 */
const openTelegramConversationMicroFeaturePopup = () => {
  PopupService.openPopup(<TelegramConversationMicroFeaturePopup />, {
    small: true,
    priority: PopupPriority.LOW,
    trackingName: 'telegramConversationMicroFeature',
    onClose: (sourceEventType) => {
      if (sourceEventType === PopupSourceEvent.CLOSE_BUTTON_CLICK) {
        getHistory().replace(SEARCH_PAGE_LINK);
      }

      localStorage.removeItem(TELEGRAM_POPUP_OPENED);
      PopupService.closePopup();
    },
  });
};

export default openTelegramConversationMicroFeaturePopup;
