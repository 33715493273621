import {from, pipe, EMPTY} from 'rxjs';
import {switchMap, take, map, filter} from 'rxjs/operators';

import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@core/utils/storage';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';

import VIRTUAL_ASSISTANT_AVAILABLE_QUERY from '@phoenix/virtualAssistant/graphql/queries/virtualAssistantAvailable.gql';
import STORAGE_KEY from '@phoenix/virtualAssistant/constants/storageKey';
import VIRTUAL_ASSISTANT_USER_QUERY from '@phoenix/virtualAssistant/graphql/queries/virtualAssistantUser.gql';
import openVirtualAssistantPopup from '@phoenix/virtualAssistant/utils/openVirtualAssistantPopup';

/**
 * @param {ApolloClient} client
 *
 * @returns {Object} result
 * @returns {Function} result.operations
 * @returns {Function} result.subscription
 */
const virtualAssistantPopupAction = (client) => ({
  operations: () =>
    pipe(
      filter(([{data}]) => data.route.current.startsWith(SEARCH_PAGE_LINK)),
      filter(() => !window.FRONTEND_CONFIG?.VASplitSessionsEnabled),
      switchMap(() => {
        return readFromSessionStorage(STORAGE_KEY)
          ? EMPTY
          : from(client.query({query: VIRTUAL_ASSISTANT_AVAILABLE_QUERY}));
      }),
      switchMap(({data}) => {
        return data?.virtualAssistant?.available
          ? from(client.query({query: VIRTUAL_ASSISTANT_USER_QUERY}))
          : EMPTY;
      }),
      map(({data}) => data?.virtualAssistant?.user),
      take(1),
    ),
  subscription: (userData) => {
    if (!userData) return;

    openVirtualAssistantPopup(userData);
    writeToSessionStorage(STORAGE_KEY, true);
  },
});

export default virtualAssistantPopupAction;
