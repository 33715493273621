import React from 'react';
import PropTypes from 'prop-types';

import getMoneyBackDescriptor from '@core/payment/widgets/securityLogos/utils/getMoneyBackDescriptor';
import usePaymentDescriptor from '@core/payment/widgets/descriptor/utils/usePaymentDescriptor';

import MoneyBackPopoverLayout from '../components/MoneyBackPopoverLayout';

const MoneyBackPopover = ({children}) => {
  const {data, loading} = usePaymentDescriptor();

  if (loading) {
    return children;
  }

  const descriptor = getMoneyBackDescriptor(data);

  return (
    <MoneyBackPopoverLayout descriptor={descriptor}>
      {children}
    </MoneyBackPopoverLayout>
  );
};

MoneyBackPopover.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
};

export default MoneyBackPopover;
