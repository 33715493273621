import ROUTES_WITH_POPUP, {
  FUNNEL,
} from '@core/application/constants/routesWithPopup';

/**
 * Listing of all routes in terms of application.
 *
 * !!! Attention this file parsed at:
 * @see /scripts/generateRoutesList.js
 * Be carefully if you change file structure
 * @type {Object.<string,string>}
 */
const ROUTES = {
  PAY_IFRAME_PP: '/pay/iframePP',
  PAY_MESSAGE: '/pay/message',
  PAY_TRUST_PAY: '/pay/trustPay',
  PAY_REMARKETING: '/pay/remarketingOffer',
  PAY_CONTRACT: '/pay/contract',
  PAY_COINS: '/pay/credit',
  PAY_VERIFICATION: '/pay/verification',
  PAY_VERIFICATION_SUCCESS: '/pay/verificationSuccess',
  PAY_APP_FUNNEL: '/pay/appFunnel',
  PAY_APP_FUNNEL_NEW_OFFER: '/pay/appFunnel/newOffer',
  PAY_APP_FUNNEL_GET_THE_APP: '/pay/appFunnel/getTheApp',
  PAY_SEPA: '/pay/sepa',
  PAY_SOFORT: '/pay/sofort',
  PAY_IDEAL: '/pay/ideal',
  PAY_KONBINI: '/pay/konbini',
  REMOVE_ACCOUNT: '/account/remove',
  GET_THE_APP: '/getTheApp',
  TRUSTED_VIA_NAME: '/trusted/:viaName/:via',
  TRUSTED_VERIFICATION: '/trusted/trustedVerification/via/:via',
  TRUSTED_VIA: '/trusted/via/:via',
  TRUSTED: '/trusted',
  SEARCH_LIVE_CAM: '/search/livecam',
  WORLD_WIDE: '/worldWide',
  USER_FROM_PROMOCODE:
    '/user/view/id/:id/fromPromocode/:fromPromocode/partnerName/:partnerName',
  DEBTOR_INVOICE: '/debtorInvoice',
  BRANDBOOK: '/brandbook',
  DISCREET_PROFILES: '/discreetProfiles',
  DISCREET_PROFILES_OPENED: '/discreetProfiles/opened',
  VIDEO_CHAT: '/videoChat/:userId',
  STORY_MY: '/story/my',
  STORY_TARGET: '/story/:id',
  STORY: '/story',
  MICROFEATURES: '/microfeatures',
  LIKE_GALLERY: '/likeGallery',
  LIKE_GALLERY_LEGACY: '/hotOrNot',
  PERSONAL_IDENTITY: '/personalIdentity',
  PMA_SERVICE: '/pmaservice',
  ROOMS: '/rooms',
  LIVE_CHAT: '/livechat',
  REELS: '/reels',
  MY_ACTIVITY_LIKES: '/myActivity/likes',
  MY_ACTIVITY_FAVOURITE: '/myActivity/favourite',
  FEEDBACK: '/support/feedback',
  FREE_COINS: '/freeCoins',
  FUNNEL_QUIZ: '/funnelQuiz',

  /**
   * Special app route. Should be replaced
   * with moving all such stuff to application entry-point
   */
  WRONG_DEVICE: '/wrongdevice',
  DESCRIPTOR: '/d/',

  NEWS_FEED_VIEW: '/newsFeed/view',
  NEWS_FEED_ALL: '/newsFeed/all',
  NEWS_FEED_INCOME: '/newsFeed/income',
  NEWS_FEED_VIEW_LEGACY: '/views',

  PARTNER_NETWORK: '/partnerNetwork',
};

/**
 * Used by /scripts/generateRoutesList.js
 * @type {Array.<string>}
 */
export const EXACT_ROUTES = [];

/**
 * @type {Array.<string>}
 */
export const ROUTES_LIST = [
  ...Object.values(ROUTES),
  ...ROUTES_WITH_POPUP,
  FUNNEL,
];

export default ROUTES;
