import PropTypes from 'prop-types';

import useAllowedDelayedRender from '../utils/useAllowedDelayedRender';

/**
 * Wrapper to render node if important queries have already loaded
 * @param children
 * @return {null|*}
 * @constructor
 */
const DelayedRenderWrapper = ({children}) => {
  const allowedDelayedRender = useAllowedDelayedRender();

  if (allowedDelayedRender) {
    return children;
  }

  return null;
};

DelayedRenderWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
};

export default DelayedRenderWrapper;
