import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import PopupService from '@core/popup/utils/PopupService';

import PopupFrame from '@phoenix/popup/components/PopupFrame';

import CoregistrationPopupActions from '../components/CoregistrationPopupActions';
import CoregistrationPopupFooterLayout from '../components/CoregistrationPopupFooterLayout';

const CoregistrationPopup = ({
  children,
  footerText,
  register,
  openInactiveTab,
  spacedContent = true,
  zone,
  url,
}) => {
  const handleCoregistration = useCallback(() => {
    register({
      zone,
      openInactiveTab,
      url,
    });

    PopupService.closePopup();
  }, [zone, openInactiveTab, register, url]);

  return (
    <PopupFrame
      fixedActions={false}
      spacedContent={spacedContent}
      content={children}
      actions={
        <CoregistrationPopupActions
          // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
          onClick={handleCoregistration}
        />
      }
      footer={<CoregistrationPopupFooterLayout footerText={footerText} />}
    />
  );
};

CoregistrationPopup.propTypes /* remove-proptypes */ = {
  footerText: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired, // @see CoregistrationProvider.js -> register
  url: PropTypes.string.isRequired,
  zone: PropTypes.string.isRequired,
  children: PropTypes.node,
  openInactiveTab: PropTypes.bool,
  spacedContent: PropTypes.bool,
};

export default CoregistrationPopup;
