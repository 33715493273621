import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import Timer from '@core/utils/timer';
import useCompactPhoneBreakpoint from '@core/responsive/useCompactPhoneBreakpoint';

import {ButtonLink, Row, RowShort, TagTranslucent} from '@phoenix/ui';
import {Align, ButtonType} from '@phoenix/ui/constants';
import PhotosListAsRoulette from '@phoenix/user/photo/components/PhotosListAsRoulette';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {H3, TextMuted, Title} from '@phoenix/typography';

import css from '../styles/ExtraDiscountInteractiveLikePopupLayout.css';

const ExtraDiscountInteractiveLikePopupLayout = ({
  url,
  users,
  extra,
  duration,
  onTimeOver,
}) => {
  const isCompactPhone = useCompactPhoneBreakpoint();

  return (
    <PopupFrame
      data-test="extraDiscountInteractiveLikePopupLayout"
      spacedAbove
      content={
        <Fragment>
          <RowShort align={Align.CENTER}>
            <H3>{t('extraPopups', 'title.liked_game')}</H3>
          </RowShort>
          <RowShort align={Align.CENTER}>
            <TextMuted>{t('extraPopups', 'text.chat_with_girls')}</TextMuted>
          </RowShort>
          <RowShort align={Align.CENTER}>
            <Title level={isCompactPhone ? 4 : 2} primary className={css.title}>
              {t('extraPopups', 'text.off', {'{extra}': extra})}
            </Title>
          </RowShort>
          <RowShort align={Align.CENTER}>
            <TagTranslucent icon="clock" large>
              <Timer
                backward
                initialTime={duration}
                duration={duration}
                onTimeOver={onTimeOver}
              />
            </TagTranslucent>
          </RowShort>
          <Row>
            <div className={css.users}>
              <PhotosListAsRoulette users={users} />
            </div>
          </Row>
        </Fragment>
      }
      actions={
        <ButtonLink href={url} type={ButtonType.PAY} fullWidth adaptive>
          {t('extraPopups', 'button.check_out_now')}
        </ButtonLink>
      }
    />
  );
};

ExtraDiscountInteractiveLikePopupLayout.propTypes /* remove-proptypes */ = {
  url: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  duration: PropTypes.number.isRequired,
  extra: PropTypes.number.isRequired,
  onTimeOver: PropTypes.func.isRequired,
};

export default ExtraDiscountInteractiveLikePopupLayout;
