import React, {forwardRef} from 'react';

import type {ButtonLinkProps} from '@phoenix/ui';
import {ButtonLink} from '@phoenix/ui';
import {ButtonType} from '@phoenix/ui/constants';

import css from '../styles/PayButton.css';

const PayButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  (props, ref) => (
    <ButtonLink
      innerRef={ref}
      className={css.upgrade}
      {...props}
      type={ButtonType.PAY}
      fixedHeight
    />
  ),
);

export default PayButtonLink;
