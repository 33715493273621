import React, {Fragment, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import times from 'lodash/times';

import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import MountTracker from '@core/tracking/babcia/containers/MountTracker';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Spacing, PlaceholderBar, RowLarge} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import StaticPageLayoutWrapper from './StaticPageLayoutWrapper';
import css from '../styles/StaticPageLayout.css';

const StaticPageLayout = ({
  pageHtml,
  // Since in most of cases we open through popups. Pages are opened only via direct link
  isPopup = false,
  isCopyEnabled,
  scrollToId,
  loading,
  'data-test': dataTest,
  onCloseButtonClick,
}) => {
  const isSmallTablet = useSmallTabletBreakpoint();

  /**
   * If exists scrollToId it will do scroll to static document position with this dom id
   * also you need using Popup::withInnerScroll prop to disable inner popup scroll
   */
  useLayoutEffect(() => {
    if (scrollToId) {
      window.document.getElementById(scrollToId)?.scrollIntoView();
    }
  }, [scrollToId]);

  return (
    <StaticPageLayoutWrapper
      isMobAppearance={isSmallTablet}
      isPopup={isPopup}
      onCloseButtonClick={onCloseButtonClick}
    >
      <Spacing
        size={isPopup ? SpacingSize.NONE : SpacingSize.LARGE}
        // This data-test id needed only for static web popup
        data-test={!isSmallTablet && isPopup ? dataTest : null}
      >
        {loading ? (
          <Fragment>
            <PlaceholderBar size={3} />
            {times(5, (index) => {
              return (
                <Fragment key={index}>
                  <RowLarge>
                    <PlaceholderBar />
                  </RowLarge>
                  <PlaceholderBar />
                  <PlaceholderBar />
                  <PlaceholderBar />
                  <PlaceholderBar size={8} />
                </Fragment>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            <div
              data-test="staticContent"
              className={cn('staticContent', !isCopyEnabled && css.disableCopy)}
              dangerouslySetInnerHTML={{
                __html: pageHtml,
              }}
            />
            {isPopup ? (
              // To track 'staticPage' popups load completion
              <PopupLoadTracker />
            ) : (
              <MountTracker name={MountTracker.NAMES.PAGE_LAYOUT} />
            )}
          </Fragment>
        )}
      </Spacing>
    </StaticPageLayoutWrapper>
  );
};

StaticPageLayout.propTypes /* remove-proptypes */ = {
  pageHtml: PropTypes.string,
  isPopup: PropTypes.bool,
  isCopyEnabled: PropTypes.bool,
  onCloseButtonClick: PropTypes.func.isRequired,
  'data-test': PropTypes.string,
  scrollToId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

export default StaticPageLayout;
