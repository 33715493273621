import getHistory from '@core/application/utils/getHistory';
import {Action} from '@core/application/constants/history';
import trackRedirectTo from '@core/tracking/babcia/utils/trackRedirectTo';
import {Event} from '@core/tracking/babcia/constants/babciaDataTypes';

const initHistorySubscription = () => {
  const history = getHistory();
  let prevPathname = window.location.pathname;

  history.listen((location, type) => {
    trackRedirectTo({
      event: type === Action.REPLACE ? Event.REPLACE : Event.REDIRECT,
      nextLocation: location,
      currentPathname: prevPathname,
    });

    prevPathname = location.pathname;
  });
};

export default initHistorySubscription;
