import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

/**
 * Update microfeatures related data in cache.
 */
const updateMicroFeaturesData = () => {
  const client = getClientInstance();

  invalidateCacheByTypename(client, 'MicroFeatures', 'microFeatureList');
  invalidateCacheByTypename(client, 'MicroFeatures', 'creditFeatureList');
};

export default updateMicroFeaturesData;
