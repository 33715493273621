import React from 'react';

import CommonFromPasswordInput from '@core/form/input';

import {Icon} from '@phoenix/ui';

import FormInput from './FormInput';

const FromPasswordInput = (props) => (
  <CommonFromPasswordInput
    {...props}
    inputComponent={FormInput}
    iconComponent={Icon}
  />
);

export default FromPasswordInput;
