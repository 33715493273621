import React from 'react';
import PropTypes from 'prop-types';

import {SpacingLarge, PlaceholderBar, Row} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';

/**
 * Placeholder layout for confirmation block (displayed as popup)
 */
const ConfirmationLayoutPlaceholder = ({content, footer}) => (
  <div>
    <SpacingLarge>
      {content}
      <Row align={Align.CENTER}>
        <PlaceholderBar />
        <PlaceholderBar />
        <PlaceholderBar />
      </Row>
    </SpacingLarge>
    {footer}
  </div>
);

ConfirmationLayoutPlaceholder.propTypes /* remove-proptypes */ = {
  content: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
};

export default ConfirmationLayoutPlaceholder;
