import React from 'react';
import {useQuery} from '@apollo/client';

import t from '@core/translations/translate';

import {PlaceholderBar} from '@phoenix/ui';
import {SpacingSize, Align} from '@phoenix/ui/constants';
import {TextWithIcon} from '@phoenix/typography';
import CoinColorizedIcon from '@phoenix/credits/components/CoinColorizedIcon';

import HighlightedDescription from '../components/HighlightedDescription';
import TELEGRAM_BOT_REWARD_TASK from '../graphql/queries/telegramBotRewardTask.gql';

const CoinsTelegramBotSubscribeMotivation = () => {
  const {
    loading,
    error,
    data: {
      credits: {
        rewardForTasks: {
          tasks: {telegramSubscription},
        },
      },
    },
  } = useQuery(TELEGRAM_BOT_REWARD_TASK);
  if (error) return null;
  return (
    <HighlightedDescription>
      <TextWithIcon
        icon={!loading && <CoinColorizedIcon />}
        space={SpacingSize.SHORT}
        align={Align.CENTER}
      >
        {loading ? (
          <PlaceholderBar size={200} />
        ) : (
          t('coins', 'text.tg_subscribe_motivation', {
            '{quantity}': telegramSubscription.reward,
          })
        )}
      </TextWithIcon>
    </HighlightedDescription>
  );
};

export default CoinsTelegramBotSubscribeMotivation;
