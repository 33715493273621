/**
 * @see ../SepaDebtorService
 * @type {{NEW: string, REPAY: string, EXPIRE: string, CANCEL: string}}
 */
const DEBTOR_STATUS = {
  NEW: 'new',
  REPAY: 'repay',
  EXPIRE: 'expire',
  CANCEL: 'cancel',
};

export default DEBTOR_STATUS;
