import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const PayAppFunnelGetTheAppLazy = lazy(
  () =>
    import(
      /* webpackChunkName: 'PayAppFunnelGetAppRoute' */ '@phoenix/payment/pages/containers/PayAppFunnelGetTheAppPage'
    ),
);

export const PayAppFunnelGetTheAppChunk = (props) => (
  <SplitChunk component={PayAppFunnelGetTheAppLazy} {...props} />
);

const PayAppFunnelNewOfferLazy = lazy(
  () =>
    import(
      /* webpackChunkName: 'PayAppFunnelOfferRoute' */ '@phoenix/payment/pages/containers/PayAppFunnelNewOfferPage'
    ),
);

export const PayAppFunnelNewOfferChunk = (props) => (
  <SplitChunk component={PayAppFunnelNewOfferLazy} {...props} />
);

const PayAppFunnelLazy = lazy(
  () =>
    import(
      /* webpackChunkName: 'PayAppFunnelRoute' */ '@phoenix/payment/pages/containers/PayAppFunnelPage'
    ),
);

export const PayAppFunnelChunk = (props) => (
  <SplitChunk component={PayAppFunnelLazy} {...props} />
);
