import {useContext, useEffect, useRef} from 'react';
import {from} from 'rxjs';
import {filter} from 'rxjs/operators';
import startsWith from 'lodash/startsWith';
import {useApolloClient} from '@apollo/client';

import {deleteCookie, getCookie, setCookie} from '@core/utils/cookie';
import {checkExpired} from '@core/utils/storage/expiredStorage';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isInRouteList from '@core/utils/routing/isInRouteList';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';

import CoregistrationContext from '../containers/CoregistrationContext';
import useCoregistrationPopup from './useCoregistrationPopup';
import {
  DISABLED_ROUTES,
  STORAGE_EXPIRED_KEYS,
  MAX_VIEWS_COUNT,
  SEARCH_POPUP_VIEWS_COUNT_COOKIE_NAME,
} from '../constants/coregistrationPopup';
import {COREG_ZONE} from '../constants/zones';

const ROUTES = {
  // Route to count users view
  USER: '/user',
  // Route to detect search live
  SEARCH_PAGE_LINK,
};

/**
 * Hook for displaying coregistration popup for selected zone
 * @param {Boolean} useClientStorage
 * @param {Boolean} skip
 * @return {null}
 */
const useCoregistrationSearchPopupZone = ({useClientStorage, skip}) => {
  const client = useApolloClient();

  const {zones, loadZoneInfo, register} = useContext(CoregistrationContext);

  const subscription = useRef();

  useCoregistrationPopup({
    register,
    zoneData: zones[COREG_ZONE.SEARCH_POPUP],
    zone: COREG_ZONE.SEARCH_POPUP,
    useClientStorage,
  });

  useEffect(() => {
    /**
     * Check users views count and start route subscription if needed
     * When SEARCH_POPUP_MAX_VIEWS_COUNT is reached loads zone info (CoregistrationProvider -> loadZoneInfo)
     * @see CoregistrationProvider.js
     * This zone info will be used to open CoregistrationPopup if it is available
     */
    let viewsCount =
      parseInt(getCookie(SEARCH_POPUP_VIEWS_COUNT_COOKIE_NAME), 10) || 0;

    if (!skip && viewsCount <= MAX_VIEWS_COUNT && !subscription.current) {
      const isExpired = checkExpired(
        STORAGE_EXPIRED_KEYS[COREG_ZONE.SEARCH_POPUP],
      );

      if (useClientStorage && !isExpired) {
        return;
      }

      subscription.current = from(client.watchQuery({query: ROUTE_QUERY}))
        .pipe(
          filter(
            ({data}) => !isInRouteList(DISABLED_ROUTES, data.route.current),
          ),
        )
        .subscribe(({data}) => {
          const {current, previous} = data.route;

          // On user page
          const needToUpdateCookie =
            startsWith(current, ROUTES.USER) &&
            !startsWith(previous, ROUTES.USER) &&
            viewsCount < MAX_VIEWS_COUNT;
          // From user page
          const needToDeleteCookie =
            startsWith(previous, ROUTES.USER) &&
            !startsWith(current, ROUTES.USER) &&
            viewsCount === MAX_VIEWS_COUNT;

          if (needToUpdateCookie) {
            setCookie(SEARCH_POPUP_VIEWS_COUNT_COOKIE_NAME, ++viewsCount, {
              expires: 365,
            });
          }

          if (needToDeleteCookie) {
            deleteCookie(SEARCH_POPUP_VIEWS_COUNT_COOKIE_NAME);
            loadZoneInfo(COREG_ZONE.SEARCH_POPUP);
            subscription.current.unsubscribe();
          }
        });
    }

    // eslint-disable-next-line consistent-return
    return () => {
      subscription.current && subscription.current.unsubscribe();
    };
  }, [skip, client, loadZoneInfo, useClientStorage]);
};

export default useCoregistrationSearchPopupZone;
