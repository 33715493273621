/**
 * List of all available routes for like gallery functionality
 */
const ROUTE = {
  LIKE_GALLERY: '/likeGallery',
  FULL_MATCHES: '/likeGallery/fullMatches',
  LIKED_YOU: '/likeGallery/likedYou',
};

export default ROUTE;
