import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import PopupService from '@core/popup/utils/PopupService';
import {gotoUrl} from '@core/utils/url';
import AUTOLOGIN_URL_QUERY from '@core/user/profile/common/graphql/queries/autologinUrl.gql';

import IN_APP_MOTIVATION_QUERY from '@phoenix/inAppMotivation/graphql/queries/inAppMotivation.gql';
import {graphSearchUserPropType} from '@phoenix/search/widget/constants/propTypes';
import useUserAttributes from '@phoenix/user/widget/utils/useUserAttributes';

import InAppMotivationPopupLayoutPlaceholder from '../components/InAppMotivationPopupLayoutPlaceholder';
import formatLink from '../utils/formatLink';
import {
  COPY_LINK,
  OPEN_BROWSER,
  PASTE_LINK,
  COMPLETE,
} from '../constants/tagSteps';
import MOTIVATION_TYPES from '../constants/motivationTypes';
import InAppMotivationPopupLayout from '../components/InAppMotivationPopupLayout';

const NEXT_ACTIVE_TAG_TIMEOUT = window.IS_INTEGRATION_TEST_ENVIRONMENT
  ? 0
  : 2000;

let openBrowserTimeout;
let pastLinkTimeout;
let returnFocusTimeout;

const InAppMotivationPopup = ({type, alterBtns, user, loading, onClose}) => {
  const attemptsToClose = useRef(2);

  const {data, loading: autoLoginLoading} = useQuery(AUTOLOGIN_URL_QUERY);
  const {data: linkTypeData, loading: linkTypeLoading} = useQuery(
    IN_APP_MOTIVATION_QUERY,
  );

  const {
    loading: attributesLoading,
    dictionaryTribes,
    distanceUnits,
  } = useUserAttributes();

  const [activeTag, setActiveTag] = useState(COPY_LINK);
  const ref = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(openBrowserTimeout);
      clearTimeout(pastLinkTimeout);
      clearTimeout(returnFocusTimeout);
      onClose && onClose();
    };
  }, [onClose]);

  const linkType = linkTypeData?.userFeatures.inAppParams.motivation.linkType;

  const formattedLink = formatLink(linkType, data?.userFeatures?.autologinUrl);

  const linkClickHandler = useCallback(() => {
    if (formattedLink && --attemptsToClose.current) {
      setActiveTag(COMPLETE);
      gotoUrl(formattedLink);
    } else {
      PopupService.closePopup();
    }
  }, [formattedLink]);

  if (loading || linkTypeLoading || autoLoginLoading || attributesLoading) {
    return <InAppMotivationPopupLayoutPlaceholder />;
  }

  const copyClickHandler = () => {
    ref.current.select();
    copy(data.userFeatures.autologinUrl);

    if (activeTag !== COPY_LINK) {
      return;
    }

    setActiveTag(OPEN_BROWSER);

    openBrowserTimeout = setTimeout(() => {
      setActiveTag(PASTE_LINK);
    }, NEXT_ACTIVE_TAG_TIMEOUT);

    pastLinkTimeout = setTimeout(() => {
      setActiveTag(COMPLETE);
    }, NEXT_ACTIVE_TAG_TIMEOUT * 2);
  };

  const inputFocusHandler = (e) => {
    e.target.setSelectionRange(0, data.userFeatures.autologinUrl.length);
  };

  const inputBlurHandler = () => {
    // Need to return the focus to body in browser. After blur in app browsers the the browser loses focus.
    returnFocusTimeout = setTimeout(() => window.focus(), 300);
  };

  return (
    <InAppMotivationPopupLayout
      alterBtns={alterBtns}
      type={type}
      user={user}
      dictionaryTribes={dictionaryTribes}
      distanceUnits={distanceUnits}
      ref={ref}
      loginUrl={data.userFeatures.autologinUrl}
      onCopyClick={copyClickHandler}
      onInputFocus={inputFocusHandler}
      onInputBlur={inputBlurHandler}
      onLinkClick={linkClickHandler}
      activeTag={activeTag}
    />
  );
};

InAppMotivationPopup.propTypes /* remove-proptypes */ = {
  type: PropTypes.oneOf(Object.values(MOTIVATION_TYPES)).isRequired,
  alterBtns: PropTypes.bool,
  user: PropTypes.shape(graphSearchUserPropType),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
};

export default InAppMotivationPopup;
