import React, {memo} from 'react';
import PropTypes from 'prop-types';

const PaymentLicense = ({list}) => {
  if (!list?.length) {
    return null;
  }

  return list.map((item) => (
    <div key={item} dangerouslySetInnerHTML={{__html: item}} />
  ));
};

PaymentLicense.propTypes /* remove-proptypes */ = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default memo(PaymentLicense);
