import TYPES from '@core/extraEvent/constants/extraEventTypes';

import spinNowPopupAction from '../actions/spinNowPopupAction/action';
import extraBuyThreeDayPopupAction from '../actions/extraBuyThreeDayPopup/action';
import extraBuyOnePlusOnePopupAction from '../actions/extraBuyOnePlusOnePopup/action';
import interactiveLikePopupAction from '../actions/interactiveLikePopup/action';
import extraRMBundlePopupAction from '../actions/extraRMBundlePopup/action';
import extraDiscountPopupAction from '../actions/extraDiscountPopup/action';
import extraDiscountExpirePopupAction from '../actions/extraDiscountPopup/expireAction';
import extraProfilesViewPopupAction from '../actions/extraProfilesPopup/action';
import extraProfilesViewExpiredPopupAction from '../actions/extraProfilesPopup/expiredAction';
import extraPriorityManPopupAction from '../actions/extraPriorityMan/action';
import extraPriorityManExpirePopupAction from '../actions/extraPriorityMan/expireAction';
import expandSearchLimitsPopupAction from '../actions/expandSearchLimitsPopup/action';
import expandSearchLimitsExpirePopupAction from '../actions/expandSearchLimitsPopup/expireAction';

/**
 * For adding a new extra event need:
 * 1) add the type of event to the constants @see extraEventTypes.js,
 *    because they are also used for websocket resolver @see websocket/resolvers/extraEvents.js
 * 2) add new action to the switch with case by event type
 *
 * Get action for extra event by type
 * @param {string} event type from interaction data
 * @param {ApolloClient} client
 * @param {Subject} extraEventsSubject
 * @see startExtraEventListener
 * @returns {Promise<void> | void}
 */
const performExtraAction = ({processId, type}, client, extraEventsSubject) => {
  switch (type) {
    case TYPES.EXTRA_DISCOUNT:
    case TYPES.EXTRA_DISCOUNT_INTERACTIVE_LIKE:
      return extraDiscountPopupAction({processId, client});
    case TYPES.INTERACTIVE_LIKE:
      return interactiveLikePopupAction({
        processId,
        client,
        extraEventsSubject,
      });
    case TYPES.EXTRA_DISCOUNT_EXPIRED:
      return extraDiscountExpirePopupAction({client});
    case TYPES.SPIN_NOW:
      return spinNowPopupAction({processId, client});
    case TYPES.BUY_ONE_PLUS_ONE:
      return extraBuyOnePlusOnePopupAction({processId, client});
    case TYPES.PROFILES_VIEW:
      return extraProfilesViewPopupAction({processId, client});
    case TYPES.RM_BUNDLE_POPUP_ONE_MONTH:
    case TYPES.RM_BUNDLE_POPUP_ONE_WEEK:
      return extraRMBundlePopupAction({processId, client});
    case TYPES.PROFILES_VIEW_EXPIRED:
      return extraProfilesViewExpiredPopupAction({client});
    case TYPES.BUY_THREE_DAY:
      return extraBuyThreeDayPopupAction({processId, client});
    case TYPES.PRIORITY_MAN:
      return extraPriorityManPopupAction({processId, client});
    case TYPES.PRIORITY_MAN_EXPIRED:
      return extraPriorityManExpirePopupAction({client, type});
    case TYPES.SEARCH_LIMITS:
      return expandSearchLimitsPopupAction({
        processId,
        client,
        type,
      });
    case TYPES.SEARCH_LIMITS_EXPIRED:
      return expandSearchLimitsExpirePopupAction();
    default:
      return null;
  }
};
export default performExtraAction;
