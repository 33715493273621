/* eslint-disable consistent-return */

import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import getHistory from '@core/application/utils/getHistory';
import isPaymentUrl from '@core/utils/url/isPayUrl';
import cachePersistManager from '@core/graphql/utils/cachePersistManager';
import paymentPendingVar from '@core/graphql/vars/paymentPendingVar';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import {getClientInstance} from '@core/graphql/client';
import {USER_PROFILE_ROUTE} from '@core/utils/url/createUrlFor';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import {localStorage} from '@core/utils/storage';
import resetAdditionalTermsChecked from '@core/payment/widgets/billingPolicy/utils/resetAdditionalTermsChecked';
import cachedPurchasedPackage from '@core/payment/common/utils/cachedPurchasedPackage';
import resetPaymentPackagesCache from '@core/payment/common/utils/resetPaymentPackagesCache';

import {REDIRECT_TO_CURRENT_PROFILE_PUSHED_ON_FUNNEL_STORAGE_KEY} from '@phoenix/funnel/paidFunnel/constants/storageKeys';
import showTargetUserMediaWithoutPermissions from '@phoenix/user/profile/target/utils/showTargetUserMediaWithoutPermissions';
import paymentPageNavigationInterceptor from '@phoenix/payment/utils/paymentPageNavigationInterceptor';
import successPaymentPageNavigationInterceptor from '@phoenix/payment/utils/successPaymentPageNavigationInterceptor';
import isTransitionActiveVar from '@phoenix/payment/graphql/vars/isTransitionActiveVar';
import checkIfNeedTriggeringLeavePaymentPage from '@phoenix/search/map/utils/checkIfNeedTriggeringLeavePaymentPage';

const history = getHistory();

history.beforePush?.((nextLocation) => {
  const currentLocationPathname = history.location.pathname;
  const nextLocationPathname = nextLocation.pathname;

  const isPayUrl = isPaymentUrl(currentLocationPathname);
  const isNextPayUrl = isPaymentUrl(nextLocationPathname);

  // Go to payment page
  if (!isPayUrl && isNextPayUrl) {
    isTransitionActiveVar(true);

    // Reset data to fill funnel stack correctly after returning to funnel
    localStorage.setItem(
      REDIRECT_TO_CURRENT_PROFILE_PUSHED_ON_FUNNEL_STORAGE_KEY,
      false,
    );

    return paymentPageNavigationInterceptor(nextLocation);
  }

  if (isPayUrl) {
    const urlParams = new URLSearchParams(history.location.search);
    checkIfNeedTriggeringLeavePaymentPage(urlParams.get('via'));
  }

  // Go to success payment page
  if (isPaySuccessUrl(nextLocationPathname)) {
    return successPaymentPageNavigationInterceptor();
  }

  // Go from payment page
  if (isPayUrl && !isNextPayUrl) {
    resetAdditionalTermsChecked();
    resetPaymentPackagesCache(false);

    PaymentPageSuccessOrders.clear();
    invalidateCacheByTypename(getClientInstance(), 'Payment', 'payAnswer');

    paymentPendingVar(false);

    // Drop cache from local storage
    cachePersistManager.clearPersistStorage();

    cachedPurchasedPackage.clear();

    return;
  }

  const isTargetProfileUrl =
    currentLocationPathname.startsWith(USER_PROFILE_ROUTE);
  const isNextTargetProfileUrl =
    nextLocationPathname.startsWith(USER_PROFILE_ROUTE);

  // Go to target user profile
  if (!isTargetProfileUrl && isNextTargetProfileUrl) {
    return showTargetUserMediaWithoutPermissions({
      currentRoute: currentLocationPathname,
      nextRoute: nextLocationPathname,
    });
  }
});
