import type {FC} from 'react';
import React, {useEffect, useMemo} from 'react';
import {useApolloClient} from '@apollo/client';

import {MOB_SITE, WEB_SITE} from '@core/responsive/environments';
import isSmallTabletViewport from '@core/responsive/isSmallTabletViewport';
import type {DocumentNodeOrOptions} from '@core/graphql/utils/usePreloadQueries';
import usePreloadQueries from '@core/graphql/utils/usePreloadQueries';
import isSearchPreloadEnabled from '@core/search/page/utils/isSearchPreloadEnabled';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import SearchPageAppearance from '@core/theming/constants/features/SearchPageAppearance';

import preloadSecondaryDataOnce from '@phoenix/application/utils/preloadSecondaryDataOnce';
import SEARCH_FORM_PARAMS_QUERY from '@phoenix/search/form/graphql/queries/searchFormParams.gql';
import SEARCH_LIST_QUERY from '@phoenix/search/list/graphql/queries/searchList.gql';
import SEARCH_PAGE_DATA_QUERY from '@phoenix/search/page/graphql/queries/searchPageData.gql';
import {WebcamPlace} from '@phoenix/search/widget/constants/WebcamPlace';

import SearchListPreloadingContext from '../../search/list/containers/SearchListPreloadingContext';
import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-42412
 */
type ThemedSearchPageProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  SearchPageAppearance,
  ModuleComponent<ThemedSearchPageProps>
> = (value) => {
  switch (value) {
    case SearchPageAppearance.ORDINARY:
      return import(
        /* webpackChunkName: 'searchPageOrdinary' */ '@phoenix/search/page/containers/SearchPage'
      );
    case SearchPageAppearance.ORDINARY_WITH_PAYMENT_BUTTON:
      return import(
        /* webpackChunkName: 'searchPageOrdinaryWithPay' */ '@phoenix/search/page/containers/SearchPageWithPayButton'
      );
    case SearchPageAppearance.FULLSCREEN_SLIDER:
      return import(
        /* webpackChunkName: 'searchPageSlider' */ '@phoenix/search/page/containers/SearchPageFullscreenSlider'
      );
    default:
      return null;
  }
};

let listQuery: DocumentNodeOrOptions[] = [];
let otherQueries: DocumentNodeOrOptions[] = [];

if (isSearchPreloadEnabled()) {
  if (!window.location.search) {
    // Do not preload SearchListQuery when url contains search params.
    // We need other variables in this case, so will load it later.
    listQuery = [
      {
        query: SEARCH_LIST_QUERY,
        variables: {
          offset: 0,
          onlyUsers: false,
          placement: WebcamPlace.SEARCH,
          viewFrom: 'search',
          params: null,
        },
      },
    ];
  }

  otherQueries = [
    {
      query: SEARCH_PAGE_DATA_QUERY,
      variables: {
        environment: isSmallTabletViewport() ? MOB_SITE : WEB_SITE,
      },
    },
    SEARCH_FORM_PARAMS_QUERY,
  ];
}

/**
 * Appearance-changeable search page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedSearchPage: FC<ThemedSearchPageProps> = (props) => {
  const client = useApolloClient();
  const isPhone = usePhoneBreakpoint();

  useEffect(() => {
    if (isSearchPreloadEnabled()) {
      preloadSecondaryDataOnce({
        client,
        isReelsAvailable: isPhone,
      });
    }

    return () => {
      // Reset queries to disable preloading for next /search page visit.
      listQuery = [];
      otherQueries = [];
    };
  }, [client, isPhone]);

  const searchListLoading = usePreloadQueries(listQuery);
  const loading = usePreloadQueries(otherQueries);
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedSearchPageProps,
        SearchPageAppearance
      >(),
    [],
  );

  return (
    <SearchListPreloadingContext.Provider value={searchListLoading}>
      <ThemedComponentLoader
        feature="search"
        param="page"
        moduleResolver={resolver}
        {...props}
        loading={loading}
      />
    </SearchListPreloadingContext.Provider>
  );
};

export default ThemedSearchPage;
