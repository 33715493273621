import range from 'lodash/range';

/**
 * Name of concept content container.
 * @const {string}
 */
export const CONCEPT_CONTENT_ID = 'conceptContent';

/**
 * Concept test name
 * @const {string}
 */
export const CONCEPT_TEST_NAME = 'remarketingPopupConcept';

/**
 * Concepts with inverse close icons
 * @type {string[]}
 */
export const INVERSE_CLOSE_ICONS = [
  'concept17',
  'concept15',
  'concept14',
  'concept13',
];

/**
 * Cookie for testing before API is ready
 */
export const REMARKETING_OFFER_CONCEPT_ID_COOKIE = 'remarketingPopupConcept';
/**
 * List of possible remarketingOffer concept ids
 */
export const REMARKETING_OFFER_CONCEPT_IDS = range(1, 27);

/**
 * List of possible remarketingOffer feature concept ids
 */
export const REMARKETING_OFFER_FEATURE_CONCEPT_IDS = range(1, 10);

/**
 * Is used to store last display time and display count per session
 */
export const STORAGE_KEY = 'PopupRemarketingOffer';
