import React, {Fragment, forwardRef} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import MediaUploadActionsAppearance from '@core/theming/constants/features/MediaUploadActionsAppearance';

import {ButtonPrimary, BarProgress, Icon, SpacingShortest} from '@phoenix/ui';
import {SpacingDirection} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import WebcamWrapper from './WebcamWrapper';
import WebcamError from './WebcamError';
import UploadActions from './UploadActions';
import css from '../styles/WebcamVideoCaptureLayout.css';

const WebcamVideoCaptureLayout = forwardRef(
  (
    {
      onStartRecording,
      onStopRecording,
      isReady,
      isReadyForStop,
      isRecording,
      percentage,
      time,
      error,
    },
    ref,
  ) => {
    const {data: actions} = useThemeFeature('mediaUpload', 'actions');

    return (
      <Fragment>
        <WebcamWrapper className={css.container} ref={ref}>
          {error && <WebcamError error={error} />}
          {isRecording && (
            <Fragment>
              <div className={css.indicator}>
                <Icon type="record" danger />
              </div>
              <div className={css.progress}>
                <SpacingShortest direction={SpacingDirection.VERTICAL}>
                  <Text type={Text.TYPE.INHERIT} align={Text.ALIGN.RIGHT} small>
                    {time}
                  </Text>
                </SpacingShortest>
                <BarProgress inverse percentage={percentage} />
              </div>
            </Fragment>
          )}
        </WebcamWrapper>
        <UploadActions>
          {isRecording ? (
            <ButtonPrimary
              icon={
                actions !== MediaUploadActionsAppearance.WITHOUT_ICONS && 'stop'
              }
              onClick={onStopRecording}
              disabled={!isReadyForStop}
              data-test="webcamVideoCaptureStop"
              trackingName="captureVideoStop"
            >
              {t('videoRecorder', 'button.stop')}
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              icon={
                actions !== MediaUploadActionsAppearance.WITHOUT_ICONS &&
                'videochat'
              }
              onClick={onStartRecording}
              disabled={!isReady}
              data-test="webcamVideoCaptureStart"
              trackingName="captureVideoStart"
            >
              {t('videoRecorder', 'button.start')}
            </ButtonPrimary>
          )}
        </UploadActions>
      </Fragment>
    );
  },
);

WebcamVideoCaptureLayout.propTypes /* remove-proptypes */ = {
  onStartRecording: PropTypes.func.isRequired,
  onStopRecording: PropTypes.func.isRequired,
  isReady: PropTypes.bool,
  isReadyForStop: PropTypes.bool,
  isRecording: PropTypes.bool,
  percentage: PropTypes.number,
  time: PropTypes.string,
  error: PropTypes.string,
};

export default WebcamVideoCaptureLayout;
