import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import useOrientationAndGender from '@core/user/profile/current/utils/useOrientationAndGender';

import MicrofeaturesIterator from '../../banners/containers/MicrofeaturesIterator';
import getTranslationByType from '../utils/getTranslationByType';
import useMicrofeaturesAvailabilityData from '../utils/useMicrofeaturesAvailabilityData';
import useMicrofeaturesData from '../utils/useMicrofeaturesData';
import useRotationData from '../utils/useRotationData';
import {Provider} from './MicrofeaturesContext';
import getMicrofeaturesList from '../utils/getMicrofeaturesList';

/**
 * To add new microfeature:
 * 1. Add new name to MICROFEATURES list (@see constants/microfeatures.js)
 * 2. Add translations (@see utils/getTranslationByType.js)
 * 3. Add icon with animation (@see icons/constants/iconComponents.js and icons/constants/icons.js)
 * 4. Add success popup animation (@see popups/constants/animationComponents.js)
 */
const MicrofeaturesProvider = ({allowedToStart, children}) => {
  const {
    loading: availabilityLoading,
    error: availabilityError,
    modelType,
  } = useMicrofeaturesAvailabilityData({skip: !allowedToStart});

  const {
    loading: dataLoading,
    error: dataError,
    sexAlarm,
    data,
    microfeaturesList,
  } = useMicrofeaturesData(modelType, {skip: !allowedToStart});

  const {
    loading: orientationAndGenderLoading,
    error: orientationAndGenderError,
    orientation,
    gender,
  } = useOrientationAndGender({skip: !allowedToStart});

  const {
    notBoughtFeatures,
    iteratePlacement,
    getFeatureForPlacement,
    checkPlacementEnabled,
    getFeatureData,
  } = useRotationData({
    allowedToStart,
  });

  const loading =
    availabilityLoading || dataLoading || orientationAndGenderLoading;
  const error = availabilityError || dataError || orientationAndGenderError;

  const microfeaturesAvailable = Boolean(modelType) && !loading && !error;

  const getSexAlarmPopupData = useCallback(() => {
    if (!microfeaturesAvailable) return {};

    return {
      number: sexAlarm?.amountRecipients,
      message: sexAlarm?.message?.value,
    };
  }, [sexAlarm, microfeaturesAvailable]);

  const getFeaturesList = useCallback(() => {
    if (!microfeaturesAvailable) return [];

    return getMicrofeaturesList(modelType, microfeaturesList);
  }, [microfeaturesList, microfeaturesAvailable, modelType]);

  const isAdult =
    data?.microFeatures?.microFeaturesConfiguration?.isUseAlternativeTitles ||
    false;
  const getTranslation = useCallback(
    ({type, featureName, options} = {}) => {
      return getTranslationByType({
        type,
        featureName,
        orientation,
        gender,
        isAdult,
        options,
      });
    },
    [gender, isAdult, orientation],
  );

  return (
    <Provider
      value={{
        loading,
        error,
        modelType,
        microfeaturesAvailable,
        // microfeatures can be available but not yet active (e.g. free user) or all bought already
        microfeaturesActive: Boolean(
          microfeaturesAvailable && notBoughtFeatures.length,
        ),
        isAdultDescription: isAdult,
        popularityLevel: data?.microFeatures?.popularityLevel || null,
        getSexAlarmPopupData,
        getFeatureForPlacement,
        getFeaturesList,
        getFeatureData,
        getTranslation,
        iteratePlacement,
        checkPlacementEnabled,
      }}
    >
      <MicrofeaturesIterator>{children}</MicrofeaturesIterator>
    </Provider>
  );
};

MicrofeaturesProvider.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  allowedToStart: PropTypes.bool,
};

export default MicrofeaturesProvider;
