import {FUNNEL} from '@core/application/constants/routesWithPopup';
import {getRmOfferConceptNameCookie} from '@core/application/utils/getRmBootstrapPopup';

import isRemarketingOfferPopupUrl from '@phoenix/remarketing/offerPopup/utils/isRemarketingOfferPopupUrl';

const {pathname} = window.location;

const variables = {
  withFunnelFields: pathname.startsWith(FUNNEL),

  /**
   * Some types don't need in AppData query for other routes
   */
  withRemarketingOfferFields: isRemarketingOfferPopupUrl(),

  isCached:
    isRemarketingOfferPopupUrl() && Boolean(getRmOfferConceptNameCookie()),
};

const getAppDataVariables = () => variables;

export default getAppDataVariables;
