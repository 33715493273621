export const IBAN = 'iban';

export const NAME_FIRST = 'nameFirst';

export const FIRST_NAME = 'firstName';

export const NAME_LAST = 'nameLast';

export const LAST_NAME = 'lastName';

export const STREET = 'street';

export const HOUSE_NUMBER = 'numberHouse';

export const POSTAL_CODE = 'postalCode';

export const CITY = 'city';

export const EMAIL = 'email';

export const PHONE = 'phoneNumber';
