import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Overlay, Row} from '@phoenix/ui';
import {OverlaySaturation} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';
import WebCamButton from '@phoenix/user/profile/common/containers/WebCamButton';

import css from '../styles/WebCamGalleryMotivation.css';

const WebCamGalleryMotivation = ({userId, forceWebCamPartner}) => (
  <Overlay saturation={OverlaySaturation.MEDIUM}>
    <div className={css.container}>
      <Row>
        <Text inverse className={css.text}>
          {t('webCamWidget', 'text.tap_to_see_more_shows')}
        </Text>
      </Row>
      <Row>
        <WebCamButton
          className={css.action}
          fullWidth
          userId={userId}
          type={WebCamButton.TYPE.PRIMARY}
          forceWebCamPartner={forceWebCamPartner}
          placementName={WebCamButton.PLACEMENT_NAMES.CAM_VIDEO_BUTTON}
        />
      </Row>
    </div>
  </Overlay>
);

WebCamGalleryMotivation.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string.isRequired,
  /**
   * Used in multiProviders RM logic. Depending on placement cam model can have different provider
   * We need to use it in query to get WebcamPlacements for specific partner
   */
  forceWebCamPartner: PropTypes.string,
};

export default WebCamGalleryMotivation;
