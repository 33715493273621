import React, {Fragment} from 'react';

import t from '@core/translations/translate';

import {Text, TextMuted, TextPrimary} from '@phoenix/typography';
import animationCss from '@phoenix/widgets/animation/animations.css';
import {IconType} from '@phoenix/ui/constants';

import FreeFeaturePopupTypes from '../constants/freeFeaturePopupTypes';

/**
 * @param {Object} params
 * @param {String} params.like
 * @param {String} params.message
 * @param {String} params.view
 * @param {Number} params.price
 * @param {Number} params.price.amount
 * @param {Object} params.price.currency
 * @param {String} params.price.currency.prefix
 * @param {String} params.price.currency.suffix
 */
const priorityManStatisticConfig = ({
  like,
  message,
  view,
  price: {
    amount,
    currency: {prefix, suffix},
  },
}) => ({
  type: FreeFeaturePopupTypes.STATISTIC,
  animationClass: animationCss.bounce,
  title: t('microfeatures', 'title.extra_event_expired'),
  text: (
    <Fragment>
      <Text inline>{t('microfeatures', 'text.free_feature_with_ms')}</Text>
      <TextMuted inline>
        {prefix && <s>{prefix}</s>}
        <s>{amount}</s>
        {suffix && <s>{suffix}</s>}
      </TextMuted>
      <TextPrimary inline>{t('microfeatures', 'text.free')}</TextPrimary>
    </Fragment>
  ),
  actionText: t('microfeatures', 'text.get_priority'),
  icon: 'crown',
  iconType: IconType.PRIMARY,
  benefits: [
    {
      icon: 'eye',
      type: IconType.PRIMARY,
      title: view,
      text: t('microfeatures', 'text.views'),
    },
    {
      icon: 'heart',
      type: IconType.DANGER,
      title: like,
      text: t('microfeatures', 'text.likes'),
    },
    {
      icon: 'mail',
      type: IconType.ACCENT,
      title: message,
      text: t('microfeatures', 'text.messages'),
    },
  ],
});

export default priorityManStatisticConfig;
