import {ButtonType} from '@phoenix/ui/constants';
import ACTIONS from '@phoenix/user/actions/constants/actions';

export const ACTION_TYPES_WITH_PROFILE_STATUS = {
  [ACTIONS.UN_BLOCK]: ButtonType.DEFAULT,
  [ACTIONS.UN_REPORT]: ButtonType.DEFAULT,
  [ACTIONS.CHANGE_REASON]: ButtonType.DEFAULT,
  [ACTIONS.GO_TO_SEARCH]: ButtonType.PRIMARY,
};

export const ACTION_TYPES_FOR_SYSTEM_PROFILE = {
  [ACTIONS.CONTACT_US]: ButtonType.DEFAULT,
  [ACTIONS.CHAT]: ButtonType.DEFAULT,
  [ACTIONS.FAVORITE]: ButtonType.PRIMARY,
  [ACTIONS.GO_TO_SEARCH]: ButtonType.PRIMARY,
};

export const ACTION_TYPES_FIXED_BOTTOM = {
  [ACTIONS.CHAT]: ButtonType.PRIMARY,
  [ACTIONS.LIKE]: ButtonType.DEFAULT,
  [ACTIONS.FAVORITE]: ButtonType.DEFAULT,
  [ACTIONS.WEBCAM]: ButtonType.DEFAULT,
  [ACTIONS.CONTACT_US]: ButtonType.PRIMARY,
  [ACTIONS.UN_BLOCK]: ButtonType.DEFAULT,
  [ACTIONS.UN_REPORT]: ButtonType.DEFAULT,
  [ACTIONS.CHANGE_REASON]: ButtonType.DEFAULT,
  [ACTIONS.GO_TO_SEARCH]: ButtonType.PRIMARY,
};

export default {
  [ACTIONS.CHAT]: ButtonType.DEFAULT,
  [ACTIONS.LIKE]: ButtonType.PRIMARY,
  [ACTIONS.FAVORITE]: ButtonType.PRIMARY,
  [ACTIONS.WEBCAM]: ButtonType.PRIMARY,
  [ACTIONS.GO_TO_SEARCH]: ButtonType.PRIMARY,
};
