const DISABLED_ROUTES = [
  'pay',
  'paymentTrialPromo',
  'confirmation',
  'funnel',
  'forbidden',
  'photoUploadMotivation',
];

export default DISABLED_ROUTES;
