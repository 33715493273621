import React, {useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';

import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';

import FreeFeatureStartPopupLayout from '../components/FreeFeatureStartPopupLayout';
import freeFeatureConfigPropType from '../constants/freeFeatureConfigPropType';

const FreeFeatureStartPopup = ({onTimeOver, onActionClick, config}) => {
  const [progress, changeProgress] = useState(100);

  const handleChangeProgress = useCallback(
    (currentTime) => changeProgress((currentTime / config.time) * 100),
    [config.time],
  );

  useEffect(() => setPageEnterTime(), []);

  return (
    <FreeFeatureStartPopupLayout
      progress={progress}
      config={config}
      onTimeUpdate={handleChangeProgress}
      onActionClick={onActionClick}
      onTimeOver={onTimeOver}
    />
  );
};

FreeFeatureStartPopup.propTypes /* remove-proptypes */ = {
  onTimeOver: PropTypes.func.isRequired,
  onActionClick: PropTypes.func.isRequired,
  config: freeFeatureConfigPropType.isRequired,
};

export default FreeFeatureStartPopup;
