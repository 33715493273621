import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';

import {ButtonFlat, SpacingShort, InlineGroup, Icon} from '@phoenix/ui';
import {Text} from '@phoenix/typography';
import {SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/PhotoControls.css';

const PhotoControls = ({
  isPrimary,
  onSetPrimaryClick,
  onDeleteClick,
  pendingDelete,
}) => (
  <div
    className={cn(css.container, pendingDelete && css.hidden)}
    data-test="photoControlsContainer"
  >
    <SpacingShort stretch={false} className={css.buttonWrapper}>
      {isPrimary ? (
        <SpacingShort className={css.button} data-test="primaryText">
          <InlineGroup
            space={SpacingSize.SHORT}
            horizontal
            className={css.wrapper}
            itemWrapperClassName={css.itemWrapper}
          >
            <Icon type="check" success />
            <Text type={Text.TYPE.SUCCESS}>
              {t('profile', 'text.set-main')}
            </Text>
          </InlineGroup>
        </SpacingShort>
      ) : (
        <ButtonFlat
          data-test="setPrimaryButton"
          className={css.button}
          icon="profile"
          inverse
          onClick={onSetPrimaryClick}
          trackingName="setPrimary"
        >
          {t('profile', 'text.set-main')}
        </ButtonFlat>
      )}
      <ButtonFlat
        data-test="deletePhotoButton"
        className={css.button}
        icon="trash"
        inverse
        onClick={onDeleteClick}
        trackingName="deletePhoto"
      >
        {t('profile', 'text.delete_photo')}
      </ButtonFlat>
    </SpacingShort>
  </div>
);

PhotoControls.propTypes /* remove-proptypes */ = {
  isPrimary: PropTypes.bool,
  /**
   * On pendingDelete we need still render block but hide it
   * to avoid bugs on IOS after deleting photo and rerender controls on next photo
   */
  pendingDelete: PropTypes.bool,
  onSetPrimaryClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default PhotoControls;
