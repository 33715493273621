import {
  ONE_STEP_CAROUSEL,
  ONE_STEP_CAROUSEL_WITH_CAROUSEL_PACKAGES,
  ONE_STEP_CAROUSEL_WITH_PACKAGES_SELECTOR,
  ONE_STEP_CAROUSEL_WITH_PACKAGES_SELECTOR_V2,
  ONE_STEP_CAROUSEL_WITH_PACKAGES_TABLE,
  ONE_STEP_CLEAR,
  ONE_STEP_CLEAR_2X,
  ONE_STEP_CLEAR_FEATURES,
  ONE_STEP_TRY,
  ONE_STEP_TRY_2X,
  ONE_STEP_WITH_POLICY_AGREEMENT,
} from './templateType';

/**
 * @const {Array}
 */
const ONE_STEP_TEMPLATES = [
  ONE_STEP_CAROUSEL,
  ONE_STEP_CAROUSEL_WITH_PACKAGES_SELECTOR,
  ONE_STEP_CAROUSEL_WITH_PACKAGES_SELECTOR_V2,
  ONE_STEP_CAROUSEL_WITH_PACKAGES_TABLE,
  ONE_STEP_CAROUSEL_WITH_CAROUSEL_PACKAGES,
  ONE_STEP_CLEAR,
  ONE_STEP_CLEAR_2X,
  ONE_STEP_TRY,
  ONE_STEP_TRY_2X,
  ONE_STEP_CLEAR_FEATURES,
  ONE_STEP_WITH_POLICY_AGREEMENT,
];

export default ONE_STEP_TEMPLATES;
