import {useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import buildFunnelStack from './buildFunnelStack';
import FUNNEL_STEPS_QUERY from '../graphql/queries/funnelSteps.gql';

/**
 * Provides funnel stack and flag on whether we can return to the previous step, depending on the current
 * @param {Boolean} active - if "false" avoid making requests if we are not on "funnel" step (@see PouteWithPopupDisplayer.js)
 * @returns {Object}
 */
const useFunnelStack = (active = true) => {
  const {location} = useHistory();
  const {data, loading} = useQuery(FUNNEL_STEPS_QUERY, {skip: !active});

  const stack = useMemo(() => buildFunnelStack(data), [data]);

  return {
    stack,
    // Can go back only if already pass some funnel
    canGoBack: !location.pathname.startsWith(stack[0]),
    loading,
  };
};
export default useFunnelStack;
