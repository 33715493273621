import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';

import {
  Group,
  ButtonPay,
  Spacing,
  SpacingLarge,
  Row,
  RowLarge,
  Bar,
} from '@phoenix/ui';
import {Align, IconType} from '@phoenix/ui/constants';
import {H3, TextWithIcon, Title1, Title4} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';

import {
  ONE_MONTH_OFFER_TEMPLATE,
  ONE_YEAR_OFFER_TEMPLATE,
} from '../constants/templates';
import css from '../styles/PaymentOfferPopupLayout.css';

const BENEFIT_LIST = [
  {
    getText: () => t('paymentOfferPopup', 'text.no_hidden_charges'),
    icon: 'credit-card',
  },
  {
    getText: () => t('paymentOfferPopup', 'text.unlimited_chats'),
    icon: 'chat',
  },
  {
    getText: () => t('paymentOfferPopup', 'all_search_results'),
    icon: 'search',
  },
  {
    getText: () => t('paymentOfferPopup', 'big_photos'),
    icon: 'photo',
  },
];

const PACKAGE_INFO = {
  [ONE_YEAR_OFFER_TEMPLATE]: () =>
    t('paymentOfferPopup', 'text.12m_package_info'),
  [ONE_MONTH_OFFER_TEMPLATE]: () =>
    t('paymentOfferPopup', 'text.1m_special_discount'),
};

const PaymentOfferPopupLayout = ({onClick, discount, template, price}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <PopupFrame
      spacedContent={false}
      actionWithTopMargin={false}
      content={
        <Fragment>
          <SpacingLarge onlyTop adaptive={false}>
            <SpacingLarge withoutBottom withoutTop>
              <Row align={Align.CENTER}>
                <Title4 uppercase primary>
                  {t('paymentOfferPopup', 'text.get_discount', {
                    '{discount}': <Title1 primary>{`${discount}%`}</Title1>,
                  })}
                </Title4>
              </Row>
              <Row align={Align.CENTER}>
                <H3>
                  <span>{`${t('paymentOfferPopup', 'text.dont_miss')} `}</span>
                  <span>
                    {PACKAGE_INFO[template]
                      ? PACKAGE_INFO[template]()
                      : t('paymentOfferPopup', 'text.1m_package_info')}
                  </span>
                  <span>{` ${t('paymentOfferPopup', 'now_for_only')} `}</span>
                  <span>
                    {t('paymentOfferPopup', 'price', {
                      '{currency}':
                        price.currency.prefix || price.currency.suffix,
                      '{amount}': price.formatted.amount,
                    })}
                  </span>
                </H3>
              </Row>
            </SpacingLarge>
          </SpacingLarge>
          <RowLarge adaptive={false}>
            <Bar>
              <Spacing>
                <Group>
                  {BENEFIT_LIST.map((benefit) => (
                    <TextWithIcon
                      className={isPhone ? null : css.center}
                      key={benefit.icon}
                      icon={benefit.icon}
                      iconType={IconType.MUTED}
                      textType={TextWithIcon.TYPE.MUTED}
                    >
                      {benefit.getText()}
                    </TextWithIcon>
                  ))}
                </Group>
              </Spacing>
            </Bar>
          </RowLarge>
        </Fragment>
      }
      actions={
        <ButtonPay fullWidth adaptive onClick={onClick} trackingName="getIt">
          {t('paymentOfferPopup', 'text.get_it_now')}
        </ButtonPay>
      }
    />
  );
};

PaymentOfferPopupLayout.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
  discount: PropTypes.number.isRequired,
  price: PropTypes.shape({
    formatted: PropTypes.shape({
      amount: PropTypes.string,
    }),
    currency: PropTypes.shape({
      prefix: PropTypes.string,
      suffix: PropTypes.string,
    }).isRequired,
  }).isRequired,
  template: PropTypes.string,
};

export default PaymentOfferPopupLayout;
