import React from 'react';
import {useQuery} from '@apollo/client';

import GALLERY_PHOTO_DATA_QUERY from '@phoenix/user/photo/graphql/queries/photoData.gql';

import Gallery from '../components/Gallery';
import mediaPropType from '../utils/mediaPropType';

/**
 * Create gallery with photo or/and video media.
 * The most of the cases have a photo content to always get additional data for photo
 */
const GalleryWithMedia = ({media, ...props}) => {
  const {data, loading, error} = useQuery(GALLERY_PHOTO_DATA_QUERY);
  if (loading || error) return null;

  return <Gallery site={data.site} items={media} {...props} />;
};

GalleryWithMedia.propTypes /* remove-proptypes */ = {
  /**
   * Media is used for passing data directly in Gallery component
   * @see src/packages/dating/messenger/messages/containers/MessagePhoto.js
   * @see src/packages/dating/messenger/messages/containers/MessageVideo.js
   */
  media: mediaPropType,
};

export default GalleryWithMedia;
