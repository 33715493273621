import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {Group, SpacingLarge} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';

import css from '../styles/RemarketingOfferPaymentDetailsLayout.css';

const RemarketingOfferPaymentDetailsLayout = ({children, hidden}) => (
  <SpacingLarge withoutBottom className={cn(hidden && css.hidden)}>
    <TextMuted small align={TextMuted.ALIGN.CENTER}>
      <Group>{children}</Group>
    </TextMuted>
  </SpacingLarge>
);

RemarketingOfferPaymentDetailsLayout.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default RemarketingOfferPaymentDetailsLayout;
