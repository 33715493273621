import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import ConfirmationChangeEmail from '@core/confirmation/containers/ConfirmationChangeEmail';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import ConfirmationActionsAppearance from '@core/theming/constants/features/ConfirmationActionsAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import {TextPrimary, TextMuted} from '@phoenix/typography';
import {
  ButtonLink,
  Button,
  Spacing,
  SpacingLarge,
  Bar,
  Accordion,
  Actions,
  Action,
  Loader,
} from '@phoenix/ui';
import {SpacingSize, ButtonType, Align} from '@phoenix/ui/constants';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import ActionWithSuccessMessage from './ActionWithSuccessMessage';
import ConfirmationForm from './ConfirmationForm';
import css from '../styles/Confirmation.css';

/**
 * Layout for confirmation block (displayed as popup)
 */
const ConfirmationLayout = ({
  motivation,
  content,
  footer,
  emailService,
  showSuccessMessage,
  resendingEmail,
  withResentLink,
  onResendClick,
  actionsWithIcons,
}) => {
  const {data: appearance} = useThemeFeature('confirmation', 'appearance');
  const isSmallTablet = useSmallTabletBreakpoint();
  const isPhone = usePhoneBreakpoint();
  const withoutIcons =
    !isPhone && appearance === ConfirmationActionsAppearance.WITHOUT_ICONS;
  const actionClassName = isSmallTablet
    ? css.verticalAction
    : css.horizontalAction;

  return (
    <div data-test="confirmation">
      {motivation && (
        <Bar
          className={css.motivation}
          asPopupHeading
          data-test="confirmationMotivation"
        >
          <Spacing
            size={isSmallTablet ? SpacingSize.SHORT : SpacingSize.NORMAL}
          >
            {motivation}
          </Spacing>
        </Bar>
      )}
      {content}
      {/* To track 'confirmation' popup load completion */}
      <PopupLoadTracker />
      <SpacingLarge>
        <Actions
          spacing={isSmallTablet ? SpacingSize.NORMAL : SpacingSize.LARGE}
          align={Align.CENTER}
          itemSpacing={SpacingSize.SHORT}
          vertical={isSmallTablet || withResentLink}
          fullWidth={withResentLink}
        >
          {emailService && (
            <Action className={actionClassName}>
              <ButtonLink
                href={emailService}
                target="_blank"
                fullWidth
                type={ButtonType.PRIMARY}
                data-test="confirmationCheckEmail"
                icon={actionsWithIcons && 'mail'}
              >
                {t('emailValidationBase', 'title.check_email')}
              </ButtonLink>
            </Action>
          )}
          {withResentLink ? (
            <Action className={css.verticalAction}>
              <Loader small active={resendingEmail}>
                <ActionWithSuccessMessage
                  successMessage={t('emailValidationBase', 'text.email_sent')}
                  showSuccessMessage={showSuccessMessage}
                  actionComponent={TextMuted}
                  inline
                  small
                >
                  {t('emailValidationBase', 'text.resend', {
                    '{n}': (
                      <TextPrimary
                        className={css.resendLink}
                        trackingName="resendLink"
                        onClick={onResendClick}
                        data-test="confirmationResendEmail"
                        inline
                        small
                      >
                        {t('emailValidationBase', 'text.resend_confirmation')}
                      </TextPrimary>
                    ),
                  })}
                </ActionWithSuccessMessage>
              </Loader>
            </Action>
          ) : (
            <Action className={actionClassName}>
              <Loader small active={resendingEmail}>
                <ActionWithSuccessMessage
                  successMessage={t('emailValidationBase', 'text.email_sent')}
                  showSuccessMessage={showSuccessMessage}
                  actionComponent={Button}
                  data-test="confirmationResendEmail"
                  trackingName="resend"
                  onClick={onResendClick}
                  icon={actionsWithIcons && 'refresh'}
                  fullWidth
                >
                  {t('emailValidationBase', 'button.resend')}
                </ActionWithSuccessMessage>
              </Loader>
            </Action>
          )}
        </Actions>
      </SpacingLarge>
      {/* Since accordions rely on first/last child for correct border display */}
      <div>
        <Accordion
          title={t('emailValidation', 'button.email_validation_change')}
          data-test="confirmationChangeEmailAccordion"
          icon="contact"
          spacedIcon={!withoutIcons}
        >
          <ConfirmationChangeEmail
            confirmationForm={ConfirmationForm}
            errorBoundary={ErrorBoundary}
          />
        </Accordion>
      </div>
      <Spacing
        onlyTop
        size={withoutIcons ? SpacingSize.NORMAL : SpacingSize.NONE}
      >
        {footer}
      </Spacing>
    </div>
  );
};

ConfirmationLayout.propTypes /* remove-proptypes */ = {
  motivation: PropTypes.node,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  emailService: PropTypes.string,
  showSuccessMessage: PropTypes.bool.isRequired,
  resendingEmail: PropTypes.bool.isRequired,
  onResendClick: PropTypes.func.isRequired,
  withResentLink: PropTypes.bool,
  actionsWithIcons: PropTypes.bool,
};

export default ConfirmationLayout;
