import type {Client} from '@core/graphql/types';
import MESSENGER_QUERY from '@core/messenger/common/graphql/queries/messenger.gql';
import type {MessengerQuery} from '@core/messenger/common/graphql/queries/messenger';

/**
 * Returns true if this user already in 'recipients'.
 */
const isContact = (client: Client, userId: string) => {
  const data = client.readQuery<MessengerQuery>({
    query: MESSENGER_QUERY,
  });

  if (data) {
    return data.messenger.initialData.recipients.some(
      (recipient) => recipient.id === userId,
    );
  }

  /**
   * invalidateCacheByTypename(..., 'Recipient') removes recipients from cache
   * and readQuery returns null.
   * This is the case when buying microfeatures by one click.
   * When query is mounted it is automatically refetched.
   * @see updateCachesAfterPayment
   */
  return false;
};

export default isContact;
