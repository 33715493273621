import {getClientInstance} from '@core/graphql/client';

import CHAT_OPENED_MUTATION from '@phoenix/activity/graphql/mutations/chatOpened.gql';
import type {
  ChatOpenedMutation,
  ChatOpenedMutationVariables,
} from '@phoenix/activity/graphql/mutations/chatOpened';

const markChatAsOpened = (userId: string) => {
  getClientInstance().mutate<ChatOpenedMutation, ChatOpenedMutationVariables>({
    mutation: CHAT_OPENED_MUTATION,
    variables: {
      assistantId: userId,
    },
  });
};

export default markChatAsOpened;
