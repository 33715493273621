import {useReactiveVar} from '@apollo/client';

import deferredPageAvailableVar from './deferredPageAvailableVar';

/**
 * Flag to understand that it is possible to draw the popup background component
 *
 * @return {{setDeferredPageAvailable: Function, deferredPageAvailable: boolean}}
 */
const useDeferredPageAvailable = () => {
  const deferredPageAvailable = useReactiveVar(deferredPageAvailableVar);

  return {
    deferredPageAvailable,
    setDeferredPageAvailable: deferredPageAvailableVar,
  };
};
export default useDeferredPageAvailable;
