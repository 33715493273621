/* eslint-disable react/prop-types */

import React, {useEffect} from 'react';
import {Redirect} from 'react-router-dom';

import {RedirectToRoot, RedirectToDefaultPage} from '@core/utils/routing';
import RedirectToCaptcha from '@core/utils/routing/RedirectToCaptcha';
import {PROTECT_TYPES} from '@core/massBlocked/constants/protectTypes';
import removeRecipientsInCache from '@core/messenger/common/utils/removeRecipientsInCache';
import getActiveRecipientId from '@core/messenger/common/utils/getActiveRecipientId';
import updateMiniMessengerVisibleRecipient from '@core/messenger/common/utils/updateMiniMessengerVisibleRecipient';
import createUrlFor from '@core/utils/url/createUrlFor';
import {PAID_FUNNEL} from '@core/application/constants/routesWithPopup';
import {ViaEnum} from '@core/types';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import {PAID_FUNNEL_VIA} from '@core/payment/common/constants/vias';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

import PhotoRestrictionHelper from '@phoenix/user/PhotoRestrictionHelper';

import ROUTES from './routes';
import {
  FUNNEL_PAID,
  PP_AFTER_REG,
  REMOVE_ACCOUNT_NOT_ALLOWED,
} from './accessErrorCode';

/**
 * Object, used as "bridge" between error code (arrived from back-end),
 * and action, that must be performed if this error occurs.
 *
 * Value is a function. Also, 'children' prop is passed inside, so you can display anything
 * Useful for e.g. when you need to open popup or make some other side effect that doesn't
 * replace main content.
 */
export default {
  [REMOVE_ACCOUNT_NOT_ALLOWED]: () => <Redirect to="/account" />,
  IP_IS_BANNED: (props) => (
    <RedirectToCaptcha protectType={PROTECT_TYPES.IP_IS_BANNED} {...props} />
  ),
  VIEW_PROFILE_BAN: (props) => (
    <RedirectToCaptcha
      protectType={PROTECT_TYPES.VIEW_PROFILE_BAN}
      {...props}
    />
  ),
  PAID_APP_FUNNEL: () => <Redirect to={ROUTES.PAY_APP_FUNNEL} />,
  EMAIL_NOT_CONFIRMED: () => <Redirect to="/confirmation" />,
  /**
   * Should navigate without replace, because it error occurred on some user action
   * and we have to return back on same page
   */
  SHOULD_BE_TRUSTED: () => {
    const redirectUrl = createUrlFor.trustedVerification(ViaEnum.message);
    return <Redirect to={redirectUrl} push />;
  },
  USER_IS_GUEST: () => <RedirectToRoot />,
  SITE_NOT_AVAILABLE: () => <Redirect to="/forbidden" />,
  VIEW_PROFILE_RESTRICTION: ({errorData: {userId, recipientId} = {}}) => {
    useEffect(() => {
      const checkingRecipientId = userId || recipientId;
      if (checkingRecipientId) {
        getActiveRecipientId().then((activeRecipientId) => {
          if (activeRecipientId === checkingRecipientId) {
            updateMiniMessengerVisibleRecipient(null);
          }
        });

        // Need to remove this recipient from list
        removeRecipientsInCache([checkingRecipientId]);
      }
    }, [userId, recipientId]);

    return <Redirect to="/notFound" />;
  },
  [FUNNEL_PAID]: () => <Redirect to={`${PAID_FUNNEL}/type/default`} />,
  [PP_AFTER_REG]: () => (
    <Redirect
      to={generatePayUrl({
        stage: PAYMENT_ACTIONS.MEMBERSHIP,
        urlParams: {via: PAID_FUNNEL_VIA},
      })}
    />
  ),
  PERSONAL_IDENTITY_NOT_VERIFIED: () => <Redirect to="/personalIdentity" />,
  PERSONAL_IDENTITY_VERIFIED: () => <RedirectToDefaultPage />,
  NOT_ALLOWED: () => <RedirectToDefaultPage />,
  NO_PHOTO: () => {
    PhotoRestrictionHelper.openNoPhotoPopup();
    return null;
  },
  WAIT_FOR_APPROVE: () => {
    PhotoRestrictionHelper.openWaitForApprovePopup();
    return null;
  },
};
