import {useFragment} from '@apollo/client';

import FREE_VIEWS_REWARD_FRAGMENT from '@core/messenger/common/graphql/fragments/freeViewsReward.gql';

import startPhotoRewardListener from './startPhotoRewardListener';

/**
 * Returns amount of free messages you will get for first photo upload.
 * @return {number}
 */
const useFirstPhotoReward = () => {
  const {data} = useFragment({
    fragment: FREE_VIEWS_REWARD_FRAGMENT,
    fragmentName: 'FreeViewsReward',
    from: {__typename: 'FreeViewsReward', type: 'firstPhotoReward'},
  });

  startPhotoRewardListener();

  return data.activated === false ? data.amount : 0;
};

export default useFirstPhotoReward;
