import {useEffect, useRef} from 'react';
import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import usePaymentProcessingStatus from '@core/payment/common/utils/usePaymentProcessingStatus';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';

import TRACK_PAYMENT_VISIT_MUTATION from '../graphql/mutations/trackPaymentVisit.gql';

export const trackPaymentVisit = (
  action,
  via,
  prevVia,
  viaProfileId,
  source,
) => {
  getClientInstance().mutate({
    mutation: TRACK_PAYMENT_VISIT_MUTATION,
    variables: {
      action,
      via,
      prevVia,
      viaProfileId,
      source,
    },
  });
};

const useTrackPaymentVisit = () => {
  const {action, via, prevVia, viaProfileId, source} = usePaymentParams();
  const {activePackage} = useActivePackage();
  const hasActivePackage = Boolean(activePackage);
  const processingStatus = usePaymentProcessingStatus();
  const trackPaymentVisitRef = useRef(once(trackPaymentVisit));

  useEffect(() => {
    if (processingStatus !== PROCESSING_STATUS.READY || !hasActivePackage) {
      return;
    }

    trackPaymentVisitRef.current(action, via, prevVia, viaProfileId, source);
  }, [
    action,
    via,
    prevVia,
    viaProfileId,
    processingStatus,
    hasActivePackage,
    source,
  ]);
};

export default useTrackPaymentVisit;
