import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import GalleryPopup from '../components/GalleryPopup';

/**
 * Method is for GalleryDataopening gallery.
 * Use component GalleryData instead of async function for getting data for gallery because this data can change while time
 * @param GalleryData - wrapper component for gallery, need for getting data for it (@see openPhotoGallery.js)
 * @param galleryProps - @see GalleryWithMedia
 * @param popupOptions - addition options for popup
 */
const openGalleryPopup = (
  {GalleryData, ...galleryProps} = {},
  popupOptions = {},
) => {
  const Gallery = GalleryData ? (
    <GalleryData {...galleryProps}>
      {(propsWithMedia) => <GalleryPopup {...propsWithMedia} />}
    </GalleryData>
  ) : (
    <GalleryPopup {...galleryProps} />
  );

  PopupService.openPopup(Gallery, {
    inverse: true,
    fullSize: true,
    trackingName: 'mediaGallery',
    ...popupOptions,
  });
};

export default openGalleryPopup;
