import React from 'react';

import {Align} from '@phoenix/ui/constants';
import {PlaceholderBar, Card, Spacing, Group, SpacingLarge} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import PhotosListAsRoulettePlaceholder from '@phoenix/user/photo/components/PhotosListAsRoulettePlaceholder';

const CoinsRewardPopupPlaceholder = () => (
  <PopupFrame
    spacedContent={false}
    content={
      <SpacingLarge onlyTop adaptive={false}>
        <SpacingLarge withoutTop withoutBottom adaptive>
          <PhotosListAsRoulettePlaceholder />
          <Spacing onlyBottom>
            <PlaceholderBar size={12} />
            <PlaceholderBar size={12} />
          </Spacing>
        </SpacingLarge>
        <Card highlighted boundless shadowLevel={0}>
          <Spacing>
            <PlaceholderBar size={12} />
          </Spacing>
        </Card>
      </SpacingLarge>
    }
    actions={
      <Group align={Align.AROUND}>
        <PlaceholderBar size={4} />
        <PlaceholderBar size={4} />
      </Group>
    }
  />
);

export default CoinsRewardPopupPlaceholder;
