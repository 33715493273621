import React from 'react';

import useRandomUsers from '@phoenix/search/list/utils/useRandomUsers';
import useFreeCoinsReward from '@phoenix/freeCoins/utils/useFreeCoinsReward';
import {WEB_PUSH} from '@phoenix/freeCoins/constants/taskType';
import useSkipBlindClick from '@phoenix/remarketing/popunder/utils/useSkipBlindClick';

import CoinsRewardPopupPlaceholder from '../components/CoinsRewardPopupPlaceholder';
import CoinsRewardPopupLayout from '../components/CoinsRewardPopupLayout';

const USERS_AMOUNT = 3;

const CoinsRewardPopup = (props) => {
  useSkipBlindClick();

  const {randomUsers: users, loading} = useRandomUsers({
    count: USERS_AMOUNT,
    exact: true,
  });

  const {reward, loading: rewardLoading} = useFreeCoinsReward(WEB_PUSH);

  if (loading || rewardLoading) return <CoinsRewardPopupPlaceholder />;

  return <CoinsRewardPopupLayout users={users} reward={reward} {...props} />;
};

export default CoinsRewardPopup;
