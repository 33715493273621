import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

/**
 * @param expireAt
 */
const openFreeDailySpinPopup = async (expireAt) => {
  const {default: FreeDailySpinPopup} = await import(
    /* webpackChunkName: 'freeDailySpinPopup' */ '@phoenix/freeDailySpin/containers/FreeDailySpinPopup'
  );

  PopupService.openPopup(
    <FreeDailySpinPopup
      expireAt={expireAt}
      openSpinPopup={openFreeDailySpinPopup}
    />,
    {
      noTitle: true,
      small: true,
      priority: PopupPriority.LOW,
      trackingName: 'spinNow',
    },
  );
};

export default openFreeDailySpinPopup;
