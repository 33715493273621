import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isObject from 'lodash/isObject';

import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import LOGOS from '@core/payment/widgets/securityLogos/constants/logos';

import useThemeColorScheme from '@phoenix/theming/utils/useThemeColorScheme';

import IMAGES from '../constants/images';
import MoneyBackPopover from '../containers/MoneyBackPopover';
import css from '../styles/PaymentSecurityLogosLayout.css';

const {MONEYBACK} = LOGOS;

const SecurityLogoItem = ({logo, onClick, colorScheme, trackingName}) => (
  <AddBabciaUBTracking trackingName={trackingName}>
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
    <img
      alt={logo}
      src={isObject(IMAGES[logo]) ? IMAGES[logo][colorScheme] : IMAGES[logo]}
      className={cn(css.item, css[logo.toLowerCase()])}
      onClick={onClick}
      data-test="securityLogoItem"
    />
  </AddBabciaUBTracking>
);

SecurityLogoItem.propTypes /* remove-proptypes */ = {
  logo: PropTypes.oneOf(Object.values(LOGOS)).isRequired,
  colorScheme: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  trackingName: PropTypes.string,
};

const PaymentSecurityLogosLayout = ({securityLogos, onClick}) => {
  const colorScheme = useThemeColorScheme();

  const securityLogosList = useMemo(() => {
    return securityLogos.filter((logo) => logo !== MONEYBACK);
  }, [securityLogos]);

  const hasMoneyBackLogo = useMemo(() => {
    return securityLogos.includes(MONEYBACK);
  }, [securityLogos]);

  return (
    <div
      className={css.container}
      data-test="paymentSecurityLogos"
      role="button"
      tabIndex={0}
    >
      {/* Money back should be first */}
      {hasMoneyBackLogo &&
        (isCompactViewportCached() ? (
          <SecurityLogoItem
            logo={MONEYBACK}
            onClick={onClick}
            trackingName={onClick ? 'securityLogo' : null}
            colorScheme={colorScheme}
          />
        ) : (
          <MoneyBackPopover>
            <SecurityLogoItem
              logo={MONEYBACK}
              // eslint-disable-next-line local-rules/tracking-name -- has tracking inside MoneyBackPopover
              onClick={onClick}
              colorScheme={colorScheme}
            />
          </MoneyBackPopover>
        ))}
      {securityLogosList.map((logo) => (
        <SecurityLogoItem
          key={logo}
          logo={logo}
          onClick={onClick}
          trackingName={onClick ? 'securityLogo' : null}
          colorScheme={colorScheme}
        />
      ))}
    </div>
  );
};

PaymentSecurityLogosLayout.propTypes /* remove-proptypes */ = {
  securityLogos: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(LOGOS)).isRequired,
  ),
  onClick: PropTypes.func,
};

export default PaymentSecurityLogosLayout;
