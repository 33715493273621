import React from 'react';
import PropTypes from 'prop-types';

import {TextMuted} from '@phoenix/typography';
import {RowShort} from '@phoenix/ui';

import css from '../styles/SwitchShowGiftsListButton.css';

const SwitchShowGiftsListButton = ({onClick, trackingName, children}) => (
  <RowShort
    className={css.button}
    role="button"
    tabIndex={0}
    onClick={onClick}
    trackingName={trackingName}
  >
    <TextMuted textDecoration="underline">{children}</TextMuted>
  </RowShort>
);

SwitchShowGiftsListButton.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
  trackingName: PropTypes.string.isRequired,
  children: PropTypes.node,
};
export default SwitchShowGiftsListButton;
