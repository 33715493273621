import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

/**
 * Routes, where the extra popup should not be displayed
 */
const DISABLED_ROUTES = [
  ...ROUTES_WITH_POPUP,
  '/pay',
  '/chat',
  '/rooms',
  '/videoChat',
  '/notFound',
  '/newsFeed',
  '/verification',
  '/trusted',
  '/forbidden',
  '/story/stream',
];

export default DISABLED_ROUTES;
