import {localStorage as storage} from '@core/utils/storage';

import {POPUNDER_ACTIONS_MAP} from '../constants/popunderActionsMap';

const POPUNDER_ACTIONS = 'popunderActions';

/**
 * Remove popunder action from localStorage
 * @param {String} userAction - user action
 */
const removeActionFromStorage = (userAction, userId) => {
  const popunderUserActions = storage.getItem(POPUNDER_ACTIONS) || {};

  const userActions = popunderUserActions[userId] || [];

  storage.setItem(POPUNDER_ACTIONS, {
    ...popunderUserActions,
    [userId]: userActions.filter((action) => action.userAction !== userAction),
  });
};

/**
 * Service for popunder actions
 * @param {Function} callback - callback for popunder delay action
 * @param {Array} actions - list of popunder delay actions
 * @return {Object}
 */
const popunderActionDelayStarter = (actions = [], callback, userId) => {
  const actionTimeouts = [];

  const actionCallback = (userAction, delay) => {
    const timeout = setTimeout(() => {
      callback();
      removeActionFromStorage(userAction, userId);
    }, delay);

    actionTimeouts.push(timeout);
  };

  const cachedPopunderUserActions = storage.getItem(POPUNDER_ACTIONS) || {};

  const cachedUserActions = cachedPopunderUserActions[userId] || [];

  /**
   * Check cached popunder actions and call it
   */
  cachedUserActions.forEach(({userAction, actionTime}) => {
    const ms = Number(actionTime) - Date.now();

    if (ms > 0) {
      actionCallback(userAction, ms);
    } else {
      removeActionFromStorage(userAction, userId);
    }
  });

  actions.forEach(({userAction, delay}) => {
    if (POPUNDER_ACTIONS_MAP[userAction]) {
      POPUNDER_ACTIONS_MAP[userAction]().subscribe(() => {
        if (delay) {
          const ms = delay * 1000;

          actionCallback(userAction, ms);

          /**
           * Save popunder action data to localStorage and
           * call it if page have been reloaded
           */
          storage.setItem(POPUNDER_ACTIONS, {
            ...cachedPopunderUserActions,
            [userId]: [
              ...cachedUserActions,
              {userAction, actionTime: Date.now() + ms},
            ],
          });
        }
      });
    }
  });

  const clearPopunderActionTimeouts = () => {
    actionTimeouts.forEach((timeout) => clearTimeout(timeout));
  };

  return {
    clear: clearPopunderActionTimeouts,
  };
};

export default popunderActionDelayStarter;
