import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import {ERROR_TYPES} from '@core/mediaUpload/constants/webCamErrors';

import {Group, Spacing} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {H3, TextMuted} from '@phoenix/typography';

const getErrorTitle = (error) => {
  if (error === ERROR_TYPES.PERMISSION_DENIED_ERROR_TYPE) {
    return t('streaming', 'title.myStreamBlocked');
  }
  if (error === ERROR_TYPES.FLASH_ENABLED_ERROR_TYPE) {
    return t('uploadPhotoWebCam', 'title.adobeFlashPlayer');
  }
  return t('uploadPhotoWebCam', 'text.video_not_available');
};

const getErrorDescription = (error) => {
  if (error === ERROR_TYPES.PERMISSION_DENIED_ERROR_TYPE) {
    return t('streaming', 'text.myStreamBlocked');
  }
  if (error === ERROR_TYPES.FLASH_ENABLED_ERROR_TYPE) {
    return (
      <Fragment>
        <a href="https://get.adobe.com/flashplayer/">
          {t('uploadPhotoWebCam', 'button.installFlashPlayer')}
        </a>
        <div>{t('uploadPhotoWebCam', 'title.activateFlashPlayer')}</div>
      </Fragment>
    );
  }
  return '';
};

const WebcamError = ({error}) => {
  const title = getErrorTitle(error);
  const description = getErrorDescription(error);

  return (
    <Spacing>
      <Group space={SpacingSize.SHORT}>
        <H3 inverse data-test="webcamErrorTitle">
          {title}
        </H3>
        {description && (
          <TextMuted inverse data-test="webcamErrorDescription">
            {description}
          </TextMuted>
        )}
      </Group>
    </Spacing>
  );
};

WebcamError.propTypes /* remove-proptypes */ = {
  error: PropTypes.string,
};

export default WebcamError;
