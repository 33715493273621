/**
 * Represents the remarketing category.
 * Used to identify campaigns related to remarketing.
 */
export const REMARKETING = 'remarketing';

/**
 * Represents a promo campaign or special offers.
 * Used to label content related to promotions.
 */
export const PROMO = 'promo';
