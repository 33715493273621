import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {getClientInstance} from '@core/graphql/client';
import PopupPriority from '@core/popup/constants/PopupPriority';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import {getRmOfferConceptNameCookie} from '@core/application/utils/getRmBootstrapPopup';
import getPaymentStatus from '@core/payment/common/utils/getPaymentStatus';

import trackRmPopup from '@phoenix/tracking/rmPopup/trackRmPopup';
import isRemarketingOfferPopupUrl from '@phoenix/remarketing/offerPopup/utils/isRemarketingOfferPopupUrl';

import REMARKETING_OFFER_QUERY from '../graphql/queries/remarketingOffer.gql';
import RemarketingOfferPopup from '../containers/RemarketingOfferPopup';
import {CLOSE_CLICK} from '../constants/trackingName';
import REMARKETING_TRACK_CLOSE_MUTATION from '../graphql/mutations/remarketingTrackClose.gql';
import getTrackingName from './getTrackingName';
import getConceptId from './getConceptId';
import popupDisplay from './popupDisplay';

/**
 * @const {String}
 */
const FEATURE_CONCEPT8_ID = 'feature_concept8';

const openRemarketingOfferPopup = async ({
  returnPathAfterPay,
  needToReFetch = false,
  onClose,
  goToDefault,
  checkShown = true,
  applySecondAttemptTrack = false,
}) => {
  const client = getClientInstance();

  if (
    checkShown &&
    !window.IS_INTEGRATION_TEST_ENVIRONMENT &&
    !popupDisplay.isAllowed()
  ) {
    goToDefault?.();
    return;
  }

  /**
   * We have issue when rm offer page isn`t our main page,
   * and we don`t load data in appData for Rm offer,
   * so we must in forced way get data for offer if
   * we open url after another non funnel page
   * as example FunnelQuizPage -> PP -> remarketingOffer
   * @see FunnelQuizPage
   * @type {boolean}
   */
  const isLoadFromCache = !needToReFetch && isRemarketingOfferPopupUrl();

  const [{data}, isPaid] = await Promise.all([
    client.query({
      query: REMARKETING_OFFER_QUERY,
      variables: {
        // To get data for cached concept
        isCached: isLoadFromCache && Boolean(getRmOfferConceptNameCookie()),
      },
      /**
       * For remarketing offer route this data will be preloaded in AppDataQuery
       */
      fetchPolicy: isLoadFromCache ? 'cache-only' : 'network-only',
    }),
    getPaymentStatus(),
  ]);

  const remarketing = data?.remarketing;

  const conceptId = getConceptId(remarketing?.paymentPopup?.conceptInfo?.name);

  if (!data || !data?.remarketing || !conceptId) {
    goToDefault?.();
    return;
  }

  /**
   * Remember the display here to prevent the popup from opening twice.
   * For example, when navigating to /search, the URL updates to /search?... (with parameters),
   * which triggers an additional event in {@see PopupOnBootstrapDisplayer} and {@see remarketingOfferPopupAction}.
   */
  if (!window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    popupDisplay.rememberDisplay();
  }

  PopupService.openPopup(
    <RemarketingOfferPopup
      returnPathAfterPay={returnPathAfterPay}
      applySecondAttemptTrack={applySecondAttemptTrack}
      goToDefault={goToDefault}
      remarketingOfferData={data}
      conceptId={conceptId}
      isPaid={isPaid}
    />,
    {
      priority: PopupPriority.LOW,
      small: true,
      trackingConceptId: conceptId,
      trackingName: 'remarketingOffer',
      inverseCloseButton: conceptId === FEATURE_CONCEPT8_ID,
      showCloseButton: false,
      onClose: (sourceEventType) => {
        if (
          [
            PopupSourceEvent.CLOSE_BUTTON_CLICK,
            PopupSourceEvent.BY_ESCAPE,
          ].includes(sourceEventType)
        ) {
          trackRmPopup({
            popupId: getTrackingName(applySecondAttemptTrack, conceptId),
            action: CLOSE_CLICK,
          });
          client.mutate({
            mutation: REMARKETING_TRACK_CLOSE_MUTATION,
            variables: {
              hash: remarketing?.paymentPopup?.hash,
            },
          });
        }
        onClose && onClose();
      },
    },
  );
};

export default openRemarketingOfferPopup;
