import React from 'react';
import PropTypes from 'prop-types';

import applePayIcon from '@core/payment/widgets/applePay/images/applePay.svg';
import applePayIconInverse from '@core/payment/widgets/applePay/images/applePayInverse.svg';
import ColorScheme from '@core/theming/constants/ColorScheme';

import useThemeColorScheme from '@phoenix/theming/utils/useThemeColorScheme';

import WalletPayButton from '../../../common/components/WalletPayButton';

const PaymentPageApplePayButton = ({onClick, ...props}) => {
  const inverse = useThemeColorScheme() === ColorScheme.DARK;

  return (
    <WalletPayButton
      inverse={inverse}
      alt="ApplePay"
      icon={inverse ? applePayIconInverse : applePayIcon}
      onClick={onClick}
      trackingName="applePay"
      {...props}
    />
  );
};

PaymentPageApplePayButton.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
};

export default PaymentPageApplePayButton;
