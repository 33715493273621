import React, {useState} from 'react';
import PropTypes from 'prop-types';

import useSelectedAdditionalPackages from '@core/payment/widgets/package/utils/useSelectedAdditionalPackages';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import useApplePaySettings from '@core/payment/widgets/applePay/utils/useApplePaySettings';
import useEventCallback from '@core/utils/react/useEventCallback';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';

import PaymentPageApplePayButton from '@phoenix/payment/widgets/applePay/components/PaymentPageApplePayButton';
import getApplePayRemarketingPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getApplePayRemarketingPopupFlow';
import trackRmPopup from '@phoenix/tracking/rmPopup/trackRmPopup';

import {APPLE_PAY_CLICK} from '../constants/trackingName';

const RemarketingOfferPopupApplePayButton = ({
  trackingConceptName,
  cancelRemarketingSubscription,
}) => {
  const [disabled, setDisabled] = useState(false);
  const {action, via} = usePaymentParams();
  const {packageList} = useSelectedAdditionalPackages();
  const {activePackage} = useActivePackage();
  const {settings, country, siteName} = useApplePaySettings();

  const handleApplePay = useEventCallback(() => {
    setDisabled(true);

    trackRmPopup({
      popupId: trackingConceptName,
      action: APPLE_PAY_CLICK,
    });

    getProcessPaymentFlow(getApplePayRemarketingPopupFlow())({
      action,
      via,
      activePackage,
      country,
      siteName,
      settings,
      selectedAdditionalPackages: packageList,
      urlParams: {
        skipStepOnBack: true,
      },
      cancelRemarketingSubscription,
      method: PAYMENT_METHODS.APPLE_PAY,
    }).then(({status}) => {
      if (!status) {
        setDisabled(false);
      }
    });
  });

  return (
    <PaymentPageApplePayButton
      onClick={handleApplePay}
      disabled={disabled}
      fullWidth
      adaptive
    />
  );
};

RemarketingOfferPopupApplePayButton.propTypes /* remove-proptypes */ = {
  trackingConceptName: PropTypes.string.isRequired,
  cancelRemarketingSubscription: PropTypes.bool.isRequired,
};

export default RemarketingOfferPopupApplePayButton;
