import React from 'react';
import PropTypes from 'prop-types';

import UpdateEmail from '@phoenix/updateEmail/containers/UpdateEmail';

const EmailUpdateRoute = ({location}) => <UpdateEmail location={location} />;

EmailUpdateRoute.propTypes /* remove-proptypes */ = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default EmailUpdateRoute;
