import {useEffect} from 'react';
import {useQuery} from '@apollo/client';

import PopupService from '@core/popup/utils/PopupService';

import VerificationStatus from '@phoenix/personalIdentity/constants/verificationStatus';

import PERSONAL_IDENTITY_ON_DEMAND_QUERY from '../graphql/queries/personalIdentityByDemand.gql';

/**
 * We have to close popup if user was approved
 */
const useClosingPopupForVerifiedStatus = () => {
  const {data} = useQuery(PERSONAL_IDENTITY_ON_DEMAND_QUERY);

  const {userFeatures: {personalIdentity: {verificationStatus} = {}} = {}} =
    data || {};

  useEffect(() => {
    if (verificationStatus === VerificationStatus.APPROVED) {
      PopupService.closePopup(true);
    }
  }, [verificationStatus]);
};

export default useClosingPopupForVerifiedStatus;
