/**
 * Paid funnel popup is displayed only once per session
 * @type {string}
 */
export const PAID_FUNNEL_SHOWN_STORAGE_KEY = 'PopupPaidFunnelShown';

/**
 * Skip strict paid funnel when not set concept name from backend
 * @type {string}
 */
export const PAID_FUNNEL_SKIP_STRICT_STORAGE_KEY =
  'PopupPaidFunnelSkipStrictFunnel';

/**
 * Concept name for QA testing
 * @type {string}
 */
export const PAID_FUNNEL_CONCEPT_KEY = 'paidFunnelConcept';

/**
 * Redirect to current profile is already pushed in stack on funnel
 * @type {string}
 */
export const REDIRECT_TO_CURRENT_PROFILE_PUSHED_ON_FUNNEL_STORAGE_KEY =
  'redirectToCurrentProfilePushedOnFunnel';
