import {useQuery, useReactiveVar} from '@apollo/client';

import paymentCredentialsPolicyCheckedVar from '@core/graphql/vars/paymentCredentialsPolicyCheckedVar';
import useIsMobFirstStage from '@core/payment/common/utils/useIsMobFirstStage';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import PAYMENT_CREDENTIALS_POLICY_QUERY from '@core/payment/widgets/billingPolicy/graphql/queries/paymentCredentialsPolicy.gql';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';

import useWithPolicyAgreement from '@phoenix/payment/pages/utils/useWithPolicyAgreement';

import useIsMobOneStep from '../../../common/utils/useIsMobOneStep';

const useCredentialsPolicy = () => {
  const {action, via, prevVia, source} = usePaymentParams();
  const isCardMethod = isCardPaymentType(useReactiveVar(activeMethodTabVar));
  const withPolicyAgreement = useWithPolicyAgreement();

  const {data, loading, error} = useQuery(PAYMENT_CREDENTIALS_POLICY_QUERY, {
    variables: {action, via, prevVia, source},
  });

  const isCredentialsPolicyChecked = useReactiveVar(
    paymentCredentialsPolicyCheckedVar,
  );

  const isMobFirstStage = useIsMobFirstStage();
  const {isOneStep, loading: isOneStepLoading} = useIsMobOneStep();

  return {
    loading: loading || isOneStepLoading,
    error,
    isCredentialsPolicyNeeded:
      Boolean(
        data?.payment.paymentFooter.blockDataMap.textBillingPolicy
          .isShowCredentialsPolicy,
      ) &&
      isCardMethod &&
      (withPolicyAgreement || isOneStep || !isMobFirstStage),
    credentialsPolicyDefaultValue:
      data?.payment.templateSettings.additionalTermsStatusMap.credentialsPolicy,
    isCredentialsPolicyChecked,
    setIsCredentialsPolicyChecked: paymentCredentialsPolicyCheckedVar,
  };
};

export default useCredentialsPolicy;
