import React, {useState} from 'react';
import PropTypes from 'prop-types';

import VIDEO_CHAT_STATUSES from '../constants/statusConstants';
import Context from './VideoChatContext';
import useVideoChatControllers from '../utils/useVideoChatControllers';

const VideoChatProvider = ({children, disabled}) => {
  const [videoChatStatus, setVideoChatStatus] = useState(
    VIDEO_CHAT_STATUSES.READY,
  );
  const [opponentUserId, setOpponentUserId] = useState('');
  const [myStreamId, setMyStreamId] = useState('');
  const [targetStreamId, setTargetStreamId] = useState('');
  const {
    rejectCall,
    acceptCall,
    stopCall,
    leaveCall,
    startCallWithUser,
    startVideoCallTimer,
  } = useVideoChatControllers({
    videoChatStatus,
    setVideoChatStatus,
    opponentUserId,
    setOpponentUserId,
    myStreamId,
    setMyStreamId,
    targetStreamId,
    setTargetStreamId,
    disabled,
  });

  return (
    <Context.Provider
      value={{
        status: videoChatStatus,
        rejectCall,
        acceptCall,
        startCallWithUser,
        leaveCall,
        stopCall,
        localStreamId: myStreamId,
        remoteStreamId: targetStreamId,
        startVideoCallTimer,
      }}
    >
      {children}
    </Context.Provider>
  );
};

VideoChatProvider.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default VideoChatProvider;
