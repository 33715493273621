import React from 'react';

import t from '@core/translations/translate';

import CoinsCommonSuccessPopup from '@phoenix/coins/buyCoins/components/CoinsCommonSuccessPopup';

/**
 * Popup with information about the reward in coins, applied to user for confirming its own account via email.
 */
const FreeCoinsEmailConfirmationReward = () => (
  <CoinsCommonSuccessPopup
    title={t('freeCoins', 'title.rewardForEmailConfirmation')}
    description={t('freeCoins', 'text.rewardForEmailConfirmation')}
    action={t('freeCoins', 'action.rewardForEmailConfirmation')}
  />
);

export default FreeCoinsEmailConfirmationReward;
