import type {FC, ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import debounce from 'lodash/debounce';
import cn from 'classnames';
import {motion, AnimatePresence} from 'framer-motion';

import getAnimationTime from '@core/utils/animation/utils/getAnimationTime';
import type HeaderPositionAppearance from '@core/theming/constants/features/HeaderPositionAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import useHeaderAndToolbarVariablesUpdater from '@phoenix/header/utils/useHeaderAndToolbarVariablesUpdater';

import css from '../styles/AnimatedSlideableFloatingSystemNotifications.css';

const DEBOUNCE_SCROLL_DELAY = 100;

/**
 * Need for remove animation on integration test
 */
const ANIMATION_TYPE = window.IS_INTEGRATION_TEST_ENVIRONMENT ? null : 'spring';

/**
 * In pixels
 */
const SCROLLED_THRESHOLD = 30;

const ANIMATION_SPEED = getAnimationTime();

/**
 * Motion variant for animated widget
 */
const variants = {
  initial: {
    opacity: 0.5,
    x: '100%',
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0.5,
    pointerEvents: 'none', // We should avoid unexpected clicks on closing notification
    x: '-100%',
  },
} as const;

type AnimatedSlideableFloatingSystemNotificationsProps = {
  children: ReactElement[];
};

/**
 * Notifications are "floating" over content and animates like sliding on sides
 */
const AnimatedSlideableFloatingSystemNotifications: FC<
  AnimatedSlideableFloatingSystemNotificationsProps
> = ({children}) => {
  const {data: headerPosition} = useThemeFeature<HeaderPositionAppearance>(
    'header',
    'position',
  );
  const {data: floatingSubheader} = useThemeFeature<boolean>(
    'header',
    'floatingSubheader',
  );

  const {data: sticked, loading: stickedLoading} = useThemeFeature<boolean>(
    'header',
    'stickedSubheader',
  );

  const [scrolled, setScrolled] = useState(Boolean(window.pageYOffset));
  const updaterHeaderAndToolbarVariables =
    useHeaderAndToolbarVariablesUpdater();

  /**
   * Used to correct notifications position when we scroll
   * in case when toolbar isn't sticked.
   */
  useEffect(() => {
    const handler = debounce(() => {
      if (window.pageYOffset > SCROLLED_THRESHOLD) {
        !scrolled && setScrolled(true);
      } else {
        scrolled && setScrolled(false);
      }
    }, DEBOUNCE_SCROLL_DELAY);

    if (!stickedLoading && !sticked) {
      window.addEventListener('scroll', handler);
    }

    return () => {
      if (!stickedLoading && !sticked) {
        window.removeEventListener('scroll', handler);
      }
    };
  }, [scrolled, sticked, stickedLoading]);

  return (
    <div
      className={cn(
        css.container,
        css[`${headerPosition}Header`],
        floatingSubheader && css.floatingSubheader,
        scrolled && !sticked && css.scrolled,
      )}
    >
      <AnimatePresence>
        {children.map((child, index) => {
          // @see SystemNotifications.js (it's reversed list)
          const isFirst = index === children.length - 1;

          return (
            <motion.div
              className={css.notification}
              key={child.key}
              initial={variants.initial}
              // Since notification in DOM, but we should display only one
              animate={isFirst ? variants.animate : variants.initial}
              exit={variants.exit}
              onAnimationComplete={updaterHeaderAndToolbarVariables}
              transition={{
                type: ANIMATION_TYPE,
                damping: 100,
                stiffness: 1000,
                duration: ANIMATION_SPEED,
              }}
            >
              {child}
            </motion.div>
          );
        })}
      </AnimatePresence>
    </div>
  );
};

export default AnimatedSlideableFloatingSystemNotifications;
