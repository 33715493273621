import WEB_2_APP_SITE_QUERY from '../graphql/queries/web2AppSite.gql';

/**
 * Paid app funnel access rule.
 */
export default {
  errorCode: 'PAID_APP_FUNNEL',
  query: WEB_2_APP_SITE_QUERY,
  resolver: (data) => !data.site.isWeb2AppSite,
};
