import {useLocation} from 'react-router-dom';

import BASE_ROUTES from '@core/application/constants/routes';

/**
 * return flag if we must show balance on MOB on specific route
 * @returns {Boolean}
 */
const useShowCoinsBalance = () => {
  const {pathname} = useLocation();

  return pathname === BASE_ROUTES.PROFILE_MENU;
};

export default useShowCoinsBalance;
