import React from 'react';
import PropTypes from 'prop-types';

import createTranslationsMap from '@core/translations/createTranslationsMap';

import {SpacingSize} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {ButtonPrimary, Button} from '@phoenix/ui';
import useSkipBlindClick from '@phoenix/remarketing/popunder/utils/useSkipBlindClick';

import POPUP_VIEW from '../constants/popupView';

const POPUP_TYPES = {
  [POPUP_VIEW.SEND_FIRST_MESSAGE]: createTranslationsMap((t) => ({
    title: t('webPushNotification', 'text.signUpToBeNotifiedReplyArrives'),
    skip: t('webPushNotification', 'button.notNow'),
    accept: t('webPushNotification', 'button.signUp'),
    icon: 'chat',
  })),
  [POPUP_VIEW.ADD_FIRST_FAVORITE]: createTranslationsMap((t) => ({
    title: t('webPushNotification', 'text.subscribeToKeepTrackOfFavs'),
    skip: t('webPushNotification', 'button.deny'),
    accept: t('webPushNotification', 'button.allow'),
    icon: 'favorite',
  })),
  [POPUP_VIEW.ADD_LIKE]: createTranslationsMap((t) => ({
    title: t('webPushNotification', 'text.dontMissLikes'),
    skip: t('webPushNotification', 'button.deny'),
    accept: t('webPushNotification', 'button.subscribe'),
    icon: 'heart',
  })),
  [POPUP_VIEW.OPEN_PROFILE]: createTranslationsMap((t) => ({
    title: t('webPushNotification', 'text.signUpToBeAwareWhoViewed'),
    skip: t('webPushNotification', 'button.deny'),
    accept: t('webPushNotification', 'button.okIWill'),
    icon: 'search',
  })),
  [POPUP_VIEW.FREE_MESSAGE_FINISHED]: createTranslationsMap((t) => ({
    title: t('webPushNotification', 'text.subscribeToNeverMissReply'),
    skip: t('webPushNotification', 'button.cancel'),
    accept: t('webPushNotification', 'button.signUp'),
    icon: 'mail',
  })),
  [POPUP_VIEW.CUSTOM_DEFAULT]: createTranslationsMap((t) => ({
    title: t('webPushNotification', 'text.signUpForPushesToTrackActivity'),
    skip: t('webPushNotification', 'button.laterPlease'),
    accept: t('webPushNotification', 'button.signMeUp'),
    icon: 'activity',
  })),
};

const NativeLikePopupLayout = ({popupType, onAcceptClick, onDenyClick}) => {
  useSkipBlindClick();

  const {title, skip, accept, icon} = POPUP_TYPES[popupType];

  return (
    <PopupNotice
      adaptive
      size={SpacingSize.LARGE}
      icon={icon}
      title={title}
      actions={[
        <Button onClick={onDenyClick} trackingName="skip" fullWidth>
          {skip}
        </Button>,
        <ButtonPrimary onClick={onAcceptClick} trackingName="accept" fullWidth>
          {accept}
        </ButtonPrimary>,
      ]}
      actionsVertical={false}
      data-test="webPushNativeLikePopup"
    />
  );
};

NativeLikePopupLayout.propTypes /* remove-proptypes */ = {
  popupType: PropTypes.oneOf(Object.values(POPUP_VIEW)).isRequired,
  onAcceptClick: PropTypes.func.isRequired,
  onDenyClick: PropTypes.func.isRequired,
};

export default NativeLikePopupLayout;
