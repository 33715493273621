import createTranslationsMap from '@core/translations/createTranslationsMap';

import {
  CHAT,
  DEFAULT,
  PAID_FUNNEL_PAGE,
  PAYMENT_PAGE,
  USER,
  VIDEO_CALL,
} from './motivationTypes';

const HEADER_TEXT_TRANSLATION = createTranslationsMap((translate) => ({
  [USER]: translate('inAppMotivation', 'text.user'),
  [CHAT]: translate('inAppMotivation', 'text.chat'),
  [PAYMENT_PAGE]: translate('inAppMotivation', 'text.payment_page'),
  [PAID_FUNNEL_PAGE]: translate('inAppMotivation', 'text.paid_funnel'),
  [DEFAULT]: translate('inAppMotivation', 'text.troubles_with_browser'),
  [VIDEO_CALL]: translate('inAppMotivation', 'text.videoCall'),
}));

export default HEADER_TEXT_TRANSLATION;
