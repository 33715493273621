import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import PopupPriority from '@core/popup/constants/PopupPriority';

import NativeLikePopupLayout from '../components/NativeLikePopupLayout';

/**
 * Open custom popup that looks like native
 * triggered on actions: [WS_ACTIONS, ROUTE]
 * @param {Function} onAccept
 * @param {Function} onClose
 * @param {Function} onDeny
 * @param {String} popupType
 */
export default function openNativeLikePopup({
  onAccept,
  onClose,
  onDeny,
  popupType,
}) {
  PopupService.openPopup(
    <NativeLikePopupLayout
      popupType={popupType}
      onAcceptClick={() => {
        PopupService.closePopup();
        onAccept();
      }}
      onDenyClick={() => {
        PopupService.closePopup();
        onDeny();
      }}
    />,
    {
      onClose: (sourceEventType) => {
        if (
          [
            PopupSourceEvent.CLOSE_BUTTON_CLICK,
            PopupSourceEvent.BY_ESCAPE,
          ].includes(sourceEventType) &&
          onClose
        ) {
          onClose();
        }
      },
      small: true,
      trackingName: 'webPushNativeLike',
      autoLoadTracking: true,
      trackingConceptId: popupType,
      priority: PopupPriority.LOW,
    },
  );
}
