import {useCallback, useState} from 'react';

import useOneClick from '@core/payment/forms/card/utils/useOneClick';
import getProcessPaymentFlow, {
  PaymentFlowStatus,
} from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import usePaymentPackages from '@core/payment/widgets/package/utils/usePaymentPackages';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import PAYMENT_ONE_CLICK_FLOWS from '@core/payment/common/constants/paymentOneclickFlows';

import getBuyCoinsPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getBuyCoinsPopupFlow';

import goToCoinsPP from '../../utils/goToCoinsPP';

const useBuyOffer = ({redirectVia, returnPath} = {}) => {
  const [disabled, setDisabled] = useState(false);
  const {via, action} = usePaymentParams();

  const {error, loading, activePackage} = usePaymentPackages({
    variables: {action, via},
    needResetCache: true,
  });

  const {
    loading: oneClickLoading,
    isOneClickAllowed,
    oneClickFlow,
    oneClickCardNumber,
  } = useOneClick();

  const handleClickGotoPP = useCallback(
    () => goToCoinsPP(redirectVia || via, returnPath),
    [via, redirectVia, returnPath],
  );

  const handlePay = useCallback(() => {
    setDisabled(true);

    getProcessPaymentFlow(getBuyCoinsPopupFlow())({
      form: {
        hidePaymentForm: 1,
      },
      activePackage,
      action,
      via,
    }).then((paymentAnswer) => {
      if (paymentAnswer === PaymentFlowStatus.ABORTED) {
        setDisabled(false);
      }
    });
  }, [activePackage, via, action]);

  return {
    loading: loading || oneClickLoading || !activePackage,
    handlePay,
    handleClickGotoPP,
    error,
    disabled,
    isOneClickAllowed:
      isOneClickAllowed && oneClickFlow === PAYMENT_ONE_CLICK_FLOWS.SIMPLE,
    oneClickCardNumber,
    activePackage,
  };
};

export default useBuyOffer;
