import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {Loader} from '@phoenix/ui';

import useDeferredPageAvailable from './useDeferredPageAvailable';

/**
 * 10 seconds wait and render background
 *
 * @type {Number}
 */
const DEFERRED_TIMEOUT = 10000;

/**
 * The component replaces the basic route component for a popup with a background (for example, a search) by loader
 * in order to give all the resources for rendering the popup (to speed up the display of the popup)
 *
 * @constructor DeferredPage
 */
const DeferredPage = ({page: Page, available = false, ...props}) => {
  const {deferredPageAvailable, setDeferredPageAvailable} =
    useDeferredPageAvailable();

  useEffect(() => {
    let timeout;
    if (!deferredPageAvailable) {
      if (!available) {
        timeout = setTimeout(
          () => setDeferredPageAvailable(true),
          DEFERRED_TIMEOUT,
        );
      } else {
        setDeferredPageAvailable(true);
      }
    }

    return () => clearTimeout(timeout);
  }, [deferredPageAvailable, setDeferredPageAvailable, available]);

  if (deferredPageAvailable) {
    return <Page {...props} />;
  }

  return <Loader fullscreen data-test="Loader" />;
};

DeferredPage.propTypes /* remove-proptypes */ = {
  page: PropTypes.func.isRequired,
  /**
   * For some cases, we don't need to delay rendering and need
   * to allow it on first render. For example on search page.
   */
  available: PropTypes.bool,
};

export default DeferredPage;
