import once from 'lodash/once';
import {from} from 'rxjs';
import {filter} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import getPaymentStatus from '@core/payment/common/utils/getPaymentStatus';
import {readFromSessionStorage} from '@core/utils/storage';

import {RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED} from '../../constants/storageKeys';
import updateGlobalFreeMessagesEndedRecipient from '../../common/utils/updateGlobalFreeMessagesEndedRecipient';

let subscription = null;

export const unsubscribeResetGlobalFreeMessagesActiveRecipientListener = () => {
  if (subscription) {
    updateGlobalFreeMessagesEndedRecipient({userId: null});
    subscription.unsubscribe();
  }
};

const getRecipientIdWhereFreeMessagesEnded = () => {
  return readFromSessionStorage(RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED) || null;
};

const startResetGlobalFreeMessagesActiveRecipientListener = once((client) => {
  getPaymentStatus().then((isPaid) => {
    if (isPaid) {
      return;
    }

    subscription = from(client.watchQuery({query: ROUTE_QUERY}))
      .pipe(
        filter(
          ({
            data: {
              route: {previous, current},
            },
          }) => previous && current !== previous,
        ),
      )
      .subscribe(() => {
        if (getRecipientIdWhereFreeMessagesEnded()) {
          unsubscribeResetGlobalFreeMessagesActiveRecipientListener();
        }
      });
  });
});

export default startResetGlobalFreeMessagesActiveRecipientListener;
