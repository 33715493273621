import React from 'react';

import {getClientInstance} from '@core/graphql/client';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';
import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import {SUBSCRIBE_POPUP} from '../constants/vias';
import CoinsTelegramBotSubscriptionPopup from '../components/CoinsTelegramBotSubscriptionPopup';
import getTelegramBotInviteUrl from './getTelegramBotInviteUrl';

const openTelegramBotSubscriptionPopup = (userId, closePopup) => {
  let currentUserId = userId;

  if (!userId) {
    currentUserId = getClientInstance().readQuery({
      query: CURRENT_USER_ID_QUERY,
    })?.myUser?.profile?.id;
  }

  const closePopupHandler = () => {
    closePopup && closePopup();
    PopupService.closePopup();
  };

  getTelegramBotInviteUrl(SUBSCRIBE_POPUP).then((url) => {
    if (url) {
      PopupService.openPopup(
        <CoinsTelegramBotSubscriptionPopup
          url={url}
          closePopup={closePopupHandler}
          userId={currentUserId}
        />,
        {
          trackingName: 'coinsTelegramBot',
          autoLoadTracking: true,
          onCloseClick: closePopup,
          priority: PopupPriority.LOW,
        },
      );
    }
  });
};

export default openTelegramBotSubscriptionPopup;
