/**
 * A logic which is used for subscription
 * @const
 */
const SUBSCRIBE_LOGIC = {
  NATIVE: 'native',
  DATEINFORM: 'dateinform',
  DISABLED: 'disabled',
};

export default SUBSCRIBE_LOGIC;
