import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@core/utils/storage';

import {LIKE_GALLERY_USERS_KEY} from '../constants/storageKeys';

/**
 * @typedef UserIdsToRestore
 * @property {String} [current] - last seen user id
 * @property {String} [previous] - previous user id
 */

/**
 * Is used for ability to restore needed user
 * after returning from payment page
 * after trying to go back to previous user:
 *   * for non-paid - restore last seen user
 *   * for paid - restore previous user
 * @param {UserIdsToRestore|null} ids
 */
export const setLikeGalleryUserIds = (ids) => {
  if (!window.IS_INTEGRATION_TEST_ENVIRONMENT) {
    writeToSessionStorage(LIKE_GALLERY_USERS_KEY, ids);
  }
};

/**
 * Get user ids stored before going to payment page from 'back to previous user' button.
 * @return {UserIdsToRestore}
 */
export const getLikeGalleryUserIds = () => {
  const ids = readFromSessionStorage(LIKE_GALLERY_USERS_KEY);
  return ids || {};
};
