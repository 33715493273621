import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';

import PopupService from '@core/popup/utils/PopupService';
import useStaticPageContentLogic from '@core/staticPages/utils/useStaticPageContentLogic';
import STATIC_PAGES from '@core/staticPages/constants/pages';

import NotFound from '@phoenix/exceptions/components/NotFound';
import openDnsmpiPopup from '@phoenix/dnsmpi/utils/openDnsmpiPopup';

import StaticPageLayout from '../components/StaticPageLayout';

const StaticPageContent = ({
  page: incomingPage,
  'data-test': dataTest,
  isPopup = true,
  siteId,
  scrollToId,
  externalDomain,
  placeholders,
}) => {
  const handleLinkClick = useCallback(
    (innerPopup) => {
      const mappedPage = STATIC_PAGES[innerPopup]
        ? STATIC_PAGES[innerPopup]
        : innerPopup;

      return PopupService.openPopup(
        // eslint-disable-next-line no-use-before-define
        <StaticPageContent
          page={innerPopup}
          data-test={dataTest}
          siteId={siteId}
          externalDomain={externalDomain}
          placeholders={placeholders}
          isPopup
        />,
        // eslint-disable-next-line no-use-before-define
        {groupKey: StaticPageContent, trackingName: mappedPage},
      );
    },
    [externalDomain, siteId, dataTest, placeholders],
  );

  const {error, loading, data} = useStaticPageContentLogic({
    siteId,
    externalDomain,
    incomingPage,
    isPopup,
    handleLinkClick,
    eventsParams: {
      openDnsmpiPopup,
    },
  });

  const handleCloseButtonClick = useCallback(() => {
    PopupService.closePopup();
  }, []);

  let content = data?.staticPage?.content;

  if (!loading) {
    if (!content || error) {
      return <NotFound />;
    }

    forEach(placeholders, (placeholderValue, placeholderKey) => {
      content = content.replaceAll(placeholderKey, placeholderValue);
    });
  }

  return (
    <StaticPageLayout
      onCloseButtonClick={handleCloseButtonClick}
      pageHtml={content}
      loading={loading}
      isPopup={isPopup}
      data-test={dataTest}
      scrollToId={loading ? null : scrollToId}
      isCopyEnabled={data?.staticPage?.isCopyEnabled}
    />
  );
};

StaticPageContent.propTypes /* remove-proptypes */ = {
  page: PropTypes.string.isRequired,
  isPopup: PropTypes.bool,
  'data-test': PropTypes.string,
  siteId: PropTypes.string,
  externalDomain: PropTypes.string,
  scrollToId: PropTypes.string,
  /**
   * Frontend placeholders for content graphql response
   * @see PaymentPageOneStepClearView::__getAdditionalPackagesData
   */
  placeholders: PropTypes.objectOf(PropTypes.any),
};

export default StaticPageContent;
