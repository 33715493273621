import type {FC} from 'react';
import React from 'react';

import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import isSepaPaymentType from '@core/payment/forms/card/utils/isSepaPaymentType';

import PaymentCredentialsPolicy from '../containers/PaymentCredentialsPolicy';
import PaymentSepaCredentialsPolicy from '../containers/PaymentSepaCredentialsPolicy';

const PaymentCredentialsPolicyFactory: FC = () => {
  const {activePackage} = useActivePackage();

  if (!activePackage) {
    return null;
  }

  if (isSepaPaymentType(activePackage.paymentMethod)) {
    return <PaymentSepaCredentialsPolicy />;
  }

  return <PaymentCredentialsPolicy />;
};

export default PaymentCredentialsPolicyFactory;
