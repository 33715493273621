import {useEffect, useCallback, useState} from 'react';

type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
};

/**
 * Can intercept PWA promotion event and promote it later
 */
const usePromotionInterceptor = () => {
  const [isActive, intercept] = useState<boolean>(false);

  const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent>();

  /**
   * Promote PWA installation
   */
  const promote = useCallback(async () => {
    if (promptEvent) {
      console.log('PROMOTE');
      await promptEvent.prompt();
    }
  }, [promptEvent]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    /**
     * Prevent default PWA installation event and cache it
     */
    const onBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      console.log('INTERCEPT');
      event.preventDefault();
      setPromptEvent(event);
    };

    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    };
  }, [isActive]);

  return {intercept, promote};
};

export default usePromotionInterceptor;
