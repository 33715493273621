import {
  ACTIVITY_TYPES,
  ACTIVITY_TYPES as TYPE,
} from '@core/activity/constants/notificationTypes';
import getActivitySubscriptionObservable from '@core/activity/utils/getActivitySubscriptionObservable';
import mergeActivities from '@core/activity/utils/mergeActivities';
import mergeUsers from '@core/activity/utils/mergeUsers';
import UPDATE_ALL_ACTIVITIES_QUERY from '@core/activity/graphql/queries/updateAllActivities.gql';
import {getClientInstance} from '@core/graphql/client';
import {
  TRANSFORMED_LAST_ACTIVITY_TYPES,
  SUBSCRIBE_TYPES,
} from '@core/messenger/constants/activity';
import {Direction} from '@core/types/graphql';

import changeActiveDialogsCompletionUser from '@phoenix/messenger/common/utils/changeActiveDialogsCompletionUser';
import changeLastActivityData from '@phoenix/messenger/common/utils/changeLastActivityData';

import TARGET_USERS_BY_ID_QUERY from '../graphql/queries/targetUsersById.gql';

let subscribed;

/**
 * Change activity data
 */
const subscribeToActivity = async () => {
  if (subscribed) {
    return;
  }
  subscribed = true;

  const client = getClientInstance();
  await client.query({query: UPDATE_ALL_ACTIVITIES_QUERY});
  getActivitySubscriptionObservable(client, TARGET_USERS_BY_ID_QUERY).subscribe(
    ({data: {activity, user}}) => {
      // Private video notifications can arrive only in sticked notifications.
      if (activity.type === TYPE.PRIVATE_VIDEO) {
        return;
      }

      const typeListenActivities = [
        ACTIVITY_TYPES.VIEW,
        ACTIVITY_TYPES.ADD_USER_LIKE,
      ];
      // add to complation active dialogs users when some view or like you
      if (typeListenActivities.includes(activity.type)) {
        changeActiveDialogsCompletionUser(client, activity.userId, true);
      }

      if (SUBSCRIBE_TYPES.includes(activity.type)) {
        changeLastActivityData({
          client,
          userId: activity.userId,
          activityType: TRANSFORMED_LAST_ACTIVITY_TYPES[activity.type],
          direction: Direction.incoming,
        });
      }

      const data = client.readQuery({
        query: UPDATE_ALL_ACTIVITIES_QUERY,
      });

      const updatedData = mergeActivities(data, [activity]);
      updatedData.activityUsers = mergeUsers(user, data.activityUsers);

      if (activity.type === TYPE.MATCH) {
        // Remove like when match happens
        updatedData.allActivities = updatedData.allActivities.filter(
          ({type, userId}) =>
            userId !== activity.userId || type !== TYPE.ADD_USER_LIKE,
        );
      }

      client.writeQuery({
        query: UPDATE_ALL_ACTIVITIES_QUERY,
        data: updatedData,
      });
    },
  );
};

export default subscribeToActivity;
