import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {Button, Row, RowShort, Radio, RadioGroup} from '@phoenix/ui';
import {Text, H3} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {
  ButtonType,
  OverlaySaturation,
  OverlayType,
  SpacingSize,
} from '@phoenix/ui/constants';
import PhotoWithWidgets from '@phoenix/user/photo/components/PhotoWithWidgets';

import css from '../styles/InteractiveLikeLayout.css';
import userItem from '../constants/propTypes';

// export for tests
export const getTexts = (isMissed, extra) => {
  if (isMissed) {
    return {
      title: t('extraPopups', 'title.missed'),
      text: t('extraPopups', 'text.sorry'),
    };
  }

  return {
    title: t('extraPopups', 'title.get_off', {'{discountamount}': extra}),
    text: t('extraPopups', 'text.four_girls'),
  };
};

const InteractiveLikeLayout = ({
  users,
  isMissed,
  handleChange,
  handleActionClick,
  checkedValue,
  extra,
}) => {
  const transactions = getTexts(isMissed, extra);
  const isPhone = usePhoneBreakpoint();

  return (
    <PopupFrame
      className={css.wrap}
      data-test="interactiveLikePopupLayout"
      content={
        <div>
          <div className={css.banner}>
            <H3>{transactions.title}</H3>
            <RowShort>
              <Text
                className={css.text}
                type={isMissed ? Text.TYPE.DANGER : Text.TYPE.MUTED}
                data-test="interactiveLikePopupLayoutText"
              >
                {transactions.text}
              </Text>
            </RowShort>
          </div>
          <Row className={css.photosWrap}>
            <RadioGroup
              horizontal
              space={SpacingSize.NONE}
              name="widgets"
              checkedValue={checkedValue}
              onChange={handleChange}
              className={css.photos}
              itemWrapperClassName={css.photoWrap}
              inline
            >
              {users.map((userData) => {
                return (
                  <Radio
                    inverse
                    flexibleWidth
                    value={userData.profile.id}
                    key={userData.profile.id}
                    background={
                      <PhotoWithWidgets
                        overlaySaturation={OverlaySaturation.LITE}
                        overlayType={OverlayType.GRADIENT_FROM_BOTTOM}
                        photo={getPrimaryPhotoData(userData.profile)}
                        online={userData.profile.statuses.isOnline}
                        recentlyOnline={
                          userData.profile.statuses.isRecentlyOnline
                        }
                        userId={userData.profile.id}
                        className={css.photo}
                      />
                    }
                    data-test="interactiveLikePopupLayoutRadio"
                  />
                );
              })}
            </RadioGroup>
          </Row>
        </div>
      }
      actions={
        <Button
          data-test="interactiveLikeAction"
          type={ButtonType.PRIMARY}
          onClick={handleActionClick}
          trackingName="choose"
          fullWidth={isPhone}
          className={css.button}
        >
          {t('extraPopups', 'button.choose')}
        </Button>
      }
    />
  );
};

InteractiveLikeLayout.propTypes /* remove-proptypes */ = {
  users: PropTypes.arrayOf(userItem).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleActionClick: PropTypes.func.isRequired,
  isMissed: PropTypes.bool.isRequired,
  checkedValue: PropTypes.string.isRequired,
  extra: PropTypes.number.isRequired,
};

export default InteractiveLikeLayout;
