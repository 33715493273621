import MediaPreviewAppearance from '@core/theming/constants/features/MediaPreviewAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43652
 */
type MediaPreviewComponent = any;

const resolver: ModuleResolver<
  MediaPreviewAppearance,
  MediaPreviewComponent
> = (value) => {
  switch (value) {
    case MediaPreviewAppearance.BLURRED_WITH_SCATTERED_CONTENT:
      return import(
        /* webpackChunkName: 'photoUpgradeBlurredAndButton' */ '@phoenix/user/constants/mediaPreviewBlurredWithScatteredContent'
      );
    case MediaPreviewAppearance.BLURRED_WITHOUT_BUTTON:
      return import(
        /* webpackChunkName: 'photoUpgradeBlurred' */ '@phoenix/user/constants/mediaPreviewBlurredWithoutButton'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable chat message based on theme.
 * Returns object of composite components that are used for theming.
 */
const useThemedMediaPreview = () =>
  useThemeCompositeFeatureLoader<MediaPreviewAppearance, MediaPreviewComponent>(
    'mediaPreview',
    null,
    resolver,
  );

export default useThemedMediaPreview;
