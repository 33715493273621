import React from 'react';
import PropTypes from 'prop-types';

import {Group, PlaceholderBar} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';

import FooterRow from '../../footer/components/FooterRow';
import useYouWillPayText from '../utils/useYouWillPayText';

const PaymentDetailsLayout = ({
  price,
  packageName,
  mutedText,
  onlyProcessingFeeText,
}) => {
  const {loading, text: youWillPayText} = useYouWillPayText({
    price,
    packageName,
    onlyProcessingFeeText,
  });

  if (loading) {
    return (
      <FooterRow>
        <PlaceholderBar />
      </FooterRow>
    );
  }

  return youWillPayText ? (
    <FooterRow>
      <Group inline space={SpacingSize.SHORTEST} data-test="paymentDetails">
        <Text type={mutedText ? Text.TYPE.MUTED : Text.TYPE.NORMAL} small>
          {youWillPayText}
        </Text>
      </Group>
    </FooterRow>
  ) : null;
};

PaymentDetailsLayout.propTypes /* remove-proptypes */ = {
  onlyProcessingFeeText: PropTypes.bool,
  price: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
  mutedText: PropTypes.bool,
};

PaymentDetailsLayout.defaultTypes = {
  onlyProcessingFeeText: false,
};

export default PaymentDetailsLayout;
