export const FIELD_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  CITY: 'city',
  COUNTRY: 'country',
  ZIP: 'zip',
  ALLOWED_INFORMATION: 'allowedInformation',
  ALLOWED_PROFILING: 'allowedProfiling',
  ALLOWED_ADVERTISING: 'allowedAdvertising',
  CAPTCHA: 'recaptchaResponse',
};
