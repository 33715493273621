import memoize from 'lodash/memoize';
import {makeVar} from '@apollo/client';

import isSmallTabletViewport from '@core/responsive/isSmallTabletViewport';

export const getBannerKey = (placementId) => {
  return `${placementId}_desktop_${String(isSmallTabletViewport())}`;
};

/**
 * Util for create reactive variable dynamically.
 * @type {(function(): ReactiveVar<null>) & MemoizedFunction}
 */
const getBannerSizeVar = memoize(() => makeVar(null));

export default getBannerSizeVar;
