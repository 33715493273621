import resetPaymentPackagesCache from '@core/payment/common/utils/resetPaymentPackagesCache';
import resetPaymentDiscountCache from '@core/payment/common/utils/resetPaymentDiscountCache';

import EXTRA_DISCOUNT_EXPIRE_QUERY from './graphql/queries/extraDiscountExpire.gql';

/**
 * Reset discount to default value when extra discount timer ends via re-fetching actual data from the server.
 * @param client - Apollo Client
 * @returns {void}
 */
const expireAction = ({client}) => {
  client.query({
    query: EXTRA_DISCOUNT_EXPIRE_QUERY,
    fetchPolicy: 'network-only',
  });
  resetPaymentPackagesCache();
  resetPaymentDiscountCache();
};

export default expireAction;
