import get from 'lodash/get';

import logger from '@core/logger';

import {getLikeGalleryUpdateQuery} from './likeGalleryUpdateHelper';

/**
 * Common util function to update like gallery list
 * @param {Function} updater
 */
const updateLikeGallery = async (updater) => {
  const updateQuery = getLikeGalleryUpdateQuery();
  if (!updater || !updateQuery) {
    return;
  }
  try {
    updateQuery((prevResult) => {
      const gallery = get(prevResult, 'likeGallery.gallery', {});
      const newGallery = updater(gallery);
      if (!newGallery) {
        return prevResult;
      }

      return {
        ...prevResult,
        likeGallery: {
          ...prevResult.likeGallery,
          gallery: {
            ...prevResult.likeGallery.gallery,
            ...newGallery,
          },
        },
      };
    });
  } catch (e) {
    logger.sendWarning(`[GraphQL] update like gallery user list ${e}`);
  }
};

export default updateLikeGallery;
