/**
 * Type of colorized icon wih coins
 * @type {string}
 */
const ONE = 'one';
// need for normal a view the one coin icon with regard to the other types of icons
const ONE_BIG = 'one_big';
const FEW = 'few';
const MANY = 'many';
const HUGE = 'huge';

export default {
  ONE,
  ONE_BIG,
  FEW,
  MANY,
  HUGE,
};
