import PopupService from '@core/popup/utils/PopupService';
import getHistory from '@core/application/utils/getHistory';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import ROUTES from '@core/application/constants/routes';
import trackPaymentClick from '@core/payment/common/utils/trackPaymentClick';
import {getPageViewTime} from '@core/payment/payProcess/utils/pageViewTime';
import makePay from '@core/payment/payProcess/paymentFlow/methods/makePay';
import {
  Action,
  Method,
  PayButtonEnum,
  PaymentFormEnum,
  ViaEnum,
} from '@core/types';

import openFreeFeaturePopup from '@phoenix/freeFeaturePopup';
import FreeFeaturesNames from '@phoenix/freeFeaturePopup/constants/freeFeatureNames';
import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';
import {MODEL_TYPES} from '@phoenix/microfeatures/common/constants/modelTypes';
import openMicrofeaturesSuccessPopup from '@phoenix/microfeatures/popups/utils/openMicrofeaturesSuccessPopup';
import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';
import getMicrofeatureData from '@phoenix/microfeatures/common/utils/getMicrofeatureData';

import MICROFEATURES_AVAILABILITY_DATA_QUERY from '../../microfeatures/common/graphql/queries/microfeaturesAvailabilityData.gql';
import MICROFEATURES_LIST_QUERY from '../../microfeatures/common/graphql/queries/microfeaturesList.gql';
import UPSELL_POPUP_STATISTIC_QUERY from '../graphql/queries/upsellPopupStatistic.gql';

/**
 * Open popup with statistic of usage free micro feature.
 * If there is no statistic by any reason, don't open popup.
 * @param {ApolloClient} client
 * @param {string} type
 * @return {Promise<void>}
 */
const openUpsellPopup = async (client, type) => {
  let isPaymentProcessing = false;
  let name;
  /** Feature name need for get statistics */
  const featureName = type.substring(0, type.indexOf('PermissionEnd'));

  switch (featureName) {
    default:
      return;
    case MICROFEATURES.PRIORITY_MAN: {
      name = FreeFeaturesNames.PRIORITY_MAN_UPSELL;
      break;
    }
    case MICROFEATURES.SUPER_BOOST: {
      name = FreeFeaturesNames.BOOST_UPSELL;
      break;
    }
    case MICROFEATURES.SEX_ALARM: {
      name = FreeFeaturesNames.SEX_ALARM_UPSELL;
      break;
    }
  }

  const {
    data: {
      extraPopups: {upsellPopupStatistic},
      myUser,
    },
  } = await client.query({
    query: UPSELL_POPUP_STATISTIC_QUERY,
    variables: {featureName},
  });

  if (!upsellPopupStatistic.enabled) return;

  const {
    data: {
      microFeatures: {
        microFeaturesConfiguration: {modelType},
      },
    },
  } = await client.query({
    query: MICROFEATURES_AVAILABILITY_DATA_QUERY,
  });

  const {
    data: {microFeatures: microfeaturesList},
  } = await client.query({
    query: MICROFEATURES_LIST_QUERY,
    variables: {
      isCreditModel: modelType === MODEL_TYPES.CREDIT,
    },
  });

  const {stockId} = getMicrofeatureData(
    modelType,
    microfeaturesList,
    featureName,
  );

  const trackClick = (isButtonActive = true) => {
    trackPaymentClick({
      action: Action.microFeatures,
      isFormHidden: true,
      isFrontendValidationPassed: true,
      isOneClickShown: false,
      isButtonActive,
      payButton: PayButtonEnum.default,
      paymentForm: PaymentFormEnum.CreditCardPaymentForm,
      timeInterval: getPageViewTime(),
    });
  };

  const handleActionClick = async () => {
    if (isPaymentProcessing) {
      trackClick(false);
      return;
    }

    isPaymentProcessing = true;
    trackClick();

    // TODO[BB_removed]: Think about pay through 'getProcessPaymentFlow', after delete Backbone pay
    const result = await makePay({
      action: Action.microFeatures,
      via: ViaEnum.microFeatures,
      prevVia: featureName,
      method: Method.card,
      payFromPopup: true,
      stockId,
      hidePaymentForm: true,
      isInstantRepeat: 0,
    });

    if (result.status) {
      PopupService.closePopup();
      openMicrofeaturesSuccessPopup({featureName});
      await updateCachesAfterPayment();
    } else {
      getHistory().push(
        generatePayUrl({
          stage: Action.microFeatures,
          urlParams: {
            prevVia: result.failPageLogic ? featureName : null,
            via: result.failPageLogic ? result.failPageLogic : featureName,
            viaMethod: Method.card,
            packageId: stockId,
            stage: Action.credit,
            ...(featureName === MICROFEATURES.TELEGRAM_CONVERSATION
              ? {
                  returnPath: ROUTES.SEARCH,
                  skipStepOnBack: true,
                }
              : null),
          },
        }),
      );
    }
  };

  openFreeFeaturePopup({
    name,
    onActionClick: handleActionClick,
    options: {
      ...upsellPopupStatistic,
      gender: myUser.profile.gender,
    },
  });
};

export default openUpsellPopup;
