import usePaymentTemplate from '@core/payment/common/utils/usePaymentTemplate';
import useIsMobFirstStage from '@core/payment/common/utils/useIsMobFirstStage';

import {
  ONE_STEP_WITH_POLICY_AGREEMENT,
  TWO_STEP_WITH_POLICY_AGREEMENT,
} from '../../common/constants/templateType';

const useWithPolicyAgreement = (): boolean => {
  const {loading, template} = usePaymentTemplate();
  const isMobFirstStage = useIsMobFirstStage();

  if (loading) {
    return false;
  }

  return (
    (isMobFirstStage && template === ONE_STEP_WITH_POLICY_AGREEMENT) ||
    (!isMobFirstStage && template === TWO_STEP_WITH_POLICY_AGREEMENT)
  );
};

export default useWithPolicyAgreement;
