import type {FC} from 'react';
import React, {useMemo} from 'react';

import RecipientsAppearance from '@core/theming/constants/features/RecipientsAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43633
 */
export type ThemedMessengerPageProps = {
  [key: string]: any;
};

export const resolver: ModuleResolver<
  RecipientsAppearance,
  ModuleComponent<ThemedMessengerPageProps>
> = (value) => {
  switch (value) {
    case RecipientsAppearance.WITH_TABS_OR_FILTER:
      return import(
        /* webpackChunkName: 'messengerWithFilter' */ '@phoenix/messenger/common/components/MessengerLayout'
      );
    case RecipientsAppearance.WITH_CAROUSEL_AND_TABS:
      return import(
        /* webpackChunkName: 'messengerWithCarousel' */ '@phoenix/messenger/common/components/MessengerWithCarousel'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable messenger page based on 'messenger -> recipients' customization.
 * If customization of messenger page will more deeper, take out separately in customization feature.
 */
const ThemedMessengerPage: FC<ThemedMessengerPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedMessengerPageProps,
        RecipientsAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="messenger"
      param="recipients"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedMessengerPage;
