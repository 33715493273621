import type {FC} from 'react';
import React, {useMemo} from 'react';

import SearchPageAppearance from '@core/theming/constants/features/SearchPageAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-42412
 */
type ThemedSearchLivecamPageProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  SearchPageAppearance,
  ModuleComponent<ThemedSearchLivecamPageProps>
> = (value) => {
  switch (value) {
    case SearchPageAppearance.ORDINARY:
    case SearchPageAppearance.ORDINARY_WITH_PAYMENT_BUTTON:
      return import(
        /* webpackChunkName: 'searchLivecamPageOrdinary' */ '@phoenix/search/page/containers/SearchLivecamPage'
      );
    case SearchPageAppearance.FULLSCREEN_SLIDER:
      return import(
        /* webpackChunkName: 'searchLivecamPageSlider' */ '@phoenix/search/page/containers/SearchLivecamPageFullscreenSlider'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable search livecam page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedSearchLivecamPage: FC<ThemedSearchLivecamPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedSearchLivecamPageProps,
        SearchPageAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="search"
      param="page"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedSearchLivecamPage;
