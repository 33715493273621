import once from 'lodash/once';

import {Direction} from '@core/types/graphql';
import AdminMessageType from '@core/messenger/messages/constants/AdminMessageType';
import getReconnectAfterResumeObservable from '@core/websocket/utils/getReconnectAfterResumeObservable';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import logger from '@core/logger';
import isInRouteList from '@core/utils/routing/isInRouteList';
import MessageType from '@core/messenger/common/constants/messageType';
import MESSENGER_QUERY from '@core/messenger/common/graphql/queries/messenger.gql';
import updateRecipientCache from '@core/messenger/common/utils/updateRecipientCache';
import deleteMessageCache from '@core/messenger/common/utils/deleteMessageCache';
import getRecipientById from '@core/messenger/common/utils/getRecipientById';
import updateVideoCache from '@core/messenger/common/utils/updateVideoCache';
import INTERACTION_READ_MESSAGE_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/interactionReadMessage.gql';
import INCOMING_MESSAGE_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/interactionMessage.gql';
import INTERACTION_APPROVE_PHOTO_IN_MESSENGER_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/interactionApprovePhotoInMessenger.gql';
import INTERACTION_USER_ACTION_PERMIT_CHANGED_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/interactionUserActionPermitChanged.gql';
import MESSAGE_PHOTO_FRAGMENT from '@core/messenger/common/graphql/fragments/messagePhoto.gql';
import APPROVE_VIDEO_IN_MESSENGER_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/approveVideoInMessenger.gql';
import MessengerAutoStarter from '@core/messenger/common/utils/MessengerAutoStarter';
import INTERACTION_MEDIA_FILTERED_BY_CONTENT_LEVEL_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/mediaFilteredByContentLevel.gql';
import INTERACTION_HIDE_MESSAGE_BY_DELETE_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/hideMessageByDelete.gql';
import DELETE_MESSAGE_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/deleteMessage.gql';
import INTERACTION_RESENT_MESSAGES_SUBSCRIPTION from '@core/messenger/common/graphql/subscriptions/interactionResentMessages.gql';
import {unmarkRecipientAsDeleted} from '@core/messenger/header/utils/deleteChat';
import updateMessageDataInCache from '@core/messenger/common/utils/updateMessageDataInCache';

import GALLERY_UNSEEN_PHOTOS_COUNT_SUBSCRIPTION from '@phoenix/messenger/photoGallery/graphql/subscriptions/galleryUnseenPhotosCount.gql';
import updateTelegramBotRewardTask from '@phoenix/telegramBot/utils/updateTelegramBotRewardTask';
import refetchPhotoGallery from '@phoenix/messenger/photoGallery/utils/refetchPhotoGallery';
import refetchMessengerChatPreload from '@phoenix/messenger/common/utils/refetchMessengerChatPreload';
import openMessengerWith from '@phoenix/messenger/common/utils/openMessengerWith';

import openInappropriateContentSendPopup from '../../messages/utils/openInappropriateContentSendPopup';
import PersonalIdentityReadService from './PersonalIdentityReadService';
import DISABLED_ROUTES from '../constants/disabledRoutes';
import UpgradeReadService from './UpgradeReadService';
import isContact from './isContact';

const OBSERVABLES = {};

export const getInteractionResentMessagesSubscription = (client) => {
  OBSERVABLES.INTERACTION_RESENT_MESSAGES =
    OBSERVABLES.INTERACTION_RESENT_MESSAGES ||
    client.subscribe({
      query: INTERACTION_RESENT_MESSAGES_SUBSCRIPTION,
    });
  return OBSERVABLES.INTERACTION_RESENT_MESSAGES;
};

/**
 * @param client
 * @param recipientId
 * @return {Promise<boolean>}
 */
const getNewRecipientData = async ({client, recipientId}) => {
  if (!isContact(client, recipientId)) {
    try {
      /**
       * New Message from new (or deleted) user which not in recipients {@see MessengerQuery}:
       *   Just request sender info and add to contacts. All another data we get from the server (if needed).
       */
      await getRecipientById(client, recipientId, false);
      return true;
    } catch (error) {
      logger.sendWarning(error);
    }
  }

  return false;
};

const INCOMING_MESSAGES_CACHE = {};

/**
 * This method need to run test in isolated scope
 * @see MessengerData.test.js
 * @param client
 */
const startMessengerListeners = once(async (client) => {
  await client.query({
    query: MESSENGER_QUERY,
    /**
     * This data may be outdated because of the cache {@see CachePersistManager}
     * if some messages came when we were on PP.
     * Invalidation is not used to display messenger faster.
     * Re-fetch here to load actual contacts and counts.
     */
    fetchPolicy: 'network-only',
  });

  getReconnectAfterResumeObservable().subscribe(() => {
    client.cache.evict({id: 'ROOT_QUERY', fieldName: 'messenger'});
    invalidateCacheByTypename(client, 'Recipient', 'messages');
  });

  const messengerAutoStarter = new MessengerAutoStarter(true);

  // Init remove recipients logic for free user
  UpgradeReadService.getInstance(client);
  PersonalIdentityReadService.getInstance(client);

  // We can't do these updates with subscribeToMore in query above because it hasn't 'messages' field.
  client
    .subscribe({query: INCOMING_MESSAGE_SUBSCRIPTION})
    .subscribe(async ({data: {message}}) => {
      unmarkRecipientAsDeleted(message.senderId);

      /**
       * Cache all incoming messages for calculating correct unread messages count
       */
      INCOMING_MESSAGES_CACHE[message.senderId] ||= [];
      INCOMING_MESSAGES_CACHE[message.senderId].push(message);

      const isNewRecipientLoaded = await getNewRecipientData({
        client,
        recipientId: message.senderId,
      });

      /**
       * Open messenger only if we didn't have conversation with this user
       * Open mini messenger only once in session
       * @param {String} recipientId - recipient model
       */
      isNewRecipientLoaded &&
        messengerAutoStarter.tryStart(message.senderId, openMessengerWith);

      // Increment 'unreadMessageCount', update 'lastMessage', insert new message to 'messages'.
      updateRecipientCache(message.senderId, (recipient) => {
        if (
          // getRecipientById called above is getting data from server, so do nothing.
          !recipient ||
          // This message has been already loaded from server, so skip it.
          (recipient.messages &&
            recipient.messages.some(({id}) => id === message.id))
        ) {
          return null;
        }

        const hasMessages = Boolean(recipient.messages);
        const needToUpdateLastMessage = message.id !== recipient.lastMessage.id;

        // Has no messages history and lastMessage is already updated
        if (!hasMessages && !needToUpdateLastMessage) {
          return null;
        }

        const data = {};

        /**
         * Update the parameter to hide the verification cup for approved messages that come in sockets
         * from scammer but messages that have been approved
         */
        if (
          [MessageType.CHAT, MessageType.PRIVATE_CHAT].includes(message.type)
        ) {
          data.canShowSendFormForApprovedScammer = true;
        }

        /**
         * Update reward for telegram bot
         * @see startCoinsTelegramBotSubscribePopupListener
         */
        if (message.type === AdminMessageType.PROMO_TELEGRAM_BOT_INVITE) {
          updateTelegramBotRewardTask(client);
        }

        /**
         * It's important to update counter and messages together.
         * In other case could be bugs when new message coming to the active conversation:
         *   if counter updated first - we could see counter blink
         *   if messages - we mark them as read immediately and then counter increments
         */
        if (needToUpdateLastMessage) {
          /**
           * All cached messages received by interaction
           */
          const incomingMessages = INCOMING_MESSAGES_CACHE[message.senderId];

          const lastMessageIndex = incomingMessages.findIndex(
            ({id}) => id === recipient.lastMessage.id,
          );

          let unreadMessageCount = recipient.unreadMessageCount || 0;

          if (lastMessageIndex !== -1) {
            const diff = incomingMessages.length - lastMessageIndex - 1;
            unreadMessageCount += diff;
            data.lastMessage = incomingMessages[incomingMessages.length - 1];
          } else if (message.timestamp >= recipient.lastMessage.timestamp) {
            unreadMessageCount++;
            data.lastMessage = message;
          }

          data.unreadMessageCount = unreadMessageCount;
        }

        /**
         * Update messages only if we already have history.
         * In other case - update only counter.
         * We could get messages from server later (if needed).
         */
        if (hasMessages) {
          data.messages = [...recipient.messages, message];
        }

        return data;
      });

      updateMessageDataInCache({
        userId: message.senderId,
        message,
        client,
        canSendNext: message.canSendNext,
        needSendFirst: message.needSendFirst,
        reason: message.reason,
      });
    });

  /**
   * Listen when someone read message I've sent.
   * We get only userId in this notification, so need to mark all messages sent to this user as read.
   */
  client
    .subscribe({query: INTERACTION_READ_MESSAGE_SUBSCRIPTION})
    .subscribe(({data: {fromUserId, messageIds = []}}) => {
      if (!isContact(client, fromUserId)) {
        // Ignore events from users who not in 'recipients'.
        return;
      }

      updateRecipientCache(fromUserId, (recipient) => {
        if (!recipient.messages) {
          // Do nothing if no messages. We could get actual statuses from server if needed.
          return null;
        }

        return {
          messages: recipient.messages.map((message) =>
            message.direction === Direction.outgoing &&
            !message.isRead &&
            messageIds.includes(message.id)
              ? {...message, isRead: true}
              : message,
          ),
        };
      });
    });

  client
    .subscribe({query: INTERACTION_USER_ACTION_PERMIT_CHANGED_SUBSCRIPTION})
    .subscribe(({data}) => refetchMessengerChatPreload(data.userId));

  /**
   * Update message photo data in cache on approve/disapprove
   */
  client
    .subscribe({query: INTERACTION_APPROVE_PHOTO_IN_MESSENGER_SUBSCRIPTION})
    .subscribe(({data}) => {
      const {photo} = data;

      client.writeFragment({
        fragment: MESSAGE_PHOTO_FRAGMENT,
        id: `MessagePhoto:${photo.id}`,
        data: photo,
      });
    });

  /**
   * Update messages after delete message
   */
  client
    .subscribe({query: DELETE_MESSAGE_SUBSCRIPTION})
    .subscribe(async ({data}) => {
      deleteMessageCache({
        recipientId: data.userId,
        messageId: data.messageId,
      });
    });

  getInteractionResentMessagesSubscription(client).subscribe(
    ({data: {recipients}}) =>
      recipients.forEach(({id, unsentMessageCount, messages}) => {
        /**
         * After pay for unsent messages need to update messages for recipients
         */
        updateRecipientCache(id, (recipient) => ({
          // update count of unsent messages
          unsentMessageCount,
          // update unsent messages in recipient
          messages: recipient.messages
            ? recipient.messages.map((cacheRecipientMessage) => {
                const messageUpdateData = messages.find(
                  (message) => message.id === cacheRecipientMessage.id,
                );
                return messageUpdateData
                  ? {
                      ...cacheRecipientMessage,
                      ...messageUpdateData,
                    }
                  : cacheRecipientMessage;
              })
            : recipient.messages,
        }));
      }),
  );

  client
    .subscribe({
      query: INTERACTION_MEDIA_FILTERED_BY_CONTENT_LEVEL_SUBSCRIPTION,
    })
    .subscribe(({data: {messageId, recipientId, isBluredForRecipient}}) => {
      if (
        !isBluredForRecipient ||
        isInRouteList(DISABLED_ROUTES, window.location.pathname) ||
        !messageId
      ) {
        return;
      }
      updateRecipientCache(recipientId, ({messages}) => ({
        messages: messages.map((message) =>
          message.id === messageId ? {...message, needBlur: false} : message,
        ),
      }));

      openInappropriateContentSendPopup({messageId, userId: recipientId});
    });

  client
    .subscribe({
      query: INTERACTION_HIDE_MESSAGE_BY_DELETE_SUBSCRIPTION,
    })
    .subscribe(({data: {messageId, reporterId}}) => {
      updateRecipientCache(reporterId, ({messages}) => ({
        messages: messages.map((message) =>
          message.id === messageId ? {...message, needBlur: true} : message,
        ),
      }));
    });

  /**
   * Update isApprovedFromMessenger and level MessageVideo props in cache
   */
  client
    .subscribe({query: APPROVE_VIDEO_IN_MESSENGER_SUBSCRIPTION})
    .subscribe(({data}) => updateVideoCache(data));

  client
    .subscribe({query: GALLERY_UNSEEN_PHOTOS_COUNT_SUBSCRIPTION})
    .subscribe(() => refetchPhotoGallery());
});

export default startMessengerListeners;
