import type {FC} from 'react';
import React, {useMemo} from 'react';

import HeaderSubToolbarAppearance from '@core/theming/constants/features/HeaderSubToolbarAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43706
 */
type ThemedHeaderProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  HeaderSubToolbarAppearance,
  ModuleComponent<ThemedHeaderProps>
> = (value) => {
  switch (value) {
    case HeaderSubToolbarAppearance.WITH_PAY_BUTTON:
      return import(
        /* webpackChunkName: 'subToolbarWithPay' */ '@phoenix/header/components/HeaderSubToolbarWithPayButton'
      );
    /**
     * We have "none" as an appearance param, we should render "nothing" in this case.
     */
    default:
      return null;
  }
};

/**
 * Appearance-changeable search page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedSubToolbar: FC<ThemedHeaderProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedHeaderProps,
        HeaderSubToolbarAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="header"
      param="subToolbar"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedSubToolbar;
