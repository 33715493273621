import type {FC} from 'react';
import React from 'react';
import compact from 'lodash/compact';

import t from '@core/translations/translate';

import {Menu, MenuItem, MenuItemContent, ButtonFlatCover} from '@phoenix/ui';
import {PopperPlacement} from '@phoenix/ui/constants';

import css from '../../common/styles/MediaItem.css';

type CreateMenuItemParams = {
  icon: string;
  text: string;
  onClick: () => void;
  trackingName: string;
};

const createMenuItem = ({
  icon,
  text,
  onClick,
  trackingName,
}: CreateMenuItemParams) => (
  <MenuItem onClick={onClick} trackingName={trackingName}>
    <MenuItemContent icon={icon}>{text}</MenuItemContent>
  </MenuItem>
);

type MediaMenuProps = {
  isPrimary?: boolean;
  onSetPrimary?: () => void;
  onDeletePhoto?: () => void;
  onDeleteVideo?: () => void;
};

const MediaMenu: FC<MediaMenuProps> = ({
  isPrimary,
  onSetPrimary,
  onDeletePhoto,
  onDeleteVideo,
}) => (
  <div className={css.menu}>
    <Menu
      placement={PopperPlacement.BOTTOM_END}
      content={compact([
        onDeletePhoto &&
          createMenuItem({
            icon: 'trash',
            text: t('myMediaList', 'button.delete_photo'),
            onClick: onDeletePhoto,
            trackingName: 'deletePhoto',
          }),
        onDeleteVideo &&
          createMenuItem({
            icon: 'trash',
            text: t('myMediaList', 'button.delete_video'),
            onClick: onDeleteVideo,
            trackingName: 'deleteeVideo',
          }),
        onSetPrimary &&
          !isPrimary &&
          createMenuItem({
            icon: 'profile-photo',
            text: t('myMediaList', 'button.set_as_primary'),
            onClick: onSetPrimary,
            trackingName: 'setAsPrimary',
          }),
      ])}
      trackingName="editMedia"
    >
      <ButtonFlatCover icon="navigation-more" round inverse />
    </Menu>
  </div>
);

export default MediaMenu;
