import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';

/**
 * Update users viewing(photo/video) permissions in cache
 * @param client - Apollo Client
 */
const resetProfileViewPermissions = (client) => {
  invalidateCacheByTypename(client, 'UserData', 'viewPermissions');
};

export default resetProfileViewPermissions;
