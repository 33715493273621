import React from 'react';
import PropTypes from 'prop-types';

import {CONSENT_AGREEMENT_POPUP_NAME} from '@core/staticPages/constants/pages';

import {Link} from '@phoenix/typography';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

export const PaymentBillingPolicyLink = ({href, text, ...props}) => (
  <Link
    muted
    underline
    small
    href={href}
    trackingName="billingPolicy"
    key="billingPolicyLink"
    onClick={(event) => {
      StaticPagePopupHelper.openPopup(
        StaticPagePopupHelper.PAGES.BILLING_POLICY,
        event,
      );
    }}
    {...props}
  >
    {text}
  </Link>
);

PaymentBillingPolicyLink.propTypes /* remove-proptypes */ = {
  href: PropTypes.string,
  text: PropTypes.node,
};

export const PaymentPrivacyPolicyLink = ({href, text}) => (
  <Link
    muted
    underline
    small
    href={href}
    trackingName="privacyPolicy"
    key="privacyPolicyLink"
    onClick={(event) => {
      StaticPagePopupHelper.openPopup(
        StaticPagePopupHelper.PAGES.PRIVACY_POLICY,
        event,
      );
    }}
  >
    {text}
  </Link>
);

PaymentPrivacyPolicyLink.propTypes /* remove-proptypes */ = {
  href: PropTypes.string,
  text: PropTypes.node,
};

export const PaymentTermsOfUseLink = ({
  isXSaleTerms,
  href,
  text,
  siteId,
  placeholders,
  externalDomain,
  ...props
}) => (
  <Link
    muted
    underline
    small
    href={href}
    trackingName="termsOfUse"
    key="termsOfUseLink"
    onClick={(event) => {
      StaticPagePopupHelper.openPopup(
        isXSaleTerms
          ? StaticPagePopupHelper.PAGES.XSALE_TERMS
          : StaticPagePopupHelper.PAGES.TERMS,
        event,
        {siteId, externalDomain, placeholders},
      );
    }}
    {...props}
  >
    {text}
  </Link>
);

PaymentTermsOfUseLink.propTypes /* remove-proptypes */ = {
  isXSaleTerms: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.node,
  siteId: PropTypes.string,
  placeholders: PropTypes.shape({
    '{PLACEHOLDER_PRICE}': PropTypes.string,
  }),
  externalDomain: PropTypes.string,
};

export const PaymentRefundPolicyLink = ({href, text}) => (
  <Link
    muted
    small
    underline
    href={href}
    trackingName="refundPolicy"
    key="refundPolicyLink"
    onClick={(event) => {
      StaticPagePopupHelper.openPopup(
        StaticPagePopupHelper.PAGES.REFUND_POLICY,
        event,
      );
    }}
  >
    {text}
  </Link>
);

PaymentRefundPolicyLink.propTypes /* remove-proptypes */ = {
  href: PropTypes.string,
  text: PropTypes.node,
};

export const PaymentConsentAgreementLink = ({href, text}) => (
  <Link
    muted
    small
    underline
    href={href}
    trackingName="consentAgreement"
    key="consentAgreementLink"
    onClick={(event) => {
      StaticPagePopupHelper.openPopup(
        StaticPagePopupHelper.PAGES[CONSENT_AGREEMENT_POPUP_NAME],
        event,
      );
    }}
  >
    {text}
  </Link>
);

PaymentConsentAgreementLink.propTypes /* remove-proptypes */ = {
  href: PropTypes.string,
  text: PropTypes.node,
};

export const PaymentCredentialsPolicyLink = ({href, text}) => (
  <Link
    muted
    underline
    small
    href={href}
    trackingName="credentialsPolicy"
    key="credentialsPolicyLink"
    onClick={(event) => {
      StaticPagePopupHelper.openPopup(
        StaticPagePopupHelper.PAGES.CREDENTIALS_POLICY,
        event,
      );
    }}
  >
    {text}
  </Link>
);

PaymentCredentialsPolicyLink.propTypes /* remove-proptypes */ = {
  href: PropTypes.string,
  text: PropTypes.node,
};
