import React from 'react';

import {Loader} from '@phoenix/ui';
import ThemedCurrentUserMenu from '@phoenix/theming/components/ThemedCurrentUserMenu';

/**
 * Don't worry, "ThemedProfileMenu" already splits component in different chunks
 */
const ProfileMenuChunk = (props) => (
  <ThemedCurrentUserMenu placeholder={<Loader fullscreen />} {...props} />
);

export default ProfileMenuChunk;
