import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import COMMON_DISABLED_ROUTES from '@core/messenger/common/constants/disabledRoutes';

/**
 * Routes, where the InappropriateContentSendPopup should not be displayed
 */
const DISABLED_ROUTES = [
  '/microfeatures',
  '/verification',
  '/trusted',
  ...COMMON_DISABLED_ROUTES,
  ...FULLSCREEN_ROUTES,
];

export default DISABLED_ROUTES;
