import includes from 'lodash/includes';
import once from 'lodash/once';
import {combineLatest, from} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isPayUrl from '@core/utils/url/isPayUrl';
import getCurrentUserId from '@core/user/profile/current/utils/getCurrentUserId';

import NEED_OPEN_POPUP_AFTER_COMMUNICATION_MATCH from '@phoenix/gifts/graphql/queries/needOpenPopupAfterCommunicationMatch.gql';
import checkNeedOpenPopupAfterCommunicationMatch from '@phoenix/gifts/utils/checkNeedOpenPopupAfterCommunicationMatch';
import openGiftsPopup from '@phoenix/gifts/utils/openGiftsPopup';
import CHAT_MATCH_SUBSCRIPTION from '@phoenix/messenger/common/graphql/subscriptions/chatMatchSubscription.gql';
import Placements from '@phoenix/gifts/constants/placements';

import TELEGRAM_BOT_REWARD_TASK from '../graphql/queries/telegramBotRewardTask.gql';
import getNeedOpenTelegramBotPopup from './getNeedOpenTelegramBotPopup';
import updateMatchCommunicationUserIds from './updateMatchCommunicationUserIds';
import openTelegramBotSubscriptionPopup from './openTelegramBotSubscriptionPopup';

/**
 * Need to open popup for each of match in the list
 */
const MATCH_COUNT_LIST = [3, 5];
const AVAILABLE_GIFT_POPUP_ROUTE = '/chat/with/';

/**
 * Check if current url is available gift popup
 */
const checkAvailableOpenGiftPopup = (url, matchCommunicationUserId) => {
  return (
    url.startsWith(AVAILABLE_GIFT_POPUP_ROUTE) &&
    url.replace(AVAILABLE_GIFT_POPUP_ROUTE, '') === matchCommunicationUserId
  );
};

/* eslint-disable consistent-return */
const startCoinsTelegramBotSubscribePopupListener = once(async (client) => {
  const currentUserId = await getCurrentUserId();
  const maxMatchCount = MATCH_COUNT_LIST[MATCH_COUNT_LIST.length - 1];
  let needOpenTelegramBotPopup = false;
  let startTelegramSubscriptionEnabled = false;
  let telegramBotPopupOpened = false;
  const result = await getNeedOpenTelegramBotPopup(
    client,
    currentUserId,
    maxMatchCount,
  );
  needOpenTelegramBotPopup = result.needOpenTelegramBotPopup;
  startTelegramSubscriptionEnabled = result.startTelegramSubscriptionEnabled;

  let needOpenPopupAfterCommunicationMatch =
    await checkNeedOpenPopupAfterCommunicationMatch(client);

  // Need refresh needOpenPopupAfterCommunicationMatch after change in cache, ex after payment
  if (!needOpenPopupAfterCommunicationMatch) {
    const subscription$ = client
      .watchQuery({query: NEED_OPEN_POPUP_AFTER_COMMUNICATION_MATCH})
      .subscribe(async () => {
        needOpenPopupAfterCommunicationMatch =
          await checkNeedOpenPopupAfterCommunicationMatch(client);
        if (needOpenPopupAfterCommunicationMatch) {
          subscription$.unsubscribe();
        }
      });
  }

  combineLatest(
    client.subscribe({query: CHAT_MATCH_SUBSCRIPTION}),
    client.watchQuery({query: ROUTE_QUERY}),
  )
    .pipe(
      filter(([, {data: routeData}]) => {
        return !isPayUrl(routeData.route.current);
      }),
      distinctUntilChanged(([prev], [current]) => {
        return (
          prev.data.matchCommunicationUserId ===
          current.data.matchCommunicationUserId
        );
      }),
    )
    .subscribe(([subscriptionResult, {data: routeData}]) => {
      const userId = subscriptionResult?.data?.matchCommunicationUserId;
      const canOpenTelegramBotPopup =
        needOpenTelegramBotPopup && !telegramBotPopupOpened;
      /**
       * In case when popup is open don't set up match to storage to prevent opening next.
       */
      const matchCommunicationUserIds = canOpenTelegramBotPopup
        ? updateMatchCommunicationUserIds(userId, currentUserId)
        : [];
      const matchCommunicationUserIdsLength = matchCommunicationUserIds.length;
      if (
        canOpenTelegramBotPopup &&
        includes(MATCH_COUNT_LIST, matchCommunicationUserIdsLength)
      ) {
        needOpenTelegramBotPopup =
          matchCommunicationUserIdsLength < maxMatchCount;

        /**
         * Prevent to open next popup if preview popup was opened
         */
        telegramBotPopupOpened = true;
        openTelegramBotSubscriptionPopup(currentUserId, () => {
          telegramBotPopupOpened = false;
        });
      } else if (
        needOpenPopupAfterCommunicationMatch &&
        checkAvailableOpenGiftPopup(routeData.route.current, userId)
      ) {
        openGiftsPopup(userId, Placements.MATCH_POPUP);
      }
    });

  from(client.watchQuery({query: TELEGRAM_BOT_REWARD_TASK})).subscribe(
    (subscriptionResult) => {
      const {completed, enabled: telegramSubscriptionEnabled} =
        subscriptionResult?.data?.credits?.rewardForTasks?.tasks
          ?.telegramSubscription ?? {};

      if (completed) {
        updateMatchCommunicationUserIds(null, currentUserId);
        needOpenTelegramBotPopup = false;
      } else if (
        !startTelegramSubscriptionEnabled &&
        telegramSubscriptionEnabled
      ) {
        openTelegramBotSubscriptionPopup(currentUserId);
        needOpenTelegramBotPopup = true;
      }
    },
  );
});

export default startCoinsTelegramBotSubscribePopupListener;
