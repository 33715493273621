import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

import {getCookie, setCookie} from '@core/utils/cookie';
import isInRouteList from '@core/utils/routing/isInRouteList';

import useIsTransitionActive from '@phoenix/payment/utils/useIsTransitionActive';

import useIsRouteChanged from './useIsRouteChanged';

/**
 * Add global listener to document for tracking "blind click" and sending track.
 * We should start listening only if callback exists, since otherwise we can't open anything.
 * @param {Function} callPopunder
 * @param {Array} excludeRoutes
 * @param {String} cookieName
 * @param {Number} expires
 */
const useBlindClickListener = ({
  callPopunder,
  excludeRoutes,
  cookieName,
  expires,
}) => {
  const location = useLocation();
  const isRouteChanged = useIsRouteChanged();
  const isTransitionActive = useIsTransitionActive();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      isRouteChanged &&
      callPopunder &&
      !getCookie(cookieName) &&
      excludeRoutes &&
      /**
       * Exclude first '/' in pathname since back-end returns such URLs
       * @todo Should be refactored when we discard legacy sites usage.
       */
      !isInRouteList(excludeRoutes, location.pathname.substring(1))
    ) {
      const listener = () => {
        if (isTransitionActive) {
          return;
        }

        setCookie(cookieName, '1', {
          expires,
        });

        callPopunder();
      };

      document.addEventListener('click', listener, {once: true});

      return () => {
        document.removeEventListener('click', listener);
      };
    }
  }, [
    isTransitionActive,
    isRouteChanged,
    cookieName,
    callPopunder,
    expires,
    location,
    excludeRoutes,
  ]);
};

export default useBlindClickListener;
