import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {packagePricePropType} from '@core/payment/widgets/package/constants/propTypes';

import {Card, Radio} from '@phoenix/ui';

import PaymentPackageTitle from './PaymentPackageTitle';
import PaymentPackagePrice from './PaymentPackagePrice';
import PAYMENT_PACKAGE_HEADER_COLOR from '../constants/packageHeaderColor';
import css from '../styles/PaymentPackageHeader.css';

const PaymentPackageHeader = ({
  currentPrice,
  oldPrice,
  percentDiscount,
  periodKey,
  title,
  titleLineThrough,
  subTitle,
  withRadio = true,
  withArrow = true,
  withPeriod = true,
  withDiscountLabel = false,
  withCenteredLoPrice = false,
  checked,
  uppercase,
  capitalize = false,
  roundArrow = false,
  color = PAYMENT_PACKAGE_HEADER_COLOR.HEADER,
  withBigTextSize,
  onPageBackground = true,
  paymentPackageOldPriceLayout: PaymentPackageOldPriceLayout,
  transparent = false,
}) => {
  const headerColorHeader =
    color === PAYMENT_PACKAGE_HEADER_COLOR.HEADER && checked; // Yep, little misunderstanding, but we have "header" color

  return (
    <Card
      className={cn(
        css.header,
        checked && !transparent && css[`${color}Color`],
      )}
      shadowLevel={0}
      onPageBackground={onPageBackground && !checked}
      data-test="paymentPackageHeader"
      transparent={transparent}
    >
      <div className={css.space}>
        <div className={css.container}>
          {withRadio && (
            <div className={css.action}>
              <Radio
                inverse={headerColorHeader}
                checked={checked}
                trackingName="paymentPackage"
                value=""
              />
            </div>
          )}
          <div className={css.info}>
            <PaymentPackageTitle
              inverse={headerColorHeader}
              title={title}
              capitalize={capitalize}
              lineThrough={titleLineThrough}
              subTitle={subTitle}
              uppercase={uppercase}
              withBigTextSize={withBigTextSize}
            />
          </div>
          <div className={css.priceHolder}>
            {oldPrice && (
              <PaymentPackageOldPriceLayout
                inverse={headerColorHeader}
                price={oldPrice}
              />
            )}
            <PaymentPackagePrice
              withArrow={withArrow}
              roundArrow={roundArrow}
              highlighted={headerColorHeader}
              price={currentPrice}
              percentDiscount={percentDiscount}
              withDiscountLabel={withDiscountLabel && checked}
              periodKey={periodKey}
              withPeriod={withPeriod}
              withCenteredLoPrice={withCenteredLoPrice}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

PaymentPackageHeader.COLOR = PAYMENT_PACKAGE_HEADER_COLOR;

PaymentPackageHeader.propTypes /* remove-proptypes */ = {
  currentPrice: packagePricePropType.isRequired,
  oldPrice: packagePricePropType,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.node,
  periodKey: PropTypes.string,
  titleLineThrough: PropTypes.bool,
  checked: PropTypes.bool,
  withRadio: PropTypes.bool,
  withArrow: PropTypes.bool,
  withPeriod: PropTypes.bool,
  withDiscountLabel: PropTypes.bool,
  percentDiscount: PropTypes.number,
  withCenteredLoPrice: PropTypes.bool,
  uppercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  roundArrow: PropTypes.bool,
  transparent: PropTypes.bool,
  // Color, that should be applied in case if package is selected (e.g. "active")
  color: PropTypes.oneOf(Object.values(PAYMENT_PACKAGE_HEADER_COLOR)),
  withBigTextSize: PropTypes.bool,
  onPageBackground: PropTypes.bool,
  paymentPackageOldPriceLayout: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
};

export default PaymentPackageHeader;
