import type {FC} from 'react';
import React from 'react';

import t from '@core/translations';
import type ThreeDSecureAbortPopupProps from '@core/payment/widgets/threeDSecure/types/threeDSecureAbortPopupProps';

import {Spacing, Actions, ButtonPrimary, Button} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {H3} from '@phoenix/typography';

const ThreeDSecureAbortPopup: FC<ThreeDSecureAbortPopupProps> = ({
  onBackClick,
  onAbortClick,
}) => (
  <Spacing>
    <H3 align={H3.ALIGN.CENTER}>
      {t('paymentPage', 'text.your_payment_is_in_processing')}
    </H3>
    <H3 align={H3.ALIGN.CENTER}>{t('paymentPage', 'text.please_wait')}</H3>
    <Actions
      fullWidth
      vertical
      spacing={SpacingSize.NORMAL}
      itemSpacing={SpacingSize.SHORT}
    >
      <ButtonPrimary fullWidth onClick={onBackClick} trackingName="back">
        {t('paymentPage', 'text.back')}
      </ButtonPrimary>
      <Button fullWidth onClick={onAbortClick} trackingName="abort">
        {t('paymentPage', 'text.abort_payment')}
      </Button>
    </Actions>
  </Spacing>
);

export default ThreeDSecureAbortPopup;
