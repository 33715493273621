import {useEffect} from 'react';
import get from 'lodash/get';
import {useApolloClient} from '@apollo/client';

import {sessionStorage as storage} from '@core/utils/storage';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';

import openCoinsSuccessPopup from '@phoenix/coins/utils/openCoinsSuccessPopup';
import getSuccessPopupType from '@phoenix/coins/specialOffer/utils/getSuccessPopupType';

import PAYMENT_ORDER_DATA_QUERY from '../graphql/queries/paymentOrderData.gql';

/**
 * @type {string}
 */
export const SUCCESS_RESULT = 'success';
export const FAILURE_RESULT = 'failure';

/**
 * @const {string}
 */
export const STORAGE_KEY = 'orderIdAfterExternalPay';

/**
 * @desc Displays a pop-up of a successful purchase of coins, upon an indulgent return to the site,
 * after an external payment, by order id and selectedPackageName, the method that was saved to the session store,
 * if the payment is successful
 */
const PaymentOrderChecker = () => {
  const client = useApolloClient();

  /**
   * Get stored in session storage selectedPackageName and orderId before redirect to external pay (s.u. sofort)
   */
  const {orderId, packageName, via} = storage.getItem(STORAGE_KEY, {
    orderId: null,
    packageName: null,
    via: null,
  });

  useEffect(() => {
    if (orderId) {
      client
        .query({query: PAYMENT_ORDER_DATA_QUERY, variables: {orderId}})
        .then(({data}) => {
          if (get(data, 'payment.orderData.status') === SUCCESS_RESULT) {
            if (packageName) {
              /**
               * Show popup and clear store when success response from billing
               */
              openCoinsSuccessPopup({
                packages: [packageName],
                type: getSuccessPopupType({via}),
              });
            } else {
              NotificationsService.addNotification({
                type: NOTIFICATION_TYPES.SUCCESS_PAYMENT,
              });
              PaymentPageSuccessOrders.add(orderId, via);
            }

            storage.removeItem(STORAGE_KEY);
          }
        });
    }
  }, [client, via, orderId, packageName]);

  return null;
};

export default PaymentOrderChecker;
