import {
  BUY_3DAY_GET_1WEEK_POPUP,
  EXTRA_DAY_VIAS,
} from '@core/payment/common/constants/vias';

export const RESTRICTED_VIA_LIST = [
  BUY_3DAY_GET_1WEEK_POPUP,
  ...EXTRA_DAY_VIAS,
  'mail_ics',
  'remove_account',
];
