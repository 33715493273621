import type {ReactNode, FC} from 'react';
import React from 'react';
import cn from 'classnames';

import type {LevelTextsType} from '@core/user/photo/constants/levelTexts';
import {LEVEL_TEXTS} from '@core/user/photo/constants/levelTexts';
import type PhotoLevel from '@core/user/photo/constants/PhotoLevel';
import type PhotoSize from '@core/user/photo/constants/PhotoSize';
import MediaPreviewAppearance from '@core/theming/constants/features/MediaPreviewAppearance';

import {Overlay, Spacing} from '@phoenix/ui';
import {OverlaySaturation} from '@phoenix/ui/constants';
import {Text, H2} from '@phoenix/typography';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import getLevelOverlayDescription from '../utils/getLevelOverlayDescription';
import css from '../styles/LevelOverlay.css';

export type LevelOverlayProps = {
  size: PhotoSize;
  level: PhotoLevel;
  texts?: LevelTextsType;
  className?: string;
  textClassName?: string;
  overlayClassName?: string;
  children?: ReactNode;
  additionalInfo?: ReactNode;
  withBigTextSize?: boolean;
  withDescription?: boolean;
};

/**
 * Photo level overlay over user photo.
 * Shows info (or not) about changing level to see photo
 */
const LevelOverlay: FC<LevelOverlayProps> = ({
  children,
  texts = LEVEL_TEXTS,
  size,
  level,
  additionalInfo,
  className,
  overlayClassName,
  textClassName,
  withBigTextSize = false,
  withDescription = true,
}) => {
  const {data: mediaPreview} =
    useThemeFeature<MediaPreviewAppearance>('mediaPreview');
  const description = withDescription
    ? getLevelOverlayDescription({
        texts,
        size,
        // @ts-expect-error -- TODO[TS]: Add type to getLevelOverlayDescription after release FE-41942
        level,
      })
    : null;

  return (
    <Overlay saturation={OverlaySaturation.LITE} className={overlayClassName}>
      <Spacing className={cn(css.wrap, className)}>
        <div className={css.content}>
          {children}
          {description && (
            <div className={cn(textClassName, css.textWrapper)}>
              {withBigTextSize ? (
                <H2 inverse>
                  {description}
                  {additionalInfo}
                </H2>
              ) : (
                <Text
                  data-test="levelOverlayDescription"
                  bold={
                    mediaPreview ===
                    MediaPreviewAppearance.BLURRED_WITHOUT_BUTTON
                  }
                  inverse
                >
                  {description}
                  {additionalInfo}
                </Text>
              )}
            </div>
          )}
        </div>
      </Spacing>
    </Overlay>
  );
};

export default LevelOverlay;
