import {getClientInstance} from '@core/graphql/client';
import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@core/utils/storage';
import isInRouteList from '@core/utils/routing/isInRouteList';

import type {RemarketingPopupSettingsFragment} from '../graphql/fragments/remarketingPopupSettings';
import REMARKETING_POPUP_SETTINGS_FRAGMENT from '../graphql/fragments/remarketingPopupSettings.gql';
import {STORAGE_KEY} from '../constants/popup';

interface RemarketingOfferData {
  count: number;
  timestamp: number;
}

/**
 * Is used to manage the RM offer popup display restrictions.
 */
const popupDisplay = {
  /**
   * Tracks the number of times the popup has been displayed and the timestamp of the most recent display.
   */
  rememberDisplay: (): void => {
    const data: RemarketingOfferData = readFromSessionStorage(STORAGE_KEY) || {
      count: 0,
      timestamp: 0,
    };
    data.count++;
    data.timestamp = Math.floor(Date.now() / 1000);
    writeToSessionStorage<RemarketingOfferData>(STORAGE_KEY, data);
  },

  /**
   * Tells if the popup is allowed to be displayed.
   * Provide the `pathname` if you want to check if the popup is allowed for a particular route.
   */
  isAllowed: (pathname?: string) => {
    const data =
      getClientInstance().readFragment<RemarketingPopupSettingsFragment>({
        id: 'PopupInfo:{}',
        fragment: REMARKETING_POPUP_SETTINGS_FRAGMENT,
      });

    const {availableRoutes, countPerSession, intervalInSeconds} =
      data?.displaySettings || {};

    if (
      !data?.isPopupAvailable ||
      (pathname &&
        (!availableRoutes?.length || !isInRouteList(availableRoutes, pathname)))
    ) {
      return false;
    }

    const storedData: RemarketingOfferData = readFromSessionStorage(
      STORAGE_KEY,
    ) || {count: 0, timestamp: 0};
    const {count, timestamp} = storedData;

    return (
      count < countPerSession &&
      Math.floor(Date.now() / 1000) - timestamp >= intervalInSeconds
    );
  },
};

export default popupDisplay;
