import type {FC} from 'react';
import React from 'react';
import Expire from 'react-expire';

import NOTIFICATION_OPTIONS from '@core/systemNotifications/constants/notificationOptions';

import type {SystemNotificationProps} from '../types';
import SystemNotificationLayout from '../components/SystemNotificationLayout';

const SystemNotification: FC<SystemNotificationProps> = ({
  onClose,
  mediaType,
  expire,
  ...props
}) => {
  const {text, ...options} = NOTIFICATION_OPTIONS[mediaType];

  return (
    <Expire onExpire={onClose as () => void} until={expire}>
      <SystemNotificationLayout
        onClose={onClose}
        text={text(props)}
        {...options}
        {...props}
      />
    </Expire>
  );
};

export default SystemNotification;
