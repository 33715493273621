import deletePhotoCommon from '@core/user/profile/current/utils/deletePhoto';
import MediaUploadAppearance from '@core/theming/constants/features/MediaUploadAppearance';

import getThemeFeature from '@phoenix/theming/utils/getThemeFeature';
import PHOTO_UPLOAD_MOTIVATION_SETTINGS_QUERY from '@phoenix/mediaUpload/graphql/queries/photoUploadMotivationSettings.gql';

/**
 * @param {String} photoId
 */
const deletePhoto = async (photoId) => {
  const appearance = await getThemeFeature('mediaUpload', 'appearance');

  deletePhotoCommon(
    photoId,
    appearance === MediaUploadAppearance.WITH_BLURRED_PHOTO_MOTIVATION
      ? [{query: PHOTO_UPLOAD_MOTIVATION_SETTINGS_QUERY}]
      : [],
  );
};

export default deletePhoto;
