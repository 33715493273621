import React from 'react';
import {Formik} from 'formik';
import {useQuery} from '@apollo/client';
import get from 'lodash/get';
import first from 'lodash/first';

import PopupService from '@core/popup/utils/PopupService';
import tryToSaveData from '@core/user/profile/current/utils/tryToSaveData';
import AUTHORIZED_QUERY from '@core/application/graphql/queries/authorized.gql';

import {Loader} from '@phoenix/ui';

import DnsmpiPopupLayout from '../components/DnsmpiPopupLayout';
import getValidationSchema from '../utils/getValidationSchema';
import validate from '../utils/validate';
import DNSMPI_MUTATION from '../graphql/mutations/dnsmpi.gql';
import {FIELD_NAMES} from '../constants/fields';
import STATES from '../constants/states';
import openDnsmpiSuccessPopup from '../utils/openDnsmpiSuccessPopup';

const initialValues = {
  [FIELD_NAMES.FIRST_NAME]: '',
  [FIELD_NAMES.LAST_NAME]: '',
  [FIELD_NAMES.EMAIL]: '', // need for not autorized zone
  [FIELD_NAMES.CITY]: '',
  [FIELD_NAMES.ZIP]: '',
  [FIELD_NAMES.ALLOWED_INFORMATION]: false,
  [FIELD_NAMES.ALLOWED_PROFILING]: false,
  [FIELD_NAMES.ALLOWED_ADVERTISING]: false,
  [FIELD_NAMES.CAPTCHA]: '', // need for not autorized zone
  [FIELD_NAMES.COUNTRY]: first(STATES), // set hard code value due to this logic need only for this state
};

const DnsmpiPopup = () => {
  const {data: authorizedData, loading} = useQuery(AUTHORIZED_QUERY);

  const isAuthorizedZone = get(authorizedData, 'isAuthorizedZone');

  if (loading) return <Loader />;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(isAuthorizedZone)}
      validate={validate}
      onSubmit={(
        {
          [FIELD_NAMES.ALLOWED_INFORMATION]: markedAsAllowedInformation,
          [FIELD_NAMES.ALLOWED_PROFILING]: markedAsAllowedProfiling,
          [FIELD_NAMES.ALLOWED_ADVERTISING]: markedAsAllowedAdvertising,
          ...values
        },
        {setSubmitting, setFieldError},
      ) => {
        tryToSaveData({
          values,
          initialValues,
          mutation: DNSMPI_MUTATION,
          setSubmitting,
          getErrorsResponse: (response) => response.data.site.dnsmpi.errors,
          prepareInfoBeforeSend: (data) => {
            return data;
          },
          mutationUpdateCacheResolver: () => {},
          setErrors: (errors) => {
            if (!errors) return;

            Object.keys(errors).forEach((fieldName) => {
              const error = errors[fieldName];

              setFieldError(fieldName, error);
            });
          },
          handleClose: () => {
            PopupService.closePopup();
            openDnsmpiSuccessPopup();
          },
        });
      }}
    >
      {({handleSubmit, isSubmitting}) => (
        <DnsmpiPopupLayout
          onClose={() => {
            PopupService.closePopup();
          }}
          withEmail={!isAuthorizedZone}
          withCaptcha={!isAuthorizedZone}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
        />
      )}
    </Formik>
  );
};

export default DnsmpiPopup;
