let onPhotoReward: (() => void) | null = null;

/**
 * Sets the handler function for photo reward.
 * @param {() => void} fn - The function to be called when a photo reward is handled.
 */
export const setPhotoRewardHandler = (fn?: () => void): void => {
  onPhotoReward = fn || null;
};

/**
 * Calls the handler function for photo reward if it is set.
 * @return {void}
 */
export const handlePhotoReward = (): void => onPhotoReward?.();
