import React from 'react';

import t from '@core/translations/translate';
import PaymentPendingPopupWrapp from '@core/payment/paymentPending/components/PaymentPendingPopupWrapp';
import PaymentPendingProgressWrapp from '@core/payment/paymentPending/components/PaymentPendingProgressWrapp';
import PaymentPendingTextWrapp from '@core/payment/paymentPending/components/PaymentPendingTextWrapp';
import {CHECK_3DS_STATUS_TIMEOUT} from '@core/payment/paymentPending/utils/createThreeDSStatusCheckObservable';

import {SpacingLarge, Row, RowShort} from '@phoenix/ui';
import {H1, H3, TextMuted} from '@phoenix/typography';
import ProgressWithTimer from '@phoenix/widgets/time/ProgressWithTimer';

const PaymentPendingPopupLayout = () => (
  <PaymentPendingPopupWrapp>
    <SpacingLarge stretch={false}>
      <Row>
        <PaymentPendingProgressWrapp>
          <ProgressWithTimer
            fixedSize={false}
            initialTime={CHECK_3DS_STATUS_TIMEOUT / 1000}
            reversePercentage
            clockwise={false}
            strokeWidth={1}
            withRestore={false}
          >
            {(time) => <H1 primary>{time}</H1>}
          </ProgressWithTimer>
        </PaymentPendingProgressWrapp>
      </Row>
      <RowShort>
        <H3>{t('paymentPage', 'title.processing_transaction')}</H3>
      </RowShort>
      <PaymentPendingTextWrapp>
        <TextMuted>
          {t('paymentPage', 'text.your_transaction_being_processed')}
        </TextMuted>
      </PaymentPendingTextWrapp>
    </SpacingLarge>
  </PaymentPendingPopupWrapp>
);

export default PaymentPendingPopupLayout;
