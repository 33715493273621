import React, {useCallback, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';
import {currencyPropType} from '@core/payment/widgets/package/constants/propTypes';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {Button, Card, Row, RowShort, SpacingShort} from '@phoenix/ui';
import {ButtonType, SpacingSize, Align} from '@phoenix/ui/constants';
import {H1, TextWithIcon} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import PaymentPackageHeader from '@phoenix/payment/widgets/package/components/PaymentPackageHeader';
import useThemedPackageHeaderProps from '@phoenix/theming/utils/useThemedPackageHeaderProps';

import css from '../styles/ExtraBuyThreeDayPopupLayout.css';

const WEEK_PERIOD_KEY = 'week.period';

const ExtraBuyThreeDayPopupLayout = ({url, amount, currency}) => {
  const history = useHistory();
  const isPhone = usePhoneBreakpoint();
  const [titleLineThrough, setTitleLineThrough] = useState(false);
  const handleAnimationStart = useCallback(() => setTitleLineThrough(true), []);
  const {data: headerProps} = useThemedPackageHeaderProps();

  const handleClick = useCallback(
    (event) => {
      /**
       * We prevent further propagation of the current event in the capturing and bubbling phases.
       * Because we have got action click on parent element and we will have a double click.
       */
      event.stopPropagation();
      history.push(url);
    },
    [history, url],
  );

  const handleClickOnContent = useCallback(() => {
    history.push(url);
  }, [url, history]);

  const currentPrice = useMemo(
    () => ({
      pricePerPart: {
        currency,
        formatted: {
          amount,
          hi: amount,
          lo: '',
        },
      },
    }),
    [currency, amount],
  );

  return (
    <PopupFrame
      data-test="extraBuyThreeDayPopupLayout"
      onClick={isPhone ? handleClickOnContent : null}
      trackingName={isPhone ? 'content' : null}
      content={
        <div className={css.container} data-test="extraBuyThreeDayPopupContent">
          <Row align={Align.CENTER}>
            <H1>{t('extraPopups', 'title.pay_for_3days')}</H1>
            <H1 primary className={css.subHeader}>
              {t('extraPopups', 'title.get_week')}
            </H1>
          </Row>
          <RowShort
            className={css.previousPackage}
            onAnimationStart={handleAnimationStart}
          >
            <Card onPageBackground>
              <PaymentPackageHeader
                {...headerProps}
                data-test="extraBuyThreeDayPopupPackage"
                currentPrice={currentPrice}
                periodKey={WEEK_PERIOD_KEY}
                title={t('paymentPagePackage', 'title.3DAY1')}
                subTitle={t(
                  'paymentPagePackage',
                  'title.package_description_premium membership',
                )}
                titleLineThrough={titleLineThrough}
                withRadio={false}
                withPeriod={false}
              />
            </Card>
          </RowShort>
          <RowShort className={css.package}>
            <Card onPageBackground>
              <div className={css.packageHeaderWrapper}>
                <PaymentPackageHeader
                  {...headerProps}
                  data-test="extraBuyThreeDayPopupPackage"
                  currentPrice={currentPrice}
                  periodKey={WEEK_PERIOD_KEY}
                  title={t('paymentPagePackage', 'title.7DAY1')}
                  subTitle={t(
                    'paymentPagePackage',
                    'title.package_description_premium membership',
                  )}
                  withRadio={false}
                  withPeriod={false}
                  checked
                />
              </div>
              <SpacingShort>
                <TextWithIcon
                  icon="fire"
                  space={SpacingSize.SHORT}
                  small
                  textType={TextWithIcon.TYPE.MUTED}
                >
                  {t('extraPopups', 'text.epic_weekend')}
                </TextWithIcon>
              </SpacingShort>
            </Card>
          </RowShort>
        </div>
      }
      actions={
        <Button
          type={ButtonType.PAY}
          fullWidth
          onClick={handleClick}
          adaptive
          data-test="extraBuyThreeDayPopupAction"
          trackingName="getIt"
        >
          {t('extraPopups', 'button.get_it')}
        </Button>
      }
    />
  );
};

ExtraBuyThreeDayPopupLayout.propTypes /* remove-proptypes */ = {
  url: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  currency: currencyPropType.isRequired,
};

export default ExtraBuyThreeDayPopupLayout;
