import once from 'lodash/once';
import {combineLatest, fromEvent} from 'rxjs';
import {distinctUntilChanged, filter, startWith} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';

import canShowPopup from '@phoenix/popup/utils/canShowPopup';

import OPEN_EXTRA_COINS_POPUP_SUBSCRIPTION from '../graphql/subscriptions/showExtraCoinsPopup.gql';
import getExtraCoinsPopupData from './getExtraCoinsPopupData';
import openExtraCoinsPopup from './openExtraCoinsPopup';

const disabledRoutes = ['/chat/with', '/rooms/join'];

const startExtraCoinsPopupListener = once(async (client) => {
  const {available} = await getExtraCoinsPopupData();

  if (!available) {
    return;
  }

  const popupState$ = fromEvent(document, POPUP_STATE_CHANGE_EVENT).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
  );

  const signal$ = client.subscribe({
    query: OPEN_EXTRA_COINS_POPUP_SUBSCRIPTION,
  });

  const routeState$ = client.watchQuery({query: ROUTE_QUERY});

  // Need opening popup only after another popups, and in right routs
  const source$ = combineLatest([signal$, routeState$, popupState$]).pipe(
    filter(
      ([
        ,
        {
          data: {route},
        },
        {detail},
      ]) => {
        return canShowPopup(route, detail, disabledRoutes);
      },
    ),
    distinctUntilChanged(([prev], [current]) => {
      return (
        prev.data.credits.openExtraCoinsPopup ===
        current.data.credits.openExtraCoinsPopup
      );
    }),
  );

  source$.subscribe(() => {
    openExtraCoinsPopup();
  });
});

export default startExtraCoinsPopupListener;
