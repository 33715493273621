import {gql} from '@apollo/client';
import upperFirst from 'lodash/upperFirst';

/**
 * Build dynamic query for retrieving only needed free coins reward fields.
 * @param {string} task
 * @returns {Object} GraphQL Query
 */
const buildFreeCoinsRewardQuery = (task) => {
  return gql`
    query FreeCoinsReward${upperFirst(task)}Query {
      credits {
        rewardForTasks {
          enabled
          tasks {
            ${task} {
              enabled
              reward
              completed
            }
          }
        }
      }
    }
  `;
};

export default buildFreeCoinsRewardQuery;
