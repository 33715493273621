import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyPasswordRecovery = lazy(
  () =>
    import(
      /* webpackChunkName: 'recoveryPassword' */ '@phoenix/recoveryPassword'
    ),
);

export default (props) => (
  <SplitChunk component={LazyPasswordRecovery} {...props} />
);
