import React, {useCallback, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import CURRENT_USER_COUNTRY_QUERY from '@core/user/profile/current/graphql/queries/currentUserCountry.gql';

import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {
  ButtonPrimary,
  Button,
  Select,
  SelectItem,
  Loader,
  SpacingShort,
} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import openPersonalIdentityByDemandDocumentUploadPopup from '../utils/openPersonalIdentityByDemandDocumentUploadPopup';
import useClosingPopupForVerifiedStatus from '../utils/useClosingPopupForVerifiedStatus';
import {COUNTRY_CODES} from '../constants/countryCodes';

const PersonalIdentityWorldRequirePopupInner = ({country}) => {
  const [countryCode, setCountryCode] = useState(country);
  const handleChange = useCallback(
    (event, code) => {
      setCountryCode(code);
    },
    [setCountryCode],
  );
  const handleClick = useCallback(() => {
    openPersonalIdentityByDemandDocumentUploadPopup(countryCode);
  }, [countryCode]);

  const countryItems = useMemo(() => {
    return COUNTRY_CODES.map((code) => {
      return {code, name: t('countryCodeDynamic', code)};
    })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({code, name}) => (
        <SelectItem key={code} value={code}>
          {name}
        </SelectItem>
      ));
  }, []);

  return (
    <PopupNotice
      icon="age-check"
      title={t('personalIdentityByDemand', 'text.verification')}
      subTitle={t(
        'personalIdentityByDemand',
        'text.verificationRequireDescription',
      )}
      description={
        <SpacingShort onlyTop>
          <Select
            value={countryCode}
            onChange={handleChange}
            label={t('personalIdentityByDemand', 'text.selectCountryLabel')}
          >
            {countryItems}
          </Select>
        </SpacingShort>
      }
      actions={[
        <ButtonPrimary fullWidth onClick={handleClick} trackingName="passport">
          {t('personalIdentityByDemand', 'button.passport')}
        </ButtonPrimary>,
        <Button fullWidth onClick={handleClick} trackingName="driverLicense">
          {t('personalIdentityByDemand', 'button.driverLicense')}
        </Button>,
      ]}
    />
  );
};

PersonalIdentityWorldRequirePopupInner.propTypes /* remove-proptypes */ = {
  country: PropTypes.string.isRequired,
};

const PersonalIdentityByDemandRequirePopup = () => {
  const {data, loading, error} = useQuery(CURRENT_USER_COUNTRY_QUERY);
  useClosingPopupForVerifiedStatus();

  if (loading) return <Loader />;
  if (error) return <ErrorBoundary error={error} />;

  const {
    myUser: {
      profile: {
        location: {country},
      },
    },
  } = data;
  return <PersonalIdentityWorldRequirePopupInner country={country} />;
};
export default PersonalIdentityByDemandRequirePopup;
