import {makeVar} from '@apollo/client';

/**
 * Flag to understand if important app requests are loaded
 */
const allowedDelayedRenderVar = makeVar(
  Boolean(window.IS_INTEGRATION_TEST_ENVIRONMENT),
);

export default allowedDelayedRenderVar;
