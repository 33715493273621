import type {FC, ReactEventHandler, ReactNode} from 'react';
import React, {useCallback, useContext} from 'react';

import useCleanProductRestrictions from '@core/application/utils/useCleanProductRestrictions';
import PhotoSize from '@core/user/photo/constants/PhotoSize';
import PhotoPreset from '@core/user/photo/constants/PhotoPreset';
import PhotoLevel from '@core/user/photo/constants/PhotoLevel';
import useCorrectedPhotoLevel from '@core/user/photo/utils/useCorrectedPhotoLevel';
import ScrollableElementContext from '@core/utils/scroll/ScrollableElementContext';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import getHistory from '@core/application/utils/getHistory';
import setCachedPhotoUrl from '@core/utils/image/setCachedPhotoUrl';

import NaughtyModeService from '@phoenix/naughtyMode/NaughtyModeService';
import PersonalIdentityService from '@phoenix/personalIdentity/PersonalIdentityService';

import usePhotoUrl from '../utils/usePhotoUrl';
import type PhotoRequestData from '../types/PhotoRequestData';
import type PhotoType from '../types/Photo';
import usePrivatePhotoRequest from '../utils/usePrivatePhotoRequest';
import openNaughtyModePopup from '../utils/openNaughtyModePopup';
import type {PhotoLayoutProps} from '../components/PhotoLayout';
import PhotoLayout from '../components/PhotoLayout';
import PhotoLayoutPlaceholder from '../components/PhotoLayoutPlaceholder';
import LazyLoadMarginContext from './LazyLoadMarginContext';

export type PhotoProps = {
  photo: PhotoType;
  lazy?: boolean;
  withShadow?: boolean;
  round?: boolean;
  onClick?: PhotoLayoutProps['onClick'];
  /**
   * Callback after image was loaded
   */
  onLoad?: ReactEventHandler<HTMLImageElement>;
  my?: boolean;
  skipBlur?: boolean;
  withNaughtyMode?: boolean;
  withNaughtyModePopup?: boolean;
  onNaughtyModeChange?: () => void;
  onNaughtyModeClick?: () => void;
  photoRequestData?: PhotoRequestData;
  /**
   * userId is an optional property, since some Photo instances may have no relation to user.
   * Passed by @see UserWidget.js for naughtyMode popup purposes
   */
  userId?: string;
  /**
   * Lazy photo starts loading when the distance between it and the viewport is less than this number of pixels.
   * You can also wrap the list with `<LazyLoadMarginContext.Provider value={...}>` to set this margin.
   * See default value in {@see PhotoLayout}.
   */
  lazyLoadMargin?: number;
  customOverlay?: ReactNode;
  customBlurLevel?: PhotoLevel;
} & Partial<PhotoLayoutProps>;

type PhotoStaticProps = {
  SIZE: typeof PhotoSize;
  LEVEL: typeof PhotoLevel;
  PRESET: typeof PhotoPreset;
};

const noop = () => {};

/**
 * Basic use photo widget. Corrects photo level based on own selected NM level.
 * If you want to render user photo in application, you must use only this component.
 */
const Photo: FC<PhotoProps> & Partial<PhotoStaticProps> = ({
  onLoad,
  userId = '',
  photo,
  round = false,
  withShadow = true,
  my = false,
  skipBlur = false,
  withNaughtyMode = true,
  photoRequestData,
  onNaughtyModeChange = noop,
  onNaughtyModeClick = noop,
  withNaughtyModePopup = false,
  customOverlay,
  customBlurLevel,
  lazyLoadMargin: propsMargin,
  onClick: propsOnClick,
  mediaCounter,
  lazy = !window.IS_INTEGRATION_TEST_ENVIRONMENT,
  ...props
}) => {
  const contextMargin = useContext(LazyLoadMarginContext);

  const {url, loading, error} = usePhotoUrl(photo);

  const {isPrivate, isRequested, requestType} = photo.privateAttributes || {};

  const {withCleanProductRestrictions} = useCleanProductRestrictions();

  const {onClick: onPrivatePhotoClick, isAllowed: isRequestAllowed} =
    usePrivatePhotoRequest({
      userId,
      photo,
      photoRequestData,
    });

  // Set normal level for own photos to hide placeholders.
  // Correct level based on our and photo levels
  const {level, blurLevel} = useCorrectedPhotoLevel({
    ...photo,
    isMy: my,
    skipBlur,
  });

  const handleClick = useCallback<PhotoLayoutProps['onClick']>(
    async (event, photoOptions) => {
      /**
       * Prevent triggering onClick handler with redirect to user profile
       * Due to we inject custom click handler which open popup we must stop propagation and default behavior for click
       * @see UserWidget.js @see ActivityWidget.js
       */
      event.stopPropagation();
      event.preventDefault();

      onNaughtyModeClick();

      const naughtyMode =
        NaughtyModeService.getNaughtyModeByPhotoLevel(blurLevel);

      if (
        PersonalIdentityService.required &&
        (await NaughtyModeService.isPaidNaughtyMode(naughtyMode))
      ) {
        getHistory().push(
          generatePayUrl({
            stage: PAYMENT_ACTIONS.MEMBERSHIP,
            urlParams: {
              via: NaughtyModeService.getPaidNaughtyModeVia(naughtyMode),
            },
          }),
        );
        return;
      }

      openNaughtyModePopup({
        blurLevel,
        onNaughtyModeChange,
        ...photoOptions,
      });
    },
    [onNaughtyModeClick, blurLevel, onNaughtyModeChange],
  );

  const handleLoad = useCallback(
    (e) => {
      setCachedPhotoUrl(photo.id, url);
      onLoad?.(e);
    },
    [photo.id, url, onLoad],
  );

  // We need scrollable element for lazy photos only
  const scrollContext = useContext(ScrollableElementContext);
  const waiting = lazy && scrollContext && !scrollContext.element;

  if (loading || error || waiting) {
    return <PhotoLayoutPlaceholder round={round} />;
  }

  let onClick = propsOnClick;

  if (isRequestAllowed) {
    onClick = onPrivatePhotoClick;
  } else if (
    !mediaCounter &&
    !isPrivate &&
    !customOverlay &&
    level !== PhotoLevel.NORMAL &&
    (withNaughtyMode || withNaughtyModePopup)
  ) {
    onClick = handleClick;
  }

  return (
    <PhotoLayout
      {...props}
      mediaCounter={mediaCounter}
      onClick={onClick}
      lazy={lazy}
      lazyLoadMargin={propsMargin || contextMargin}
      scrollRoot={scrollContext?.element}
      url={url}
      level={level}
      blurLevel={customBlurLevel || blurLevel}
      round={round}
      shadow={Boolean(withShadow && !my && !round && photo.id)}
      pendingDelete={photo.pendingDelete}
      onLoad={handleLoad}
      withCleanProductRestrictions={withCleanProductRestrictions}
      photoRequestData={{
        userId,
        isPrivate,
        isRequested,
        requestType,
        ...photoRequestData,
      }}
      customOverlay={customOverlay}
    />
  );
};

Photo.SIZE = PhotoSize;
Photo.LEVEL = PhotoLevel;
Photo.PRESET = PhotoPreset;

export default Photo;
