import type {ReactNode} from 'react';
import React, {useMemo} from 'react';
import {useQuery} from '@apollo/client';

import USER_PROFILE_PRIMARY_PHOTO_QUERY from '@core/user/profile/common/graphql/queries/profilePrimaryPhoto.gql';
import type {
  UserProfilePrimaryPhotoQuery,
  UserProfilePrimaryPhotoQueryVariables,
} from '@core/user/profile/common/graphql/queries/profilePrimaryPhoto';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';

import PhotosListAsRoulette from '@phoenix/user/photo/components/PhotosListAsRoulette';
import PhotoWithWidgets from '@phoenix/user/photo/components/PhotoWithWidgets';
import useRandomUsers from '@phoenix/search/list/utils/useRandomUsers';

const USERS_AMOUNT = 3;

type MediaMotivationOptions = {
  userId?: string;
  userAmount?: number;
  withOnlineStatus?: boolean;
  userPhotoOptions?: {
    withNaughtyModePopup?: boolean;
    withNaughtyMode?: boolean;
    withDescription?: boolean;
    className?: string;
  };
};

type MediaMotivationResult = {
  media: ReactNode | null;
  loading: boolean;
  userName: string | null;
};

const useMediaMotivation = ({
  userId = '',
  userAmount = USERS_AMOUNT,
  withOnlineStatus = false,
  userPhotoOptions = {},
}: MediaMotivationOptions): MediaMotivationResult => {
  const {randomUsers: users = [], loading} = useRandomUsers({
    count: userAmount,
    exact: true,
    skip: Boolean(userId),
  });

  const {data, loading: photoLoading} = useQuery<
    UserProfilePrimaryPhotoQuery,
    UserProfilePrimaryPhotoQueryVariables
  >(USER_PROFILE_PRIMARY_PHOTO_QUERY, {variables: {userId}, skip: !userId});

  const media = useMemo(() => {
    if (users?.length && userAmount > 1) {
      return <PhotosListAsRoulette users={users} />;
    }
    if (data || users?.length) {
      const {profile} = data?.user || users[0];
      return (
        <PhotoWithWidgets
          photo={getPrimaryPhotoData(profile)}
          userId={profile.id}
          online={withOnlineStatus && profile.statuses.isOnline}
          round
          {...userPhotoOptions}
        />
      );
    }

    return null;
  }, [users, data, withOnlineStatus, userAmount, userPhotoOptions]);

  const userName =
    data?.user.profile.login ||
    (Array.isArray(users) && users[0]?.profile.login) ||
    null;

  return {
    loading: photoLoading || loading,
    userName,
    media,
  };
};

export default useMediaMotivation;
