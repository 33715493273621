import {getCookie} from '@core/utils/cookie';

import {
  BACK_OFFER_POPUNDER_COOKIE,
  EXPIRE_COOKIE_YEAR,
} from '../constants/cookie';
import DISABLED_ROUTES from '../constants/disabledRoutes';
import useBlindClickListener from '../utils/useBlindClickListener';
import useBackOfferPopunder from '../utils/useBackOfferPopunder';
import useIsRouteChanged from '../utils/useIsRouteChanged';

const BlindClickBackOfferPopunder = () => {
  // We should start data-retrieving for pop-under only after first redirect.
  const isRouteChanged = useIsRouteChanged();

  const callPopunder = useBackOfferPopunder(
    !isRouteChanged || Boolean(getCookie(BACK_OFFER_POPUNDER_COOKIE)),
  );

  useBlindClickListener({
    callPopunder,
    excludeRoutes: DISABLED_ROUTES,
    cookieName: BACK_OFFER_POPUNDER_COOKIE,
    expires: EXPIRE_COOKIE_YEAR,
  });

  return null;
};

export default BlindClickBackOfferPopunder;
