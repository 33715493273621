import useAdditionalTermsBase from '@core/payment/widgets/billingPolicy/utils/useAdditionalTerms';
import useIsMobFirstStage from '@core/payment/common/utils/useIsMobFirstStage';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';

import useIsMobOneStep from '../../../common/utils/useIsMobOneStep';
import useWithPolicyAgreement from '../../../pages/utils/useWithPolicyAgreement';
import isWithPolicyAgreementMethod from '../../additionalPackage/utils/isWithPolicyAgreementMethod';

const useAdditionalTerms = () => {
  const {isPayUrl, isAdditionalTermsNeeded, loading, error, ...rest} =
    useAdditionalTermsBase();
  const withPolicyAgreement = useWithPolicyAgreement();
  const {activePackage} = useActivePackage();

  const isMobFirstStage = useIsMobFirstStage();

  const {
    isOneStep,
    loading: oneStepLoading,
    error: oneStepError,
  } = useIsMobOneStep();

  const isAdditionalTermsNeededComputed =
    isAdditionalTermsNeeded ||
    (isPayUrl &&
      withPolicyAgreement &&
      isWithPolicyAgreementMethod(activePackage?.method));

  return {
    ...rest,
    withCheckbox:
      isAdditionalTermsNeededComputed && (isOneStep || !isMobFirstStage),
    isAdditionalTermsNeeded: isAdditionalTermsNeededComputed,
    loading: loading || oneStepLoading,
    error: error || oneStepError,
  };
};

export default useAdditionalTerms;
