import React, {lazy} from 'react';

import {Loader} from '@phoenix/ui';
import ThemedBlockedPage from '@phoenix/theming/components/ThemedBlockedPage';
import ThemedReportedPage from '@phoenix/theming/components/ThemedReportedPage';

import SplitChunk from '../components/SplitChunk';

const Account = lazy(
  () =>
    import(
      /* webpackChunkName: 'account' */ '@phoenix/account/containers/Account'
    ),
);

/**
 * Account page.
 */
export const AccountChunk = (props) => (
  <SplitChunk component={Account} {...props} />
);

const LazyUnsubscribe = lazy(
  () => import(/* webpackChunkName: 'unsubscribe' */ '@phoenix/unsubscribe'),
);

/**
 * Unsubscribe from maillist page (user can reach it only from e-mail)
 */
export const UnsubscribeChunk = (props) => (
  <SplitChunk component={LazyUnsubscribe} {...props} />
);

/**
 * Blocked users list
 */
export const BlockedUsersChunk = (props) => (
  <ThemedBlockedPage placeholder={<Loader fullscreen />} {...props} />
);

/**
 * Reported users list
 */
export const ReportedUsersChunk = (props) => (
  <ThemedReportedPage placeholder={<Loader fullscreen />} {...props} />
);
