import {useState, useRef, useCallback, useEffect} from 'react';

const TIMER_INTERVAL = 1000;
const INITIAL_TIMER_DURATION = 0;

let timerInterval;

/**
 * Hook for manipulations with video chat timer.
 */
const useVideoChatTimer = () => {
  const [callDuration, setCallDuration] = useState(INITIAL_TIMER_DURATION);
  const [isTimerRun, setIsTimerRun] = useState(false);
  const savedUpdateTimer = useRef({});

  const updateTimer = useCallback(() => {
    setCallDuration(callDuration + TIMER_INTERVAL / 1000);
  }, [callDuration]);

  useEffect(() => {
    savedUpdateTimer.current = updateTimer;
  }, [updateTimer]);

  useEffect(() => {
    if (isTimerRun) {
      timerInterval = setInterval(
        () => savedUpdateTimer.current(),
        TIMER_INTERVAL,
      );
    } else {
      timerInterval && clearInterval(timerInterval);
      setCallDuration(INITIAL_TIMER_DURATION);
    }
  }, [isTimerRun]);

  const runTimer = useCallback(() => {
    setIsTimerRun(true);
  }, []);

  const stopTimer = useCallback(() => {
    setIsTimerRun(false);
  }, []);

  return {
    callDuration,
    runTimer,
    stopTimer,
  };
};

export default useVideoChatTimer;
