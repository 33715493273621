import {getClientInstance} from '@core/graphql/client';
import GLOBAL_FREE_MESSAGES_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessages.gql';

const updateGlobalFreeMessagesTimeout = ({freeMessagesTimeout}) => {
  const client = getClientInstance();

  const data = client.readQuery({
    query: GLOBAL_FREE_MESSAGES_QUERY,
  });

  client.writeQuery({
    query: GLOBAL_FREE_MESSAGES_QUERY,
    data: {
      messenger: {
        ...data.messenger,
        initialData: {
          ...data.messenger.initialData,
          globalFreeMessages: {
            ...data.messenger.initialData.globalFreeMessages,
            freeMessagesTimeout,
          },
        },
      },
    },
  });
};

export default updateGlobalFreeMessagesTimeout;
