import {
  REWARD_FOR_TELEGRAM_BOT_SUBSCRIBED,
  REWARD_FOR_CONFIRM,
} from '@phoenix/credits/constants/balanceChangeReason';
import {
  EMAIL_VERIFICATION,
  TELEGRAM_SUBSCRIPTION,
} from '@phoenix/freeCoins/constants/taskType';
import completeRewardTask from '@phoenix/freeCoins/utils/completeRewardTask';

const completeRewardTaskByBalanceReason = (client, reason) => {
  switch (reason) {
    case REWARD_FOR_CONFIRM:
      completeRewardTask(client, EMAIL_VERIFICATION);
      break;
    case REWARD_FOR_TELEGRAM_BOT_SUBSCRIBED:
      completeRewardTask(client, TELEGRAM_SUBSCRIPTION);
      break;
    default:
  }
};

export default completeRewardTaskByBalanceReason;
