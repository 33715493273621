/**
 * @const {string}
 * @summary Two steps PP.
 * @description First step is to select packages, second - carousel with card form, benefits and footer.
 */
export const HORIZONTAL_CAROUSEL = 'horizontalCarousel';

/**
 * @const {string}
 * @summary One step PP.
 * @desc Carousel with packages and card form, benefits and footer.
 */
export const ONE_STEP_CAROUSEL = 'oneStepCarousel';

/**
 * @const {string}
 */
export const MOB_UNIFY = 'mobUnify';

/**
 * @const {string}
 */
export const ONE_STEP_CAROUSEL_WITH_PACKAGES_SELECTOR =
  'oneStepCarouselWithPackagesSelector';

/**
 * @const {string}
 */
export const ONE_STEP_CAROUSEL_WITH_PACKAGES_SELECTOR_V2 =
  'oneStepCarouselWithPackagesSelectorV2';

/**
 * @const {string}
 */
export const ONE_STEP_CAROUSEL_WITH_PACKAGES_TABLE =
  'oneStepCarouselWithPackagesTable';

/**
 * @const {string}
 */
export const ONE_STEP_CAROUSEL_WITH_CAROUSEL_PACKAGES =
  'oneStepCarouselWithCarouselPackages';

/**
 * @const {string}
 */
export const ONE_STEP_CLEAR = 'oneStepClear';

/**
 * @const {string}
 */
export const ONE_STEP_CLEAR_2X = 'oneStepClear2x';

/**
 * @const {string}
 */
export const ONE_STEP_TRY = 'oneStepTry';

/**
 * @const {string}
 */
export const ONE_STEP_TRY_2X = 'oneStepTry2x';

/**
 * @const {string}
 */
export const ONE_STEP_CLEAR_FEATURES = 'oneStepClearFeature';

/**
 * @const {string}
 */
export const LIFE_TIME_OFFER = 'lifeTimeOffer';

/**
 * Payment page without packages and motivation
 * @const {string}
 */
export const AGE_VERIFICATION = 'ageVerification';

/**
 * One-line card form with animation
 * @const {string}
 */
export const ONE_LINE_PP = 'oneLinePP';

/**
 * Payment page with two checkboxes, additional package and billing policy for one step.
 * @const {string}
 */
export const ONE_STEP_WITH_POLICY_AGREEMENT = 'oneStepWithPolicyAgreement';

/**
 * Payment page with two checkboxes, additional package and billing policy for two steps.
 * @const {string}
 */
export const TWO_STEP_WITH_POLICY_AGREEMENT = 'twoStepWithPolicyAgreement';

/**
 * Payment page with select package type on first step and ONE_STEP_CLEAR template on second step.
 */
export const TWO_STEP_BCP = 'twoStepWithAdvantagesBCP';

/**
 * Payment page with correct display of x-sale information on membership.
 */
export const TWO_STEP_CLEAR = 'twoStepClear';

/**
 * Payment page with correct display of x-sale information on features
 */
export const TWO_STEP_CLEAR_FEATURES = 'twoStepClearFeature';
