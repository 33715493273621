import React from 'react';

import CURRENT_USER_PHOTOS_QUERY from '@core/user/profile/current/graphql/queries/currentUserPhotos.gql';
import sortPhotosByCreateDate from '@core/user/profile/current/utils/sortPhotosByCreateDate';
import MEDIA_ITEMS_GRID from '@core/user/profile/common/constants/MediaItemsGrid';

import QueryWithErrorBoundary from '@phoenix/graphql/containers/QueryWithErrorBoundary';

import PhotoListPlaceholder from '../../common/components/PhotoListPlaceholder';
import CurrentUserPhotoMotivationListLayout from '../components/CurrentUserPhotoMotivationListLayout';

const CurrentUserPhotoMotivationList = () => (
  <QueryWithErrorBoundary query={CURRENT_USER_PHOTOS_QUERY}>
    {({data, loading}) => {
      if (loading || !data) {
        return <PhotoListPlaceholder grid={MEDIA_ITEMS_GRID.THREE_COLUMNS} />;
      }

      const {
        myUser: {
          profile: {id, photos, gender},
        },
        disapprove,
      } = data;

      return (
        <CurrentUserPhotoMotivationListLayout
          photos={sortPhotosByCreateDate(photos.allPhotos)}
          primaryPhotoId={photos?.primaryPhoto?.id}
          gender={gender}
          disapprovePhoto={disapprove.statuses.photo}
          userId={id}
        />
      );
    }}
  </QueryWithErrorBoundary>
);

export default CurrentUserPhotoMotivationList;
