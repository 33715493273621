import {useMemo, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import startsWith from 'lodash/startsWith';
import last from 'lodash/last';

import useIsPopupOpen from '@core/popup/utils/useIsPopupOpen';
import useEventCallback from '@core/utils/react/useEventCallback';
import useActivityConsumer from '@core/activity/utils/useActivityConsumer';
import ACTIVITIES_QUERY_TYPES from '@core/activity/constants/activitiesQueryTypes';
import {FILTER_TO_PROP_MAP} from '@core/activity/constants/filters';
import FilterType from '@core/activity/constants/FilterType';

import MicrofeaturesContext from '../../common/containers/MicrofeaturesContext';
import {
  TOP_SEARCH_PLACEMENT,
  SEARCH_PLACEMENT,
  ACTIVITY_PLACEMENT,
  USER_PROFILE_PLACEMENT,
  CHAT_PLACEMENT,
  NOTIFICATIONS_PLACEMENT,
} from '../constants/bannersPlacements';

// Export for tests
export const ROUTES = {
  CHAT: '/chat/recipients',
  USER: '/user/view',
  LIKE_GALLERY: '/likeGallery',
  ACTIVITY: '/newsFeed',
  SEARCH: '/search',
};

const getPlacementByPathname = (pathname) => {
  if (startsWith(pathname, ROUTES.CHAT)) return [CHAT_PLACEMENT];

  if (
    startsWith(pathname, ROUTES.USER) ||
    startsWith(pathname, ROUTES.LIKE_GALLERY)
  ) {
    return [USER_PROFILE_PLACEMENT];
  }

  if (startsWith(pathname, ROUTES.ACTIVITY)) return [ACTIVITY_PLACEMENT];

  if (startsWith(pathname, ROUTES.SEARCH)) {
    return [TOP_SEARCH_PLACEMENT, SEARCH_PLACEMENT];
  }

  return [];
};

/**
 * Banners rotation on routes or popup open
 * in case of logic complication - wrap separate pages or lists into MicrofeaturesIterator and remove it from MicrofeaturesProvider
 * and set placements manually (not by route like in getPlacementByPathname)
 */
const MicrofeaturesIterator = ({children}) => {
  const {pathname} = useLocation();
  const isActivityPage = startsWith(pathname, ROUTES.ACTIVITY);
  const placements = getPlacementByPathname(pathname);
  const isPopupOpened = useIsPopupOpen();
  const {loading: activityLoading, ...consumerProps} = useActivityConsumer(
    // Full activity list needed only for ACTIVITY_PLACEMENT
    isActivityPage ? ACTIVITIES_QUERY_TYPES.ALL : false,
  );
  const fullActivityListLoading = isActivityPage ? activityLoading : false;

  // @see MicrofeaturesProvider.js
  const {microfeaturesAvailable, iteratePlacement} =
    useContext(MicrofeaturesContext);

  useMemo(() => {
    // NOTIFICATIONS_PLACEMENT doesn't depend on route, but needs iteration on each notification`s popup opening
    if (isPopupOpened) {
      iteratePlacement(NOTIFICATIONS_PLACEMENT);
    }
  }, [isPopupOpened, iteratePlacement]);

  const iterate = useEventCallback(() => {
    let needToIterate = true;

    placements.forEach((placement) => {
      // To avoid microfeatures rotation on empty list
      if (placement === ACTIVITY_PLACEMENT) {
        const currentFilter = last(pathname.split('/'));

        if (Object.values(FilterType).includes(currentFilter)) {
          needToIterate = Boolean(
            consumerProps[FILTER_TO_PROP_MAP[currentFilter]].length,
          );
        }
      }

      needToIterate && iteratePlacement(placement);
    });
  });

  useMemo(() => {
    if (!microfeaturesAvailable) {
      return;
    }

    iterate();

    // Update on route change or when activity list is loaded (only for ACTIVITY_PLACEMENT)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterate, pathname, microfeaturesAvailable, fullActivityListLoading]);

  return children;
};

MicrofeaturesIterator.propTypes /* remove-proptypes */ = {
  children: PropTypes.node,
};

export default MicrofeaturesIterator;
