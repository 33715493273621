import once from 'lodash/once';
import {combineLatest, fromEvent} from 'rxjs';
import {distinctUntilChanged, filter, startWith} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';

import canShowPopup from '@phoenix/popup/utils/canShowPopup';

import OPEN_SPECIAL_OFFER_POPUP_SUBSCRIPTION from '../graphql/subscriptions/showSpecialOfferPopup.gql';
import isCoinsModel from '../../utils/isCoinsModel';
import {WEEK_SPEAK} from '../constants/specialOfferPopups';
import openSpecialOfferPopup from './openSpecialOfferPopup';
import updateSearchBannersAfterPay from './updateSearchBannersAfterPay';

const startSpecialOfferPopupListener = once(async (client) => {
  const withCoins = await isCoinsModel();

  if (!withCoins) {
    return;
  }

  const popupState$ = fromEvent(document, POPUP_STATE_CHANGE_EVENT).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
  );

  const signal$ = client.subscribe({
    query: OPEN_SPECIAL_OFFER_POPUP_SUBSCRIPTION,
  });

  const routeState$ = client.watchQuery({query: ROUTE_QUERY});

  // Need opening popup only after another popups, and in right routs
  const source$ = combineLatest([signal$, routeState$, popupState$]).pipe(
    filter(
      ([
        ,
        {
          data: {route},
        },
        {detail},
      ]) => {
        return canShowPopup(route, detail);
      },
    ),
    distinctUntilChanged(([prev], [current]) => {
      return (
        prev.data.credits.openSpecialOfferPopup.timestamp ===
        current.data.credits.openSpecialOfferPopup.timestamp
      );
    }),
  );

  source$.subscribe(([{data}]) => {
    const {type} = data.credits.openSpecialOfferPopup;
    if (type === WEEK_SPEAK) {
      openSpecialOfferPopup();
      updateSearchBannersAfterPay(true);
    }
  });
});

export default startSpecialOfferPopupListener;
