import t from '@core/translations/translate';

/**
 * @param {Boolean} cleanCamsAllowed
 * @returns {Object}
 */
const menu = (cleanCamsAllowed) => {
  return {
    icon: 'webcam',
    menuText: cleanCamsAllowed
      ? t('userMenu', 'text.live_chats')
      : t('userMenu', 'text.livecam'),
    headerText: cleanCamsAllowed
      ? t('header', 'title.live_chats')
      : t('header', 'title.livecam'),
    labelText: t('userMenu', 'text.hot'),
  };
};

/**
 * @param {string} webCamData.partner
 * @param {boolean} withProviderName - indicates whether partner name must be shown in button caption
 *
 * @returns {Object}
 */
const user = (partner, withProviderName) => {
  if (withProviderName) {
    return {
      text: t('userListWidget', 'button.join_chat'),
      labelText: t('webcamButton', 'text.livecam_model'),
    };
  }

  return {
    text: t('webcamButton', 'text.join_live_chat'),
    labelText: '',
  };
};

export default {
  menu,
  user,
};
