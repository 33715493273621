import type {FC} from 'react';
import React, {useMemo} from 'react';

import ActivityBookmarkAppearance from '@core/theming/constants/features/ActivityBookmarkAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43668
 */
type ThemedActivityBookmarkProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  ActivityBookmarkAppearance,
  ModuleComponent<ThemedActivityBookmarkProps>
> = (value) => {
  switch (value) {
    case ActivityBookmarkAppearance.AS_PRIMARY_BUTTON:
      return import(
        /* webpackChunkName: 'activityBookmarkPrimaryButton' */ '@phoenix/activity/components/ActivityBookmarkPrimaryButton'
      );
    case ActivityBookmarkAppearance.AS_PAY_BUTTON:
      return import(
        /* webpackChunkName: 'activityBookmarkPayButton' */ '@phoenix/activity/components/ActivityBookmarkPayButton'
      );
    case ActivityBookmarkAppearance.AS_PAY_BUTTON_AND_AI_ASSISTANT:
      return import(
        /* webpackChunkName: 'activityBookmarkWithAIAssistant' */ '@phoenix/activity/containers/ActivityBookmarkPayButtonAndAIAssistant'
      );
    case ActivityBookmarkAppearance.AS_FLOATING_SQUARE_WIDGET:
      return import(
        /* webpackChunkName: 'activityBookmarkSquareWidget' */ '@phoenix/activity/components/ActivityBookmarkFloatingSquareWidget'
      );
    case ActivityBookmarkAppearance.AS_FLOATING_SQUARE_CHAT_WIDGET:
      return import(
        /* webpackChunkName: 'activityBookmarkSquareChatWidget' */ '@phoenix/activity/components/ActivityBookmarkFloatingSquareChatWidget'
      );
    case ActivityBookmarkAppearance.AS_FLOATING_SQUARE_WITH_CUSTOM_NOTIFICATION_POPUP:
      return import(
        /* webpackChunkName: 'activityBookmarkFloatingSquareWithCustomNotificationPopup' */ '@phoenix/activity/components/ActivityBookmarkFloatingSquareWithCustomNotificationPopup'
      );
    case ActivityBookmarkAppearance.AS_FLOATING_SQUARE_WIDGET_AND_HEADING:
      return import(
        /* webpackChunkName: 'activityBookmarkSquareWidgetAndHeading' */ '@phoenix/activity/components/ActivityBookmarkFloatingSquareWidgetAndHeading'
      );
    default:
      /**
       * Yes, all other modifications are equal to "NONE". So no
       * bookmark will be displayed at all.
       */
      return null;
  }
};

/**
 * Appearance-changeable activity bookmark based on theme.
 * In fact - it displays or not it with lazy load.
 * Proxies all props and adds some module-loading dynamic.
 *
 * Custom cache name is used since the same feature is used in different place and
 * it will cause overwriting.
 * @see ThemedActivityPage.js
 */
const ThemedActivityBookmark: FC<ThemedActivityBookmarkProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedActivityBookmarkProps,
        ActivityBookmarkAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="activity"
      param="bookmark"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedActivityBookmark;
