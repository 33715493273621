import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyLikeGallery = lazy(
  () =>
    import(
      /* webpackChunkName: 'likeGallery' */ '@phoenix/likeGallery/components/LikeGalleryPage'
    ),
);

const LikeGalleryChunk = (props) => (
  <SplitChunk component={LazyLikeGallery} {...props} />
);

export default LikeGalleryChunk;
