import React, {Fragment, memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation, Redirect} from 'react-router-dom';
import {useQuery, useReactiveVar} from '@apollo/client';

import translationsLoadedVar from '@core/graphql/vars/translationsLoadedVar';
import logger from '@core/logger';
import Router from '@core/application/containers/Router';
import ResponsiveProvider from '@core/responsive/ResponsiveProvider';
import LocationPaymentParamsProvider from '@core/payment/common/containers/LocationPaymentParamsProvider';
import PaymentBaseDataLoader from '@core/payment/common/containers/PaymentBaseDataLoader';
import interaction from '@core/websocket/interaction';
import GraphQLProvider from '@core/graphql/containers/GraphQLProvider';
import CSSAppSizeDetector from '@core/application/containers/CSSAppSizeDetector';
import PageDataActualization from '@core/application/containers/PageDataActualization';
import TranslationsLoader from '@core/translations/TranslationsLoader';
import useBootstrapBabciaTrack from '@core/tracking/babcia/utils/useBootstrapBabciaTrack';
import BabciaNotifications from '@core/tracking/babcia/containers/BabciaNotifications';
import isPayUrl from '@core/utils/url/isPayUrl';
import routeWithPopupErrorVar from '@core/graphql/vars/routeWithPopupErrorVar';
import PopupProvider from '@core/popup/containers/PopupProvider';

import useFacebookReload from '@phoenix/application/utils/useFacebookReload';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import processAfterExternal3DSecurePay from '@phoenix/payment/widgets/threeDSecure/utils/processAfterExternal3DSecurePay';
import useRedirectToSuccessPage from '@phoenix/payment/common/utils/useRedirectToSuccessPage';

import '../utils/navigationInterceptor';
import '../utils/trackingListeners';
import getAppDataVariables from '../utils/getAppDataVariables';
import APP_DATA_QUERY from '../graphql/queries/appData.gql';
import useTimezoneUpdate from '../utils/useTimezoneUpdate';
import useDeferredPageAvailable from '../deferredPage/useDeferredPageAvailable';
import TranslationsLoadedSetter from './TranslationsLoadedSetter';
import MainLayout from '../components/MainLayout';
import PaymentLayout from '../components/PaymentLayout';

const AppInner = memo(
  ({
    loading,
    isAuthorizedZone,
    isBanned,
    isSiteClosed,
    isWeb2AppSite,
    useClientStorage,
    userId,
    dataLoading,
    remarketingConceptName,
  }) => {
    const {pathname} = useLocation();

    const {deferredPageAvailable} = useDeferredPageAvailable();

    const isAllowed = Boolean(
      !loading && isAuthorizedZone && !isBanned && !isSiteClosed,
    );

    const isPaymentPage = isPayUrl(pathname);

    /**
     * Init user context for Logger if user is authorized
     */
    useEffect(() => {
      isAllowed && userId && logger.setUserContext({id: userId});
    }, [isAllowed, userId]);

    /**
     * Send to server timezone offset of user's browser
     */
    useTimezoneUpdate(isAllowed && deferredPageAvailable);

    /**
     * Start interaction service
     */
    useEffect(() => {
      isAllowed && interaction.connect();
    }, [isAllowed]);

    useBootstrapBabciaTrack(userId, loading);

    const baseProps = {
      loading,
      isAllowed,
      deferredPageAvailable,
      isBanned,
      isAuthorizedZone,
      isSiteClosed,
      isWeb2AppSite,
    };

    return (
      <PopupProvider>
        {isPaymentPage ? (
          <PaymentLayout {...baseProps} />
        ) : (
          <MainLayout
            {...baseProps}
            useClientStorage={useClientStorage}
            dataLoading={dataLoading}
            remarketingConceptName={remarketingConceptName}
          />
        )}
      </PopupProvider>
    );
  },
);

AppInner.propTypes /* remove-proptypes */ = {
  loading: PropTypes.bool.isRequired,
  isAuthorizedZone: PropTypes.bool.isRequired,
  isBanned: PropTypes.bool.isRequired,
  isSiteClosed: PropTypes.bool.isRequired,
  isWeb2AppSite: PropTypes.bool.isRequired,
  useClientStorage: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  dataLoading: PropTypes.bool.isRequired, // Remarketing offer backbone model and AppData query loading flag
  remarketingConceptName: PropTypes.string,
};

const AppDataLoader = () => {
  const translationsLoaded = useReactiveVar(translationsLoadedVar);
  const routeWithPopupError = useReactiveVar(routeWithPopupErrorVar);
  const shouldReload = useFacebookReload();

  const {loading, data, error} = useQuery(APP_DATA_QUERY, {
    variables: getAppDataVariables(),
    errorPolicy: 'all',
    skip: shouldReload,
  });

  if (shouldReload) {
    return null;
  }

  if ((!loading && !data) || routeWithPopupError) {
    return (
      <ErrorBoundary
        error={error || routeWithPopupError}
        // For case when AppDataQuery fails, but TranslationsLoader still loading.
        {...(!translationsLoaded && {
          errorTitle: 'Something went wrong.',
          errorText: 'Please refresh page or try again later.',
        })}
      />
    );
  }

  return (
    <AppInner
      loading={loading || !translationsLoaded}
      isAuthorizedZone={data?.isAuthorizedZone || false}
      isBanned={data?.isBanned !== false}
      isSiteClosed={data?.site?.isSiteClosed !== false}
      isWeb2AppSite={data?.site?.isWeb2AppSite !== false}
      useClientStorage={
        data?.userFeatures?.coregistrationUseClientSessionStorage || false
      }
      userId={data?.myUser?.id}
      dataLoading={loading}
      remarketingConceptName={
        data?.remarketing?.paymentPopup?.conceptInfo?.name
      }
    />
  );
};

const AppContent = () => {
  const redirectUrl = useRedirectToSuccessPage();

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Fragment>
      <CSSAppSizeDetector />
      <BabciaNotifications />
      <PageDataActualization />

      {
        // LocationPaymentParamsProvider is used here, because it's difficult to provide
        // params in another way for components rendered by `PopupService.openPopup` or `useToolbar`.
      }
      <LocationPaymentParamsProvider>
        <PaymentBaseDataLoader
          processExternalPay={processAfterExternal3DSecurePay}
        />
        <AppDataLoader />
      </LocationPaymentParamsProvider>

      <TranslationsLoader>
        {(loaded) =>
          // this strange logic is used instead of rendering AppDataLoader here to avoid remounting.
          loaded && <TranslationsLoadedSetter />
        }
      </TranslationsLoader>
    </Fragment>
  );
};

/**
 * Main application root react component.
 * When we decide to drop 'AppView' we must place this somewhere in root.
 * Also, until we haven't dropped 'AppView' - we must take in 'sync' those layout with PUG templates
 */
const App = () => (
  <ResponsiveProvider>
    <GraphQLProvider>
      <Router>
        <AppContent />
      </Router>
    </GraphQLProvider>
  </ResponsiveProvider>
);

export default App;
