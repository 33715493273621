import React from 'react';

import {getClientInstance} from '@core/graphql/client';
import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import GiftsPopup from '../containers/GiftsPopup';
import GIFTS_QUERY from '../graphql/queries/gifts.gql';

/**
 * Open gift popup
 * @param {string} userId
 * @param {string} placement
 */
const openGiftsPopup = (userId, placement) =>
  getClientInstance()
    .query({query: GIFTS_QUERY, variables: {placement}})
    .then(({data}) => {
      PopupService.openPopup(
        <GiftsPopup
          userId={userId}
          gifts={data.gifts.list}
          close={PopupService.closePopup}
        />,
        {
          small: true,
          trackingName: 'giftPopup',
          autoLoadTracking: true,
          priority: PopupPriority.LOW,
        },
      );
    });

export default openGiftsPopup;
