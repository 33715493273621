import VerificationStatus from '../constants/verificationStatus';

/**
 * Checks that personalIdentity verification is required for current user and he hasn't verified himself yet
 * @param {boolean} isRequired for current user
 * @param {number|string} verificationStatus
 * @param {boolean} isAdmin - is target user admin
 * @param {boolean} isModerator - is target user moderator
 * @returns {boolean} - is chat disabled
 */
const isVerificationRequired = (
  isRequired,
  verificationStatus,
  isAdmin = false,
  isModerator = false,
) => {
  if (isAdmin || isModerator) {
    return false;
  }

  return (
    isRequired &&
    parseInt(verificationStatus, 10) !== VerificationStatus.APPROVED
  );
};

export default isVerificationRequired;
