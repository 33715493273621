import React, {lazy, Suspense} from 'react';

import PopupService from '@core/popup/utils/PopupService';

import {Loader} from '@phoenix/ui';

import groupKey from '../constants/popupGroupKey';

const FreeMessagesPopupContent = lazy(
  () =>
    import(
      /* webpackChunkName: 'freeMessagesRewardsPopup' */ '../containers/FreeMessagesRewardsPopup'
    ),
);

const openFreeMessagesRewardsPopup = ({userId} = {}) => {
  PopupService.openPopup(
    <Suspense fallback={<Loader small />}>
      <FreeMessagesPopupContent userId={userId} />
    </Suspense>,
    {
      groupKey,
      small: true,
      trackingName: 'freeMessagesRewards',
    },
  );
};

export default openFreeMessagesRewardsPopup;
