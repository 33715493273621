import React from 'react';
import {useQuery} from '@apollo/client';

import t from '@core/translations/translate';
import VIDEO_UPLOAD_SETTINGS_QUERY from '@core/mediaUpload/graphql/queries/videoUploadSettings.gql';

import {PlaceholderInput} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

const WebcamVideoRequirements = () => {
  const {data, loading, error} = useQuery(VIDEO_UPLOAD_SETTINGS_QUERY);

  if (loading) {
    return <PlaceholderInput />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  const {
    userFeatures: {
      userVideo: {
        settings: {minLenght, maxLenght, limit},
      },
    },
  } = data;

  return t('popupVideoUploadMotivation', 'text.limit_videos', {
    '{maximumVideoAmount}': limit,
    '{minimumVideoDuration}': minLenght,
    '{maximumVideoDuration}': maxLenght,
  });
};

export default WebcamVideoRequirements;
