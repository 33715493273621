/**
 * Recipient id with empty dialog try to send message and get error freeMessageNotEnoughMessages from backend.
 * Remember only last id such user.
 */
export const RECIPIENT_ID_EMPTY_DIALOG_WHERE_FREE_MESSAGES_ENDED =
  'recipientIdEmptyDialogFreeMessagesEnded';

/**
 * Recipient id where number of messages left is zero. It reset when go to any other page from current dialog.
 */
export const RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED =
  'recipientIdFreeMessagesEnded';
