import React from 'react';

import {ICONS} from '../../icons/constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesPopupAnimationLayout from './MicrofeaturesPopupAnimationLayout';
import css from '../styles/MicrofeaturesAnimationTopInChat.css';

const ICONS_COUNT = 5;

const MicrofeaturesAnimationTopInChat = () => (
  <MicrofeaturesPopupAnimationLayout
    iconsCount={ICONS_COUNT}
    iconType={ICONS[MICROFEATURES.TOP_IN_CHAT]}
    className={css.icon}
  />
);

export default MicrofeaturesAnimationTopInChat;
