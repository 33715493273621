import {localStorage as storage} from '@core/utils/storage';

import {CHAT, PAYMENT_PAGE, USER} from '../constants/motivationTypes';
import getStorageKeyForInAppMotivationPopup from './getStorageKeyForInAppMotivationPopup';

const USER_VISIT_COUNT = 3;
const PAYMENT_VISIT_COUNT = 2;

export const USER_VIEW_ROUTE = '/user/view';
export const CHAT_ROUTE = '/chat/recipients';
export const PAY_ROUTE = '/pay';
export const FUNNEL_ROUTE = '/funnel';
export const SEARCH_ROUTE = '/search';

/**
 * Stub flag to define a rm popup on search that also triggers the action
 * @type {boolean}
 */
let doubleCheck = false;

/**
 *
 * @param {String} key
 * @param {Number} visitLimit
 * @returns {null|*}
 */
const checkVisitCount = (key, visitLimit) => {
  const visitCount =
    Number(storage.getItem(getStorageKeyForInAppMotivationPopup(key), 0)) + 1;

  if (visitCount === visitLimit) {
    storage.setItem(getStorageKeyForInAppMotivationPopup(key), visitCount);

    return key;
  }

  if (visitCount < USER_VISIT_COUNT) {
    storage.setItem(getStorageKeyForInAppMotivationPopup(key), visitCount);
  }
  return null;
};

const checkChat = (current) => {
  if (current.indexOf(CHAT_ROUTE) !== -1) {
    const isVisited = storage.getItem(
      getStorageKeyForInAppMotivationPopup(CHAT),
      false,
    );

    if (!isVisited) {
      storage.setItem(getStorageKeyForInAppMotivationPopup(CHAT), true);

      return CHAT;
    }
  }

  return null;
};

const checkCloseUser = (current, previous) => {
  if (
    previous &&
    previous !== current &&
    previous.indexOf(`${USER_VIEW_ROUTE}/`) !== -1
  ) {
    if (!doubleCheck) {
      doubleCheck = true;

      return checkVisitCount(USER, USER_VISIT_COUNT);
    }
  } else {
    doubleCheck = false;
  }

  return null;
};

const checkPP = (current, previous) => {
  /**
   * We must prevent to re-trigger action due adding
   * search params to search route for correct count
   * when return from PP where previous route was RM popup or Paid Funnel
   */
  if (
    current.includes(SEARCH_ROUTE) &&
    (!current.includes(`ageFrom`) ||
      !current.includes(`fragmentToken`) ||
      !current.includes(`fragmentBrowser`) ||
      !current.includes(`fragmentKey`))
  ) {
    return null;
  }

  if (
    previous &&
    previous !== current &&
    previous.indexOf(`${PAY_ROUTE}/`) !== -1
  ) {
    return checkVisitCount(PAYMENT_PAGE, PAYMENT_VISIT_COUNT);
  }

  return null;
};

const getMotivationRouteChecker = (current, previous) =>
  checkChat(current) ||
  checkCloseUser(current, previous) ||
  checkPP(current, previous);

export default getMotivationRouteChecker;
