import type {ComponentType} from 'react';

import UIKit from '@core/theming/constants/UIKit';

/**
 * @todo after migration of all ui/theme (FE-42347) components to TS UIKitModule will be ComponentType
 *        union aka ComponentType<props 1> | ComponentType<props 2> | ...
 */
type UIKitModule = ComponentType<any>;

/**
 * We avoid using dynamic imports like `import(`./theme/${theme}`)` since webpack works
 * better with static imports. For e.g. in case of dynamic import it creates.
 */
const UI_KIT_MODULES: Record<
  UIKit,
  () => Promise<Record<string, UIKitModule>>
> = {
  [UIKit.MATERIAL]: () =>
    import(
      /* webpackChunkName: 'materialUIKit' */ '@phoenix/ui/theme/material'
    ),
  [UIKit.FRESH]: () =>
    import(/* webpackChunkName: 'freshUIKit' */ '@phoenix/ui/theme/fresh'),
  [UIKit.MANEKO]: () =>
    import(/* webpackChunkName: 'manekoUIKit' */ '@phoenix/ui/theme/maneko'),
  [UIKit.FORCE]: () =>
    import(/* webpackChunkName: 'forceUIKit' */ '@phoenix/ui/theme/force'),
  [UIKit.GLASS]: () =>
    import(/* webpackChunkName: 'glassUIKit' */ '@phoenix/ui/theme/glass'),
  [UIKit.LOVEIS]: () =>
    import(/* webpackChunkName: 'loveisUIKit' */ '@phoenix/ui/theme/loveis'),
  [UIKit.MOON]: () =>
    import(/* webpackChunkName: 'moonUIKit' */ '@phoenix/ui/theme/moon'),
  [UIKit.SKY]: () =>
    import(/* webpackChunkName: 'skyUIKit' */ '@phoenix/ui/theme/sky'),
};

export default UI_KIT_MODULES;
