import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import MEDIA_ITEMS_GRID from '@core/user/profile/common/constants/MediaItemsGrid';

import {Card} from '@phoenix/ui';

import css from '../styles/MediaWrapper.css';

/**
 * Wrapper of media components (VideoItem, PhotoItem etc.) to make responsive square.
 */
const MediaWrapper = forwardRef(
  (
    {
      children,
      grid = MediaWrapper.GRID.FOUR_COLUMNS,
      className = '',
      dark = false, // @see VideoItem
      ...props
    },
    ref,
  ) => (
    <Card
      light
      onPageBackground
      shadowLevel={0}
      className={cn(css[grid], css.item, className)}
      innerRef={ref}
      {...props}
    >
      <div className={cn(css.wrapper, dark && css.dark)}>{children}</div>
    </Card>
  ),
);

MediaWrapper.GRID = MEDIA_ITEMS_GRID;

MediaWrapper.propTypes /* remove-proptypes */ = {
  dark: PropTypes.bool,
  className: PropTypes.string,
  grid: PropTypes.oneOf(Object.values(MediaWrapper.GRID)),
  children: PropTypes.node.isRequired,
};

export default MediaWrapper;
