import {useReactiveVar} from '@apollo/client';

import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';

const useIsKonbiniActiveTab = (): boolean => {
  const activeMethodTab = useReactiveVar(activeMethodTabVar);

  return activeMethodTab === PAYMENT_METHODS.KONBINI;
};

export default useIsKonbiniActiveTab;
