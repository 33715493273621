import React from 'react';
import {combineLatest, fromEvent, of} from 'rxjs';
import {
  buffer,
  delay,
  filter,
  distinctUntilChanged,
  map,
  tap,
  startWith,
  switchMap,
} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import PopupPriority from '@core/popup/constants/PopupPriority';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';
import isInRouteList from '@core/utils/routing/isInRouteList';
import isPayUrl from '@core/utils/url/isPayUrl';
import PopupService from '@core/popup/utils/PopupService';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

import {
  REWARD_REASONS,
  REWARD_FOR_PHOTO_UPLOAD,
} from '@phoenix/credits/constants/balanceChangeReason';
import CREDITS_BALANCE_INCREASE_SUBSCRIPTION from '@phoenix/credits/graphql/subscriptions/creditsBalanceIncrease.gql';
import completeRewardTaskByBalanceReason from '@phoenix/freeCoins/utils/completeRewardTaskByBalanceReason';

import FreeCoinsRewardPopup from '../containers/FreeCoinsRewardPopup';
import FREE_COINS_AVAILABLE_QUERY from '../graphql/queries/freeCoinsAvailable.gql';
import FREE_COINS_MEDIA_UPLOAD_COMPLETED_QUERY from '../graphql/queries/freeCoinsMediaUploadCompleted.gql';

let startedListeners = false;

/**
 * @const {number}
 * For avoiding strange and buggy animations on popup appearance during
 * route change (and as you know we have too many calculations on route change),
 * we delay them.
 */
const DELAY_FOR_EMISSION = 3000;

/**
 * @param {Array} accumulator
 * @param {Object} data
 * @returns {Array}
 */
const groupReducer = (accumulator, data) => {
  const index = accumulator.findIndex(
    (accumulated) => accumulated.reason === data.reason,
  );

  if (index === -1) {
    accumulator.push(data);
  } else {
    accumulator[index].amount += data.amount;
  }

  return accumulator;
};

/**
 * Subscription for showing popups after certain events, where
 * user receives some reward for some action.
 * @see SubscriptionsStarter.js
 */
const startFreeCoinsListener = async (
  client,
  delayForEmission = DELAY_FOR_EMISSION,
) => {
  // Avoiding multiple resubscribes in case of unpredictable cache updates.
  if (startedListeners) {
    return;
  }

  startedListeners = true;

  // Get info about availability for avoiding unneeded subscription.
  const {data: availableData} = await client.query({
    query: FREE_COINS_AVAILABLE_QUERY,
  });

  if (!availableData.credits.rewardForTasks.enabled) {
    return;
  }

  const popupState$ = fromEvent(document, POPUP_STATE_CHANGE_EVENT).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
  );

  const routeState$ = client.watchQuery({query: ROUTE_QUERY});

  const mediaUploadCompleted$ = client.watchQuery({
    query: FREE_COINS_MEDIA_UPLOAD_COMPLETED_QUERY,
  });

  const source$ = combineLatest([
    client.subscribe({query: CREDITS_BALANCE_INCREASE_SUBSCRIPTION}),
    routeState$,
    popupState$,
  ]);

  const bufferBy$ = source$.pipe(
    filter(
      ([, {data}, {detail}]) =>
        !isPayUrl(data.route.current) &&
        !isInRouteList(ROUTES_WITH_POPUP, data.route.current) &&
        !detail.hasQueue,
    ),
    // Bad practice, but RxJS doesn't provide "instant emiting buffer". Inspired by:
    // @see https://stackoverflow.com/questions/46760805/rxjs-buffer-until-stream-is-true
    delay(0),
  );

  source$
    .pipe(
      // Normalize incoming data, and remove route and popup related data
      // since is required only for triggering new data.
      map(([{data}]) => data.credits.balanceIncrease),
      // In terms of 'free coins' functionality we support only such list of 'reasons'
      filter((event) => REWARD_REASONS.includes(event.reason)),
      // Since route or popup state can change - we should filter such cases, passing only
      // real happened events. And timestamp of event is the best ID ever seen :)
      distinctUntilChanged(
        (prevEvent, currentEvent) => prevEvent.time === currentEvent.time,
      ),
      // For certain event types we should query additional data.
      tap((event) => {
        if (REWARD_FOR_PHOTO_UPLOAD === event.reason) {
          // Update media upload, fetch only once. Because reason addPhoto trigger when approved the first photo.
          mediaUploadCompleted$.refetch();
        }

        completeRewardTaskByBalanceReason(client, event.reason);
      }),
      // If we can't show popup, buffer if for future usage.
      buffer(bufferBy$),
      // If there is nothing, stop any other calculations
      filter((events) => events.length),
      // Group events by category (reason), increase total 'amount' for all category.
      map((events) => events.reduce(groupReducer, [])),
      switchMap((events) => of(...events)),
      // Delay showing each event, making it more user friendly.
      delay(delayForEmission),
    )
    .subscribe(({reason, amount}) => {
      // Add popup with 'priority' = LOW option
      PopupService.openPopup(
        <FreeCoinsRewardPopup reason={reason} amount={amount} />,
        {
          small: true,
          // Add such popups back in stack, for avoiding overlapping on active one
          priority: PopupPriority.LOW,
          trackingName: 'freeCoinsReward',
          autoLoadTracking: true,
        },
      );
    });
};

export default startFreeCoinsListener;
