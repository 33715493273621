import {localStorage} from '@core/utils/storage';

import matchCommunicationUserIdsStorageKey from '../constants/matchCommunicationUserIdsStorageKey';

const updateMatchCommunicationUserIds = (userId, currentUserId) => {
  const userIdsStorageKey = matchCommunicationUserIdsStorageKey(currentUserId);
  if (!userId) {
    localStorage.removeItem(userIdsStorageKey);
    return [];
  }

  const matchCommunicationUserIds = localStorage.getItem(userIdsStorageKey, []);

  const isNewUserId = !matchCommunicationUserIds.includes(userId);
  const newMatchCommunicationUserIds = isNewUserId
    ? [userId, ...matchCommunicationUserIds]
    : matchCommunicationUserIds;

  if (isNewUserId) {
    localStorage.setItem(userIdsStorageKey, newMatchCommunicationUserIds);
  }

  return newMatchCommunicationUserIds;
};

export default updateMatchCommunicationUserIds;
