import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import FreeTrialSuccessPaymentPopup from '../containers/FreeTrialSuccessPaymentPopup';

const openFreeTrialSuccessPaymentPopup = () => {
  PopupService.openPopup(<FreeTrialSuccessPaymentPopup />, {
    small: true,
    trackingName: 'freeTrialSuccessPaymentComplete',
    autoLoadTracking: true,
  });
};

export default openFreeTrialSuccessPaymentPopup;
