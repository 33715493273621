import type {ReactEventHandler} from 'react';
import React, {forwardRef, useEffect, memo, useCallback} from 'react';
import cn from 'classnames';

import getUserAgentParser from '@core/utils/getUserAgentParser';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import useForwardedRefFallback from '@core/utils/react/useForwardedRefFallback';

import css from '../styles/Video.css';

const iOsDevices = ['iOS', 'Mac OS'];
const VIDEO_TAG_NAME = 'VIDEO';

const fixMutedAttribute = iOsDevices.includes(
  getUserAgentParser().getOS().name,
);

export enum VideoScaling {
  COVER = 'cover',
  CONTAIN = 'contain',
  NONE = 'none',
}

export enum VideoPreload {
  AUTO = 'auto',
  METADATA = 'metadata',
  NONE = 'none',
}

/* eslint-disable react/no-unused-prop-types */
type VideoProps = {
  url: string;
  previewUrl?: string;
  scaling?: VideoScaling;
  controls?: boolean;
  autoPlay?: boolean;
  onVideoEnded?: ReactEventHandler<HTMLVideoElement>;
  className?: string;
  shadow?: boolean;
  preload?: VideoPreload;
  onPlay?: () => void;
  loop?: boolean;
  playsInline?: boolean;
  muted?: boolean;
  onReady?: () => void;
  fullScreen?: boolean;
  onTimeUpdate?: () => void;
  onContextMenu?: () => void;
  onPause?: () => void;
  trackingName?: string;
  onClick?: () => void;
  onLoadStart?: () => void;
  onCanPlay?: () => void;
  withTouchMoveBehaviourFix?: boolean;
  disableRemotePlayback?: boolean;
  disablePictureInPicture?: boolean;
  tabIndex?: number;
};

const Video = memo(
  forwardRef<HTMLVideoElement, VideoProps>(
    (
      {
        url,
        previewUrl,
        scaling = VideoScaling.CONTAIN,
        controls,
        autoPlay,
        onVideoEnded,
        className = '',
        shadow,
        preload = VideoPreload.METADATA,
        onPlay,
        loop = false,
        onReady,
        fullScreen = false,
        onTimeUpdate,
        onContextMenu,
        onPause,
        trackingName,
        onClick,
        onLoadStart,
        onCanPlay,
        withTouchMoveBehaviourFix = true,
        disableRemotePlayback,
        disablePictureInPicture,
        tabIndex,
        // include other video props like onError, e.g
        ...otherProps
      },
      externalRef,
    ) => {
      const classNames = cn(
        css.video,
        fullScreen ? css.fullScreen : css.parentWidth,
        css[scaling],
        shadow && css.shadow,
        className,
      );

      const ref = useForwardedRefFallback<HTMLVideoElement>(externalRef);

      useEffect(() => {
        if (withTouchMoveBehaviourFix) {
          // Can't use React's onTouchMove because it adds {passive: true} event listener.
          ref.current.addEventListener(
            'touchmove',
            (e) => {
              if (document.fullscreenElement?.tagName === VIDEO_TAG_NAME) {
                e.preventDefault();
              }
            },
            {passive: false},
          );
        }
      }, [ref, withTouchMoveBehaviourFix]);

      const onFinish = useCallback(
        (event) => {
          onVideoEnded?.(event);

          if (loop) {
            ref.current.currentTime = 0;
            ref.current.play();
          }
        },
        [ref, onVideoEnded, loop],
      );

      if (fixMutedAttribute && autoPlay) {
        const attributes = ['autoplay', 'playsinline', 'muted'];

        if (previewUrl) {
          attributes.push(`poster="${previewUrl}"`);
        }

        if (disableRemotePlayback) {
          attributes.push('disableRemotePlayback');
        }

        if (disablePictureInPicture) {
          attributes.push('disablePictureInPicture');
        }

        if (tabIndex) {
          attributes.push(`tabindex="${tabIndex}"`);
        }

        // Fix muted attribute for IOS. More info https://github.com/facebook/react/issues/6544
        return (
          <AddBabciaUBTracking trackingName={trackingName}>
            {/* It's difficult to use tracking on `video` itself in this case, so track wrapper: */}
            {/* eslint-disable-next-line local-rules/no-non-clickable-tracking */}
            <div
              ref={(element) => {
                const videoElement = element?.children[0] as HTMLVideoElement;

                if (ref) {
                  ref.current = videoElement;
                }

                if (videoElement) {
                  if (onPlay) videoElement.onplay = onPlay;
                  if (onVideoEnded || loop) videoElement.onended = onFinish;
                  if (onReady) videoElement.onloadedmetadata = onReady;
                  if (onTimeUpdate) videoElement.ontimeupdate = onTimeUpdate;
                  if (onClick) videoElement.onclick = onClick;
                  if (onPause) videoElement.onpause = onPause;
                  if (onContextMenu) videoElement.oncontextmenu = onContextMenu;
                  if (onLoadStart) videoElement.onloadstart = onLoadStart;
                  if (onCanPlay) videoElement.oncanplay = onCanPlay;
                }
              }}
              className={classNames}
              dangerouslySetInnerHTML={{
                __html: `<video class="${classNames}" preload="${preload}" src="${url}" ${attributes.join(
                  ' ',
                )}></video>`,
              }}
            />
          </AddBabciaUBTracking>
        );
      }

      return (
        <AddBabciaUBTracking trackingName={trackingName}>
          <video
            ref={ref}
            className={classNames}
            src={url}
            controls={controls}
            controlsList="nodownload"
            poster={previewUrl}
            preload={preload}
            onClick={onClick}
            autoPlay={autoPlay}
            playsInline={!controls}
            muted={autoPlay}
            onEnded={onFinish}
            onPlay={onPlay}
            onTimeUpdate={onTimeUpdate}
            onPause={onPause}
            onContextMenu={onContextMenu}
            onLoadStart={onLoadStart}
            onCanPlay={onCanPlay}
            disableRemotePlayback={disableRemotePlayback}
            disablePictureInPicture={disablePictureInPicture}
            /**
             * Use onloadedmetadata like an indicator of ready to watch,
             * because some browsers start loading video after start to play
             */
            onLoadedMetadata={onReady}
            {...otherProps}
          />
        </AddBabciaUBTracking>
      );
    },
  ),
);

export default Video;
