import React from 'react';
import {useQuery} from '@apollo/client';

import usePaymentParams from '@core/payment/common/utils/usePaymentParams';

import {TextMuted} from '@phoenix/typography';

import PAYMENT_ADDITIONAL_DETAILS_QUERY from '../graphql/queries/paymentAdditionalDetails.gql';

/**
 * TODO: temporary component, should be removed after task BU-170788
 * (this text will be moved to payment.paymentFooter.blockDataMap.packageDescriptions)
 */
const PaymentAdditionalDetails = () => {
  const {action, via, prevVia} = usePaymentParams();
  const {data} = useQuery(PAYMENT_ADDITIONAL_DETAILS_QUERY, {
    variables: {action, via, prevVia},
  });

  const description =
    data?.payment?.packagesData?.methodTabs?.[0]?.packages?.[0]
      ?.packageDescription;

  return description ? <TextMuted small>{description}</TextMuted> : null;
};

export default PaymentAdditionalDetails;
