import getHistory from '@core/application/utils/getHistory';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import cachedPurchasedPackage from '@core/payment/common/utils/cachedPurchasedPackage';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import PopupService from '@core/popup/utils/PopupService';
import type {PaymentDataResponse} from '@core/payment/payProcess/types/paymentFlow';

import {OPEN_PP_POPUP_WEEK_SPEAK} from '@phoenix/coins/buyCoins/constants/vias';
import {WEEK_SPEAK} from '@phoenix/coins/specialOffer/constants/specialOfferPopups';
import updateSearchBannersAfterPay from '@phoenix/coins/specialOffer/utils/updateSearchBannersAfterPay';
import openCoinsSuccessPopup from '@phoenix/coins/utils/openCoinsSuccessPopup';

const getCoinsSpecialOfferStepsAfterPayment = (
  paymentResponse: PaymentDataResponse,
) => {
  PopupService.closePopup();
  PaymentPageSuccessOrders.clear();

  if (paymentResponse.failPageLogic) {
    // When we got Decline we redirect to PP and reset Cached package
    const purchasedPackage = cachedPurchasedPackage.get();
    getHistory().push(
      generatePayUrl({
        stage: PAYMENT_ACTIONS.CREDIT,
        urlParams: {
          via: paymentResponse.failPageLogic,
          prevVia: OPEN_PP_POPUP_WEEK_SPEAK,
          stockId: purchasedPackage.stockId,
        },
      }),
    );
  }

  if (paymentResponse.status) {
    updateSearchBannersAfterPay();
    openCoinsSuccessPopup({type: WEEK_SPEAK});
  }
};

export default getCoinsSpecialOfferStepsAfterPayment;
