import {localStorage} from '@core/utils/storage';

const STORAGE_KEY = 'upsellPopup';

/**
 * @param {String} id
 * @return {String}
 */
const getStorageKey = (id) => `${STORAGE_KEY}:${id}`;

/**
 * @param {String} id
 * @return {String|Null}
 */
export const getCacheData = (id) => localStorage.getItem(getStorageKey(id));

/**
 * @param {String} id
 */
export const clearCachedData = (id) =>
  localStorage.removeItem(getStorageKey(id));

/**
 * @param {String} id
 * @param {Object} data
 */
export const cacheData = (id, data) => {
  clearCachedData(id);
  localStorage.setItem(getStorageKey(id), data);
};
