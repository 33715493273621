import React from 'react';
import PropTypes from 'prop-types';

import MediaPreviewLayout from './MediaPreviewLayout';
import FilePreview from '../containers/FilePreview';

const MediaPreview = ({blob, ...props}) => {
  return (
    <MediaPreviewLayout {...props}>
      <FilePreview blob={blob} controls scaling={FilePreview.SCALING.CONTAIN} />
    </MediaPreviewLayout>
  );
};

MediaPreview.propTypes /* remove-proptypes */ = {
  blob: PropTypes.instanceOf(window.Blob).isRequired,
};

export default MediaPreview;
