import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import PersonalIdentityByDemandFailPopup from '../containers/PersonalIdentityByDemandFailPopup';

const openPersonalIdentityByDemandFailPopup = (onClose) => {
  PopupService.openPopup(<PersonalIdentityByDemandFailPopup />, {
    small: true,
    trackingName: 'personalIdentityByDemandFail',
    autoLoadTracking: true,
    showCloseButton: false,
    onClose,
  });
};

export default openPersonalIdentityByDemandFailPopup;
