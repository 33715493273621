import {gotoUrl} from '@core/utils/url';

import openFreeFeaturePopup from '@phoenix/freeFeaturePopup';
import FreeFeatureNames from '@phoenix/freeFeaturePopup/constants/freeFeatureNames';

import EXTRA_PRIORITY_MAN_EXPIRE_QUERY from './graphql/queries/extraPriorityManExpire.gql';

/**
 * Open popup with statistic of usage free Priority Man feature.
 * If there is no statistic by any reason, don't open popup.
 * @param {ApolloClient} client
 * @param {string} type
 * @return {Promise<void>}
 */
const expireAction = async ({client, type}) => {
  /** Feature name need for get statistics */
  const featureName = type.substring(0, type.indexOf('FeatureEnds'));

  const {
    data: {
      extraPopups: {
        priorityMan: {statistic},
      },
    },
  } = await client.query({
    query: EXTRA_PRIORITY_MAN_EXPIRE_QUERY,
    variables: {featureName},
  });

  if (!statistic) return;

  openFreeFeaturePopup({
    name: FreeFeatureNames.PRIORITY_MAN_STATISTIC,
    onActionClick: () => gotoUrl(statistic.paymentUrl),
    options: {
      ...statistic,
      withPopupName: true,
    },
  });
};

export default expireAction;
