import React from 'react';
import {useQuery, useReactiveVar} from '@apollo/client';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

import {isDescriptorUrl} from '@core/utils/url';
import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import EMPTY_ROUTES_FOR_REDIRECT from '@core/application/constants/emptyRoutesForRedirect';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import {getAllHashedLinksList} from '@core/application/utils/hashedStaticLinks';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';
import BASE_ROUTES from '@core/application/constants/routes';
import SearchPageAppearance from '@core/theming/constants/features/SearchPageAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import ROUTES from '@phoenix/application/constants/routes';

import isSpacedActionVar from '../graphql/vars/isSpacedActionVar';
import FOOTER_QUERY from '../graphql/queries/footer.gql';
import FooterLayout from '../components/FooterLayout';

const WITHOUT_FOOTER_ROUTES = [
  ...ROUTES_WITH_POPUP,
  ...FULLSCREEN_ROUTES,
  ...EMPTY_ROUTES_FOR_REDIRECT,
];

const MAP_WITHOUT_FOOTER_ROUTES = WITHOUT_FOOTER_ROUTES.map((route) => ({
  name: route,
}));

const HASHED_LINKS = getAllHashedLinksList().map((link) => ({
  name: link,
}));

/**
 * Routes, where footer must be hidden
 */
export const DISABLED_ROUTES = {
  WEB: [
    {name: BASE_ROUTES.PAY},
    {name: BASE_ROUTES.GET_THE_APP},
    {name: '/notFound'},
    {name: BASE_ROUTES.FORBIDDEN},
    {name: ROUTES.DISCREET_PROFILES, exact: true},
    ...MAP_WITHOUT_FOOTER_ROUTES,
  ],
  MOB: [
    {name: BASE_ROUTES.PAY},
    {name: BASE_ROUTES.GET_THE_APP},
    {name: '/notFound'},
    {name: BASE_ROUTES.FORBIDDEN},
    {name: '/staticPage'},
    ...HASHED_LINKS,
    {name: '/rooms/join'},
    {name: '/chat/with'},
    {name: ROUTES.REELS},
    {name: ROUTES.DISCREET_PROFILES, exact: true},
    ...MAP_WITHOUT_FOOTER_ROUTES,
  ],
};

/**
 * Check if route is disabled
 * @param {string} route
 * @param {boolean} isPhone
 * @param {Object} themeParams
 * @param {string} themeParams.searchPageAppearance
 * @returns {boolean}
 */
const isRouteDisabled = (route, isPhone, {searchPageAppearance}) => {
  // For visual purposes footer is disabled on such modification.
  if (
    searchPageAppearance === SearchPageAppearance.FULLSCREEN_SLIDER &&
    route.startsWith('/search')
  ) {
    return true;
  }

  return Boolean(
    DISABLED_ROUTES[isPhone ? 'MOB' : 'WEB'].find((path) =>
      path.exact ? route === path.name : route.startsWith(path.name),
    ),
  );
};

const Footer = ({authorizedZone}) => {
  const isPhone = usePhoneBreakpoint();
  const {pathname} = useLocation();

  const {data, loading, error} = useQuery(FOOTER_QUERY, {
    variables: {
      showPciCertified: authorizedZone,
    },
  });
  const withSpacedAction = useReactiveVar(isSpacedActionVar);

  const {data: searchPageAppearance, loading: themeSearchLoading} =
    useThemeFeature('search', 'page');

  const disabledRoute = isRouteDisabled(pathname, isPhone, {
    searchPageAppearance,
  });

  if (disabledRoute || error || loading || themeSearchLoading) {
    return null;
  }

  const showBanner = authorizedZone && !isDescriptorUrl(pathname);

  return (
    <BabciaScopedProvider context="footer">
      <FooterLayout
        {...data.site.footer}
        showSiteMap={isPhone}
        withSpacedAction={withSpacedAction}
        showBanner={showBanner}
      />
    </BabciaScopedProvider>
  );
};

Footer.propTypes /* remove-proptypes */ = {
  authorizedZone: PropTypes.bool.isRequired,
};

export default Footer;
