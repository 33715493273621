import setStyleNode from '@core/application/utils/setStyleNode';

export const TOOLBAR_HEIGHT_ID = 'topWrapperHeight';

/**
 * @param {Number} height
 */
const setTopWrapperHeightVariable = (height) => {
  const styles = `:root {--topWrapperHeight: ${height}px; }`;

  setStyleNode({id: TOOLBAR_HEIGHT_ID, styles});
};

export default setTopWrapperHeightVariable;
