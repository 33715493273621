import sendUBEventToBabcia from '@core/tracking/babcia/utils/sendUBEventToBabcia';
import {
  Event,
  PageVisibility,
} from '@core/tracking/babcia/constants/babciaDataTypes';

const initPageVisibilityListeners = () => {
  const onPageHide = () => {
    sendUBEventToBabcia({
      event: Event.CLOSE,
    });
  };
  const onVisibilityChange = () => {
    sendUBEventToBabcia({
      event: Event.VISIBILITY_CHANGE,
      value: document.hidden ? PageVisibility.HIDDEN : PageVisibility.VISIBLE,
    });
  };

  window.addEventListener('pagehide', onPageHide);
  window.addEventListener('visibilitychange', onVisibilityChange);
};

export default initPageVisibilityListeners;
