import EXTRA_DISCOUNT_EXPIRE_QUERY from '../actions/extraDiscountPopup/graphql/queries/extraDiscountExpire.gql';

/**
 * Set extra discount to Apollo cache
 * @param {number} paymentDiscount
 * @param client
 */
const setPaymentDiscount = (paymentDiscount, client) =>
  client.writeQuery({
    query: EXTRA_DISCOUNT_EXPIRE_QUERY,
    data: {
      userFeatures: {
        paymentDiscount,
        __typename: 'UserFeatures',
      },
    },
  });

export default setPaymentDiscount;
