import React from 'react';

import COIN_AMOUNT from '../constants/coinAmount';

/**
 *
 * @param {string} type
 * @param {string} svgClassName
 * @returns {JSX.Element|null}
 */
const getColorizedIconFactory = (type, svgClassName) => {
  if (type === COIN_AMOUNT.ONE) {
    return (
      <svg
        className={svgClassName}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
          fill="#F9B80C"
        />
        <path
          d="M16 29.176C8.723 29.176 2.824 23.277 2.824 16S8.723 2.823 16 2.823 29.177 8.723 29.177 16c0 7.277-5.9 13.176-13.177 13.176z"
          fill="#FFE9A2"
        />
        <path
          d="M18.823 6.588l.002 2.12a7.528 7.528 0 0 1 3.754 2.3l-2.375 2.375a4.183 4.183 0 1 0 0 5.234l2.375 2.374a7.528 7.528 0 0 1-3.755 2.3v2.12h-3.765v-2.119a7.533 7.533 0 0 1 0-14.584v-2.12h3.764z"
          fill="#F9B80C"
        />
      </svg>
    );
  }

  if (type === COIN_AMOUNT.ONE_BIG) {
    return (
      <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30 14C38.8366 14 46 21.1634 46 30C46 38.8366 38.8366 46 30 46C21.1634 46 14 38.8366 14 30C14 21.1634 21.1634 14 30 14Z"
          fill="#F9B80C"
        />
        <path
          d="M30 43.1764C22.7228 43.1764 16.8235 37.2771 16.8235 30C16.8235 22.7228 22.7228 16.8235 30 16.8235C37.2771 16.8235 43.1764 22.7228 43.1764 30C43.1764 37.2771 37.2771 43.1764 30 43.1764Z"
          fill="#FFE9A2"
        />
        <path
          d="M32.8235 20.5881L32.8245 22.7079C34.2992 23.0877 35.599 23.903 36.5786 25.0086L34.2044 27.3827C33.4378 26.428 32.2608 25.8169 30.9412 25.8169C28.6309 25.8169 26.7581 27.6897 26.7581 29.9999C26.7581 32.3101 28.6309 34.1829 30.9412 34.1829C32.2608 34.1829 33.4378 33.5718 34.2044 32.6171L36.5786 34.9912C35.599 36.0968 34.2992 36.9121 32.8245 37.2919L32.8235 39.4117H29.0588L29.0594 37.2923C25.8116 36.4566 23.4117 33.5085 23.4117 29.9999C23.4117 26.4913 25.8116 23.5432 29.0594 22.7075L29.0588 20.5881H32.8235Z"
          fill="#F9B80C"
        />
      </svg>
    );
  }

  if (type === COIN_AMOUNT.FEW) {
    return (
      <svg
        className={svgClassName}
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clipCoinFew)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 36H58V40.6C58 41.5 57.4 42.2 56.6 42.5C51.7 44.1 46.9 44.8 42 44.8C36.7 44.8 31.3 43.9 26 42V36Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 36H58V40.6C58 41.5 57.4 42.2 56.6 42.5C51.7 44.1 46.9 44.8 42 44.8C36.7 44.8 31.3 43.9 26 42V36Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 42.0001V41.0001C31.3 42.9001 36.7 43.8001 42 43.8001C46.9 43.8001 51.7 43.0001 56.6 41.5001C57.4 41.3001 57.9 40.6001 58 39.8001V39.6001V40.6001C58 41.5001 57.4 42.2001 56.6 42.5001C51.7 44.1001 46.9 44.8001 42 44.8001C36.7 44.8001 31.3 43.9001 26 42.0001V41.0001V42.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M42 40H42.8C51.3 39.9 58 38.1 58 36C58 33.8 51.1 32.1 42.4 32H42C33.2 32 26 33.8 26 36C26 38.2 33.2 40 42 40Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 39C37.8 39 33.8 38.6 30.9 37.9C28.4 37.2 27 36.4 27 36C27 35.6 28.4 34.8 30.9 34.1C33.8 33.4 37.8 33 42 33C46.2 33 50.2 33.4 53.1 34.1C55.6 34.7 57 35.6 57 36C57 36.4 55.6 37.2 53.1 37.9C50.2 38.6 46.2 39 42 39Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 33C46.2 33 50.2 33.4 53.1 34.1C55.6 34.7 57 35.6 57 36C57 36.1 56.9 36.3 56.6 36.5C56 36.1 54.8 35.6 53.1 35.1C50.2 34.4 46.2 34 42 34C37.8 34 33.8 34.4 30.9 35.1C29.2 35.5 28 36 27.4 36.5C27.1 36.3 27 36.1 27 36C27 35.6 28.4 34.8 30.9 34.1C33.8 33.4 37.8 33 42 33Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 36H34V40.6C34 41.5 33.4 42.2 32.6 42.5C27.7 44.1 22.9 44.8 18 44.8C12.7 44.8 7.3 43.9 2 42V36Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 42.0001V41.0001C7.3 42.9001 12.7 43.8001 18 43.8001C22.9 43.8001 27.7 43.0001 32.6 41.5001C33.4 41.3001 33.9 40.6001 34 39.8001V39.6001V40.6001C34 41.5001 33.4 42.2001 32.6 42.5001C27.7 44.1001 22.9 44.8001 18 44.8001C12.7 44.8001 7.3 43.9001 2 42.0001V41.0001V42.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M18 40H18.8C27.3 39.9 34 38.1 34 36C34 33.8 27.1 32.1 18.4 32H18C9.2 32 2 33.8 2 36C2 38.2 9.2 40 18 40Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 39C13.8 39 9.8 38.6 6.9 37.9C4.4 37.2 3 36.4 3 36C3 35.6 4.4 34.8 6.9 34.1C9.8 33.4 13.8 33 18 33C22.2 33 26.2 33.4 29.1 34.1C31.6 34.7 33 35.6 33 36C33 36.4 31.6 37.2 29.1 37.9C26.2 38.6 22.2 39 18 39Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 33C22.2 33 26.2 33.4 29.1 34.1C31.6 34.7 33 35.6 33 36C33 36.1 32.9 36.3 32.6 36.5C32 36.1 30.8 35.6 29.1 35.1C26.2 34.4 22.2 34 18 34C13.8 34 9.8 34.4 6.9 35.1C5.2 35.5 4 36 3.4 36.5C3.1 36.3 3 36.1 3 36C3 35.6 4.4 34.8 6.9 34.1C9.8 33.4 13.8 33 18 33Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 30H55V34.6C55 35.5 54.4 36.2 53.6 36.5C48.7 38.1 43.9 38.8 39 38.8C33.7 38.8 28.3 37.9 23 36V30Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 30H55V34.6C55 35.5 54.4 36.2 53.6 36.5C48.7 38.1 43.9 38.8 39 38.8C33.7 38.8 28.3 37.9 23 36V30Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 36.0001V35.0001C28.3 36.9001 33.7 37.8001 39 37.8001C43.9 37.8001 48.7 37.0001 53.6 35.5001C54.4 35.3001 54.9 34.6001 55 33.8001V33.6001V34.6001C55 35.5001 54.4 36.2001 53.6 36.5001C48.7 38.1001 43.9 38.8001 39 38.8001C33.7 38.8001 28.3 37.9001 23 36.0001V35.0001V36.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M39 34H39.8C48.3 33.9 55 32.1 55 30C55 27.8 48.1 26.1 39.4 26H39C30.2 26 23 27.8 23 30C23 32.2 30.2 34 39 34Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 33C34.8 33 30.8 32.6 27.9 31.9C25.4 31.2 24 30.4 24 30C24 29.6 25.4 28.8 27.9 28.1C30.8 27.4 34.8 27 39 27C43.2 27 47.2 27.4 50.1 28.1C52.6 28.7 54 29.6 54 30C54 30.4 52.6 31.2 50.1 31.9C47.2 32.6 43.2 33 39 33Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 27C43.2 27 47.2 27.4 50.1 28.1C52.6 28.7 54 29.6 54 30C54 30.1 53.9 30.3 53.6 30.5C53 30.1 51.8 29.6 50.1 29.1C47.2 28.4 43.2 28 39 28C34.8 28 30.8 28.4 27.9 29.1C26.2 29.5 25 30 24.4 30.5C24.1 30.3 24 30.1 24 30C24 29.6 25.4 28.8 27.9 28.1C30.8 27.4 34.8 27 39 27Z"
            fill="#F9B80C"
          />
        </g>
        <path
          d="M30 14C38.8471 14 46 21.1529 46 30C46 38.8471 38.8471 46 30 46C21.1529 46 14 38.8471 14 30C14 21.1529 21.1529 14 30 14Z"
          fill="#F9B80C"
        />
        <path
          d="M30 43.1764C22.753 43.1764 16.8235 37.247 16.8235 30C16.8235 22.7529 22.753 16.8235 30 16.8235C37.2471 16.8235 43.1765 22.7529 43.1765 30C43.1765 37.247 37.2471 43.1764 30 43.1764Z"
          fill="#FFE9A2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8235 20.5881V22.7528C34.3294 23.1293 35.5529 23.9764 36.5882 25.0117L34.2353 27.3646C33.4823 26.4234 32.2588 25.7646 30.9412 25.7646C28.5882 25.7646 26.8 27.647 26.8 29.9058C26.8 32.1646 28.6823 34.047 30.9412 34.047C32.2588 34.047 33.4823 33.4823 34.2353 32.447L36.5882 34.7999C35.647 35.9293 34.3294 36.6823 32.8235 37.0587V39.2234H29.0588V37.0587C25.7647 36.2117 23.4117 33.294 23.4117 29.8117C23.4117 26.3293 25.7647 23.3175 29.0588 22.5646V20.5881H32.8235Z"
          fill="#F9B80C"
        />
        <defs>
          <clipPath id="clipCoinFew">
            <rect
              width="56"
              height="18.8"
              fill="white"
              transform="translate(2 26)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (type === COIN_AMOUNT.MANY) {
    return (
      <svg
        className={svgClassName}
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clipCoinMany)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 36H58V40.6C58 41.5 57.4 42.2 56.6 42.5C51.7 44.1 46.9 44.8 42 44.8C36.7 44.8 31.3 43.9 26 42V36Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 36H58V40.6C58 41.5 57.4 42.2 56.6 42.5C51.7 44.1 46.9 44.8 42 44.8C36.7 44.8 31.3 43.9 26 42V36Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 42.0001V41.0001C31.3 42.9001 36.7 43.8001 42 43.8001C46.9 43.8001 51.7 43.0001 56.6 41.5001C57.4 41.3001 57.9 40.6001 58 39.8001V39.6001V40.6001C58 41.5001 57.4 42.2001 56.6 42.5001C51.7 44.1001 46.9 44.8001 42 44.8001C36.7 44.8001 31.3 43.9001 26 42.0001V41.0001V42.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M42 40H42.8C51.3 39.9 58 38.1 58 36C58 33.8 51.1 32.1 42.4 32H42C33.2 32 26 33.8 26 36C26 38.2 33.2 40 42 40Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 39C37.8 39 33.8 38.6 30.9 37.9C28.4 37.2 27 36.4 27 36C27 35.6 28.4 34.8 30.9 34.1C33.8 33.4 37.8 33 42 33C46.2 33 50.2 33.4 53.1 34.1C55.6 34.7 57 35.6 57 36C57 36.4 55.6 37.2 53.1 37.9C50.2 38.6 46.2 39 42 39Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 33C46.2 33 50.2 33.4 53.1 34.1C55.6 34.7 57 35.6 57 36C57 36.1 56.9 36.3 56.6 36.5C56 36.1 54.8 35.6 53.1 35.1C50.2 34.4 46.2 34 42 34C37.8 34 33.8 34.4 30.9 35.1C29.2 35.5 28 36 27.4 36.5C27.1 36.3 27 36.1 27 36C27 35.6 28.4 34.8 30.9 34.1C33.8 33.4 37.8 33 42 33Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 30H55V34.6C55 35.5 54.4 36.2 53.6 36.5C48.7 38.1 43.9 38.8 39 38.8C33.7 38.8 28.3 37.9 23 36V30Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 30H55V34.6C55 35.5 54.4 36.2 53.6 36.5C48.7 38.1 43.9 38.8 39 38.8C33.7 38.8 28.3 37.9 23 36V30Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 36.0001V35.0001C28.3 36.9001 33.7 37.8001 39 37.8001C43.9 37.8001 48.7 37.0001 53.6 35.5001C54.4 35.3001 54.9 34.6001 55 33.8001V33.6001V34.6001C55 35.5001 54.4 36.2001 53.6 36.5001C48.7 38.1001 43.9 38.8001 39 38.8001C33.7 38.8001 28.3 37.9001 23 36.0001V35.0001V36.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M39 34H39.8C48.3 33.9 55 32.1 55 30C55 27.8 48.1 26.1 39.4 26H39C30.2 26 23 27.8 23 30C23 32.2 30.2 34 39 34Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 33C34.8 33 30.8 32.6 27.9 31.9C25.4 31.2 24 30.4 24 30C24 29.6 25.4 28.8 27.9 28.1C30.8 27.4 34.8 27 39 27C43.2 27 47.2 27.4 50.1 28.1C52.6 28.7 54 29.6 54 30C54 30.4 52.6 31.2 50.1 31.9C47.2 32.6 43.2 33 39 33Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 27C43.2 27 47.2 27.4 50.1 28.1C52.6 28.7 54 29.6 54 30C54 30.1 53.9 30.3 53.6 30.5C53 30.1 51.8 29.6 50.1 29.1C47.2 28.4 43.2 28 39 28C34.8 28 30.8 28.4 27.9 29.1C26.2 29.5 25 30 24.4 30.5C24.1 30.3 24 30.1 24 30C24 29.6 25.4 28.8 27.9 28.1C30.8 27.4 34.8 27 39 27Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 23H58V27.6C58 28.5 57.4 29.2 56.6 29.5C51.7 31.1 46.9 31.8 42 31.8C36.7 31.8 31.3 30.9 26 29V23Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 23H58V27.6C58 28.5 57.4 29.2 56.6 29.5C51.7 31.1 46.9 31.8 42 31.8C36.7 31.8 31.3 30.9 26 29V23Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 29.0001V28.0001C31.3 29.9001 36.7 30.8001 42 30.8001C46.9 30.8001 51.7 30.0001 56.6 28.5001C57.4 28.3001 57.9 27.6001 58 26.8001V26.6001V27.6001C58 28.5001 57.4 29.2001 56.6 29.5001C51.7 31.1001 46.9 31.8001 42 31.8001C36.7 31.8001 31.3 30.9001 26 29.0001V28.0001V29.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M42 27H42.8C51.3 26.9 58 25.1 58 23C58 20.8 51.1 19.1 42.4 19H42C33.2 19 26 20.8 26 23C26 25.2 33.2 27 42 27Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 26C37.8 26 33.8 25.6 30.9 24.9C28.4 24.2 27 23.4 27 23C27 22.6 28.4 21.8 30.9 21.1C33.8 20.4 37.8 20 42 20C46.2 20 50.2 20.4 53.1 21.1C55.6 21.8 57 22.6 57 23C57 23.4 55.6 24.2 53.1 24.9C50.2 25.6 46.2 26 42 26Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 20C46.2 20 50.2 20.4 53.1 21.1C55.6 21.8 57 22.6 57 23C57 23.1 56.9 23.3 56.6 23.5C56 23.1 54.8 22.6 53.1 22.1C50.2 21.4 46.2 21 42 21C37.8 21 33.8 21.4 30.9 22.1C29.2 22.5 28 23 27.4 23.5C27.1 23.3 27 23.1 27 23C27 22.6 28.4 21.8 30.9 21.1C33.8 20.4 37.8 20 42 20Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 36H37V40.6C37 41.5 36.4 42.2 35.6 42.5C30.7 44.1 25.9 44.8 21 44.8C15.7 44.8 10.3 43.9 5 42V36Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 42.0001V41.0001C10.3 42.9001 15.7 43.8001 21 43.8001C25.9 43.8001 30.7 43.0001 35.6 41.5001C36.4 41.3001 36.9 40.6001 37 39.8001V39.6001V40.6001C37 41.5001 36.4 42.2001 35.6 42.5001C30.7 44.1001 25.9 44.8001 21 44.8001C15.7 44.8001 10.3 43.9001 5 42.0001V41.0001V42.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M21 40H21.8C30.3 39.9 37 38.1 37 36C37 33.8 30.1 32.1 21.4 32H21C12.2 32 5 33.8 5 36C5 38.2 12.2 40 21 40Z"
            fill="#FFE9A2"
          />
          <path
            d="M21 39C16.8 39 12.8 38.6 9.9 37.9C7.4 37.2 6 36.4 6 36C6 35.6 7.4 34.8 9.9 34.1C12.8 33.4 16.8 33 21 33C25.2 33 29.2 33.4 32.1 34.1C34.6 34.7 36 35.6 36 36C36 36.4 34.6 37.2 32.1 37.9C29.2 38.6 25.2 39 21 39Z"
            fill="#FFE9A2"
          />
          <path
            d="M21 33C25.2 33 29.2 33.4 32.1 34.1C34.6 34.7 36 35.6 36 36C36 36.1 35.9 36.3 35.6 36.5C35 36.1 33.8 35.6 32.1 35.1C29.2 34.4 25.2 34 21 34C16.8 34 12.8 34.4 9.9 35.1C8.2 35.5 7 36 6.4 36.5C6.1 36.3 6 36.1 6 36C6 35.6 7.4 34.8 9.9 34.1C12.8 33.4 16.8 33 21 33Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 30H34V34.6C34 35.5 33.4 36.2 32.6 36.5C27.7 38.1 22.9 38.8 18 38.8C12.7 38.8 7.3 37.9 2 36V30Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 36.0001V35.0001C7.3 36.9001 12.7 37.8001 18 37.8001C22.9 37.8001 27.7 37.0001 32.6 35.5001C33.4 35.3001 33.9 34.6001 34 33.8001V33.6001V34.6001C34 35.5001 33.4 36.2001 32.6 36.5001C27.7 38.1001 22.9 38.8001 18 38.8001C12.7 38.8001 7.3 37.9001 2 36.0001V35.0001V36.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M18 34H18.8C27.3 33.9 34 32.1 34 30C34 27.8 27.1 26.1 18.4 26H18C9.2 26 2 27.8 2 30C2 32.2 9.2 34 18 34Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 33C13.8 33 9.8 32.6 6.9 31.9C4.4 31.2 3 30.4 3 30C3 29.6 4.4 28.8 6.9 28.1C9.8 27.4 13.8 27 18 27C22.2 27 26.2 27.4 29.1 28.1C31.6 28.7 33 29.6 33 30C33 30.4 31.6 31.2 29.1 31.9C26.2 32.6 22.2 33 18 33Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 27C22.2 27 26.2 27.4 29.1 28.1C31.6 28.7 33 29.6 33 30C33 30.1 32.9 30.3 32.6 30.5C32 30.1 30.8 29.6 29.1 29.1C26.2 28.4 22.2 28 18 28C13.8 28 9.8 28.4 6.9 29.1C5.2 29.5 4 30 3.4 30.5C3.1 30.3 3 30.1 3 30C3 29.6 4.4 28.8 6.9 28.1C9.8 27.4 13.8 27 18 27Z"
            fill="#F9B80C"
          />
        </g>
        <path
          d="M30 14C38.8471 14 46 21.1529 46 30C46 38.8471 38.8471 46 30 46C21.1529 46 14 38.8471 14 30C14 21.1529 21.1529 14 30 14Z"
          fill="#F9B80C"
        />
        <path
          d="M30 43.1764C22.753 43.1764 16.8235 37.247 16.8235 30C16.8235 22.7529 22.753 16.8235 30 16.8235C37.2471 16.8235 43.1765 22.7529 43.1765 30C43.1765 37.247 37.2471 43.1764 30 43.1764Z"
          fill="#FFE9A2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8235 20.5881V22.7528C34.3294 23.1293 35.5529 23.9764 36.5882 25.0117L34.2353 27.3646C33.4823 26.4234 32.2588 25.7646 30.9412 25.7646C28.5882 25.7646 26.8 27.647 26.8 29.9058C26.8 32.1646 28.6823 34.047 30.9412 34.047C32.2588 34.047 33.4823 33.4823 34.2353 32.447L36.5882 34.7999C35.647 35.9293 34.3294 36.6823 32.8235 37.0587V39.2234H29.0588V37.0587C25.7647 36.2117 23.4117 33.294 23.4117 29.8117C23.4117 26.3293 25.7647 23.3175 29.0588 22.5646V20.5881H32.8235Z"
          fill="#F9B80C"
        />
        <defs>
          <clipPath id="clipCoinMany">
            <rect
              width="56"
              height="25.8"
              fill="white"
              transform="translate(2 19)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (type === COIN_AMOUNT.HUGE) {
    return (
      <svg
        className={svgClassName}
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clipCoinHuge)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 40H58V44.6C58 45.5 57.4 46.2 56.6 46.5C51.7 48.1 46.9 48.8 42 48.8C36.7 48.8 31.3 47.9 26 46V40Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 40H58V44.6C58 45.5 57.4 46.2 56.6 46.5C51.7 48.1 46.9 48.8 42 48.8C36.7 48.8 31.3 47.9 26 46V40Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 46.0001V45.0001C31.3 46.9001 36.7 47.8001 42 47.8001C46.9 47.8001 51.7 47.0001 56.6 45.5001C57.4 45.3001 57.9 44.6001 58 43.8001V43.6001V44.6001C58 45.5001 57.4 46.2001 56.6 46.5001C51.7 48.1001 46.9 48.8001 42 48.8001C36.7 48.8001 31.3 47.9001 26 46.0001V45.0001V46.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M42 44H42.8C51.3 43.9 58 42.1 58 40C58 37.8 51.1 36.1 42.4 36H42C33.2 36 26 37.8 26 40C26 42.2 33.2 44 42 44Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 43C37.8 43 33.8 42.6 30.9 41.9C28.4 41.2 27 40.4 27 40C27 39.6 28.4 38.8 30.9 38.1C33.8 37.4 37.8 37 42 37C46.2 37 50.2 37.4 53.1 38.1C55.6 38.7 57 39.6 57 40C57 40.4 55.6 41.2 53.1 41.9C50.2 42.6 46.2 43 42 43Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 37C46.2 37 50.2 37.4 53.1 38.1C55.6 38.7 57 39.6 57 40C57 40.1 56.9 40.3 56.6 40.5C56 40.1 54.8 39.6 53.1 39.1C50.2 38.4 46.2 38 42 38C37.8 38 33.8 38.4 30.9 39.1C29.2 39.5 28 40 27.4 40.5C27.1 40.3 27 40.1 27 40C27 39.6 28.4 38.8 30.9 38.1C33.8 37.4 37.8 37 42 37Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 34H55V38.6C55 39.5 54.4 40.2 53.6 40.5C48.7 42.1 43.9 42.8 39 42.8C33.7 42.8 28.3 41.9 23 40V34Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 34H55V38.6C55 39.5 54.4 40.2 53.6 40.5C48.7 42.1 43.9 42.8 39 42.8C33.7 42.8 28.3 41.9 23 40V34Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 40.0001V39.0001C28.3 40.9001 33.7 41.8001 39 41.8001C43.9 41.8001 48.7 41.0001 53.6 39.5001C54.4 39.3001 54.9 38.6001 55 37.8001V37.6001V38.6001C55 39.5001 54.4 40.2001 53.6 40.5001C48.7 42.1001 43.9 42.8001 39 42.8001C33.7 42.8001 28.3 41.9001 23 40.0001V39.0001V40.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M39 38H39.8C48.3 37.9 55 36.1 55 34C55 31.8 48.1 30.1 39.4 30H39C30.2 30 23 31.8 23 34C23 36.2 30.2 38 39 38Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 37C34.8 37 30.8 36.6 27.9 35.9C25.4 35.2 24 34.4 24 34C24 33.6 25.4 32.8 27.9 32.1C30.8 31.4 34.8 31 39 31C43.2 31 47.2 31.4 50.1 32.1C52.6 32.7 54 33.6 54 34C54 34.4 52.6 35.2 50.1 35.9C47.2 36.6 43.2 37 39 37Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 31C43.2 31 47.2 31.4 50.1 32.1C52.6 32.7 54 33.6 54 34C54 34.1 53.9 34.3 53.6 34.5C53 34.1 51.8 33.6 50.1 33.1C47.2 32.4 43.2 32 39 32C34.8 32 30.8 32.4 27.9 33.1C26.2 33.5 25 34 24.4 34.5C24.1 34.3 24 34.1 24 34C24 33.6 25.4 32.8 27.9 32.1C30.8 31.4 34.8 31 39 31Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 27H58V31.6C58 32.5 57.4 33.2 56.6 33.5C51.7 35.1 46.9 35.8 42 35.8C36.7 35.8 31.3 34.9 26 33V27Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 27H58V31.6C58 32.5 57.4 33.2 56.6 33.5C51.7 35.1 46.9 35.8 42 35.8C36.7 35.8 31.3 34.9 26 33V27Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 33.0001V32.0001C31.3 33.9001 36.7 34.8001 42 34.8001C46.9 34.8001 51.7 34.0001 56.6 32.5001C57.4 32.3001 57.9 31.6001 58 30.8001V30.6001V31.6001C58 32.5001 57.4 33.2001 56.6 33.5001C51.7 35.1001 46.9 35.8001 42 35.8001C36.7 35.8001 31.3 34.9001 26 33.0001V32.0001V33.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M42 31H42.8C51.3 30.9 58 29.1 58 27C58 24.8 51.1 23.1 42.4 23H42C33.2 23 26 24.8 26 27C26 29.2 33.2 31 42 31Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 30C37.8 30 33.8 29.6 30.9 28.9C28.4 28.2 27 27.4 27 27C27 26.6 28.4 25.8 30.9 25.1C33.8 24.4 37.8 24 42 24C46.2 24 50.2 24.4 53.1 25.1C55.6 25.7 57 26.6 57 27C57 27.4 55.6 28.2 53.1 28.9C50.2 29.6 46.2 30 42 30Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 24C46.2 24 50.2 24.4 53.1 25.1C55.6 25.7 57 26.6 57 27C57 27.1 56.9 27.3 56.6 27.5C56 27.1 54.8 26.6 53.1 26.1C50.2 25.4 46.2 25 42 25C37.8 25 33.8 25.4 30.9 26.1C29.2 26.5 28 27 27.4 27.5C27.1 27.3 27 27.1 27 27C27 26.6 28.4 25.8 30.9 25.1C33.8 24.4 37.8 24 42 24Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 40H37V44.6C37 45.5 36.4 46.2 35.6 46.5C30.7 48.1 25.9 48.8 21 48.8C15.7 48.8 10.3 47.9 5 46V40Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 46.0001V45.0001C10.3 46.9001 15.7 47.8001 21 47.8001C25.9 47.8001 30.7 47.0001 35.6 45.5001C36.4 45.3001 36.9 44.6001 37 43.8001V43.6001V44.6001C37 45.5001 36.4 46.2001 35.6 46.5001C30.7 48.1001 25.9 48.8001 21 48.8001C15.7 48.8001 10.3 47.9001 5 46.0001V45.0001V46.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M21 44H21.8C30.3 43.9 37 42.1 37 40C37 37.8 30.1 36.1 21.4 36H21C12.2 36 5 37.8 5 40C5 42.2 12.2 44 21 44Z"
            fill="#FFE9A2"
          />
          <path
            d="M21 43C16.8 43 12.8 42.6 9.9 41.9C7.4 41.2 6 40.4 6 40C6 39.6 7.4 38.8 9.9 38.1C12.8 37.4 16.8 37 21 37C25.2 37 29.2 37.4 32.1 38.1C34.6 38.7 36 39.6 36 40C36 40.4 34.6 41.2 32.1 41.9C29.2 42.6 25.2 43 21 43Z"
            fill="#FFE9A2"
          />
          <path
            d="M21 37C25.2 37 29.2 37.4 32.1 38.1C34.6 38.7 36 39.6 36 40C36 40.1 35.9 40.3 35.6 40.5C35 40.1 33.8 39.6 32.1 39.1C29.2 38.4 25.2 38 21 38C16.8 38 12.8 38.4 9.9 39.1C8.2 39.5 7 40 6.4 40.5C6.1 40.3 6 40.1 6 40C6 39.6 7.4 38.8 9.9 38.1C12.8 37.4 16.8 37 21 37Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 34H34V38.6C34 39.5 33.4 40.2 32.6 40.5C27.7 42.1 22.9 42.8 18 42.8C12.7 42.8 7.3 41.9 2 40V34Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 40.0001V39.0001C7.3 40.9001 12.7 41.8001 18 41.8001C22.9 41.8001 27.7 41.0001 32.6 39.5001C33.4 39.3001 33.9 38.6001 34 37.8001V37.6001V38.6001C34 39.5001 33.4 40.2001 32.6 40.5001C27.7 42.1001 22.9 42.8001 18 42.8001C12.7 42.8001 7.3 41.9001 2 40.0001V39.0001V40.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M18 38H18.8C27.3 37.9 34 36.1 34 34C34 31.8 27.1 30.1 18.4 30H18C9.2 30 2 31.8 2 34C2 36.2 9.2 38 18 38Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 37C13.8 37 9.8 36.6 6.9 35.9C4.4 35.2 3 34.4 3 34C3 33.6 4.4 32.8 6.9 32.1C9.8 31.4 13.8 31 18 31C22.2 31 26.2 31.4 29.1 32.1C31.6 32.7 33 33.6 33 34C33 34.4 31.6 35.2 29.1 35.9C26.2 36.6 22.2 37 18 37Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 31C22.2 31 26.2 31.4 29.1 32.1C31.6 32.7 33 33.6 33 34C33 34.1 32.9 34.3 32.6 34.5C32 34.1 30.8 33.6 29.1 33.1C26.2 32.4 22.2 32 18 32C13.8 32 9.8 32.4 6.9 33.1C5.2 33.5 4 34 3.4 34.5C3.1 34.3 3 34.1 3 34C3 33.6 4.4 32.8 6.9 32.1C9.8 31.4 13.8 31 18 31Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 28H37V32.6C37 33.5 36.4 34.2 35.6 34.5C30.7 36.1 25.9 36.8 21 36.8C15.7 36.8 10.3 35.9 5 34V28Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 34.0001V33.0001C10.3 34.9001 15.7 35.8001 21 35.8001C25.9 35.8001 30.7 35.0001 35.6 33.5001C36.4 33.3001 36.9 32.6001 37 31.8001V31.6001V32.6001C37 33.5001 36.4 34.2001 35.6 34.5001C30.7 36.1001 25.9 36.8001 21 36.8001C15.7 36.8001 10.3 35.9001 5 34.0001V33.0001V34.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M21 32H21.8C30.3 31.9 37 30.1 37 28C37 25.8 30.1 24.1 21.4 24H21C12.2 24 5 25.8 5 28C5 30.2 12.2 32 21 32Z"
            fill="#FFE9A2"
          />
          <path
            d="M21 31C16.8 31 12.8 30.6 9.9 29.9C7.4 29.2 6 28.4 6 28C6 27.6 7.4 26.8 9.9 26.1C12.8 25.4 16.8 25 21 25C25.2 25 29.2 25.4 32.1 26.1C34.6 26.7 36 27.6 36 28C36 28.4 34.6 29.2 32.1 29.9C29.2 30.6 25.2 31 21 31Z"
            fill="#FFE9A2"
          />
          <path
            d="M21 25C25.2 25 29.2 25.4 32.1 26.1C34.6 26.7 36 27.6 36 28C36 28.1 35.9 28.3 35.6 28.5C35 28.1 33.8 27.6 32.1 27.1C29.2 26.4 25.2 26 21 26C16.8 26 12.8 26.4 9.9 27.1C8.2 27.5 7 28 6.4 28.5C6.1 28.3 6 28.1 6 28C6 27.6 7.4 26.8 9.9 26.1C12.8 25.4 16.8 25 21 25Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 21H34V25.6C34 26.5 33.4 27.2 32.6 27.5C27.7 29.1 22.9 29.8 18 29.8C12.7 29.8 7.3 28.9 2 27V21Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 27.0001V26.0001C7.3 27.9001 12.7 28.8001 18 28.8001C22.9 28.8001 27.7 28.0001 32.6 26.5001C33.4 26.3001 33.9 25.6001 34 24.8001V24.6001V25.6001C34 26.5001 33.4 27.2001 32.6 27.5001C27.7 29.1001 22.9 29.8001 18 29.8001C12.7 29.8001 7.3 28.9001 2 27.0001V26.0001V27.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M18 25H18.8C27.3 24.9 34 23.1 34 21C34 18.8 27.1 17.1 18.4 17H18C9.2 17 2 18.8 2 21C2 23.2 9.2 25 18 25Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 24C13.8 24 9.8 23.6 6.9 22.9C4.4 22.2 3 21.4 3 21C3 20.6 4.4 19.8 6.9 19.1C9.8 18.4 13.8 18 18 18C22.2 18 26.2 18.4 29.1 19.1C31.6 19.8 33 20.6 33 21C33 21.4 31.6 22.2 29.1 22.9C26.2 23.6 22.2 24 18 24Z"
            fill="#FFE9A2"
          />
          <path
            d="M18 18C22.2 18 26.2 18.4 29.1 19.1C31.6 19.8 33 20.6 33 21C33 21.1 32.9 21.3 32.6 21.5C32 21.1 30.8 20.6 29.1 20.1C26.2 19.4 22.2 19 18 19C13.8 19 9.8 19.4 6.9 20.1C5.2 20.5 4 21 3.4 21.5C3.1 21.3 3 21.1 3 21C3 20.6 4.4 19.8 6.9 19.1C9.8 18.4 13.8 18 18 18Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 21H55V25.6C55 26.5 54.4 27.2 53.6 27.5C48.7 29.1 43.9 29.8 39 29.8C33.7 29.8 28.3 28.9 23 27V21Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 21H55V25.6C55 26.5 54.4 27.2 53.6 27.5C48.7 29.1 43.9 29.8 39 29.8C33.7 29.8 28.3 28.9 23 27V21Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 27.0001V26.0001C28.3 27.9001 33.7 28.8001 39 28.8001C43.9 28.8001 48.7 28.0001 53.6 26.5001C54.4 26.3001 54.9 25.6001 55 24.8001V24.6001V25.6001C55 26.5001 54.4 27.2001 53.6 27.5001C48.7 29.1001 43.9 29.8001 39 29.8001C33.7 29.8001 28.3 28.9001 23 27.0001V26.0001V27.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M39 25H39.8C48.3 24.9 55 23.1 55 21C55 18.8 48.1 17.1 39.4 17H39C30.2 17 23 18.8 23 21C23 23.2 30.2 25 39 25Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 24C34.8 24 30.8 23.6 27.9 22.9C25.4 22.2 24 21.4 24 21C24 20.6 25.4 19.8 27.9 19.1C30.8 18.4 34.8 18 39 18C43.2 18 47.2 18.4 50.1 19.1C52.6 19.8 54 20.6 54 21C54 21.4 52.6 22.2 50.1 22.9C47.2 23.6 43.2 24 39 24Z"
            fill="#FFE9A2"
          />
          <path
            d="M39 18C43.2 18 47.2 18.4 50.1 19.1C52.6 19.8 54 20.6 54 21C54 21.1 53.9 21.3 53.6 21.5C53 21.1 51.8 20.6 50.1 20.1C47.2 19.4 43.2 19 39 19C34.8 19 30.8 19.4 27.9 20.1C26.2 20.5 25 21 24.4 21.5C24.1 21.3 24 21.1 24 21C24 20.6 25.4 19.8 27.9 19.1C30.8 18.4 34.8 18 39 18Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 14H58V18.6C58 19.5 57.4 20.2 56.6 20.5C51.7 22.1 46.9 22.8 42 22.8C36.7 22.8 31.3 21.9 26 20V14Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 14H58V18.6C58 19.5 57.4 20.2 56.6 20.5C51.7 22.1 46.9 22.8 42 22.8C36.7 22.8 31.3 21.9 26 20V14Z"
            fill="#F9B80C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 20.0001V19.0001C31.3 20.9001 36.7 21.8001 42 21.8001C46.9 21.8001 51.7 21.0001 56.6 19.5001C57.4 19.3001 57.9 18.6001 58 17.8001V17.6001V18.6001C58 19.5001 57.4 20.2001 56.6 20.5001C51.7 22.1001 46.9 22.8001 42 22.8001C36.7 22.8001 31.3 21.9001 26 20.0001V19.0001V20.0001Z"
            fill="#EAAD0B"
          />
          <path
            d="M42 18H42.8C51.3 17.9 58 16.1 58 14C58 11.8 51.1 10.1 42.4 10H42C33.2 10 26 11.8 26 14C26 16.2 33.2 18 42 18Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 17C37.8 17 33.8 16.6 30.9 15.9C28.4 15.2 27 14.4 27 14C27 13.6 28.4 12.8 30.9 12.1C33.8 11.4 37.8 11 42 11C46.2 11 50.2 11.4 53.1 12.1C55.6 12.8 57 13.6 57 14C57 14.4 55.6 15.2 53.1 15.9C50.2 16.6 46.2 17 42 17Z"
            fill="#FFE9A2"
          />
          <path
            d="M42 11C46.2 11 50.2 11.4 53.1 12.1C55.6 12.8 57 13.6 57 14C57 14.1 56.9 14.3 56.6 14.5C56 14.1 54.8 13.6 53.1 13.1C50.2 12.4 46.2 12 42 12C37.8 12 33.8 12.4 30.9 13.1C29.2 13.5 28 14 27.4 14.5C27.1 14.3 27 14.1 27 14C27 13.6 28.4 12.8 30.9 12.1C33.8 11.4 37.8 11 42 11Z"
            fill="#F9B80C"
          />
        </g>
        <path
          d="M30 18C38.8471 18 46 25.1529 46 34C46 42.8471 38.8471 50 30 50C21.1529 50 14 42.8471 14 34C14 25.1529 21.1529 18 30 18Z"
          fill="#F9B80C"
        />
        <path
          d="M30 47.1764C22.753 47.1764 16.8235 41.247 16.8235 34C16.8235 26.7529 22.753 20.8235 30 20.8235C37.2471 20.8235 43.1765 26.7529 43.1765 34C43.1765 41.247 37.2471 47.1764 30 47.1764Z"
          fill="#FFE9A2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8235 24.5881V26.7528C34.3294 27.1293 35.5529 27.9764 36.5882 29.0117L34.2353 31.3646C33.4823 30.4234 32.2588 29.7646 30.9412 29.7646C28.5882 29.7646 26.8 31.647 26.8 33.9058C26.8 36.1646 28.6823 38.047 30.9412 38.047C32.2588 38.047 33.4823 37.4823 34.2353 36.447L36.5882 38.7999C35.647 39.9293 34.3294 40.6823 32.8235 41.0587V43.2234H29.0588V41.0587C25.7647 40.2117 23.4117 37.294 23.4117 33.8117C23.4117 30.3293 25.7647 27.3175 29.0588 26.5646V24.3999H32.8235V24.5881Z"
          fill="#F9B80C"
        />
        <defs>
          <clipPath id="clipCoinHuge">
            <rect
              width="56"
              height="38.8"
              fill="white"
              transform="translate(2 10)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return null;
};

export default getColorizedIconFactory;
