import isSearchPreloadEnabled from '@core/search/page/utils/isSearchPreloadEnabled';

// Block queries until another query end (succeed or failed).
const blockUntilComplete = {
  // Queries allowed to run only after AppDataQuery to resolve them from cache:
  BootstrapQuery: 'AppDataQuery',
  DefaultRouteQuery: 'AppDataQuery',
  AuthorizedQuery: 'AppDataQuery',
  CurrentUserIdQuery: 'AppDataQuery',
  BannedQuery: 'AppDataQuery',
  Web2AppSiteQuery: 'AppDataQuery',
  MarketingProductConfigurationQuery: 'AppDataQuery',
};

// Block queries until another query successful completion.
// They won't be allowed if query failed.
const blockUntilSuccess = {
  // Queries blocked by AppDataQuery to resolve them from cache:
  EmailConfirmedQuery: 'AppDataQuery',
  ActiveSplitQuery: 'AppDataQuery',
  NaughtyModeQuery: 'AppDataQuery',
  PhotoDataQuery: 'AppDataQuery',
  SearchParamsFeaturesQuery: 'AppDataQuery',
  CurrentUserAccountStatusQuery: 'AppDataQuery',
};

if (isSearchPreloadEnabled()) {
  const searchQuery = window.location.search.includes('ageFrom=')
    ? 'SearchListQuery'
    : 'SearchParamsQuery';

  Object.assign(blockUntilSuccess, {
    // Delay some queries to speed up search results loading:
    SecondaryDataQuery: searchQuery,
    ActivitiesQuery: searchQuery,
    ActivitiesForCountersQuery: searchQuery,
    MessengerQuery: searchQuery,
    TrustedVerificationPopupQuery: searchQuery,
    PaymentCoinsMotivationDataQuery: searchQuery,
    ActivityBookmarkAccountStatusQuery: searchQuery,
    UpdateTimeZoneMutation: searchQuery,

    // Queries blocked by SearchPageDataQuery to resolve them from cache:
    AdultContentAvailableQuery: 'SearchPageDataQuery',
    SearchShowAdditionalLocationPopupQuery: 'SearchPageDataQuery',
    PreferredContentShowOnScrollQuery: 'SearchPageDataQuery',
    SearchPayButtonAvailableQuery: 'SearchPageDataQuery',
    MotivationBannersQuery: 'SearchPageDataQuery',
    BannersOptionsQuery: 'SearchPageDataQuery',
    MicrofeaturesAvailabilityDataQuery: 'SearchPageDataQuery',
    // loaded by SearchPageDataQuery too, but delay them a little bit more:
    UniformMotivationQuery: searchQuery,
    GlobalSettingsCacheDependenciesQuery: searchQuery,
    StoriesAvailableQuery: searchQuery,
    FreeDailySpinAvailableQuery: searchQuery,
    DisapproveQuery: searchQuery,

    // Queries blocked by SecondaryDataQuery to resolve them from cache:
    FirstLoginDelayedSetAllowedQuery: 'SecondaryDataQuery',
    FooterQuery: 'SecondaryDataQuery',
    ReelsQuery: 'SecondaryDataQuery',
    ApplePayDisplaySettingsQuery: 'SecondaryDataQuery',
    GooglePayDisplaySettingsQuery: 'SecondaryDataQuery',
    PopunderActionDelayQuery: 'SecondaryDataQuery',
    GlobalFreeMessagesQuery: 'SecondaryDataQuery',
    PersonalIdentityByDemandAvailableQuery: 'SecondaryDataQuery',
    PersonalIdentityQuery: 'SecondaryDataQuery',
    WebCamPromoQuery: 'SecondaryDataQuery',
    PhotoUploadMotivationSettingsQuery: 'SecondaryDataQuery',
    PaymentBannerOpenInNewTabQuery: 'SecondaryDataQuery',
    EnabledRequiredPhotoUploadQuery: 'SecondaryDataQuery',
    ProfileCompletionParamsQuery: 'SecondaryDataQuery',
    SepaDebtorStatusQuery: 'SecondaryDataQuery',
    TrackingCodesQuery: 'SecondaryDataQuery',
    FraudNetQuery: 'SecondaryDataQuery',
    EnabledActivityTypesQuery: 'SecondaryDataQuery',
    DateFormatQuery: 'SecondaryDataQuery',
    FreeDailySpinSensitiveDataQuery: 'SecondaryDataQuery',
    CancelRepeat24hQuery: 'SecondaryDataQuery',
    PersonalIdentityByDemandQuery: 'SecondaryDataQuery',
    WebpushQuery: 'SecondaryDataQuery',
    InAppMotivationQuery: 'SecondaryDataQuery',
    VirtualAssistantAvailableQuery: 'SecondaryDataQuery',
    BrowserChangeMotivationQuery: 'SecondaryDataQuery',
    PendingDeleteQuery: 'SecondaryDataQuery',
    CreditsBalanceQuery: 'SecondaryDataQuery',
    ConfirmationMotivationQuery: 'SecondaryDataQuery',
    SeparatedDialogsQuery: 'SecondaryDataQuery',
    AllowedLikeGalleryQuery: 'SecondaryDataQuery',
    StickedNotificationsSettingsQuery: 'SecondaryDataQuery',
    MotivationDelayQuery: 'SecondaryDataQuery',
    UnpaidMotivationQuery: 'SecondaryDataQuery',
    PaymentPageVisitsQuery: 'SecondaryDataQuery',
    MotivationWidgetsQuery: 'SecondaryDataQuery',
    PersonalIdentityReadServiceQuery: 'SecondaryDataQuery',
  });
}

const BLOCKED_QUERIES = {blockUntilComplete, blockUntilSuccess};

export default BLOCKED_QUERIES;
