import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

import EXTRA_RM_BUNDLE_QUERY from './graphql/queries/extraRmBundle.gql';
import ExtraRMBundlePopup from './containers/ExtraRMBundlePopup';

/**
 * Open Rm bundle offer popup
 * @param processId
 * @param client
 * @returns {Promise<void>}
 */
const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {
        RMBundle: {enabled, via, sites, buttonType},
      },
    },
  } = await client.query({
    query: EXTRA_RM_BUNDLE_QUERY,
    variables: {processId},
  });

  if (!enabled) return;

  const url = generatePayUrl({
    stage: PAYMENT_ACTIONS.REMARKETING_OFFER,
    urlParams: {via},
  });

  PopupService.openPopup(
    <ExtraRMBundlePopup sites={sites} url={url} comboContext={buttonType} />,
    {
      small: true,
      priority: PopupPriority.LOW,
      autoLoadTracking: true,
      trackingName: `extraRMBundle_${buttonType}`,
    },
  );
};

export default action;
