import React from 'react';
import PropTypes from 'prop-types';

import useTargetUserData from '@phoenix/user/profile/target/utils/useTargetUserData';

import InAppMotivationPopup from './InAppMotivationPopup';
import MOTIVATION_TYPES from '../constants/motivationTypes';
import {GOOGLE_TYPE, INTENT_TYPE} from '../utils/formatLink';

const InAppMotivationPopupWithTargetUser = ({userId, ...props}) => {
  const {user, loading} = useTargetUserData({userId});

  return <InAppMotivationPopup {...props} user={user} loading={loading} />;
};

InAppMotivationPopupWithTargetUser.propTypes /* remove-proptypes */ = {
  type: PropTypes.oneOf(Object.values(MOTIVATION_TYPES)).isRequired,
  userId: PropTypes.string.isRequired,
  linkType: PropTypes.oneOf([GOOGLE_TYPE, INTENT_TYPE]),
  alterBtns: PropTypes.bool,
  loading: PropTypes.bool,
};

export default InAppMotivationPopupWithTargetUser;
