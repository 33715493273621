import React from 'react';
import PropTypes from 'prop-types';

import {PhotoRequestTypeEnum} from '@core/types';
import isMobileViewport from '@core/responsive/isMobileViewport';
import SIZE from '@core/user/photo/constants/PhotoSize';
import photoPropType from '@core/user/photo/constants/propTypes';
import MEDIA_ITEMS_GRID from '@core/user/profile/common/constants/MediaItemsGrid';

import Photo from '../../../photo/containers/Photo';
import css from '../styles/MediaItem.css';
import MediaWrapper from './MediaWrapper';
import PRIVATE_PHOTO_OVERLAY_TYPE from '../../../photo/constants/PrivatePhotoOverlayType';

const PhotoItem = ({
  photo,
  mediaCounter,
  menu,
  customOverlay,
  withNaughtyMode,
  onClick,
  size = SIZE.NORMAL,
  grid = MEDIA_ITEMS_GRID.FOUR_COLUMNS,
  userId,
  showOverlay,
  my = false,
  withShadow = true,
  onNaughtyModeClick,
  withLockOverlay,
}) => (
  <MediaWrapper grid={grid}>
    <Photo
      userId={userId}
      onNaughtyModeClick={onNaughtyModeClick}
      size={size}
      pendingDeleteShowDescription
      pendingDeleteOneColumn={isMobileViewport()}
      className={css.image}
      photo={photo}
      onClick={onClick}
      showOverlay={showOverlay}
      showOverlayContent={false}
      withNaughtyMode={withNaughtyMode}
      mediaCounter={mediaCounter}
      my={my}
      withShadow={withShadow}
      withLockOverlay={withLockOverlay}
      customOverlay={customOverlay}
      photoRequestData={{
        overlayType:
          photo?.privateAttributes?.requestType ===
          PhotoRequestTypeEnum.pmaPhoto
            ? PRIVATE_PHOTO_OVERLAY_TYPE.ICON
            : PRIVATE_PHOTO_OVERLAY_TYPE.DESCRIPTION,
      }}
    />
    {!photo.pendingDelete && menu}
  </MediaWrapper>
);

PhotoItem.propTypes /* remove-proptypes */ = {
  size: PropTypes.oneOf(Object.values(SIZE)),
  photo: photoPropType,
  onClick: PropTypes.func.isRequired,
  userId: PropTypes.string,
  mediaCounter: PropTypes.number,
  menu: PropTypes.node,
  customOverlay: PropTypes.node,
  grid: PropTypes.oneOf(Object.values(MEDIA_ITEMS_GRID)),
  showOverlay: PropTypes.bool,
  withNaughtyMode: PropTypes.bool,
  my: PropTypes.bool,
  withShadow: PropTypes.bool,
  withLockOverlay: PropTypes.bool,
  onNaughtyModeClick: PropTypes.func,
};

export default PhotoItem;
