import {
  DEBT_CREDITS_USING,
  DEBT_LIMIT_REACHED,
  NO_ENOUGH_COINS,
} from '@core/messenger/common/constants/reasons';
import MessageType from '@core/messenger/common/constants/messageType';
import PopupService from '@core/popup/utils/PopupService';

import goToCoinsPP from '@phoenix/coins/utils/goToCoinsPP';

import getViaForCoinsByType from '../../common/utils/getViaForCoinsByType';
import getViaForUnsentMessageByType from '../../common/utils/getViaForUnsentMessageByType';

/**
 * Goto buy coins after|before send message for different reasons
 * @param {string} reason
 * @param {string} messageType
 * @param {string|null} returnPath
 */
const gotoBuyCreditsOnPPForSendingReason = ({
  reason,
  messageType,
  returnPath = null,
}) => {
  // Close opening popup
  PopupService.closePopup();
  /**
   * Go to PP for no enough coins for all message types, except gift
   * Got to PP for gift type will be in another way @see sendGiftFromPlace
   *
   */
  if (reason === NO_ENOUGH_COINS && messageType !== MessageType.GIFT) {
    // For all message types
    goToCoinsPP(getViaForCoinsByType(messageType), returnPath);
  }

  /**
   * Need open buy credit popup for unsent message
   */
  if (
    reason === DEBT_LIMIT_REACHED ||
    (reason === DEBT_CREDITS_USING && messageType !== MessageType.GIFT)
  ) {
    goToCoinsPP(getViaForUnsentMessageByType(messageType), returnPath);
  }
};

export default gotoBuyCreditsOnPPForSendingReason;
