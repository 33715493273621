import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';
import animationsCss from '@phoenix/widgets/animation/animations.css';

import {ICONS} from '../constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';
import css from '../styles/MicrofeaturesIconSuperBoost.css';

const MicrofeaturesIconSuperBoost = ({
  withAnimation = true,
  active = false,
  ...props
}) => (
  <MicrofeaturesIconLayout
    icon={
      <Icon
        className={cn(withAnimation && animationsCss.boost, css.icon)}
        type={ICONS[MICROFEATURES.SUPER_BOOST]}
        inherit={!active}
      />
    }
    type={MicrofeaturesIconLayout.TYPE.SUCCESS}
    active={active}
    {...props}
  />
);

MicrofeaturesIconSuperBoost.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconSuperBoost.propTypes /* remove-proptypes */ = {
  withAnimation: PropTypes.bool,
  active: PropTypes.bool,
};

export default MicrofeaturesIconSuperBoost;
