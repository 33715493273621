import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import usePaymentStatus from '@core/payment/common/utils/usePaymentStatus';

import useCoinsModel from '@phoenix/coins/utils/useCoinsModel';

import SplitChunk from '../components/SplitChunk';

const LazyPayReactChunk = lazy(() =>
  isCompactViewportCached()
    ? import(
        /* webpackChunkName: 'payReactMob' */ '@phoenix/payment/routes/mob/PayReactCommonRoute'
      )
    : import(
        /* webpackChunkName: 'payReactWeb' */ '@phoenix/payment/routes/web/PayReactCommonRoute'
      ),
);

const PayReactChunk = ({location, match, ...props}) => {
  const {loading, isPaid} = usePaymentStatus();

  const {loading: modelLoading, isCoinsModel} = useCoinsModel();

  const {pathname, originalUrl} = location;

  const {url} = match;

  if (loading || modelLoading) {
    return null;
  }

  const payPackageUrl = `${url}/${PAYMENT_ACTIONS.PACKAGE}`;

  if (pathname === payPackageUrl) {
    const action = isPaid
      ? PAYMENT_ACTIONS.FEATURES
      : PAYMENT_ACTIONS.MEMBERSHIP;

    const correctedUrl = originalUrl
      .replace(payPackageUrl, `${url}/${action}`)
      .replace('packageId', 'stockId');

    return <Redirect to={correctedUrl} push={false} />;
  }

  return (
    <SplitChunk
      component={LazyPayReactChunk}
      location={location}
      match={match}
      isCoinsModel={isCoinsModel}
      {...props}
    />
  );
};

PayReactChunk.propTypes /* remove-proptypes */ = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    originalUrl: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default PayReactChunk;
