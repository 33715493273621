/* global MOCKED_API_ENABLED */

import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useApolloClient} from '@apollo/client';

import addScriptNode from '@core/application/utils/addScriptNode';
import {getCookie} from '@core/utils/cookie';
import {GOOGLE_PAY_TEST_ENVIRONMENT} from '@core/payment/common/constants/walletVisualTestCookies';
import BASE_ROUTES from '@core/application/constants/routes';
import APPLE_PAY_DISPLAY_SETTINGS_QUERY from '@core/payment/widgets/applePay/graphql/queries/applePayDisplaySettings.gql';
import canMakeApplePayPayments from '@core/payment/widgets/applePay/utils/canMakeApplePayPayments';
import {
  GOOGLE_API_DOM_ID,
  GOOGLE_PAY_SERVICE_LINK,
} from '@core/payment/widgets/googlePay/constants/googlePay';
import getGooglePayPaymentsClient from '@core/payment/widgets/googlePay/utils/getGooglePayPaymentsClient';

import GOOGLE_PAY_DISPLAY_SETTINGS_QUERY from '../../widgets/googlePay/graphql/queries/googlePayDisplaySettings.gql';

/**
 * Flag for preload data once
 * @type {boolean}
 */
let executedOnce = false;

/**
 * Preload wallet data for popup and payment page
 * @return {null}
 * @constructor WalletPreloader
 */
const WalletPreloader = ({pathname}) => {
  const client = useApolloClient();

  useEffect(() => {
    // Preload once, and not for all routes
    if (executedOnce || pathname === BASE_ROUTES.PWA_NEXT_STEP) {
      return;
    }

    // Get setting for Google Pay and check on availability
    client
      .query({query: GOOGLE_PAY_DISPLAY_SETTINGS_QUERY})
      .then(({data}) => {
        const settings =
          data?.payment?.cardWalletDisplaySettings?.googlePayDisplaySettings;

        if (
          (settings || getCookie(GOOGLE_PAY_TEST_ENVIRONMENT)) &&
          !MOCKED_API_ENABLED
        ) {
          addScriptNode({
            id: GOOGLE_API_DOM_ID,
            src: window.IS_INTEGRATION_TEST_ENVIRONMENT
              ? ''
              : GOOGLE_PAY_SERVICE_LINK,
            onLoad: () => {
              getGooglePayPaymentsClient(settings);
            },
          });
        }
      })
      .catch((e) => {
        /**
         * Don't need sentry error logs, pay process are established, and errors appear only with bad internet
         * connection, request cancel and similar
         */
        window.console.warn(`Google pay ${e}`);
      });

    // Get setting for Apple Pay  and check on availability
    client
      .query({query: APPLE_PAY_DISPLAY_SETTINGS_QUERY})
      .then((resp) => {
        const merchantId =
          resp?.data?.payment?.cardWalletDisplaySettings
            ?.applePayDisplaySettings?.merchantId;

        if (merchantId) {
          canMakeApplePayPayments(merchantId);
        }
      })
      .catch((e) => {
        /**
         * Don't need sentry error logs, pay process are established, and errors appear only with bad internet
         * connection, request cancel and similar
         */
        window.console.warn(`Apple pay ${e}`);
      });

    executedOnce = true;
  }, [client, pathname]);

  return null;
};

WalletPreloader.propTypes /* remove-proptypes */ = {
  pathname: PropTypes.string.isRequired,
};

export default WalletPreloader;
