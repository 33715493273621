import {combineLatest, fromEvent} from 'rxjs';
import {filter, startWith, take} from 'rxjs/operators';

import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isPayUrl from '@core/utils/url/isPayUrl';
import isInRouteList from '@core/utils/routing/isInRouteList';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

import PHOTO_UPLOAD_MOTIVATION_SETTINGS_QUERY from '@phoenix/mediaUpload/graphql/queries/photoUploadMotivationSettings.gql';
import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';

import openMicrofeaturesSuccessPopup from '../../popups/utils/openMicrofeaturesSuccessPopup';
import MICROFEATURE_ASSIGNED_SUBSCRIPTION from '../graphql/subscriptions/microfeatureAssigned.gql';
import MICROFEATURES_AVAILABILITY_DATA_QUERY from '../graphql/queries/microfeaturesAvailabilityData.gql';

export const DISABLED_ROUTES = [
  '/videoChat',
  '/rooms',
  '/chat',
  '/forbidden',
  '/getTheApp',
  '/notFound',
  '/trusted',
  '/browserInfo',
  '/newsFeed',
  ...ROUTES_WITH_POPUP,
];

// Avoid new subscriptions.
let started = false;

const startMicrofeatureAssignedListener = async (client) => {
  if (started) return;
  started = true;

  const {
    data: {
      userFeatures: {
        photoUploadMotivationSettings: {enabled},
      },
    },
  } = await client.query({
    query: PHOTO_UPLOAD_MOTIVATION_SETTINGS_QUERY,
  });

  const {
    data: {
      microFeatures: {microFeaturesConfiguration},
    },
  } = await client.query({
    query: MICROFEATURES_AVAILABILITY_DATA_QUERY,
  });

  const modelType = microFeaturesConfiguration?.modelType || null;

  if (!enabled) return;

  const signal$ = client.subscribe({query: MICROFEATURE_ASSIGNED_SUBSCRIPTION});

  const popupState$ = fromEvent(document, POPUP_STATE_CHANGE_EVENT).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
  );

  const routeState$ = client.watchQuery({query: ROUTE_QUERY});

  const source$ = combineLatest([signal$, routeState$, popupState$]).pipe(
    filter(
      ([
        ,
        {
          data: {route},
        },
        {detail},
      ]) =>
        !isPayUrl(route.current) &&
        !isInRouteList(DISABLED_ROUTES, route.current) &&
        !detail.hasQueue,
    ),
    take(1),
  );

  source$.subscribe(
    ([
      {
        data: {
          microFeatures: {microFeatureList},
        },
      },
    ]) => {
      const [{featureName}] = microFeatureList;

      if (modelType) {
        updateCachesAfterPayment();
      }

      openMicrofeaturesSuccessPopup({featureName});
    },
  );
};

export default startMicrofeatureAssignedListener;
