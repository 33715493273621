import type {FC, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import Size from '@core/search/widget/constants/size';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import type SearchWidgetAppearance from '@core/theming/constants/features/SearchWidgetAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import type {Align} from '@phoenix/ui/constants';

import css from '../styles/SearchWidgetSpacing.css';

type SearchWidgetSpacingProps = {
  size?: Size;
  children: ReactNode;
  align?: Align;
  withoutBottom?: boolean;
  isRmBanner?: boolean;
  className?: string;
  trackingName?: string;
  onClick?: () => void;
};

/**
 * Just a reusable component for spacing between widgets on search results. Used in such places.
 * @see SearchWidgetLayout
 *
 * Be aware that such component will not work correctly if you use it without wrapping widgets in
 * @see SearchPage
 * If you need spacing in some other place - make spacings there as separated component
 */
const SearchWidgetSpacing: FC<SearchWidgetSpacingProps> & {
  SIZE: typeof Size;
} = ({
  size = Size.FLUID,
  children,
  align,
  withoutBottom,
  isRmBanner,
  className,
  trackingName,
  ...props
}) => {
  const {data: feature} = useThemeFeature<SearchWidgetAppearance>(
    'search',
    'widget',
  );

  const content = (
    <div
      {...props}
      className={cn(
        css.widget,
        css[size],
        align && css[align],
        css[feature],
        withoutBottom && css.withoutBottom,
        isRmBanner && css.rmBanner,
        className,
      )}
    >
      {children}
    </div>
  );

  return trackingName ? (
    <AddBabciaUBTracking trackingName={trackingName}>
      {content}
    </AddBabciaUBTracking>
  ) : (
    content
  );
};

SearchWidgetSpacing.SIZE = Size;

export default SearchWidgetSpacing;
