import {readFromLocalStorage} from '@core/utils/storage';

import {PAID_FUNNEL_SKIP_STRICT_STORAGE_KEY} from '@phoenix/funnel/paidFunnel/constants/storageKeys';

import {FULL_FUNNEL_OPEN_PAY} from '../constants/marketingProductConfigurationName';
import MARKETING_PRODUCT_CONFIGURATION_QUERY from '../graphql/queries/marketingProductConfiguration.gql';
import {FUNNEL_PAID} from '../constants/accessErrorCode';

/**
 * Paid funnel without skip access rule.
 */
export default {
  errorCode: FUNNEL_PAID,
  query: MARKETING_PRODUCT_CONFIGURATION_QUERY,
  resolver: (data) =>
    data?.userFeatures?.marketingProductConfigurationName !==
      FULL_FUNNEL_OPEN_PAY ||
    Boolean(readFromLocalStorage(PAID_FUNNEL_SKIP_STRICT_STORAGE_KEY)),
};
