import React from 'react';

import pciCertifiedUrl from '@core/payment/widgets/securityLogos/images/pciCertified.svg';

import css from '../styles/PciCertified.css';

const PciCertified = () => {
  return <img className={css.size} src={pciCertifiedUrl} alt="Pci Certified" />;
};

export default PciCertified;
