import React from 'react';

import Field from '@core/form/AbstractFormField';

import {Textarea} from '@phoenix/ui';

/**
 * Textarea field implementation over abstract field
 */
const FormTextarea = (props) => <Field component={Textarea} {...props} />;

export default FormTextarea;
