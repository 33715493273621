import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconIncognito from '../components/MicrofeaturesIconIncognito';
import MicrofeaturesIconMoreLikes from '../components/MicrofeaturesIconMoreLikes';
import MicrofeaturesIconPriorityMan from '../components/MicrofeaturesIconPriorityMan';
import MicrofeaturesIconSuperBoost from '../components/MicrofeaturesIconSuperBoost';
import MicrofeaturesIconSexAlarm from '../components/MicrofeaturesIconSexAlarm';
import MicrofeaturesIconTopInChat from '../components/MicrofeaturesIconTopInChat';
import MicrofeaturesIconMicrofeatureOffer from '../components/MicrofeaturesIconMicrofeatureOffer';
import MicrofeaturesIconTelegramConversation from '../components/MicrofeaturesIconTelegramConversation';

/**
 * Separate icon components to separate specific styles for colors and animations
 */
export const MICROFEATURES_ICON = {
  [MICROFEATURES.MICRO_INCOGNITO]: MicrofeaturesIconIncognito,
  [MICROFEATURES.MORE_LIKES]: MicrofeaturesIconMoreLikes,
  [MICROFEATURES.PRIORITY_MAN]: MicrofeaturesIconPriorityMan,
  [MICROFEATURES.SEARCH_QUEEN]: MicrofeaturesIconPriorityMan,
  [MICROFEATURES.SUPER_BOOST]: MicrofeaturesIconSuperBoost,
  [MICROFEATURES.FLIRT_ALARM]: MicrofeaturesIconSexAlarm,
  [MICROFEATURES.SEX_ALARM]: MicrofeaturesIconSexAlarm,
  [MICROFEATURES.TOP_IN_CHAT]: MicrofeaturesIconTopInChat,
  [MICROFEATURES.MICROFEATURE_OFFER]: MicrofeaturesIconMicrofeatureOffer,
  [MICROFEATURES.TELEGRAM_CONVERSATION]: MicrofeaturesIconTelegramConversation,
};
