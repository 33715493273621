import useAltMethodsSettings from '@core/payment/common/utils/useAltMethodsSettings';

const useSepaSettings = (method) => {
  const {settings, loading, error} = useAltMethodsSettings({
    paymentMethod: method,
  });

  return {
    loading,
    error,
    maskedFields: settings?.maskedFields,
    isSepaMandate: Boolean(settings?.maskedFields),
  };
};

export default useSepaSettings;
