import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import WeekSpeakOfferPopup from '../containers/WeekSpeakOfferPopup';

const openSpecialOfferPopup = () => {
  PopupService.openPopup(<WeekSpeakOfferPopup />, {
    trackingName: 'weekSpeakOffer',
    inverseCloseButton: true,
    small: true,
  });
};

export default openSpecialOfferPopup;
