import {from} from 'rxjs';
import {filter, pairwise, take} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';

/**
 * @const {Object} Popunder user actions .
 */
export const USER_ACTIONS = {
  PAYMENT: 'payment',
};

export const POPUNDER_ACTIONS_MAP = {
  /**
   * Refetch popunder data with a delay after payment
   */
  [USER_ACTIONS.PAYMENT]: () => {
    const client = getClientInstance();

    return from(client.watchQuery({query: PAYMENT_ACCOUNT_STATUS_QUERY})).pipe(
      pairwise(),
      filter(([prev, current]) => {
        return (
          !prev.data.payment.accountStatus.isPaid &&
          current.data.payment.accountStatus.isPaid
        );
      }),
      take(1),
    );
  },
};
