import isBoolean from 'lodash/isBoolean';

import {getClientInstance} from '@core/graphql/client';
import USER_VIEW_PERMISSIONS_FRAGMENT from '@core/user/profile/target/graphql/fragments/userViewPermissions.gql';
import type {UserViewPermissionsFragment} from '@core/user/profile/target/graphql/fragments/userViewPermissions';

type GetUserViewPermissionFragmentResult = {
  data: UserViewPermissionsFragment | null;
  fragmentId: string;
};

/**
 * Get user view permissions fragment
 */
export const getUserViewPermissionFragment = (
  userId: string,
): GetUserViewPermissionFragmentResult => {
  const client = getClientInstance();
  const fragmentId = `UserData:${userId}`;

  const data = client.readFragment<UserViewPermissionsFragment>({
    id: fragmentId,
    fragment: USER_VIEW_PERMISSIONS_FRAGMENT,
  });

  return {
    data,
    fragmentId,
  };
};

type UserViewPermission = {
  photo?: boolean;
  video?: boolean;
};

/**
 * Set provided user view permissions in cache
 */
const updateUserViewPermission = (
  userId: string,
  {photo, video}: UserViewPermission,
): void => {
  const {data, fragmentId} = getUserViewPermissionFragment(userId);

  if (data) {
    getClientInstance().writeFragment<UserViewPermissionsFragment>({
      id: fragmentId,
      fragment: USER_VIEW_PERMISSIONS_FRAGMENT,
      data: {
        ...data,
        viewPermissions: {
          ...data.viewPermissions,
          photo: isBoolean(photo) ? photo : data.viewPermissions.photo,
          video: isBoolean(video) ? video : data.viewPermissions.video,
        },
      },
    });
  }
};

export default updateUserViewPermission;
