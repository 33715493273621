import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyWrongDevice = lazy(
  () => import('@phoenix/exceptions/components/WrongDevice'),
);

const WrongDeviceChunk = (props) => (
  <SplitChunk component={LazyWrongDevice} {...props} />
);

export default WrongDeviceChunk;
