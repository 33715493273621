import t from '@core/translations/translate';

import {IconType} from '@phoenix/ui/constants';
import animationCss from '@phoenix/widgets/animation/animations.css';

import FreeFeaturePopupTypes from '../constants/freeFeaturePopupTypes';

/**
 * @param {Object} params
 * @param {String} params.view
 * @param {String} params.like
 * @param {String} params.message
 * @param {Number} params.price
 */
const boostUpsellConfig = ({view, like, message, price}) => ({
  type: FreeFeaturePopupTypes.STATISTIC,
  animationClass: animationCss.boost,
  title: t('upsellPopup', 'boost.title'),
  description: t('upsellPopup', 'upsell.text'),
  bottomNotice: t('microfeatures', 'text.feature_costs', {
    '{price}': price,
    '{n}': 1,
  }),
  actionText: t('upsellPopup', 'boost.button'),
  icon: 'lightning',
  iconType: IconType.SUCCESS,
  benefits: [
    {
      icon: 'eye',
      type: IconType.PRIMARY,
      title: view,
      text: t('microfeatures', 'text.views'),
    },
    {
      icon: 'heart',
      type: IconType.DANGER,
      title: like,
      text: t('microfeatures', 'text.likes'),
    },
    {
      icon: 'mail',
      type: IconType.ACCENT,
      title: message,
      text: t('microfeatures', 'text.messages'),
    },
  ],
});

export default boostUpsellConfig;
