import {from, of, empty, pipe} from 'rxjs';
import {switchMapTo, switchMap, take} from 'rxjs/operators';

import CANCEL_REPEAT_24H_QUERY from '@phoenix/multiStep/cancelBilling/graphql/queries/cancelRepeat24hQuery.gql';
import showCancelRepeatPopup from '@phoenix/multiStep/cancelBilling/utils/showCancelRepeatPopup';

/**
 * Open cancel 24 repeat popup if is available
 *
 * @param {ApolloClient} client
 *
 * @returns {Object} result
 * @returns {Function} result.operations
 * @returns {Function} result.subscription
 */
const cancel24RepeatPopupAction = (client) => ({
  operations: () =>
    pipe(
      switchMapTo(
        from(
          client.query({
            query: CANCEL_REPEAT_24H_QUERY,
          }),
        ),
      ),
      switchMap(({data}) => {
        const type = data.userFeatures.cancelRepeat24h;
        // If type wasn't provided - terminate all.
        return type ? of(type) : empty();
      }),
      // Since we can trigger single popup only one time
      take(1),
    ),
  subscription: (type) => {
    showCancelRepeatPopup({
      type,
    });
  },
});

export default cancel24RepeatPopupAction;
