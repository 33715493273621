import type {ReactNode, FC} from 'react';
import React, {useMemo} from 'react';
import cn from 'classnames';

import useTabletBreakpoint from '@core/responsive/useTabletBreakpoint';
import MountTracker from '@core/tracking/babcia/containers/MountTracker';

import {Icon, Spacing, RowLarge} from '@phoenix/ui';
import {Heading} from '@phoenix/typography';
import {SpacingSize, IconSize} from '@phoenix/ui/constants';
import DisapproveNotice from '@phoenix/disapprove/components/DisapproveNotice';

import css from '../styles/SectionLayout.css';

type SectionLayoutProps = {
  children?: ReactNode;
  title?: ReactNode;
  icon?: ReactNode | string;
  onDisapprovedClick?: () => void;
  disapproveType?: string;
  small?: boolean;
  contentSpacedLeft?: boolean;
  withInnerSpacing?: boolean;
  withInnerSideSpacing?: boolean;
  withBorder?: boolean;
  spaced?: boolean;
  'data-test'?: string;
  withoutExternalBottomSpacing?: boolean;
  withoutSpacingBottom?: boolean;
  withoutSpacingRight?: boolean;
  withoutSpacingLeft?: boolean;
  withoutSpacingTop?: boolean;
  withIcon?: boolean;
  titleSpacing?: SpacingSize;
};

/**
 * Wrapping layout for sections, used inside current and target
 * uses profile pages
 */
const SectionLayout: FC<SectionLayoutProps> = ({
  children,
  title,
  icon,
  withIcon = true,
  disapproveType,
  onDisapprovedClick,
  contentSpacedLeft = true,
  spaced = true,
  withInnerSpacing,
  withInnerSideSpacing,
  withBorder,
  withoutExternalBottomSpacing,
  small = false,
  titleSpacing,
  withoutSpacingBottom,
  withoutSpacingRight = false,
  withoutSpacingLeft = false,
  withoutSpacingTop,
  'data-test': dataTest,
}) => {
  const isTablet = useTabletBreakpoint();
  const smallSize = small || isTablet;

  const spacingSize = useMemo(() => {
    if (!children) {
      return SpacingSize.NONE;
    }
    if (titleSpacing) {
      return titleSpacing;
    }
    return smallSize ? SpacingSize.SHORT : SpacingSize.NORMAL;
  }, [children, titleSpacing, smallSize]);

  return (
    <Spacing
      className={cn(
        smallSize && css.small,
        withBorder && css.withBorder,
        withoutExternalBottomSpacing && css.withoutExternalBottomSpacing,
        css.container,
      )}
      withoutLeft={withoutSpacingLeft || !withInnerSideSpacing}
      withoutRight={withoutSpacingRight || !withInnerSideSpacing}
      withoutBottom={withoutSpacingBottom}
      withoutTop={withoutSpacingTop}
      size={withInnerSpacing ? SpacingSize.NORMAL : SpacingSize.NONE}
      data-test={dataTest}
    >
      <div className={css.content}>
        {title && (
          <Spacing onlyBottom size={spacingSize} className={css.title}>
            {withIcon &&
              (typeof icon === 'string' ? (
                <RowLarge
                  className={css.iconHolder}
                  horizontal
                  flexibleWidth={false}
                >
                  {disapproveType ? (
                    <DisapproveNotice
                      type={disapproveType}
                      onClick={onDisapprovedClick}
                      small={smallSize}
                    />
                  ) : (
                    <Icon
                      type={icon}
                      standard
                      size={smallSize ? IconSize.SIZE_24 : IconSize.SIZE_36}
                    />
                  )}
                </RowLarge>
              ) : (
                icon
              ))}
            {title && <Heading level={smallSize ? 4 : 2}>{title}</Heading>}
          </Spacing>
        )}
        {children && (
          <div
            className={cn(
              css.inner,
              withIcon && spaced && contentSpacedLeft && css.spacedLeft,
            )}
          >
            {children}
          </div>
        )}
      </div>
      <MountTracker name={MountTracker.NAMES.PROFILE_SECTION} />
    </Spacing>
  );
};

export default SectionLayout;
