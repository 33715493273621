import React, {forwardRef} from 'react';
import type {LinkProps as RouterLinkProps} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';
import createRouteName from '@core/tracking/babcia/utils/createRouteName';

export type LinkProps = Omit<RouterLinkProps, 'to'> & {
  trackingName?: string;
  to: string;
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({trackingName, to, children, ...props}, ref) => (
    <AddBabciaUBTracking
      trackingName={`${trackingName || createRouteName(to)}Link`}
    >
      <RouterLink to={to} ref={ref} {...props}>
        {children}
      </RouterLink>
    </AddBabciaUBTracking>
  ),
);

export default Link;
