import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import PopupPriority from '@core/popup/constants/PopupPriority';

import TargetCustomPopupLayout from '@phoenix/webpush/components/TargetCustomPopupLayout';

type TargetCustomPopupOptions = {
  onAccept: () => void;
  onClose?: () => void;
  onDeny: () => void;
  popupType: string;
  userId?: string;
};

/**
 * Open custom popup that looks like native
 * triggered on actions: [WS_ACTIONS, ROUTE]
 */
export default function openTargetCustomPopup({
  onAccept,
  onClose,
  onDeny,
  popupType,
  userId,
}: TargetCustomPopupOptions): void {
  PopupService.openPopup(
    <TargetCustomPopupLayout
      popupType={popupType}
      userId={userId}
      onAcceptClick={() => {
        PopupService.closePopup();
        onAccept();
      }}
      onDenyClick={() => {
        PopupService.closePopup();
        onDeny();
      }}
    />,
    {
      onClose: (sourceEventType) => {
        if (
          [
            PopupSourceEvent.CLOSE_BUTTON_CLICK,
            PopupSourceEvent.BY_ESCAPE,
          ].includes(sourceEventType) &&
          onClose
        ) {
          onClose();
        }
      },
      small: true,
      trackingName: 'webPushNativeLike',
      trackingConceptId: `${popupType}_${userId}_single`,
      priority: PopupPriority.LOW,
    },
  );
}
