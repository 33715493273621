import React from 'react';

import PaymentDetailsCommon from '@core/payment/widgets/details/containers/PaymentDetails';

import PaymentDetailsLayout from './PaymentDetailsLayout';

const PaymentDetails = (props) => (
  <PaymentDetailsCommon {...props} layout={PaymentDetailsLayout} />
);

export default PaymentDetails;
