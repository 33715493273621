import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import t from '@core/translations/translate';

import {Icon, Overlay} from '@phoenix/ui';
import {IconSize, OverlaySaturation} from '@phoenix/ui/constants';

import css from '../styles/PendingDeleteOverlay.css';

type PendingDeleteOverlayProps = {
  oneColumn?: boolean;
  showDescription?: boolean;
};

/**
 * Pending delete functionality overlay.
 * Shows that photo is "deleting" over user photo
 */
const PendingDeleteOverlay: FC<PendingDeleteOverlayProps> = ({
  oneColumn,
  showDescription,
}) => {
  return (
    <Overlay saturation={OverlaySaturation.LITE}>
      <div className={cn(css.content, oneColumn && css.oneColumn)}>
        <Icon type="trash" inverse size={IconSize.SIZE_24} />
        {showDescription && (
          <div className={css.description}>
            {t('profile', 'text.pending_delete')}
          </div>
        )}
      </div>
    </Overlay>
  );
};

export default PendingDeleteOverlay;
