import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

import fetchData from '@core/application/utils/fetchData';
import URI from '@core/utils/url';
import trackPaymentDescriptor from '@core/payment/widgets/descriptor/utils/trackPaymentDescriptor';
import getPaymentDescriptorTrackData from '@core/payment/widgets/descriptor/utils/getPaymentDescriptorTrackData';
import getPaymentDescriptorText from '@core/payment/widgets/descriptor/utils/getPaymentDescriptorText';
import showRedirectUrl from '@core/payment/payProcess/utils/showRedirectUrl';
import isCardMethods from '@core/payment/payProcess/utils/isCardMethods';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';

import {REMARKETING_SHOW_POPUP} from '@phoenix/remarketing/offerPopup/constants/remarketingViaList';
import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';

import RemarketingPopupIbanPayModel from '@legacy/application/components/remarketingPopup/models/RemarketingPopupIbanPayModel';
import RemarketingPopupPayModel from '@legacy/application/components/remarketingPopup/models/RemarketingPopupPayModel';

const formatRedirectUrl = (redirectUrl, returnPath, orderId) => {
  if (!returnPath) {
    return redirectUrl;
  }

  const url =
    isArray(redirectUrl) && redirectUrl.length === 1
      ? redirectUrl[0]
      : redirectUrl;

  if (isString(url)) {
    const urlObject = URI(url);

    if (!urlObject.hasSearch('returnPath')) {
      urlObject.addSearch('returnPath', returnPath);
    }

    if (orderId && !urlObject.hasSearch('orderId')) {
      urlObject.addSearch('orderId', orderId);
    }

    return urlObject.toString();
  }

  url.returnPath = returnPath;

  return url;
};

/**
 * @deprecated
 */
const payForRemarketingOfferLegacy = ({
  stockId,
  packageId,
  currencyCode,
  cancelRemarketingSubscription,
  method,
  via,
  returnPath,
  selectedAdditionalPackages,
}) => {
  const paymentData = {
    package_id: stockId,
    via,
    selectedAdditionalPackages,
  };

  const altMethodPaymentData = {
    method,
    product_id: packageId,
    currency_code: currencyCode,
    /**
     * TODO ATTENTION Mezhuev said that thees fields don't need on server.
     * Need check paying without them, if all good - remove them, otherwise set task on BU to modify pay/alternative logic
     * BECAUSE THEES FIELDS server already know
     */
    // eslint-disable-next-line
    user_id: undefined,
    // eslint-disable-next-line
    domain: undefined,
    // eslint-disable-next-line
    locale: undefined,
    ...paymentData,
  };

  let model;
  const isCard = isCardMethods(method);

  if (isCard) {
    model = new RemarketingPopupPayModel(paymentData);
  } else {
    model = new RemarketingPopupIbanPayModel(altMethodPaymentData);
  }

  /**
   * @TODO need to send isCancelSubscription in pay request and do it on backend
   */
  model.on('pay:complete', (data) => {
    const orderId = model.get('orderId');
    const redirectUrl = data && data.redirectUrl;

    if (redirectUrl) {
      model.set(
        'redirectUrl',
        formatRedirectUrl(redirectUrl, returnPath, orderId),
      );
    }

    if (!orderId) {
      showRedirectUrl(model.get('redirectUrl'));
      model.navigateToNextPage();

      return;
    }

    /**
     * Tracking payment descriptor is enabled only for card payment method
     */
    if (isCard) {
      const {descriptor, companyName, hideDescriptor} =
        getPaymentDescriptorTrackData();

      if (!hideDescriptor) {
        trackPaymentDescriptor({
          orderId,
          action: via,
          descriptor,
          descriptorText: getPaymentDescriptorText({
            descriptor,
            companyName,
          }),
          via: REMARKETING_SHOW_POPUP,
        });
      }
    }

    Promise.all([
      updateCachesAfterPayment(true, ['UserFeatures', 'remarketingPopup']),
      cancelRemarketingSubscription
        ? fetchData({
            url: '/api/v1/remarketingPopup/cancel',
            body: {orderId},
          })
        : Promise.resolve(),
    ]).then(() => {
      showRedirectUrl(model.get('redirectUrl'));

      // If payment was declined, we need to show the correct decline message depending on the decline template
      if (data.status === false && data.declineScreenTemplate) {
        processPaymentAnswer({paymentResult: data, paymentData: {}});
      }

      model.navigateToNextPage();
    });
  });
  model.save();
};

export default payForRemarketingOfferLegacy;
