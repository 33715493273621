import once from 'lodash/once';

import SECONDARY_DATA_QUERY from '../graphql/queries/secondaryData.gql';

const preloadSecondaryDataOnce = once(({client, isReelsAvailable = false}) => {
  /**
   * {@see BLOCKED_QUERIES} may delay this query.
   */
  client
    .query({
      query: SECONDARY_DATA_QUERY,
      variables: {
        isReelsAvailable,
      },
      errorPolicy: 'all',
    })
    .catch(() => {});
});

export default preloadSecondaryDataOnce;
