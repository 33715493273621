import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import ExtraRMBundlePopupLayout from '../components/ExtraRMBundlePopupLayout';
import {RM_BUNDLE_ONE_MONTH} from '../../../constants/comboContextTypes';

const ExtraRMBundlePopup = ({url, sites, comboContext}) => {
  const bundleInfo = useMemo(() => {
    const amountSites = {
      '{amountSites}': sites.length,
    };
    return comboContext === RM_BUNDLE_ONE_MONTH
      ? {
          description: t('extraPopups', 'text.one_site', amountSites),
          textPackage: t('extraPopups', 'text.one_month_membership'),
        }
      : {
          description: t('extraPopups', 'text.some_sites', amountSites),
          textPackage: t('extraPopups', 'text.one_week_membership'),
        };
  }, [comboContext, sites]);

  return (
    <ExtraRMBundlePopupLayout
      sites={sites}
      url={url}
      description={bundleInfo.description}
      textPackage={bundleInfo.textPackage}
    />
  );
};

ExtraRMBundlePopup.propTypes /* remove-proptypes */ = {
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.string.isRequired,
      domain: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        default: PropTypes.string.isRequired,
        inverse: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  comboContext: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ExtraRMBundlePopup;
