import UserActionsAppearance from '@core/theming/constants/features/UserActionsAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43656
 */
type UserActionsComponent = any;

const resolver: ModuleResolver<UserActionsAppearance, UserActionsComponent> = (
  value,
) => {
  switch (value) {
    case UserActionsAppearance.ROUNDED:
      return import(
        /* webpackChunkName: 'roundedUserActions' */ '@phoenix/user/actions/constants/roundedUserActions'
      );
    case UserActionsAppearance.VERTICAL:
      return import(
        /* webpackChunkName: 'verticalUserActions' */ '@phoenix/user/actions/constants/verticalUserActions'
      );
    case UserActionsAppearance.ROUNDED_REVERSE:
      return import(
        /* webpackChunkName: 'rounderReverseUserActions' */ '@phoenix/user/actions/constants/roundedReverseUserActions'
      );
    case UserActionsAppearance.ROUND_AND_COLORIZED:
      return import(
        /* webpackChunkName: 'roundAndColorizedUserActions' */ '@phoenix/user/actions/constants/roundAndColorizedUserActions'
      );
    case UserActionsAppearance.NARROW_WITH_DIVIDER:
      return import(
        /* webpackChunkName: 'narrowWithDividerUserActions' */ '@phoenix/user/actions/constants/narrowWithDividerUserActions'
      );
    case UserActionsAppearance.WITH_MAIN_FLIRT_ACTION:
      return import(
        /* webpackChunkName: 'withMainFlirtActionUserActions' */ '@phoenix/user/actions/constants/withMainFlirtActionUserActions'
      );
    case UserActionsAppearance.FLAT_ROUND_WITH_SEPARATED_FAVORITE:
      return import(
        /* webpackChunkName: 'flatRoundWithSeparatedFavoriteUserActions' */ '@phoenix/user/actions/constants/flatRoundWithSeparatedFavoriteUserActions'
      );
    default:
      return null;
  }
};

const useThemedUserActions = () => {
  const {data, loading} = useThemeCompositeFeatureLoader<
    UserActionsAppearance,
    UserActionsComponent
  >('userActions', 'appearance', resolver);

  return {data: data ?? {}, loading};
};

export default useThemedUserActions;
