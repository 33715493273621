import React, {lazy} from 'react';

import isCompactViewportCached from '@core/responsive/isCompactViewportCached';

import SplitChunk from '../components/SplitChunk';

const LazyPayCommon = lazy(() => {
  return isCompactViewportCached()
    ? import(/* webpackChunkName: 'payMob' */ '../routes/mob/PayCommonRoute')
    : import(/* webpackChunkName: 'payWeb' */ '../routes/web/PayCommonRoute');
});

/**
 * Main payment flow chunk. Includes most used routes where user can visit payment page. Such:
 * 1. Membership action
 * 2. Features action
 * 3. Result (Payment success) action.
 *
 * All other payment functionalities are split in different chunks, because there are
 * mostly rare cases when user reaches those pages.
 */
export const PayCommonChunk = (props) => (
  <SplitChunk component={LazyPayCommon} {...props} />
);

const LazyPayRemarketing = lazy(() =>
  isCompactViewportCached()
    ? import(
        /* webpackChunkName: 'payRemarketingMob' */ '../routes/mob/PayRemarketingRoute'
      )
    : import(
        /* webpackChunkName: 'payRemarketingWeb' */ '../routes/web/PayRemarketingRoute'
      ),
);

/**
 * Remarketing post-transaction page.
 */
export const PayRemarketingChunk = (props) => (
  <SplitChunk component={LazyPayRemarketing} {...props} />
);

const LazyPaySepa = lazy(
  () =>
    import(/* webpackChunkName: 'paySepaMob' */ '../routes/mob/PaySepaRoute'),
);

/**
 * Sepa payment page.
 */
export const PaySepaChunk = (props) => (
  <SplitChunk component={LazyPaySepa} {...props} />
);

const LazyPaySofort = lazy(
  () =>
    import(
      /* webpackChunkName: 'paySofortMob' */ '../routes/mob/PaySofortRoute'
    ),
);

/**
 * Sepa payment page.
 */
export const PaySofortChunk = (props) => (
  <SplitChunk component={LazyPaySofort} {...props} />
);

const LazyPayTrustPaySepa = lazy(
  () =>
    import(
      /* webpackChunkName: 'payTrustPaySepaMob' */ '../routes/mob/PayTrustPaySepaRoute'
    ),
);

/**
 * Sepa payment page.
 */
export const PayTrustPaySepaChunk = (props) => (
  <SplitChunk component={LazyPayTrustPaySepa} {...props} />
);

const LazyPayIdeal = lazy(
  () =>
    import(/* webpackChunkName: 'paySepaMob' */ '../routes/mob/PayIdealRoute'),
);

/**
 * 'trustPayIDeal' payment page.
 */
export const PayIdealChunk = (props) => (
  <SplitChunk component={LazyPayIdeal} {...props} />
);

const LazyPayKonbini = lazy(
  () =>
    import(
      /* webpackChunkName: 'payKobiniMob' */ '../routes/mob/PayKonbiniRoute'
    ),
);

/**
 * konbini payment page.
 */
export const PayKonbiniChunk = (props) => (
  <SplitChunk component={LazyPayKonbini} {...props} />
);

const LazyPayMessagePage = lazy(() =>
  isCompactViewportCached()
    ? import(
        /* webpackChunkName: 'paymentPageCompact' */ '@phoenix/payment/pages/containers/PaymentPageCompact'
      )
    : import(
        /* webpackChunkName: 'paymentPagePayMessage' */ '@phoenix/payment/pages/containers/PayMessagePage'
      ),
);

/**
 * Buy messages payment page
 */
export const PayMessagePageChunk = (props) => (
  <SplitChunk component={LazyPayMessagePage} {...props} />
);

const LazyPayIframePage = lazy(
  () =>
    import(
      /* webpackChunkName: 'paymentPagePayIframePage' */ '@phoenix/payment/pages/containers/PayIframePage'
    ),
);

/**
 * Buy messages payment page
 */
export const PayIframePageChunk = (props) => (
  <SplitChunk component={LazyPayIframePage} {...props} />
);
