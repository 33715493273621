/**
 * Dictionary names of inventory tracking events.
 * @see inventoryTracking.gql (events)
 */
enum EventName {
  VIEW = 'view',
  CLICK = 'click',
}

export default EventName;
