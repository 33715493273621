import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import URI from '@core/utils/url';
import getHistory from '@core/application/utils/getHistory';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import getFragmentKey from '@core/application/utils/getFragmentKey';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';

let startedListeners = false;

/**
 * @summary Listen route changes
 * @description Need to add url parameter for all urls in inAppBrowser
 * @param {ApolloClient} client
 * @param currentUserId
 */
const startInAppLoginFragmentTokenListener = (client, currentUserId) => {
  // Avoiding multiple resubscribes in case of unpredictable cache updates.
  if (startedListeners) {
    return null;
  }

  startedListeners = true;

  const inAppBrowserName = getBootstrapParam(IN_APP_NAME);

  if (inAppBrowserName === InAppBrowserName.NORMAL_BROWSER) {
    return null;
  }
  const fragmentKey = getFragmentKey();

  return client.watchQuery({query: ROUTE_QUERY}).subscribe(
    ({
      data: {
        route: {current},
      },
    }) => {
      const uri = URI(current);

      if (!uri.hasSearch('fragmentToken')) {
        const newUrl = uri
          .addSearch({
            fragmentToken: currentUserId,
            fragmentBrowser: inAppBrowserName,
            ...(fragmentKey && {fragmentKey}),
          })
          .toString();
        getHistory().replace(newUrl);
      }
    },
  );
};

export default startInAppLoginFragmentTokenListener;
