import {getClientInstance} from '@core/graphql/client';
import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';
import type {CurrentUserAccountStatusQuery} from '@core/payment/common/graphql/queries/paymentAccountStatus';

import {
  EXTENDED_DISTANCE,
  EXTENDED_LOCATION,
} from '@phoenix/search/map/constants/via';
import FREE_TRIAL_QUERY from '@phoenix/search/map/graphql/queries/freeTrialData.gql';
import type {FreeTrialDataQuery} from '@phoenix/search/map/graphql/queries/freeTrialData';
import LEAVE_PAYMENT_PAGE_MUTATION from '@phoenix/search/map/graphql/mutations/leavePaymentPage.gql';
import type {LeavePaymentPageMutation} from '@phoenix/search/map/graphql/mutations/leavePaymentPage';

const checkIfNeedTriggeringLeavePaymentPage = (via: string) => {
  if (![EXTENDED_DISTANCE, EXTENDED_LOCATION].includes(via)) {
    return;
  }

  const client = getClientInstance();

  Promise.all([
    client.query<CurrentUserAccountStatusQuery>({
      query: PAYMENT_ACCOUNT_STATUS_QUERY,
    }),
    client.query<FreeTrialDataQuery>({query: FREE_TRIAL_QUERY}),
  ]).then(([{data: paymentData}, {data: trialData}]) => {
    if (
      !trialData.searchWithMap ||
      !trialData.searchWithMap?.hasFreeTrialAvailable
    ) {
      return;
    }

    if (
      (via === EXTENDED_DISTANCE &&
        !paymentData.payment.accountStatus.isPaid) ||
      (via === EXTENDED_LOCATION &&
        paymentData.payment.accountStatus.notBoughtAccountFeatures.length)
    ) {
      client.mutate<LeavePaymentPageMutation>({
        mutation: LEAVE_PAYMENT_PAGE_MUTATION,
      });
    }
  });
};

export default checkIfNeedTriggeringLeavePaymentPage;
