import type {FC} from 'react';
import React, {useMemo} from 'react';

import BlockedReportedAppearance from '@core/theming/constants/features/BlockedReportedAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43670
 */
type ThemedBlockedPageProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  BlockedReportedAppearance,
  ModuleComponent<ThemedBlockedPageProps>
> = (value) => {
  switch (value) {
    case BlockedReportedAppearance.AS_SEARCH_LIST:
      return import(
        /* webpackChunkName: 'blockedSearch' */ '@phoenix/blockedReported/page/components/BlockedPageAsSearchList'
      );
    case BlockedReportedAppearance.AS_REGULAR_LIST:
      return import(
        /* webpackChunkName: 'blockedRegular' */ '@phoenix/blockedReported/page/components/BlockedPageAsRegularList'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable blocked users page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedBlockedPage: FC<ThemedBlockedPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedBlockedPageProps,
        BlockedReportedAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="blockedReported"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedBlockedPage;
