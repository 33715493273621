import createTranslationsMap from '@core/translations/createTranslationsMap';

import {MICROFEATURES} from './microfeatures';

export const SPECIAL_OFFER_TITLE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'title.more_likes_feature'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'title.priority_man_feature',
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'title.priority_man_feature',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'title.super_boost_feature',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'title.flirt_alarm_feature',
    ),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'title.sex_alarm_feature'),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'title.first_to_talk_feature',
    ),
  }));
