import getCookie from '@core/utils/cookie/getCookie';

import {REMARKETING_OFFER_CONCEPT_ID_COOKIE} from '../constants/popup';

const getConceptId = (idFromServer) => {
  const idFromCookie = getCookie(REMARKETING_OFFER_CONCEPT_ID_COOKIE);

  if (idFromCookie) {
    return idFromCookie;
  }

  return idFromServer;
};

export default getConceptId;
