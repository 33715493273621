import React, {lazy} from 'react';

import useIsMiniMessengerAllowed from '@phoenix/messenger/utils/useIsMiniMessengerAllowed';

import SplitChunk from '../components/SplitChunk';

const DraggableMiniMessenger = lazy(
  () =>
    import(
      /* webpackChunkName: 'MiniMessenger' */ '@phoenix/messenger/mini/containers/DraggableMiniMessenger'
    ),
);

const DraggableMiniMessengerChunk = (props) => {
  const isAllowed = useIsMiniMessengerAllowed();
  if (!isAllowed) {
    return null;
  }

  return <SplitChunk component={DraggableMiniMessenger} {...props} />;
};

export default DraggableMiniMessengerChunk;
