import {createContext} from 'react';

import type {ConsumerData} from '../types';
import {EMPTY_ZONES} from '../constants/zones';

/**
 * Create context for coregistration functionality
 * @see https://reactjs.org/docs/context.html
 */
const CoregistrationContext = createContext<ConsumerData>({
  zones: EMPTY_ZONES,
  registrationInProgress: false,
  loadZoneInfo: () => {},
  register: () => {},
  clearZoneInfo: () => {},
});

export const {Provider, Consumer} = CoregistrationContext;

export default CoregistrationContext;
