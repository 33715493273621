import type {FC, SyntheticEvent} from 'react';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import t from '@core/translations/translate';
import scrollIntoView from '@core/utils/scroll/scrollIntoView';
import usePaymentProcessingStatus from '@core/payment/common/utils/usePaymentProcessingStatus';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';
import NotifierAboutAgreement$ from '@core/payment/widgets/billingPolicy/utils/notifierAboutAgreement';

import {PlaceholderBar} from '@phoenix/ui';
import FooterRow from '@phoenix/payment/widgets/footer/components/FooterRow';

import {
  PaymentBillingPolicyLink,
  PaymentCredentialsPolicyLink,
} from '../components/PaymentBillingPolicyLinks';
import PaymentCommonPolicyLayout from '../components/PaymentCommonPolicyLayout';

type PaymentCredentialsPolicyCommonProps = {
  loading: boolean;
  isCredentialsPolicyNeeded: boolean;
  credentialsPolicyDefaultValue: boolean;
  isCredentialsPolicyChecked: boolean;
  setIsCredentialsPolicyChecked: (checked: boolean) => void;
};

const PaymentCredentialsPolicyCommon: FC<
  PaymentCredentialsPolicyCommonProps
> = ({
  loading,
  isCredentialsPolicyNeeded,
  credentialsPolicyDefaultValue,
  isCredentialsPolicyChecked,
  setIsCredentialsPolicyChecked,
}) => {
  const processingStatus = usePaymentProcessingStatus();
  const [animated, setAnimated] = useState(false);
  const ref = useRef();

  const handleChange = useCallback(
    (_event: SyntheticEvent, _value: string, checked: boolean) => {
      setIsCredentialsPolicyChecked(checked);
      setAnimated(true);
    },
    [setIsCredentialsPolicyChecked],
  );

  useEffect(() => {
    if (isCredentialsPolicyNeeded) {
      setIsCredentialsPolicyChecked(credentialsPolicyDefaultValue);
    }
  }, [
    isCredentialsPolicyNeeded,
    setIsCredentialsPolicyChecked,
    credentialsPolicyDefaultValue,
  ]);

  useEffect(() => {
    if (
      isCredentialsPolicyNeeded &&
      processingStatus === PROCESSING_STATUS.FAILED
    ) {
      setIsCredentialsPolicyChecked(credentialsPolicyDefaultValue);
    }
  }, [
    isCredentialsPolicyNeeded,
    processingStatus,
    setIsCredentialsPolicyChecked,
    credentialsPolicyDefaultValue,
  ]);

  useEffect(() => {
    if (!isCredentialsPolicyNeeded || loading) {
      return;
    }

    const subscription = NotifierAboutAgreement$.subscribe((notified) => {
      setAnimated(notified);

      if (notified && ref.current) {
        scrollIntoView({currentElem: ref.current});
      }
    });

    // eslint-disable-next-line consistent-return
    return () => {
      subscription.unsubscribe();
    };
  }, [isCredentialsPolicyNeeded, loading]);

  if (loading) {
    return (
      <FooterRow>
        <PlaceholderBar />
      </FooterRow>
    );
  }

  if (!isCredentialsPolicyNeeded) {
    return null;
  }

  return (
    <div ref={ref}>
      <FooterRow>
        <PaymentCommonPolicyLayout
          withCheckbox
          onCheckboxChange={handleChange}
          animated={animated}
          checked={isCredentialsPolicyChecked}
          data-test="paymentCredentialsPolicy"
        >
          {t('paymentPage', 'text.agree_with')}
          <span> </span>
          <PaymentCredentialsPolicyLink
            text={t('paymentPage', 'text.stored_credentials')}
          />
          <span>, </span>
          {/* @ts-expect-error --> TODO[TS] Missing attribute */}
          <PaymentBillingPolicyLink
            text={t('popupStaticPage', 'title.billing_policy')}
          />
        </PaymentCommonPolicyLayout>
      </FooterRow>
    </div>
  );
};

export default PaymentCredentialsPolicyCommon;
