import PLACES from '@core/user/actions/constants/Place';

import ACTIONS from '@phoenix/user/actions/constants/actions';

export const ACTION_FOR_PLACE_WEB = {
  [PLACES.USER_INFO]: [ACTIONS.CHAT, ACTIONS.LIKE, ACTIONS.FAVORITE],
  [PLACES.USER_TOOLBAR]: [ACTIONS.CHAT, ACTIONS.LIKE, ACTIONS.FAVORITE],
};
/**
 * User actions for coins system by places
 */
export default {
  [PLACES.SEARCH]: [ACTIONS.LIKE, ACTIONS.CHAT],
  [PLACES.USER_INFO]: [ACTIONS.LIKE, ACTIONS.FAVORITE, ACTIONS.CHAT],
  [PLACES.USER_TOOLBAR]: [ACTIONS.LIKE, ACTIONS.FAVORITE, ACTIONS.CHAT],
  [PLACES.USER_TOOLBAR_SWITCHABLE]: [ACTIONS.LIKE, ACTIONS.CHAT],
};
