import type {FC} from 'react';
import React from 'react';
import cn from 'classnames';

import t from '@core/translations/translate';
import type {ErrorBoundaryProps} from '@core/graphql/types';
import {ErrorBoundarySize} from '@core/graphql/types';

import {Icon, Card, SpacingShort, Tooltip} from '@phoenix/ui';
import {IconSize, PopperPlacement, PopperTrigger} from '@phoenix/ui/constants';

import css from '../styles/ErrorBoundary.css';

/**
 * Component for rendering danger warnings when graphQL error occurs
 */
const ErrorBoundary: FC<ErrorBoundaryProps> & {
  SIZE: typeof ErrorBoundarySize;
} = ({
  error,
  errorSize = ErrorBoundarySize.NORMAL,
  title,
  text,
  fullHeight = false,
}) => {
  const isOnlyIcon = errorSize === ErrorBoundary.SIZE.ONLY_ICON;
  const isSmall = errorSize === ErrorBoundary.SIZE.SMALL;

  if (error) {
    console.error(error);
  }

  if (isOnlyIcon) {
    return (
      <Tooltip
        error
        trigger={PopperTrigger.HOVER}
        placement={PopperPlacement.BOTTOM}
        content={text || t('errorBoundary', 'text.pls_rtr')}
      >
        <Card danger className={cn(css.content)}>
          <Icon type="heart-broken" danger />
        </Card>
      </Tooltip>
    );
  }

  return (
    <Card
      danger
      className={cn(
        fullHeight && css.fullHeight,
        isSmall ? css.content : css.contentNormal,
      )}
    >
      <SpacingShort stretch={false}>
        <Icon
          type="heart-broken"
          danger
          size={isSmall ? IconSize.SIZE_24 : IconSize.SIZE_48}
        />
      </SpacingShort>
      <h2 className={cn(isSmall ? css.headerSmall : css.headerNormal)}>
        {title || t('errorBoundary', 'text.smth_wnt_wrng')}
      </h2>
      {!isSmall && (
        <p className={cn(css.text)}>
          {text || t('errorBoundary', 'text.pls_rtr')}
        </p>
      )}
    </Card>
  );
};

ErrorBoundary.SIZE = ErrorBoundarySize;

export default ErrorBoundary;
