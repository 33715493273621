/**
 * A custom error constructor which instance can be generated while a subscription logic is being processed
 * You have to use this error constructor in case of you want to generate a custom error
 * @class
 */
export default class WebPushError extends Error {
  name = 'WebPushNotificationError';

  constructor(message) {
    super();

    this.message = message || 'Web push is disabled';
  }
}
