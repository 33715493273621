import getHistory from '@core/application/utils/getHistory';
import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';
import type {CurrentUserAccountStatusQuery} from '@core/payment/common/graphql/queries/paymentAccountStatus';
import type {Client} from '@core/graphql/types';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import {setReady} from '@core/payment/common/utils/setPaymentProcessingStatus';
import trackPaymentClick from '@core/payment/common/utils/trackPaymentClick';
import {getPageViewTime} from '@core/payment/payProcess/utils/pageViewTime';
import {Action, PayButtonEnum, PaymentFormEnum, ViaEnum} from '@core/types';

import getFreeTrialSuccessPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getFreeTrialSuccessPopupFlow';
import formatPackageData from '@phoenix/payment/widgets/failForm/utils/formatPackageData';
import freeFeatureNames from '@phoenix/freeFeaturePopup/constants/freeFeatureNames';
import openFreeFeaturePopup from '@phoenix/freeFeaturePopup';
import type {PackagesData} from '@phoenix/remarketing/offerPopup/utils/getActivePackage';
import getActivePackage from '@phoenix/remarketing/offerPopup/utils/getActivePackage';

import type {
  SearchWithMapStatisticQuery,
  SearchWithMapStatisticQueryVariables,
} from '../graphql/queries/searchWithMapStatisticQuery';
import SEARCH_WITH_MAP_STATISTIC_QUERY from '../graphql/queries/searchWithMapStatisticQuery.gql';

/**
 * Open popup with statistic of usage free map search.
 * If there is no statistic by any reason, don't open popup.
 */
const openFreeTrialSuccessPopup = async (client: Client): Promise<void> => {
  let isPaymentProcessing = false;

  const {data: paymentData} = await client.query<CurrentUserAccountStatusQuery>(
    {
      query: PAYMENT_ACCOUNT_STATUS_QUERY,
    },
  );

  const isPaid = paymentData?.payment?.accountStatus?.isPaid ?? false;

  const {data, error} = await client.query<
    SearchWithMapStatisticQuery,
    SearchWithMapStatisticQueryVariables
  >({
    query: SEARCH_WITH_MAP_STATISTIC_QUERY,
    variables: {
      action: isPaid ? Action.features : Action.membership,
      via: ViaEnum.extended_location,
    },
  });

  if (!data || error) {
    return;
  }

  setReady();

  const {
    searchWithMap: {statistic},
    payment: {packagesData},
  } = data;

  const activePackage = getActivePackage(packagesData as PackagesData);

  const {fullPrice} = formatPackageData(activePackage);

  const trackClick = (isButtonActive = true) => {
    trackPaymentClick({
      action: Action.features,
      isFormHidden: true,
      isOneClickShown: false,
      isButtonActive,
      isFrontendValidationPassed: true,
      paymentForm: PaymentFormEnum.CreditCardPaymentForm,
      payButton: PayButtonEnum.default,
      timeInterval: getPageViewTime(),
    });
  };

  const handleActionClick = () => {
    if (isPaid) {
      if (isPaymentProcessing) {
        trackClick(false);
        return;
      }

      isPaymentProcessing = true;
      trackClick();

      const processPaymentFlow = getProcessPaymentFlow(
        getFreeTrialSuccessPopupFlow(),
      );
      const method = activePackage?.paymentMethod;

      processPaymentFlow({
        hidePaymentForm: 1,
        activePackage: {paymentMethod: method},
        method,
        action: Action.features,
        payFromPopup: true,
        stockId: activePackage?.stockId,
        via: ViaEnum.features,
        prevVia: ViaEnum.extended_location,
      });
    } else {
      getHistory().push(
        generatePayUrl({
          stage: Action.membership,
          urlParams: {
            via: ViaEnum.extended_location,
          },
        }),
      );
    }
  };

  openFreeFeaturePopup({
    name: freeFeatureNames.FREE_TRIAL_SUCCESS,
    onActionClick: handleActionClick,
    options: {...statistic, price: fullPrice, isPaid},
  });
};

export default openFreeTrialSuccessPopup;
