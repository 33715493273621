import React from 'react';
import PropTypes from 'prop-types';

import CommonUpdateEmail from '@core/updateEmail/containers/UpdateEmail';

import {Loader} from '@phoenix/ui';

const UpdateEmail = ({location}) => (
  <CommonUpdateEmail location={location} componentLoader={Loader} />
);

UpdateEmail.propTypes /* remove-proptypes */ = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default UpdateEmail;
