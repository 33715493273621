import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import useIsSearchFormOpen from '@core/search/utils/useIsSearchFormOpen';
import HeaderToolbarProvider from '@core/header/containers/HeaderToolbarProvider';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import useIsPopupOpen from '@core/popup/utils/useIsPopupOpen';
import AccessError from '@core/application/containers/AccessError';
import FaviconWithActivity from '@core/activity/containers/FaviconWithActivity';
import isPwaNextStepUrl from '@core/utils/url/isPwaNextStepUrl';
import TrackingCodes from '@core/tracking/codes';
import DeviceDetection from '@core/deviceDetection/containers/DeviceDetection';
import PayPalV2FraudNet from '@core/payment/common/containers/PayPalV2FraudNet';

import ActivityProvider from '@phoenix/activity/containers/ActivityProvider';
import TARGET_USERS_BY_ID_QUERY from '@phoenix/activity/graphql/queries/targetUsersById.gql';
import CoregistrationProvider from '@phoenix/remarketing/coregistration/containers/CoregistrationProvider';
import MicrofeaturesProvider from '@phoenix/microfeatures/common/containers/MicrofeaturesProvider';
import WalletPreloader from '@phoenix/payment/common/containers/WalletPreloader';
import CoregistrationPopupDisplayer from '@phoenix/remarketing/coregistration/containers/CoregistrationPopupDisplayer';
import PaymentOrderChecker from '@phoenix/payment/common/containers/PaymentOrderChecker';
import BlindClickPopunder from '@phoenix/remarketing/popunder/containers/BlindClickPopunder';
import BlindClickBackOfferPopunder from '@phoenix/remarketing/popunder/containers/BlindClickBackOfferPopunder';
import VideoChatProvider from '@phoenix/videoChat/containers/VideoChatProvider';
import {isPopupRoute} from '@phoenix/application/utils/getPopupBaseRoute';

import ERROR_CODE_MAP from '../constants/accessErrorCodeMap';
import FirstLoginChecker from '../containers/FirstLoginChecker';
import RouteWithPopupDisplayer from '../containers/RouteWithPopupDisplayer';
import PopupOnBootstrapDisplayer from '../containers/PopupOnBootstrapDisplayer';
import LegacyAppViewInitializer from '../containers/LegacyAppViewInitializer';
import SubscriptionsStarter from '../containers/SubscriptionsStarter';
import AppLayout from './AppLayout';
import RemarketingConceptPreload from '../containers/RemarketingConceptPreload';

const MainLayout = ({
  isAllowed,
  useClientStorage,
  deferredPageAvailable,
  isBanned,
  isAuthorizedZone,
  isSiteClosed,
  isWeb2AppSite,
  loading,
  dataLoading,
  remarketingConceptName,
}) => {
  const isSearchFormOpen = useIsSearchFormOpen();
  const isPopupOpen = useIsPopupOpen();
  const {pathname} = useLocation();
  const isFunnelPopupRoute = isPopupRoute(pathname);

  return (
    <BabciaScopedProvider
      category={BabciaScopedProvider.CATEGORY.PAGE}
      skip={isFunnelPopupRoute || isPopupOpen || isSearchFormOpen}
    >
      <VideoChatProvider disabled={!isAllowed}>
        <ActivityProvider
          allowedToStart={isAllowed}
          fetchUsersQuery={TARGET_USERS_BY_ID_QUERY}
        >
          <CoregistrationProvider>
            <HeaderToolbarProvider>
              <MicrofeaturesProvider
                allowedToStart={isAllowed && !isFunnelPopupRoute}
              >
                <AccessError rulesMap={ERROR_CODE_MAP}>
                  {isAllowed && (
                    <Fragment>
                      {!dataLoading && remarketingConceptName && (
                        <RemarketingConceptPreload
                          conceptName={remarketingConceptName}
                        />
                      )}
                      <FirstLoginChecker pathname={pathname} />
                      <RouteWithPopupDisplayer />
                      <WalletPreloader pathname={pathname} />
                      {!isFunnelPopupRoute && (
                        <Fragment>
                          <CoregistrationPopupDisplayer
                            useClientStorage={useClientStorage}
                          />
                          <PopupOnBootstrapDisplayer />
                          <PaymentOrderChecker />
                          <BlindClickPopunder />
                          <BlindClickBackOfferPopunder />
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                  <FaviconWithActivity />
                  <AppLayout
                    isPopupOpen={isPopupOpen}
                    loading={loading}
                    deferredRenderAllowed={deferredPageAvailable}
                    disabled={isFunnelPopupRoute}
                    onlyContent={isPwaNextStepUrl(pathname)}
                    banned={isBanned}
                    authorizedZone={isAuthorizedZone}
                    siteClosed={isSiteClosed}
                    web2AppSite={isWeb2AppSite}
                  />
                  {!loading && <LegacyAppViewInitializer />}
                </AccessError>
                {isAllowed && (
                  <Fragment>
                    {deferredPageAvailable && <SubscriptionsStarter />}
                    <TrackingCodes />
                    <DeviceDetection />
                    <PayPalV2FraudNet />
                  </Fragment>
                )}
              </MicrofeaturesProvider>
            </HeaderToolbarProvider>
          </CoregistrationProvider>
        </ActivityProvider>
      </VideoChatProvider>
    </BabciaScopedProvider>
  );
};

MainLayout.propTypes /* remove-proptypes */ = {
  isAuthorizedZone: PropTypes.bool.isRequired,
  isBanned: PropTypes.bool.isRequired,
  isSiteClosed: PropTypes.bool.isRequired,
  isWeb2AppSite: PropTypes.bool.isRequired,
  deferredPageAvailable: PropTypes.bool,
  isAllowed: PropTypes.bool,
  useClientStorage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  dataLoading: PropTypes.bool.isRequired, // Remarketing offer backbone model and AppData query loading flag
  remarketingConceptName: PropTypes.string,
};

export default MainLayout;
