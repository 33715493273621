import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {objectfit} from 'modernizr';

import PRESET from '@core/user/photo/constants/PhotoPreset';
import {LEVEL, SIZE} from '@core/user/photo/constants';
import useCorrectedPhotoLevel from '@core/user/photo/utils/useCorrectedPhotoLevel';
import PinchZoom from '@core/gallery/containers/PinchZoom';

import createPhotoUrl from '@phoenix/user/photo/utils/createPhotoUrl';
import Photo from '@phoenix/user/photo/containers/Photo';
import {FittingImage, Overlay} from '@phoenix/ui';
import {ImageScaling, OverlaySaturation} from '@phoenix/ui/constants';
import PRIVATE_PHOTO_OVERLAY_TYPE from '@phoenix/user/photo/constants/PrivatePhotoOverlayType';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import css from '../styles/PhotoZoomableLayout.css';

const pinchZoomContainerProps = {
  className: css.zoomableContainer,
};

const PhotoZoomableLayout = ({
  photo,
  site,
  my,
  skipBlur,
  userId,
  withOverlay,
  withShadow,
  showLoadingBackground,
  onZoomUpdate,
  onLoad,
}) => {
  const {level, error, loading} = useCorrectedPhotoLevel({
    ...photo,
    isMy: my,
    skipBlur,
  });

  if (loading) {
    return null;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (level !== LEVEL.NORMAL || photo.pendingDelete) {
    return (
      <div
        className={cn(css.container, !objectfit && css.blurredPhotoContainer)}
      >
        <div className={css.blurredPhoto}>
          <Photo
            size={photo.pendingDelete ? SIZE.NORMAL : SIZE.SMALL}
            photo={{preset: PRESET.NORMAL, ...photo}}
            photoRequestData={{
              overlayType: PRIVATE_PHOTO_OVERLAY_TYPE.WITH_REQUEST_BUTTON,
            }}
            my={my}
            withShadow={withShadow}
            showLoadingBackground={showLoadingBackground}
            userId={userId}
            onLoad={onLoad}
            pendingDeleteShowDescription
          />
        </div>
      </div>
    );
  }

  const image = (
    <FittingImage
      url={createPhotoUrl({preset: PRESET.NORMAL, ...site, ...photo})}
      scaling={ImageScaling.CONTAIN}
      className={css.photo}
      onLoad={onLoad}
    />
  );

  return (
    <div className={css.container} data-test="pinchZoom">
      <PinchZoom
        onZoomUpdate={onZoomUpdate}
        containerProps={pinchZoomContainerProps}
      >
        <div className={css.photoWrap}>
          {withOverlay ? (
            <Overlay saturation={OverlaySaturation.LITE}>{image}</Overlay>
          ) : (
            image
          )}
        </div>
      </PinchZoom>
    </div>
  );
};

PhotoZoomableLayout.propTypes /* remove-proptypes */ = {
  photo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    level: PropTypes.oneOf(Object.values(LEVEL)),
    censored: PropTypes.bool.isRequired,
    updatedOn: PropTypes.string.isRequired,
    isApprovedFromMessenger: PropTypes.number,
    pendingDelete: PropTypes.bool,
  }).isRequired,
  site: PropTypes.shape({
    cdnDomain: PropTypes.string.isRequired,
    imageFormat: PropTypes.string.isRequired,
    photoUrlExtension: PropTypes.string,
  }).isRequired,
  my: PropTypes.bool.isRequired,
  onZoomUpdate: PropTypes.func,
  onLoad: PropTypes.func,
  skipBlur: PropTypes.bool,
  withShadow: PropTypes.bool,
  userId: PropTypes.string,
  withOverlay: PropTypes.bool,
  showLoadingBackground: PropTypes.bool,
};

export default PhotoZoomableLayout;
