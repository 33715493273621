import {fromEvent, from} from 'rxjs';
import {
  startWith,
  map,
  switchMap,
  filter,
  withLatestFrom,
} from 'rxjs/operators';

import type {RouteQuery} from '@core/application/graphql/queries/route';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isInRouteList from '@core/utils/routing/isInRouteList';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';
import type {Client} from '@core/graphql/types';

import DISABLED_ROUTES from '@phoenix/extraEvent/constants/disabledRoutes';

import type {GlobalSearchTrialEndedNotificationSubscription} from '../graphql/subscriptions/globalSearchTrialEndedNotification';
import GLOBAL_SEARCH_TRIAL_ENDED_NOTIFICATION_SUBSCRIPTION from '../graphql/subscriptions/globalSearchTrialEndedNotification.gql';
import openFreeTrialSuccessPopup from './openFreeTrialSuccessPopup';

let startedListeners = false;

// Starts the free trial success popup listener.
const startFreeTrialSuccessPopupListener = (client: Client): void => {
  if (startedListeners) {
    return;
  }

  startedListeners = true;

  // Observable that emits on every Popup state change (open/close).
  const popupState$ = fromEvent<CustomEvent<{hasQueue: boolean}>>(
    document,
    POPUP_STATE_CHANGE_EVENT,
  ).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
    map((event) => event.detail.hasQueue),
  );

  const routeState$ = from(
    client.watchQuery<RouteQuery>({
      query: ROUTE_QUERY,
    }),
  );

  from(
    client.subscribe<GlobalSearchTrialEndedNotificationSubscription>({
      query: GLOBAL_SEARCH_TRIAL_ENDED_NOTIFICATION_SUBSCRIPTION,
    }),
  )
    .pipe(
      switchMap(() =>
        routeState$.pipe(
          withLatestFrom(popupState$),
          filter(([routeData, hasQueue]) => {
            const currentRoute = routeData.data.route.current;
            return !isInRouteList(DISABLED_ROUTES, currentRoute) && !hasQueue;
          }),
        ),
      ),
    )
    .subscribe(() => {
      openFreeTrialSuccessPopup(client);
    });
};

export default startFreeTrialSuccessPopupListener;
