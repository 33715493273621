import TYPES from '../../constants/storyPartTypes';
/**
 * Prepare data for story part
 * @param {string} storyPartType
 * @param {string} storyPartId
 * @param {string} createdAt
 * @param {string} url
 * @param {string} previewUrl
 * @param {string} videoPreviewUrl
 * @param {boolean} isConverted
 * @param {number} duration
 * @param {number} group
 * @param {number} order
 * @returns {{__typename: string, isLiked: boolean, photo: ({level: number, __typename: string, id: *, updatedOn: *, censored: boolean}|null), likeCount: number, video: ({duration: *, previewUrl: *, level: number, __typename: string, id: *, isConverted: *, videoPreviewUrl: *, url: *}|null), type: *, isViewed: boolean, createdAt: *, stream: ({streamUrl: string, previewUrl: *, level: number, __typename: string, id: *}|null), id: *, viewCount: number, group: *, order: *}}
 */
const getPartForCache = ({
  storyPartType,
  storyPartId,
  createdAt,
  url,
  previewUrl,
  videoPreviewUrl,
  isConverted,
  duration,
  group = 0,
  order = 0,
}) => ({
  __typename: 'StoryPartItem',
  id: storyPartId,
  type: storyPartType,
  createdAt,
  isLiked: false,
  isViewed: false,
  likeCount: 0,
  viewCount: 0,
  group,
  order,
  photo:
    storyPartType === TYPES.PHOTO
      ? {
          __typename: 'PhotoType',
          id: storyPartId,
          level: 0,
          censored: false,
          privateAttributes: null,
          updatedOn: createdAt,
        }
      : null,
  video:
    storyPartType === TYPES.VIDEO
      ? {
          __typename: 'VideoType',
          id: storyPartId,
          level: 0,
          duration,
          url,
          previewUrl,
          videoPreviewUrl,
          isConverted,
        }
      : null,
});

export default getPartForCache;
