import isUndefined from 'lodash/isUndefined';
import upperFirst from 'lodash/upperFirst';
import isObject from 'lodash/isObject';

import logger from '@core/logger';
import getCookie from '@core/utils/cookie/getCookie';
import {getClientInstance} from '@core/graphql/client';
import ACTIVE_SPLIT_QUERY from '@core/utils/split/graphql/queries/activeSplit.gql';
import type {ActiveSplitQuery} from '@core/utils/split/graphql/queries/activeSplit';
import BackendBasedAppearance from '@core/theming/constants/features/BackendBasedAppearance';
import SplitBasedAppearance from '@core/theming/constants/features/SplitBasedAppearance';
import getTheme from '@core/application/utils/getTheme';

import type {MessengerCustomAppearanceQuery} from '../graphql/queries/messengerCustomAppearance';
import type {MediaUploadCustomAppearanceQuery} from '../graphql/queries/mediaUploadCustomAppearance';
import MESSENGER_CUSTOM_APPEARANCE_QUERY from '../graphql/queries/messengerCustomAppearance.gql';
import MEDIA_UPLOAD_CUSTOM_APPEARANCE_QUERY from '../graphql/queries/mediaUploadCustomAppearance.gql';
import type {
  FeatureName,
  CustomAppearanceSplitBased,
  CustomAppearanceBackendBased,
  StoredThemeFeature,
} from '../types';

const BACKEND_BASED_QUERY_BY_FEATURE = {
  mediaUpload: MEDIA_UPLOAD_CUSTOM_APPEARANCE_QUERY,
  messenger: MESSENGER_CUSTOM_APPEARANCE_QUERY,
};

/**
 * ATTENTION!!!
 * This function should be used only for small theming changes such as changing
 * of element position (top or bottom), color scheme (default or inverse).
 *
 * DON'T USE THIS UTIL FUNCTION FOR DETECTING HEAVY CHANGES THAT WILL CREATE
 * BRANCHING IN COMPONENT TREE. SUCH CHANGES SHOULD BE SPLITTED IN DIFFERENT
 * CHUNKS FOR AVOIDING MAIN BUNDLE BLOATING.
 */
const getThemeFeature = <T>(
  feature: FeatureName,
  param?: string,
): StoredThemeFeature<T> => {
  const {features} = getTheme();

  if (isUndefined(features[feature])) {
    logger.sendError(`[getThemeFeature] No "${feature}" feature found.`);
    return null;
  }

  if (param && isUndefined(features[feature][param])) {
    logger.sendError(
      `[getThemeFeature] No "${param}" param on "${feature}" feature found.`,
    );
    return null;
  }

  const result = (param ? features[feature][param] : features[feature]) as T;

  if (result === SplitBasedAppearance.SPLIT_BASED) {
    return getClientInstance()
      .query<ActiveSplitQuery>({query: ACTIVE_SPLIT_QUERY})
      .then(async ({data}) => {
        const {userFeatures: {activeSplit: {splitId, splitGroup} = {}} = {}} =
          data;
        const fileName = param ? `${feature}${upperFirst(param)}` : feature;

        const {
          default: splitMapping,
        }: {default: CustomAppearanceSplitBased<T>} = await import(
          `../constants/splitBased/${fileName}`
        );

        if (splitId && splitMapping[splitId]) {
          return splitMapping[splitId][splitGroup];
        }

        /**
         * Case when split is not active and API returns "null". It describes the case
         * when it wasn't published yet.
         */
        return splitMapping[SplitBasedAppearance.SPLIT_FALLBACK];
      });
  }

  if (result === BackendBasedAppearance.BACKEND_BASED) {
    // Load data from API
    return getClientInstance()
      .query<MessengerCustomAppearanceQuery | MediaUploadCustomAppearanceQuery>(
        {
          query: BACKEND_BASED_QUERY_BY_FEATURE[feature],
        },
      )
      .then(async ({data}) => {
        // Load related mapping between API and theme variables.
        const {
          default: backendMapping,
        }: {
          default: CustomAppearanceBackendBased;
        } = await import(`../constants/backendBased/${feature}`);

        // Allow more easily debug different widgets with cookie.
        const key: FeatureName =
          getCookie<FeatureName>(`${feature}CustomAppearance`) ||
          data.customAppearance[feature];

        let selectedMapping = backendMapping[key];
        if (!selectedMapping) {
          logger.sendWarning(
            `[getThemeFeature] Using fallback configuration for a backend-based themed ${feature}, custom key ${key} not found in the mapping.`,
          );
          selectedMapping = backendMapping.FALLBACK;
        }

        return isObject(selectedMapping)
          ? selectedMapping[param]
          : selectedMapping;
      });
  }

  return result;
};

export default getThemeFeature;
