import {useEffect, useState} from 'react';

import {toCamelCase, ucfirst} from '@core/utils/string';

import getConceptId from './getConceptId';

const cacheMap = new Map();

const resolver = (conceptName) => {
  const conceptId = getConceptId(conceptName);

  return import(
    /* webpackChunkName: 'remarketingPopupConcept' */ `../components/RemarketingPopup${ucfirst(
      toCamelCase(conceptId),
    )}`
  );
};

/**
 * To receive and cache react remarketing concept module for preloading
 * @param conceptName
 * @return {{Concept: null, loading: boolean}}
 */
const useRemarketingConcept = (conceptName) => {
  const [data, setData] = useState({
    loading: true,
    Concept: null,
  });
  const cachedResolver = cacheMap.get(conceptName);

  useEffect(() => {
    if (!cachedResolver) {
      const module = resolver(conceptName);
      module.then((result) => {
        cacheMap.set(conceptName, result?.default);
        setData({
          loading: false,
          Concept: result?.default,
        });
      });
    } else {
      setData({
        loading: false,
        Concept: cachedResolver,
      });
    }
  }, [cachedResolver, conceptName]);

  return data;
};

export default useRemarketingConcept;
