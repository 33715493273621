import React, {lazy, Suspense} from 'react';

import PopupService from '@core/popup/utils/PopupService';

import {Loader} from '@phoenix/ui';

const FortuneWheelPopupContent = lazy(
  () =>
    import(
      /* webpackChunkName: 'fortuneWheel' */ '../containers/FortuneWheelForCreditsPopup'
    ),
);

const openFortuneWheelForCreditsPopup = ({needToStartGame = false}) => {
  PopupService.openPopup(
    <Suspense fallback={<Loader small />}>
      <FortuneWheelPopupContent
        openFortuneWheelForCreditsPopup={openFortuneWheelForCreditsPopup}
        needToStartGame={needToStartGame}
      />
    </Suspense>,
    {
      small: true,
      trackingName: 'fortuneWheelForCredits',
    },
  );
};

export default openFortuneWheelForCreditsPopup;
