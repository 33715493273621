import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {Icon} from '@phoenix/ui';

import css from './ConfettiAnimation.css';

const CONFETTI = [
  <Icon type="favorite" className={cn(css.icon, css.primary)} inherit />,
  <svg
    className={cn(css.icon, css.gold)}
    viewBox="0 0 19 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M0 7.64L.41 0s12.4-.3 17.8 6c-.4.03-1.97 8.08-1.97 8.08S11.93 2.26 0 7.64" />
    </g>
  </svg>,
  <svg
    className={cn(css.icon, css.gray)}
    viewBox="0 0 17 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M.56 11.8s-1.97-1.67 6.29-5.72c1.2-.6 2.61-1.06 3.71-1.72C14.68 1.87 13.83.39 13.83.39l3.08 4.17s-10.38 3.34-10.7 9c-.59-.08-5.65-1.75-5.65-1.75" />
    </g>
  </svg>,
  <svg
    className={cn(css.icon, css.gold)}
    viewBox="0 0 13 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M8.89 15.86c-2.37-.59-4.35-2.79-5.98-5.23C1.33 8.14.11 5.4.46 2.9L4.38.13c-.35 2.5.88 5.24 2.46 7.72 1.63 2.44 3.6 4.64 5.97 5.23L8.9 15.86" />
    </g>
  </svg>,
  <svg
    className={cn(css.icon, css.gray)}
    viewBox="0 0 17 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M.44 8.97c.62-2.38 2.84-4.35 5.3-5.96C8.24 1.45 10.97.24 13.46.63l2.71 4c-2.5-.38-5.23.82-7.72 2.38-2.46 1.62-4.68 3.58-5.3 5.97l-2.71-4" />
    </g>
  </svg>,
  <svg
    className={cn(css.icon, css.primary)}
    viewBox="0 0 10 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M.62 15.06C-.44 12.74 0 9.73.86 6.86 1.77 4 3.1 1.28 5.27 0l4.67 1.5c-2.17 1.28-3.5 4-4.42 6.86-.85 2.87-1.3 5.88-.24 8.2l-4.66-1.5" />
    </g>
  </svg>,
  <Icon type="bullet" className={cn(css.icon, css.gold)} inherit />,
  <Icon type="bullet" className={cn(css.icon, css.gold)} inherit />,
  <Icon type="favorite" className={cn(css.icon, css.primary)} inherit />,
  <Icon type="favorite" className={cn(css.icon, css.gray)} inherit />,
  <Icon type="favorite" className={cn(css.icon, css.gray)} inherit />,
  <Icon type="favorite" className={cn(css.icon, css.gold)} inherit />,
  <Icon type="favorite" className={cn(css.icon, css.gold)} inherit />,
  <Icon type="favorite" className={cn(css.icon, css.primary)} inherit />,
  <svg
    className={cn(css.icon, css.gold)}
    viewBox="0 0 19 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M0 7.64L.41 0s12.4-.3 17.8 6c-.4.03-1.97 8.08-1.97 8.08S11.93 2.26 0 7.64" />
    </g>
  </svg>,
];

const ConfettiAnimation = ({waitPopupAnimation}) => (
  <div className={css.confetti}>
    {CONFETTI.map((confetti, i) => (
      <div
        className={cn(css.item, waitPopupAnimation && css.waitPopupAnimation)}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      >
        {confetti}
      </div>
    ))}
  </div>
);

ConfettiAnimation.propTypes /* remove-proptypes */ = {
  waitPopupAnimation: PropTypes.bool,
};

export default ConfettiAnimation;
