import MessageType from '@core/messenger/common/constants/messageType';

import {
  SEND_MMS_MESSAGE,
  SEND_SMS_MESSAGE,
} from '@phoenix/coins/buyCoins/constants/vias';

/**
 * Via for unsent message buy type of message
 * @param {string} type
 * @returns {string|string}
 */
const getViaForCoinsByType = (type) => {
  switch (type) {
    case MessageType.IMB_IMAGE:
    case MessageType.IMB_VIDEO:
      return SEND_MMS_MESSAGE;

    // For default use via for a chat type message
    default:
      return SEND_SMS_MESSAGE;
  }
};

export default getViaForCoinsByType;
