import COMMON_ACL_RULES from './commonACLRules';
import {FUNNEL_PAID, PP_AFTER_REG} from './accessErrorCode';

/**
 * Common rules without strict paid funnel and payment page after registration without skip.
 */
const COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS = COMMON_ACL_RULES.filter(
  ({errorCode}) => ![FUNNEL_PAID, PP_AFTER_REG].includes(errorCode),
);

export default COMMON_ACL_RULES_NO_PAYMENT_RESTRICTIONS;
