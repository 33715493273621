import React from 'react';

import PaymentAdditionalPackageCommon from '@core/payment/widgets/additionalPackage/containers/PaymentAdditionalPackage';

import setParamsToAdditionalPackageText from '../utils/setParamsToAdditionalPackageText';
import PaymentAdditionalPackageLayout from '../components/PaymentAdditionalPackageLayout';
import usePaymentButtonTitle from '../../buttons/utils/usePaymentButtonTitle';

const PaymentAdditionalPackage = (props) => (
  <PaymentAdditionalPackageCommon
    normalizeText={setParamsToAdditionalPackageText}
    layout={PaymentAdditionalPackageLayout}
    usePaymentButtonTitle={usePaymentButtonTitle}
    {...props}
  />
);

export default PaymentAdditionalPackage;
