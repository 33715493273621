import React from 'react';
import PropTypes from 'prop-types';

import {errorPropType} from '@core/mediaUpload/constants/propTypes';
import t from '@core/translations/translate';

import {Align} from '@phoenix/ui/constants';
import {Link} from '@phoenix/typography';

import UploadErrorWrapper from './UploadErrorWrapper';

const UploadErrorsInline = ({errors, filesCount, onMoreErrorsClick}) => {
  const {length} = errors;

  return (
    <UploadErrorWrapper align={Align.CENTER}>
      {length === 1 && errors[0].error.message}
      {length > 1 &&
        t('mediaUpload', 'text.files_couldnt_be_uploaded', {
          '{errors}': length,
          '{files}': filesCount,
          '{viewErrors}': (
            <Link
              inherit
              underline
              small
              trackingName="viewErrors"
              onClick={onMoreErrorsClick}
              data-test="moreErrors"
            />
          ),
        })}
    </UploadErrorWrapper>
  );
};

UploadErrorsInline.propTypes /* remove-proptypes */ = {
  errors: PropTypes.arrayOf(errorPropType).isRequired,
  filesCount: PropTypes.number,
  onMoreErrorsClick: PropTypes.func.isRequired,
};

export default UploadErrorsInline;
