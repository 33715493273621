import {useEffect, useState} from 'react';
import isUndefined from 'lodash/isUndefined';

import getThemeFeature from './getThemeFeature';
import {getCachedValue, cacheValue} from './themeCache';
import type {FeatureName} from '../types';

/**
 * ATTENTION!!!
 * This hook should be used only for small theming changes such as changing
 * of element position (top or bottom), color scheme (default or inverse).
 *
 * DON'T USE THIS HOOK FOR DETECTING HEAVY CHANGES THAT WILL CREATE
 * BRANCHING IN COMPONENT TREE. SUCH CHANGES SHOULD BE SPLITTED IN DIFFERENT
 * CHUNKS FOR AVOIDING MAIN BUNDLE BLOATING.
 *
 * Helper hook for theming. Retrieves feature (if it have no nested sub-features named "params")
 * of nested feature param directly. Used for light theming differences between components.
 *
 * Note that changing 'feature' or 'param' DOESN'T RETRIGGER RECALCULATION.
 * It's done for performance purposes and since we don't need to change features dynamically
 */
const useThemeFeature = <T>(
  feature: FeatureName,
  param = '',
): {
  loading: boolean;
  data?: T;
} => {
  const [, update] = useState({});

  // Try to get it from cache
  let data = getCachedValue<T>(feature, param);

  if (isUndefined(data)) {
    // It returns result synchronously if possible
    data = getThemeFeature<T>(feature, param);
    // Cache result or Promise to avoid duplicated queries
    cacheValue<T>(feature, param, data);
  }

  useEffect(() => {
    // Wait for resolve (if data is Promise)
    if (data instanceof Promise) {
      data.then((themeFeature) => {
        cacheValue<T>(feature, param, themeFeature || null);
        update({});
      });
    }
  }, [data, feature, param]);

  if (data instanceof Promise) {
    return {loading: true};
  }

  return {loading: false, data};
};

export default useThemeFeature;
