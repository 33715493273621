import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {ButtonPrimary} from '@phoenix/ui';

const CoregistrationPopupActions = ({onClick}) => (
  <ButtonPrimary
    fullWidth
    onClick={onClick}
    trackingName="register"
    adaptive
    data-test="coregistrationOkButton"
  >
    {t('coregistration', 'coreg.ok')}
  </ButtonPrimary>
);

CoregistrationPopupActions.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func.isRequired,
};

export default CoregistrationPopupActions;
