import React from 'react';
import PropTypes from 'prop-types';

import TYPES from '@core/disapprove/constants/types';
import getTypeContents from '@core/disapprove/utils/getTypeContents';

import {Icon, Tooltip} from '@phoenix/ui';
import {IconSize, PopperPlacement, PopperTrigger} from '@phoenix/ui/constants';

import css from '../styles/DisapproveNotice.css';

/**
 * @param {string} type
 * @returns {string}
 */
const getTooltipContent = (type) => {
  const contents = getTypeContents({type});
  if (contents) {
    return contents.text;
  }
  return '';
};

/**
 * Disapprove notice, displayer mainly on current user profile
 * Notice is a just presentational components. It doesn't fetch any data
 * from server. It just recieves data as props and updates his state.
 * @see ShortInfoLayout.js
 */
const DisapproveNotice = ({type, onClick, small}) => (
  <Tooltip
    trigger={PopperTrigger.HOVER}
    placement={PopperPlacement.BOTTOM_START}
    content={getTooltipContent(type)}
  >
    <div className={css.disapprove}>
      <Icon
        onClick={onClick}
        trackingName="disapproveNotice"
        danger
        type="danger"
        size={small ? IconSize.SIZE_24 : IconSize.SIZE_36}
      />
    </div>
  </Tooltip>
);

DisapproveNotice.TYPE = TYPES;

DisapproveNotice.propTypes /* remove-proptypes */ = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(DisapproveNotice.TYPE)).isRequired,
  small: PropTypes.bool,
};

export default DisapproveNotice;
