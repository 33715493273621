import React from 'react';
import PropTypes from 'prop-types';

import ActivityProviderCommon from '@core/activity/containers/ActivityProvider';
import useDataForActivityProvider from '@core/activity/utils/useDataForActivityProvider';
import ACTIVITIES_QUERY_TYPES from '@core/activity/constants/activitiesQueryTypes';

import useAllowedMyActivity from '../utils/useAllowedMyActivity';
import subscribeToActivity from '../utils/subscribeToActivity';

/**
 * Single source of data for activities inside application.
 * There is no other place where we query activities. They are queries and received from interaction
 * only here.
 */
const ActivityProvider = ({children, allowedToStart}) => {
  const {error, ...props} = useDataForActivityProvider(allowedToStart);
  const allowedMyActivity = useAllowedMyActivity();

  if (error) {
    return children;
  }

  return (
    <ActivityProviderCommon
      {...props}
      {...(allowedMyActivity && {
        additionalQueryType: ACTIVITIES_QUERY_TYPES.OUTCOME,
      })}
      subscribeToActivity={subscribeToActivity}
    >
      {children}
    </ActivityProviderCommon>
  );
};

ActivityProvider.propTypes /* remove-proptypes */ = {
  children: PropTypes.element.isRequired,
  allowedToStart: PropTypes.bool.isRequired,
};

export default ActivityProvider;
