import type {FC} from 'react';
import React, {useMemo} from 'react';

import CurrentUserMenuAppearance from '@core/theming/constants/features/CurrentUserMenuAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43673
 */
type ThemedCurrentUserMenuProps = {
  [key: string]: any;
};

const resolver: ModuleResolver<
  CurrentUserMenuAppearance,
  ModuleComponent<ThemedCurrentUserMenuProps>
> = (value) => {
  switch (value) {
    case CurrentUserMenuAppearance.AS_LIST:
      return import(
        /* webpackChunkName: 'profileMenuList' */ '@phoenix/user/profile/menu/containers/ProfileMenuList'
      );
    case CurrentUserMenuAppearance.AS_LAYERED_MASONRY:
      return import(
        /* webpackChunkName: 'profileMenuLayeredMasonry' */ '@phoenix/user/profile/menu/containers/ProfileMenuLayeredMasonry'
      );
    case CurrentUserMenuAppearance.AS_LAYERED_LIST:
      return import(
        /* webpackChunkName: 'profileMenuLayeredList' */ '@phoenix/user/profile/menu/containers/ProfileMenuLayeredList'
      );
    case CurrentUserMenuAppearance.AS_LIST_WITH_BLURRED_PHOTO:
      return import(
        /* webpackChunkName: 'profileMenuListWithBlurredPhoto' */ '@phoenix/user/profile/menu/containers/ProfileMenuListWithBlurredPhoto'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable profile menu based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedCurrentUserMenu: FC<ThemedCurrentUserMenuProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedCurrentUserMenuProps,
        CurrentUserMenuAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="currentUser"
      param="menu"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedCurrentUserMenu;
