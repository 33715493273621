import {localStorage} from '@core/utils/storage';

import matchCommunicationUserIdsStorageKey from '../constants/matchCommunicationUserIdsStorageKey';

/**
 * Return from cache match communication ids
 * @param {string} userId - current userId
 * @returns {array}
 */
const getCoinsMatchCommunicationsIds = (userId) =>
  localStorage.getItem(matchCommunicationUserIdsStorageKey(userId), []);

export default getCoinsMatchCommunicationsIds;
