import type {FC} from 'react';
import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import t from '@core/translations/translate';

import {ButtonPrimary, Icon} from '@phoenix/ui';
import {IconSize} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

const FreeTrialSuccessPaymentPopup: FC = () => {
  return (
    <PopupNotice
      icon={<Icon type="check" success size={IconSize.SIZE_96} />}
      title={t('paymentPage', 'text.payment_is_successful')}
      actions={[
        <ButtonPrimary
          onClick={() => PopupService.closePopup()}
          trackingName="closePopup"
          fullWidth
        >
          {t('paymentPage', 'text.back_to_the_site')}
        </ButtonPrimary>,
      ]}
    />
  );
};

export default FreeTrialSuccessPaymentPopup;
