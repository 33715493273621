import {BannedRule, AuthenticatedRule} from '@core/application/accessRules';

import {
  EmailNotConfirmedRule,
  PaidAppFunnelRule,
  PPAfterRegRule,
  FunnelPaidRule,
} from '../accessRules';

/**
 * Just common rules that should be applied to most part of routes.
 */
const COMMON_ACL_RULES = [
  PaidAppFunnelRule,
  BannedRule,
  AuthenticatedRule,
  FunnelPaidRule,
  PPAfterRegRule,
  EmailNotConfirmedRule,
];

export default COMMON_ACL_RULES;
