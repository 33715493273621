import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import closeFailForm from '@core/payment/payProcess/paymentFlow/methods/closeFailForm';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import addFailFormData from '@core/payment/payProcess/paymentFlow/methods/addFailFormData';
import resetSelectedAdditionalPackages from '@core/payment/payProcess/paymentFlow/methods/resetSelectedAdditionalPackages';
import reloadPackagesAfterDecline from '@core/payment/payProcess/paymentFlow/methods/reloadPackagesAfterDecline';
import goToNextStep from '@core/payment/payProcess/paymentFlow/methods/goToNextStep';
import addSuccessOrder from '@core/payment/payProcess/paymentFlow/methods/addSuccessOrder';
import type {PaymentFlowMethods} from '@core/payment/payProcess/types/paymentProcess';

import invalidateSearchLivecamAfterSuccess from '@phoenix/payment/payProcess/paymentFlow/methods/invalidateSearchLivecamAfterSuccess';
import updateCacheAfterPayment from '@phoenix/payment/payProcess/paymentFlow/methods/updateCacheAfterPayment';
import openPendingPopupIfNeeded from '@phoenix/payment/payProcess/paymentFlow/methods/openPendingPopupIfNeeded';

const getProcessExternalPayFlow = (): PaymentFlowMethods => {
  return [
    reportStartPayment,
    closeFailForm,
    reportEndPayment,
    addFailFormData,
    openPendingPopupIfNeeded,
    resetSelectedAdditionalPackages,
    invalidateSearchLivecamAfterSuccess,
    reloadPackagesAfterDecline,
    updateCacheAfterPayment,
    addSuccessOrder,
    goToNextStep,
  ];
};

export default getProcessExternalPayFlow;
