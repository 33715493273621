import React, {memo} from 'react';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';

import {IconSubstrate, Row, RowShort, RowShortest} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {H1, TextMuted} from '@phoenix/typography';

import freeFeatureBenefitsPropType from '../constants/freeFeatureBenefitsPropType';
import css from '../styles/FreeFeatureBenefits.css';

const FreeFeatureBenefits = ({benefits}) => (
  <div className={css.content}>
    <Row align={Align.AROUND}>
      {benefits.map(({icon, type, text, title}) => {
        const TextRowComponent = title || title === 0 ? RowShortest : RowShort;

        return (
          <div key={`${icon}-${type}`} className={css.benefit}>
            <RowShort>
              <IconSubstrate icon={icon} type={type} className={css.icon} />
            </RowShort>
            {!isNil(title) && (
              <TextRowComponent align={Align.CENTER}>
                <H1 className={css.text}>{title}</H1>
              </TextRowComponent>
            )}
            <TextRowComponent
              align={Align.CENTER}
              data-test="freeFeatureBenefit"
            >
              <TextMuted className={css.text}>{upperFirst(text)}</TextMuted>
            </TextRowComponent>
          </div>
        );
      })}
    </Row>
  </div>
);

FreeFeatureBenefits.propTypes /* remove-proptypes */ = {
  benefits: freeFeatureBenefitsPropType.isRequired,
};

export default memo(FreeFeatureBenefits);
