import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import PersonalIdentityByDemandWaitForApprovePopup from '../components/PersonalIdentityByDemandWaitForApprovePopup';

const openWaiteForApprovePopup = (onClose) => {
  PopupService.openPopup(<PersonalIdentityByDemandWaitForApprovePopup />, {
    small: true,
    trackingName: 'personalIdentityByDemandWaitForApprove',
    autoLoadTracking: true,
    showCloseButton: false,
    onClose,
  });
};

export default openWaiteForApprovePopup;
