import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import isPaySuccessUrl from '@core/utils/url/isPaySuccessUrl';
import trackPaymentDescriptor from '@core/payment/widgets/descriptor/utils/trackPaymentDescriptor';
import usePaymentDescriptor from '@core/payment/widgets/descriptor/utils/usePaymentDescriptor';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import getPaymentDescriptorText from '@core/payment/widgets/descriptor/utils/getPaymentDescriptorText';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import remarketingOfferAltMethodProcessorVar from '@core/graphql/vars/remarketingOfferAltMethodProcessorVar';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {Text} from '@phoenix/typography';
import {SpacingSize} from '@phoenix/ui/constants';

import FooterRow from '../../footer/components/FooterRow';

/**
 * Appears company's name on a credit card statement and is set up when the merchant account is established.
 */
const PaymentDescriptor = ({
  align,
  muted = false,
  small = false,
  className,
  space,
}) => {
  const {action, source} = usePaymentParams();
  const {pathname} = useLocation();

  const {
    data: {descriptorData, hideDescriptor} = {},
    loading,
    error,
    skipRequest,
    originalDescriptorData,
  } = usePaymentDescriptor();

  const {descriptor, companyName} = descriptorData || {};

  const descriptorText =
    hideDescriptor || !descriptor
      ? null
      : getPaymentDescriptorText({descriptor, companyName});

  const isPaymentSuccessPage = isPaySuccessUrl(pathname);

  useEffect(() => {
    const paymentPageNotCardMethod =
      source === PAYMENT_SOURCES.PAYMENT_PAGE &&
      !isCardPaymentType(activeMethodTabVar());

    const remarketingOfferPopupAltMethod =
      source === PAYMENT_SOURCES.POPUP &&
      remarketingOfferAltMethodProcessorVar();

    if (
      !descriptorText ||
      skipRequest ||
      loading ||
      error ||
      (paymentPageNotCardMethod && !isPaymentSuccessPage) ||
      remarketingOfferPopupAltMethod
    ) {
      return;
    }

    trackPaymentDescriptor({
      source,
      descriptor: originalDescriptorData?.descriptor,
      descriptorText,
      action,
    });
  }, [
    source,
    loading,
    error,
    descriptorData,
    descriptorText,
    action,
    skipRequest,
    isPaymentSuccessPage,
    descriptor,
    originalDescriptorData,
    hideDescriptor,
  ]);

  if (loading || !descriptorData || hideDescriptor) {
    return null;
  }

  if (error) {
    return (
      <FooterRow>
        <ErrorBoundary error={error} errorSize={ErrorBoundary.SIZE.SMALL} />
      </FooterRow>
    );
  }

  if (!descriptor) {
    return null;
  }

  return (
    <FooterRow space={space}>
      <Text
        data-test="paymentDescriptor"
        className={className}
        type={muted ? Text.TYPE.MUTED : Text.TYPE.NORMAL}
        small={small}
        align={align}
      >
        {descriptorText}
      </Text>
    </FooterRow>
  );
};

PaymentDescriptor.propTypes /* remove-proptypes */ = {
  align: PropTypes.oneOf(Object.values(Text.ALIGN)),
  space: PropTypes.oneOf(Object.values(SpacingSize)),
  className: PropTypes.string,
  small: PropTypes.bool,
  muted: PropTypes.bool,
};

export default PaymentDescriptor;
