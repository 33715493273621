import React, {lazy, Suspense} from 'react';

import PopupService from '@core/popup/utils/PopupService';

import {Loader} from '@phoenix/ui';

const FreeMessagesTimeoutPopupContent = lazy(
  () =>
    import(
      /* webpackChunkName: 'freeMessagesTimeoutPopup' */ './FreeMessagesTimeoutPopup'
    ),
);

const openFreeMessagesTimeoutPopup = (userId, timeout) => {
  PopupService.openPopup(
    <Suspense fallback={<Loader />}>
      <FreeMessagesTimeoutPopupContent userId={userId} timeout={timeout} />
    </Suspense>,
    {
      small: true,
      trackingName: 'freeMessagesTimeout',
    },
  );
};

export default openFreeMessagesTimeoutPopup;
