import {useEffect, useCallback, useState} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import get from 'lodash/get';

import {isWebSocketConnected} from '@core/websocket/utils/ws';
import {gotoUrl} from '@core/utils/url';

import POPUNDER_QUERY from '../graphql/queries/popunder.gql';
import POPUNDER_TARGET_URL_MUTATION from '../graphql/mutations/popunderTargetUrl.gql';
import TRACK_POPUNDER_MUTATION from '../graphql/mutations/trackPopunder.gql';

/**
 * "Pop-under" is a special thing dedicated for making more money from users
 * redirecting them (in new tab) to another web-site where he can find anything he wants and
 * as a result - make more payments.
 *
 * Such functionality have 3 implementations:
 * - As blind click everywhere (@see useBlindClickPopunder.js)
 * - Opening something new after logout (@see useLogoutPopunder.js)
 * - Opening rm target by route /pwaNextStep in current window (@see PwaNextStep.js)
 *
 * @param {String} zone Available pop-under zone (@see @phoenix/remarketing/popunder/constants/popunderZone.js)
 * @param {Boolean} skip Should we avoid querying any data. Useful when we should not initialize pop-under before certain time.
 * @returns {Object} data
 * @returns {Function|null} data.callPopunder - Callback for calling pop-under where is required
 * @returns {Array|null} data.excludeRoutes - List of routes where we should not call pop-under
 * @returns {Function} data.refetch - Callback for re-fetch pop-under data
 */
const usePopunder = (zone, skip = false) => {
  const [url, setUrl] = useState(null);
  /**
   * Is checking if mutation is returned result or if query returns banner url if socket not allowed
   */
  const [isUrlReceived, setIsUrlReceived] = useState(skip);

  /**
   * Query initial data for detecting if popunder is available or not
   */
  const {data, loading, refetch} = useQuery(POPUNDER_QUERY, {
    variables: {
      zone,
      /**
       * In case if WS is allowed we retrieve from server data for sending via WS.
       * Otherwise, we fall back to regular URL opening via API Server (that is more slowly than WS in terms of performance).
       */
      async: isWebSocketConnected(),
    },
    skip,
  });

  // Only in case when WS is available - retrieve URL for new window.
  const [getUrl] = useMutation(POPUNDER_TARGET_URL_MUTATION);

  // When executing pop-under and opening new window - send additional track.
  const [track] = useMutation(TRACK_POPUNDER_MUTATION);

  useEffect(() => {
    if (!loading && data && data.userFeatures) {
      const {bannerUrl, deliveryUrl, zonePlacements} =
        data.userFeatures.popunder;

      if (deliveryUrl) {
        getUrl({
          variables: {
            deliveryUrl,
            zonePlacements: {
              popunder: zonePlacements[0],
            },
          },
        })
          .then(({data: result}) => {
            const targetUrl =
              result.userFeatures.popunderTargetUrl.result || null;

            setUrl(targetUrl);

            setIsUrlReceived(true);
          })
          .catch(() => {
            setIsUrlReceived(true);
          });
      } else if (bannerUrl) {
        setUrl(bannerUrl);

        setIsUrlReceived(true);
      } else {
        setIsUrlReceived(true);
      }
    }
  }, [data, getUrl, loading, setUrl]);

  const callPopunder = useCallback(() => {
    if (!url) return;

    gotoUrl(url, true);

    track();
  }, [track, url]);

  return {
    loading: loading || !isUrlReceived,
    callPopunder: url ? callPopunder : null,
    excludeRoutes: get(data, 'userFeatures.popunder.excludeRoutes', null),
    refetch,
  };
};

export default usePopunder;
