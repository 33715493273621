import {getClientInstance} from '@core/graphql/client';

import PAYMENT_SUCCESS_XSALE_URL_QUERY from '../graphql/queries/paymentSuccessXSaleUrl.gql';

const getPaymentSuccessXSaleUrl = async () => {
  const {data} = await getClientInstance().query({
    query: PAYMENT_SUCCESS_XSALE_URL_QUERY,
  });

  return data?.payment.successData.xSaleAutologinUrl;
};

export default getPaymentSuccessXSaleUrl;
