import {from} from 'rxjs';
import {filter} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import {readFromLocalStorage, writeToLocalStorage} from '@core/utils/storage';
import {getClientInstance} from '@core/graphql/client';
import isInRouteList from '@core/utils/routing/isInRouteList';

import updateCachesAfterPayment from '@phoenix/payment/payProcess/utils/updateCachesAfterPayment';

import openLifetimeOfferPopup from './openLifetimeOfferPopup';
import DISABLED_ROUTES from '../constants/disabledRoutes';
import {LIFETIME_OFFER} from '../constants/storageKey';

/**
 * Get via from storage for Lifetime popup which needs to show later
 * @return {string|null}
 */
export const getLifetimeVia = () => readFromLocalStorage(LIFETIME_OFFER);

/**
 * Save via for Lifetime popup to show it later
 * @param {String|null} via
 */
export const saveLifetimeVia = (via = null) => {
  writeToLocalStorage(LIFETIME_OFFER, via);
};

/**
 * Clear data from storage
 */
export const resetLifetimeVia = () => {
  saveLifetimeVia();
};

export const prepareAndOpenPopup = ({paymentUrl, withClean = true}) => {
  updateCachesAfterPayment().then(() => {
    // Remove via from storage
    withClean && saveLifetimeVia();
    openLifetimeOfferPopup(paymentUrl);
  });
};

/**
 * @summary Listen route changes
 * @description Show later lifetimeOffer popup if flag is present in storage when route changes
 * @param paymentUrl
 */
const setPopupLaterByRouteChangeSubscription = (paymentUrl) => {
  from(getClientInstance().watchQuery({query: ROUTE_QUERY}))
    .pipe(
      filter(({data}) => !isInRouteList(DISABLED_ROUTES, data.route.current)),
    )
    .subscribe(() => {
      const via = getLifetimeVia();
      via && paymentUrl && prepareAndOpenPopup({paymentUrl, withClean: true});
    });
};

export default setPopupLaterByRouteChangeSubscription;
