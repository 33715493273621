import {useQuery} from '@apollo/client';

import COINS_EMAIL_VERIFICATION from '../graphql/queries/coinsEmailVerification.gql';

/**
 * Get email verification data for coins
 * @return {{amount: (*|number), enabled: (boolean), loading: (boolean), error: (ApolloError)}}
 */
const useCoinsEmailVerification = () => {
  const {data, loading, error} = useQuery(COINS_EMAIL_VERIFICATION);

  const emailVerification =
    data?.credits?.rewardForTasks?.tasks?.emailVerification;
  return {
    loading,
    error,
    amount: emailVerification?.reward || 0,
    enabled:
      (emailVerification?.enabled && !emailVerification?.completed) || false,
  };
};

export default useCoinsEmailVerification;
