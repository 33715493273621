import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';

import SearchWidgetLayout from '@phoenix/search/widget/components/SearchWidgetLayout';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import {Text} from '@phoenix/typography';

import {WEB_SEARCH_BANNER} from '../constants/conceptTypes';
import css from '../styles/SearchWrapperWithMotivation.css';

const SearchWrapperWithMotivation = ({children, conceptType}) => {
  const {data: appearance} = useThemeFeature('search', 'widget');

  const isWebSearchBanner = conceptType === WEB_SEARCH_BANNER;

  return (
    <SearchWidgetLayout
      size={SearchWidgetLayout.SIZE.FIXED_FULL}
      isRmBanner={!isWebSearchBanner}
      actions={
        !isWebSearchBanner && <Text bold>{t('banner', 'text.offer_day')}</Text>
      }
    >
      {isWebSearchBanner ? (
        children
      ) : (
        <div
          className={cn(css[`${appearance}Appearance`], css.wrapper)}
          data-test="searchWidgetWrapper"
        >
          {children}
        </div>
      )}
    </SearchWidgetLayout>
  );
};

SearchWrapperWithMotivation.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  conceptType: PropTypes.string,
};

export default SearchWrapperWithMotivation;
