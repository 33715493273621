import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import PopupService from '@core/popup/utils/PopupService';
import {
  settingsPropType,
  errorPropType,
} from '@core/mediaUpload/constants/propTypes';
import {PHOTO} from '@core/mediaUpload/constants/uploadTypes';
import t from '@core/translations/translate';

import {Group, Row, RowShort} from '@phoenix/ui';
import {H3} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Align} from '@phoenix/ui/constants';

import UploadErrorWrapper from './UploadErrorWrapper';
import FilePreview from '../containers/FilePreview';
import css from '../styles/UploadErrorsPopup.css';

const UploadErrorsPopup = ({
  settings,
  errors,
  onFileSelect,
  onCaptureClick,
  uploadRenderer,
}) => {
  const handleFileSelect = useCallback(
    (files) => {
      PopupService.closePopup();
      onFileSelect(files);
    },
    [onFileSelect],
  );
  const handleCaptureClick = useCallback(() => {
    PopupService.closePopup();
    onCaptureClick && onCaptureClick();
  }, [onCaptureClick]);

  const groupedErrors = useMemo(
    () => groupBy(errors, ({error: {message}}) => message),
    [errors],
  );

  return (
    <PopupFrame
      data-test="mediaUploadErrorsPopup"
      title={
        <H3>
          {settings.type === PHOTO
            ? t('mediaUpload', 'text.photos_havent_been_uploaded')
            : t('mediaUpload', 'text.videos_havent_been_uploaded')}
        </H3>
      }
      titleAlign={Align.LEFT}
      content={
        <Group>
          {map(groupedErrors, (messageErrors, message) => (
            <Row key={message}>
              <RowShort>
                <UploadErrorWrapper>{message}</UploadErrorWrapper>
              </RowShort>
              <RowShort>
                <div className={css.errors}>
                  {map(messageErrors, ({file}) => (
                    <div className={css.preview} key={file.name || file.size}>
                      <FilePreview
                        blob={file}
                        scaling={FilePreview.SCALING.COVER}
                      />
                    </div>
                  ))}
                </div>
              </RowShort>
            </Row>
          ))}
        </Group>
      }
      actions={uploadRenderer({
        onFileSelect: handleFileSelect,
        onCaptureClick: handleCaptureClick,
      })}
    />
  );
};

UploadErrorsPopup.propTypes /* remove-proptypes */ = {
  settings: settingsPropType.isRequired,
  errors: PropTypes.arrayOf(errorPropType).isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onCaptureClick: PropTypes.func,
  uploadRenderer: PropTypes.func.isRequired,
};

export default UploadErrorsPopup;
