import type {FC} from 'react';
import React, {Fragment} from 'react';

import isMobileViewportCached from '@core/responsive/isMobileViewportCached';

import {Spacing, Separator} from '@phoenix/ui';

import useIsKonbiniActiveTab from '../../../utils/useIsKonbiniActiveTab';
import PaymentKonbiniInfoLayout from '../components/PaymentKonbiniInfoLayout';

const PaymentKonbiniInfo: FC = () => {
  const isKonbiniActiveTab = useIsKonbiniActiveTab();

  if (!isKonbiniActiveTab) {
    return null;
  }

  const withSeparator = isMobileViewportCached();

  return (
    <Fragment>
      <PaymentKonbiniInfoLayout />
      {withSeparator && (
        <Spacing onlyTop>
          <Separator />
        </Spacing>
      )}
    </Fragment>
  );
};

export default PaymentKonbiniInfo;
