import type {FC} from 'react';
import React, {useMemo} from 'react';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import ActivityInteractAppearance from '@core/theming/constants/features/ActivityInteractAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43668
 */
type ThemedActivityPageProps = {
  [key: string]: any;
};

const webResolver: ModuleResolver<
  ActivityInteractAppearance,
  ModuleComponent<ThemedActivityPageProps>
> = (value) => {
  switch (value) {
    /**
     * Temporary solution!
     * After the web design is ready, remove the 'webResolver'.
     */
    case ActivityInteractAppearance.UNITED:
    case ActivityInteractAppearance.WITH_EAR:
      return import(
        /* webpackChunkName: 'activityPage' */ '@phoenix/activity/components/ActivityPage'
      );
    case ActivityInteractAppearance.ALL_ON_PAGE:
      return import(
        /* webpackChunkName: 'activityPageAll' */ '@phoenix/activity/containers/ActivityPageWithAllWidgets'
      );
    case ActivityInteractAppearance.ALL_ON_PAGE_AND_PHOTO_GALLERY_BANNER:
      return import(
        /* webpackChunkName: 'activityPageWithPhotoGalleryBanner' */ '@phoenix/activity/containers/ActivityPageWithPhotoGalleryBanner'
      );
    case ActivityInteractAppearance.WITH_BANNERS_AND_TABS_DISPLAY_CONDITION:
      return import(
        /* webpackChunkName: 'withBannersAndTabsDisplayCondition' */ '@phoenix/activity/containers/ActivityPageWithBannersAndTabsDisplayCondition'
      );
    default:
      return null;
  }
};

const mobileResolver: ModuleResolver<
  ActivityInteractAppearance,
  ModuleComponent<ThemedActivityPageProps>
> = (value) => {
  if (value === ActivityInteractAppearance.UNITED) {
    return import(
      /* webpackChunkName: 'activityPageUnited' */ '@phoenix/activity/containers/ActivityPageUnited'
    );
  }

  return webResolver(value);
};

/**
 * Appearance-changeable activity page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedActivityPage: FC<ThemedActivityPageProps> = (props) => {
  const isPhone = usePhoneBreakpoint();
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedActivityPageProps,
        ActivityInteractAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="activity"
      param="interact"
      moduleResolver={isPhone ? mobileResolver : webResolver}
      {...props}
    />
  );
};

export default ThemedActivityPage;
