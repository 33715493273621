import {getClientInstance} from '@core/graphql/client';

import MESSENGER_GALLERY_QUERY from '../graphql/queries/messengerGallery.gql';
import {LIMIT} from '../constants/limitItemsOnPhotoGallery';

export default function refetchPhotoGallery() {
  getClientInstance().query({
    query: MESSENGER_GALLERY_QUERY,
    variables: {
      limit: LIMIT,
    },
    fetchPolicy: 'network-only',
  });
}
