import logger from '@core/logger';
import type {PhotoPreset} from '@core/user/photo/constants/PhotoPreset';
import {normalToCensoredPhotoPresetMap} from '@core/user/photo/constants/PhotoPreset';
import createImageUrl from '@core/utils/image/createImageUrl';
import type {GenderTypeEnum} from '@core/types';
import ImageGroups from '@core/theming/constants/ImageGroups';

import getThemedImage from '@phoenix/theming/utils/getThemedImage';

type CreatePhotoUrlParams = {
  id?: string;
  gender?: GenderTypeEnum;
  preset?: PhotoPreset;
  censored?: boolean;
  uniform?: boolean;
  updatedOn?: string;
  imageFormat?: string;
  cdnDomain?: string;
  photoUrlExtension?: string;
};

/**
 * Helper method for generating correct links for user photos for CDN (or not)
 * Is the only way to create correct link to a photo, so, please,
 * don't set directly on production photos without using this helper method.
 * Same as createImageUrl but with default image by gander and user-photos specific presets
 */
const createPhotoUrl = ({
  id,
  gender,
  preset,
  censored,
  uniform,
  updatedOn,
  imageFormat,
  cdnDomain,
  photoUrlExtension,
}: CreatePhotoUrlParams): string => {
  if (!id) {
    if (gender) {
      // Missing uniform avatars only for uniformdating
      const group = uniform
        ? ImageGroups.DEFAULT_UNIFORM_AVATAR
        : ImageGroups.DEFAULT_AVATAR;

      return getThemedImage(group, gender);
    }

    logger.sendWarning('No "gender" param specified for static image');
    return '';
  }

  const formatName = censored ? normalToCensoredPhotoPresetMap[preset] : preset;

  return createImageUrl({
    id,
    formatName,
    updatedOn,
    imageFormat,
    cdnDomain,
    photoUrlExtension,
  });
};

export default createPhotoUrl;
