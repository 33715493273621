import REMOVE_ACCOUNT_AVAILABILITY_QUERY from '@phoenix/multiStep/removeAccount/graphql/queries/removeAccountAvailability.gql';

import {REMOVE_ACCOUNT_NOT_ALLOWED} from '../constants/accessErrorCode';

// Not allowed remove account if bought any packages
export default {
  errorCode: REMOVE_ACCOUNT_NOT_ALLOWED,
  query: REMOVE_ACCOUNT_AVAILABILITY_QUERY,
  resolver: (data) => !data?.removeAccount.isCancelBillingPageBeforeRemove,
};
