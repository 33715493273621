import getProcessPaymentFlow from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import getActiveSplitGroup from '@core/utils/split/getActiveSplitGroup';
import {SPLITS} from '@core/utils/split';

import getRemarketingPopupFlow from '@phoenix/payment/payProcess/paymentFlow/flows/getRemarketingPopupFlow';

import payForRemarketingOfferLegacy from './payForRemarketingOfferLegacy';

const payForRemarketingOfferFactory = async (props) => {
  const isFetchApi = await getActiveSplitGroup(
    SPLITS.PAYMENT_PROCESSING_FETCH_API.ID,
    SPLITS.PAYMENT_PROCESSING_FETCH_API.GROUP.ACTIVE,
  );

  return isFetchApi
    ? getProcessPaymentFlow(getRemarketingPopupFlow())(props)
    : payForRemarketingOfferLegacy(props);
};

export default payForRemarketingOfferFactory;
