import startUniformMotivationSubscription from './startUniformMotivationSubscription';

let startedListeners = false;

export default async function startMediaUploadListeners(client) {
  if (startedListeners) {
    return;
  }
  startedListeners = true;

  startUniformMotivationSubscription(client);
}
