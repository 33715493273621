/**
 * Types of pedal contexts combos with other pedals. To detect in which context (combination) pedal was invoked.
 * For example pedal extraDiscount can be invoked right after profilesView and should have another view.
 * In future there can be many different pedal combinations
 */
export const EXTRA_DISCOUNT = 'extra_discount';
export const INTERACTIVE_LIKE = 'interactiveLike';
export const SEARCH_2X = 'search2x';
export const PMA = 'pma';
export const LIKE_ACTION = 'likeAction';
export const SPIN_NOW = 'spinNow';
export const PROFILES_VIEW = 'profilesView';
export const PRIORITY_MAN = 'priorityMan';
export const RM_BUNDLE_ONE_MONTH = 'RMBundle1s';
export const RM_BUNDLE_ONE_WEEK = 'RMBundle2s';
