import {Spacing, SpacingShort} from '@phoenix/ui';
import SearchBannerSpacing from '@phoenix/search/widget/components/SearchBannerSpacing';

import SearchWrapperWithMotivation from '../components/SearchWrapperWithMotivation';
import SearchWrapper from '../components/SearchWrapper';
import ListItemWrapper from '../components/ListItemWrapper';

/**
 * Different banner designs (components) used as wrapper components
 * @see RemarketingBanner.js
 */
const BANNER_WRAPPER = {
  DEFAULT: null,
  LIST_ITEM: ListItemWrapper, // E.g. vertical listings where banner is injected
  SPACED: Spacing,
  ACTIVITY: SpacingShort,
  SEARCH_TILE_WITH_MOTIVATION: SearchWrapperWithMotivation,
  SEARCH_TILE: SearchWrapper,
  SEARCH_COLUMN: SearchBannerSpacing,
};

export default BANNER_WRAPPER;
