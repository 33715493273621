import isEmpty from 'lodash/isEmpty';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';

import CURRENT_USER_PRIMARY_PHOTO_QUERY from '@core/user/profile/current/graphql/queries/currentUserPrimaryPhoto.gql';
import {REQUIRED_PHOTO} from '@core/mediaUpload/constants/sources';
import {errorPropType} from '@core/mediaUpload/constants/propTypes';

/**
 * When upload multiple files need check should we show close button popup if even one file was uploaded success.
 * UseQuery because sometimes slow update interaction changePrimaryPhotoNotice. After update data need rerender.
 */
const useCloseButtonAllowedOnPhotoUpload = ({source, errors}) => {
  const {data, loading, error} = useQuery(CURRENT_USER_PRIMARY_PHOTO_QUERY);
  const skipLogic =
    source !== REQUIRED_PHOTO || isEmpty(errors) || loading || error;

  if (skipLogic) {
    return false;
  }

  const {
    myUser: {
      profile: {
        photos: {primaryPhoto},
      },
    },
  } = data;

  return Boolean(primaryPhoto && !primaryPhoto?.pendingDelete);
};

useCloseButtonAllowedOnPhotoUpload.propTypes /* remove-proptypes */ = {
  errors: PropTypes.arrayOf(errorPropType),
  source: PropTypes.string,
};

export default useCloseButtonAllowedOnPhotoUpload;
