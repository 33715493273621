/**
 * Need to membership concepts
 * @const {String}
 */
export const MEMBERSHIP_RM_VIA = 'membership';

/**
 * Need for features concepts
 * @const {String}
 */
export const FEATURES_RM_VIA = 'features';

/**
 * @const {String}
 */
export const REMARKETING_SHOW_POPUP = 'remarketing_show_popup';

/**
 * @const {String}
 */
export const REMARKETING_POPUP = 'remarketing_popup';
