import isInRouteList from '@core/utils/routing/isInRouteList';
import {getActiveSplitGroup, SPLITS} from '@core/utils/split';
import {sessionStorage} from '@core/utils/storage';
import isPopupOpenVar from '@core/graphql/vars/isPopupOpenVar';

import openProgressiveWebApplicationMotivationPopup from '@phoenix/progressiveWebApplication/utils/openProgressiveWebApplicationMotivationPopup';
import openTargetCustomPopup from '@phoenix/webpush/utils/openTargetCustomPopup';

import STORAGE_KEY from '../constants/storageKey';
import ACTIONS from '../constants/actions';
import DATEINFORM_RESUBSCRIBE_LOGIC from '../constants/dateinformSubscribeLogic';
import POPUP_VIEW from '../constants/popupView';
import TRACK from '../constants/track';
import openPopupWithUserPhotos from '../utils/openPopupWithUserPhotos';
import openCustomPopup from '../utils/openCustomPopup';
import getRouteObservable from '../utils/getRouteObservable';
import getActivityObservable from '../utils/getActivityObservable';
import DateinformHandler from './DateinformHandler';
import CommonLogic from './CommonLogic';
import openNativeLikePopup from '../utils/openNativeLikePopup';

/**
 * Containing all the necessary methods to deal with actions
 * actions list: ROUTE, CHAT, LIKE
 * default action: ROUTE
 * @constructor
 */
export default class ActionLogic extends CommonLogic {
  /**
   * @param {Object} options
   * @param {Object} logicsMediator
   */
  constructor({logicsMediator, options}) {
    super({
      logicsMediator,
      options: {
        /**
         * Time in ms that is used to postpone a logic`s showing up
         * @type {number}
         */
        timeout: 0,

        /**
         * If there is no action, action 'route' is used
         * For instance it is a value for the 'default' logic
         * @type {string}
         */
        action: ACTIONS.ROUTE,

        /**
         * @type {string}
         */
        name: 'default',

        ...options,
      },
    });

    /**
     * The flag is activated if a logic's timeout is already started
     * @var
     * @type {Boolean}
     */
    this.isActiveTimeout = false;

    /**
     * @type {string}
     */
    this.logicTimeoutKey = `${this.logicsMediator.getStorageKey(
      STORAGE_KEY.LOGIC_TIMEOUT,
    )}::${this.options.action}`;

    /**
     * @type {string|null}
     */
    this.toUserId = null;
  }

  /**
   * The main entry point to set up a certain logic
   * @override
   */
  setup() {
    if (this.getTimeoutExpirationTime()) {
      this.startWithTimeout();
    }

    super.setup();
  }

  /**
   * @protected
   */
  async initListeners() {
    this.isWebPushCustomPopupSplit = await getActiveSplitGroup(
      SPLITS.WEB_PUSH_CUSTOM_POPUPS.ID,
      SPLITS.WEB_PUSH_CUSTOM_POPUPS.GROUP.ACTIVE,
    );

    switch (this.options.action) {
      case ACTIONS.LIKE:
      case ACTIONS.CHAT:
        this.listeners.push(
          getActivityObservable(this.options.action).subscribe((toUserId) => {
            this.toUserId = toUserId;
            this.start();
          }),
        );
        return;
      default:
        this.listeners.push(getRouteObservable().subscribe(() => this.start()));
    }
  }

  /**
   * Pay your attention that DATEINFORM_POPUP and DATEINFORM_POPUNDER mean to show a window WITHOUT a popup unlike how it might have seemed at the first look
   * @return {boolean}
   * @private
   */
  isDateinformResibscribeLogicPopup() {
    return (
      this.logicHandler instanceof DateinformHandler &&
      this.options.dateInformResubscribeLogic ===
        DATEINFORM_RESUBSCRIBE_LOGIC.CUSTOM_POPUP
    );
  }

  /**
   * @return {boolean}
   * @override
   */
  isStartDisabled() {
    return super.isStartDisabled() || this.isActiveTimeout;
  }

  /**
   * Open a popup and listen to its events
   * @private
   */
  openPopup() {
    if (this.logicsMediator.isPopupOpened) {
      this.track(TRACK.ACTIONS.CONFLICTED);
      return;
    }

    /**
     * Try to show PWA motivation popup instead web push popups
     */
    if (openProgressiveWebApplicationMotivationPopup()) return;

    if (this.options.popup === POPUP_VIEW.CUSTOM_DEFAULT) {
      const openPopup = this.isWebPushCustomPopupSplit
        ? openTargetCustomPopup
        : openNativeLikePopup;

      openPopup({
        popupType: this.options.popup,
        onAccept: () => this.onPopupAccept(),
        onClose: () => this.onPopupClose(),
        onDeny: () => this.onPopupDeny(),
      });

      this.track(TRACK.ACTIONS.PROPOSED);
    }

    if (
      this.options.popup === POPUP_VIEW.CUSTOM ||
      this.isDateinformResibscribeLogicPopup()
    ) {
      openCustomPopup({
        onAccept: () => this.onPopupAccept(),
        onClose: () => this.onPopupClose(),
      });

      this.track(TRACK.ACTIONS.PROPOSED);
    }

    if (
      [POPUP_VIEW.CUSTOM_WITH_USERS, POPUP_VIEW.CHAT, POPUP_VIEW.LIKE].includes(
        this.options.popup,
      )
    ) {
      openPopupWithUserPhotos({
        onAccept: () => this.onPopupAccept(),
        onClose: () => this.onPopupClose(),
        onDeny: () => this.onPopupDeny(),
        textType: this.options.action,
        popupType: this.options.popup,
        userId:
          this.options.popup !== POPUP_VIEW.CUSTOM_WITH_USERS
            ? this.toUserId
            : '',
      });

      this.track(TRACK.ACTIONS.PROPOSED);
    }
  }

  /**
   * @return {number}
   * @private
   */
  getTimeoutValue() {
    return this.getTimeoutExpirationTime() - Date.now();
  }

  /**
   * @private
   */
  setTimeoutExpirationTime() {
    sessionStorage.setItem(
      this.logicTimeoutKey,
      new Date().getTime() + this.options.timeout,
    );
  }

  /**
   * @return {number}
   * @private
   */
  getTimeoutExpirationTime() {
    return sessionStorage.getItem(this.logicTimeoutKey);
  }

  /**
   * @private
   */
  removeTimeoutExpirationTime() {
    sessionStorage.removeItem(this.logicTimeoutKey);
  }

  /**
   * Purge all the timeout data
   * @public
   */
  purgeTimeoutData() {
    this.isActiveTimeout = false;
    this.removeTimeoutExpirationTime();
    clearTimeout(this.options.timeoutId);
  }

  /**
   * The main method to start a subscription
   * @protected
   */
  start() {
    if (this.isStartDisabled()) {
      return;
    }

    /**
     * All the timeouts' data for all the logics should be purged since a certain logic is starting
     * so it means this logic has enough conditions to overlap the other ones
     */
    this.logicsMediator.purgeAllTimeoutsData();

    if (this.options.timeout) {
      this.setTimeoutExpirationTime();
      this.startWithTimeout();
      return;
    }

    this.logger('Started without a timeout');

    this.tryToShow();
  }

  /**
   * Start a subscription with a timeout
   * @private
   */
  startWithTimeout() {
    this.isActiveTimeout = true;
    const timeoutValue = this.getTimeoutValue();

    this.options.timeoutId = setTimeout(() => {
      this.tryToShow();
      this.purgeTimeoutData();
    }, timeoutValue);

    this.logger(`Started with a timeout ${timeoutValue}`);
  }

  /**
   * Show a popup, start a subscription or do nothing if everything is disallowed
   * @private
   */
  tryToShow() {
    if (isInRouteList(this.options.disabledRoutes, window.location.pathname)) {
      this.logger('Can`t be shown here');
      return;
    }

    // Open a popup before the main subscription
    if (this.options.popup && this.options.popup !== POPUP_VIEW.NONE) {
      this.openPopup();
      return;
    }

    if (this.logicsMediator.isPopupOpened) {
      // Start the main subscription after all popups are closed
      this.logger("Can't start subscription. Wait for all popups to be closed");
      isPopupOpenVar.onNextChange(() => {
        this.logger('All popups are closed, start subscription');
        this.startSubscription();
      });
    } else {
      // Start the main subscription immediately
      this.startSubscription();
    }
  }
}
