import isEmpty from 'lodash/isEmpty';
import mergeWith from 'lodash/mergeWith';

import logger from '@core/logger';
import {getClientInstance} from '@core/graphql/client';
import {removeTypenameRecursively} from '@core/graphql/utils/removeTypename';
import {localStorage} from '@core/utils/storage';

import WEB_PUSH_QUERY from '../graphql/queries/webpush.gql';
import LogicsMediator from './LogicsMediator';
import WebPushService from '../WebPushService';
import WebPushError from '../utils/WebPushError';
import STORAGE_KEY from '../constants/storageKey';
import InteractionLogic from './InteractionLogic';
import ActionLogic from './ActionLogic';
import CoinsLogic from './CoinsLogic';
import {WS_ACTIONS} from '../constants/actions';

let instance;

/**
 * The entry point to initialize all the logics which options have been received from a server-side
 * A server's response with the options looks like:
 *
 * @example
 * options: {
 *      ...
 *      logicOptions: {
 *          general: {
 *              ...
 *          }
 *          like: {
 *              ...
 *              alter: {...}
 *          }
 *          route: {
 *              ...
 *              alter: {...}
 *          }
 *          ...
 *      }
 * }
 *
 * The main idea is to be able to have all the subscription logics started at the same time and listen to
 * certain actions to show up (like a user, change a route)
 * They may have their own timers with a delay before showing up
 * Also they have priority to prevent them to conflict between each other
 * Under certain conditions they may switch to their 'alter' subscription logic which contains different options
 * and therefore works differently
 * All the logics' options override the options in the 'general' field and then those options may be overridden
 * by the 'alter' field
 * @class
 */
class WebPushNotification {
  /**
   * @public
   * @param {string} userId
   */
  static initialize(userId) {
    if (instance) return;

    instance = new WebPushNotification();

    instance.run(userId);
  }

  /**
   * @private
   * @param userId
   * @returns {Promise<void>}
   */
  async run(userId) {
    const {data} = await this.getData();

    this.options = {
      ...removeTypenameRecursively(data.userFeatures.webPush),
      userId,
      pwaScenario: data?.pwa?.scenario,
    };

    this.generalOptions = this.getGeneralOptions();

    const {
      pauseAfterIgnore,
      pauseAfterDeny,
      disableAfterIgnoreCount,
      disableAfterDenyCount,
    } = this.generalOptions;

    /**
     * Initialize a mediator and pass there options which are needed to deal with all the logics
     */
    this.logicsMediator = new LogicsMediator({
      pauseAfterIgnore,
      pauseAfterDeny,
      disableAfterIgnoreCount,
      disableAfterDenyCount,
      userId: this.options.userId,
    });

    this.resendDataIfGranted();
    this.initLogics();
  }

  /**
   * @returns {Promise<{}>}
   */
  async getData() {
    try {
      return getClientInstance().query({
        query: WEB_PUSH_QUERY,
      });
    } catch (error) {
      logger.sendError(error);

      return Promise.resolve({});
    }
  }

  /**
   * Initialize all the logic by getting them from a server's response
   * If there are no logicOptions, initialize only the default logic instead
   * @private
   */
  initLogics() {
    if (!isEmpty(this.options.logicOptions)) {
      Object.keys(this.options.logicOptions)
        .filter(
          (key) => this.options.logicOptions[key] !== null && key !== 'general',
        )
        .forEach((action) => {
          this.registerLogic(
            this.getConstructor(action),
            this.getLogicOptions(action),
          );
        });
    } else {
      // If logicOptions is empty run BaseLogic with default action ROUTE
      this.registerLogic(this.getConstructor(), this.getRootOptions());
    }
    // Run all the registered logics
    this.logicsMediator.setup();
  }

  /**
   * @private
   */
  resendDataIfGranted() {
    const pushService = WebPushService.getInstance(this.options);
    if (pushService) {
      pushService.resendDataIfGranted();
    }
  }

  /**
   * @param {String} action
   * @returns {InteractionLogic|CoinsLogic|ActionLogic}
   */
  getConstructor(action) {
    switch (action) {
      case WS_ACTIONS.SEND_FIRST_MESSAGE:
      case WS_ACTIONS.ADD_FIRST_FAVORITE:
      case WS_ACTIONS.ADD_LIKE:
      case WS_ACTIONS.OPEN_PROFILE:
      case WS_ACTIONS.FREE_MESSAGE_FINISHED:
        return InteractionLogic;
      case WS_ACTIONS.SEND_SECOND_MESSAGE:
        return CoinsLogic;
      default:
        return ActionLogic;
    }
  }

  /**
   * Get general options for all the logics
   * A certain logic may override these options with its own options
   * @return {Object}
   * @private
   */
  getGeneralOptions() {
    return {
      ...this.getRootOptions(),
      ...(this.options.logicOptions && this.options.logicOptions.general),
    };
  }

  /**
   * The root options for all the logics
   * They may be extended with a specific logic's options or may not if it's the default logic
   * @return {Object}
   * @private
   */
  getRootOptions() {
    return {
      // There is a need to get some options from the object's root level
      logic: this.options.subscribeLogic,
      dateInformEnabled: this.options.dateInformEnabled,
      dateInformResubscribeLogic: this.options.dateInformResubscribeLogic,
      disabledRoutes: this.options.disabledRoutes,

      // The root object's level. These options may only be needed for a native webPush popup
      defaultOptions: this.options,
    };
  }

  /**
   * Get a certain logic's options
   * @param {String} action
   * @return {Object}
   * @private
   */
  getLogicOptions(action) {
    return {
      ...mergeWith(
        {...this.generalOptions},
        this.options.logicOptions[action],
        (generalValue, actionValue) =>
          actionValue === null ? generalValue : actionValue,
      ),
      // Pass an action to each logic's constructor in order to know what logic is being processed
      action,
    };
  }

  /**
   * @param {Object} Constructor
   * @param {Object} options
   * @private
   */
  registerLogic(Constructor, options) {
    try {
      this.logicsMediator.register(
        new Constructor({options, logicsMediator: this.logicsMediator}),
      );
    } catch (error) {
      if (error instanceof WebPushError) {
        if (localStorage.getItem(STORAGE_KEY.LOGGER_ENABLED)) {
          // eslint-disable-next-line no-console
          console.log(
            `Web push logic ${options.action.toUpperCase()} hasn't been set up: ${
              error.message
            }`,
          );
        }
      } else {
        // Show an error without an impact on a script execution
        // eslint-disable-next-line no-console
        console.error(error);

        logger.sendError(error);
      }
    }
  }
}

export default WebPushNotification;
