import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import TrustedVerificationPopup from '../containers/TrustedVerificationPopup';

const openTrustedVerificationPopup = () => {
  PopupService.openPopup(
    <TrustedVerificationPopup
      // Passed via props to avoid circular dependency.
      openTrustedVerificationPopup={openTrustedVerificationPopup}
    />,
    {
      small: true,
      trackingName: 'trustedVerification',
    },
  );
};

export default openTrustedVerificationPopup;
