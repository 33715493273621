import React, {lazy, Suspense} from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import {Loader} from '@phoenix/ui';

const MessagesCountPopupContent = lazy(
  () =>
    import(
      /* webpackChunkName: 'messagesCountPopup' */ '../containers/MessagesCountPopup'
    ),
);

const openMessagesCountPopup = (count, markAsShown) => {
  PopupService.openPopup(
    <Suspense fallback={<Loader small />}>
      <MessagesCountPopupContent count={count} markAsShown={markAsShown} />
    </Suspense>,
    {
      popupName: 'MessagesCountPopup',
      small: true,
      trackingName: 'messagesCount',
      priority: PopupPriority.MIDDLE,
      groupKey: 'messagesCount',
    },
  );
};

export default openMessagesCountPopup;
