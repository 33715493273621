import React from 'react';

import {PlaceholderBar, PlaceholderBlock} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

const ConfirmationContentMailNoticeLayoutPlaceholder = () => (
  <PopupNotice
    heading={<PlaceholderBlock size={80} inline />}
    title={<PlaceholderBar size={5} inline />}
    subTitle={<PlaceholderBar size={7} inline />}
    withoutBottom
  />
);

export default ConfirmationContentMailNoticeLayoutPlaceholder;
