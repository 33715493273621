import type {FC, MouseEvent} from 'react';
import React from 'react';
import cn from 'classnames';

import ArrowPositionAppearance from '@core/theming/constants/features/ArrowPositionAppearance';

import {ButtonFlat} from '@phoenix/ui';
import {Direction} from '@phoenix/ui/constants';

import css from './NavigationButton.css';

type NavigationButtonProps = {
  arrowsPosition?: ArrowPositionAppearance;
  direction: Direction;
  inverse?: boolean;
  onClick?: (e: MouseEvent) => void;
  overlay?: boolean;
};

const NavigationButton: FC<NavigationButtonProps> = ({
  arrowsPosition,
  direction,
  inverse = false,
  onClick,
  overlay = true,
}) => {
  const onTop = arrowsPosition === ArrowPositionAppearance.ON_TOP;
  const trackingName =
    direction === Direction.LEFT ? 'navigationPrev' : 'navigationNext';

  return (
    <div
      className={cn(
        onTop ? css[`${direction}OnTopIcon`] : css[`${direction}OnSideIcon`],
        !onTop && overlay && css.overlay,
      )}
      data-test="navigationButtonWrap"
    >
      <ButtonFlat
        round
        icon={direction}
        inverse={inverse}
        onClick={onClick}
        trackingName={trackingName}
        data-test={trackingName}
      />
    </div>
  );
};

export default NavigationButton;
