/**
 * Web-push notification event list
 * @const
 */
const NOTIFICATION_EVENT = {
  ACCEPTED: 'pushNotificationAccepted',
  DECLINED: 'pushNotificationDeclined',
  CLOSE: 'pushNotificationClose',
  /**
   * Triggered when notification permissions have been changed
   */
  CHANGED: 'pushNotificationChanged',
  PROPOSED: 'pushNotificationProposed',
};

export default NOTIFICATION_EVENT;
