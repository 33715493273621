import type {ReactNode} from 'react';

type LevelTexts = {
  [key: string]: ReactNode;
  DEFAULT: ReactNode;
};

type Texts = {
  [key: string]: LevelTexts;
  DEFAULT: LevelTexts;
};

type UseDescriptionProps = {
  texts: Texts;
  size: string;
  level: string;
};

const getLevelOverlayDescription = ({
  texts,
  size,
  level,
}: UseDescriptionProps): ReactNode => {
  const sizeTexts = texts[size] || texts.DEFAULT;

  return sizeTexts[level] || sizeTexts.DEFAULT;
};

export default getLevelOverlayDescription;
