import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import FreeFeatureStartPopup from '../containers/FreeFeatureStartPopup';
import FreeFeatureStatisticPopup from '../containers/FreeFeatureStatisticPopup';
import FreeFeaturePopupTypes from '../constants/freeFeaturePopupTypes';
import freeFeaturesConfigMap from '../configs';

/**
 * Map to bind popup type to corresponding component.
 * Popup type should be declared in config. (@see ./configs)
 */
const freeFeaturePopupsMap = {
  [FreeFeaturePopupTypes.START]: FreeFeatureStartPopup,
  [FreeFeaturePopupTypes.STATISTIC]: FreeFeatureStatisticPopup,
};

/**
 * Entry point to show Free Feature Popups.
 * @param {Object} config
 * @param {String} config.name - feature name to match appropriate config.
 * @param {Function} [config.onTimeOver]
 * @param {Function} config.onActionClick
 * @param {Object} config.options - Any additional data that need to be passed to the component.
 */
const openFreeFeaturePopup = ({
  name,
  onActionClick,
  onTimeOver = () => PopupService.closePopup(),
  options = {
    withPopupName: false,
  },
}) => {
  const {type, ...config} = freeFeaturesConfigMap[name](options);
  const PopupComponent = freeFeaturePopupsMap[type];

  PopupService.openPopup(
    <PopupComponent
      config={config}
      onTimeOver={onTimeOver}
      onActionClick={onActionClick}
    />,
    {
      small: true,
      priority: PopupPriority.LOW,
      autoLoadTracking: true,
      trackingName: `freeFeature_${name}`,
    },
  );
};

export default openFreeFeaturePopup;
