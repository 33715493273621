import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {H4, TextWithIcon} from '@phoenix/typography';
import {VerticalAlign} from '@phoenix/ui/constants';

import css from '../styles/DisapproveReasonsTitle.css';

const DisapproveReasonsTitle = ({icon, inPopup, inverse, withIcon = true}) => {
  const content = (
    <H4 className={inPopup && css.title} spaced inverse={inverse}>
      {t('disapproveReason', 'title.disapproval_reasons')}
    </H4>
  );
  return withIcon ? (
    <TextWithIcon verticalAlign={VerticalAlign.CENTER} icon={icon}>
      {content}
    </TextWithIcon>
  ) : (
    content
  );
};

DisapproveReasonsTitle.propTypes /* remove-proptypes */ = {
  icon: PropTypes.string,
  inPopup: PropTypes.bool,
  inverse: PropTypes.bool,
  withIcon: PropTypes.bool,
};

export default DisapproveReasonsTitle;
