import once from 'lodash/once';
import {from, combineLatest} from 'rxjs';

import {
  readFromSessionStorage,
  writeToSessionStorage,
} from '@core/utils/storage';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isPayUrl from '@core/utils/url/isPayUrl';
import GLOBAL_FREE_MESSAGES_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessages.gql';

import openMessagesCountPopup from '../../common/utils/openMessagesCountPopup';
import GLOBAL_FREE_MESSAGE_INCREASE_SUBSCRIPTION from '../graphql/subscriptions/globalFreeMessageCountIncrease.gql';

const GLOBAL_FREE_MESSAGE_POPUP_STORAGE_KEY = 'showGlobalFreeMessagePopup';

const EMPTY_STORAGE_STATE = {showPopup: false, count: 0, time: 0};
const getGlobalFreeMessagesPopupFromStorage = () => {
  const data = readFromSessionStorage(GLOBAL_FREE_MESSAGE_POPUP_STORAGE_KEY);
  return data || EMPTY_STORAGE_STATE;
};

const setGlobalFreeMessagesPopupInStorage = (data) => {
  writeToSessionStorage(GLOBAL_FREE_MESSAGE_POPUP_STORAGE_KEY, data);
};

const markAsShown = () =>
  setGlobalFreeMessagesPopupInStorage(EMPTY_STORAGE_STATE);

const getActualCount = (count, time, cache) => {
  return time > cache?.time || !cache?.count ? count : cache?.count;
};

const startGlobalFreeMessagesPopupListeners = once((client) => {
  from(
    client.subscribe({query: GLOBAL_FREE_MESSAGE_INCREASE_SUBSCRIPTION}),
  ).subscribe(
    ({
      data: {
        globalFreeMessage: {count, time},
      },
    }) => {
      setGlobalFreeMessagesPopupInStorage({
        count,
        time,
        showPopup: true,
      });
    },
  );

  combineLatest(
    from(client.watchQuery({query: ROUTE_QUERY})),
    from(client.watchQuery({query: GLOBAL_FREE_MESSAGES_QUERY})),
  ).subscribe(
    ([
      {
        data: {
          route: {current},
        },
      },
      {
        data: {
          messenger: {
            initialData: {globalFreeMessages},
          },
        },
      },
    ]) => {
      const {count, time, showPopup} = getGlobalFreeMessagesPopupFromStorage();
      if (
        !showPopup ||
        isPayUrl(current) ||
        current.startsWith('/funnel') ||
        current.startsWith('/remarketingOffer')
      ) {
        return;
      }

      const updatedCount = getActualCount(count, time, globalFreeMessages);

      openMessagesCountPopup(updatedCount, markAsShown);
    },
  );
});

export default startGlobalFreeMessagesPopupListeners;
