import type {FC} from 'react';
import React from 'react';

import type {ProgressWithTimerProps} from '@core/widgets/time/ProgressWithTimer';
import BaseProgressWithTimer from '@core/widgets/time/ProgressWithTimer';

import {ProgressType} from '@phoenix/ui/constants';
import {Progress} from '@phoenix/ui';
import {H3} from '@phoenix/typography';

const ProgressWithTimer: FC<Omit<ProgressWithTimerProps, 'Progress'>> = ({
  type,
  children,
  ...props
}) => (
  <BaseProgressWithTimer Progress={Progress} type={type} {...props}>
    {children ||
      ((formatted) => (
        <H3 inverse={type === ProgressType.LIGHT}>{formatted}</H3>
      ))}
  </BaseProgressWithTimer>
);

export default ProgressWithTimer;
