import {useMemo} from 'react';
import {useQuery} from '@apollo/client';

import useCachedTargetUserData from '@core/user/profile/target/utils/useCachedTargetUserData';

import TARGET_WEBCAM_USER from '../graphql/queries/targetWebcamUser.gql';

/**
 * Hook for get webcam status for user
 */
const useGetWebcamProfileData = (userId, forceWebCamPartner) => {
  const {loading, error, data} = useQuery(TARGET_WEBCAM_USER, {
    variables: {userId, partner: forceWebCamPartner},
  });

  const cachedData = useCachedTargetUserData(userId);
  const webcamPlacements = useMemo(
    () => data?.user.webcamPlacements || {},
    [data],
  );

  if (!cachedData && (loading || error || !data)) {
    return {loading, error};
  }

  const {
    profile: {
      statuses: {isAdmin, isVirtualAssistant, isModerator, isWebcamProfile},
    },
  } = data?.user || cachedData;

  return {
    loading: loading && !cachedData,
    error,
    isWebcamProfile: isWebcamProfile && Boolean(webcamPlacements),
    isAdmin,
    isModerator,
    isVirtualAssistant,
    webcamPlacements,
  };
};

export default useGetWebcamProfileData;
