import webPushLogger from '../utils/webPushLogger';

/**
 * This handler needs to provide showing web push motivation popup by logicOptions, instead custom web push popups
 * needs for Chrome and Safari (default browser, instead PWA), which doesn't have service worker functionality
 * @class NativeStubHandler
 */
export default class NativeStubHandler {
  constructor(options = {}) {
    this.trackingService = options.trackingService;
    this.options = options;
    this.webPush = {};

    webPushLogger('Stub logic enabled', 'NativeStubHandler');
  }

  /**
   * @public
   * @return {boolean}
   */
  isAllowedPushService() {
    return true;
  }

  /**
   * @public
   * @return {boolean}
   */
  isPermissionDenied() {
    return false;
  }

  /**
   * @public
   * @return {*|boolean}
   */
  isPermissionDefault() {
    return true;
  }

  /**
   * @public
   * @return {*|boolean}
   */
  isPermissionGranted() {
    return false;
  }

  /**
   * @public
   */
  trySubscribe() {}
}
