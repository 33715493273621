import once from 'lodash/once';
import {fromEvent, from, merge, EMPTY} from 'rxjs';
import {bufferToggle, filter} from 'rxjs/operators';

import {isWebSocketConnected} from '@core/websocket/utils/ws';

import ZONES from '@phoenix/remarketing/popunder/constants/popunderZone';
import POPUNDER_QUERY from '@phoenix/remarketing/popunder/graphql/queries/popunder.gql';

import REMARKETING_RELOAD_SUBSCRIPTION from '../graphql/subscriptions/remarketingReload.gql';
import RemarketingBannerCollector from './RemarketingBannerCollector';
import {POPUP_COREG_ZONE} from '../../coregistration/constants/zones';

const startRemarketingReloadListener = once(
  (client, coregistrationContextRef) => {
    const rmReload$ = from(
      client.subscribe({query: REMARKETING_RELOAD_SUBSCRIPTION}),
    );
    const tabVisibility$ = fromEvent(document, 'visibilitychange');

    merge(
      // Emits rmReload$ values immediately if tab is active
      rmReload$.pipe(filter(() => !document.hidden)),
      // Buffers the rmReload$ values until tab is NOT active.
      // Emits from buffer right after tab activation!
      rmReload$.pipe(
        bufferToggle(tabVisibility$, () =>
          document.hidden ? tabVisibility$ : EMPTY,
        ),
        // get rid of an empty array, returned by bufferToggle() on 'visibilitychange'
        filter((events) => events.length),
      ),
    ).subscribe(() => {
      const {zones, loadZoneInfo} = coregistrationContextRef.current;
      const controller = RemarketingBannerCollector.getInstance();

      Object.keys(zones).forEach(
        (zone) =>
          // There is no need to re-fetch the data for the pop-up zones, as they need to be shown once
          !Object.values(POPUP_COREG_ZONE).includes(zone) && loadZoneInfo(zone),
      );
      controller.refetchBanners();

      // Refetch profile menu popunder URLs on remarketingReload event.
      client.query({
        query: POPUNDER_QUERY,
        variables: {
          zone: ZONES.PROFILE_MENU,
          async: isWebSocketConnected(),
        },
        fetchPolicy: 'network-only',
      });
    });
  },
);

export default startRemarketingReloadListener;
