import {combineLatest} from 'rxjs';

import {localStorage} from '@core/utils/storage/storage';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';

import UNIFORM_MOTIVATION_QUERY from '../graphql/queries/uniformMotivation.gql';
import openPhotoUploadPopup from './openPhotoUploadPopup';

const CURRENT_ROUTE = '/search';
const PREVIOUS_ROUTE = '/user/view';
const STORAGE_KEY_PREFIX = 'UniformMotivationViewed';

export default async function startUniformMotivationSubscription(client) {
  const subscription = combineLatest(
    client.watchQuery({query: UNIFORM_MOTIVATION_QUERY}),
    client.watchQuery({query: ROUTE_QUERY}),
  ).subscribe(
    ([
      {
        data: {
          myUser: {
            id,
            profile: {
              photos: {count},
            },
          },
          userFeatures: {uniform},
        },
      },
      {
        data: {
          route: {current, previous},
        },
      },
    ]) => {
      const storageKey = `${STORAGE_KEY_PREFIX}:${id}`;

      if (!uniform || localStorage.getItem(storageKey)) {
        subscription.unsubscribe();
        return;
      }

      if (
        current &&
        current.startsWith(CURRENT_ROUTE) &&
        previous &&
        previous.startsWith(PREVIOUS_ROUTE) &&
        !count
      ) {
        subscription.unsubscribe();
        localStorage.setItem(storageKey, true);
        openPhotoUploadPopup();
      }
    },
  );
}
