import React from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';

import {SpacingDirection} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {ButtonLinkPrimary, Spacing, SpacingShort} from '@phoenix/ui';

import CoinsTelegramBotHeader from '../containers/CoinsTelegramBotHeader';
import CoinsTelegramBotSubscribeMotivation from '../containers/CoinsTelegramBotSubscribeMotivation';

const CoinsTelegramBotSubscriptionPopup = ({url, closePopup, userId}) => {
  const isPhone = usePhoneBreakpoint();
  return (
    <PopupNotice
      withoutLeft
      withoutRight
      withoutTop={isPhone}
      data-test="coinsTelegramPopup"
      heading={<CoinsTelegramBotHeader userId={userId} />}
      title={
        <Spacing direction={SpacingDirection.HORIZONTAL}>
          {t('coins', 'title.tg_subscribe_title')}
        </Spacing>
      }
      description={
        <SpacingShort onlyTop>
          <CoinsTelegramBotSubscribeMotivation />
        </SpacingShort>
      }
      actions={[
        <Spacing direction={SpacingDirection.HORIZONTAL}>
          <ButtonLinkPrimary
            fullWidth
            target="_blank"
            icon="send"
            href={url}
            onClick={closePopup}
          >
            {t('coins', 'button.tg_subscribe')}
          </ButtonLinkPrimary>
        </Spacing>,
      ]}
    />
  );
};

CoinsTelegramBotSubscriptionPopup.propTypes /* remove-proptypes */ = {
  url: PropTypes.string.isRequired,
  userId: PropTypes.string,
  closePopup: PropTypes.func,
};

export default CoinsTelegramBotSubscriptionPopup;
