import {useQuery} from '@apollo/client';

import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';
import useCurrentCardBinInfo from '@core/payment/forms/card/utils/useCurrentCardBinInfo';
import getPriceWithCurrency from '@core/payment/common/utils/getPriceWithCurrency';

import type {ProcessingFeeQuery} from '../graphql/queries/processingFee';
import calculateTotalAmount from '../../../common/utils/calculateTotalAmount';
import PROCESSING_FEE_QUERY from '../graphql/queries/processingFee.gql';

type UseProcessingFeeResult = {
  loading: boolean;
  fee: string | null;
  amount: string | null;
  financialServicePlatform: string | null;
};

type ProcessingFeeData = {
  loading: boolean;
  data: ProcessingFeeQuery | undefined;
};

const useProcessingFee = (): UseProcessingFeeResult => {
  let fee: string | null = null;
  let amount: string | null = null;

  const {activePackage} = useActivePackage();
  const {bin, financialServicePlatform} = useCurrentCardBinInfo();
  const {action} = usePaymentParams();
  const {data, loading}: ProcessingFeeData = useQuery<ProcessingFeeQuery>(
    PROCESSING_FEE_QUERY,
    {
      variables: {bin, action},
      skip: !bin,
    },
  );

  const processingFee = data?.payment.processingFee;

  if (
    !processingFee?.fee ||
    !activePackage ||
    !activePackage.isTrialMembership
  ) {
    return {loading, fee, amount, financialServicePlatform};
  }

  const {fullPrice} =
    activePackage.packagePricingDetails.packageDiscountPriceData ||
    activePackage.packagePricingDetails.packagePriceData;

  const {
    currency: {prefix, suffix},
  } = fullPrice;

  fee = processingFee.fee.formatted.amount;
  amount = calculateTotalAmount(fee, fullPrice.formatted.amount);

  return {
    loading,
    fee: getPriceWithCurrency(fee, {prefix, suffix}),
    amount: getPriceWithCurrency(amount, {prefix, suffix}),
    financialServicePlatform,
  };
};

export default useProcessingFee;
