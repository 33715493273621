import {useReactiveVar} from '@apollo/client';
import {useEffect} from 'react';

import allowedDelayedRenderVar from '../vars/allowedDelayedRenderVar';
import isAllowedRequestIdle from './isAllowedRequestIdle';

/**
 * Check if app can show delayed content when main requests are loaded
 * @return {boolean}
 */
const useAllowedDelayedRender = () => {
  const allowedDelayedRender = useReactiveVar(allowedDelayedRenderVar);

  useEffect(() => {
    let handle = null;
    const isAllowed =
      isAllowedRequestIdle() && !window.IS_INTEGRATION_TEST_ENVIRONMENT;

    if (isAllowed) {
      handle = () => allowedDelayedRenderVar(true);
      window.requestIdleCallback(handle);
    }

    return () => {
      if (isAllowed) {
        window.cancelIdleCallback(handle);
      }
    };
  }, []);

  return allowedDelayedRender || window.IS_INTEGRATION_TEST_ENVIRONMENT;
};

export default useAllowedDelayedRender;
