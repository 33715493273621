import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {
  REWARD_REASONS,
  REWARD_FOR_TELEGRAM_BOT_SUBSCRIBED,
} from '@phoenix/credits/constants/balanceChangeReason';
import CoinsCommonSuccessPopup from '@phoenix/coins/buyCoins/components/CoinsCommonSuccessPopup';

const getTranslationsForPopupByReason = (reason) => {
  switch (reason) {
    case REWARD_FOR_TELEGRAM_BOT_SUBSCRIBED:
      return {
        title: (amount) =>
          t('freeCoins', 'title.rewardTelegramBotMotivation', {
            '{quantity}': amount,
          }),
        description: () => t('freeCoins', 'text.rewardTelegramBotMotivation'),
        action: () => t('freeCoins', 'action.rewardTelegramBotMotivation'),
      };
    default:
      return {
        title: () => t('freeCoins', 'title.commonRewardMotivation'),
        description: (amount) =>
          t('freeCoins', 'text.commonRewardMotivation', {
            '{n}': amount,
          }),
        action: () => t('freeCoins', 'action.commonRewardMotivation'),
      };
  }
};
/**
 * Popup with reward info after completing certain tasks.
 */
const FreeCoinsRewardPopup = ({reason, amount}) => {
  const {title, description, action} = getTranslationsForPopupByReason(reason);
  return (
    <CoinsCommonSuccessPopup
      title={title(amount)}
      description={description(amount)}
      action={action()}
    />
  );
};

FreeCoinsRewardPopup.propTypes /* remove-proptypes */ = {
  reason: PropTypes.oneOf(REWARD_REASONS),
  amount: PropTypes.number.isRequired,
};

export default FreeCoinsRewardPopup;
