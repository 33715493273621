import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Group, Popover} from '@phoenix/ui';
import {
  PopperPlacement,
  PopperTrigger,
  SpacingSize,
} from '@phoenix/ui/constants';

import css from '../styles/MoneyBackPopoverLayout.css';

const MoneyBackPopoverLayout = ({descriptor, children}) => (
  <Popover
    placement={PopperPlacement.TOP}
    trigger={PopperTrigger.HOVER_CLICK}
    content={
      <Group space={SpacingSize.SHORT}>
        <Fragment>{t('paymentPage', 'text.money_back_1')}</Fragment>
        {Boolean(descriptor) && (
          <div>
            {t('paymentPage', 'text.money_back_2', {
              '{siteDescriptor}': descriptor,
            })}
          </div>
        )}
        <div>{t('paymentPage', 'text.money_back_3')}</div>
        <div>{t('paymentPage', 'text.money_back_4')}</div>
      </Group>
    }
    className={css.popover}
    trackingName="moneyBack"
  >
    <div className={css.popoverItem}>{children}</div>
  </Popover>
);

MoneyBackPopoverLayout.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  descriptor: PropTypes.string,
};

export default MoneyBackPopoverLayout;
