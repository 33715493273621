import {from} from 'rxjs';
import {pairwise, filter} from 'rxjs/operators';

import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';

import PersonalIdentityService from '../PersonalIdentityService';

let cachedStatus;

/**
 * @summary Listen account status changes
 * @description Set flag about show later popup personal identity
 *              when account status changes
 * @param {ApolloClient} client
 */
const setPopupLaterByAccountStatusChangeSubscription = (client) => {
  cachedStatus = PersonalIdentityService.status;
  from(client.watchQuery({query: PAYMENT_ACCOUNT_STATUS_QUERY}))
    .pipe(
      pairwise(),
      filter((values) => {
        const prevAccountStatus = values[0].data.payment.accountStatus;
        const currAccountStatus = values[1].data.payment.accountStatus;
        return (
          prevAccountStatus.isPaid !== currAccountStatus.isPaid ||
          prevAccountStatus.notBoughtAccountFeatures !==
            currAccountStatus.notBoughtAccountFeatures
        );
      }),
    )
    .subscribe(() => {
      const {status} = PersonalIdentityService;
      if (cachedStatus === status) return;
      PersonalIdentityService.setViewPopupLaterInStorage();
      cachedStatus = status;
    });
};

export default setPopupLaterByAccountStatusChangeSubscription;
