import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import {SIZE} from '@core/user/photo/constants';
import PHOTO_LEVEL from '@core/user/photo/constants/PhotoLevel';

import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {Bar, ButtonPrimary, Spacing} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {TextMuted, Link} from '@phoenix/typography';
import VideoPreviewLayout from '@phoenix/user/video/components/VideoPreviewLayout';
import PhotoLayout from '@phoenix/user/photo/components/PhotoLayout';
import BlurredSticker from '@phoenix/stickersSelector/components/BlurredSticker';

import {
  DESCRIPTION_TRANSLATIONS,
  LEVEL_TRANSLATIONS,
  LEVEL_TRANSLATIONS_CLEAN,
} from '../constants/translations';
import {VIDEO, STICKER, PHOTO} from '../constants/naughtyModePopupWidgetTypes';
import css from '../styles/NaughtyModeSwitchConfirmation.css';

/**
 *
 * @param {string} widgetType (@see naughtyModePopupWidgetTypes.js)
 * @param {number} level (@see NaughtyModeLevel.ts)
 * @param {boolean} isClean (@see withCleanProductRestrictions in useCleanProductRestrictions.js)
 * @return {string}
 */
const getTitleTranslation = (widgetType, level, isClean) => {
  const levelTranslations = isClean
    ? LEVEL_TRANSLATIONS_CLEAN
    : LEVEL_TRANSLATIONS;

  switch (widgetType) {
    case VIDEO:
      return `${DESCRIPTION_TRANSLATIONS[VIDEO]} ${levelTranslations[level]}`;
    case STICKER:
      return `${DESCRIPTION_TRANSLATIONS[STICKER]} ${levelTranslations[level]}`;
    default:
      return `${DESCRIPTION_TRANSLATIONS[PHOTO]} ${levelTranslations[level]}`;
  }
};

const NaughtyModeSwitchConfirmationLayout = ({
  blurLevel,
  disabledButton,
  widgetType = PHOTO,
  level,
  url,
  onLinkClick,
  onButtonClick,
  withCleanProductRestrictions,
}) => {
  const props = {
    url,
    blurLevel,
  };
  const extendedProps = {
    level,
    round: true,
    size: SIZE.SMALL,
    showButton: false,
    ...props,
  };

  return (
    <Fragment>
      <PopupNotice
        icon={
          <div className={css.photo}>
            {widgetType === STICKER && <BlurredSticker {...props} />}
            {widgetType === VIDEO && (
              <VideoPreviewLayout
                {...extendedProps}
                withCleanProductRestrictions={withCleanProductRestrictions}
              />
            )}
            {widgetType === PHOTO && (
              <PhotoLayout
                {...extendedProps}
                overlayClassName={css.transform}
                withCleanProductRestrictions={withCleanProductRestrictions}
              />
            )}
          </div>
        }
        title={getTitleTranslation(
          widgetType,
          level,
          withCleanProductRestrictions,
        )}
        description={
          <TextMuted>
            {t('popupNaughtyMode', 'text.you-need-agree-1', {
              '{restrictionLink}': (
                <Link onClick={onLinkClick} trackingName="restriction">
                  {t('popupNaughtyMode', 'text.restriction-link')}
                </Link>
              ),
            })}
          </TextMuted>
        }
        actions={[
          <ButtonPrimary
            fullWidth
            disabled={disabledButton}
            onClick={onButtonClick}
            trackingName="switch"
            data-test="switchButton"
          >
            {t('popupNaughtyMode', 'text.switch_now')}
          </ButtonPrimary>,
        ]}
      />
      <Bar withoutTop>
        <Spacing>
          <TextMuted align={Align.CENTER} small>
            {t('popupNaughtyMode', 'text.you-need-confirm', {
              '{buttonText}': t('popupNaughtyMode', 'text.switch_now'),
              '{restrictionLink}': (
                <Link onClick={onLinkClick} trackingName="restriction" small>
                  {t('popupNaughtyMode', 'text.restriction-link')}
                </Link>
              ),
            })}
          </TextMuted>
        </Spacing>
      </Bar>
    </Fragment>
  );
};

NaughtyModeSwitchConfirmationLayout.propTypes /* remove-proptypes */ = {
  blurLevel: PropTypes.oneOf(Object.values(PHOTO_LEVEL)),
  disabledButton: PropTypes.bool,
  widgetType: PropTypes.oneOf([VIDEO, STICKER, PHOTO]),
  level: PropTypes.oneOf(Object.values(PHOTO_LEVEL)).isRequired,
  onButtonClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  url: PropTypes.string.isRequired,
  withCleanProductRestrictions: PropTypes.bool,
};

export default NaughtyModeSwitchConfirmationLayout;
