import {from} from 'rxjs';
import once from 'lodash/once';
import {filter} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isPayUrl from '@core/utils/url/isPayUrl';
import addLinkNode from '@core/application/utils/addLinkNode';
import type {Client} from '@core/graphql/types';
import type {RouteQuery} from '@core/application/graphql/queries/route';
import type {ScenarioEnum} from '@core/types/graphql';

/**
 * As part of the parameters for displaying PWA promotion
 * If exists manifest it will be PWA promotion on the site in new browsers, in old browsers need to add worker (how adds
 * worker you can see in ProgressiveWebApplication.tsx) together with manifest to show PWA promotion.
 */
const startProgressiveWebApplicationListener = once(
  (client: Client, scenario: ScenarioEnum | null) => {
    from(client.watchQuery<RouteQuery>({query: ROUTE_QUERY}))
      .pipe(
        filter(({data}) => {
          return !isPayUrl(data.route.current);
        }),
      )
      .subscribe(() => {
        addLinkNode({
          id: 'pwaManifest',
          url: `/manifest.json?scenario=${scenario}`,
          rel: 'manifest',
        });
      });
  },
);

export default startProgressiveWebApplicationListener;
