import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';
import trackApplePayClick from '@core/payment/payProcess/paymentFlow/methods/trackApplePayClick';
import trackPaymentDescriptor from '@core/payment/payProcess/paymentFlow/methods/trackPaymentDescriptor';
import goToNextStep from '@core/payment/payProcess/paymentFlow/methods/goToNextStep';
import goToNextStepOnDecline from '@core/payment/payProcess/paymentFlow/methods/goToNextStepOnDecline';
import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import addSuccessOrder from '@core/payment/payProcess/paymentFlow/methods/addSuccessOrder';
import updateMicroFeaturesAfterPayment from '@core/payment/payProcess/paymentFlow/methods/updateMicroFeaturesAfterPayment';
import updateCSRFToken from '@core/payment/payProcess/paymentFlow/methods/updateCSRFToken';
import addBrowserDetails from '@core/payment/payProcess/paymentFlow/methods/addBrowserDetails';
import type {PaymentFlowMethods} from '@core/payment/payProcess/types/paymentProcess';

import getWalletPayByMethod from '@phoenix/payment/payProcess/paymentFlow/methods/getWalletPayByMethod';
import showRedirectUrlInConsoleIfNeed from '@phoenix/payment/payProcess/paymentFlow/methods/showRedirectUrlInConsoleIfNeed';

import remarketingPopupCancelSubscription from '../methods/remarketingPopupCancelSubscription';
import invalidateSearchLivecamAfterSuccess from '../methods/invalidateSearchLivecamAfterSuccess';
import updateRemarketingOfferAfterPayment from '../methods/updateRemarketingOfferAfterPayment';

/**
 * Describes flow of payment process by Apple Pay
 */
const getApplePayRemarketingPopupFlow = (): PaymentFlowMethods => {
  return [
    reportStartPayment,
    trackApplePayClick,
    addBrowserDetails,
    getWalletPayByMethod(WALLET_METHODS.APPLE_PAY),
    updateCSRFToken,
    reportEndPayment,
    showRedirectUrlInConsoleIfNeed,
    processPaymentAnswer,
    trackPaymentDescriptor,
    remarketingPopupCancelSubscription,
    invalidateSearchLivecamAfterSuccess,
    updateMicroFeaturesAfterPayment,
    updateRemarketingOfferAfterPayment,
    addSuccessOrder,
    goToNextStepOnDecline,
    goToNextStep,
  ];
};

export default getApplePayRemarketingPopupFlow;
