import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {PHOTO_SETTINGS} from '@core/mediaUpload/constants/mediaSettings';
import {IMAGES, MIXED} from '@core/mediaUpload/constants/accept';
import inAppPhotoRestriction from '@core/mediaUpload/utils/inAppPhotoRestriction';
import isPhotoUploadDisabled from '@core/mediaUpload/utils/isPhotoUploadDisabled';
import isProfileVideoUploadDisabled from '@core/mediaUpload/utils/isProfileVideoUploadDisabled';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';
import {
  FUNNEL,
  FUNNEL_PMA_NEXT_STEP,
} from '@core/mediaUpload/constants/sources';
import {getActiveSplitGroup, SPLITS} from '@core/utils/split';
import {getClientInstance} from '@core/graphql/client';

import openInAppMotivationPopup from '@phoenix/inAppMotivation/utils/openInAppMotivationPopup';
import {PAID_FUNNEL_PAGE} from '@phoenix/inAppMotivation/constants/motivationTypes';
import getThemeFeature from '@phoenix/theming/utils/getThemeFeature';
import VIRTUAL_ASSISTANT_WELCOME_MESSAGE_MUTATION from '@phoenix/virtualAssistant/graphql/mutations/virtualAssistantWelcomeMessage.gql';
import {setPhotoRewardHandler} from '@phoenix/messenger/messages/utils/photoRewardHandler';

import openMediaUploadDisabledPopup from './openMediaUploadDisabledPopup';
import PhotoUploadPopup from '../containers/PhotoUploadPopup';

let isSendWelcomeMutationSent = false;

/**
 * Open photo upload popup
 * @param {boolean} spacedFooter
 * @param {function} motivation
 * @param {string} customTitle
 * @param {function} actionPhotoReward, @see useFreeView
 * @param {Object} props Photo upload settings. @see settingsPropType
 */
const openPhotoUploadPopup = async ({
  motivation,
  spacedFooter,
  customTitle,
  actionPhotoReward = null,
  ...props
} = {}) => {
  // Check for onclick={openPhotoUploadPopup} usage
  // Prevent "Warning: This synthetic event is reused for performance reasons..." error
  const validProps = props.nativeEvent ? {} : props;
  const {paymentUrl, onClose, showCloseButton, priority, onSuccess} =
    validProps;

  const [
    inAppPhotoRestrictionResult,
    isPhotoUploadDisabledResult,
    isProfileVideoUploadDisabledResult,
    splitResult,
  ] = await Promise.all([
    inAppPhotoRestriction(),
    isPhotoUploadDisabled(),
    isProfileVideoUploadDisabled(),
    getActiveSplitGroup(
      SPLITS.OPEN_ONLY_FIRST_VIDEO_IN_PROFILE.ID,
      SPLITS.OPEN_ONLY_FIRST_VIDEO_IN_PROFILE.GROUP.ACTIVE,
    ),
  ]);

  // TODO need remove this mutation and check split in rollout in task FE-43822
  const sendMutation = (isPhotoUploaded = true) => {
    if (
      [FUNNEL, FUNNEL_PMA_NEXT_STEP].includes(validProps?.source) &&
      splitResult &&
      !isSendWelcomeMutationSent
    ) {
      isSendWelcomeMutationSent = true;

      getClientInstance().mutate({
        mutation: VIRTUAL_ASSISTANT_WELCOME_MESSAGE_MUTATION,
        variables: {isPhotoUploaded},
      });
    }
  };

  const handleClose = (data) => {
    sendMutation(false);

    onClose && onClose(data);
  };

  const handleSuccess = (data) => {
    sendMutation();

    onSuccess && onSuccess(data);
  };

  // Prevent open photoupload if we in app browser
  if (inAppPhotoRestrictionResult) {
    return;
  }

  if (isPhotoUploadDisabledResult) {
    openMediaUploadDisabledPopup({onClose});
    return;
  }

  const isSnapchat =
    getBootstrapParam(IN_APP_NAME) === InAppBrowserName.SNAPCHAT;

  if (isSnapchat) {
    openInAppMotivationPopup({
      motivationType: PAID_FUNNEL_PAGE,
      onClose,
    });

    return;
  }

  setPhotoRewardHandler(actionPhotoReward);

  const trackingConceptId = await getThemeFeature('mediaUpload', 'appearance');

  PopupService.openPopup(
    <PhotoUploadPopup
      showRequirements={!paymentUrl}
      settings={{
        ...PHOTO_SETTINGS,
        accept: isProfileVideoUploadDisabledResult ? IMAGES : MIXED,
        ...validProps,
        type: PHOTO_SETTINGS.type,
        onSuccess: handleSuccess,
        notifySuccess: FUNNEL_PMA_NEXT_STEP !== validProps?.source,
      }}
      motivation={motivation}
      spacedFooter={spacedFooter}
      customTitle={customTitle}
    />,
    {
      onClose: handleClose,
      priority,
      showCloseButton,
      trackingName: 'photoUpload',
      trackingConceptId,
      small: true,
    },
  );
};

export default openPhotoUploadPopup;
