import React from 'react';
import PropTypes from 'prop-types';

import {SpacingSize, VerticalAlign} from '@phoenix/ui/constants';
import {TextMuted, TextWithIcon} from '@phoenix/typography';

import css from '../styles/MediaDisapproveReason.css';

const MediaDisapproveReason = ({imageUrl, children, inverse}) => {
  return (
    <TextWithIcon
      space={SpacingSize.SHORT}
      verticalAlign={VerticalAlign.CENTER}
      icon={<img src={imageUrl} className={css.image} alt={children} />}
    >
      <TextMuted inverse={inverse} small className={css.text}>
        {children}
      </TextMuted>
    </TextWithIcon>
  );
};

MediaDisapproveReason.propTypes /* remove-proptypes */ = {
  imageUrl: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
};

export default MediaDisapproveReason;
