import React from 'react';

import {Row, Bar, Spacing, PlaceholderBar} from '@phoenix/ui';

const ConfirmationImportantNotePlaceholder = () => (
  <Bar>
    <Spacing>
      <Row>
        <PlaceholderBar size={100} />
      </Row>
      <Row>
        <PlaceholderBar />
        <PlaceholderBar />
        <PlaceholderBar />
      </Row>
    </Spacing>
  </Bar>
);

export default ConfirmationImportantNotePlaceholder;
