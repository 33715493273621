import {getActiveSplitGroup, SPLITS} from '@core/utils/split';

import openProgressiveWebApplicationMotivationPopup from '@phoenix/progressiveWebApplication/utils/openProgressiveWebApplicationMotivationPopup';
import openTargetCustomPopup from '@phoenix/webpush/utils/openTargetCustomPopup';

import getInteractionObservable from '../utils/getInteractionObservable';
import TRACK from '../constants/track';
import CommonLogic from './CommonLogic';
import openNativeLikePopup from '../utils/openNativeLikePopup';

/**
 * Containing all the necessary methods to deal with interactions incoming via WS
 * interactions list: WS_ACTIONS
 * @constructor
 */
export default class InteractionLogic extends CommonLogic {
  /**
   * @private
   */
  async initListeners() {
    this.isWebPushCustomPopupSplit = await getActiveSplitGroup(
      SPLITS.WEB_PUSH_CUSTOM_POPUPS.ID,
      SPLITS.WEB_PUSH_CUSTOM_POPUPS.GROUP.ACTIVE,
    );

    this.listeners.push(
      getInteractionObservable(this.options.action).subscribe(
        ({data: {recipientId}}) => {
          this.recipientId = recipientId;
          this.start();
        },
      ),
    );
  }

  /**
   * The main method to start a subscription
   * @protected
   */
  start() {
    if (this.isStartDisabled()) {
      return;
    }

    if (this.logicsMediator.isPopupOpened) {
      this.track(TRACK.ACTIONS.CONFLICTED);
      return;
    }

    /**
     * All the timeouts' data for all the logics should be purged since a certain logic is starting
     * so it means this logic has enough conditions to overlap the other ones
     */
    this.logicsMediator.purgeAllTimeoutsData();

    this.openPopup();

    this.track(TRACK.ACTIONS.PROPOSED);
  }

  /**
   * @private
   */
  openPopup() {
    /**
     * Try to show PWA motivation popup instead web push popups
     */
    if (openProgressiveWebApplicationMotivationPopup()) return;

    const popupProps = {
      popupType: this.options.popup,
      onAccept: () => this.onPopupAccept(),
      onClose: () => this.onPopupClose(),
      onDeny: () => this.onPopupDeny(),
    };

    if (this.isWebPushCustomPopupSplit) {
      openTargetCustomPopup({...popupProps, userId: this.recipientId});
      return;
    }

    openNativeLikePopup(popupProps);
  }
}
