import {getClientInstance} from '@core/graphql/client';

import buildFreeCoinsRewardQuery from '@phoenix/freeCoins/utils/buildFreeCoinsRewardQuery';
import {WEB_PUSH} from '@phoenix/freeCoins/constants/taskType';
import FREE_COINS_QUERY from '@phoenix/freeCoins/graphql/queries/freeCoins.gql';

import getInteractionObservable from '../utils/getInteractionObservable';
import TRACK from '../constants/track';
import openCoinsRewardPopup from '../utils/openCoinsRewardPopup';
import InteractionLogic from './InteractionLogic';
import getCustomEventObservable from '../utils/getCustomEventObservable';
import {CUSTOM_ACTIONS} from '../constants/actions';

/**
 * Containing all the necessary methods to deal with interactions incoming via WS for Coins
 * @constructor
 */
export default class CoinsLogic extends InteractionLogic {
  /**
   * @override
   */
  initListeners() {
    this.listeners.push(
      getInteractionObservable(this.options.action).subscribe(
        ({data: {action}}) => {
          this.updateOptions({
            action,
            popup: action,
          });
          this.start();
        },
      ),
      getCustomEventObservable().subscribe((event) => {
        this.updateOptions({
          action: event.detail.action,
          popup: TRACK.POPUP_TYPES.NATIVE,
        });
        this.startSubscription();
      }),
    );
  }

  /**
   * @override
   */
  openPopup() {
    openCoinsRewardPopup({
      onAccept: () => this.onPopupAccept(),
      onClose: () => this.onPopupClose(),
      onDeny: () => this.onPopupDeny(),
    });
  }

  /**
   * @param options
   * @private
   */
  updateOptions(options) {
    this.trackingService.setAttributes(options);
    this.options = {...this.options, ...options};
    this.logicHandler = this.getLogicHandler();
  }

  /**
   * @private
   */
  onSubscriptionAccepted() {
    if (!this.logicHandler.isPermissionGranted()) return;

    this.updateTaskData();
  }

  /**
   * @override
   */
  onSubscriptionDeny() {
    if (Object.values(CUSTOM_ACTIONS).includes(this.options.action)) {
      this.updateTaskData();

      return;
    }

    super.onSubscriptionDeny();
  }

  /**
   * @private
   */
  updateTaskData() {
    const client = getClientInstance();

    const freeCoinsData = client.cache.readQuery({
      query: FREE_COINS_QUERY,
    });

    if (freeCoinsData) {
      client.cache.updateQuery(
        {
          query: buildFreeCoinsRewardQuery(WEB_PUSH),
        },
        (cachedData) => ({
          credits: {
            ...cachedData.credits,
            rewardForTasks: {
              ...cachedData.credits.rewardForTasks,
              tasks: {
                ...cachedData.credits.rewardForTasks.tasks,
                webPush: {
                  ...cachedData.credits.rewardForTasks.tasks.webPush,
                  reward: null,
                  completed: true,
                },
              },
            },
          },
        }),
      );
    }
  }
}
