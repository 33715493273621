import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import RemarketingBannerService from '../RemarketingBannerService';
import Banner from '../components/Banner';
import getBannerSizeVar, {getBannerKey} from '../utils/getBannerSizeVar';

/**
 * Container to display banner.
 * It's used to notify RemarketingBannerService about banner mount/unmount.
 */
const RemarketingBannerContent = ({data, wrapper, ...props}) => {
  const [bannerData, setBannerData] = useState({});

  useEffect(() => {
    const subscription = RemarketingBannerService.listenBannerEvent(
      data.divId,
      (info) => {
        setBannerData(info);
        /**
         * Used for rm banners loaders while navigation by pages
         * without loaders scroll restoration works incorrectly
         * (we remember scroll position with banners, but when we restore scroll position banners are not loaded yet, e.g. on search page)
         * we save banner size by placement, and later based on that will show loader for it
         * after loading banner will use fresh fetched size (and store updated loader size for next loading)
         * @see RemarketingBanner.js
         */
        getBannerSizeVar(getBannerKey(data.zoneId))({
          height: info?.height,
          width: info?.width,
        });
      },
    );

    return () => {
      subscription.unsubscribe();
    };
    // Don't delete "data.ifrSrc", it is needed to respond to changes in the iframe
  }, [data.divId, data.zoneId, data.ifrSrc]);

  return (
    <Banner
      wrapper={wrapper}
      data={{...data, ...bannerData}}
      iframeReady={bannerData.iframeReady}
      {...props}
    />
  );
};

RemarketingBannerContent.propTypes /* remove-proptypes */ = {
  data: PropTypes.shape({
    ifrSrc: PropTypes.string.isRequired,
    divId: PropTypes.string.isRequired,
    zoneId: PropTypes.string.isRequired,
  }).isRequired,
  wrapper: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.any),
  ]),
};

export default RemarketingBannerContent;
