import updateBlockUserHeMeInCache from '@core/user/actions/utils/updateBlockUserHeMeInCache';
import INTERACTION_BLOCK_USER_SUBSCRIPTION from '@core/user/profile/target/graphql/subscriptions/interactionBlockUser.gql';
import INTERACTION_UNBLOCK_USER_SUBSCRIPTION from '@core/user/profile/target/graphql/subscriptions/interactionUnBlockUser.gql';

import removeUserFromLikeGallery from '@phoenix/likeGallery/utils/removeUserFromLikeGallery';

let startedListeners = false;

/**
 * Starts subscriptions for any blockUser change
 * @param {object} client
 */
const startBlockUserListeners = async (client) => {
  if (!startedListeners) {
    /**
     * Set in false flag that current user is blocked by recipient
     */
    client
      .subscribe({query: INTERACTION_UNBLOCK_USER_SUBSCRIPTION})
      .subscribe(({data: {userId}}) => {
        updateBlockUserHeMeInCache(userId, false);
      });

    /**
     * Set in true flag that current user is blocked by recipient
     */
    client
      .subscribe({query: INTERACTION_BLOCK_USER_SUBSCRIPTION})
      .subscribe(({data: {userId}}) => {
        updateBlockUserHeMeInCache(userId, true);
        removeUserFromLikeGallery(userId);
      });
  }

  startedListeners = true;
};

export default startBlockUserListeners;
