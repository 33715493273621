import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import type {CommonNotification} from '@core/systemNotifications/types';

import type {StoryFragment} from '@phoenix/stories/page/graphql/fragments/story';
import STORY_FRAGMENT from '@phoenix/stories/page/graphql/fragments/story.gql';
import type {DeclinedGroupVideoSubscription} from '@phoenix/stories/graphql/subscriptions/declinedGroupVideo';
import {getDeclinedGroupVideoSubscription} from '@phoenix/stories/utils/startStoriesListeners';

type StoriesDeclineGroupVideoNotification = CommonNotification & {
  error?: string;
};

const storiesDeclineGroupVideoDataSource =
  (): Observable<StoriesDeclineGroupVideoNotification> =>
    from<{
      data: DeclinedGroupVideoSubscription;
    }>(getDeclinedGroupVideoSubscription()).pipe(
      filter(
        ({
          data: {
            declineVideoGroup: {userId, group},
          },
        }) => {
          const story = getClientInstance().readFragment<StoryFragment>({
            id: `StoryItem:${userId}`,
            fragmentName: 'Story',
            fragment: STORY_FRAGMENT,
          });

          // Prevent to set notification if group was removed in the previous event
          return story.parts.some((part) => part.group === group);
        },
      ),
      map(({data: {declineVideoGroup}}) => {
        if (declineVideoGroup.error) {
          return {
            type: NOTIFICATION_TYPES.DECLINE_VIDEO_GROUP,
            error: declineVideoGroup.error,
          };
        }

        return {type: NOTIFICATION_TYPES.DISAPPROVE_VIDEO};
      }),
    );

export default storiesDeclineGroupVideoDataSource;
