/**
 * Millisecond to seconds conversion factor
 * (the time from the server is obtained in seconds)
 */
export const CONVERSION_FACTOR = 1000;

// export for tests
export const MOCK_TIME_LEFT = 11198;

/**
 * Get expiration time
 * @param expiredAt {number} timestamp in seconds
 * @returns {number} expiration time in seconds
 */
const getTimeLeft = (expiredAt) => {
  const timeLeft = window.IS_INTEGRATION_TEST_ENVIRONMENT
    ? MOCK_TIME_LEFT
    : Math.round(expiredAt - Date.now() / CONVERSION_FACTOR);
  return timeLeft > 0 ? timeLeft : null;
};

export default getTimeLeft;
