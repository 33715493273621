import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyContactUs = lazy(
  () =>
    import(
      /* webpackChunkName: 'contact' */
      '@phoenix/support/contact/containers/ContactPage'
    ),
);

export const ContactUsChunk = (props) => (
  <SplitChunk component={LazyContactUs} {...props} />
);

const LazyFeedback = lazy(
  () =>
    import(
      /* webpackChunkName: 'feedback' */
      '@phoenix/support/feedback/containers/Feedback'
    ),
);

export const FeedbackChunk = (props) => (
  <SplitChunk component={LazyFeedback} {...props} />
);
