import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useApolloClient} from '@apollo/client';
import {object, string} from 'yup';
import {Formik} from 'formik';

import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';
import PopupService from '@core/popup/utils/PopupService';
import t from '@core/translations/translate';
import logger from '@core/logger';

import PROFILE_MENU_QUERY from '@phoenix/user/profile/menu/graphql/queries/profileMenu.gql';

import TrustedVerificationSmsSecondStepLayout from '../components/TrustedVerificationSmsSecondStepLayout';
import VERIFY_SMS_CODE_MUTATION from '../graphql/mutations/verifySmsCode.gql';
import {SECOND_STEP_FIELD_NAMES} from '../contants/fields';

/**
 * @const {String}
 */
const {CODE} = SECOND_STEP_FIELD_NAMES;

/**
 * @returns {Object}
 */
const getValidationSchema = () =>
  object().shape({
    [CODE]: string()
      .required(t('paymentTrusted', 'text.enter.verification.code'))
      .matches(/^\d+$/, t('paymentTrusted', 'text.sms.step2.error')),
  });

/**
 * The second step of SMS verification
 * At this step, we can step by means of SMS, which will be sent to the selected number or return to
 * the previous step to change the phone number
 */
const TrustedVerificationSmsSecondStep = ({
  referenceId,
  onError,
  smsInfo,
  actions,
}) => {
  const history = useHistory();
  const client = useApolloClient();

  const onSubmit = useCallback(
    async (payload, {setSubmitting, setErrors}) => {
      const variables = {
        [CODE]: payload[CODE],
        referenceId,
        setFullSafeMode: true,
      };
      let response;
      try {
        response = await client.mutate({
          mutation: VERIFY_SMS_CODE_MUTATION,
          variables,
        });
      } catch (e) {
        onError();
        logger.sendError(
          `[TrustedVerificationSmsSecondStep] Submitting error for payload: "${JSON.stringify(
            variables,
          )}".`,
          e,
        );
        return;
      }
      const errors = normalizeMutationErrors(
        response.data.trusted.verifySmsCode.errors,
      );

      // Remove loader
      setSubmitting(false);

      if (errors) {
        /**
         * Set possible field errors inside Formik. Due to the fact
         * that form is small we can set 'general' errors under the field too.
         */

        if (errors.general) {
          onError();
          return;
        }

        setErrors({
          [CODE]: t('paymentTrusted', 'text.sms.step2.error.code'),
        });
        return;
      }
      try {
        await client.query({
          query: PROFILE_MENU_QUERY,
          fetchPolicy: 'network-only',
        });
      } finally {
        history.goBack();
        PopupService.closePopup();
      }
    },
    [referenceId, client, onError, history],
  );

  return (
    <Formik
      initialValues={{
        [CODE]: '',
      }}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {({handleSubmit, isSubmitting}) => (
        <TrustedVerificationSmsSecondStepLayout
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          actions={actions}
          smsInfo={smsInfo}
        />
      )}
    </Formik>
  );
};

TrustedVerificationSmsSecondStep.propTypes /* remove-proptypes */ = {
  referenceId: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  actions: PropTypes.node,
  smsInfo: PropTypes.node,
};

export default TrustedVerificationSmsSecondStep;
