import type {FC} from 'react';
import React from 'react';

import type {ThemedPopupProps} from '../utils/useThemedPopup';
import useThemedPopup from '../utils/useThemedPopup';

/**
 * Appearance-changeable popup based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedPopup: FC<ThemedPopupProps> = (props) => {
  const {loading, data: Popup} = useThemedPopup();

  if (loading) {
    return null;
  }

  return <Popup {...props} />;
};

export default ThemedPopup;
