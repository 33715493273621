import {useEffect} from 'react';

import getTheme from '@core/application/utils/getTheme';
import ColorScheme from '@core/theming/constants/ColorScheme';

import css from '../styles/ThemeColorScheme.css';

/**
 * Based on theme "color scheme" attach required classname
 * that will re-assign different CSS3 variables based on flag
 * and return color scheme as it is.
 *
 * @see ThemeColorScheme.css
 * @see src/skins/base/index.js
 */
const useThemeColorScheme = (): ColorScheme => {
  const {colorScheme} = getTheme();

  useEffect(() => {
    const {classList} = document.body;

    if (
      Object.values(ColorScheme).includes(colorScheme) &&
      !classList.contains(css[colorScheme])
    ) {
      classList.add(css[colorScheme]);
    }
  }, [colorScheme]);

  return colorScheme;
};

export default useThemeColorScheme;
