import type {FC, MouseEvent} from 'react';
import React, {Fragment, useCallback} from 'react';

import t from '@core/translations/translate';
import {sessionStorage} from '@core/utils/storage';

import {Link} from '@phoenix/typography';
import openInAppMotivationPopup from '@phoenix/inAppMotivation/utils/openInAppMotivationPopup';
import {DEFAULT} from '@phoenix/inAppMotivation/constants/motivationTypes';

import type {SystemNotificationProps} from '../types';
import {ALLOWED_NOTIFICATION_STORAGE_KEY} from '../constants/allowedNotificationStorageKey';
import SystemNotificationLayout from '../components/SystemNotificationLayout';

const SystemNotificationBrowserChange: FC<SystemNotificationProps> = ({
  onClose,
  onClick,
  ...props
}) => {
  const handleClick = useCallback(() => {
    onClick?.();
    openInAppMotivationPopup({
      motivationType: DEFAULT,
    });
  }, [onClick]);

  const handleClose = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onClose && onClose();
      sessionStorage.setItem(ALLOWED_NOTIFICATION_STORAGE_KEY, false);
    },
    [onClose],
  );

  return (
    <SystemNotificationLayout
      onClose={handleClose}
      text={(inverse) => (
        <Fragment>
          {`${t('headerNotification', 'text.any_trouble')} `}
          <Link inverse={inverse}>
            {t('headerNotification', 'text.change_browser')}
          </Link>
        </Fragment>
      )}
      iconType="world"
      type="highlighted"
      {...props}
      onClick={handleClick}
    />
  );
};

export default SystemNotificationBrowserChange;
