/**
 * Sizes for gifts
 */
export default {
  // Gifts will have fixed size. (@see Gift.css)
  FIXED_120: 'fixed120',
  FIXED_40: 'fixed40',
  FIXED_200: 'fixed200',

  // Gifts will have fluid size, calculated in percent
  FLUID: 'fluid',
};
