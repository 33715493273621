import type {FC, ReactNode} from 'react';
import React from 'react';

import PhotoSize from '@core/user/photo/constants/PhotoSize';
import t from '@core/translations/translate';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {IconSize, OverlaySaturation, SpacingSize} from '@phoenix/ui/constants';
import {ButtonPrimary, Icon, Overlay, RowShort, Spacing} from '@phoenix/ui';
import {Text, TextMuted} from '@phoenix/typography';

import css from '../styles/PmaRequestPhotoOverlayLayout.css';

type PmaRequestPhotoOverlayLayoutProps = {
  size: PhotoSize;
  iconSize?: IconSize;
  canShowRequestLayout: boolean;
  showButton: boolean;
  showRequestLimitText: boolean;
  showIcon: boolean;
  showDescription: boolean;
  showVerifyAgeDescription: boolean;
  buttonText: ReactNode;
};

const PmaRequestPhotoOverlayLayout: FC<PmaRequestPhotoOverlayLayoutProps> = ({
  size,
  iconSize,
  canShowRequestLayout,
  showButton,
  showRequestLimitText,
  showIcon,
  showDescription,
  showVerifyAgeDescription,
  buttonText,
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <Overlay saturation={OverlaySaturation.LITE}>
      <Spacing
        className={css.wrap}
        size={
          size === PhotoSize.SMALL &&
          !isPhone &&
          !(showButton && showRequestLimitText)
            ? SpacingSize.SHORT
            : SpacingSize.NORMAL
        }
      >
        <div className={css.content}>
          {showIcon && (
            <RowShort flexibleWidth={false}>
              <Icon
                size={iconSize || IconSize.SIZE_36}
                inverse
                type={canShowRequestLayout ? 'check' : 'lock'}
              />
            </RowShort>
          )}
          {showDescription && (
            <Text inverse data-test="overlayDescription">
              {canShowRequestLayout
                ? t('privatePhoto', 'title.request_sent')
                : t('privatePhoto', 'title.private_photo')}
            </Text>
          )}
          {showVerifyAgeDescription && (
            <RowShort flexibleWidth={false}>
              <TextMuted inverse small data-test="overlayVerifyAgeText">
                {t('privatePhoto', 'text.should_verify_your_age')}
              </TextMuted>
            </RowShort>
          )}
        </div>
        {showButton && !showRequestLimitText && (
          <ButtonPrimary
            className={css.action}
            fullWidth
            data-test="overlayButton"
          >
            {buttonText}
          </ButtonPrimary>
        )}
        {showButton && showRequestLimitText && (
          <Text
            inverse
            small
            type={Text.TYPE.MUTED}
            data-test="overlayRequestLimitText"
          >
            {t('privatePhoto', 'text.you_have_reached_request_limit')}
          </Text>
        )}
      </Spacing>
    </Overlay>
  );
};

export default PmaRequestPhotoOverlayLayout;
