import type {FC} from 'react';
import React, {Fragment} from 'react';

import t from '@core/translations/translate';

import {Link} from '@phoenix/typography';

import SystemNotificationLayout from '../components/SystemNotificationLayout';
import type {SystemNotificationLayoutProps} from '../types';

const handleReloadClick = () => {
  window.location.reload();
};

type SystemNotificationLostConnectionProps = Omit<
  SystemNotificationLayoutProps,
  'text' | 'iconType' | 'type'
>;

const SystemNotificationLostConnection: FC<
  SystemNotificationLostConnectionProps
> = (props) => {
  return (
    <SystemNotificationLayout
      text={(inverse) => (
        <Fragment>
          {`${t('notificationAlert', 'text.disconnect')} `}
          <Link
            onClick={handleReloadClick}
            trackingName="reloadPage"
            inverse={inverse}
          >
            {t('notificationAlert', 'text.reload_page')}
          </Link>
        </Fragment>
      )}
      iconType="reload"
      type="highlighted"
      {...props}
    />
  );
};

export default SystemNotificationLostConnection;
