import EMPTY_ROUTES_FOR_REDIRECT from '@core/application/constants/emptyRoutesForRedirect';
import FULLSCREEN_ROUTES from '@core/application/constants/fullscreenRoutes';
import APP_ROUTES from '@core/application/constants/routes';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

import ROUTES from '@phoenix/application/constants/routes';

import {COREG_ZONE} from './zones';

const {PAY, FORBIDDEN} = APP_ROUTES;
const {ROOMS, TRUSTED, REELS} = ROUTES;

/**
 * Used for filtering routes where popup is not allowed
 * @see CoregistrationPopupDisplayer.js
 */
export const DISABLED_ROUTES = [
  PAY,
  ROOMS,
  TRUSTED,
  FORBIDDEN,
  REELS,
  '/notFound',
  '/staticPage',
  ...EMPTY_ROUTES_FOR_REDIRECT,
  ...ROUTES_WITH_POPUP,
  ...FULLSCREEN_ROUTES,
];

/**
 * Cookie name used to track users view count
 * @see useCoregistrationSearchPopupZone.js
 */

export const SEARCH_POPUP_VIEWS_COUNT_COOKIE_NAME =
  'coregistrationСountViewUser';

/**
 * Max users view count before opening popup
 * @see useCoregistrationSearchPopupZone.js
 */
export const MAX_VIEWS_COUNT = 2;

const STANDARD_CONCEPT_TYPES = {
  DEFAULT: 'default',
  SLIDER_1: 'slider1',
  SLIDER_2: 'slider2',
  SLIDER_3: 'slider3',
  SLIDER_4: 'slider4',
};

export const YOUNG_CONCEPTS = {
  YOUNGS_1: 'rm_youngs_1',
  YOUNGS_2: 'rm_youngs_2',
  YOUNGS_3: 'rm_youngs_3',
  YOUNGS_4: 'rm_youngs_4',
  YOUNGS_5: 'rm_youngs_5',
  YOUNGS_6: 'rm_youngs_6',
  YOUNGS_7: 'rm_youngs_7',
  YOUNGS_8: 'rm_youngs_8',
};

/**
 * List of concepts where close button
 * is inverse by design purpose
 * @see CoregistrationPopupDisplayer.js
 */
export const INVERSE_CLOSE_BUTTON = [
  'rm_youngs_1',
  'rm_youngs_2',
  'rm_youngs_4',
  'rm_youngs_5',
  'rm_youngs_6',
  'rm_youngs_7',
  'rm_youngs_8',
];

/**
 * Popup with popup default spacing above content
 * @see useCoregistrationPopup.js
 */
export const SPACED_ABOVE_CONCEPT = 'rm_youngs_2';

/**
 *  List of all design concept
 * @see useCoregistrationPopup.js
 */
export const CONCEPT_TYPES = {
  ...STANDARD_CONCEPT_TYPES,
  ...YOUNG_CONCEPTS,
};

/**
 * List of design concept for integration test
 * without duplicated design popups
 * @see useCoregistrationPopup.js
 */
export const CONCEPT_TYPES_TEST = {
  ...STANDARD_CONCEPT_TYPES,
  YOUNGS_1: 'rm_youngs_1',
  YOUNGS_2: 'rm_youngs_2',
  YOUNGS_3: 'rm_youngs_3',
  YOUNGS_4: 'rm_youngs_4',
  YOUNGS_7: 'rm_youngs_7',
  YOUNGS_8: 'rm_youngs_8',
};

/**
 * Used to set expiring flag
 * @see useCoregistrationPopup.js
 * @type {number}
 */
export const EXPIRED_INTERVAL = 86400000; // 24h in ms

/**
 * Used to read expiring flag
 * @see useCoregistrationPopup.js
 */
export const STORAGE_EXPIRED_KEYS = {
  [COREG_ZONE.SEARCH_POPUP]: 'coregistrationPopupExpired',
};
