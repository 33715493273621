import t from '@core/translations/translate';

import {IconType} from '@phoenix/ui/constants';
import animationCss from '@phoenix/widgets/animation/animations.css';

import FreeFeaturePopupTypes from '../constants/freeFeaturePopupTypes';

/**
 * @param {Object} params
 * @param {String} params.view
 * @param {String} params.like
 * @param {String} params.message
 * @param {Number} params.price
 * @param {String} params.gender
 */
const priorityManUpsellConfig = ({view, like, message, price, gender}) => ({
  type: FreeFeaturePopupTypes.STATISTIC,
  animationClass: animationCss.bounce,
  title:
    gender === 'male'
      ? t('upsellPopup', 'priorityMan.title')
      : t('upsellPopup', 'searchQueen.title'),
  description: t('upsellPopup', 'upsell.text'),
  bottomNotice: t('microfeatures', 'text.feature_costs', {
    '{price}': price,
    '{n}': 1,
  }),
  actionText:
    gender === 'male'
      ? t('upsellPopup', 'priorityMan.button')
      : t('upsellPopup', 'searchQueen.button'),
  icon: 'crown',
  iconType: IconType.PRIMARY,
  benefits: [
    {
      icon: 'eye',
      type: IconType.PRIMARY,
      title: view,
      text: t('microfeatures', 'text.views'),
    },
    {
      icon: 'heart',
      type: IconType.DANGER,
      title: like,
      text: t('microfeatures', 'text.likes'),
    },
    {
      icon: 'mail',
      type: IconType.ACCENT,
      title: message,
      text: t('microfeatures', 'text.messages'),
    },
  ],
});

export default priorityManUpsellConfig;
