import React from 'react';
import PropTypes from 'prop-types';

import SearchWidgetSpacing from '@phoenix/search/widget/components/SearchWidgetSpacing';

import css from '../styles/SearchWrapper.css';

/**
 * Be aware that such banner wrapper DOESN'T scale the "iframe"
 * so you should definitely use it with large search widgets.
 */
const SearchWrapper = ({children}) => {
  return (
    <SearchWidgetSpacing size={SearchWidgetSpacing.SIZE.FIXED_FULL}>
      <div className={css.wrapper} data-test="searchWidgetWrapper">
        {children}
      </div>
    </SearchWidgetSpacing>
  );
};

SearchWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
};

export default SearchWrapper;
