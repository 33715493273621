import React from 'react';
import PropTypes from 'prop-types';

import {createTranslationsMap} from '@core/translations';

import useMediaMotivation from '@phoenix/webpush/utils/useMediaMotivation';

import PopupWithUserPhotosLayout from '../components/PopupWithUserPhotosLayout';
import PopupWithUserPhotosPlaceholder from '../components/PopupWithUserPhotosPlaceholder';
import ACTIONS from '../constants/actions';

const TRANSLATIONS = createTranslationsMap((t) => ({
  [ACTIONS.CHAT]: t('webPushNotification', 'text.informed_chat'),
  [ACTIONS.LIKE]: t('webPushNotification', 'text.informed_likes'),
}));

const PopupWithUserPhotos = ({onAccept, onDeny, userId, textType}) => {
  const {media, loading} = useMediaMotivation({
    userId,
    withOnlineStatus: true,
  });

  if (loading) {
    return <PopupWithUserPhotosPlaceholder withOnePhoto={Boolean(userId)} />;
  }

  return (
    <PopupWithUserPhotosLayout
      media={media}
      onDeny={onDeny}
      onAccept={onAccept}
      text={TRANSLATIONS[textType]}
      withOnePhoto={Boolean(userId)}
    />
  );
};

PopupWithUserPhotos.propTypes /* remove-proptypes */ = {
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
  textType: PropTypes.string.isRequired,
  userId: PropTypes.string,
};

export default PopupWithUserPhotos;
