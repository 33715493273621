import React from 'react';

import Field from '@core/form/AbstractFormField';

import {Input} from '@phoenix/ui';

/**
 * Input field implementation over abstract field
 */
const FormInput = (props) => <Field component={Input} {...props} />;

export default FormInput;
