import {useQuery} from '@apollo/client';

import PAYMENT_CLICKABLE_ELEMENTS_QUERY from '@core/payment/common/graphql/queries/paymentClickableElements.gql';
import useIsMobFirstStage from '@core/payment/common/utils/useIsMobFirstStage';

import useIsMobOneStep from './useIsMobOneStep';

const useIsClickableElements = () => {
  const {isOneStep, loading} = useIsMobOneStep();
  const isMobFirstStage = useIsMobFirstStage();

  const {
    data,
    loading: clickableElementLoading,
    error,
  } = useQuery(PAYMENT_CLICKABLE_ELEMENTS_QUERY, {
    skip: isOneStep,
  });

  if (
    loading ||
    clickableElementLoading ||
    error ||
    isOneStep ||
    !isMobFirstStage
  ) {
    return {
      loading,
      isClickablePackages: false,
      // Clickable elements: motivation, logos and payment tabs
      isClickableElements: false,
    };
  }

  const templateSettings = data?.payment?.templateSettings;

  return {
    loading,
    isClickablePackages: templateSettings?.isClickablePackages,
    isClickableElements: templateSettings?.isClickableElements,
  };
};

export default useIsClickableElements;
