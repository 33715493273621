import getCookie from '@core/utils/cookie/getCookie';

import {restorePreviousSearchLimit} from '@phoenix/search/list/utils/updateSearchLimit';

/**
 * Reset search limit to default count after the expiration of the extended limit after the implementation
 * of the advanced search pedal
 */
const expireAction = () => {
  /**
   * Added since it's impossible to test delayed events logic on development environment.
   * It's because backend doesn't support pending messages. All messages instantly arrive.
   */
  const debugDelay = getCookie('expandSearchLimitsExpireDelay');

  if (!debugDelay) {
    restorePreviousSearchLimit();
    return;
  }

  setTimeout(() => {
    restorePreviousSearchLimit();
  }, debugDelay);
};

export default expireAction;
