import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import type {CommonNotification} from '@core/systemNotifications/types';

import type {StoriesDeclineSubscription} from '@phoenix/stories/graphql/subscriptions/storiesDecline';
import {getStoriesDeclineSubscription} from '@phoenix/stories/utils/startStoriesListeners';

const storiesDisapproveDataSource = (): Observable<CommonNotification> =>
  from<{data: StoriesDeclineSubscription}>(
    getStoriesDeclineSubscription(),
  ).pipe(
    filter(({data}) => Boolean(data.storyPart.id)),
    map(({data}) => ({
      type: data.storyPart.isVideo
        ? NOTIFICATION_TYPES.DISAPPROVE_VIDEO
        : NOTIFICATION_TYPES.DISAPPROVE_PHOTO,
    })),
  );

export default storiesDisapproveDataSource;
