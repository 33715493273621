import RecipientWidgetAppearance from '@core/theming/constants/features/RecipientWidgetAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43644 FE-43642
 */
type RecipientWidgetComponent = any;

const resolver: ModuleResolver<
  RecipientWidgetAppearance,
  RecipientWidgetComponent
> = (value) => {
  switch (value) {
    case RecipientWidgetAppearance.WITH_REPLY_LABEL:
      return import(
        /* webpackChunkName: 'recipientWidgetWithReplyIcon' */ '@phoenix/messenger/common/constants/recipientWidgetWithReplyLabel'
      );
    case RecipientWidgetAppearance.REGULAR_WIDGET:
      return import(
        /* webpackChunkName: 'recipientWidgetRegular' */ '@phoenix/messenger/common/constants/recipientWidgetRegular'
      );
    case RecipientWidgetAppearance.MINIMIZED_WITH_OUTSIDE_ONLINE:
      return import(
        /* webpackChunkName: 'recipientWidgetMinimizedWithOutsideOnline' */ '@phoenix/messenger/common/constants/recipientWidgetMinimizedWithOutsideOnline'
      );
    case RecipientWidgetAppearance.WITH_MESSAGE_PREVIEW:
      return import(
        /* webpackChunkName: 'recipientWidgetWithMessagePreview' */ '@phoenix/messenger/common/constants/recipientWidgetWithMessagePreview'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable recipient widget based on theme.
 * Returns object of composite components that are used for theming.
 */
const useThemedRecipientWidget = () =>
  useThemeCompositeFeatureLoader<
    RecipientWidgetAppearance,
    RecipientWidgetComponent
  >('messenger', 'recipientWidget', resolver);

export default useThemedRecipientWidget;
