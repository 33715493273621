/**
 * @const {String}
 */
export const POPUNDER_COOKIE = 'popunder';

/**
 * @const {String}
 * Cookie for visual test of menu item "More dates" to avoid difficult API setups
 */
export const MORE_DATES_MENU_ITEM_POPUNDER_COOKIE = 'moreDatesMenuItemPopunder';

/**
 * @const {String}
 */
export const BACK_OFFER_POPUNDER_COOKIE = 'backOfferPopunder';

/**
 * @const {Number} We should avoid showing pop-under more often than once a day.
 */
export const EXPIRE_COOKIE_DAY = 86400; // 60 * 60 * 24

/**
 * @const {Number} We should avoid showing pop-under more often than once a year.
 */
export const EXPIRE_COOKIE_YEAR = 31536000; // 60 * 60 * 24 * 365
