export const DEFAULT = 'default';
export const USER = 'user';
export const CHAT = 'chat';
export const PAYMENT_PAGE = 'paymentPage';
export const PAID_FUNNEL_PAGE = 'paidFunnel';
export const VIDEO_CALL = 'videoCall';

export default {
  DEFAULT,
  USER,
  CHAT,
  PAYMENT_PAGE,
  PAID_FUNNEL_PAGE,
  VIDEO_CALL,
};
