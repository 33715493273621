import {from, timer, combineLatest} from 'rxjs';
import {delayWhen, filter, map, tap} from 'rxjs/operators';

import {watchLocalStorage, writeToLocalStorage} from '@core/utils/storage';

import STORAGE_KEY from '../constants/storageKey';
import FREE_DAILY_SPIN_AVAILABLE_QUERY from '../graphql/queries/freeDailySpinAvailable.gql';
import FREE_DAILY_SPIN_SENSITIVE_DATA_QUERY from '../graphql/queries/freeDailySpinSensitiveData.gql';

let startedListener = false;

/**
 * @param {*} value
 */
const updateStorage = (value) => writeToLocalStorage(STORAGE_KEY, value);

/**
 * Subscription for invalidating free daily spin timeouts.
 * We just watch possible sensitive data, that can fill query,
 * wait for delay ("nextGameTimeout" field) and refetch such query
 * for retrieving actual data.
 *
 * @see SubscriptionsStarter.js
 */
const startFreeDailySpinListener = async (client) => {
  // Avoiding multiple resubscribes in case of unpredictable cache updates.
  if (startedListener) {
    return;
  }

  startedListener = true;

  // Get info about availability for avoiding unneeded subscription.
  const {data: availableData} = await client.query({
    query: FREE_DAILY_SPIN_AVAILABLE_QUERY,
  });

  if (!availableData.spin.available) {
    return;
  }

  const data$ = client.watchQuery({
    query: FREE_DAILY_SPIN_SENSITIVE_DATA_QUERY,
  });

  const spinSensitiveData$ = from(data$).pipe(
    map(({data}) => data.spin.nextGameTimeout),
  );

  const storage$ = watchLocalStorage(STORAGE_KEY);

  combineLatest([spinSensitiveData$, storage$])
    .pipe(
      tap(([timeout, expireDate]) => {
        const now = Date.now();
        if ((!timeout && expireDate) || expireDate < now) {
          updateStorage(null);
        }

        if (timeout && !expireDate) {
          updateStorage(now + timeout * 1000);
        }
      }),
      map(([timeout, expireDate]) => {
        const now = Date.now();
        /**
         * If there is no timeout but we have some expiration it means
         * that previous timeout was not yet expired and we should wait.
         */
        if (!timeout && expireDate > now) {
          return [Math.round((expireDate - now) / 1000), expireDate];
        }

        return [timeout, expireDate];
      }),
      filter(([timeout]) => timeout),
      // Since timeout arrives in seconds.
      delayWhen(([timeout]) => timer(timeout * 1000)),
    )
    .subscribe(() => {
      data$.refetch();
    });
};

export default startFreeDailySpinListener;
