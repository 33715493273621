import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PLACES from '@core/user/actions/constants/Place';
import GalleryActionsAppearance from '@core/theming/constants/features/GalleryActionsAppearance';

import {Spacing} from '@phoenix/ui';
import {SpacingSize, SpacingDirection} from '@phoenix/ui/constants';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import ACTIONS from '@phoenix/user/actions/constants/actions';
import useThemedUserActions from '@phoenix/theming/utils/useThemedUserActions';

import css from '../styles/TargetBottomFixedUserActionsLayout.css';

const DEFAULT_ACTIONS_TYPES = {};

const TargetBottomFixedUserActionsLayout = ({
  userId,
  actions,
  actionsTypes = DEFAULT_ACTIONS_TYPES,
  place,
  spacingSize,
  spacingDirection,
  fullWidthSingleAction,
  fullSize,
  ...props
}) => {
  const isTargetUserGallery =
    place === PLACES.TARGET_USER_INFO_GALLERY_FLOATING;

  const {data: appearance} = useThemeFeature('gallery', 'appearance');

  const {
    data: {[place]: Actions},
    loading,
  } = useThemedUserActions();

  const size = useMemo(() => {
    if (spacingSize === false) {
      return SpacingSize.NONE;
    }
    if (spacingSize) {
      return spacingSize;
    }

    const isRoundActions = [
      GalleryActionsAppearance.WITH_ROUND_ACTIONS,
      GalleryActionsAppearance.WITH_ROUND_REVERSED_ACTIONS,
    ].includes(appearance);

    return isRoundActions ? SpacingSize.NORMAL : SpacingSize.SHORTEST;
  }, [spacingSize, appearance]);

  if (loading) {
    return null;
  }

  const singleAction = actions.length === 1;

  return (
    <Spacing
      className={cn(
        css.wrapper,
        singleAction && fullWidthSingleAction && css.fullWidth,
        singleAction &&
          Boolean(size) &&
          !isTargetUserGallery &&
          css.sideSpacing,
      )}
      stretch={false}
      direction={
        spacingDirection || (singleAction && !isTargetUserGallery)
          ? SpacingDirection.BOTH
          : SpacingDirection.VERTICAL
      }
      size={size}
    >
      <Actions
        {...props}
        userId={userId}
        actions={actions}
        place={place}
        groupClassName={cn(css.container, fullSize && css.fullSize)}
        actionClassName={cn(!singleAction && css.action)}
        actionsTypes={actionsTypes}
      />
    </Spacing>
  );
};

TargetBottomFixedUserActionsLayout.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(ACTIONS)))
    .isRequired,
  actionsTypes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  place: PropTypes.oneOf(Object.values(PLACES)),
  fullWidthSingleAction: PropTypes.bool,
  fullSize: PropTypes.bool,
  spacingSize: PropTypes.oneOf(Object.values(SpacingSize)),
  spacingDirection: PropTypes.oneOf(Object.values(SpacingDirection)),
};

export default TargetBottomFixedUserActionsLayout;
