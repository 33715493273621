import React from 'react';
import PropTypes from 'prop-types';

import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';

import {IconSubstrate} from '@phoenix/ui';
import Photo from '@phoenix/user/photo/containers/Photo';

import css from '../styles/HeaderWithImages.css';

const HeaderWithImages = ({users}) => {
  return (
    <div className={css.wrap}>
      <div className={css.avatars}>
        {users.map(({profile}) => (
          <Photo
            key={`photo${profile.id}`}
            className={css.photo}
            photo={getPrimaryPhotoData(profile)}
            lazy={false}
            size={Photo.SIZE.SMALL}
            withNaughtyMode={false}
            round
          />
        ))}
      </div>
      <IconSubstrate
        className={css.iconWrap}
        iconClassName={css.icon}
        icon="send"
        inline
        round
      />
    </div>
  );
};

HeaderWithImages.propTypes /* remove-proptypes */ = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.shape({
        gender: PropTypes.string.isRequired,
        photos: PropTypes.shape({
          primaryPhoto: PropTypes.shape({
            id: PropTypes.string,
            censored: PropTypes.bool,
            updatedOn: PropTypes.string,
            level: PropTypes.number,
            pendingDelete: PropTypes.bool,
          }),
        }),
        statuses: PropTypes.shape({
          isOnline: PropTypes.bool,
        }),
      }),
    }),
  ).isRequired,
};

export default HeaderWithImages;
