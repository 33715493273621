import React from 'react';
import PropTypes from 'prop-types';

import AnimatedSwitchable from '@phoenix/widgets/animation/AnimatedSwitchable';

import SuccessMessage from './SuccessMessage';

/**
 * Action with ability to change it's own appearance to
 * "success message" with animation.
 */
const ActionWithSuccessMessage = ({
  successMessage,
  showSuccessMessage,
  actionComponent: ActionComponent,
  ...props // All action-related props
}) => (
  <AnimatedSwitchable switched={showSuccessMessage}>
    {showSuccessMessage ? (
      <SuccessMessage data-test="successMessage" key={showSuccessMessage}>
        {successMessage}
      </SuccessMessage>
    ) : (
      <ActionComponent {...props} key={showSuccessMessage} />
    )}
  </AnimatedSwitchable>
);

ActionWithSuccessMessage.propTypes /* remove-proptypes */ = {
  successMessage: PropTypes.string.isRequired,
  showSuccessMessage: PropTypes.bool.isRequired,
  actionComponent: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.func,
  ]).isRequired,
};

export default ActionWithSuccessMessage;
