import {getClientInstance} from '@core/graphql/client';

import NEED_OPEN_POPUP_AFTER_COMMUNICATION_MATCH from '@phoenix/gifts/graphql/queries/needOpenPopupAfterCommunicationMatch.gql';
import type {NeedOpenPopupAfterCommunicationMatchQuery} from '@phoenix/gifts/graphql/queries/needOpenPopupAfterCommunicationMatch';

const checkNeedOpenPopupAfterCommunicationMatch = async (
  client = getClientInstance(),
) => {
  const {
    data: {
      gifts: {needOpenPopupAfterCommunicationMatch},
    },
  } = await client.query<NeedOpenPopupAfterCommunicationMatchQuery>({
    query: NEED_OPEN_POPUP_AFTER_COMMUNICATION_MATCH,
  });

  return needOpenPopupAfterCommunicationMatch;
};

export default checkNeedOpenPopupAfterCommunicationMatch;
