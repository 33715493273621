import {from} from 'rxjs';
import {filter} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isPayUrl from '@core/utils/url/isPayUrl';
import isInRouteList from '@core/utils/routing/isInRouteList';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

import PersonalIdentityService from '../PersonalIdentityService';

/**
 * @summary Listen route changes
 * @description Show later popup personal identity if flag is present
 *              in storage when route changes
 * @param {ApolloClient} client
 */
const setPopupLaterByRouteChangeSubscription = (client) =>
  from(client.watchQuery({query: ROUTE_QUERY}))
    .pipe(
      filter(
        ({data}) =>
          !isPayUrl(data.route.current) &&
          !isInRouteList(ROUTES_WITH_POPUP, data.route.current),
      ),
    )
    .subscribe(() => {
      if (PersonalIdentityService.getViewPopupLaterFromStorage()) {
        PersonalIdentityService.showPopupIfAllowed();
      }
    });

export default setPopupLaterByRouteChangeSubscription;
