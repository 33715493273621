import has from 'lodash/has';
import get from 'lodash/get';
import {useMemo} from 'react';
import {useQuery} from '@apollo/client';

import type {ActionsFromUserFeaturesQuery} from '../graphql/queries/actionsFromUserFeatures';
import ACTIONS_FROM_USER_FEATURES_QUERY from '../graphql/queries/actionsFromUserFeatures.gql';

export type ActionsWithUserFeatures = {
  actions?: string[];
  loading: boolean;
};

/**
 * Clarify actions with user features
 */
const useActionsWithUserFeatures = (
  actionsForPlace: string[],
): ActionsWithUserFeatures => {
  const {loading, error, data} = useQuery<ActionsFromUserFeaturesQuery>(
    ACTIONS_FROM_USER_FEATURES_QUERY,
  );

  return useMemo(() => {
    if (loading || error) return {loading: true};

    const actions = actionsForPlace.filter((action) => {
      const key = `${action}InUserActions`;

      if (!has(data.userFeatures, key)) {
        return true;
      }

      return get(data.userFeatures, key);
    });

    return {actions, loading};
  }, [loading, error, actionsForPlace, data]);
};

export default useActionsWithUserFeatures;
