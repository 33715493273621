import getPackageListFromMethodTabs from '@core/payment/widgets/package/utils/getPackageListFromMethodTabs';
import type {
  MethodTabsParams,
  PaymentPackage,
} from '@core/payment/widgets/package/types';

export type PackagesData = {
  defaultActiveTab: string;
  methodTabs: MethodTabsParams[];
};

const getActivePackage = (packagesData: PackagesData): PaymentPackage => {
  const {defaultActiveTab, methodTabs} = packagesData;
  const packagesList = getPackageListFromMethodTabs(
    methodTabs,
  ) as PaymentPackage[];

  return (
    packagesList.find(
      ({isDefault, method}) => isDefault && method === defaultActiveTab,
    ) ||
    packagesList.filter(({method}) => method === defaultActiveTab)[0] ||
    packagesList[0]
  );
};

export default getActivePackage;
