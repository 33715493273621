import React from 'react';
import {useQuery} from '@apollo/client';

import t from '@core/translations/translate';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import PHOTO_UPLOAD_SETTINGS_QUERY from '@core/mediaUpload/graphql/queries/photoUploadSettings.gql';
import getAllowedExtensionsAsString from '@core/mediaUpload/utils/getAllowedExtensionsAsString';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {PlaceholderInput} from '@phoenix/ui';

const PhotoRequirements = () => {
  const {data, loading, error} = useQuery(PHOTO_UPLOAD_SETTINGS_QUERY);

  if (loading) {
    return <PlaceholderInput />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  const {
    userFeatures: {
      photoSettings: {
        allowedQuality,
        allowedExtensions,
        maxSizeInMb,
        minPhotoResolution: {width, height},
      },
    },
  } = data;

  return (
    <div data-test="mediaRequirements">
      {t('photoUploadMotivation', 'text.photo_upload_notice', {
        '{height}': height,
        '{width}': width,
        '{maxPhotoSize}': maxSizeInMb,
        '{allowedExtensions}': getAllowedExtensionsAsString({
          extensions: allowedExtensions,
        }),
      })}
      {allowedQuality && (
        <>
          &nbsp;
          {t('photoUploadMotivation', 'text.quality_auto_enhance_notice')}
        </>
      )}
      {/* To track 'photoUpload' and 'videoUpload' popup load completion */}
      <PopupLoadTracker />
    </div>
  );
};

export default PhotoRequirements;
