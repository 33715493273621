import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import useRandomUsers from '@phoenix/search/list/utils/useRandomUsers';

import PHOTOS_BY_USERS_ID_QUERY from '../graphql/queries/photosByUsersIdQuery.gql';
import HeaderWithImages from '../components/HeaderWithImages';
import HeaderWithImagesPlaceholder from '../components/HeaderWithImagesPlaceholder';
import {COINS_RANDOM_USERS_COUNT} from '../constants/randomUsersCount';
import getCoinsMatchCommunicationsIds from '../utils/getCoinsMatchCommunicationsIds';

const CoinsTelegramBotHeader = ({userId}) => {
  const userIds = userId ? getCoinsMatchCommunicationsIds(userId) : [];
  const userIdsForView = userIds.slice(0, COINS_RANDOM_USERS_COUNT);
  const needRandomUsers = userIdsForView.length < COINS_RANDOM_USERS_COUNT;

  const {
    loading,
    error,
    data: {userList} = {},
  } = useQuery(PHOTOS_BY_USERS_ID_QUERY, {
    variables: {ids: userIdsForView},
    skip: needRandomUsers,
  });

  const {
    loading: randomUsersLoading,
    error: randomUsersError,
    randomUsers,
  } = useRandomUsers({
    count: COINS_RANDOM_USERS_COUNT,
    skip: !needRandomUsers,
  });

  const users = userList || randomUsers || [];
  if (loading || randomUsersLoading) {
    return <HeaderWithImagesPlaceholder count={COINS_RANDOM_USERS_COUNT} />;
  }
  if (error || randomUsersError || !users.length) {
    return null;
  }

  return <HeaderWithImages users={users} />;
};

CoinsTelegramBotHeader.propTypes /* remove-proptypes */ = {
  userId: PropTypes.string,
};

export default CoinsTelegramBotHeader;
