import isPayUrl from '@core/utils/url/isPayUrl';
import isInRouteList from '@core/utils/routing/isInRouteList';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

/**
 * Can show popup. Depend on route, popup state and disabled routes
 * @param {Object} route
 * @param {Object} popup
 * @param {Array} disabledRoutes
 * @returns {Boolean}
 */
const canShowPopup = (route, popup, disabledRoutes = []) => {
  return (
    !isPayUrl(route.current) &&
    !isInRouteList(ROUTES_WITH_POPUP, route.current) &&
    !isInRouteList(disabledRoutes, route.current) &&
    !popup.hasQueue
  );
};

export default canShowPopup;
