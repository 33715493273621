import {useCallback} from 'react';

import t from '@core/translations/translate';
import trimMessageToMaxLength from '@core/utils/textEditor/trimMessageToMaxLength';
import prepareMessage from '@core/utils/textEditor/prepareMessage';

const getRemaining = (maxLength, value) =>
  maxLength - prepareMessage(value).length;

/**
 * Hook for returning how many characters user can write.
 * Returned value is already translated string.
 *
 * @param {String} value
 * @param {Number} maxLength
 * @param {Function} onChange - it will be spied inside
 * @returns {Object} result
 * @returns {Function} result.onChange
 * @returns {String} result.description
 * @returns {Number} result.remaining
 */
const useRemainingCharacters = (value = '', maxLength, onChange) => {
  const handleChange = useCallback(
    (event, inputValue = '') => {
      let data = inputValue;

      if (prepareMessage(data).length > maxLength) {
        data = trimMessageToMaxLength(data, maxLength);
      }
      // Useful for formik because it doesn't know about custom second onChange argument.
      if (data !== inputValue) {
        event.target.value = data;
      }

      onChange && onChange(event, data); // Execute original 'onChange'
    },
    [maxLength, onChange],
  );

  const remaining = getRemaining(maxLength, value);

  return {
    remaining,
    onChange: handleChange,
    description: t('profileBase', 'text.remaining_n_characters', {
      '{amount}': remaining,
    }),
  };
};

export default useRemainingCharacters;
