import PropTypes from 'prop-types';

import useCoregistrationSearchPopupZone from '../utils/useCoregistrationSearchPopupZone';

/**
 * Starts coregistration popup route listner to show it when needed
 * Should be wrapped in CoregistrationProvider.js
 * @see App.js
 */
const CoregistrationPopupDisplayer = ({useClientStorage}) => {
  useCoregistrationSearchPopupZone({
    useClientStorage,
  });

  return null;
};

CoregistrationPopupDisplayer.propTypes /* remove-proptypes */ = {
  useClientStorage: PropTypes.bool.isRequired,
};

export default CoregistrationPopupDisplayer;
