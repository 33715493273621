/**
 * Available verification statuses for personal identity feature
 * Used for showing notification and for detecting global user status in terms
 * of uploaded or not documents
 *
 * Available values:
 *   UNAVAILABLE - personal identity unavailable, no data was uploaded, do nothing
 *   APPROVED - document was approved
 *   DISAPPROVED - document was disapproved
 *   DEFAULT - personal identity available, do nothing
 */

enum VerificationStatus {
  UNAVAILABLE = 0,
  APPROVED = 1,
  DISAPPROVED = 2,
  DEFAULT = 3,
}

export default VerificationStatus;
