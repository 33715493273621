import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';

import cachePersistManager from '@core/graphql/utils/cachePersistManager';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import HeaderToolbarProvider from '@core/header/containers/HeaderToolbarProvider';
import AccessError from '@core/application/containers/AccessError';

import WalletPreloader from '@phoenix/payment/common/containers/WalletPreloader';
import PaymentOrderChecker from '@phoenix/payment/common/containers/PaymentOrderChecker';

import ERROR_CODE_MAP from '../constants/accessErrorCodeMap';
import LegacyAppViewInitializer from '../containers/LegacyAppViewInitializer';
import SubscriptionsStarter from '../containers/SubscriptionsStarter';
import AppLayout from './AppLayout';

const persistCache = async () => {
  await cachePersistManager.persistCache();
};

const PaymentLayout = ({
  isAllowed,
  deferredPageAvailable,
  isBanned,
  isAuthorizedZone,
  isSiteClosed,
  isWeb2AppSite,
  loading,
}) => {
  const {pathname} = useLocation();
  /**
   * Before reload payment page, we need to persist cache to local storage
   * because we could receive new data, tos show then on page before payment page after cache restoring
   * (used only on payment page)
   */
  useEffect(() => {
    const persistAllowed = !window.IS_INTEGRATION_TEST_ENVIRONMENT && isAllowed;
    if (persistAllowed) {
      window.addEventListener('beforeunload', persistCache);
    }

    return () => {
      if (persistAllowed) {
        window.removeEventListener('beforeunload', persistCache);
      }
    };
  }, [isAllowed]);

  return (
    <BabciaScopedProvider category={BabciaScopedProvider.CATEGORY.PAGE}>
      <HeaderToolbarProvider>
        <Fragment>
          <AccessError rulesMap={ERROR_CODE_MAP}>
            {isAllowed && (
              <Fragment>
                <WalletPreloader pathname={pathname} />
                <PaymentOrderChecker />
              </Fragment>
            )}
            <AppLayout
              loading={loading}
              deferredRenderAllowed={deferredPageAvailable}
              banned={isBanned}
              authorizedZone={isAuthorizedZone}
              siteClosed={isSiteClosed}
              web2AppSite={isWeb2AppSite}
              isPaymentPage
            />
            {!loading && <LegacyAppViewInitializer />}
          </AccessError>
          {isAllowed && deferredPageAvailable && <SubscriptionsStarter />}
        </Fragment>
      </HeaderToolbarProvider>
    </BabciaScopedProvider>
  );
};

PaymentLayout.propTypes /* remove-proptypes */ = {
  isAuthorizedZone: PropTypes.bool.isRequired,
  isBanned: PropTypes.bool.isRequired,
  isSiteClosed: PropTypes.bool.isRequired,
  isWeb2AppSite: PropTypes.bool.isRequired,
  deferredPageAvailable: PropTypes.bool,
  isAllowed: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

export default PaymentLayout;
