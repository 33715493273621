import React from 'react';

import {Loader} from '@phoenix/ui';
import ThemedActivityPage from '@phoenix/theming/components/ThemedActivityPage';

/**
 * Don't worry, "ThemedActivityPage" already splits component in different chunks
 */
const NewsFeedChunk = (props) => (
  <ThemedActivityPage placeholder={<Loader fullscreen />} {...props} />
);

export default NewsFeedChunk;
