import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {Button, Row} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Align, ButtonType} from '@phoenix/ui/constants';
import {H3} from '@phoenix/typography';
import PhotoWithWidgets from '@phoenix/user/photo/components/PhotoWithWidgets';

import userItem from '../constants/propTypes';
import css from '../styles/InteractiveLikeWin.css';

const InteractiveLikeWinLayout = ({user, handleClick}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <PopupFrame
      data-test="interactiveLikeWinPopupLayout"
      content={
        <Row align={Align.CENTER}>
          <PhotoWithWidgets
            className={css.photo}
            photo={getPrimaryPhotoData(user.profile)}
            round
            online={user.profile.statuses.isOnline}
            recentlyOnline={user.profile.statuses.isRecentlyOnline}
            showOverlay={false}
            userId={user.profile.id}
          />
          <Row>
            <H3>{t('extraPopups', 'text.thumbs_up')}</H3>
          </Row>
        </Row>
      }
      actions={
        <Button
          data-test="interactiveLikeAction"
          className={cn(css.button)}
          type={ButtonType.PRIMARY}
          fullWidth={isPhone}
          onClick={handleClick}
          trackingName="pickUpGift"
        >
          {t('extraPopups', 'text.pick_up_gift')}
        </Button>
      }
    />
  );
};

InteractiveLikeWinLayout.propTypes /* remove-proptypes */ = {
  user: userItem,
  handleClick: PropTypes.func.isRequired,
};

export default InteractiveLikeWinLayout;
