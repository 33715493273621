import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyPwaNextStep = lazy(
  () =>
    import(
      /* webpackChunkName: 'PwaNextStep' */ '@phoenix/progressiveWebApplication/PwaNextStep'
    ),
);

export default (props) => <SplitChunk component={LazyPwaNextStep} {...props} />;
