import type {FC, ReactNode} from 'react';
import React from 'react';

import {PlaceholderInput, PlaceholderCircle, Spacing} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/SectionLayout.css';

type SectionLayoutPlaceholderProps = {
  children?: ReactNode;
  icon?: boolean;
  spacingSize?: SpacingSize;
};

/**
 * Wrapping layout for sections, used inside current and target
 * uses profile pages
 */
const SectionLayoutPlaceholder: FC<SectionLayoutPlaceholderProps> = ({
  children,
  icon = true,
  spacingSize = SpacingSize.NONE,
}) => (
  <div className={css.container}>
    <Spacing size={spacingSize} withoutBottom>
      {icon && (
        <div className={css.iconHolder}>
          <PlaceholderCircle size={40} />
        </div>
      )}
      <div className={css.content}>
        <div className={css.row}>
          <PlaceholderInput size={150} />
        </div>
        <div className={css.inner}>{children}</div>
      </div>
    </Spacing>
  </div>
);

export default SectionLayoutPlaceholder;
