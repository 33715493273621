import type {FC, MouseEvent} from 'react';
import React, {Fragment, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';

import {Link} from '@phoenix/typography';

import type {SystemNotificationProps} from '../types';
import SystemNotificationLayout from '../components/SystemNotificationLayout';

const SystemNotificationAgeVerification: FC<SystemNotificationProps> = ({
  onClose,
  ...props
}) => {
  const history = useHistory();

  const handleClose = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      onClose?.(e);
      history.push('/personalIdentity');
    },
    [history, onClose],
  );

  return (
    <SystemNotificationLayout
      onClose={onClose}
      // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
      onClick={handleClose}
      text={(inverse: boolean) => (
        <Fragment>
          <Link inverse={inverse}>
            {t('headerNotification', 'text.verify_your_age_link')}
          </Link>
          {` ${t('headerNotification', 'text.verify_your_age_text')}`}
        </Fragment>
      )}
      iconType="age-check"
      type="highlighted"
      {...props}
    />
  );
};

export default SystemNotificationAgeVerification;
