import type {NormalizedCacheObject, ApolloCache} from '@apollo/client';
import {ApolloLink} from '@apollo/client';
import {Subject} from 'rxjs';

import DelayedRenderChecker from '@phoenix/delayedRender/utils/DelayedRenderChecker';
import isAllowedRequestIdle from '@phoenix/delayedRender/utils/isAllowedRequestIdle';

const idleChecker = isAllowedRequestIdle() ? null : new DelayedRenderChecker();

/**
 * Example:
 * {
 *   AppDataQuery: boolean // is successfully completed
 * }
 */
const completedOperations: Record<string, boolean> = {};

const listeners: Record<string, Array<(ok: boolean) => void>> = {};

const waitFor = (
  operationName: string,
  callback: (ok: boolean) => void,
): void => {
  listeners[operationName] ||= [];
  listeners[operationName].push(callback);
};

const triggerComplete = (operationName: string, ok: boolean): void => {
  completedOperations[operationName] = ok;

  const callbacks = listeners[operationName];
  delete listeners[operationName];

  idleChecker?.afterRequest(operationName);

  if (callbacks) {
    // operation is completed, but wait for cache update before trying to resolve blocked queries.
    setTimeout(() => {
      callbacks.forEach((callback) => callback(ok));
    }, 0);
  }
};

const isCompleted = (operationName: string): boolean =>
  // eslint-disable-next-line no-prototype-builtins
  completedOperations.hasOwnProperty(operationName);

type GetOptimizationLinkParams = {
  blockUntilComplete: Record<string, string>;
  blockUntilSuccess: Record<string, string>;
};

/**
 * Allows to run queries in specific order to use data preload or delay something secondary.
 *
 * @param {ApolloCache} cache
 * @param {Object<string>} blockUntilComplete
 * @param {Object<string>} blockUntilSuccess
 */
const getOptimizationLink = (
  cache: ApolloCache<NormalizedCacheObject>,
  {blockUntilComplete, blockUntilSuccess}: GetOptimizationLinkParams,
): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const {operationName, query, variables} = operation;

    const successOnly = Boolean(blockUntilSuccess[operationName]);
    const blockedByOperationName =
      blockUntilSuccess[operationName] || blockUntilComplete[operationName];

    let observable;

    if (blockedByOperationName && !isCompleted(blockedByOperationName)) {
      observable = new Subject();

      waitFor(blockedByOperationName, (ok) => {
        if (successOnly && !ok) {
          // Keep blocked query in "loading" state to avoid additional errors.
          return;
        }

        // Resolve unblocked operation from cache if possible, otherwise run network request.
        const data =
          operationName.endsWith('Query') &&
          cache.readQuery<unknown>({
            query,
            variables: {
              ...variables,
              /**
               * For some reason InMemoryCache may return incorrect data (without client fields) for delayed operations.
               * See readQuery in {@see updateGlobalFreeMessagesCount}.
               * Custom variable is used to avoid returning data without passing through typePolicies.
               */
              _fix: 1,
            },
          });

        if (data) {
          observable.next({data});
          observable.complete();
        } else {
          forward(operation).subscribe(observable);
        }
      });
    } else {
      observable = forward(operation);
    }

    idleChecker?.beforeRequest(operationName);

    observable.subscribe(({errors}) => {
      triggerComplete(operationName, !errors);
    });

    return observable;
  });

export default getOptimizationLink;
