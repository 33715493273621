import React, {Fragment, useRef} from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import PopupService from '@core/popup/utils/PopupService';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Row, SpacingLarge} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import TrustedVerificationSmsSecondStep from './TrustedVerificationSmsSecondStep';
import TrustedVerificationSmsSecondStepInfo from '../components/TrustedVerificationSmsSecondStepInfo';
import TrustedVerificationPopupHeader from '../components/TrustedVerificationPopupHeader';
import TrustedVerificationSmsSecondStepLayoutActions from '../components/TrustedVerificationSmsSecondStepLayoutActions';
import openTrustedVerificationExceedStepPopup from '../utils/openTrustedVerificationExceedStepPopup';

/**
 * Component for allowing you to call the support
 */
const TrustedVerificationSecondStepPopup = ({
  referenceId,
  handleChangePhone,
}) => {
  const {data: spacedMotivation, loading} = useThemeFeature(
    'trustedVerificationPopup',
    'spacedMotivation',
  );
  const isPhone = usePhoneBreakpoint();
  /**
   * Need for getting correct value of height of first step
   * for loading gdpr and hiding under scroll
   */
  const contentRef = useRef();

  if (loading) {
    return null;
  }

  return (
    <PopupFrame
      spacedContent={false}
      forceFixedActions
      content={
        <Fragment>
          <div ref={contentRef}>
            <TrustedVerificationPopupHeader
              spacedMotivation={spacedMotivation}
              isPhone={isPhone}
            />
            <SpacingLarge withoutBottom>
              <TrustedVerificationSmsSecondStep
                referenceId={referenceId}
                smsInfo={<TrustedVerificationSmsSecondStepInfo inPopup />}
                onError={() => {
                  PopupService.closePopup();
                  openTrustedVerificationExceedStepPopup();
                }}
              />
            </SpacingLarge>
          </div>
        </Fragment>
      }
      actions={
        <Row align={Align.CENTER}>
          <TrustedVerificationSmsSecondStepLayoutActions
            oneColumn={isPhone}
            handleChangePhone={() => {
              PopupService.closePopup();
              handleChangePhone();
            }}
          />
          {/* To track 'trustedVerificationSecondStep' popup load completion */}
          <PopupLoadTracker />
        </Row>
      }
    />
  );
};

TrustedVerificationSecondStepPopup.propTypes /* remove-proptypes */ = {
  referenceId: PropTypes.string.isRequired,
  handleChangePhone: PropTypes.func.isRequired,
};

export default TrustedVerificationSecondStepPopup;
