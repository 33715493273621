import type {FC} from 'react';
import React, {useMemo} from 'react';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import HeaderAppearance from '@core/theming/constants/features/HeaderAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43706
 */
type ThemedHeaderProps = {
  [key: string]: any;
};

const mobileResolver: ModuleResolver<
  HeaderAppearance,
  ModuleComponent<ThemedHeaderProps>
> = (value) => {
  switch (value) {
    case HeaderAppearance.REGULAR:
      return import(
        /* webpackChunkName: 'header' */ '@phoenix/header/containers/HeaderRegular'
      );
    case HeaderAppearance.WITH_USER_PHOTO:
      return import(
        /* webpackChunkName: 'headerWithPhoto' */ '@phoenix/header/containers/HeaderWithUserPhoto'
      );
    case HeaderAppearance.WITH_USER_PHOTO_AND_SHORT_LIST:
      return import(
        /* webpackChunkName: 'headerWithPhotoAndShortList' */ '@phoenix/header/containers/HeaderWithUserPhotoAndShortList'
      );
    case HeaderAppearance.WITH_USER_PHOTO_AND_SHORT_LIST_AND_DISAPPROVE:
      return import(
        /* webpackChunkName: 'headerWithPhotoAndShortList' */ '@phoenix/header/containers/HeaderWithUserPhotoAndShortListAndDisapprove'
      );
    case HeaderAppearance.WITH_USER_PHOTO_AND_SHORT_LIST_AND_EXCLUDE_ROUTES:
      return import(
        /* webpackChunkName: 'headerWithPhotoAndShortListAndExcludeRoutes' */ '@phoenix/header/containers/HeaderWithUserPhotoAndShortListAndExcludeRoutes'
      );
    case HeaderAppearance.BOUNDLESS:
      return import(
        /* webpackChunkName: 'headerWithBoundlessItems' */ '@phoenix/header/containers/HeaderWithBoundlessItems'
      );
    case HeaderAppearance.WITH_HIGHLIGHTED_ACTIVE:
      return import(
        /* webpackChunkName: 'headerWithHighlightedActive' */ '@phoenix/header/containers/HeaderWithHighlightedActive'
      );
    case HeaderAppearance.WITH_HIGHLIGHTED_ACTIVE_AND_HOME:
      return import(
        /* webpackChunkName: 'headerWithHighlightedHome' */ '@phoenix/header/containers/HeaderWithHighlightedActiveAndHome'
      );
    case HeaderAppearance.WITH_UNDERLINED_ACTIVE:
      return import(
        /* webpackChunkName: 'headerWithUnderlinedActive' */ '@phoenix/header/containers/HeaderWithUnderlinedActive'
      );
    case HeaderAppearance.WITH_TEXT_ON_ACTIVE_ITEM:
      return import(
        /* webpackChunkName: 'headerWithUnderlinedActive' */ '@phoenix/header/containers/HeaderWithTextOnActiveItem'
      );
    default:
      return null;
  }
};

const webResolver: ModuleResolver<
  never,
  ModuleComponent<ThemedHeaderProps>
> = () =>
  import(
    /* webpackChunkName: 'headerWeb' */ '@phoenix/header/containers/Header'
  );

/**
 * Appearance-changeable header based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedHeader: FC<ThemedHeaderProps> = (props) => {
  const isPhone = usePhoneBreakpoint();
  const ThemedComponentLoader = useMemo(
    () => createThemedComponentLoader<ThemedHeaderProps, HeaderAppearance>(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="header"
      param="appearance"
      moduleResolver={isPhone ? mobileResolver : webResolver}
      {...props}
    />
  );
};

export default ThemedHeader;
