/**
 * Go to subscribe from popup
 * @type {string}
 */
export const SUBSCRIBE_POPUP = 'subscribePopup';

/**
 * Go to subscribe from admin message
 * @type {string}
 */
export const SUBSCRIBE_MESSAGE = 'subscribeMessage';
