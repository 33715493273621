import {fromEvent} from 'rxjs';

import getUserAgentParser from '@core/utils/getUserAgentParser';
import isInRouteList from '@core/utils/routing/isInRouteList';
import withUserGestureVar from '@core/utils/dom/withUserGestureVar';

import isStandalone from '@phoenix/progressiveWebApplication/utils/isStandalone';
import {MOTIVATION_ALLOWED_OS_NAME} from '@phoenix/progressiveWebApplication/constants/motivationAllowedOsName';

import webPushLogger from '../utils/webPushLogger';
import NOTIFICATION_EVENT from '../constants/notificationEvent';
import TRACK from '../constants/track';
import WebPushTrackingService from '../utils/WebPushTrackingService';
import POPUP_VIEW from '../constants/popupView';
import NativeHandler from './NativeHandler';
import DateinformHandler from './DateinformHandler';
import WebPushError from '../utils/WebPushError';
import DATEINFORM_RESUBSCRIBE_LOGIC from '../constants/dateinformSubscribeLogic';
import SUBSCRIBE_LOGIC from '../constants/subscribeLogic';
import NativeStubHandler from './NativeStubHandler';

/**
 * An abstract constructor containing all common methods for existing logics
 */
export default class CommonLogic {
  constructor({logicsMediator, options}) {
    /**
     * Stub to check if script can use scroll user event as bypassing user gesture restriction.
     * The scroll event can only be used after user activity, namely clicks and transitions, so it needs to be sure
     * that there was activity before attaching the event to the scroll
     */
    if (CommonLogic.needBypassingUserGesture()) {
      /**
       * To prevent multiple scroll event listeners with several subscriptions
       * @type {boolean}
       */
      this.waitGesture = false;

      /**
       * Adds scroll listener to the window to propose web push subscription for Mac OS Safari and iOS PWA
       * @private
       */
      this.handleScroll = () => {
        if (
          !isInRouteList(
            this.options.disabledRoutes,
            window.location.pathname,
          ) &&
          withUserGestureVar()
        ) {
          this.processSubscription();
          window.removeEventListener('scroll', this.handleScroll);
          this.waitGesture = false;
        }
      };

      /**
       * This flag needs to check if user has activity and we can use scroll event for subscription prompt
       * more details in withUserGestureVar
       */
      window.document.addEventListener(
        'click',
        () => {
          withUserGestureVar(true);
        },
        {once: true},
      );
    }

    this.options = {
      /**
       * The options from the very root level
       * @type {Object}
       */
      defaultOptions: {},

      /**
       * A logic`s priority to prevent conflicts with other ones
       * @type {number}
       */
      priority: 0,

      ...options,
    };

    this.logicsMediator = logicsMediator;

    /**
     * Should be as a property in order to be able to filter by it
     * @type {number}
     */
    this.priority = this.options.priority;

    /**
     * Shows whether a logic is currently being used as a main or an alternative subscription
     * @var
     * @type {Boolean}
     */
    this.isAlternativeSubscription = false;

    /**
     * @type {array}
     */
    this.listeners = [];

    /**
     * @type {boolean}
     */
    this.subscriptionListenersStarted = false;

    this.initLogicComponents();
  }

  /**
   * Init all the necessary logic`s components
   * e.g. popups, handlers, models
   * @private
   */
  initLogicComponents() {
    this.trackingService = new WebPushTrackingService({
      userId: this.options.defaultOptions.userId,
      logic: this.options.logic,
      logicName: this.options.name,
      logicType: TRACK.LOGIC_TYPES.MAIN,
      action: this.options.action,
      popup:
        !this.options.popup || this.options.popup === POPUP_VIEW.NONE
          ? TRACK.POPUP_TYPES.NATIVE
          : this.options.popup,
      timeout: this.options.timeout,
    });
    this.logicHandler = this.getLogicHandler();
  }

  /**
   * Get a handler which will be used for a subscription
   * @return {Object}
   * @throws {Error} an error instance
   * @protected
   */
  getLogicHandler() {
    /**
     * dateInformEnabled === true - we have never blocked a subscription on dateinform
     * dateInformResubscribeLogic !== disabled - for some reason we have to resubscribe to dateinform
     * this.options.logic === dateinform - the current subscription logic is dateinform
     * this.isAlternativeSubscription === true - there is a need to force start an alternative subscription even if 'logic' isn't alternative
     */
    const isDateinformSubscription =
      this.options.dateInformEnabled &&
      (this.options.dateInformResubscribeLogic !==
        DATEINFORM_RESUBSCRIBE_LOGIC.DISABLED ||
        this.options.logic === SUBSCRIBE_LOGIC.DATEINFORM ||
        this.isAlternativeSubscription);

    /**
     * There are some conditions indicating we have to subscribe to dateinform
     * @see isDateinformSubscription
     */
    if (isDateinformSubscription) {
      return this.getDateinformHandler();
    }

    /**
     * If a native subscription is enabled get a native handler
     */
    if (this.options.logic === SUBSCRIBE_LOGIC.NATIVE) {
      return this.getNativeHandler();
    }

    /**
     * Throw an error if everything is disabled
     */
    if (
      (this.options.logic === SUBSCRIBE_LOGIC.DATEINFORM &&
        !this.options.dateInformEnabled) ||
      this.options.logic === SUBSCRIBE_LOGIC.DISABLED
    ) {
      this.removeListeners();
      throw new WebPushError('there is no enabled logic');
    }

    /**
     * Throw an error if a logic's value could't be recognized
     */
    this.removeListeners();
    throw new WebPushError(`incorrect value for logic ${this.options.logic}`);
  }

  /**
   * @return {DateinformHandler}
   * @protected
   */
  getDateinformHandler() {
    return new DateinformHandler({
      dateInformEnabled: this.options.dateInformEnabled,
      dateInformResubscribeLogic: this.options.dateInformResubscribeLogic,
      ...this.trackingService.getAttributes(),
      userId: this.options.defaultOptions.userId,
      popup: TRACK.POPUP_TYPES.DATEINFORM,
    });
  }

  /**
   * Take the handler instance and check out its permission
   * If the permission is denied, get and return an alternative handler
   * If granted, return the handler
   * @throws {Error} an error instance
   * @return {Object}
   * @private
   */
  getNativeHandler() {
    const options = {
      ...this.options.defaultOptions,
      tracking: {
        ...this.trackingService.getAttributes(),
        popup: TRACK.POPUP_TYPES.NATIVE,
      },
      trackingService: this.trackingService,
    };

    let nativeHandler = new NativeHandler(options);

    if (
      !nativeHandler.isAllowedPushService() &&
      getUserAgentParser().getOS().name === MOTIVATION_ALLOWED_OS_NAME &&
      !isStandalone()
    ) {
      nativeHandler = new NativeStubHandler(options);
    }

    /**
     * If native handler not allowed, return alt handler instead
     */
    if (!nativeHandler.isAllowedPushService()) {
      return this.getAlternativeHandler();
    }

    // If a native notification's permission is denied, get an alternative handler instead
    return nativeHandler.isPermissionDenied()
      ? this.getAlternativeHandler() // May throw an error
      : nativeHandler;
  }

  /**
   * Set new options based on alternative settings and get a handler
   * @return {Object}
   * @throws {Error} an error instance
   * @protected
   */
  getAlternativeHandler() {
    /**
     * If it's already an alternative subscription, it means there is an attempt of getting the native handler again,
     * but native subscription is blocked, as it's already known
     * Throw an error in this case
     */
    if (this.isAlternativeSubscription) {
      throw new WebPushError(
        'an attempt of getting a native handler but its permission is denied and there is no alternative one',
      );
    }

    // Now the flag indicates we are following an alternative subscription logic
    this.isAlternativeSubscription = true;

    // Extend the options with alter settings
    this.options = {...this.options, ...this.options.alter};

    this.trackingService.setAttributes({
      logicType: TRACK.LOGIC_TYPES.ALTER,
      popup: this.options.popup,
    });

    this.logger('Switch to alternative');

    // Get a handler again after extending the options
    return this.getLogicHandler();
  }

  /**
   * The main entry point to set up a certain logic
   * @public
   */
  setup() {
    this.initListeners();

    this.logger('Setup');
  }

  /**
   * Whether a logic is disabled and shouldn't be set up at all
   * @return {boolean}
   * @public
   */
  isSetupDisabled() {
    return (
      !this.logicHandler.isPermissionDefault() ||
      this.logicsMediator.isCountersLimitReached()
    );
  }

  /**
   * Whether a logic is disabled to start right now
   * @return {boolean}
   * @private
   */
  isStartDisabled() {
    const isPermissionDefault = this.logicHandler.isPermissionDefault();
    const isCountersLimitReached = this.logicsMediator.isCountersLimitReached();
    const isPaused = this.isPaused();
    const isHighestPriority = this.logicsMediator.isHighestPriority(
      this.options.priority,
    );

    return (
      !isPermissionDefault ||
      isCountersLimitReached ||
      isPaused ||
      !isHighestPriority
    );
  }

  /**
   * This stub needs for Mac OS safari and iOS PWA browsers, whose want showing subscription prompt with user gesture action
   * That why we add scroll event handler for prompt showing after this check
   * @static
   * @private
   * @returns {boolean}
   */
  static needBypassingUserGesture() {
    const osName = getUserAgentParser().getOS().name;
    const browserName = getUserAgentParser().getBrowser().name;
    return (
      (osName === 'Mac OS' && browserName === 'Safari') ||
      (osName === 'iOS' && browserName === 'Mobile Safari' && isStandalone())
    );
  }

  /**
   * Subscription process
   * @private
   */
  processSubscription() {
    this.logicHandler.trySubscribe();
    this.initSubscriptionListeners();
    this.logger('Subscription started');
  }

  /**
   * Start the main subscription (the native popups)
   * @private
   */
  startSubscription() {
    /**
     * If it is Safari Mac OS or iOS PWA, use stubbed scenario with scroll and click listeners to bypass
     * user gesture restriction
     */
    if (CommonLogic.needBypassingUserGesture()) {
      if (!this.waitGesture) {
        window.addEventListener('scroll', this.handleScroll);
        this.waitGesture = true;
      }

      return;
    }

    this.processSubscription();
  }

  /**
   * Remove all the active listeners in order to clear memory
   * @private
   */
  removeListeners() {
    this.listeners.length &&
      this.listeners.forEach((listener) => listener.unsubscribe());
  }

  /**
   * Init native handler listeners
   */
  initSubscriptionListeners() {
    if (this.subscriptionListenersStarted) return;

    this.listeners.push(
      fromEvent(window, NOTIFICATION_EVENT.DECLINED).subscribe(() =>
        this.onSubscriptionDeny(),
      ),
      fromEvent(window, NOTIFICATION_EVENT.CLOSE).subscribe(() =>
        this.onSubscriptionClose(),
      ),
    );

    this.subscriptionListenersStarted = true;
  }

  /**
   * @private
   */
  onSubscriptionDeny() {
    this.onDeny();
  }

  /**
   * @private
   */
  onSubscriptionClose() {
    this.logicsMediator.onIgnore();
  }

  /**
   * @private
   */
  onPopupClose() {
    this.onSubscriptionClose();
    this.track(TRACK.ACTIONS.CLOSED);
  }

  /**
   * @private
   */
  onPopupAccept() {
    this.startSubscription();
    this.track(TRACK.ACTIONS.ACCEPTED);
  }

  /**
   * @private
   */
  onPopupDeny() {
    this.onDeny();
    this.track(TRACK.ACTIONS.DENIED);
  }

  /**
   * @private
   */
  onDeny() {
    try {
      if (this.options.alter) this.logicHandler = this.getAlternativeHandler();

      this.logicsMediator.onDeny();
    } catch (error) {
      this.logger(
        `Web push logic ALTERNATIVE for ${this.options.action.toUpperCase()} hasn't been set up: ${
          error.message
        }`,
      );
    }
  }

  /**
   * Whether a logic is temporarily paused
   * @return {Boolean}
   * @private
   */
  isPaused() {
    return this.logicsMediator.isPaused();
  }

  /**
   * @param {string} message
   * @private
   */
  logger(message) {
    webPushLogger(message, this.options.action);
  }

  /**
   * @param {string} action
   * @private
   */
  track(action) {
    this.trackingService.track(action);
  }
}
