import ImageGroups from '@core/theming/constants/ImageGroups';

enum ImageExtension {
  JPG = 'jpg',
  SVG = 'svg',
}

/**
 * Mapping between image groups and image extensions that are used
 */
const EXTENSION_BY_IMAGE_GROUP: Record<ImageGroups, ImageExtension> = {
  [ImageGroups.DEFAULT_AVATAR]: ImageExtension.SVG,
  [ImageGroups.DEFAULT_UNIFORM_AVATAR]: ImageExtension.SVG,
  [ImageGroups.DISAPPROVE_REASONS]: ImageExtension.JPG,
};

export default EXTENSION_BY_IMAGE_GROUP;
