import {isNil} from 'lodash/lang';

import logger from '@core/logger';

import DEBT_BALANCE_QUERY from '../graphql/queries/debtBalance.gql';
import CHANGE_DEBT_BALANCE_SUBSCRIPTION from '../graphql/subscriptions/changeDebtBalance.gql';

/**
 * Update debt balance
 * @param debtBalance
 * @param client
 */
const updateDebtBalance = (debtBalance, client) => {
  const data = client.readQuery({query: DEBT_BALANCE_QUERY});
  if (!data) {
    logger.sendWarning('[updateDebtBalance] DebtBalanceQuery read failed');
    return;
  }

  // If return debtBalance === null, debt logic is turn off for this site
  if (isNil(data.credits.debtBalance)) {
    return;
  }

  client.writeQuery({
    query: DEBT_BALANCE_QUERY,
    data: {
      credits: {
        ...data.credits,
        debtBalance,
      },
    },
  });
};

let startedListeners = false;
/**
 * Listener subscriptions for changing debt
 * @param client
 */
const startCreditDebtListeners = (client) => {
  if (startedListeners) {
    return;
  }

  startedListeners = true;

  client
    .subscribe({
      query: CHANGE_DEBT_BALANCE_SUBSCRIPTION,
    })
    .subscribe(
      ({
        data: {
          credits: {debtBalance},
        },
      }) => updateDebtBalance(debtBalance, client),
    );
};

export default startCreditDebtListeners;
