export const BANANA = 'banana';
export const LOLY_POP = 'lolyPop';
export const MAFIN = 'mafin';
export const ICE_CREAM = 'iceCream';
export const BEAR = 'bear';
export const COCTAIL = 'coctail';
export const ROSE = 'rose';
export const MASK = 'mask';
export const HEART = 'heart';
export const KISS = 'kiss';
export const CHOCOLATE = 'chocolate';
export const COFFEE = 'coffee';
export const CROWN = 'crown';
export const DIAMOND = 'diamond';
export const QUEEN = 'queen';
export const BLUE_DIAMOND = 'blueDiamond';
export const BOUQUET = 'bouquet';
export const FLOWERS = 'flowers';
export const CHAMPAGNE = 'champagne';
export const CHOCOLATE_STRAWBERRY = 'chocolateStrawberry';
export const FIRE = 'fire';
export const SMILE_KISS = 'smileKiss';
export const HEART_ON_FIRE = 'heartOnFire';
export const CATS_BLACK = 'cats_black';
export const CATS_GOLD = 'cats_gold';
export const HOME = 'home';
export const JEEP = 'jeep';
export const LOCK_AND_KEY = 'lock_and_key';
export const STAR_GOLD = 'star_gold';
export const HOME_GOLD = 'home_gold';
export const LOCK = 'lock';
export const JEEP_GOLD = 'jeep_gold';
export const SWEET_BOX = 'sweetBox';
export const STRAWBERRY = 'strawberry';
export const RING = 'ring';
export const PERL = 'perl';
export const PERFUME = 'perfume';
export const PENDANT_PINK = 'pendantPink';
export const PENDANT_BLUE = 'pendantBlue';
export const PANCAKES = 'pancakes';
export const MACAROON = 'macaroon';
export const KAMASUTRA = 'kamasutra';
export const RED_HEART = 'redHeart';
export const HANDCUFFS = 'handcuffs';
export const GLASSES = 'glasses';
export const FRUIT_BASKET = 'fruitBasket';
export const COFFEE_WITH_HEART = 'coffeeWithHeart';
export const CHOKER = 'choker';
export const CHEESECAKE = 'cheesecake';
export const CARNIVAL_MASK = 'carnivalMask';
export const CAKE = 'cake';
export const BOX = 'box';
export const BOOT = 'boot';

/**
 * Mapping between machine names of gifts and
 * real images that represents them.
 */
export default {
  [BANANA]: require('../images/gifts/banana.png'),
  [LOLY_POP]: require('../images/gifts/lolyPop.png'),
  [MAFIN]: require('../images/gifts/mafin.png'),
  [ICE_CREAM]: require('../images/gifts/iceCream.png'),
  [BEAR]: require('../images/gifts/bear.png'),
  [COCTAIL]: require('../images/gifts/coctail.png'),
  [ROSE]: require('../images/gifts/rose.png'),
  [MASK]: require('../images/gifts/mask.png'),
  [HEART]: require('../images/gifts/heart.png'),
  [KISS]: require('../images/gifts/kiss.png'),
  [CHOCOLATE]: require('../images/gifts/chocolate.png'),
  [COFFEE]: require('../images/gifts/coffee.png'),
  [CROWN]: require('../images/gifts/crown.png'),
  [DIAMOND]: require('../images/gifts/diamond.png'),
  [QUEEN]: require('../images/gifts/queen.png'),
  [BLUE_DIAMOND]: require('../images/gifts/blueDiamond.png'),
  [BOUQUET]: require('../images/gifts/bouquet.png'),
  [FLOWERS]: require('../images/gifts/flowers.png'),
  [CHAMPAGNE]: require('../images/gifts/champagne.png'),
  [CHOCOLATE_STRAWBERRY]: require('../images/gifts/chocolateStrawberry.png'),
  [FIRE]: require('../images/gifts/fire.png'),
  [SMILE_KISS]: require('../images/gifts/smileKiss.png'),
  [HEART_ON_FIRE]: require('../images/gifts/heartOnFire.png'),
  [CATS_BLACK]: require('../images/gifts/catsBlack.png'),
  [CATS_GOLD]: require('../images/gifts/catsGold.png'),
  [HOME]: require('../images/gifts/home.png'),
  [JEEP]: require('../images/gifts/jeep.png'),
  [LOCK_AND_KEY]: require('../images/gifts/lockAndKey.png'),
  [STAR_GOLD]: require('../images/gifts/starGold.png'),
  [HOME_GOLD]: require('../images/gifts/homeGold.png'),
  [LOCK]: require('../images/gifts/lock.png'),
  [JEEP_GOLD]: require('../images/gifts/jeepGold.png'),
  [BOOT]: require('../images/gifts/boot.png'),
  [SWEET_BOX]: require('../images/gifts/sweetBox.png'),
  [STRAWBERRY]: require('../images/gifts/strawberry.png'),
  [RING]: require('../images/gifts/ring.png'),
  [PERL]: require('../images/gifts/perl.png'),
  [PERFUME]: require('../images/gifts/perfume.png'),
  [PENDANT_PINK]: require('../images/gifts/pendantPink.png'),
  [PENDANT_BLUE]: require('../images/gifts/pendantBlue.png'),
  [PANCAKES]: require('../images/gifts/pancakes.png'),
  [MACAROON]: require('../images/gifts/macaroon.png'),
  [KAMASUTRA]: require('../images/gifts/kamasutra.png'),
  [RED_HEART]: require('../images/gifts/redHeart.png'),
  [HANDCUFFS]: require('../images/gifts/handcuffs.png'),
  [GLASSES]: require('../images/gifts/glasses.png'),
  [FRUIT_BASKET]: require('../images/gifts/fruitBasket.png'),
  [COFFEE_WITH_HEART]: require('../images/gifts/coffeeWithHeart.png'),
  [CHOKER]: require('../images/gifts/choker.png'),
  [CHEESECAKE]: require('../images/gifts/cheesecake.png'),
  [CARNIVAL_MASK]: require('../images/gifts/carnivalMask.png'),
  [CAKE]: require('../images/gifts/cake.png'),
  [BOX]: require('../images/gifts/box.png'),
};
