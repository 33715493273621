import HorizontalWidgetAppearance from '@core/theming/constants/features/HorizontalWidgetAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43651
 */
type HorizontalWidgetComponent = any;

const resolver: ModuleResolver<
  HorizontalWidgetAppearance,
  HorizontalWidgetComponent
> = (value) => {
  switch (value) {
    case HorizontalWidgetAppearance.PARTIALLY_BORDERED:
      return import(
        /* webpackChunkName: 'horizontalWidgetBordered' */ '@phoenix/widgets/horizontalWidget/HorizontalWidgetBordered'
      );
    case HorizontalWidgetAppearance.BOUNDLESS:
      return import(
        /* webpackChunkName: 'horizontalWidget' */ '@phoenix/widgets/horizontalWidget/HorizontalWidget'
      );
    case HorizontalWidgetAppearance.WRAPPED_IN_CARD:
      return import(
        /* webpackChunkName: 'horizontalWidgetRounded' */ '@phoenix/widgets/horizontalWidget/HorizontalWidgetWrappedInCard'
      );
    case HorizontalWidgetAppearance.SPACED_SIDES:
      return import(
        /* webpackChunkName: 'horizontalWidgetSpacedSides' */ '@phoenix/widgets/horizontalWidget/HorizontalWidgetSpacedSides'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable horizontal widget based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const useThemedHorizontalWidget = () =>
  useThemeCompositeFeatureLoader<
    HorizontalWidgetAppearance,
    HorizontalWidgetComponent
  >('horizontalWidget', null, resolver);

export default useThemedHorizontalWidget;
