import get from 'lodash/get';

import {getClientInstance} from '@core/graphql/client';

import STORY_FRAGMENT from '../page/graphql/fragments/story.gql';
import TYPES from '../constants/storyPartTypes';
import SINGLE_STORY_QUERY from '../page/graphql/queries/singleStory.gql';
import getPartForCache from '../uploadPopup/utils/getPartForCache';

/**
 * Update cache for full video part and parts of video after divide
 * @param {string} userId
 * @param {string} id
 * @param {string} previewUrl
 * @param url
 * @param {boolean} isConverted
 * @param {number} duration
 * @param {number} partOfGroup - order video in group
 * @param {number} group
 * @param {string} error
 */
export const updateVideo = (
  userId,
  {
    id,
    previewUrl,
    url,
    isConverted,
    duration,
    partOfGroup = 0,
    group = 0,
    error,
  },
) => {
  const client = getClientInstance();
  const story = client.readFragment({
    id: `StoryItem:${userId}`,
    fragmentName: 'Story',
    fragment: STORY_FRAGMENT,
  });

  if (!story || !story.parts) {
    return;
  }

  const parts = [];
  story.parts.forEach((part) => {
    const videoId = group ? `${group}${partOfGroup}` : id;
    if (part.type === TYPES.VIDEO && part.video && part.video.id === videoId) {
      if (error) {
        // Remove videos with error
        return;
      }

      parts.push({
        ...part,
        id,
        group,
        order: partOfGroup,
        video: {
          ...part.video,
          id,
          url,
          previewUrl,
          isConverted,
          duration,
        },
      });
      return;
    }

    parts.push(part);
  });

  client.writeFragment({
    id: `StoryItem:${userId}`,
    fragmentName: 'Story',
    fragment: STORY_FRAGMENT,
    data: {
      ...story,
      parts,
    },
  });
};

export const removeStoryParts = ({userId, id, group}) => {
  const client = getClientInstance();
  const story = client.readFragment({
    id: `StoryItem:${userId}`,
    fragmentName: 'Story',
    fragment: STORY_FRAGMENT,
  });

  if (!story || !story.parts) {
    return;
  }

  // filter part by id or group
  const parts = story.parts.filter((part) => {
    if (id) return part.id !== id;
    if (group) return part.group !== group;
    return true;
  });

  client.writeFragment({
    id: `StoryItem:${userId}`,
    fragmentName: 'Story',
    fragment: STORY_FRAGMENT,
    data: {
      ...story,
      parts,
      partsCount: parts.length,
      areAllViewed: parts.every((part) => part.isViewed),
    },
  });
};

/**
 * Set preview parts after the event of count of group
 * The event of count of group will be able to coming after event of convert or decline
 * @param {string} userId
 * @param {number} group
 * @param {number} count
 */
export const setGroupPreviewsStoryParts = ({userId, group, count}) => {
  const client = getClientInstance();
  const data = client.readQuery({
    query: SINGLE_STORY_QUERY,
    variables: {viewedUserId: userId},
  });

  let parts = [];
  /**
   * If the uploaded video was longer then needed it was divided on some parts
   * Add set to cache all of this video parts
   */
  for (let i = 1; i <= count; i++) {
    parts.push(
      getPartForCache({
        storyPartType: TYPES.VIDEO,
        storyPartId: `${group}${i}`,
        group,
        order: i,
        createdAt: new Date().toISOString(),
        url: null,
        previewUrl: null,
        videoPreviewUrl: null,
        isConverted: false,
        duration: null,
      }),
    );
  }

  const partsInCache = get(data, 'stories.one.parts', []);
  const groupExistInCache = Boolean(
    partsInCache.find((part) => part.id === `${group}`),
  );

  partsInCache.forEach((part) => {
    if (part.id !== `${group}`) {
      /**
       * If an event of video converting come before the an event of count video parts,
       * need to set this part instead of preview part
       */
      if (part.group === group && part.order) {
        parts[part.order + 1] = part;
      } else {
        parts.push(part);
      }
    }
  });

  // If a decline of group had came before count of group - remove other preview
  if (group && !groupExistInCache) {
    parts = parts.filter((part) =>
      Boolean(part.type !== TYPES.VIDEO || part.video.url),
    );
  }

  client.writeQuery({
    query: SINGLE_STORY_QUERY,
    variables: {viewedUserId: userId},
    data: {
      ...data,
      stories: {
        ...data.stories,
        one: {
          ...(data.stories.one || {
            id: userId,
            areAllViewed: false,
          }),
          parts,
          partsCount: parts.length,
          __typename: 'StoryItem',
        },
      },
    },
  });
};
