import {makeVar} from '@apollo/client';

import POSITION_TYPES from '../../constants/headerPositionTypes';

/**
 * @type {Object}
 */
export const DEFAULT_PROPS = {
  withShadow: true,
  transparent: false,
  positionType: POSITION_TYPES.STICKY,
};

/**
 * Extensible object for the app header modifiers.
 */
const headerPropsVar = makeVar(DEFAULT_PROPS);

export default headerPropsVar;
