/**
 * @const {string}
 */
export const CHAT_TYPE = 'chatType';

/**
 * @const {string}
 */
export const PMA_MATCH = 'pmaMatch';

/**
 * @const {string}
 */
export const SEARCH_GRID = 'searchGrid';

/**
 * @const {string}
 */
export const SEARCH_MATCH = 'searchMatch';

/**
 * @const {string}
 */
export const PHOTO_DRUM = 'photoDrum';

/**
 * @const {string}
 */
export const PHOTO_BUBBLE_COLLAGE = 'photoBubbleCollage';

/**
 * @const {string}
 */
export const PHOTO_BUBBLE_ON_MAP = 'photoBubbleOnMap';

/**
 * @const {string}
 */
export const WEEK_SPEAK = 'weekSpeak';

/**
 * @const {string}
 */
export const WORLD_WIDE = 'worldWide';

/**
 * @const {string}
 */
export const FREE_PREMIUM = 'freePremium';

export const COINS_DISCOUNT = 'coinsDiscount';

export const REELS_3 = 'reels3';

export const REELS_4 = 'reels4';

/**
 * Back does not return this template. @see PaymentPageCoinsView
 * @const {string}
 */
export const UNSENT_MESSAGES = 'unsentMessages';

export const EXTRA_DISCOUNT = 'extraDiscount';

export const EXTRA_DAY = 'extraDay';

export const MICRO_FEATURE_PRIORITY_MAN = 'priorityMan';

/**
 * Template for Hybrid business model
 * @const {string}
 */
export const MOTIVATION_TITLE = 'motivationTitle';

/**
 * Template for PPC Hybrid business model
 * @see 'rnd.20241119.chernenko.TN_212904' split
 */
export const FREE_PREMIUM_TITLE = 'freePremiumTitle';

/**
 * A list of motivations in which the header is radically different in appearance from the default header
 * @type {(string)[]}
 */
export const MOTIVATIONS_WITH_HEADER_OVERLAY = [
  CHAT_TYPE,
  PMA_MATCH,
  REELS_3,
  REELS_4,
  SEARCH_GRID,
  SEARCH_MATCH,
  PHOTO_DRUM,
  PHOTO_BUBBLE_COLLAGE,
  PHOTO_BUBBLE_ON_MAP,
];

export const MOTIVATION_WITHOUT_SECOND_STEP = [
  ...MOTIVATIONS_WITH_HEADER_OVERLAY,
  EXTRA_DISCOUNT,
];
