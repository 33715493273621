/* eslint-disable react/default-props-match-prop-types */

import React, {createElement} from 'react';
import cn from 'classnames';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';

import useBabciaUBTrack from '@core/tracking/babcia/utils/useBabciaUBTrack';

import css from '../styles/Banner.css';

const Banner = ({
  bannerClassName,
  iframeReady,
  withBorder,
  fullsize,
  inline,
  wrapper,
  data,
  placementId,
  ...props
}) => {
  // Don't use AddBabciaUBTracking because it leads to duplicate requests
  const elementRef = useBabciaUBTrack(
    iframeReady ? `RMBanner_${placementId}` : null,
  );

  const renderIframe = () => {
    const {ifrSrc, iframeId, width, height} = data;
    // Extract possible other props like data-attributes
    const otherProps = omit(props, [
      'wrapper',
      'data',
      'iframeReady',
      'withBorder',
      'inline',
      'bannerClassName',
    ]);

    return (
      <iframe
        ref={elementRef}
        className={css.iframe}
        id={iframeId}
        src={ifrSrc}
        width={parseInt(width, 10)}
        height={parseInt(height, 10)}
        title="smartElements"
        scrolling="no"
        data-test="bannerIframe"
        {...otherProps}
      />
    );
  };

  return (
    <div
      className={cn(
        css.banner,
        bannerClassName,
        !iframeReady && css.hidden,
        withBorder && css.withBorder,
        fullsize && css.fullsize,
        // There are places where banner should be inline
        // @see SearchWidgetsDisplayer.js
        iframeReady && inline && css.inlineBanner,
      )}
      data-test="banner"
    >
      <div className={css.wrap}>
        {wrapper
          ? createElement(
              wrapper,
              {conceptType: data.conceptType},
              renderIframe(),
            )
          : renderIframe()}
      </div>
    </div>
  );
};

Banner.propTypes /* remove-proptypes */ = {
  wrapper: PropTypes.func,
  withBorder: PropTypes.bool,
  fullsize: PropTypes.bool,
  inline: PropTypes.bool,
  iframeReady: PropTypes.bool,
  bannerClassName: PropTypes.string,
  placementId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    conceptType: PropTypes.string,
    iframeId: PropTypes.string,
    ifrSrc: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  }).isRequired,
};

export default Banner;
