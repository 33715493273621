import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import getHistory from '@core/application/utils/getHistory';
import type {PopupOptions} from '@core/popup/types/PopupOptions';

import MicrofeaturesSuccessPopup from '../containers/MicrofeaturesSuccessPopup';

type OpenMicrofeaturesSuccessPopupOptions = {
  featureName?: string;
  returnPath?: string;
} & Partial<PopupOptions>;

/**
 * Show success popup after microfeature payment
 */
const openMicrofeaturesSuccessPopup = ({
  featureName,
  returnPath,
  ...options
}: OpenMicrofeaturesSuccessPopupOptions = {}) => {
  PopupService.openPopup(
    <MicrofeaturesSuccessPopup
      featureName={featureName}
      returnPath={returnPath}
    />,
    {
      small: true,
      trackingName: `microfeaturesSuccess_${featureName}`,
      autoLoadTracking: true,
      onClose: () => {
        returnPath && getHistory().replace(returnPath);
      },
      ...options,
    },
  );
};

export default openMicrofeaturesSuccessPopup;
