import React, {Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import isFunction from 'lodash/isFunction';
import {Field, getIn} from 'formik';

import {MultipleSelect} from '@phoenix/ui';

/**
 * Wrapper around select widget to make it working without
 * passing additional props with 'Formik' forms
 */
const FormMultipleSelect = ({
  validate,
  name,
  error,
  value,
  parser,
  children,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({field, form}) => {
      const formError = getIn(form.errors, name);
      const touched = getIn(form.touched, name);
      const restField = omit(field, 'onChange', 'value');
      const customValues = isFunction(value) ? value(field.value) : field.value;
      const defaultValue = String(children[0].props.value);

      const onChange = (event, values) => {
        const selectedValues = values.filter((v) => String(v) !== defaultValue);
        form.setFieldValue(
          name,
          isFunction(parser)
            ? parser(selectedValues, defaultValue)
            : selectedValues,
        );
      };

      return (
        <MultipleSelect
          onChange={onChange}
          {...restField}
          {...props}
          error={error || (touched && formError)}
        >
          {Children.map(children, (child) =>
            cloneElement(child, {
              active: customValues.length
                ? customValues.includes(String(child.props.value))
                : String(child.props.value) === defaultValue,
            }),
          )}
        </MultipleSelect>
      );
    }}
  </Field>
);

FormMultipleSelect.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.func,
  parser: PropTypes.func,
};

export default FormMultipleSelect;
