import {getClientInstance} from '@core/graphql/client';
import getConvertVideoSubscription from '@core/user/profile/current/utils/getConvertVideoSubscription';
import globalSettingsCacheWatcher from '@core/application/utils/globalSettingsCacheWatcher';

import AMOUNT_PART_GROUP_VIDEO_SUBSCRIPTION from '../graphql/subscriptions/amountPartGroupVideo.gql';
import STORIES_DECLINE_SUBSCRIPTION from '../graphql/subscriptions/storiesDecline.gql';
import DECLINED_GROUP_VIDEO_SUBSCRIPTION from '../graphql/subscriptions/declinedGroupVideo.gql';
import STORY_PART_ADDED_SUBSCRIPTION from '../graphql/subscriptions/storyPartAdded.gql';
import STORIES_AVAILABLE_QUERY from '../widget/graphql/queries/storiesAvailable.gql';
import {
  updateVideo,
  removeStoryParts,
  setGroupPreviewsStoryParts,
} from './cacheUpdaters';
import updateMyStoryData from '../uploadPopup/utils/updateMyStoryData';

const OBSERVABLES = {};
let startedListeners = false;

export const getStoriesDeclineSubscription = () => {
  OBSERVABLES.STORIES_DECLINE =
    OBSERVABLES.STORIES_DECLINE ||
    getClientInstance().subscribe({
      query: STORIES_DECLINE_SUBSCRIPTION,
    });
  return OBSERVABLES.STORIES_DECLINE;
};

export const getDeclinedGroupVideoSubscription = () => {
  OBSERVABLES.DECLINED_GROUP_VIDEO =
    OBSERVABLES.DECLINED_GROUP_VIDEO ||
    getClientInstance().subscribe({
      query: DECLINED_GROUP_VIDEO_SUBSCRIPTION,
    });
  return OBSERVABLES.DECLINED_GROUP_VIDEO;
};

const getAmountPartGroupVideoSubscription = () => {
  OBSERVABLES.AMOUNT_PART_GROUP_VIDEO =
    OBSERVABLES.AMOUNT_PART_GROUP_VIDEO ||
    getClientInstance().subscribe({
      query: AMOUNT_PART_GROUP_VIDEO_SUBSCRIPTION,
    });
  return OBSERVABLES.AMOUNT_PART_GROUP_VIDEO;
};

/**
 * Story can be not only uploaded by hand in stories widget.
 * It can be uploaded automatically from photo/video upload (in your profile).
 */
const getStoryUploadedSubscription = () => {
  OBSERVABLES.STORY_PART_ADDED =
    OBSERVABLES.STORY_PART_ADDED ||
    getClientInstance().subscribe({
      query: STORY_PART_ADDED_SUBSCRIPTION,
    });
  return OBSERVABLES.STORY_PART_ADDED;
};

const startStoriesListeners = async (client, userId) => {
  if (startedListeners) {
    return;
  }

  const {data} = await client.query({query: STORIES_AVAILABLE_QUERY});
  if (!data?.stories.available) {
    return;
  }

  startedListeners = true;

  getConvertVideoSubscription().subscribe(({data: {video}}) => {
    updateVideo(userId, video);
    updateMyStoryData(userId);
  });

  getStoriesDeclineSubscription().subscribe(
    ({
      data: {
        storyPart: {id},
      },
    }) => {
      id && removeStoryParts({userId, id});
    },
  );

  getDeclinedGroupVideoSubscription().subscribe(
    ({
      data: {
        declineVideoGroup: {group},
      },
    }) => {
      removeStoryParts({userId, group});
    },
  );

  getAmountPartGroupVideoSubscription().subscribe(
    ({
      data: {
        amountPartVideoGroup: {group, count},
      },
    }) => {
      setGroupPreviewsStoryParts({userId, group, count});
    },
  );

  getStoryUploadedSubscription().subscribe(
    ({
      data: {
        storyPartAdded: {userId: storyPartUserId},
      },
    }) => {
      updateMyStoryData(storyPartUserId);
    },
  );

  /**
   * Invalidate stories user data in case if global settings such as naughty mode changes
   */
  globalSettingsCacheWatcher(client, () => {
    client.cache.evict({id: 'Stories:{}', fieldName: 'list'});
  });
};

export default startStoriesListeners;
