import React from 'react';
import {useLocation} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {TOOLBAR_EXCLUDE_ROUTES} from '@core/header/constants/displayRules';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import isInRouteList from '@core/utils/routing/isInRouteList';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import ROUTES from '@phoenix/application/constants/routes';

import showBalanceHeaderInMessengerVar from '../graphql/vars/showBalanceHeaderInMessengerVar';
import CreditBalanceRefillBanner from './CreditBalanceRefillBanner';

/**
 * @const {Array.<string>}
 */
export const EXCLUDE_ROUTES = [
  // Since there is already motivation with current balance.
  '/freeCoins',
  // Where toolbar whould be hidden - such widget should hide too.
  ...TOOLBAR_EXCLUDE_ROUTES,
  ROUTES.DISCREET_PROFILES,
];

/**
 * It is a displayer for the refill banner which is situated below header
 * @see HeaderDisplayer
 * @returns {JSX.Element|null}
 * @constructor
 */
const CreditBalanceHeaderDisplayer = () => {
  const location = useLocation();
  const isPhone = usePhoneBreakpoint();
  /**
   * Need hide header with balance in messenger
   * @see startShowCoinsBuyOfferBannerListener
   */
  const showBalanceHeaderInMessenger = useReactiveVar(
    showBalanceHeaderInMessengerVar,
  );

  const excludeRoutes = showBalanceHeaderInMessenger
    ? EXCLUDE_ROUTES
    : [...EXCLUDE_ROUTES, '/chat/with'];

  if (isInRouteList(excludeRoutes, location.pathname) || !isPhone) {
    return null;
  }

  return (
    <BabciaScopedProvider context="creditBalanceHeader">
      <CreditBalanceRefillBanner />
    </BabciaScopedProvider>
  );
};

export default CreditBalanceHeaderDisplayer;
