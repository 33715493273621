import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import MountTracker from '@core/tracking/babcia/containers/MountTracker';

import {Loader, Row, RowLarge} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import {FormInput} from '@phoenix/form/input';

import {SECOND_STEP_FIELD_NAMES} from '../contants/fields';

/**
 * @see TrustedVerificationSmsSecondStep.js
 */
const TrustedVerificationSmsSecondStepLayout = ({
  handleSubmit,
  isSubmitting,
  smsInfo,
  actions,
}) => {
  return (
    <Loader active={isSubmitting}>
      <form
        onSubmit={handleSubmit}
        data-test="trustedVerificationSecondStep"
        id="trustedSecondStep"
      >
        <Row>
          <FormInput
            data-test={`${SECOND_STEP_FIELD_NAMES.CODE}Input`}
            name={SECOND_STEP_FIELD_NAMES.CODE}
            placeholder={t('paymentTrusted', 'text.enter.verification.code')}
            label={t('paymentTrusted', 'text.enter.verification.code')}
          />
        </Row>
        {smsInfo && <RowLarge>{smsInfo}</RowLarge>}
        {actions && <RowLarge align={Align.CENTER}>{actions}</RowLarge>}
      </form>
      <MountTracker name={MountTracker.NAMES.PAGE_LAYOUT} />
    </Loader>
  );
};

TrustedVerificationSmsSecondStepLayout.propTypes /* remove-proptypes */ = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  actions: PropTypes.node,
  smsInfo: PropTypes.node,
};

export default TrustedVerificationSmsSecondStepLayout;
