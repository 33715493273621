import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import type PhotoLevel from '@core/user/photo/constants/PhotoLevel';

import NaughtyModeSwitchConfirmationPopup from '@phoenix/naughtyMode/containers/NaughtyModeSwitchConfirmationPopup';
import {PHOTO} from '@phoenix/naughtyMode/constants/naughtyModePopupWidgetTypes';

type OpenNaughtyModePopupParams = {
  url?: string;
  level?: PhotoLevel;
  blurLevel?: PhotoLevel;
  onNaughtyModeChange?: () => void;
  /**
   * one of possible widget types: photo (by default), video, sticker (see: NaughtyModeSwitchConfirmationLayout.js)
   */
  widgetType?: string;
};

const openNaughtyModePopup = ({
  blurLevel,
  url,
  level,
  widgetType = PHOTO,
  onNaughtyModeChange,
}: OpenNaughtyModePopupParams): void => {
  PopupService.openPopup(
    <NaughtyModeSwitchConfirmationPopup
      blurLevel={blurLevel}
      widgetType={widgetType}
      url={url}
      level={level}
      onNaughtyModeChange={onNaughtyModeChange}
    />,
    {
      small: true,
      trackingName: 'naughtyModeSwitchConfirmation',
      trackingConceptId: widgetType,
      autoLoadTracking: true,
    },
  );
};

export default openNaughtyModePopup;
