/**
 * @const {Object}
 */
export const FIRST_STEP_FIELD_NAMES = {
  COUNTRY: 'country',
  COUNTRY_PHONE_CODE: 'countryPhoneCode',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LANGUAGE: 'language',
  PHONE: 'phoneNumber',
};

/**
 * @const {Object}
 */
export const SECOND_STEP_FIELD_NAMES = {
  CODE: 'code',
};

/**
 * @const {Number}
 */
export const MAX_LENGTH_FIELD = 30;
