import merge from 'lodash/merge';

import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';

import SEARCH_LIMIT_QUERY from '../graphql/queries/searchLimit.gql';
import type {SearchLimitQuery} from '../graphql/queries/searchLimit';

/**
 * Here we store any remembered search limit
 */
let storedLimit: number;

const storeSearchLimit = (newLimit: number): void => {
  storedLimit = newLimit;
};

/**
 * Updates search limit inside Apollo cache
 */
export const updateSearchLimit = (newLimit: number): void => {
  const client = getClientInstance();

  const data = client.readQuery<SearchLimitQuery>({query: SEARCH_LIMIT_QUERY});
  if (data) {
    storeSearchLimit(data.search.settings.searchLimit);

    client.writeQuery<SearchLimitQuery>({
      query: SEARCH_LIMIT_QUERY,
      data: merge({}, data, {search: {settings: {searchLimit: newLimit}}}),
    });
  }
  /**
   * Else do nothing since it indicates that query wasn't queried yet.
   * So when we go to search page - actual data will return with other fetched data.
   */
};

/**
 * Restores search limit as it was before last update
 */
export const restorePreviousSearchLimit = (): void => {
  if (!storedLimit) {
    logger.sendError(
      "Can't restore previous search limit. Use 'updateSearchLimit' before.",
    );
    return;
  }

  updateSearchLimit(storedLimit);
};

export default updateSearchLimit;
