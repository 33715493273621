import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {TRUSTED_STATUSES} from '@core/trusted/constants/status';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import type {CommonNotification} from '@core/systemNotifications/types';
import getTrustedStatusById from '@core/trusted/utils/getTrustedStatusById';

import getTrustedStatusSubscription from '@phoenix/user/profile/current/utils/getTrustedStatusSubscription';

const trustedDataSource = (): Observable<CommonNotification> =>
  from(getTrustedStatusSubscription()).pipe(
    filter(
      ({data}) =>
        getTrustedStatusById(data.user.trusted.sv) ===
        TRUSTED_STATUSES.VERIFIED,
    ),
    map(() => ({
      type: NOTIFICATION_TYPES.CHANGE_TRUSTED,
    })),
  );

export default trustedDataSource;
