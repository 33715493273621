import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import type Photo from '../types/Photo';
import PmaPhotoRequestPopup from '../containers/PmaPhotoRequestPopup';

type OpenPmaRequestPhotoPopupParams = {
  userId: string;
  photo: Photo;
};

const openPmaRequestPhotoPopup = ({
  userId,
  photo,
}: OpenPmaRequestPhotoPopupParams): void => {
  PopupService.openPopup(
    <PmaPhotoRequestPopup userId={userId} photo={photo} />,
    {
      small: true,
      trackingName: 'pmaPhotoRequest',
    },
  );
};

export default openPmaRequestPhotoPopup;
