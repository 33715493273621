import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import LEVEL from '@core/user/photo/constants/PhotoLevel';
import PRIMARY_PHOTO from '@core/user/profile/current/graphql/queries/currentUserPrimaryPhoto.gql';
import setPrimaryPhoto from '@core/user/profile/current/utils/setPrimaryPhoto';

import deletePhoto from '@phoenix/user/profile/current/utils/deletePhoto';
import PhotoControls from '@phoenix/gallery/components/PhotoControls';

const useIsPrimary = (currentMediaId) => {
  const {data, loading, error} = useQuery(PRIMARY_PHOTO);

  if (loading || error) return {loading, error};

  const {
    myUser: {
      profile: {
        photos: {primaryPhoto},
      },
    },
  } = data;
  const isPrimary = (primaryPhoto && primaryPhoto.id) === currentMediaId;

  return {isPrimary, loading, error};
};

const ProfileGalleryPhotoControls = ({currentMedia}) => {
  const {id, photo} = currentMedia;
  const {isPrimary, loading, error} = useIsPrimary(id);
  const handleDeleteClick = useCallback(() => deletePhoto(id), [id]);
  const handleSetPrimaryClick = useCallback(() => setPrimaryPhoto(id), [id]);

  if (loading || error) return null;

  return (
    <PhotoControls
      isPrimary={isPrimary}
      onDeleteClick={handleDeleteClick}
      onSetPrimaryClick={handleSetPrimaryClick}
      pendingDelete={photo.pendingDelete}
    />
  );
};

ProfileGalleryPhotoControls.propTypes /* remove-proptypes */ = {
  currentMedia: PropTypes.shape({
    id: PropTypes.string.isRequired,
    video: PropTypes.shape({
      previewUrl: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      level: PropTypes.oneOf(Object.values(LEVEL)),
    }),
    photo: PropTypes.shape({
      id: PropTypes.string.isRequired,
      level: PropTypes.oneOf(Object.values(LEVEL)),
      censored: PropTypes.bool, // @todo When we migrate to new current user API - make this field required
      updatedOn: PropTypes.string.isRequired,
      isApprovedFromMessenger: PropTypes.number,
      pendingDelete: PropTypes.bool,
    }),
  }).isRequired,
};

export default ProfileGalleryPhotoControls;
