import getCountryISO2 from 'country-iso-3-to-2';

import logger from '@core/logger';
import getPayMethodWithBackboneModel from '@core/payment/payProcess/utils/getPayMethodWithBackboneModel';
import API_VERSION_PARAMS from '@core/payment/widgets/googlePay/constants/apiVersionParams';
import googlePayMaskedCardVar from '@core/graphql/vars/googlePayMaskedCardVar';
import getGooglePayPaymentsClient from '@core/payment/widgets/googlePay/utils/getGooglePayPaymentsClient';
import {PaymentFlowStatus} from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import {openGooglePayCvvForm} from '@core/payment/forms/googlePay/utils/useIsGooglePayCvvForm';
import getFormName from '@core/payment/payProcess/utils/getFormName';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import CardWalletModel from '@legacy/application/components/paymentPageForm/models/CardWalletModel';

const STATUS_CODE_CANCELED = 'CANCELED';

/**
 * Cache for wallet data send when response from BU need to clarification cvv
 * @type {null|Object}
 */
let cachedPaymentMethodData = null;

/**
 * @deprecated Use googlePay.ts
 */
const googlePayLegacy: PaymentProcessStep = async (data) => {
  const {paymentData} = data;
  const {
    action,
    method = PAYMENT_METHODS.GOOGLE_PAY,
    via,
    activePackage: {
      stockId,
      packageId,
      tokenPrice: {amount, currencyCode},
    },
    formName,
    country,
    siteName,
    settings,
    urlParams,
    replaceRedirectToPostMessage,
    ...props
  } = paymentData;

  const {
    merchantId,
    merchantName,
    gateway,
    gatewayMerchantId,
    allowedAuthMethods,
    allowedCardNetworks,
  } = settings;

  const pay = getPayMethodWithBackboneModel({
    method,
    action,
    // @ts-expect-error method will be transferred to enum soon
    formName: formName || getFormName({method}),
  });

  const googlePayClient = await getGooglePayPaymentsClient(settings);

  const paymentDataRequest = {
    ...API_VERSION_PARAMS,
    allowedPaymentMethods: [
      {
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway,
            gatewayMerchantId,
          },
        },
        type: 'CARD',
        parameters: {
          allowedAuthMethods,
          allowedCardNetworks,
        },
      },
    ],
    merchantInfo: {
      merchantName,
      merchantId,
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPrice: amount,
      currencyCode,
      countryCode: getCountryISO2(country),
    },
  };

  try {
    // Google api pay
    const paymentMethodData =
      cachedPaymentMethodData ||
      (await googlePayClient.loadPaymentData(paymentDataRequest))
        .paymentMethodData;

    const {
      tokenizationData: {token},
    } = paymentMethodData;

    if (!googlePayMaskedCardVar()) {
      const maskedCard = await new CardWalletModel().getCard(token, method);

      if (maskedCard) {
        cachedPaymentMethodData = paymentMethodData;
        openGooglePayCvvForm(maskedCard);
        return {...data, flowStatus: PaymentFlowStatus.ABORTED};
      }
    }

    let result;

    try {
      // Send pay data to phoenix backend (after api pay success)
      result = await pay({
        stockId,
        packageId,
        country,
        currency_code: currencyCode,
        domain: siteName,
        prevVia: via,
        hidePaymentForm: 0,
        walletToken: JSON.stringify(paymentMethodData),
        ...props,
      });
    } catch (error) {
      result = error;
    }

    return {
      ...data,
      paymentResult: {
        ...result,
        method,
        replaceRedirectToPostMessage,
      },
    };
  } catch (error) {
    cachedPaymentMethodData = null;

    // If pay popup closed
    if (error.statusCode === STATUS_CODE_CANCELED) {
      return {...data, flowStatus: PaymentFlowStatus.ABORTED};
    }

    logger.sendError(
      `[googlePay] Can't pay by google pay api: ${JSON.stringify(error)}`,
    );

    return {...data, paymentResult: {status: false}};
  }
};

export default googlePayLegacy;
