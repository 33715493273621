import isCoinsModel from '@phoenix/coins/utils/isCoinsModel';

import CREDITS_BALANCE_INCREASE_SUBSCRIPTION from '../graphql/subscriptions/creditsBalanceIncrease.gql';
import CREDITS_BALANCE_DECREASE_SUBSCRIPTION from '../graphql/subscriptions/creditsBalanceDecrease.gql';
import updateCreditsBalance from './updateCreditsBalance';

let startedListeners = false;

/**
 * Use this subscription like global. We have a lot of logic which use credit balance.
 * @see freecoins, coins, microfeatures, camstreaming.
 * @param client
 */
const startCreditsListeners = async (client) => {
  if (startedListeners) {
    return;
  }

  const isCoins = await isCoinsModel();

  if (!isCoins) {
    return;
  }

  startedListeners = true;

  client
    .subscribe({
      query: CREDITS_BALANCE_INCREASE_SUBSCRIPTION,
    })
    .subscribe(
      ({
        data: {
          credits: {balanceIncrease},
        },
      }) => {
        updateCreditsBalance(balanceIncrease);
      },
    );

  client
    .subscribe({
      query: CREDITS_BALANCE_DECREASE_SUBSCRIPTION,
    })
    .subscribe(
      ({
        data: {
          credits: {balanceDecrease},
        },
      }) => updateCreditsBalance(balanceDecrease),
    );
};

export default startCreditsListeners;
