import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';
import fetchData from '@core/application/utils/fetchData';

const remarketingPopupCancelSubscription: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;
  const {cancelRemarketingSubscription} = paymentData;
  const {orderId} = paymentResult;

  if (cancelRemarketingSubscription && orderId) {
    // Need wait cancel request before redirect to payment page
    await fetchData({
      url: '/api/v1/remarketingPopup/cancel',
      body: {orderId},
    });
  }

  return data;
};

export default remarketingPopupCancelSubscription;
