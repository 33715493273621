import React, {lazy} from 'react';

import {Loader} from '@phoenix/ui';
import ThemedTargetUserPage from '@phoenix/theming/components/ThemedTargetUserPage';

import SplitChunk from '../components/SplitChunk';

/**
 * Don't worry, "ThemedTargetUserPage" already splits component in different chunks
 */
export const UserChunk = (props) => (
  <ThemedTargetUserPage placeholder={<Loader fullscreen />} {...props} />
);

const LazyMassBlockedPopup = lazy(
  () =>
    import(
      /* webpackChunkName: 'massBlocked' */ '@phoenix/massBlocked/components/MassBlockedPage'
    ),
);

export const MassBlockedChunk = (props) => (
  <SplitChunk component={LazyMassBlockedPopup} {...props} />
);
