/* eslint-disable import/prefer-default-export */

import React from 'react';

import ThemedSearchLivecamPage from '@phoenix/theming/components/ThemedSearchLivecamPage';

/**
 * Special 'livecam' search page. It looks like search but it uses another data as source
 * and have a little 'cutted' functionality.
 */
export const SearchLivecamChunk = (props) => (
  <ThemedSearchLivecamPage {...props} />
);
