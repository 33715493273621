import React, {useEffect, useContext, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {settingsPropType} from '@core/mediaUpload/constants/propTypes';
import useUploadFilesMultiple from '@core/mediaUpload/utils/useUploadFilesMultiple'; // eslint-disable-line import/no-cycle
import MediaUploadPopupDisplayer from '@core/mediaUpload/containers/MediaUploadPopupDisplayer';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import PopupContext from '@core/popup/containers/PopupContext';
import t from '@core/translations/translate';
import {getClientInstance} from '@core/graphql/client';

import openMediaUploadDisabledPopup from '@phoenix/mediaUpload/utils/openMediaUploadDisabledPopup';
import {Loader} from '@phoenix/ui';
import UploadErrorsInline from '@phoenix/mediaUpload/components/UploadErrorsInline';
import openErrorsPopup from '@phoenix/mediaUpload/utils/openErrorsPopup';
import {SpacingSize} from '@phoenix/ui/constants';
import savePmaSettings from '@phoenix/pma/utils/savePmaSettings';

import MediaUploadPopupLayout from '../components/MediaUploadPopupLayout';
import useCloseButtonAllowedOnPhotoUpload from '../utils/useCloseButtonAllowedOnPhotoUpload';
import MediaUploadMotivationUserPhotoList from '../components/MediaUploadMotivationUserPhotoList';
import PhotoUploadActions from './PhotoUploadActions';
import MediaUploadMotivationPma from '../components/MediaUploadMotivationPma';

const PmaPhotoUploadPopup = ({settings, onClose}) => {
  const [texts, setTexts] = useState({
    title: t('funnelPhotoUploadMotivation', 'title.well_done'),
    description: t('funnelPhotoUploadMotivation', 'text.add_more'),
  });

  const handleAgree = async (data) => {
    await savePmaSettings(getClientInstance(), {
      activated: true,
    });

    onClose && onClose(data);
  };

  const handleSuccess = () => {
    setTexts({
      title: t('funnelPhotoUploadMotivation', 'title.amazing'),
      description: t('funnelPhotoUploadMotivation', 'text.more_photo'),
    });
  };

  const {source, multiple, accept} = settings;

  const uploadRenderer = useCallback(
    (props) => (
      <PhotoUploadActions
        multiple={multiple}
        accept={accept}
        spacing={SpacingSize.NONE}
        {...props}
      />
    ),
    [multiple, accept],
  );

  Object.assign(settings, {
    onSuccess: handleSuccess,
  });

  const {isUploading, files, setFiles, errors, clearErrors} =
    useUploadFilesMultiple({
      settings,
      uploadRenderer,
      openErrorsPopup,
      isProfileVideoUploadDisabled: true,
      openMediaUploadDisabledPopup,
      needCloseAfterSuccess: false,
    });

  const {
    setPopupProps,
    options: {showCloseButton},
  } = useContext(PopupContext);

  const available = useCloseButtonAllowedOnPhotoUpload({
    source,
    errors,
  });

  /**
   * Set photo upload popup close button visibility
   * showCloseButtonOption used for correctly update popup props when openErrorsPopup is called.
   * openErrorsPopup triggered setPopupProps with wrong values.
   */
  useEffect(() => {
    available && setPopupProps({showCloseButton: true});
  }, [available, setPopupProps, showCloseButton]);

  return (
    <BabciaScopedProvider conceptId="mediaUploadMotivationPma">
      <MediaUploadPopupDisplayer
        loaderComponent={Loader}
        uploadErrorsInlineComponent={UploadErrorsInline}
        openErrorsPopup={openErrorsPopup}
        layoutComponent={(props) => (
          <MediaUploadPopupLayout
            contentSpace={SpacingSize.NORMAL}
            withoutTop={false}
            {...props}
          />
        )}
        settings={{
          ...settings,
          onSuccess: handleSuccess,
        }}
        pmaCheckbox={
          <MediaUploadMotivationPma onClose={onClose} onAgree={handleAgree} />
        }
        isUploading={isUploading}
        onFileSelect={setFiles}
        errors={errors}
        clearErrors={clearErrors}
        filesCount={files.length}
        motivation={
          <MediaUploadMotivationUserPhotoList
            onFileSelect={setFiles}
            accept={accept}
            multiple={multiple}
            texts={texts}
          />
        }
        uploadRenderer={() => {}}
        webcamRenderer={() => {}}
        previewRenderer={() => {}}
      />
    </BabciaScopedProvider>
  );
};

PmaPhotoUploadPopup.propTypes /* remove-proptypes */ = {
  settings: settingsPropType.isRequired,
  onClose: PropTypes.func,
};

export default PmaPhotoUploadPopup;
