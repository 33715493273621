import React, {lazy, Suspense} from 'react';

import PopupService from '@core/popup/utils/PopupService';

import {Loader} from '@phoenix/ui';

const MassMailingTimeoutPopupContent = lazy(
  () =>
    import(
      /* webpackChunkName: 'massMailingTimeoutPopup' */ './MassMailingTimeoutPopup'
    ),
);

const openMassMailingTimeoutPopup = (userId, timeout) => {
  PopupService.openPopup(
    <Suspense fallback={<Loader />}>
      <MassMailingTimeoutPopupContent userId={userId} timeout={timeout} />
    </Suspense>,
    {
      small: true,
      trackingName: 'massMailingTimeout',
    },
  );
};

export default openMassMailingTimeoutPopup;
