import defer from 'lodash/defer';

import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';
import {getClientInstance} from '@core/graphql/client';
import getHistory from '@core/application/utils/getHistory';
import parsePathname from '@core/utils/url/parsePathname';
import getDefaultPage from '@core/utils/routing/getDefaultPage';

import openCoinsSuccessPopup from '@phoenix/coins/utils/openCoinsSuccessPopup';
import getPaymentSuccessXSaleUrl from '@phoenix/payment/pages/utils/getPaymentSuccessXSaleUrl';
import PAYMENT_SUCCESS_DATA_QUERY from '@phoenix/payment/pages/graphql/queries/paymentSuccessData.gql';

const processCoinsSuccess = async () => {
  const orderIds = PaymentPageSuccessOrders.getNotViewedOrderIds();

  let xSaleAutologinUrl;
  let resultPackages;
  let defaultRoute;

  if (orderIds.length) {
    const {data} = await getClientInstance().query({
      query: PAYMENT_SUCCESS_DATA_QUERY,
      variables: {orderIds},
    });

    const successData = data?.payment?.successData || {};

    xSaleAutologinUrl = successData?.xSaleAutologinUrl;
    resultPackages = successData?.resultPackages;
    defaultRoute = data?.site.defaultRoute;
  } else if (!xSaleAutologinUrl) {
    xSaleAutologinUrl = await getPaymentSuccessXSaleUrl();
  }

  if (!defaultRoute) {
    defaultRoute = await getDefaultPage();
  }

  PaymentPageSuccessOrders.setViewed();

  const {
    options: {successReturnPath, returnPath},
  } = parsePathname(window.location);

  const onClose = () => {
    const returnRoute = successReturnPath || returnPath;

    if (xSaleAutologinUrl) {
      window.open(returnRoute || defaultRoute);
      window.location.replace(xSaleAutologinUrl);
      return;
    }

    const history = getHistory();

    if (returnRoute) {
      history.push(returnRoute);
      return;
    }

    history.push(defaultRoute);
  };

  // After pay success before open this popup need close PaymentPageExternalPopupView
  defer(() => {
    openCoinsSuccessPopup({
      packages: resultPackages || [],
      onClose,
    });
  });
};

export default processCoinsSuccess;
