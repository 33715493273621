import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import {Card, Spacing, ButtonPrimary, Group, SpacingLarge} from '@phoenix/ui';
import {TextWithIcon, H3, Link} from '@phoenix/typography';
import CoinColorizedIconByAmount from '@phoenix/credits/components/CoinColorizedIconByAmount';
import PhotosListAsRoulette from '@phoenix/user/photo/components/PhotosListAsRoulette';

const CoinsRewardPopupLayout = ({users, reward, onAccept, onDeny}) => {
  return (
    <PopupFrame
      spacedContent={false}
      content={
        <PopupLoadTracker>
          <SpacingLarge onlyTop adaptive={false}>
            <SpacingLarge withoutTop withoutBottom adaptive>
              {users && <PhotosListAsRoulette users={users} />}
              <Spacing onlyTop={!reward} withoutLeft withoutRight>
                <H3 align={Align.CENTER}>
                  {t(
                    'webPushNotification',
                    'title.signUpForWebPushesToGetNotified',
                  )}
                </H3>
              </Spacing>
            </SpacingLarge>
          </SpacingLarge>
          {Boolean(reward) && (
            <Card highlighted boundless shadowLevel={0}>
              <Spacing>
                <TextWithIcon
                  space={SpacingSize.SHORT}
                  align={Align.CENTER}
                  icon={
                    <CoinColorizedIconByAmount
                      size={CoinColorizedIconByAmount.SIZE.MEDIUM}
                    />
                  }
                >
                  {t('webPushNotification', 'text.getCoinsAdAGift', {
                    '{amount}': reward,
                  })}
                </TextWithIcon>
              </Spacing>
            </Card>
          )}
        </PopupLoadTracker>
      }
      actions={
        <Group align={Align.AROUND}>
          <ButtonPrimary
            fullWidth
            adaptive
            onClick={onAccept}
            trackingName="accept"
          >
            {t('webPushNotification', 'button.signMeUp')}
          </ButtonPrimary>
          <Link muted underline onClick={onDeny} trackingName="deny">
            {t('webPushNotification', 'button.illDoItLater')}
          </Link>
        </Group>
      }
    />
  );
};

CoinsRewardPopupLayout.propTypes /* remove-proptypes */ = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ),
  reward: PropTypes.number,
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
};

export default CoinsRewardPopupLayout;
