import {useHistory, useLocation} from 'react-router-dom';

import logger from '@core/logger';
import useEventCallback from '@core/utils/react/useEventCallback';

import useFunnelStack from './useFunnelStack';

/**
 * Find index of route inside stack.
 * We should find index of original route name, without additional params inside,
 * e.g. if pathname is "/photoUploadMotivation/index/bar/1/baz/2" we should try to find "/photoUploadMotivation"
 * @param {Array} stack
 * @param {String} route
 * @returns {Number}
 */
const getStackIndexForRoute = (stack, route) =>
  // 'startsWith' is used since we have legacy URL with params like '/funnel/index/showClose/false/doSomething/true'
  stack.findIndex((url) => url === route || route.startsWith(url));

/**
 * Hook for encapsulating all "next" step logic to funnel related functionality.
 * Returns function that, on calling, performs redirect to next step if it is
 * possible, otherwise to default route.
 *
 * @param {Boolean} active - if "false" nothing starts. Avoid making requests if we are not on "funnel" step (@see RouteWithPopupDisplayer.js)
 * @returns {Object} result
 * @returns {Function} result.goToNextStep
 * @returns {String|null} result.nextStep
 * @returns {Array} result.stack
 * @returns {Boolean} result.loading
 */
const useFunnelSteps = (active = true) => {
  const history = useHistory();
  const {pathname, search} = useLocation();

  const {stack, loading} = useFunnelStack(active);
  const index = getStackIndexForRoute(stack, pathname + search);
  /**
   * Be aware that if you are on non-funnel step, function
   * invocation will lead you on first funnel step from stack
   */
  const nextStep = stack[index + 1] || null;

  /**
   * If we use regular 'useCallback' - effect will be invalidated
   * @see src/packages/dating/application/containers/RouteWithPopupDisplayer.js
   */
  const goToNextStep = useEventCallback(() => {
    /*
     * useHistory: https://reactrouter.com/core/api/history
     * useLocation: https://reactrouter.com/core/api/location
     * History object is mutable and location doesn't mutable, that's why after user go to previous route
     * we can compare pathname from history.location.pathname (where will be new url) and location.pathname
     * (where is being old url).
     * If user wants go to previous funnel step, we'll need to prevent pushing new route.
     */
    if (!active || history.location.pathname !== pathname) {
      return;
    }

    if (stack.length === index + 1) {
      logger.sendWarning(
        `[useFunnelStep] Trying to get next URL after passing all funnel steps (route: ${pathname})`,
      );
      return;
    }

    history.push(nextStep);
  });

  return {goToNextStep, nextStep, stack, loading};
};

export default useFunnelSteps;
