import updateRecipientMessagePermissions from '@core/messenger/common/utils/updateRecipientMessagePermissions';
import updateMessageDataInCache from '@core/messenger/common/utils/updateMessageDataInCache';

import isCreditsLimitReason from '../../common/utils/isCreditsLimitReason';
import updateGlobalFreeMessagesTimeout from './updateGlobalFreeMessagesTimeout';

/**
 * Update permissions
 * @param {ApolloClient} client
 * @param {string} recipientId
 * @param {boolean} canSendNext
 * @param data
 */
const updatePermissionsForSendMessage = ({
  client,
  recipientId,
  type,
  direction,
  countFreeMessages,
  freeAssistantMessages,
  canSendNext,
  reason,
  needSendFirst,
  freeMessagesTimeout,
}) => {
  /**
   * @see src/packages/websocket/resolvers/messenger.js
   */
  if (isCreditsLimitReason(reason)) {
    updateRecipientMessagePermissions({
      client,
      userId: recipientId,
      canSendNext,
      reason,
    });
  } else {
    updateMessageDataInCache({
      userId: recipientId,
      message: {type, direction, countFreeMessages, freeAssistantMessages},
      client,
      canSendNext,
      needSendFirst,
      reason,
    });
    updateGlobalFreeMessagesTimeout({freeMessagesTimeout});
  }
};

export default updatePermissionsForSendMessage;
