/**
 * Size of colorized icon
 * @see CoinColorizedIconByAmount.js
 * @see CoinColorizedIcon.js
 */
export default {
  NONE: 'none', // Block will use all available size
  SMALLEST: 'smallest',
  SMALL: 'small',
  MEDIUM: 'medium',
  BIG: 'big',
  LARGE: 'large',
  HUGE: 'huge',
};
