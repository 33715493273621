import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyWorldWideSearch = lazy(
  () =>
    import(/* webpackChunkName: 'worldwide' */ 'src/packages/dating/worldWide'),
);

export default (props) => (
  <SplitChunk component={LazyWorldWideSearch} {...props} />
);
