import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import CoinColorizedIconByAmount from '@phoenix/credits/components/CoinColorizedIconByAmount';
import {TextMuted, TextWithIcon} from '@phoenix/typography';
import {TagPrimary} from '@phoenix/ui';
import {Align, SpacingSize, VerticalAlign} from '@phoenix/ui/constants';

import Gift from './Gift';
import css from '../styles/GiftList.css';

const GiftsList = ({
  gifts,
  onSelect,
  inline,
  small,
  align = Align.BETWEEN,
  adaptive = true,
  selectId,
}) => {
  const icon = <CoinColorizedIconByAmount inline textTop={false} />;

  return (
    <div className={cn(css.container, css[align], inline && css.inline)}>
      {gifts.map(({id, price, freeAmount}) => (
        <AddBabciaUBTracking
          key={id}
          trackingName={onSelect ? 'selectGift' : null}
        >
          <div
            data-test="giftsItem"
            className={cn(
              css.gift,
              small ? css.small : css.default,
              inline && css.inlineItem,
              selectId === id && css.active,
            )}
            role="button"
            tabIndex={0}
            onClick={() => onSelect && onSelect(id)}
          >
            <Gift id={id} className={css.image} />
            {freeAmount ? (
              <TagPrimary>
                {`${freeAmount > 1 ? `+${freeAmount} ` : ''}${t(
                  'gifts',
                  'text.free',
                )}`}
              </TagPrimary>
            ) : (
              <TextWithIcon
                icon={icon}
                align={Align.CENTER}
                vertical={false}
                space={SpacingSize.SHORT}
                verticalAlign={VerticalAlign.CENTER}
              >
                <TextMuted small>{price}</TextMuted>
              </TextWithIcon>
            )}
          </div>
        </AddBabciaUBTracking>
      ))}
      {!inline && adaptive && (
        <div
          data-test="giftsLastItem"
          className={cn(css.gift, small ? css.small : css.default, css.last)}
          key="last"
        />
      )}
    </div>
  );
};

GiftsList.propTypes /* remove-proptypes */ = {
  gifts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
  inline: PropTypes.bool, // list in one line with scroll
  small: PropTypes.bool,
  adaptive: PropTypes.bool,
  align: PropTypes.oneOf([Align.CENTER, Align.BETWEEN]),
  selectId: PropTypes.string,
};

export default GiftsList;
