import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyVideoChat = lazy(
  () =>
    import(
      /* webpackChunkName: 'videoChat' */ '@phoenix/videoChat/containers/VideoChat'
    ),
);

const VideoChatChunk = (props) => (
  <SplitChunk component={LazyVideoChat} {...props} />
);

export default VideoChatChunk;
