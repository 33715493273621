import {useQuery} from '@apollo/client';

import {MODEL_TYPES} from '../constants/modelTypes';
import MICROFEATURES_QUERY from '../graphql/queries/microfeatures.gql';
import MICROFEATURES_LIST_QUERY from '../graphql/queries/microfeaturesList.gql';

/**
 * @param {string} modelType
 * @param {Object} data
 * @param {boolean} data.skip
 * @return {Object}
 */
const useMicrofeaturesData = (modelType, {skip} = {}) => {
  const {loading, error, data} = useQuery(MICROFEATURES_QUERY, {
    skip: skip || !modelType,
  });
  const {
    loading: listLoading,
    error: listError,
    data: listData,
  } = useQuery(MICROFEATURES_LIST_QUERY, {
    variables: {
      isCreditModel: modelType === MODEL_TYPES.CREDIT,
    },
    skip: skip || !modelType,
  });

  return {
    loading: loading || listLoading,
    error: error || listError,
    microfeaturesList: listData?.microFeatures || {},
    data: data || {},
    sexAlarm: data?.microFeatures?.sexAlarm || {},
  };
};

export default useMicrofeaturesData;
