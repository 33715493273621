import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import usePreventIOSSelectError from '@core/utils/select/usePreventIOSSelectError';

import FormSelectItem from './FormSelectItem';
import FormSelect from './FormSelect';
import css from './styles/FormSelectRange.css';

const FormSelectRange = ({selectData, label}) => {
  const {isIOS, onBlur, onChange, onTouchStart, isIOSError} =
    usePreventIOSSelectError();

  /**
   * Needed in case when we have iOS picker keyboard.
   * To prevent issue with select data change
   */
  if (isIOS) {
    selectData.forEach((item) =>
      Object.assign(item, {onChange, onBlur, isIOSError, onTouchStart}),
    );
  }

  return (
    <div className={css.wrap}>
      {selectData.map(({from, to, name, ...rest}, index) => (
        <FormSelect
          key={name}
          name={name}
          className={css.select}
          parser={Number}
          // Since empty line will be interpreted as existing label
          // and additional space will be added.
          label={index === 0 ? label : '\u00A0'}
          {...rest}
        >
          {range(from, to).map((value) => (
            <FormSelectItem value={value} key={value}>
              {value}
            </FormSelectItem>
          ))}
        </FormSelect>
      ))}
      <div className={css.dash}> — </div>
    </div>
  );
};

const selectPropTypes = PropTypes.shape({
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

FormSelectRange.propTypes /* remove-proptypes */ = {
  /**
   * array "selectData" consists of two "selectPropTypes"
   * because in the "FormSelectRange" renders two select
   */
  selectData: PropTypes.arrayOf(selectPropTypes, selectPropTypes).isRequired,
  label: PropTypes.string.isRequired,
};

export default FormSelectRange;
