import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import LOGO_SIZE from '@core/logo/constants/logoSize';

import {Button, Group, Icon} from '@phoenix/ui';
import {SpacingSize, IconSize, Align} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import Logo from '@phoenix/logo/components/Logo';

import css from '../styles/ProgressiveWebApplicationMotivationLayout.css';

const ProgressiveWebApplicationMotivationLayout = ({
  onButtonClick,
  siteName,
}) => (
  <PopupNotice
    adaptive
    size={SpacingSize.LARGE}
    icon={<Logo size={LOGO_SIZE.LARGE} fullWidth inverse clickable={false} />}
    title={t('progressiveWebApplication', 'title.add_to_hs', {
      '{siteName}': siteName,
    })}
    subTitle={t('progressiveWebApplication', 'title.unlock', {
      '{siteName}': siteName,
    })}
    subTitleMuted
    description={
      <Group space={SpacingSize.SHORT}>
        <div>
          {t('progressiveWebApplication', 'text.step_one')}
          <span className={css.iconWrapper}>
            <Icon type="share" size={IconSize.SIZE_24} />
          </span>
        </div>
        {t('progressiveWebApplication', 'text.step_two')}
        {t('progressiveWebApplication', 'text.step_three')}
      </Group>
    }
    descriptionSpacing={SpacingSize.NORMAL}
    descriptionAlign={Align.LEFT}
    actions={[
      <Button inline fullWidth onClick={onButtonClick} trackingName="gotIt">
        {t('progressiveWebApplication', 'button.got_it')}
      </Button>,
    ]}
  />
);

ProgressiveWebApplicationMotivationLayout.propTypes /* remove-proptypes */ = {
  onButtonClick: PropTypes.func.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default ProgressiveWebApplicationMotivationLayout;
