import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useReactiveVar} from '@apollo/client';

import isSearchPreloadEnabled from '@core/search/page/utils/isSearchPreloadEnabled';
import {CONTENT_CONTAINER} from '@core/application/constants/layout';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import isAppLayoutSpacedBottomVar from '@core/graphql/vars/isAppLayoutSpacedBottomVar';
import isTikTokBottomSpacingVar from '@core/graphql/vars/isTikTokBottomSpacingVar';
import useIsAppLayoutScrollable from '@core/application/utils/useIsAppLayoutScrollable';
import {MAIN_LAYOUT_ID} from '@core/application/constants/appLayout';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import {SPLITS, getActiveSplitGroup} from '@core/utils/split';
import {setAppSize} from '@core/application/containers/CSSAppSizeDetector';

import DelayedRenderWrapper from '@phoenix/delayedRender/containers/DelayedRenderWrapper';
import ThemeFeaturesPreloader from '@phoenix/theming/components/ThemeFeaturesPreloader';
import ThemedStickedNotifications from '@phoenix/theming/components/ThemedStickedNotifications';
import ThemedActivityBookmark from '@phoenix/theming/components/ThemedActivityBookmark';
import PaymentBottomNoticeDisplayer from '@phoenix/payment/banners/bottomNotice/containers/PaymentBottomNoticeDisplayer';
import {Card, Loader} from '@phoenix/ui';
import PopupDisplayer from '@phoenix/popup/containers/PopupDisplayer';
import HeaderDisplayer from '@phoenix/header/containers/HeaderDisplayer';
import Footer from '@phoenix/footer/containers/Footer';
import PreloadThemedComponentsPage from '@phoenix/theming/components/PreloadThemedComponentsPage';
import PreloadThemedCompactComponentsPage from '@phoenix/theming/components/PreloadThemedCompactComponentsPage';
import ProgressiveWebApplication from '@phoenix/progressiveWebApplication/components/ProgressiveWebApplication';

import Routes from '../containers/Routes';
import css from '../styles/AppLayout.css';
import DraggableMiniMessengerChunk from '../chunks/DraggableMiniMessengerChunk';

/**
 * @const {Boolean}
 */
const IS_MIUI_BROWSER =
  getUserAgentParser().getBrowser().name === 'MIUI Browser';

const AppLayoutBaseContent = ({loading}) => {
  return (
    <div id={CONTENT_CONTAINER}>
      {!loading || isSearchPreloadEnabled() ? (
        <Routes />
      ) : (
        <Loader fullscreen />
      )}
    </div>
  );
};

AppLayoutBaseContent.propTypes /* remove-proptypes */ = {
  loading: PropTypes.bool.isRequired,
};

const AppLayoutContent = ({
  disabled = false,
  banned = false,
  authorizedZone = false,
  siteClosed = false,
  web2AppSite = false,
  isPaymentPage = false,
  isPopupOpen = false,
  deferredRenderAllowed = false,
  loading = false,
}) => {
  const isAppLayoutScrollable = useIsAppLayoutScrollable();
  const isAppLayoutSpacedBottom = useReactiveVar(isAppLayoutSpacedBottomVar);
  const isTiktokBottomSpacing = useReactiveVar(isTikTokBottomSpacingVar);
  const isPhone = usePhoneBreakpoint();
  /* Fixes bug related to "position: sticky" for "senderForm" in InApp */
  const withTransform =
    isAppLayoutSpacedBottom && isAppLayoutScrollable && !isPopupOpen;
  const isAllowed = !loading && !banned && authorizedZone && !siteClosed;

  useEffect(() => {
    if (isAllowed) {
      getActiveSplitGroup(
        SPLITS.ZOOMED_PRODUCT.ID,
        SPLITS.ZOOMED_PRODUCT.GROUP.ACTIVE,
      ).then((withZoom) => {
        if (withZoom) {
          document.documentElement.classList.add(css.zoom);

          // Update app size after zoom site
          setAppSize();
        }
      });
    }
  }, [isAllowed]);

  return (
    <Fragment>
      <ThemeFeaturesPreloader />
      <div id="outdated" />
      <div
        id={MAIN_LAYOUT_ID}
        className={cn(
          css.viewport,
          disabled && css.disabled,
          isAppLayoutScrollable && css.scrollable,
          isAppLayoutSpacedBottom && css.spacedBottom,
          withTransform && css.transform,
          IS_MIUI_BROWSER && css.spacedBottomForMiui,
        )}
      >
        <div id="main-container" className={css.main}>
          <HeaderDisplayer
            isAllowed={isAllowed}
            withTopWrapper={loading || !authorizedZone || siteClosed}
          >
            <AppLayoutBaseContent loading={loading} />
            {isAllowed && deferredRenderAllowed && (
              <Fragment>
                {isPhone && !isAppLayoutSpacedBottom && (
                  <Fragment>
                    <ThemedActivityBookmark />
                    {!web2AppSite && (
                      <DelayedRenderWrapper>
                        <PreloadThemedCompactComponentsPage />
                        <ProgressiveWebApplication />
                      </DelayedRenderWrapper>
                    )}
                  </Fragment>
                )}
                {!isPhone && (
                  <Fragment>
                    {!web2AppSite && (
                      <DelayedRenderWrapper>
                        <PreloadThemedComponentsPage />
                        <DraggableMiniMessengerChunk />
                        <ProgressiveWebApplication />
                      </DelayedRenderWrapper>
                    )}
                    <PaymentBottomNoticeDisplayer />
                  </Fragment>
                )}
                {!isPaymentPage && <ThemedStickedNotifications />}
              </Fragment>
            )}
            <div className={css.footer} data-test="siteFooter">
              {!loading && !banned && !isPaymentPage && (
                <Footer authorizedZone={authorizedZone} />
              )}
            </div>
            {!loading && <PopupDisplayer />}
          </HeaderDisplayer>
          {isAppLayoutScrollable && isTiktokBottomSpacing && (
            <Card light boundless shadowLevel={0}>
              <div className={css.tiktokSpacing} />
            </Card>
          )}
        </div>
      </div>
      <div id="popper" />
      <div id="scrollToTop" />
    </Fragment>
  );
};

AppLayoutContent.propTypes /* remove-proptypes */ = {
  disabled: PropTypes.bool, // @see App.js
  loading: PropTypes.bool, // @see App.js
  banned: PropTypes.bool, // @see App.js
  authorizedZone: PropTypes.bool, // @see App.js
  isPopupOpen: PropTypes.bool,
  isPaymentPage: PropTypes.bool,
  siteClosed: PropTypes.bool, // @see App.js
  web2AppSite: PropTypes.bool, // @see App.js
  /**
   * It is used for optimization purposes - defers rendering
   * of all things that are not required right now, slightly later.
   */
  deferredRenderAllowed: PropTypes.bool,
};

/**
 * Layout for root application.
 * Used for 'hydration' from existing markup
 */
const AppLayout = ({loading = false, onlyContent = false, ...props}) => {
  if (onlyContent) {
    return <AppLayoutBaseContent loading={loading} />;
  }

  return <AppLayoutContent {...props} loading={loading} />;
};

AppLayout.propTypes /* remove-proptypes */ = {
  onlyContent: PropTypes.bool, // Render only content without footer, header and others
  loading: PropTypes.bool, // @see App.js
};

export default AppLayout;
