import {getClientInstance} from '@core/graphql/client';

import PMA_REQUEST_PHOTO from '../graphql/queries/pmaRequestPhoto.gql';
import type {PmaRequestPhotoQuery} from '../graphql/queries/pmaRequestPhoto';

type UpdatePmaRequestPhotoDataParams = {
  updateRequestCount?: boolean;
  allowedRequestsCount?: number;
};

const updatePmaRequestPhotoData = (
  payload: UpdatePmaRequestPhotoDataParams = {},
): void => {
  const client = getClientInstance();

  const queryData = client.readQuery<PmaRequestPhotoQuery>({
    query: PMA_REQUEST_PHOTO,
  });

  const pmaRequestPhoto = queryData?.userFeatures?.pmaRequestPhoto;

  if (!pmaRequestPhoto) {
    return;
  }

  const {updateRequestCount} = payload;

  if (updateRequestCount) {
    const requestCount = pmaRequestPhoto.allowedRequestsCount;
    payload.allowedRequestsCount = requestCount ? requestCount - 1 : 0;
  }

  client.writeQuery<PmaRequestPhotoQuery>({
    query: PMA_REQUEST_PHOTO,
    data: {
      ...queryData,
      userFeatures: {
        ...queryData.userFeatures,
        pmaRequestPhoto: {
          ...pmaRequestPhoto,
          ...payload,
        },
      },
    },
  });
};

export default updatePmaRequestPhotoData;
