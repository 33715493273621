import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {map, tap, filter} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import type {CommonNotification} from '@core/systemNotifications/types';
import type {ProcessingStatusQuery} from '@core/payment/common/graphql/queries/processingStatus';
import PROCESSING_STATUS_QUERY from '@core/payment/common/graphql/queries/processingStatus.gql';
import PROCESSING_STATUS from '@core/payment/common/constants/processingStatus';
import PaymentPageSuccessOrders from '@core/payment/payProcess/utils/PaymentPageSuccessOrders';

const successPaymentDataSource = (): Observable<CommonNotification> =>
  from(
    getClientInstance().watchQuery<ProcessingStatusQuery>({
      query: PROCESSING_STATUS_QUERY,
    }),
  ).pipe(
    filter((processingStatusData) => {
      return (
        processingStatusData.data.payment.processingStatus ===
          PROCESSING_STATUS.READY &&
        PaymentPageSuccessOrders.isNeedShowNotification()
      );
    }),
    tap(() => PaymentPageSuccessOrders.disableNotification()),
    map(() => ({type: NOTIFICATION_TYPES.SUCCESS_PAYMENT})),
  );

export default successPaymentDataSource;
