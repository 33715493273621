import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import getHistory from '@core/application/utils/getHistory';

import LifetimeOfferPopup from '../components/LifetimeOfferPopup';

/**
 * @param {String} paymentUrl
 * @param {Function} onClick
 */
const openLifetimeOfferPopup = (paymentUrl, onClick) => {
  const history = getHistory();

  const handleClick = () => {
    history.push(paymentUrl);
    onClick && onClick();
  };

  PopupService.openPopup(
    <LifetimeOfferPopup
      // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
      onClick={handleClick}
    />,
    {
      small: true,
      showCloseButton: false,
      trackingName: 'lifetimeOffer',
      autoLoadTracking: true,
    },
  );
};

export default openLifetimeOfferPopup;
