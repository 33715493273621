import React from 'react';

import isMobileViewport from '@core/responsive/isMobileViewport';
import PopupService from '@core/popup/utils/PopupService';

import ExpandSearchLimitsPopupLayout from '../../expandSearchLimitsPopup/components/ExpandSearchLimitsPopupLayout';

const openExpandSearchLimitsPopup = ({popupConfig, ...props}) => {
  const isMobile = isMobileViewport();

  PopupService.openPopup(<ExpandSearchLimitsPopupLayout expired {...props} />, {
    ...popupConfig,
    inverseCloseButton: isMobile,
    flatCoverCloseButton: isMobile,
    trackingName: 'expandSearchLimitsWithDiscount',
    autoLoadTracking: true,
  });
};

export default openExpandSearchLimitsPopup;
