import React from 'react';

import t from '@core/translations/translate';
import STATIC_PAGES from '@core/staticPages/constants/pages';

import {ButtonPrimary, Button} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';
import {IconType} from '@phoenix/ui/constants';

import openPersonalIdentityByDemandRequirePopup from '../utils/openPersonalIdentityByDemandRequirePopup';
import useClosingPopupForVerifiedStatus from '../utils/useClosingPopupForVerifiedStatus';

const openTermsPopup = () => {
  StaticPagePopupHelper.openPopup(STATIC_PAGES.TERMS);
};

const openAddVerifyDocumentPopup = () =>
  openPersonalIdentityByDemandRequirePopup();

const PersonalIdentityByDemandFailPopup = () => {
  useClosingPopupForVerifiedStatus();

  return (
    <PopupNotice
      icon="age-cross"
      iconType={IconType.DANGER}
      title={t('personalIdentityByDemand', 'text.verificationFail')}
      subTitle={t('personalIdentityByDemand', 'text.failDescription')}
      actions={[
        <ButtonPrimary
          fullWidth
          onClick={openAddVerifyDocumentPopup}
          trackingName="verify"
        >
          {t('personalIdentityByDemand', 'button.verifyNow')}
        </ButtonPrimary>,
        <Button fullWidth onClick={openTermsPopup} trackingName="checkReason">
          {t('personalIdentityByDemand', 'button.checkReason')}
        </Button>,
      ]}
    />
  );
};

export default PersonalIdentityByDemandFailPopup;
