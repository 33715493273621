import React, {useMemo} from 'react';
import {useQuery} from '@apollo/client';
import get from 'lodash/get';

import CURRENT_USER_VIDEOS_QUERY from '@core/user/profile/current/graphql/queries/currentUserVideos.gql';
import deleteVideo from '@core/user/profile/current/utils/deleteVideo';

import openGalleryPopup from '@phoenix/gallery/utils/openGalleryPopup';
import VideoControls from '@phoenix/gallery/components/VideoControls';

import TARGET_USER_VIDEOS_QUERY from '../../target/graphql/queries/targetUserVideos.gql';

const getMediaDataFromVideos = (videos) =>
  videos.map((video) => ({id: video.id, video, original: video.id}));

const getVideoQuery = (userId, my) =>
  my
    ? [CURRENT_USER_VIDEOS_QUERY]
    : [TARGET_USER_VIDEOS_QUERY, {variables: {id: userId}}];

export const useGetVideos = ({userId, my}) => {
  const [query, options] = useMemo(
    () => getVideoQuery(userId, my),
    [userId, my],
  );
  const {data, error, loading} = useQuery(query, options);

  let videos = my
    ? get(data, 'myUser.profile.videos.allVideos')
    : get(data, 'user.profile.videos.allVideos');
  videos = videos || [];

  // used for target user only to show specific webCam placement after video
  const showWebCamMotivation =
    !my && get(data, 'user.profile.statuses.isWebcamProfile');

  return useMemo(() => {
    if (error || loading) return {error, loading};

    return {
      error,
      loading,
      media: getMediaDataFromVideos(videos),
      showWebCamMotivation,
    };
  }, [error, loading, videos, showWebCamMotivation]);
};

/**
 * Wrapper component for getting data for gallery
 * @param userId
 * @param my
 * @param children
 * @param props
 * @returns {null|*}
 * @constructor
 */
export const VideoGalleryData = ({userId, my, children, ...props}) => {
  const {error, loading, media, showWebCamMotivation} = useGetVideos({
    userId,
    my,
  });
  if (error || loading) return null;

  return children({
    media,
    userId,
    my,
    showWebCamMotivation,
    ...props,
  });
};

/**
 * Render prop for render controls for video
 * @see Gallery.js
 * @returns {*}
 * @param currentMedia
 */
export const videoControlsRenderer = (currentMedia) => (
  <VideoControls onDeleteClick={() => deleteVideo(currentMedia.id)} />
);

const openVideoGallery = ({
  userId,
  my,
  videoId,
  forceWebCamPartner,
  needGTMTrack = false,
}) =>
  openGalleryPopup({
    userId,
    needGTMTrack,
    my,
    forceWebCamPartner,
    initialMediaId: videoId,
    showNav: true,
    showCounter: true,
    bottomRenderer: my ? videoControlsRenderer : null,
    GalleryData: VideoGalleryData,
  });

export default openVideoGallery;
