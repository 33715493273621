import {localStorage} from '@core/utils/storage';

import STORAGE_KEY from '../constants/storageKey';

/**
 * @param {string} message
 * @param {string} logicName
 */
const webPushLogger = (message, logicName = '') => {
  if (message && localStorage.getItem(STORAGE_KEY.LOGGER_ENABLED)) {
    console.log(`Web push logic ${logicName.toUpperCase()}: ${message}`);
  }
};

export default webPushLogger;
