import type {FC} from 'react';
import React, {useMemo} from 'react';

import RecipientsAppearance from '@core/theming/constants/features/RecipientsAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43633
 */
type ThemedRecipientsPageProps = {
  [key: string]: any;
};

export const resolver: ModuleResolver<
  RecipientsAppearance,
  ModuleComponent<ThemedRecipientsPageProps>
> = (value) => {
  switch (value) {
    case RecipientsAppearance.WITH_TABS_OR_FILTER:
      return import(
        /* webpackChunkName: 'recipientsPageWithTabs' */ '@phoenix/messenger/common/containers/RecipientPageWithTabs'
      );
    case RecipientsAppearance.WITH_CAROUSEL_AND_TABS:
      return import(
        /* webpackChunkName: 'recipientsPageWithCarouselAndTabs' */ '@phoenix/messenger/common/containers/RecipientPageWithCarouselAndTabs'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable recipients page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedRecipientsPage: FC<ThemedRecipientsPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedRecipientsPageProps,
        RecipientsAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="messenger"
      param="recipients"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedRecipientsPage;
