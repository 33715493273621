import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyPersonalIdentity = lazy(
  () =>
    import(
      /* webpackChunkName: 'personalIdentity' */ '@phoenix/personalIdentity/containers/PersonalIdentityPage'
    ),
);

/**
 * Personal identity functionality page (used on Japan location)
 */
export const PersonalIdentityChunk = (props) => (
  <SplitChunk component={LazyPersonalIdentity} {...props} />
);

export default PersonalIdentityChunk;
