import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {RowLarge, Button, ButtonPrimary} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';

import UploadActions from './UploadActions';
import css from '../styles/MediaPreviewLayout.css';

const MediaPreviewLayout = ({
  children,
  sendText,
  cancelText,
  onSubmit,
  onCancel,
}) => (
  <Fragment>
    <RowLarge align={Align.CENTER}>
      <div className={css.container}>{children}</div>
    </RowLarge>
    <UploadActions>
      <Button
        onClick={onCancel}
        trackingName="cancel"
        data-test="mediaPreviewCancel"
      >
        {cancelText}
      </Button>
      <ButtonPrimary
        onClick={onSubmit}
        trackingName="submit"
        data-test="mediaPreviewSubmit"
      >
        {sendText}
      </ButtonPrimary>
    </UploadActions>
  </Fragment>
);

MediaPreviewLayout.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  sendText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MediaPreviewLayout;
