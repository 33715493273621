import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import {Icon} from '@phoenix/ui';

const MicrofeaturesPopupAnimationLayout = ({
  iconsCount,
  iconType,
  className,
}) => {
  return range(iconsCount).map((position) => (
    <div className={className} key={position}>
      <Icon type={iconType} inherit />
    </div>
  ));
};

MicrofeaturesPopupAnimationLayout.propTypes /* remove-proptypes */ = {
  iconsCount: PropTypes.number.isRequired,
  iconType: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MicrofeaturesPopupAnimationLayout;
