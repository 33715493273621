import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';
import trackPaymentDescriptor from '@core/payment/payProcess/paymentFlow/methods/trackPaymentDescriptor';
import addSuccessOrder from '@core/payment/payProcess/paymentFlow/methods/addSuccessOrder';
import updateCSRFToken from '@core/payment/payProcess/paymentFlow/methods/updateCSRFToken';
import addBrowserDetails from '@core/payment/payProcess/paymentFlow/methods/addBrowserDetails';
import externalThreeDSRedirect from '@core/payment/payProcess/paymentFlow/methods/externalThreeDSRedirect';
import payByMethodFromFreeTrialSuccessPopup from '@core/payment/payProcess/utils/payByMethodFromFreeTrialSuccessPopup';

import showRedirectUrlInConsoleIfNeed from '../methods/showRedirectUrlInConsoleIfNeed';
import processThreeDSecureAnswer from '../methods/processThreeDSecureAnswer';
import showFreeTrialSuccessPaymentCompletePopup from '../methods/showFreeTrialSuccessPaymentCompletePopup';
import updateCacheAfterPayment from '../methods/updateCacheAfterPayment';

const getFreeTrialSuccessPopupFlow = () => {
  return [
    reportStartPayment,
    addBrowserDetails,
    payByMethodFromFreeTrialSuccessPopup,
    updateCSRFToken,
    reportEndPayment,
    showRedirectUrlInConsoleIfNeed,
    externalThreeDSRedirect,
    processThreeDSecureAnswer,
    processPaymentAnswer,
    trackPaymentDescriptor,
    updateCacheAfterPayment,
    showFreeTrialSuccessPaymentCompletePopup,
    addSuccessOrder,
  ];
};

export default getFreeTrialSuccessPopupFlow;
