import React from 'react';

import Field from '@core/form/AbstractFormField';

import TextareaWithMaxLength from '@phoenix/widgets/input/TextareaWithMaxLength';

const FormTextareaWithMaxLength = (props) => (
  <Field component={TextareaWithMaxLength} {...props} />
);

export default FormTextareaWithMaxLength;
