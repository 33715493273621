import type {FC, MouseEvent, ReactNode} from 'react';
import React from 'react';
import cn from 'classnames';

import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import css from '../styles/VideoPreviewWrapper.css';

type VideoPreviewWrapperProps = {
  onClick?: (e: MouseEvent) => void;
  trackingName: string;
  round?: boolean;
  naughtyLevel: boolean;
  naughtySize: string;
  shadow?: boolean;
  showMediaCounter: boolean;
  customOverlay: ReactNode;
  children: ReactNode;
};

const VideoPreviewWrapper: FC<VideoPreviewWrapperProps> = ({
  onClick,
  trackingName,
  round,
  naughtyLevel,
  naughtySize,
  shadow,
  showMediaCounter,
  customOverlay,
  children,
}) => {
  return (
    <AddBabciaUBTracking trackingName={onClick ? trackingName : null}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onClick={onClick}
        data-test="videoPreview"
        className={cn(
          css.container,
          round && css.round,
          onClick && css.clickable,
        )}
      >
        <div
          className={cn(
            css.preview,
            naughtyLevel ? css[naughtySize] : css.full,
            shadow && css.shadow,
          )}
        >
          {children}
        </div>
        {!showMediaCounter && customOverlay}
      </div>
    </AddBabciaUBTracking>
  );
};

export default VideoPreviewWrapper;
