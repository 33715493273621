import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import MicrofeaturesPaymentPopup from '../containers/MicrofeaturesPaymentPopup';

/**
 * Show microfeature payment popup
 * @param {Object} params
 * @param {string} params.featureName (@see MICROFEATURES from common/constants/microfeatures.js)
 * @param {string} params.placement (@see banners/constants/bannersPlacements.js)
 * @param {string} [params.isFromCreditsLink] - TODO: add credits logic after coins test
 */
const openPaymentMicroFeaturePopup = ({
  featureName,
  placement,
  isFromCreditsLink,
} = {}) => {
  PopupService.openPopup(
    <MicrofeaturesPaymentPopup
      featureName={featureName}
      placement={placement}
      isFromCreditsLink={isFromCreditsLink}
    />,
    {
      small: true,
      trackingName: `microfeaturesPayment_${featureName}`,
    },
  );
};

export default openPaymentMicroFeaturePopup;
