import React from 'react';
import PropTypes from 'prop-types';

import {Spacing} from '@phoenix/ui';

import css from '../styles/HighlightedDescription.css';

const HighlightedDescription = ({children}) => (
  <Spacing className={css.wrap}>{children}</Spacing>
);

HighlightedDescription.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
};

export default HighlightedDescription;
