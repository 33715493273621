import FREE_MESSAGE_NOTIFICATION_QUERY from '../graphql/queries/freeMessageNotification.gql';

const updateFreeMessageNotification = ({
  client,
  isAvailable,
  period,
  bannerType,
}) => {
  const data = client.readQuery({query: FREE_MESSAGE_NOTIFICATION_QUERY});

  client.writeQuery({
    query: FREE_MESSAGE_NOTIFICATION_QUERY,
    data: {
      notifications: {
        ...data.notifications,
        freeMessageNotification: {
          ...data.notifications.freeMessageNotification,
          isAvailable,
          period,
          bannerType,
        },
      },
    },
  });
};

export default updateFreeMessageNotification;
