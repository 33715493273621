import {MODEL_TYPES} from '../constants/modelTypes';

/**
 * @param {String} modelType - one of MODEL_TYPES
 * @param {Object} microfeaturesList - result of MicrofeaturesListQuery
 * @returns {Array}
 */
const getMicrofeaturesList = (modelType, microfeaturesList) => {
  // Credits api used for coins
  if (modelType === MODEL_TYPES.CREDIT) {
    return microfeaturesList?.creditFeatureList || [];
  }

  return microfeaturesList?.microFeatureList || [];
};

export default getMicrofeaturesList;
