import ROUTES from '@core/application/constants/routes';
import getHistory from '@core/application/utils/getHistory';
import {PaymentFlowStatus} from '@core/payment/payProcess/utils/getProcessPaymentFlow';
import {MICROFEATURES_VIA} from '@core/payment/common/constants/vias';
import PAYMENT_METHODS from '@core/payment/common/constants/paymentMethods';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';

const goToNextStepOnDeclineAfterMicroFeaturesPayment: PaymentProcessStep =
  async (data) => {
    const {paymentResult, paymentData} = data;
    const {prevVia} = paymentData;
    const {action, status, failPageLogic, stockId} = paymentResult;

    if (!status) {
      getHistory().push(
        generatePayUrl({
          stage: action,
          urlParams: {
            prevVia,
            viaMethod: PAYMENT_METHODS.CARD,
            stockId,
            returnPath: window.location.pathname,
            skipStepOnBack: false,
            via: failPageLogic || MICROFEATURES_VIA,
            ...(prevVia === MICROFEATURES.TELEGRAM_CONVERSATION
              ? {returnPath: ROUTES.SEARCH, skipStepOnBack: true}
              : null),
          },
        }),
      );

      return {...data, flowStatus: PaymentFlowStatus.ABORTED};
    }

    return data;
  };

export default goToNextStepOnDeclineAfterMicroFeaturesPayment;
