import type {Observable} from 'rxjs';
import {combineLatest} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import {localStorage} from '@core/utils/storage/storage';
import type {CurrentUserIdQuery} from '@core/user/profile/current/graphql/queries/currentUserId';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';
import type {CommonNotification} from '@core/systemNotifications/types';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';

import PersonalIdentityService from '@phoenix/personalIdentity/PersonalIdentityService';

const getStorageKey = (id: string) => {
  return `AgeVerificationNotificationWasClosed:${id}`;
};

type AgeVerificationNotification = CommonNotification & {
  onClose: () => void;
};

const ageVerificationDataSource = (): Observable<AgeVerificationNotification> =>
  combineLatest<[never, {data: CurrentUserIdQuery}]>([
    PersonalIdentityService.getData(),
    getClientInstance().watchQuery<CurrentUserIdQuery>({
      query: CURRENT_USER_ID_QUERY,
    }),
  ]).pipe(
    filter(
      ([, {data}]) =>
        !localStorage.getItem(getStorageKey(data.myUser.id)) &&
        PersonalIdentityService.isVerificationRequired() &&
        PersonalIdentityService.firstDayLogic,
    ),
    map(([, {data}]) => ({
      type: NOTIFICATION_TYPES.AGE_VERIFICATION,
      onClose: () => {
        localStorage.setItem(getStorageKey(data.myUser.id), true);
      },
    })),
  );

export default ageVerificationDataSource;
