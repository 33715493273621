import Expire from 'react-expire';
import type {FC} from 'react';
import React from 'react';
import {useQuery} from '@apollo/client';

import t from '@core/translations/translate';
import type {VideoLimitQuery} from '@core/user/profile/current/graphql/queries/videoLimit';
import VIDEO_LIMIT_QUERY from '@core/user/profile/current/graphql/queries/videoLimit.gql';

import type {SystemNotificationProps} from '../types';
import SystemNotificationLayout from '../components/SystemNotificationLayout';

const SystemNotificationVideoLimit: FC<SystemNotificationProps> = ({
  onClose,
  expire,
  ...props
}) => {
  const {data, loading} = useQuery<VideoLimitQuery>(VIDEO_LIMIT_QUERY);

  if (loading) {
    return null;
  }

  return (
    <Expire onExpire={onClose as () => void} until={expire}>
      <SystemNotificationLayout
        onClose={onClose}
        text={t('uploadButtonMessages', 'maximum user videos reached', {
          '{maximumVideoAmount}': data.userFeatures.userVideo.settings.limit,
        })}
        type="danger"
        iconType="video"
        {...props}
      />
    </Expire>
  );
};

export default SystemNotificationVideoLimit;
