/* eslint-disable consistent-return */

import cachePersistManager from '@core/graphql/utils/cachePersistManager';

import isTransitionActiveVar from '../graphql/vars/isTransitionActiveVar';
import openPaymentPageInNewTab from './openPaymentPageInNewTab';
import showRmPopupInsteadOfPaymentPage from './showRmPopupInsteadOfPaymentPage';

/**
 * Visit payment page redirect check
 * @param nextLocation
 * @returns {Promise<*|boolean>}
 */
const paymentPageVisitNavigationInterceptor = async (nextLocation) => {
  const showRmPopup = showRmPopupInsteadOfPaymentPage(nextLocation);

  if (showRmPopup) {
    return false; // cancel navigation to payment page when need show RM popup
  }

  const openInNewTab = await openPaymentPageInNewTab(nextLocation);

  /*
     Persist cache to accelerate PP loading speed and keep cache
     to accelerate retreat time from PP
   */
  await cachePersistManager.persistCache();

  if (openInNewTab) {
    isTransitionActiveVar(false);

    return false; // cancel navigation to payment page
  }
};

export default paymentPageVisitNavigationInterceptor;
