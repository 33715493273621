import React, {useEffect, createRef} from 'react';

import Portals from '@core/utils/backbone/Portals';

import renderAppView from '@legacy/application/components/appInit/renderAppView';

const ref = createRef();

let initialized = false;

export default function LegacyAppViewInitializer() {
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      renderAppView((...args) => ref.current(...args));
    }
  }, []);

  return <Portals ref={ref} />;
}
