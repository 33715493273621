import type {FC, ReactNode} from 'react';
import React from 'react';

import css from '../styles/SearchBannerSpacing.css';

type SearchBannerSpacingProps = {
  children: ReactNode;
};

/**
 * We can't use {SearchWidgetSpacing} since of small devices like iPhone 5
 * we should modify layout for allowing to display long banner without cutting it on sides
 */
const SearchBannerSpacing: FC<SearchBannerSpacingProps> = ({children}) => (
  <div className={css.widget}>{children}</div>
);

export default SearchBannerSpacing;
