/**
 * @const {String}
 */
export const PAID_NAUGHTY_MODE_SEXY_VIA = 'paid_nm_sexy';

/**
 * @const {String}
 */
export const PAID_NAUGHTY_MODE_NO_LIMITS_VIA = 'paid_nm_no_limits';

/**
 * @const {String}
 */
export const ACCOUNT_VIA = 'account';
