import React from 'react';

import {PlaceholderBar, PlaceholderCircle, Group} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/ConfirmationUserMotivation.css';

const ConfirmationUserMotivationPlaceholder = () => (
  <Group horizontal space={SpacingSize.SHORT} itemWithFlexibleWidth={false}>
    <PlaceholderCircle className={css.photo} />
    <PlaceholderBar size={150} />
  </Group>
);

export default ConfirmationUserMotivationPlaceholder;
