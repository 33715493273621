import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import SuccessDnsmpiPopup from '../components/SuccessDnsmpiPopup';

const openDnsmpiSuccessPopup = () => {
  PopupService.openPopup(
    <SuccessDnsmpiPopup
      // eslint-disable-next-line local-rules/tracking-name -- has tracking inside
      onClick={() => PopupService.closePopup()}
    />,
    {
      small: true,
      trackingName: 'dnsmpiSuccess',
      autoLoadTracking: true,
    },
  );
};

export default openDnsmpiSuccessPopup;
