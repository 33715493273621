import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import useCSSHeaderHeight from '@core/header/utils/useCSSHeaderHeight';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import {HEADER_ID, TOP_WRAPPER} from '@core/header/constants/header';
import HeaderPositionAppearance from '@core/theming/constants/features/HeaderPositionAppearance';
import HeaderRoundedAppearance from '@core/theming/constants/features/HeaderRoundedAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import SystemNotifications from '@phoenix/systemNotifications/containers/SystemNotifications';
import CreditBalanceHeaderDisplayer from '@phoenix/credits/containers/CreditBalanceHeaderDisplayer';
import ThemedHeader from '@phoenix/theming/components/ThemedHeader';

import StickedPositioner from '../components/StickedPositioner';
import HeaderToolbar from '../components/HeaderToolbar';
import LocationDependentHeaderTopWrapper from '../components/LocationDependentHeaderTopWrapper';
import useHeaderDisplayerData from '../utils/useHeaderDisplayerData';
import useCSSTopWrapperHeight from '../utils/useCSSTopWrapperHeight';

/**
 * Since header is rendered in async way,
 * we should start our hook only together with DOM around it.
 */
const CSSHeaderHeightDetector = () => {
  useCSSHeaderHeight();
  return null;
};

const CSSTopWrapperHeightDetector = () => {
  useCSSTopWrapperHeight();
  return null;
};

/**
 * Common component for displaying site header.
 * Implements different things:
 * - Themed header displayment
 * - Placement of header based on feature (because of component is created as wrapping one)
 * - Adds global CSS3 variable for retrieving header height
 */
const HeaderDisplayer = ({isAllowed, withTopWrapper, children}) => {
  const isPhone = usePhoneBreakpoint();

  const {data: rounded} = useThemeFeature('header', 'rounded');
  const {data: floatingHeader} = useThemeFeature('header', 'floatingHeader');
  const {data: floatingSubheader} = useThemeFeature(
    'header',
    'floatingSubheader',
  );
  const {data: stickedSubheader} = useThemeFeature(
    'header',
    'stickedSubheader',
  );
  const {data: inverseHeader} = useThemeFeature('header', 'inverse');
  const {data: systemNotificationOnTop} = useThemeFeature(
    'payment',
    'systemNotificationOnTop',
  );

  const {position, isPP, topWrapperHeightVariableAvailable} =
    useHeaderDisplayerData();

  let headerTopRounded = rounded;

  if (stickedSubheader) {
    // On a mobile site you don't need to display the radius, so it's a crutch.
    if (
      !inverseHeader &&
      rounded === HeaderRoundedAppearance.ROUNDED &&
      isPhone
    ) {
      headerTopRounded = HeaderRoundedAppearance.NONE;

      // Ignore strongly rounded for subheaders, it should be used for headers only
    } else if (rounded !== HeaderRoundedAppearance.NONE) {
      headerTopRounded = HeaderRoundedAppearance.ROUNDED;
    }
  }

  return (
    <Fragment>
      {isAllowed && (
        <Fragment>
          <StickedPositioner
            /*
             * When the header is on bottom - we should attach ID there.
             * Also we can't attach it down, since spacing matters in Like Gallery.
             * We should place ID here to give scroll offset from toolbar
             * on PP because there is no header bar there in any case (see FE-29602).
             * */
            id={
              position === HeaderPositionAppearance.TOP || isPP
                ? HEADER_ID
                : null
            }
            spacingId={stickedSubheader ? TOP_WRAPPER : null}
            position={HeaderPositionAppearance.TOP}
            rounded={headerTopRounded}
            floating={
              position === HeaderPositionAppearance.TOP
                ? floatingHeader && floatingSubheader
                : floatingSubheader
            }
            data-test="stickedTopWrapper"
          >
            {position === HeaderPositionAppearance.TOP && (
              <BabciaScopedProvider context="header">
                <ThemedHeader />
              </BabciaScopedProvider>
            )}
            {(!isPP || (isPP && systemNotificationOnTop)) && (
              <SystemNotifications onTop={isPP && systemNotificationOnTop} />
            )}
            {stickedSubheader && (
              <Fragment>
                <CreditBalanceHeaderDisplayer />
                <HeaderToolbar />
              </Fragment>
            )}
            {isPP && !systemNotificationOnTop && <SystemNotifications />}
          </StickedPositioner>
          {!stickedSubheader && (
            <LocationDependentHeaderTopWrapper
              spacingId={TOP_WRAPPER}
              sticked={false}
            >
              <CreditBalanceHeaderDisplayer />
              <HeaderToolbar />
            </LocationDependentHeaderTopWrapper>
          )}
        </Fragment>
      )}

      <LocationDependentHeaderTopWrapper
        withoutTop={false}
        stretch
        unneeded={!withTopWrapper}
      >
        {children}
      </LocationDependentHeaderTopWrapper>

      {isAllowed && (
        <Fragment>
          {position === HeaderPositionAppearance.BOTTOM && !isPP && (
            <StickedPositioner
              id={HEADER_ID}
              position={HeaderPositionAppearance.BOTTOM}
              rounded={rounded}
              floating={floatingHeader}
              data-test="stickedBottomWrapper"
            >
              <BabciaScopedProvider context="header">
                <ThemedHeader />
              </BabciaScopedProvider>
            </StickedPositioner>
          )}
          <CSSHeaderHeightDetector key={isPP} />

          {topWrapperHeightVariableAvailable && (
            // Calculate top wrapper height when header is on bottom position or on PP
            <CSSTopWrapperHeightDetector />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

HeaderDisplayer.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  // Is allowed to render things for authenticated user.
  isAllowed: PropTypes.bool,
  withTopWrapper: PropTypes.bool,
};

export default HeaderDisplayer;
