import buildFreeCoinsRewardQuery from '@phoenix/freeCoins/utils/buildFreeCoinsRewardQuery';

const completeRewardTask = (client, task) => {
  const query = buildFreeCoinsRewardQuery(task);
  const data = client.readQuery({query});
  if (data) {
    client.writeQuery({
      query,
      data: {
        ...data,
        credits: {
          ...data.credits,
          rewardForTasks: {
            ...data.credits.rewardForTasks,
            tasks: {
              ...data.credits.rewardForTasks.tasks,
              [task]: {
                ...data.credits.rewardForTasks.tasks[task],
                completed: true,
              },
            },
          },
        },
      },
    });
  }
};

export default completeRewardTask;
