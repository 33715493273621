import React from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';

import {SpacingSize} from '@phoenix/ui/constants';
import {Notice, Spacing} from '@phoenix/ui';
import useCoinsEmailVerification from '@phoenix/coins/utils/useCoinsEmailVerification';
import CoinColorizedIconByAmount from '@phoenix/credits/components/CoinColorizedIconByAmount';
import COIN_AMOUNT from '@phoenix/credits/constants/coinAmount';
import COIN_ICON_SIZES from '@phoenix/credits/constants/coinColorizedIconSizes';

const ConfirmationCoinsContent = ({email}) => {
  const isPhone = usePhoneBreakpoint();
  const {amount} = useCoinsEmailVerification();

  return (
    <Spacing
      onlyTop
      size={isPhone ? SpacingSize.NORMAL : SpacingSize.NONE}
      adaptive
      data-test="confirmationContent"
    >
      <Notice
        spacing={isPhone ? SpacingSize.NORMAL : SpacingSize.LARGE}
        icon={
          <CoinColorizedIconByAmount
            amount={COIN_AMOUNT.HUGE}
            size={COIN_ICON_SIZES.HUGE}
          />
        }
        title={t('emailValidationBase', 'title.get_coins_for_free', {
          '{amount}': amount,
        })}
        subTitle={t(
          'emailValidationBase',
          'text.confirm_coins_email_motivation',
          {
            '{email}': email,
            '{amount}': amount,
          },
        )}
        withoutBottom
      />
    </Spacing>
  );
};

ConfirmationCoinsContent.propTypes /* remove-proptypes */ = {
  email: PropTypes.string.isRequired,
};

export default ConfirmationCoinsContent;
