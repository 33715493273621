import type {FC} from 'react';
import React, {useMemo} from 'react';

import TargetUserPageAppearance from '@core/theming/constants/features/TargetUserPageAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43459
 */
export type ThemedTargetUserPageProps = {
  [key: string]: any;
};

export const resolver: ModuleResolver<
  TargetUserPageAppearance,
  ModuleComponent<ThemedTargetUserPageProps>
> = (value) => {
  switch (value) {
    case TargetUserPageAppearance.AS_LAYERED_WITH_TABS:
      return import(
        /* webpackChunkName: 'userLayeredWithTabs' */ '@phoenix/user/profile/target/containers/TargetUserPageLayeredWithTabs'
      );
    case TargetUserPageAppearance.AS_LAYERED_WITH_MEDIA:
      return import(
        /* webpackChunkName: 'userLayeredWithLoadMore' */ '@phoenix/user/profile/target/containers/TargetUserPageLayered'
      );
    case TargetUserPageAppearance.AS_LAYERED_WITH_GALLERY:
      return import(
        /* webpackChunkName: 'userLayeredWithGallery' */ '@phoenix/user/profile/target/containers/TargetUserPageLayeredWithGallery'
      );
    case TargetUserPageAppearance.AS_LIST_WITH_TOOLBAR:
      return import(
        /* webpackChunkName: 'userListWithToolbar' */ '@phoenix/user/profile/target/containers/TargetUserPageListWithToolbar'
      );
    case TargetUserPageAppearance.AS_LIST_WITH_TOOLBAR_AND_PLAIN_MAIN_PHOTO:
      return import(
        /* webpackChunkName: 'userListWithToolbarLoadMore' */ '@phoenix/user/profile/target/containers/TargetUserPageListWithToolbarAndPlainMainPhoto'
      );
    case TargetUserPageAppearance.AS_LIST_WITH_TOOLBAR_AND_BLURRED_ACTIONS:
      return import(
        /* webpackChunkName: 'userListWithBlurredActions' */ '@phoenix/user/profile/target/containers/TargetUserPageListWithToolbarAndBlurredActions'
      );
    case TargetUserPageAppearance.WITH_INTEGRATED_STORIES_AND_TOOLBAR:
      return import(
        /* webpackChunkName: 'userListWithStories' */ '@phoenix/user/profile/target/containers/TargetUserPageWithIntegratedStoriesAndToolbar'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable target user page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedTargetUserPage: FC<ThemedTargetUserPageProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedTargetUserPageProps,
        TargetUserPageAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="targetUser"
      param="page"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedTargetUserPage;
