import getFormattedPrice from '@core/payment/common/utils/getFormattedPrice';
import PAYMENT_PRICING from '@core/payment/common/constants/paymentPricing';
import type {PaymentPackage} from '@core/payment/widgets/package/types';
import type {CachedPurchasedPackage} from '@core/payment/common/utils/cachedPurchasedPackage';
import type {PackageDataFragment} from '@core/payment/widgets/package/graphql/fragments/package';

type PaymentMethod = {
  method?: string;
};

export type FormatPackageDataResult = PaymentMethod & {
  packageName: string;
  fullPrice: string;
};

type SelectedPackage =
  | (PaymentPackage &
      Partial<Pick<PackageDataFragment, 'packagePricingDetails'>>)
  | (CachedPurchasedPackage & PaymentMethod);

const formatPackageData = (
  selectedPackage: SelectedPackage,
): FormatPackageDataResult => {
  const {
    method,
    packageName,
    packagePricingDetails: {packagePriceData, packageDiscountPriceData},
  } = selectedPackage;

  return {
    packageName,
    fullPrice: getFormattedPrice(
      packageDiscountPriceData
        ? packageDiscountPriceData[PAYMENT_PRICING.FULL_PRICE]
        : packagePriceData[PAYMENT_PRICING.FULL_PRICE],
    ),
    method,
  };
};

export default formatPackageData;
