import debounce from 'lodash/debounce';
import {useEffect} from 'react';

import isTransitionActiveVar from '@phoenix/payment/graphql/vars/isTransitionActiveVar';

const skipBlindClick = debounce(isTransitionActiveVar, 0);

/**
 * Is used to disable {@see useBlindClickListener} while this hook is mounted.
 */
const useSkipBlindClick = () => {
  useEffect(() => {
    skipBlindClick.cancel();
    isTransitionActiveVar(true);
    /**
     * Can't use `isTransitionActiveVar(false)` directly, because {@see useBlindClickListener}
     * will add their listener and process the event caused this hook to unmount.
     */
    return () => skipBlindClick(false);
  }, []);
};

export default useSkipBlindClick;
