import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {Button} from '@phoenix/ui';

import css from '../styles/WalletPayButton.css';

const WalletPayButton = ({icon, alt, inverse, className, ...props}) => {
  return (
    <Button
      className={cn(inverse ? css.buttonInverse : css.button, className)}
      {...props}
    >
      <img className={css.image} alt={alt} src={icon} />
    </Button>
  );
};

WalletPayButton.propTypes /* remove-proptypes */ = {
  className: PropTypes.string,
  inverse: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default WalletPayButton;
