import URI from '@core/utils/url';
import {localStorage} from '@core/utils/storage';

const BASE_URL = '/api/v1/crm/webPush/track';
const STORAGE_KEY = 'WebPush:blockedTrack';

class WebPushTrackingService {
  /**
   * set of all needed options for correct work
   * @type {Object|null}
   */
  attributes = {};

  /**
   * @param {Object} options
   */
  constructor(options) {
    this.setAttributes(options);
  }

  /**
   * @param {Object} attributes
   * @public
   */
  setAttributes(attributes) {
    this.attributes = {...this.attributes, ...attributes};
  }

  /**
   * @public
   * @return {Object}
   */
  getAttributes() {
    return this.attributes;
  }

  /**
   * @private
   * @return {String}
   */
  createUrl() {
    const searchParams = this.getAttributes();
    const uri = URI(BASE_URL);
    return uri.addSearch(searchParams).toString();
  }

  /**
   * @public
   * @param {String} action
   */
  track(action) {
    this.setAttributes({track: action});

    const url = this.createUrl();

    fetch(url).catch((err) => {
      console.error(err);
    });
  }

  /**
   * @private
   */
  getStorageKey() {
    return `${STORAGE_KEY}:${this.attributes.userId}`;
  }

  /**
   * @public
   */
  setStorageItem() {
    localStorage.setItem(this.getStorageKey(), true);
  }

  /**
   * @public
   */
  removeStorageItem() {
    localStorage.removeItem(this.getStorageKey());
  }

  /**
   * @private
   */
  getStorageItem() {
    return localStorage.getItem(this.getStorageKey());
  }
}

export default WebPushTrackingService;
