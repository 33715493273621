import {getClientInstance} from '@core/graphql/client';

import MESSENGER_CHAT_PRELOAD_QUERY from '../graphql/queries/messengerChatPreload.gql';

const refetchMessengerChatPreload = (recipientId: string): void => {
  getClientInstance().query({
    query: MESSENGER_CHAT_PRELOAD_QUERY,
    variables: {
      recipientId,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  });
};

export default refetchMessengerChatPreload;
