import type {FC} from 'react';
import React from 'react';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {ButtonFlat, Card, Icon, Spacing, SpacingLarge} from '@phoenix/ui';
import {SpacingDirection, SpacingSize} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';

import type {SystemNotificationLayoutProps} from '../types';
import css from '../styles/SystemNotificationLayout.css';

/**
 * Map between background prop and icon type.
 */
enum IconMap {
  success = 'success',
  highlighted = 'active',
  danger = 'danger',
}

const SystemNotificationLayout: FC<SystemNotificationLayoutProps> = ({
  iconType,
  text,
  type,
  onClose,
  onClick,
  floating,
  inverse,
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <Spacing
      direction={SpacingDirection.HORIZONTAL}
      size={floating ? SpacingSize.SHORT : SpacingSize.NONE}
      onClick={onClick}
      trackingName={onClick ? 'widget' : null}
    >
      <Card
        saturated={inverse && type !== 'highlighted'}
        inverse={inverse}
        boundless={!floating}
        shadowLevel={floating ? 3 : 2}
        className={css.wrap}
        {...(inverse && type === 'highlighted' ? {} : {[type]: true})}
      >
        <SpacingLarge
          direction={SpacingDirection.HORIZONTAL}
          className={isPhone ? css.contentPhone : css.content}
          withoutLeft={isPhone}
          adaptive={false}
        >
          <Icon
            type={iconType}
            inverse={inverse}
            {...{[IconMap[type]]: !inverse}}
          />
          <Text
            className={css.text}
            inverse={inverse}
            data-test="systemNotificationText"
          >
            {typeof text === 'function' ? text(inverse) : text}
          </Text>
        </SpacingLarge>
        <div className={floating ? css.floatingClose : css.close}>
          <ButtonFlat
            data-test="systemNotificationClose"
            round
            onClick={onClose}
            trackingName="close"
            inverse={inverse}
          >
            <Icon type="cross" muted inverse={inverse} />
          </ButtonFlat>
        </div>
      </Card>
    </Spacing>
  );
};

export default SystemNotificationLayout;
