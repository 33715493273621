import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import {Link} from '@phoenix/typography';

import RemarketingToggleSubscriptionCheckbox from '../components/RemarketingToggleSubscriptionCheckbox';
import css from '../styles/RemarketingToggleSubscriptionLink.css';

const RemarketingToggleSubscriptionLink = ({onCancelChange, children}) => {
  const [checked, setChecked] = useState(false);

  const handleLinkClick = useCallback(() => {
    setChecked(true);
    onCancelChange(true);
  }, [onCancelChange]);

  const handleCheckboxChange = useCallback(() => {
    setChecked(false);
    onCancelChange(false);
  }, [onCancelChange]);

  return (
    <span className={css.wrap}>
      <Link
        underline={!checked}
        unstyled
        inherit
        trackingName="cancelSubscription"
        onClick={handleLinkClick}
      >
        {children}
      </Link>
      {checked && (
        <RemarketingToggleSubscriptionCheckbox
          onChange={handleCheckboxChange}
        />
      )}
    </span>
  );
};

RemarketingToggleSubscriptionLink.propTypes /* remove-proptypes */ = {
  onCancelChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default RemarketingToggleSubscriptionLink;
