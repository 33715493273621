import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import useSiteName from '@core/application/utils/useSiteName';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import ProgressiveWebApplicationMotivationLayout from '../components/ProgressiveWebApplicationMotivationLayout';

const ProgressiveWebApplicationMotivation = ({closePopup}) => {
  const {siteName, loading, error} = useSiteName();

  if (loading) {
    return null;
  }

  if (error) {
    closePopup();
  }

  return (
    <Fragment>
      <ProgressiveWebApplicationMotivationLayout
        siteName={siteName}
        onButtonClick={closePopup}
      />
      <PopupLoadTracker />
    </Fragment>
  );
};

ProgressiveWebApplicationMotivation.propTypes /* remove-proptypes */ = {
  closePopup: PropTypes.func.isRequired,
};

export default ProgressiveWebApplicationMotivation;
