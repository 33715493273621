import React from 'react';
import {useQuery} from '@apollo/client';

import t from '@core/translations/translate';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import VIDEO_UPLOAD_SETTINGS_QUERY from '@core/mediaUpload/graphql/queries/videoUploadSettings.gql';
import BYTES_IN_MB from '@core/mediaUpload/constants/bytesInMb';

import {PlaceholderInput} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

const VideoRequirements = () => {
  const {data, loading, error} = useQuery(VIDEO_UPLOAD_SETTINGS_QUERY);

  if (loading) {
    return <PlaceholderInput />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  const {
    userFeatures: {
      userVideo: {
        settings: {maxSize, minLenght, maxLenght, limit},
      },
    },
  } = data;

  return (
    <div data-test="mediaRequirements">
      {t('popupVideoUploadMotivation', 'text.video_tooltip_size', {
        '{maximumVideoSize}': maxSize / BYTES_IN_MB,
      })}
      &nbsp;
      {t('popupVideoUploadMotivation', 'text.limit_videos', {
        '{maximumVideoAmount}': limit,
        '{minimumVideoDuration}': minLenght,
        '{maximumVideoDuration}': maxLenght,
      })}
      &nbsp;
      {t('popupVideoUploadMotivation', 'text.video_tooltip_max_duration', {
        '{maximumVideoDuration}': maxLenght,
      })}
      {/* To track 'photoUpload' and 'videoUpload' popup load completion */}
      <PopupLoadTracker />
    </div>
  );
};

export default VideoRequirements;
