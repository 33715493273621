// Tracking event name, used with actions below
export const NAME = 'rmPopupTracking';

// Tracking actions
export const PAY_CLICK = 'pay_click'; // @see RemarketingOfferPopupActions.js
export const APPLE_PAY_CLICK = 'apple_pay_click'; // @see RemarketingOfferPopupApplePayButton.js
export const CHECKBOX_CLICK = 'checkbox_click'; // @see RemarketingOfferPopupActions.js
export const CLOSE_CLICK = 'close_click'; // @see remarketingOfferAction.js
export const CHANGE_CLICK = 'change_click'; // @see RemarketingOfferPaymentDetails.js
export const POPUP_SHOW = 'popup_show'; // @see RemarketingOfferPopup.js

export const SECOND_ATTEMPT_TRACK_POSTFIX = '_2nd'; // @see openRemarketingOfferPopup.js RemarketingPopupModel.js
