import {makeVar} from '@apollo/client';

import {isPopupRoute} from '@phoenix/application/utils/getPopupBaseRoute';

/**
 * @see useDeferredPageAvailable.js
 * @type {Object}
 */
const deferredPageAvailableVar = makeVar(
  !isPopupRoute(window.location.pathname),
);

export default deferredPageAvailableVar;
