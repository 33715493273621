import MODERATOR_QUERY from '../graphql/queries/moderatorPage.gql';

/**
 * Moderator page access rule.
 */
export default {
  errorCode: 'NOT_ALLOWED',
  query: MODERATOR_QUERY,
  resolver: (data) => data.myUser && data.myUser.profile.statuses.isModerator,
};
