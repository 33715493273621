import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Link} from '@phoenix/typography';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

import css from '../styles/Disclaimer.css';

const handleOpenDisclaimer = (event) =>
  StaticPagePopupHelper.openPopup(StaticPagePopupHelper.PAGES.STATEMENT, event);

const Disclaimer = ({text, ...props}) => {
  return (
    <Link
      data-bypass=""
      data-test="footerDisclaimer"
      small
      muted
      underline
      className={css.text}
      trackingName="disclaimer"
      onClick={handleOpenDisclaimer}
      {...props}
    >
      {text || t('footer', 'text.disclaimer')}
    </Link>
  );
};

Disclaimer.propTypes /* remove-proptypes */ = {
  text: PropTypes.string,
};

export default Disclaimer;
