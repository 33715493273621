import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {ButtonPrimary, Icon} from '@phoenix/ui';
import {SpacingSize, IconSize} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

import css from '../styles/CustomPopupLayout.css';

const Item = ({children, isPhone}) => (
  <TextMuted className={cn(css.item, isPhone && css.fullWidth)}>
    <Icon className={css.icon} size={IconSize.SIZE_16} type="check" success />
    {children}
  </TextMuted>
);

Item.propTypes /* remove-proptypes */ = {
  children: PropTypes.string,
  isPhone: PropTypes.bool.isRequired,
};

const CustomPopupLayout = ({onButtonClick}) => {
  const buttonRef = useRef();
  const isPhone = usePhoneBreakpoint();

  /**
   * Dateinform popup relies on adding it's handler while click event
   * bubbling {@see DateinformHandler.trySubscribe}. That's why addEventListener is used here.
   * In case of using onClick={onButtonClick} Dateinfom's handler will be added after bubbling,
   * so it will not handle this click and the popup will not open.
   */
  useEffect(() => {
    const button = buttonRef.current;
    button.addEventListener('click', onButtonClick);
    return () => button.removeEventListener('click', onButtonClick);
  }, [onButtonClick]);

  return (
    <PopupNotice
      adaptive
      size={SpacingSize.LARGE}
      icon="loudspeaker"
      title={t('webPushNotification', 'title.subscribe')}
      description={
        <div className={css.description}>
          {t('webPushNotification', 'text.activities', {
            '{title}': <TextMuted className={css.subTitle} />,
            '{row}': <Item isPhone={isPhone} />,
          })}
        </div>
      }
      actions={[
        <ButtonPrimary inline fullWidth innerRef={buttonRef}>
          {t('webPushNotification', 'button.stayInformed')}
        </ButtonPrimary>,
      ]}
      data-test="webPushCustomPopup"
    />
  );
};

CustomPopupLayout.propTypes /* remove-proptypes */ = {
  onButtonClick: PropTypes.func.isRequired,
};

export default CustomPopupLayout;
