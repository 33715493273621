import React from 'react';

import photoPropType from '@core/user/photo/constants/propTypes';

import {PlaceholderCircle} from '@phoenix/ui';
import Photo from '@phoenix/user/photo/containers/Photo';

import css from '../styles/UserMotivation.css';

const UserMotivation = ({photo}) => {
  if (!photo) {
    return <PlaceholderCircle className={css.photo} />;
  }
  return (
    <Photo
      className={css.photo}
      photo={photo}
      lazy={false}
      size={Photo.SIZE.SMALL}
      withNaughtyMode={false}
      round
    />
  );
};

UserMotivation.propTypes /* remove-proptypes */ = {
  photo: photoPropType,
};

export default UserMotivation;
