import BaseTracker from '@core/tracking/base/BaseTracker';
import prepareData from '@core/tracking/base/prepareData';
import {RM_POPUP} from '@core/tracking/constants/identities';
import type {TrackRmPopupData} from '@core/types';

import TRACK_RM_POPUP_MUTATION from './graphql/mutations/trackRmPopup.gql';

/**
 * Private instance of tracking
 */
let rmPopupTracker: BaseTracker<
  {events: TrackRmPopupData[]},
  TrackRmPopupData
> | null = null;

/**
 * TRacks for remarketing popups (by Websocket API)
 */
const trackRmPopup = ({action, popupId, buttonTitle}: TrackRmPopupData) => {
  if (!rmPopupTracker) {
    rmPopupTracker = new BaseTracker(
      TRACK_RM_POPUP_MUTATION,
      prepareData,
      RM_POPUP.METHOD,
    );
  }

  rmPopupTracker.checkAndTrack(
    {action, popupId, ...(buttonTitle ? {buttonTitle} : null)},
    true,
  );
};

export default trackRmPopup;
