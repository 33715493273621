import React from 'react';
import PropTypes from 'prop-types';

import css from '../styles/ListItemWrapper.css';

/**
 * Used in cases when we have banner that is injected
 * between some content. It should add spacing between, and
 * we can't just use UI 'Row' component due to nesting and child order.
 */
const ListItemWrapper = ({children}) => (
  <div className={css.wrapper}>{children}</div>
);

ListItemWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
};

export default ListItemWrapper;
