import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesContext from '../../common/containers/MicrofeaturesContext';
import MicrofeaturesPaymentPopupFooterLayout from '../components/MicrofeaturesPaymentPopupFooterLayout';

/**
 * Info block with microfeature cost and time availability
 */
const MicrofeaturesPaymentPopupFooter = ({featureName}) => {
  // @see MicrofeaturesProvider.js
  const {getFeatureData} = useContext(MicrofeaturesContext);
  const featureData = getFeatureData({featureName});

  if (!featureData) {
    return null;
  }

  return (
    <MicrofeaturesPaymentPopupFooterLayout
      amount={featureData.microFeaturePackagePrice?.formatted?.amount}
      // difference in API for usual and coins microfeatures
      period={featureData.packageDatePeriod || featureData.period}
      currency={featureData.microFeaturePackagePrice?.currency}
      coins={featureData.creditAmount}
    />
  );
};

MicrofeaturesPaymentPopupFooter.propTypes /* remove-proptypes */ = {
  featureName: PropTypes.oneOf(Object.values(MICROFEATURES)).isRequired,
};

export default MicrofeaturesPaymentPopupFooter;
