import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import withStaticPaymentParamsProvider from '@core/payment/common/utils/withStaticPaymentParamsProvider';
import useActiveSplitGroup from '@core/utils/split/useActiveSplitGroup';
import {SPLITS} from '@core/utils/split';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {ButtonPrimary, Notice, PlaceholderBar} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {trackPaymentVisit} from '@phoenix/payment/pages/utils/useTrackPaymentVisit';

import {IconSize} from '../../icons/constants/IconSize';
import MicrofeaturesPaymentPopupFooter from './MicrofeaturesPaymentPopupFooter';
import {
  MICROFEATURES,
  TRANSLATION_TYPES,
} from '../../common/constants/microfeatures';
import {MICROFEATURES_ICON} from '../../icons/constants/iconComponents';
import MicrofeaturesContext from '../../common/containers/MicrofeaturesContext';
import usePayForMicroFeature from '../../common/utils/usePayForMicroFeature';
import usePayForMicrofeatureLegacy from '../../common/utils/usePayForMicrofeatureLegacy';
import useRoundMicrofeatureIcon from '../../common/utils/useRoundMicrofeatureIcon';
import getMicrofeaturesModelType from '../../common/utils/getMicrofeaturesModelType';
import {MODEL_TYPES} from '../../common/constants/modelTypes';

let translationNumber;

/**
 * Forms translation options for specific microfeatures
 * @param {string} featureName - one of MICROFEATURES
 * @param {object} sexAlarmOptions - sexAlarm data
 * @param {number} sexAlarmOptions.number - sexAlarm number of receivers
 * @param {string} sexAlarmOptions.message - sexAlarm message
 */
const getTranslationOptions = (featureName, sexAlarmOptions) => {
  if (
    featureName === MICROFEATURES.PRIORITY_MAN ||
    featureName === MICROFEATURES.SEARCH_QUEEN
  ) {
    if (!translationNumber) {
      translationNumber = Math.floor(Math.random() * 200) + 250;
    }

    return {
      '{number}': translationNumber,
    };
  }

  if (
    featureName === MICROFEATURES.SEX_ALARM ||
    featureName === MICROFEATURES.FLIRT_ALARM
  ) {
    return {
      '{number}': sexAlarmOptions.number,
      '{message}': sexAlarmOptions.message,
    };
  }

  return {};
};

const usePayForMicroFeatureForPopup = (isFetchApi) => {
  return isFetchApi ? usePayForMicroFeature : usePayForMicrofeatureLegacy;
};

/**
 * Separate microfeature payment popup
 */
const MicrofeaturesPaymentPopup = ({featureName}) => {
  // @see MicrofeaturesProvider.js
  const {getTranslation, getSexAlarmPopupData} =
    useContext(MicrofeaturesContext);
  const sexAlarmOptions = getSexAlarmPopupData();

  const {result: isFetchApi, loading} = useActiveSplitGroup(
    SPLITS.PAYMENT_PROCESSING_FETCH_API.ID,
    SPLITS.PAYMENT_PROCESSING_FETCH_API.GROUP.ACTIVE,
  );

  const {packagesError, packagesLoading, processingPayment, pay} =
    usePayForMicroFeatureForPopup(isFetchApi)({
      featureName,
    });

  const isRoundIcon = useRoundMicrofeatureIcon();

  useEffect(() => {
    if (!packagesError && !packagesLoading) {
      // Need for track pay in popup
      setPageEnterTime();

      trackPaymentVisit(PAYMENT_ACTIONS.MICRO_FEATURES, featureName);
    }
  }, [featureName, packagesError, packagesLoading]);

  const IconComponent = MICROFEATURES_ICON[featureName];

  if (packagesLoading || loading) {
    return (
      <PopupFrame
        content={
          <Notice
            spacing={SpacingSize.NONE}
            icon={<IconComponent size={IconSize.LARGE} round={isRoundIcon} />}
            title={<PlaceholderBar />}
            description={<PlaceholderBar />}
            actions={[<PlaceholderBar />]}
          />
        }
        footer={<MicrofeaturesPaymentPopupFooter featureName={featureName} />}
      />
    );
  }

  if (packagesError) {
    return <ErrorBoundary error={packagesError} />;
  }

  const popupTitle = getTranslation({
    type: TRANSLATION_TYPES.POPUP_TITLE,
    featureName,
  });
  const popupDescription = getTranslation({
    type: TRANSLATION_TYPES.POPUP_DESCRIPTION,
    featureName,
    options: getTranslationOptions(featureName, sexAlarmOptions),
  });
  const popupAction = getTranslation({
    type: TRANSLATION_TYPES.POPUP_ACTION,
    featureName,
  });

  return (
    <PopupFrame
      // data-test is needed for automated tests.
      data-test="microfeaturesPaymentPopup"
      content={
        <Notice
          spacing={SpacingSize.NONE}
          icon={<IconComponent size={IconSize.LARGE} round={isRoundIcon} />}
          title={popupTitle}
          description={<TextMuted>{popupDescription}</TextMuted>}
          actions={[
            <ButtonPrimary
              // data-test is needed for automated tests.
              data-test="microfeaturesPaymentPopupAction"
              onClick={pay}
              trackingName="upgrade"
              fullWidth
              adaptive
              disabled={processingPayment}
              disabledInteraction
            >
              {popupAction}
              {/* To track 'microfeaturesPayment' popup load completion */}
              <PopupLoadTracker />
            </ButtonPrimary>,
          ]}
        />
      }
      footer={<MicrofeaturesPaymentPopupFooter featureName={featureName} />}
    />
  );
};

MicrofeaturesPaymentPopup.propTypes /* remove-proptypes */ = {
  featureName: PropTypes.oneOf(Object.values(MICROFEATURES)).isRequired,
};

export default withStaticPaymentParamsProvider(
  MicrofeaturesPaymentPopup,
  ({featureName}) => ({
    action:
      getMicrofeaturesModelType() === MODEL_TYPES.CREDIT
        ? PAYMENT_ACTIONS.CREDIT
        : PAYMENT_ACTIONS.MICRO_FEATURES,
    via: featureName,
  }),
);
