import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import ExtraProfilesViewPopupLayout from '../../extraProfilesPopup/components/ExtraProfilesViewPopupLayout';

const openExtraProfilesViewPopup = ({popupConfig, ...props}) =>
  PopupService.openPopup(<ExtraProfilesViewPopupLayout expired {...props} />, {
    ...popupConfig,
    trackingName: 'extraProfilesViewWithDiscount',
    autoLoadTracking: true,
  });

export default openExtraProfilesViewPopup;
