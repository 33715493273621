import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Align, SpacingSize} from '@phoenix/ui/constants';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Group, PlaceholderBar, PlaceholderCircle, Spacing} from '@phoenix/ui';

import css from '../styles/WeekSpeakOfferPopupContent.css';

const WeekSpeakOfferPopupPlaceholder = ({isPhone}) => {
  return (
    <PopupFrame
      spacedContent={false}
      content={
        <Fragment>
          <div className={css.wrap}>
            <Spacing onlyTop>
              <Spacing>
                <Group space={SpacingSize.SHORT} align={Align.CENTER}>
                  <PlaceholderBar size={6} inline />
                  <Spacing space={SpacingSize.SHORT} onlyBottom>
                    <PlaceholderBar size={6} inline />
                  </Spacing>
                </Group>
              </Spacing>
            </Spacing>

            <div className={css.iconsWrap}>
              <PlaceholderCircle size={60} className={css.icon} inline />
              <PlaceholderCircle className={css.photo} inline />
            </div>
          </div>
          <Spacing
            size={isPhone ? SpacingSize.NONE : SpacingSize.NORMAL}
            onlyTop
          >
            <Spacing withoutBottom>
              <Group align={Align.CENTER}>
                <PlaceholderBar size={5} inline />
                <PlaceholderBar size={5} inline />
                <PlaceholderBar size={5} inline />
              </Group>
            </Spacing>
          </Spacing>
        </Fragment>
      }
      actions={
        <Group align={Align.CENTER}>
          <PlaceholderBar size={200} inline />
        </Group>
      }
    />
  );
};

WeekSpeakOfferPopupPlaceholder.propTypes /* remove-proptypes */ = {
  isPhone: PropTypes.bool,
};

export default WeekSpeakOfferPopupPlaceholder;
