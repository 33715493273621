import MicrofeaturesBannerAppearance from '@core/theming/constants/features/MicrofeaturesBannerAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

const useRoundMicrofeatureIcon = () => {
  const {data: bannerType} = useThemeFeature('microfeatures', 'banner');

  return (
    bannerType !==
      MicrofeaturesBannerAppearance.FLAT_WITHOUT_BORDER_AND_NOT_ROUND_ICON &&
    bannerType !== MicrofeaturesBannerAppearance.IN_CARD_WITHOUT_BORDER
  );
};

export default useRoundMicrofeatureIcon;
