import React, {useState, useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import first from 'lodash/first';
import find from 'lodash/find';
import get from 'lodash/get';
import {useHistory} from 'react-router-dom';

import PopupService from '@core/popup/utils/PopupService';
import logger from '@core/logger';
import PopupPriority from '@core/popup/constants/PopupPriority';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import createUrlFor, {USER_PROFILE_ROUTE} from '@core/utils/url/createUrlFor';

import ALLOW_EXTRA_POPUPS_MUTATION from '../graphql/mutations/allowExtraPopups.gql';
import SET_INTERACTIVE_LIKE_BONUS_MUTATION from '../graphql/mutations/setInteractiveLikeBonus.gql';
import userItem from '../constants/propTypes';
import InteractiveLikeLayout from '../components/InteractiveLikeLayout';
import InteractiveLikeWinLayout from '../components/InteractiveLikeWinLayout';
import setPaymentDiscount from '../../../utils/setPaymentDiscount';

/**
 * @const
 */
const STEPS = {
  FIRST_GAME: 'first',
  SECOND_GAME: 'second',
};

/**
 * The listener hangs up on the route change to show the discount pedal, we skip the process id from the current pad
 * @param {ApolloClient} client
 * @param {function} openDiscountPopup
 */
export const openDiscountPopupOnChangeRoute = (client, openDiscountPopup) => {
  const subscription = client
    .watchQuery({query: ROUTE_QUERY})
    .subscribe(({data}) => {
      const previous = get(data, 'route.previous', '') || '';

      if (previous.indexOf(USER_PROFILE_ROUTE) !== -1) {
        openDiscountPopup();

        subscription.unsubscribe();
      }
    });
};

const InteractiveLikePopup = ({
  users,
  extra,
  userIdToGuess,
  client,
  processId,
  openDiscountPopup,
}) => {
  const [checkedValue, setCheckedValue] = useState(first(users).profile.id);
  const [isMissed, setIsMissed] = useState(false);
  const step = useRef(STEPS.FIRST_GAME);
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (step.current === STEPS.FIRST_GAME) {
        /**
         * Enable to show discount popup.
         */
        client.mutate({
          mutation: ALLOW_EXTRA_POPUPS_MUTATION,
        });
      }
    };
  }, [client, step]);

  /**
   * Set a discount if the user started playing
   */
  const setDiscount = useCallback(
    (userId) => {
      client.mutate({
        mutation: SET_INTERACTIVE_LIKE_BONUS_MUTATION,
        variables: {
          userId,
          processId,
        },
        update: (cache, {data}) => {
          const errors = get(data, 'setInteractiveLikeBonus.errors', null);
          const result = get(data, 'setInteractiveLikeBonus.result', {});

          if (errors || !result.extra) {
            /**
             * Can't get discount data on docker
             */
            logger.sendError('[InteractiveLikePopup] Set discount error.');

            return;
          }

          setPaymentDiscount(result.extra, client);
        },
      });
    },
    [client, processId],
  );

  const handleActionClick = useCallback(() => {
    if (step.current === STEPS.SECOND_GAME && checkedValue !== userIdToGuess) {
      PopupService.closePopup();
      openDiscountPopup();

      return;
    }

    if (checkedValue !== userIdToGuess) {
      step.current = STEPS.SECOND_GAME;
      setIsMissed(true);

      return;
    }

    step.current = STEPS.SECOND_GAME;

    setDiscount(checkedValue);
    PopupService.closePopup();
    const user = find(users, {
      id: checkedValue,
    });

    PopupService.openPopup(
      <InteractiveLikeWinLayout
        user={user}
        handleClick={() => {
          history.push(createUrlFor.userProfile(user.profile.id));

          openDiscountPopupOnChangeRoute(client, openDiscountPopup);
        }}
      />,
      {
        trackingName: 'interactiveLikeWin',
        autoLoadTracking: true,
        small: true,
        priority: PopupPriority.LOW,
        onClose: (event) => {
          if (!event) return;

          openDiscountPopup();
        },
      },
    );
  }, [
    checkedValue,
    userIdToGuess,
    users,
    setDiscount,
    client,
    openDiscountPopup,
    history,
  ]);

  return (
    <InteractiveLikeLayout
      inverse
      users={users}
      handleChange={(event, value) => setCheckedValue(value)}
      handleActionClick={handleActionClick}
      isMissed={isMissed}
      checkedValue={checkedValue}
      extra={extra}
    />
  );
};

InteractiveLikePopup.propTypes /* remove-proptypes */ = {
  users: PropTypes.arrayOf(userItem).isRequired,
  userIdToGuess: PropTypes.string,
  processId: PropTypes.string.isRequired,
  // Arrived from 'withApollo'
  client: PropTypes.objectOf(PropTypes.any).isRequired,
  extra: PropTypes.number.isRequired,
  openDiscountPopup: PropTypes.func.isRequired,
};

export default InteractiveLikePopup;
