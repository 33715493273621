import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Checkbox, Icon, Popover} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import {
  PopperTrigger,
  PopperPlacement,
  VerticalAlign,
} from '@phoenix/ui/constants';

import css from '../styles/PmaCheckboxLayout.css';

const PmaCheckboxLayout = ({active, inverse = false, ...checkboxProps}) => {
  return (
    <div className={css.container}>
      <Checkbox
        name="pma"
        {...checkboxProps}
        checked={active}
        verticalAlign={VerticalAlign.CENTER}
        data-test="pmaCheckbox"
        inverse={inverse}
      >
        <div className={css.label}>
          <TextMuted inverse={inverse}>
            {t(
              'funnelPhotoUploadMotivation',
              'text.activate_promote_my_account',
            )}
          </TextMuted>
        </div>
      </Checkbox>
      <Popover
        trigger={PopperTrigger.HOVER_CLICK}
        placement={PopperPlacement.TOP_END}
        data-test="pmaHelpContent"
        content={
          <div className={css.pmaTooltip}>
            {t('funnelPhotoUploadMotivation', 'text.info_promote_my_account')}
          </div>
        }
        trackingName="help"
      >
        <div className={css.iconHelp} data-test="pmaHelp">
          <Icon type="help" muted inverse={inverse} />
        </div>
      </Popover>
    </div>
  );
};

PmaCheckboxLayout.propTypes /* remove-proptypes */ = {
  inverse: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default PmaCheckboxLayout;
