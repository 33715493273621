import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {LEVEL, SIZE} from '@core/user/photo/constants';
import formatMediaLevel from '@core/user/video/utils/formatMediaLevel';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {Overlay, ButtonPay, Spacing, RowShort} from '@phoenix/ui';
import {
  SpacingSize,
  ImageScaling,
  OverlaySaturation,
  Align,
} from '@phoenix/ui/constants';
import PayButtonLink from '@phoenix/payment/banners/upgradeButton/components/PayButtonLink';
import BlurredPhoto from '@phoenix/user/photo/components/BlurredPhoto';
import LevelOverlay from '@phoenix/user/photo/components/LevelOverlay';
import {Text} from '@phoenix/typography';
import useCoinsModel from '@phoenix/coins/utils/useCoinsModel';

import NaughtyModeSize from '../constants/NaughtyModeSize';
import {
  NAUGHTY_MODE_TEXTS,
  NAUGHTY_MODE_TEXTS_CLEAN,
} from '../constants/naughtyModeTexts';
import PlayButton from './PlayButton';
import VideoPreviewWrapper from './VideoPreviewWrapper';
import VideoPreviewWithOverlayLayout from './VideoPreviewWithOverlayLayout';
import getLevelOverlayDescription from '../../photo/utils/getLevelOverlayDescription';
import css from '../styles/VideoPreviewLayout.css';

const VideoPreviewLayout = ({
  url,
  videoPreviewUrl,
  level: levelFromProps,
  blurLevel: blurLevelFromProps,
  forceBlur: forceBlurFromProps,
  showOverlay = true,
  showButton = true,
  buttonType,
  naughtySize = NaughtyModeSize.FULL,
  // By default preview without naughty mode will have native scaling
  scaling = ImageScaling.CONTAIN,
  fullHeight,
  size = SIZE.SMALL,
  centered,
  mediaCounter = 0,
  mediaCounters,
  mediaUnlockPrice,
  spacedPlayButton = false,
  payButtonText,
  payButtonUrl,
  action,
  shadow,
  round,
  text = null,
  trackingName = 'userVideo',
  onClick,
  onLoad,
  withCleanProductRestrictions,
  showCompactPlayButton,
  customOverlay,
  withBigTextSize = false,
  showNavigationOverlay = false,
  withAutoplay,
}) => {
  const isPhone = usePhoneBreakpoint();
  const {isCoinsModel} = useCoinsModel();

  const {level, blurLevel} = formatMediaLevel(
    levelFromProps,
    blurLevelFromProps,
    forceBlurFromProps,
  );

  const naughtyLevel = level !== LEVEL.NORMAL;
  const PayBtn = payButtonUrl ? PayButtonLink : ButtonPay;

  // Simplify checking for detection of hidden counter availability
  const showMediaCounter = Boolean(mediaCounter);
  const showNaughtyOverlay = !showMediaCounter && naughtyLevel && showOverlay;

  const showPlayButton =
    !showMediaCounter && showButton && !showCompactPlayButton;

  let texts = withCleanProductRestrictions
    ? NAUGHTY_MODE_TEXTS_CLEAN
    : NAUGHTY_MODE_TEXTS;

  if (text) {
    texts = {
      DEFAULT: {
        DEFAULT: text,
      },
    };
  }

  return (
    <VideoPreviewWrapper
      onClick={onClick}
      trackingName={trackingName}
      round={round}
      naughtyLevel={naughtyLevel}
      naughtySize={naughtySize}
      shadow={shadow}
      showMediaCounter={showMediaCounter}
      customOverlay={customOverlay}
    >
      {videoPreviewUrl && !isCoinsModel ? (
        <VideoPreviewWithOverlayLayout
          withAutoplay={withAutoplay}
          videoPreviewUrl={videoPreviewUrl}
          centered={centered}
          scaling={scaling}
          description={
            naughtyLevel
              ? getLevelOverlayDescription({
                  texts,
                  size,
                  level,
                })
              : null
          }
          payButtonText={payButtonText}
          payButtonUrl={payButtonUrl}
          showPlayButton={
            showPlayButton && !payButtonText && !payButtonUrl && !naughtyLevel
          }
          spacedActions={showNavigationOverlay}
          buttonType={buttonType}
        />
      ) : (
        <>
          <BlurredPhoto
            url={url}
            level={blurLevel || level}
            onLoad={onLoad}
            scaling={naughtyLevel ? ImageScaling.COVER : scaling}
            fullHeight={fullHeight}
          />
          {showNaughtyOverlay && !customOverlay && (
            <LevelOverlay
              size={size}
              level={level}
              texts={texts}
              withBigTextSize={withBigTextSize}
              className={css.preventEvents}
              textClassName={cn(showPlayButton && css.levelDescription)}
            >
              {showPlayButton && (
                <Spacing
                  size={
                    spacedPlayButton ? SpacingSize.NORMAL : SpacingSize.NONE
                  }
                  withoutLeft
                  withoutRight
                >
                  <PlayButton centered={false} type={buttonType} />
                </Spacing>
              )}
            </LevelOverlay>
          )}
          {!showNaughtyOverlay && (
            <>
              {mediaCounters}
              {showPlayButton && <PlayButton type={buttonType} />}
            </>
          )}
          {payButtonText && !forceBlurFromProps && (
            <>
              {!showNaughtyOverlay && (
                <Overlay saturation={OverlaySaturation.LITE} />
              )}
              <Spacing
                size={isPhone ? SpacingSize.NORMAL : SpacingSize.SHORT}
                className={css.payButton}
                withoutTop
              >
                {showCompactPlayButton && (
                  <Spacing onlyBottom>
                    <PlayButton type={buttonType} centered={false} />
                  </Spacing>
                )}
                {action || (
                  <PayBtn
                    fullWidth
                    {...(payButtonUrl ? {href: payButtonUrl} : {})}
                  >
                    {payButtonText}
                  </PayBtn>
                )}
                {mediaUnlockPrice && (
                  <RowShort align={Align.CENTER}>{mediaUnlockPrice}</RowShort>
                )}
              </Spacing>
            </>
          )}
          {showMediaCounter && (
            <Overlay saturation={OverlaySaturation.LITE}>
              <Text className={css.centered} inverse>
                {`+${mediaCounter}`}
              </Text>
            </Overlay>
          )}
        </>
      )}
    </VideoPreviewWrapper>
  );
};

VideoPreviewLayout.propTypes /* remove-proptypes */ = {
  size: PropTypes.oneOf(Object.values(SIZE)),
  url: PropTypes.string.isRequired,
  videoPreviewUrl: PropTypes.string,
  level: PropTypes.oneOf(Object.values(LEVEL)).isRequired,
  blurLevel: PropTypes.oneOf(Object.values(LEVEL)),
  forceBlur: PropTypes.bool,
  showOverlay: PropTypes.bool,
  trackingName: PropTypes.string,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
  round: PropTypes.bool,
  centered: PropTypes.bool,
  // Size for preview photo with naughty mode.
  naughtySize: PropTypes.oneOf(Object.values(NaughtyModeSize)),
  // Scaling for preview photo, need for adding preview in different ways(e.x for full @see MessageVideo.js)
  scaling: PropTypes.oneOf(Object.values(ImageScaling)),
  spacedPlayButton: PropTypes.bool,
  mediaCounter: PropTypes.number,
  mediaCounters: PropTypes.node,
  mediaUnlockPrice: PropTypes.node,
  fullHeight: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonType: PropTypes.string,
  showNavigationOverlay: PropTypes.bool,
  payButtonText: PropTypes.string,
  action: PropTypes.node,
  text: PropTypes.string,
  payButtonUrl: PropTypes.string,
  shadow: PropTypes.bool,
  withCleanProductRestrictions: PropTypes.bool,
  showCompactPlayButton: PropTypes.bool,
  customOverlay: PropTypes.node,
  withBigTextSize: PropTypes.bool,
  withAutoplay: PropTypes.bool,
};

export default VideoPreviewLayout;
