import {from} from 'rxjs';
import {switchMapTo, filter} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import isInRouteList from '@core/utils/routing/isInRouteList';
import PopupService from '@core/popup/utils/PopupService';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

import VerificationStatus from '@phoenix/personalIdentity/constants/verificationStatus';
import * as DOCUMENT_STATUSES from '@phoenix/personalIdentity/constants/documentStatus';

import openWaiteForApprovePopup from './openWaiteForApprovePopup';
import openPersonalIdentityByDemandRequirePopup from './openPersonalIdentityByDemandRequirePopup';
import openPersonalIdentityByDemandFailPopup from './openPersonalIdentityByDemandFailPopup';
import PERSONAL_IDENTITY_ON_DEMAND_QUERY from '../graphql/queries/personalIdentityByDemand.gql';

const startVerificationChangeListener = () => {
  const client = getClientInstance();
  let isOpened = false;
  const onClose = () => {
    isOpened = false;
  };

  from(client.watchQuery({query: ROUTE_QUERY}))
    .pipe(
      filter(({data}) => {
        /**
         * Need to open only one time.
         * It prevents to resubscribe and double open popup if route change
         * @see Search, UrlUpdater - route /search change to /search?[search params]
         */
        return (
          !isInRouteList(ROUTES_WITH_POPUP, data.route.current) && !isOpened
        );
      }),
      switchMapTo(
        client.watchQuery({
          query: PERSONAL_IDENTITY_ON_DEMAND_QUERY,
        }),
      ),
    )
    .subscribe(
      ({
        data: {
          userFeatures: {
            personalIdentity: {documentStatus, verificationStatus},
          },
        },
      }) => {
        if (verificationStatus === VerificationStatus.DISAPPROVED) {
          PopupService.closePopup(true);
          if (documentStatus === DOCUMENT_STATUSES.NOT_EXIST) {
            isOpened = true;
            openPersonalIdentityByDemandRequirePopup(onClose);
          } else if (documentStatus === DOCUMENT_STATUSES.DISAPPROVED) {
            isOpened = true;
            openPersonalIdentityByDemandFailPopup(onClose);
          } else if (documentStatus === DOCUMENT_STATUSES.PENDING_TO_APPROVE) {
            isOpened = true;
            openWaiteForApprovePopup(onClose);
          }
        }

        /**
         * Need to close popup, if isOpened === true and documentStatus was changed to approved
         * Because interaction 'changePersonalIdentity' can change before documentStatusChange
         */
        if (
          documentStatus === DOCUMENT_STATUSES.APPROVED &&
          isOpened === true
        ) {
          PopupService.closePopup(true);
        }
      },
    );
};

export default startVerificationChangeListener;
