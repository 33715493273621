import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import ExtraDiscountPopupLayout from '../components/ExtraDiscountPopupLayout';

const openExtraDiscountPopup = ({popupConfig, ...props}) =>
  PopupService.openPopup(<ExtraDiscountPopupLayout {...props} />, {
    ...popupConfig,
    inverseCloseButton: true,
    trackingName: `extraDiscount_${props.holiday}_${props.comboContext || ''}`,
    autoLoadTracking: true,
  });

export default openExtraDiscountPopup;
