import type {FC} from 'react';
import React from 'react';

import PhotoLevel from '@core/user/photo/constants/PhotoLevel';

import {Overlay} from '@phoenix/ui';
import type {OverlaySaturation, OverlayType} from '@phoenix/ui/constants';

import css from '../styles/PhotoOverlay.css';

export type PhotoOverlayProps = {
  level: PhotoLevel;
  overlaySaturation: OverlaySaturation;
  overlayType: OverlayType;
  overlayFullSize?: boolean;
};

const PhotoOverlay: FC<PhotoOverlayProps> = ({
  level = PhotoLevel.NORMAL,
  overlaySaturation,
  overlayType,
  overlayFullSize = true,
}) => {
  if (overlaySaturation || overlayType) {
    const overlay = (
      <Overlay
        saturation={overlaySaturation}
        type={overlayType}
        fullSize={overlayFullSize}
        className={css.overlay}
      />
    );

    return level === PhotoLevel.NORMAL ? (
      <div className={css.overlayWrap}>{overlay}</div>
    ) : (
      overlay
    );
  }

  return null;
};

export default PhotoOverlay;
