import PMA_QUERY from '../graphql/queries/pma.gql';
import PMA_MUTATION from '../graphql/mutations/pma.gql';

/**
 * Updates the PMA feature settings.
 * @param {ApolloClient} client
 * @param {object} variables
 * @param {boolean} variables.activated
 * @param {number} [variables.messageTheme]
 * @return {Promise<boolean>}
 */
const savePmaSettings = async (client, variables) => {
  const result = await client.mutate({
    mutation: PMA_MUTATION,
    variables,
  });

  if (result.data.userFeatures.pma.result) {
    const data = client.readQuery({query: PMA_QUERY});

    if (data) {
      client.writeQuery({
        query: PMA_QUERY,
        data: {
          ...data,
          userFeatures: {
            ...data.userFeatures,
            pma: {
              ...data.userFeatures.pma,
              ...variables,
            },
          },
        },
      });
    }

    return true;
  }

  return false;
};

export default savePmaSettings;
