import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import t from '@core/translations/translate';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import {Spacing, ButtonPay, Group, Actions} from '@phoenix/ui';
import {TextMuted, H3, Text} from '@phoenix/typography';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import useDeferredPageAvailable from '@phoenix/application/deferredPage/useDeferredPageAvailable';

import RemarketingOfferPopupApplePayButton from '../containers/RemarketingOfferPopupApplePayButton';
import css from '../styles/RemarketingOfferPopupActionsLayout.css';

const DAYS_IN_MONTH = 28;

const RemarketingOfferPopupActionsLayout = ({
  isTrialMembership,
  isPaymentProcessing,
  loading,
  onPayClick,
  footerDNSMPI,
  showTitle,
  interval,
  paymentButtonTitle,
  discountText,
  walletMethods,
  trackingConceptName,
  cancelRemarketingSubscription,
}) => {
  const isSmallTablet = useSmallTabletBreakpoint();
  const {setDeferredPageAvailable} = useDeferredPageAvailable();

  useEffect(() => {
    setDeferredPageAvailable(true);
  }, [setDeferredPageAvailable]);

  return (
    <Spacing size={isSmallTablet ? SpacingSize.SHORT : SpacingSize.NONE}>
      <Group
        align={Align.CENTER}
        itemWrapperClassName={css.row}
        space={
          footerDNSMPI && discountText ? SpacingSize.SHORT : SpacingSize.NORMAL
        }
      >
        {showTitle && (
          <H3 align={H3.ALIGN.CENTER} highlighted>
            {isTrialMembership || interval < DAYS_IN_MONTH
              ? t('remarketingPopup', 'text.get_membership', interval)
              : t(
                  'remarketingPopup',
                  'text.get_month_membership',
                  interval / DAYS_IN_MONTH,
                )}
          </H3>
        )}
        <Actions
          reverse={!isSmallTablet}
          itemSpacing={SpacingSize.SHORT}
          align={Align.CENTER}
          vertical={isSmallTablet}
        >
          {walletMethods?.includes(WALLET_METHODS.APPLE_PAY) && (
            <RemarketingOfferPopupApplePayButton
              trackingConceptName={trackingConceptName}
              cancelRemarketingSubscription={cancelRemarketingSubscription}
            />
          )}
          <ButtonPay
            fullWidth
            data-test="remarketingOfferPopupPay"
            adaptive={!isSmallTablet}
            onClick={onPayClick}
            trackingName="upgrade"
            disabled={isPaymentProcessing || loading}
            disabledInteraction
            className={css.button}
          >
            {paymentButtonTitle}
            {/* To track 'remarketingOffer' popup load completion */}
            {!loading && <PopupLoadTracker />}
          </ButtonPay>
        </Actions>
        {discountText && (
          <TextMuted align={Text.ALIGN.CENTER} small>
            {discountText}
          </TextMuted>
        )}
        {footerDNSMPI && (
          <TextMuted align={Text.ALIGN.CENTER}>{footerDNSMPI}</TextMuted>
        )}
      </Group>
    </Spacing>
  );
};

RemarketingOfferPopupActionsLayout.propTypes /* remove-proptypes */ = {
  isTrialMembership: PropTypes.bool,
  isPaymentProcessing: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onPayClick: PropTypes.func.isRequired,
  footerDNSMPI: PropTypes.node,
  showTitle: PropTypes.bool,
  cancelRemarketingSubscription: PropTypes.bool.isRequired,
  trackingConceptName: PropTypes.string.isRequired,
  walletMethods: PropTypes.arrayOf(PropTypes.string),
  interval: PropTypes.number.isRequired,
  paymentButtonTitle: PropTypes.string.isRequired,
  discountText: PropTypes.node,
};

export default RemarketingOfferPopupActionsLayout;
