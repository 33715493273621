import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import CANCEL_REPEAT_STATUS from '../constants/cancelRepeatStatus';
import CancelRepeatSuccessfulPopup from '../containers/CancelRepeatSuccessfulPopup';
import CancelRepeatUnsuccessfulPopup from '../containers/CancelRepeatUnsuccessfulPopup';

/**
 * Shows needed popup by type
 * @param {string} type
 * @param {Function} onClose
 */
const showCancelRepeatPopup = ({
  type,
  onClose = PopupService.closePopup,
} = {}) => {
  const popups = {
    [CANCEL_REPEAT_STATUS.SUCCESSFUL]: {
      popup: <CancelRepeatSuccessfulPopup onCloseClick={onClose} />,
      trackingName: 'cancelRepeatSuccessful',
    },
    [CANCEL_REPEAT_STATUS.UNSUCCESSFUL]: {
      popup: <CancelRepeatUnsuccessfulPopup />,
      trackingName: 'cancelRepeatUnsuccessful',
      autoLoadTracking: true,
    },
  };

  popups[type] &&
    PopupService.openPopup(popups[type].popup, {
      small: true,
      onClose,
      trackingName: popups[type].trackingName,
    });
};

export default showCancelRepeatPopup;
