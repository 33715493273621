import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import COIN_AMOUNT from '../constants/coinAmount';
import getColorizedIconFactory from '../utils/getColorizedIconFactory';
import COIN_ICON_SIZES from '../constants/coinColorizedIconSizes';
import css from '../styles/CoinColorizedIconByAmount.css';

/**
 * Create colorized icon by type
 * TODO: don`t use this component for adding an icon with amount == ONE,
 * in this case use CoinColorizedIcon component
 * @returns {JSX.Element}
 * @see PaymentCoinsPackage
 */
const CoinColorizedIconByAmount = ({
  amount = COIN_AMOUNT.ONE,
  size = COIN_ICON_SIZES.SMALLEST,
  inline = false,
  textTop = true,
  className,
}) => (
  <div
    className={cn(
      inline && css.inline,
      textTop && css.textTop,
      css[size],
      className,
    )}
  >
    {getColorizedIconFactory(amount, css.svg)}
  </div>
);

CoinColorizedIconByAmount.AMOUNT = COIN_AMOUNT;

CoinColorizedIconByAmount.SIZE = COIN_ICON_SIZES;

CoinColorizedIconByAmount.propTypes /* remove-proptypes */ = {
  amount: PropTypes.oneOf(Object.values(COIN_AMOUNT)),
  inline: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(COIN_ICON_SIZES)),
  className: PropTypes.string,
  textTop: PropTypes.bool,
};

export default CoinColorizedIconByAmount;
