import type {FC} from 'react';
import {useEffect} from 'react';
import {useQuery} from '@apollo/client';

import getCookie from '@core/utils/cookie/getCookie';

import usePromotionInterceptor from '@phoenix/progressiveWebApplication/utils/usePromotionInterceptor';

import PWA_SCENARIO_QUERY from '../graphql/queries/pwaScenario.gql';
import type {PwaScenarioQuery} from '../graphql/queries/pwaScenario';
import {PROMO} from '../constants/scenarios';
import {TEST_PWA} from '../constants/progressiveWebApplicationCookies';

const ProgressiveWebApplication: FC = () => {
  const {data} = useQuery<PwaScenarioQuery>(PWA_SCENARIO_QUERY);

  const scenario = data?.pwa?.scenario;

  const {intercept, promote} = usePromotionInterceptor();

  /**
   * This is a test solution for simulating the activation of an interceptor and triggering a popup when needed.
   */
  useEffect(() => {
    if (scenario === PROMO) {
      // Activate the interceptor
      intercept(true);

      if (getCookie(TEST_PWA)) {
        document.onclick = async () => {
          // Call popup
          await promote();
        };
      }
    }
  }, [scenario, intercept, promote]);

  /**
   * Here we can add worker for PWA to promote it in old browsers, it logic here to separate from web push logic.
   * Here if web push worker hasn't added we can add worker here for PWA promotion.
   */
  useEffect(() => {
    if (
      scenario &&
      'serviceWorker' in navigator &&
      !navigator.serviceWorker.controller
    ) {
      navigator.serviceWorker.register(STATIC_FILES.PWA_FETCH);
    }
  }, [scenario]);

  return null;
};

export default ProgressiveWebApplication;
