import {useEffect} from 'react';
import {useQuery, useMutation} from '@apollo/client';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import DEFAULT_ROUTE_QUERY from '@core/utils/routing/graphql/queries/defaultRoute.gql';
import CURRENT_USER_ID_QUERY from '@core/user/profile/current/graphql/queries/currentUserId.gql';
import FIRST_LOGIN_MUTATION from '@core/user/profile/current/graphql/mutations/firstLogin.gql';
import isInRouteList from '@core/utils/routing/isInRouteList';
import {localStorage} from '@core/utils/storage/storage';

import FIRST_LOGIN_DELAYED_SET_ALLOWED_QUERY from '../graphql/queries/firstLoginDelayedSetAllowed.gql';

/**
 * First routes in different registration scenarios which can be counted as 'logged in'
 * @type {string[]}
 *
 * export for unit tests
 */
export const AVAILABLE_ROUTES = [
  '/funnel/paid',
  '/funnel/confirm',
  '/funnel/photoUpload',
];

// export for unit tests
export const STORAGE_KEY = 'FIRST_LOGIN_SET';

const useFirstLoginData = () => {
  const {data} = useQuery(DEFAULT_ROUTE_QUERY);
  const {data: currentUserIdData} = useQuery(CURRENT_USER_ID_QUERY);
  const {data: setDelayedAllowedData, loading} = useQuery(
    FIRST_LOGIN_DELAYED_SET_ALLOWED_QUERY,
  );

  return {
    loading,
    myUserId: get(currentUserIdData, 'myUser.id'),
    defaultRoute: get(data, 'site.defaultRoute'),
    firstLoginDelayedSetAllowed: get(
      setDelayedAllowedData,
      'userFeatures.firstLoginDelayedSetAllowed',
    ),
  };
};

const FirstLoginChecker = ({pathname}) => {
  const {loading, myUserId, defaultRoute, firstLoginDelayedSetAllowed} =
    useFirstLoginData();
  const storageKey = `${STORAGE_KEY}:${myUserId}`;
  const [setFirstLogin] = useMutation(FIRST_LOGIN_MUTATION, {
    update() {
      localStorage.setItem(storageKey, true);
    },
  });

  const firstLoginSet = localStorage.getItem(storageKey);

  useEffect(() => {
    if (!loading && !firstLoginSet) {
      let availableRoutes = [defaultRoute];
      if (!firstLoginDelayedSetAllowed) {
        availableRoutes = [...AVAILABLE_ROUTES, defaultRoute];
      }
      if (isInRouteList(availableRoutes, pathname)) {
        setFirstLogin();
      }
    }
  }, [
    firstLoginSet,
    pathname,
    defaultRoute,
    setFirstLogin,
    loading,
    firstLoginDelayedSetAllowed,
  ]);

  return null;
};

FirstLoginChecker.propTypes /* remove-proptypes */ = {
  pathname: PropTypes.string.isRequired,
};

export default FirstLoginChecker;
