import React from 'react';

import PaymentFooterCommon from '@core/payment/widgets/footer/containers/PaymentFooter';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import PaymentFooterLayout from '../components/PaymentFooterLayout';
import PaymentFooterPlaceholder from '../components/PaymentFooterPlaceholder';

const PaymentFooter = (props) => (
  <PaymentFooterCommon
    layout={PaymentFooterLayout}
    placeholder={PaymentFooterPlaceholder}
    errorBoundary={ErrorBoundary}
    {...props}
  />
);

export default PaymentFooter;
