import {filter, take} from 'rxjs/operators';

import isPayUrl from '@core/utils/url/isPayUrl';
import isInRouteList from '@core/utils/routing/isInRouteList';
import ROUTES_WITH_POPUP, {
  FUNNEL,
} from '@core/application/constants/routesWithPopup';

import WebPushNotification from '../notification/WebPushNotification';
import getRouteObservable from './getRouteObservable';

/**
 * Initializes WepPush notification logic once after navigating to an allowed route
 * @param client
 * @param currentUserId
 */
const startWebPushListener = (client, currentUserId) => {
  getRouteObservable(client)
    .pipe(
      filter(
        ({data}) =>
          /**
           * WebPush popups shouldn't be displayed before funnel popup or on PP
           */
          !isPayUrl(data.route.current) &&
          !isInRouteList([...ROUTES_WITH_POPUP, FUNNEL], data.route.current),
      ),
      take(1),
    )
    .subscribe(() => {
      WebPushNotification.initialize(currentUserId);
    });
};

export default startWebPushListener;
