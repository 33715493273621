import defaults from 'lodash/defaults';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import max from 'lodash/max';
import {fromEvent} from 'rxjs';
import {takeUntil, finalize} from 'rxjs/operators';

import {sessionStorage} from '@core/utils/storage';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';

import STORAGE_KEY from '../constants/storageKey';
import NOTIFICATION_EVENT from '../constants/notificationEvent';

/**
 * A mediator containing all the logics' objects and necessary methods to deal with them
 *
 * @param {object} options
 * @param {object} options.storage
 * @param {number} options.pauseAfterIgnore
 * @param {number} options.pauseAfterDeny
 * @param {number} options.disableAfterIgnoreCount
 * @param {number} options.disableAfterDenyCount
 * @class
 */
export default class LogicsMediator {
  /**
   * @constant
   * @type {object}
   */
  COUNTER_TYPES = {
    IGNORE: 'ignore',
    DENY: 'deny',
  };

  /**
   * @constant
   * @type {number}
   */
  DEFAULT_PAUSE_AFTER_DENY = 20000 * 60;

  /**
   * shows whether the popup is opened or not
   * @type {boolean}
   */
  isPopupOpened = false;

  constructor(options) {
    this.options = defaults(options, {
      /**
       * An array to store all the registered logics
       * @type {Array}
       */
      logics: [],

      /**
       * Pause all the logics after denying one
       * @type {number}
       */
      pauseAfterIgnore: 0,

      /**
       * Pause all the logics after denying one
       * @type {number}
       */
      pauseAfterDeny: this.DEFAULT_PAUSE_AFTER_DENY,

      /**
       * Disable all the logics if an ignoring counter reaches this value
       * @type {number}
       */
      disableAfterIgnoreCount: 2,

      /**
       * Disable all the logics if a denial counter reaches this value
       * @type {number}
       */
      disableAfterDenyCount: 0,

      /**
       * @type {string}
       */
      userId: '',
    });

    this.initPopupStateListener();
  }

  /**
   * Changes the isPopupOpened flag until the notification is accepted
   * @returns {Subscription}
   */
  initPopupStateListener() {
    fromEvent(document, POPUP_STATE_CHANGE_EVENT)
      .pipe(
        takeUntil(fromEvent(window, NOTIFICATION_EVENT.ACCEPTED)),
        finalize(() => this.stopAll()),
      )
      .subscribe(({detail}) => {
        this.isPopupOpened = detail.hasQueue;
      });
  }

  /**
   * Stop all the event listeners for all the logics
   * @private
   */
  stopAll() {
    this.options.logics.forEach((logic) => {
      logic.onSubscriptionAccepted?.();
      logic.removeListeners();
    });
  }

  /**
   * @param {string} type
   * @return {string}
   * @private
   */
  getCountKey(type) {
    if (type === this.COUNTER_TYPES.IGNORE) {
      return this.getStorageKey(STORAGE_KEY.IGNORE_COUNT);
    }

    return this.getStorageKey(STORAGE_KEY.DENY_COUNT);
  }

  /**
   * @param {string} type
   * @return {number}
   * @private
   */
  getCount(type) {
    return sessionStorage.getItem(this.getCountKey(type));
  }

  /**
   * @param {string} type
   * @param {number} value
   * @private
   */
  setCount(type, value) {
    sessionStorage.setItem(this.getCountKey(type), value);
  }

  /**
   * @param {number} delay
   * @private
   */
  setPauseExpirationTime(delay) {
    const key = this.getStorageKey(STORAGE_KEY.PAUSE_EXPIRATION_TIME);

    sessionStorage.setItem(key, Date.now() + delay);
  }

  /**
   * The entry point to register a logic
   * @param {Object} logic
   * @public
   */
  register(logic) {
    this.options.logics.push(logic);
  }

  /**
   * Some logics may start a subscription right after their initialization,
   * therefore, there is a need to run them in descending order by their priority
   * @public
   */
  setup() {
    const sortedByPriorities = sortBy(
      this.options.logics,
      (logic) => -logic.priority,
    );

    sortedByPriorities.forEach((logic) => {
      if (!logic.isSetupDisabled()) {
        logic.setup();
      }
    });
  }

  /**
   * Purge all the timeouts data for all the logics
   * @public
   */
  purgeAllTimeoutsData() {
    this.options.logics.forEach((logic) => logic.purgeTimeoutData?.());
  }

  /**
   * Go through all the logics and determine whether the one intending to show up has the highest priority
   * @param {number} priority
   * @return {boolean}
   * @public
   */
  isHighestPriority(priority) {
    const priorities = map(
      filter(this.options.logics, {
        isActiveTimeout: true,
      }),
      'priority',
    );

    return max(priorities) || priority >= 0;
  }

  /**
   * @public
   */
  onDeny() {
    const type = this.COUNTER_TYPES.DENY;
    this.options.disableAfterDenyCount &&
      this.setCount(type, (this.getCount(type) || 0) + 1);
    this.options.pauseAfterDeny &&
      this.setPauseExpirationTime(this.options.pauseAfterDeny);
  }

  /**
   * @public
   */
  onIgnore() {
    const type = this.COUNTER_TYPES.IGNORE;
    this.options.disableAfterIgnoreCount &&
      this.setCount(type, (this.getCount(type) || 0) + 1);
    this.options.pauseAfterIgnore &&
      this.setPauseExpirationTime(this.options.pauseAfterIgnore);
  }

  /**
   * Whether the logics are disabled
   * @return {boolean}
   * @public
   */
  isCountersLimitReached() {
    return (
      (this.options.disableAfterDenyCount &&
        this.getCount(this.COUNTER_TYPES.DENY) >=
          this.options.disableAfterDenyCount) ||
      (this.options.disableAfterIgnoreCount &&
        this.getCount(this.COUNTER_TYPES.IGNORE) >=
          this.options.disableAfterIgnoreCount)
    );
  }

  /**
   * @return {boolean}
   * @public
   */
  isPaused() {
    const key = this.getStorageKey(STORAGE_KEY.PAUSE_EXPIRATION_TIME);

    return Date.now() < (sessionStorage.getItem(key) || 0);
  }

  /**
   * @public
   * @param {string} key
   * @return {string}
   */
  getStorageKey(key) {
    return `WebPush::OM::${key}::${this.options.userId}`;
  }
}
