import openMessengerWithCommon from '@core/messenger/common/utils/openMessengerWith';
import sendToGTM from '@core/gtm/utils/sendToGTM';
import EventName from '@core/gtm/constants/EventName';

import markChatAsOpened from './markChatAsOpened';

/**
 * Is used to open chat with specified user.
 * @param {Object} params
 * @param {String} userId - user id
 * @param {Boolean} replace - replace current page
 * @param {Boolean} skipMiniMessenger - skip mini messenger opening
 * @param {Boolean} needGTMTrack - need track GTM or not
 * @param {Boolean} isExHumanAssistant - need to send a mutation
 * @return {Promise}
 */
const openMessengerWith = async ({
  userId,
  replace = false,
  skipMiniMessenger = false,
  isExHumanAssistant = false,
  needGTMTrack = false,
} = {}) => {
  if (needGTMTrack) {
    sendToGTM({
      eventName: EventName.OPEN_CHAT,
    });
  }

  if (isExHumanAssistant) {
    markChatAsOpened(userId);
  }

  return openMessengerWithCommon({userId, replace, skipMiniMessenger});
};

export default openMessengerWith;
