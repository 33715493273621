import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import InAppMotivationPopupWithRandomUser from '../containers/InAppMotivationPopupWithRandomUser';
import InAppMotivationPopupWithTargetUser from '../containers/InAppMotivationPopupWithTargetUser';
import {DEFAULT} from '../constants/motivationTypes';
import InAppPopupMotivationWithoutUser from '../containers/InAppPopupMotivationWithoutUser';

const getInAppMotivationPopup = (type, userId) => {
  if (type === DEFAULT) {
    return InAppPopupMotivationWithoutUser;
  }

  return userId
    ? InAppMotivationPopupWithTargetUser
    : InAppMotivationPopupWithRandomUser;
};

/**
 * @param {Object} params
 * @param {string} params.motivationType
 * @param {string} [params.linkType]
 * @param {string} [params.userId]
 * @param {boolean} [params.alterBtns]
 * @param {Function} [params.onClose]
 */
const openInAppMotivationPopup = ({
  linkType,
  motivationType,
  userId,
  alterBtns = false,
  onClose,
}) => {
  const popupProps = {
    type: motivationType,
    linkType,
    alterBtns,
    userId,
    onClose,
  };

  const InAppMotivationPopup = getInAppMotivationPopup(motivationType, userId);

  PopupService.openPopup(<InAppMotivationPopup {...popupProps} />, {
    small: true,
    trackingName: 'inAppMotivation',
    trackingConceptId: motivationType,
    closeByNavigation: false,
    showCloseButton: !alterBtns,
    onClose,
  });
};

export default openInAppMotivationPopup;
