import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import useReCaptcha from '@core/massBlocked/utils/useReCaptcha';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import MountTracker from '@core/tracking/babcia/containers/MountTracker';

import {H3, TextDanger} from '@phoenix/typography';

import css from '../styles/CaptchaLayout.css';

const CaptchaLayout = forwardRef(
  ({reason, error, publicKey, onChange}, ref) => {
    const withReason = Boolean(reason);

    const {loading, data} = useReCaptcha();

    if (loading) return null;

    const {default: ReCAPTCHA} = data;

    return (
      <div className={css.massBlocked}>
        <div className={withReason ? css.withReason : css.withoutReason}>
          {withReason && (
            <div className={css.blockedText} data-test="reason">
              <H3>{reason}</H3>
            </div>
          )}
          <div className={css.recaptcha}>
            <ReCAPTCHA ref={ref} sitekey={publicKey} onChange={onChange} />
          </div>
          {error && (
            <div className={css.error} data-test="error">
              <TextDanger>{error}</TextDanger>
            </div>
          )}
        </div>
        <PopupLoadTracker />
        <MountTracker name={MountTracker.NAMES.PAGE_LAYOUT} />
      </div>
    );
  },
);

CaptchaLayout.propTypes /* remove-proptypes */ = {
  reason: PropTypes.string,
  error: PropTypes.string,
  publicKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CaptchaLayout;
