import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import isCompactViewportCached from '@core/responsive/isCompactViewportCached';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import usePaymentTemplate from '@core/payment/common/utils/usePaymentTemplate';
import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import useAltOneClick from '@core/payment/forms/common/utils/useAltOneClick';

import ROUTES from '@phoenix/application/constants/routes';

import ONE_STEP_TEMPLATES from '../constants/oneStepTemplates';
import ONE_STEP_METHODS from '../constants/oneStepMethods';
import useSepaSettings from '../../forms/sepa/utils/useSepaSettings';

/**
 * @type {(string)[]}
 */
const TWO_STEPS_ACTIONS = [
  PAYMENT_ACTIONS.MEMBERSHIP,
  PAYMENT_ACTIONS.DATING_COURSE,
];

/**
 * Hook for check if payment with one step on mob
 * @returns {{isOneStep: boolean, loading: boolean}}
 */
const useIsMobOneStep = () => {
  const {action} = usePaymentParams();
  const {pathname} = useLocation();
  const {loading: templateLoading, template} = usePaymentTemplate();
  const activeMethodTab = useReactiveVar(activeMethodTabVar);

  const {isAltOneClick: isAltOneClickScenario, loading: isAltOneClickLoading} =
    useAltOneClick(activeMethodTab);

  const {isSepaMandate: hasSepaMandate, loading: isSepaSettingsLoading} =
    useSepaSettings(activeMethodTab);

  const isAltOneClick = isAltOneClickScenario && !hasSepaMandate;
  const isSepaMandate = isAltOneClickScenario && hasSepaMandate;

  const loading =
    templateLoading || isAltOneClickLoading || isSepaSettingsLoading;

  return useMemo(() => {
    const isTwoStepAction = TWO_STEPS_ACTIONS.includes(action);
    const isOneStepTemplate = ONE_STEP_TEMPLATES.includes(template);
    const isOneStepMethod =
      ONE_STEP_METHODS.includes(activeMethodTab) && !isSepaMandate;

    if (!isCompactViewportCached()) {
      return {
        isOneStep: true,
        loading: false,
      };
    }

    if (
      action === PAYMENT_ACTIONS.FEATURES &&
      !isCardPaymentType(activeMethodTab) &&
      !isOneStepMethod &&
      !isAltOneClick
    ) {
      /**
       * This is for alt methods on features step
       * For example sepa initial on features should be two steps
       */
      return {
        isOneStep: false,
        loading,
      };
    }

    return {
      isOneStep:
        isOneStepTemplate ||
        isOneStepMethod ||
        isAltOneClick ||
        !isTwoStepAction ||
        pathname.startsWith(ROUTES.PAY_APP_FUNNEL),
      loading,
    };
  }, [
    action,
    activeMethodTab,
    loading,
    pathname,
    template,
    isAltOneClick,
    isSepaMandate,
  ]);
};

export default useIsMobOneStep;
