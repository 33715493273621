/**
 * This object is used to simplify comparison in CoregistrationProvider.
 * @type {Record<string, Zone>}
 * @see CoregistrationProvider
 */
export const EMPTY_ZONES = {};

/**
 * Initial zone properties
 * @see CoregistrationProvider.state.consumerData.zones - the parent of zones
 * @see ./containers/CoregistrationWidget.js
 */
export const DEFAULT_ZONE = {
  // is zoneInfo loading
  loading: false,

  // is zoneInfo outdated
  outdated: true,

  /**
   * @see CoregistrationCheckbox.propTypes.zoneInfo for structure
   */
  zoneInfo: null,

  /**
   * Used to show success message.
   * @var {null|true} is coregistration in this zone was successful. false is not used because it looks like a fail.
   */
  success: null,
};

/**
 * Zones with popup
 */
export const POPUP_COREG_ZONE = {
  SEARCH_POPUP: 'coreg-search-popup',
};

/**
 * Available coregistration zones. Used when we want to render some coregistration widget on frontend.
 * Every widget makes request on API (@see coregistration.gql) for retrieving display params based on passed zone.
 * You should ensure the API works properly when adding new zones
 */
export const COREG_ZONE = {
  EDIT_ABOUT: 'edit-about',
  EDIT_INFO: 'edit-info',
  EDIT_STATUS: 'edit-status',
  SEARCH: 'advanced-search',
  STORIES: 'CoregUserStory',
  REMOVE_ACCOUNT_STAGE_MEMBERSHIP: 'remove-account-stage-statusMembership',
  REMOVE_ACCOUNT_STAGE_USER_REASON: 'remove-account-stage-userReason',
  REMOVE_ACCOUNT_STAGE_STOP: 'remove-account-stage-stop',
  ...POPUP_COREG_ZONE,
};
