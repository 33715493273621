import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PopupPriority from '@core/popup/constants/PopupPriority';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';

import EXTRA_BUY_ONE_PLUS_ONE_QUERY from './graphql/queries/extraBuyOnePlusOne.gql';
import ExtraBuyOnePlusOnePopupLayout from './components/ExtraBuyOnePlusOnePopupLayout';

/**
 * @param processId
 * @param client
 * @returns {Promise<void>}
 */
const action = async ({processId, client}) => {
  const {
    data: {
      extraPopups: {
        buyOnePlusOne: {enabled, via, price},
      },
    },
  } = await client.query({
    query: EXTRA_BUY_ONE_PLUS_ONE_QUERY,
    variables: {processId},
  });

  if (!enabled) return;

  const url = generatePayUrl({
    stage: PAYMENT_ACTIONS.MEMBERSHIP,
    urlParams: {via},
  });

  PopupService.openPopup(
    <ExtraBuyOnePlusOnePopupLayout price={price} url={url} />,
    {
      small: true,
      priority: PopupPriority.LOW,
      trackingName: 'extraBuyOnePlusOne',
      autoLoadTracking: true,
    },
  );
};

export default action;
