import {useQuery} from '@apollo/client';

import PMA_REQUEST_PHOTO from '../graphql/queries/pmaRequestPhoto.gql';
import type {PmaRequestPhotoQuery} from '../graphql/queries/pmaRequestPhoto';

type UsePmaRequestPhotoReturnType = {
  loading: boolean;
  available?: boolean;
  isConfirmedAgeVerification?: boolean;
  allowedRequestsCount?: number;
  needShowAgeConfirmationPopup?: boolean;
};

const usePmaRequestPhoto = (): UsePmaRequestPhotoReturnType => {
  const {data, loading} = useQuery<PmaRequestPhotoQuery>(PMA_REQUEST_PHOTO);

  const {
    available,
    isConfirmedAgeVerification,
    allowedRequestsCount,
    needShowAgeConfirmationPopup,
  } = data?.userFeatures?.pmaRequestPhoto || {};

  return {
    loading,
    available,
    isConfirmedAgeVerification,
    allowedRequestsCount,
    needShowAgeConfirmationPopup,
  };
};

export default usePmaRequestPhoto;
