import type {Observable} from 'rxjs';
import {from, of} from 'rxjs';
import {concatMap} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import type {CommonNotification} from '@core/systemNotifications/types';
import type {InteractionResentMessageSubscription} from '@core/messenger/common/graphql/subscriptions/interactionResentMessages';

import {getInteractionResentMessagesSubscription} from '@phoenix/messenger/common/utils/startMessengerListeners';

type ResentMessagesNotification = CommonNotification & {
  '{n}'?: number;
};

const resentMessagesDataSource = (): Observable<ResentMessagesNotification> => {
  const client = getClientInstance();

  return from<{
    data: InteractionResentMessageSubscription;
  }>(getInteractionResentMessagesSubscription(client)).pipe(
    concatMap(({data: {allUnsentMessagesCount, recipients}}) => {
      const sentMessageCount = recipients.reduce(
        (count, item) => count + item.messages.length,
        0,
      );
      return of(
        {
          type: NotificationsService.TYPES.SENT_MESSAGES_SUCCESS,
          '{n}': sentMessageCount,
        },
        Boolean(allUnsentMessagesCount) && {
          type: NotificationsService.TYPES.UNSENT_MESSAGES,
        },
      );
    }),
  );
};

export default resentMessagesDataSource;
