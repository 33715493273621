import once from 'lodash/once';

import changePersonalIdentitySubscription from '@phoenix/personalIdentity/utils/changePersonalIdentitySubscription';

import PERSONAL_IDENTITY_ON_DEMAND_AVAILABLE_QUERY from '../graphql/queries/personalIdentityByDemandAvailable.gql';
import startDocumentStatusListener from './startDocumentStatusListener';
import startVerificationChangeListener from './startVerificationChangeListener';

/**
 * Start listeners
 * @param {ApolloClient} client
 * @returns {Promise<void>}
 */
const startPersonalIdentityOnDemandListeners = once(async (client) => {
  const {
    data: {
      userFeatures: {
        personalIdentity: {availableOnDemand},
      },
    },
  } = await client.query({query: PERSONAL_IDENTITY_ON_DEMAND_AVAILABLE_QUERY});

  if (availableOnDemand) {
    changePersonalIdentitySubscription({client, applyToAllRoutes: true});
    startVerificationChangeListener();
    startDocumentStatusListener();
  }
});

export default startPersonalIdentityOnDemandListeners;
