import type {ReactNode} from 'react';
import React from 'react';

import t from '@core/translations/translate';

import {IconSize, IconType} from '@phoenix/ui/constants';
import {Icon} from '@phoenix/ui';

import FreeFeaturePopupTypes from '../constants/freeFeaturePopupTypes';

type Benefit = {
  icon: string;
  type: IconType;
  title: string;
  text: string;
};

type FreeTrialSuccessStatisticParams = {
  like: string;
  message: string;
  view: string;
  price: string;
  isPaid: boolean;
};

type FreeTrialSuccessStatisticConfigResult = {
  type: string;
  title: string;
  actionText: string;
  description: string;
  bottomNotice: ReactNode;
  customIcon: ReactNode;
  benefits: Benefit[];
};

const freeTrialSuccessStatisticConfig = ({
  like,
  message,
  view,
  price,
  isPaid = false,
}: FreeTrialSuccessStatisticParams): FreeTrialSuccessStatisticConfigResult => ({
  type: FreeFeaturePopupTypes.STATISTIC,
  title: t('freeTrialSuccess', 'text.title'),
  actionText: t('freeTrialSuccess', 'button.try_again_action'),
  customIcon: <Icon type="location" size={IconSize.SIZE_96} muted />,
  description: t('freeTrialSuccess', 'text.description'),
  bottomNotice:
    isPaid &&
    t('microfeatures', 'text.feature_costs', {
      '{price}': price,
      '{n}': 1,
    }),
  benefits: [
    {
      icon: 'eye',
      type: IconType.PRIMARY,
      title: view,
      text: t('freeTrialSuccess', 'text.views'),
    },
    {
      icon: 'heart',
      type: IconType.DANGER,
      title: like,
      text: t('microfeatures', 'text.likes'),
    },
    {
      icon: 'mail',
      type: IconType.ACCENT,
      title: message,
      text: t('microfeatures', 'text.messages'),
    },
  ],
});

export default freeTrialSuccessStatisticConfig;
