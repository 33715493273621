import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';

import PopupWithUserPhotos from '../containers/PopupWithUserPhotos';

export default function openPopupWithUserPhotos({
  onAccept,
  onClose,
  onDeny,
  popupType,
  userId,
  ...props
}) {
  PopupService.openPopup(
    <PopupWithUserPhotos
      onAccept={() => {
        PopupService.closePopup();
        onAccept();
      }}
      onDeny={() => {
        PopupService.closePopup();
        onDeny();
      }}
      userId={userId}
      {...props}
    />,
    {
      onClose: (sourceEventType) => {
        if (
          [
            PopupSourceEvent.CLOSE_BUTTON_CLICK,
            PopupSourceEvent.BY_ESCAPE,
          ].includes(sourceEventType) &&
          onClose
        ) {
          onClose();
        }
      },
      small: true,
      trackingName: 'webPushWithUserPhotos',
      trackingConceptId: `${popupType}_${userId ? 'single' : 'multiple'}`,
    },
  );
}
