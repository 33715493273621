import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import TrustedVerificationSecondStepPopup from '../containers/TrustedVerificationSecondStepPopup';

const openTrustedVerificationSecondStepPopup = ({
  referenceId,
  handleChangePhone,
}) => {
  PopupService.openPopup(
    <TrustedVerificationSecondStepPopup
      referenceId={referenceId}
      handleChangePhone={handleChangePhone}
    />,
    {
      small: true,
      trackingName: 'trustedVerificationSecondStep',
    },
  );
};

export default openTrustedVerificationSecondStepPopup;
