import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import FreeCoinsEmailConfirmationReward from '../containers/FreeCoinsEmailConfirmationReward';

const openFreeCoinsEmailConfirmationRewardPopup = () => {
  PopupService.openPopup(<FreeCoinsEmailConfirmationReward />, {
    small: true,
    // Add such popups back in stack, for avoiding overlapping on active one
    priority: PopupPriority.LOW,
    trackingName: 'freeCoinsEmailConfirmationReward',
    autoLoadTracking: true,
  });
};

export default openFreeCoinsEmailConfirmationRewardPopup;
