import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {getClientInstance} from '@core/graphql/client';
import {sessionStorage} from '@core/utils/storage/storage';

import SepaDebtorLayout from './components/SepaDebtorLayout';
import SepaDebtorActions from './components/SepaDebtorActions';
import SEPA_DEBTOR_STATUS_QUERY from './graphql/queries/sepaDebtorStatus.gql';
import SEPA_DEBTOR_INVOICE_URL_QUERY from './graphql/queries/sepaDebtorInvoiceUrl.gql';
import SEPA_DEBTOR_QUERY from './graphql/queries/sepaDebtor.gql';
import DEBTOR_STATUS from './constants/debtorStatus';
import {SEPA_DEBTOR_KEY} from './constants/debtorConstants';

export default class SepaDebtorService {
  /**
   * @const {String}
   */
  static DEBTOR_INVOICE_EXTERNAL = 'debtorInvoice';

  /**
   * @private
   * @type {null | string}
   */
  static debtorUserId = null;

  /**
   * @private
   * @returns {Promise<{accountStatus: *, debtor: *}>}
   */
  static async getDebtorData() {
    const {
      data: {
        payment: {debtor, accountStatus},
      },
    } = await getClientInstance().query({
      query: SEPA_DEBTOR_QUERY,
    });
    return {debtor, accountStatus};
  }

  /**
   * @private
   * @returns {Promise<string | null>}
   */
  static async getDebtorStatus() {
    const {
      data: {
        payment: {debtor},
      },
    } = await getClientInstance().query({
      query: SEPA_DEBTOR_STATUS_QUERY,
    });
    return debtor ? debtor.status : null;
  }

  /**
   * @public
   * @returns {Promise<string | null>}
   */
  static async getDebtorInvoiceUrl() {
    const {
      data: {
        payment: {debtor},
      },
    } = await getClientInstance().query({
      query: SEPA_DEBTOR_INVOICE_URL_QUERY,
    });

    return debtor ? debtor.invoiceUrl : null;
  }

  /**
   * Define if user is debtor,
   * open popup if it's necessary
   * @public
   */
  static async initialize() {
    const status = await this.getDebtorStatus();
    if (status !== DEBTOR_STATUS.NEW) {
      return;
    }

    await this.processDebtorNotification();
  }

  /**
   * @private
   * @returns {Promise<void>}
   */
  static async processDebtorNotification() {
    const {debtor} = await this.getDebtorData();
    const {userId, invoiceUrl, canCloseDebtorPopup} = debtor;
    this.debtorUserId = userId;
    const wasDisplayed = this.getWasDisplayed();

    if ((wasDisplayed && canCloseDebtorPopup) || this.isDevtorInvoiceRoute()) {
      return;
    }

    const actionsProps = {
      invoiceUrl,
    };

    if (canCloseDebtorPopup) {
      actionsProps.onCloseClick = () => {
        SepaDebtorService.setWasDisplayed();
        PopupService.closePopup();
      };
    }

    PopupService.openPopup(
      <SepaDebtorLayout
        debtorData={debtor}
        actions={<SepaDebtorActions {...actionsProps} />}
      />,
      {
        showScrollToTopButton: false,
        trackingName: 'sepaDebtor',
        autoLoadTracking: true,
        showCloseButton: canCloseDebtorPopup,
      },
    );
  }

  /**
   * @private
   * @returns {string}
   */
  static getDebtorSessionKey() {
    return `${SEPA_DEBTOR_KEY}:${this.debtorUserId}`;
  }

  /**
   * @private
   */
  static setWasDisplayed() {
    sessionStorage.setItem(this.getDebtorSessionKey(), true);
  }

  /**
   * @private
   * @returns {string}
   */
  static getWasDisplayed() {
    return sessionStorage.getItem(this.getDebtorSessionKey());
  }

  /**
   * @private
   * @returns {Boolean}
   */
  static isDevtorInvoiceRoute() {
    const pathName = window.location.pathname.slice(1);

    return pathName === this.DEBTOR_INVOICE_EXTERNAL;
  }
}
