import React, {Fragment, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {TextMuted, H3} from '@phoenix/typography';
import {Align} from '@phoenix/ui/constants';
import {ButtonPrimary, RowShort} from '@phoenix/ui';

/**
 * @const {string}
 */
export const REDIRECT_URL = '/account';

const CancelRepeatUnsuccessfulPopup = () => {
  const history = useHistory();

  // Just make redirect to user settings.
  const handleClick = useCallback(() => {
    history.push(REDIRECT_URL);
  }, [history]);

  return (
    <PopupFrame
      data-test="cancelRepeatPopup"
      spacedAbove
      content={
        <Fragment>
          <RowShort align={Align.CENTER}>
            <H3>
              {t(
                'popup24CancelRepeat',
                'title.confirm-cancel-by-email-unsuccessful',
              )}
            </H3>
          </RowShort>
          <RowShort align={Align.CENTER}>
            <TextMuted>
              {t(
                'popup24CancelRepeat',
                'text.confirm-cancel-by-email-unsuccessful-paragraph-1',
              )}
            </TextMuted>
          </RowShort>
        </Fragment>
      }
      actions={
        <ButtonPrimary
          fullWidth
          adaptive
          onClick={handleClick}
          trackingName="submitNewRequest"
          data-test="cancelRepeatAction"
        >
          {t(
            'popup24CancelRepeat',
            'button.confirm-cancel-by-email-unsuccessful-submit-new-request',
          )}
        </ButtonPrimary>
      }
    />
  );
};

export default CancelRepeatUnsuccessfulPopup;
