import {useRef} from 'react';
import {useLocation} from 'react-router-dom';

import usePrevious from '@core/utils/react/usePrevious';

/**
 * @desc Hook for detection that route is changed
 * @return {Boolean}
 */
const useIsRouteChanged = () => {
  const changed = useRef(false);
  const {pathname} = useLocation();
  const prevPathname = usePrevious(pathname);

  if (!changed.current && prevPathname && pathname !== prevPathname) {
    changed.current = true;
  }
  return changed.current;
};

export default useIsRouteChanged;
