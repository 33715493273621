import React from 'react';

import CommonACLRoute from '@core/application/containers/ACLRoute';

import {Loader} from '@phoenix/ui';

/**
 * Basic ACL route with applied styling
 */
const ACLRoute = (props) => (
  <CommonACLRoute fallback={<Loader fullsize />} {...props} />
);

export default ACLRoute;
