import React, {lazy} from 'react';

import isMobileViewportCached from '@core/responsive/isMobileViewportCached';

import SplitChunk from '../components/SplitChunk';

const LazyPayCoins = lazy(() =>
  isMobileViewportCached()
    ? import(
        /* webpackChunkName: 'payCoinsRouteMob' */ '../routes/mob/PayCoinsRoute'
      )
    : import(
        /* webpackChunkName: 'payCoinsRouteWeb' */ '../routes/web/PayCoinsRoute'
      ),
);

export default (props) => <SplitChunk component={LazyPayCoins} {...props} />;
