import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupPriority from '@core/popup/constants/PopupPriority';

import VirtualAssistantPopup from '../components/VirtualAssistantPopup';

const openVirtualAssistantPopup = (props) => {
  PopupService.openPopup(<VirtualAssistantPopup {...props} />, {
    small: true,
    trackingName: 'virtualAssistant',
    autoLoadTracking: true,
    inverseCloseButton: true,
    priority: PopupPriority.LOW,
  });
};

export default openVirtualAssistantPopup;
