import React from 'react';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';

import {ICONS} from '../constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';

const MicrofeaturesIconMicrofeatureOffer = ({active = false, ...props}) => (
  <MicrofeaturesIconLayout
    icon={
      <Icon type={ICONS[MICROFEATURES.MICROFEATURE_OFFER]} inherit={!active} />
    }
    active={active}
    {...props}
  />
);

MicrofeaturesIconMicrofeatureOffer.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconMicrofeatureOffer.propTypes /* remove-proptypes */ = {
  active: PropTypes.bool,
};

export default MicrofeaturesIconMicrofeatureOffer;
