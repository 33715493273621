import {sessionStorage as storage} from '@core/utils/storage';

import {STORAGE_KEY} from '../containers/PaymentOrderChecker';

const setOrderIdAfterExternalPay = ({orderId, via} = {}) => {
  if (!orderId) {
    return;
  }

  storage.setItem(STORAGE_KEY, {
    ...storage.getItem(STORAGE_KEY, {}),
    orderId,
    via,
  });
};

export default setOrderIdAfterExternalPay;
