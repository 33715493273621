/**
 * Just an 'iframe' tag with content inside.
 * Used for smart element rm-banners logic.
 * @const {string}
 */
export const SMART_ELEMENT_BANNER = 'BannerLoaded';

/**
 * Coreg links/popups
 */
export const COREG_BANNER = 'CoregLoaded';

/**
 * We receive such message instead of 'BannerLoaded' when RM don't want to show this banner.
 * Is used to hide the banner if it was previously visible.
 */
export const BANNER_NOT_LOADED = 'BannerNotLoaded';

/**
 * Coreg message for not loaded banner data on banner system for links/popups
 * @const {string}
 */
export const COREG_BANNER_NOT_LOADED = 'CoregNotLoaded';

/**
 * Event for remarketing notification.
 */
export const EXPLODED_BANNER_LOADED = 'ExplodedBannerLoaded';

/**
 * There are big amount of banner types, that are catched
 * by postmessage observer (iframe sends 'callbackEvents' with this name).
 * We implement only few common types.
 *
 * @see createPostMessageObservable.js
 */
const SUPPORTED_BANNER_TYPES = [
  SMART_ELEMENT_BANNER,
  COREG_BANNER_NOT_LOADED,
  BANNER_NOT_LOADED,
  COREG_BANNER,
  EXPLODED_BANNER_LOADED,
];

export default SUPPORTED_BANNER_TYPES;
