import React from 'react';

import {Loader} from '@phoenix/ui';
import ThemedCurrentUserPage from '@phoenix/theming/components/ThemedCurrentUserPage';

/**
 * Don't worry, "ThemedProfilePage" already splits component in different chunks
 */
const ProfileChunk = (props) => (
  <ThemedCurrentUserPage placeholder={<Loader fullscreen />} {...props} />
);

export default ProfileChunk;
