import PaymentScenario from '@core/payment/common/constants/paymentScenario';
import getFormName from '@core/payment/payProcess/utils/getFormName';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import PaymentPageAlternativePaymentModel from '@legacy/application/components/paymentPageExternal/models/PaymentPageAlternativePaymentModel';

/**
 * @deprecated Use paypalPay.ts
 * */
const paypalPayLegacy: PaymentProcessStep = async (data) => {
  const {paymentData} = data;
  const {
    action,
    via,
    activePackage: {
      stockId,
      packageId,
      tokenPrice: {currencyCode},
    },
    country,
    siteName,
    userId,
    locale,
    method,
    selectedAdditionalPackages,
    scenario,
    returnPath,
  } = paymentData;

  const payScenario = scenario || PaymentScenario.INITIAL;

  return new Promise((resolve) => {
    const payModel = new (PaymentPageAlternativePaymentModel.extend({
      REACT_REDIRECT_PAYMENT: payScenario === PaymentScenario.INITIAL,
      FORCE_REDIRECT: 0,
      SKIP_RESPONSE_META_ERROR_REDIRECT: true,
    }))({
      // @ts-expect-error TODO: must be fixed in FE-43926
      formName: getFormName(method),
      method,
      via: action,
      scenario: payScenario,
    });

    // Success for initial scenario
    payModel.on('pay:reactRedirectPayment', (paymentAnswer) => {
      resolve({
        ...data,
        paymentResult: {
          ...paymentAnswer,
          /**
           * @see continueExternalPaymentInAnotherWindow
           */
          isAllowedRedirect: true,
        },
      });
    });

    // Success for oneClick scenario
    payModel.on('pay:success', (paymentAnswer) => {
      resolve({
        ...data,
        paymentResult: paymentAnswer,
      });
    });

    // Decline for oneClick scenario
    payModel.on('pay:fail', (paymentAnswer) => {
      resolve({
        ...data,
        paymentResult: paymentAnswer,
      });
    });

    payModel.save({
      via: action,
      method,
      prevVia: via,
      selectedAdditionalPackages,
      currency_code: currencyCode,
      domain: siteName,
      country,
      locale,
      package_id: stockId,
      product_id: packageId,
      hidePaymentForm: 1,
      returnPath,
      user_id: userId,
    });
  });
};

export default paypalPayLegacy;
