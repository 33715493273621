import React, {Fragment, useCallback, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import {MESSENGER} from '@core/mediaUpload/constants/sources';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import {settingsPropType} from '@core/mediaUpload/constants/propTypes';
import SOURCES_WITH_MOTIVATION from '@core/mediaUpload/constants/sourcesWithMotivation';
import SOURCES_WITH_PMA from '@core/mediaUpload/constants/sourcesWithPMA';
import useUploadFilesMultiple from '@core/mediaUpload/utils/useUploadFilesMultiple'; // eslint-disable-line import/no-cycle
import MediaUploadPopupDisplayer from '@core/mediaUpload/containers/MediaUploadPopupDisplayer';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import PopupContext from '@core/popup/containers/PopupContext';
import MediaUploadActionsAppearance from '@core/theming/constants/features/MediaUploadActionsAppearance';
import MediaUploadAppearance from '@core/theming/constants/features/MediaUploadAppearance';

import RemarketingBanner from '@phoenix/remarketing/banner';
import PLACEMENT_IDS from '@phoenix/remarketing/banner/constants/placementIds';
import {Text} from '@phoenix/typography';
import {Row, Loader, Separator, ButtonLink} from '@phoenix/ui';
import UploadErrorsInline from '@phoenix/mediaUpload/components/UploadErrorsInline';
import useThemedMediaUpload from '@phoenix/theming/utils/useThemedMediaUpload';
import PhotoDisapproveReasons from '@phoenix/disapprove/containers/PhotoDisapproveReasons';
import MessengerDisapproveReasons from '@phoenix/disapprove/containers/MessengerDisapproveReasons';
import MediaDisapproveReasons from '@phoenix/disapprove/containers/MediaDisapproveReasons';
import usePmaCheckbox, {PMA_PLACES} from '@phoenix/pma/utils/usePmaCheckbox';
import CreditAmount from '@phoenix/credits/components/CreditAmount';
import COIN_ICON_SIZES from '@phoenix/credits/constants/coinColorizedIconSizes';
import openErrorsPopup from '@phoenix/mediaUpload/utils/openErrorsPopup';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';
import useFreeCoinsReward from '@phoenix/freeCoins/utils/useFreeCoinsReward';
import {MEDIA_UPLOAD} from '@phoenix/freeCoins/constants/taskType';
import {ButtonType, SpacingSize} from '@phoenix/ui/constants';
import useFirstPhotoReward from '@phoenix/messenger/common/utils/useFirstPhotoReward';

import MediaUploadPopupLayout from '../components/MediaUploadPopupLayout';
import MediaPreview from '../components/MediaPreview';
import PhotoUploadActions from './PhotoUploadActions';
import WebcamPhotoCapture from '../components/WebcamPhotoCapture';
import PhotoRequirements from './PhotoRequirements';
import VideoRequirements from './VideoRequirements';
import DisapproveReasonsWrapper from '../components/DisapproveReasonsWrapper';
import useCloseButtonAllowedOnPhotoUpload from '../utils/useCloseButtonAllowedOnPhotoUpload';

const INVERSE_BACKGROUND = [
  MediaUploadAppearance.WITH_INVERSE_BACKGROUND_AND_UPLOAD_ACTION_MOTIVATION,
  MediaUploadAppearance.WITH_PHOTO_ON_BACKGROUND_AS_MOTIVATION,
];

const getTitle = ({firstPhotoReward, mediaUploadActions}) => {
  if (firstPhotoReward > 0) {
    return t('funnelPhotoUploadMotivation', 'text.get_communication_bonus');
  }

  return mediaUploadActions ===
    MediaUploadActionsAppearance.UNIFIED_FOR_JOINED_UPLOAD
    ? t('funnelPhotoUploadMotivation', 'text.users_with_media')
    : t('funnelPhotoUploadMotivation', 'text.users_with_photos');
};

const PhotoUploadPopup = ({
  settings,
  motivation,
  customTitle = null,
  footer,
  spacedAbove,
  unspacedBelow,
  spacedFooter,
  showRequirements = true,
  showFooter = true,
}) => {
  const firstPhotoReward = useFirstPhotoReward();

  const {source, multiple, accept, uploadCost, unifiedUpload, paymentUrl} =
    settings;

  const {data: mediaUploadActions, loading: themeLoading} = useThemeFeature(
    'mediaUpload',
    'actions',
  );

  const {data: mediaAppearance, loading: appearanceLoading} = useThemeFeature(
    'mediaUpload',
    'appearance',
  );

  const {
    loading,
    data: {motivation: MediaUploadMotivation, layout: MediaUploadLayout} = {},
  } = useThemedMediaUpload();

  const {reward} = useFreeCoinsReward(MEDIA_UPLOAD);

  const withInverseBackground = INVERSE_BACKGROUND.includes(mediaAppearance);
  const withMotivation = motivation || SOURCES_WITH_MOTIVATION.includes(source);
  const descriptionCreditAmount =
    uploadCost ||
    (reward
      ? t('freeCoins', 'text.grabFreeCoins', {
          '{n}': reward,
        })
      : null);

  const uploadRenderer = useCallback(
    (props) => (
      <Fragment>
        <PhotoUploadActions
          uploadPhotoButtonType={
            paymentUrl ? ButtonType.DEFAULT : ButtonType.PRIMARY
          }
          multiple={multiple}
          accept={accept}
          unifiedUpload={unifiedUpload}
          spacing={SpacingSize.NONE}
          {...props}
        />
        {Boolean(descriptionCreditAmount) && (
          <Row>
            <CreditAmount
              textType={Text.TYPE.MUTED}
              iconSize={COIN_ICON_SIZES.SMALLEST}
              inverse={withInverseBackground && withMotivation}
              small
            >
              {descriptionCreditAmount}
            </CreditAmount>
          </Row>
        )}
      </Fragment>
    ),
    [
      paymentUrl,
      multiple,
      accept,
      unifiedUpload,
      descriptionCreditAmount,
      withInverseBackground,
      withMotivation,
    ],
  );

  const {isUploading, files, setFiles, errors, clearErrors} =
    useUploadFilesMultiple({
      settings,
      uploadRenderer,
      openErrorsPopup,
    });

  const webcamRenderer = useCallback(
    (props) => <WebcamPhotoCapture {...props} />,
    [],
  );

  const previewRenderer = useCallback(
    (props) => (
      <MediaPreview
        sendText={
          source === MESSENGER
            ? t('mediaUpload', 'text.send')
            : t('mediaUpload', 'text.add')
        }
        cancelText={t('mediaUpload', 'text.retake_photo')}
        {...props}
      />
    ),
    [source],
  );

  const {checkbox: pmaCheckbox} = usePmaCheckbox({
    place: SOURCES_WITH_PMA.includes(source)
      ? PMA_PLACES.PHOTO_UPLOAD_FUNNEL
      : null,
    inverse:
      MediaUploadAppearance.WITH_PHOTO_ON_BACKGROUND_AS_MOTIVATION ===
      mediaAppearance,
  });

  const {
    setPopupProps,
    options: {showCloseButton},
  } = useContext(PopupContext);

  const available = useCloseButtonAllowedOnPhotoUpload({
    source,
    errors,
  });

  /**
   * Set photo upload popup close button visibility
   * showCloseButtonOption used for correctly update popup props when openErrorsPopup is called.
   * openErrorsPopup triggered setPopupProps with wrong values.
   */
  useEffect(() => {
    available && setPopupProps({showCloseButton: true});
  }, [available, setPopupProps, showCloseButton]);

  /**
   * Chunk with layout components load too quickly and
   * there is no need to render placeholders
   */
  if (loading || themeLoading || appearanceLoading) return null;

  const profileDisapproveReasons = unifiedUpload
    ? MediaDisapproveReasons
    : PhotoDisapproveReasons;

  const uploadFooter = ({wrappedFooter}) => (
    <Fragment>
      <RemarketingBanner placementId={PLACEMENT_IDS.BANNER_PHOTO_UPLOAD} />
      {footer ||
        (paymentUrl ? (
          <>
            <Row>
              <Separator>or</Separator>
            </Row>
            <Row>
              <ButtonLink
                type={ButtonType.PAY}
                href={paymentUrl}
                data-test="upgrade"
                trackingName="upgrade"
                fullWidth
              >
                {t('mediaUpload', 'button.upgrade')}
              </ButtonLink>
            </Row>
          </>
        ) : (
          <DisapproveReasonsWrapper
            icon={unifiedUpload ? 'gallery' : 'photo'}
            withIcon={!withInverseBackground}
            unbordered
            component={
              source === MESSENGER
                ? MessengerDisapproveReasons
                : profileDisapproveReasons
            }
            wrapped={wrappedFooter}
          />
        ))}
    </Fragment>
  );

  return (
    <BabciaScopedProvider conceptId={mediaAppearance}>
      <MediaUploadPopupDisplayer
        loaderComponent={Loader}
        uploadErrorsInlineComponent={UploadErrorsInline}
        openErrorsPopup={openErrorsPopup}
        layoutComponent={
          withMotivation ? MediaUploadLayout : MediaUploadPopupLayout
        }
        spacedFooter={spacedFooter}
        settings={settings}
        isUploading={isUploading}
        onFileSelect={setFiles}
        errors={errors}
        clearErrors={clearErrors}
        filesCount={files.length}
        uploadRenderer={uploadRenderer}
        webcamRenderer={webcamRenderer}
        previewRenderer={previewRenderer}
        motivation={
          motivation ||
          (SOURCES_WITH_MOTIVATION.includes(source) && (
            <MediaUploadMotivation
              type="photo"
              title={
                customTitle || getTitle({firstPhotoReward, mediaUploadActions})
              }
              canUseThemedTitle={!customTitle}
              onFileSelect={setFiles}
              accept={accept}
              multiple={multiple}
            />
          ))
        }
        requirements={
          showRequirements && (
            <Fragment>
              <PhotoRequirements />
              {unifiedUpload && (
                <Row>
                  <VideoRequirements />
                </Row>
              )}
            </Fragment>
          )
        }
        footer={showFooter ? uploadFooter : () => {}}
        pmaCheckbox={pmaCheckbox}
        spacedAbove={spacedAbove}
        unspacedBelow={unspacedBelow}
      />
      {/**
       * To track 'photoUpload' and 'videoUpload' popup load completion
       * Fallback for tracking popup loading in case, if popup rendered without motivation and requirements
       */}
      {!withMotivation && !showRequirements && <PopupLoadTracker />}
    </BabciaScopedProvider>
  );
};

PhotoUploadPopup.propTypes /* remove-proptypes */ = {
  settings: settingsPropType.isRequired,
  motivation: PropTypes.node,
  customTitle: PropTypes.string,
  footer: PropTypes.node,
  spacedAbove: PropTypes.bool,
  unspacedBelow: PropTypes.bool,
  spacedFooter: PropTypes.bool,
  showFooter: PropTypes.bool,
  showRequirements: PropTypes.bool,
  uploadCost: PropTypes.number,
};

export default PhotoUploadPopup;
