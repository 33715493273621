import type {FC} from 'react';
import React from 'react';

import t from '@core/translations/translate';

import {ButtonPrimary, Overlay, Row, RowShort, SpacingShort} from '@phoenix/ui';
import {Text, TextWithIcon} from '@phoenix/typography';
import {
  OverlaySaturation,
  SpacingSize,
  VerticalAlign,
} from '@phoenix/ui/constants';
import CoinColorizedIconByAmount from '@phoenix/credits/components/CoinColorizedIconByAmount';
import COIN_AMOUNT from '@phoenix/credits/constants/coinAmount';
import COIN_ICON_SIZES from '@phoenix/credits/constants/coinColorizedIconSizes';

import css from '../styles/PrivateAlbumPhotoOverlayLayout.css';

type PrivateAlbumPhotoOverlayLayoutProps = {
  loading?: boolean;
  onClick?: () => void;
  price?: number;
};

const PrivateAlbumPhotoOverlayLayout: FC<
  PrivateAlbumPhotoOverlayLayoutProps
> = ({loading, onClick, price}) => {
  if (onClick && price) {
    return (
      <Overlay saturation={OverlaySaturation.LITE}>
        <div className={css.wrap}>
          <SpacingShort onlyBottom className={css.content}>
            <Row flexibleWidth={false}>
              <Text inverse>{t('privatePhoto', 'title.private_photo')}</Text>
            </Row>
            <ButtonPrimary
              loading={loading}
              fullWidth
              adaptive
              trackingName="viewAllPrivateAlbumPhotos"
              onClick={onClick}
            >
              {t('privatePhoto', 'title.view_all_private_photos')}
            </ButtonPrimary>
            <RowShort flexibleWidth={false}>
              <TextWithIcon
                icon={
                  <CoinColorizedIconByAmount
                    amount={COIN_AMOUNT.ONE}
                    size={COIN_ICON_SIZES.SMALL}
                  />
                }
                align={Text.ALIGN.CENTER}
                vertical={false}
                space={SpacingSize.SHORTEST}
                verticalAlign={VerticalAlign.CENTER}
                textType={TextWithIcon.TYPE.MUTED}
                small
                inverse
              >
                {price}
              </TextWithIcon>
            </RowShort>
          </SpacingShort>
        </div>
      </Overlay>
    );
  }

  return (
    <Overlay saturation={OverlaySaturation.LITE}>
      <SpacingShort className={css.content}>
        <Text inverse>{t('privatePhoto', 'title.private')}</Text>
      </SpacingShort>
    </Overlay>
  );
};

export default PrivateAlbumPhotoOverlayLayout;
