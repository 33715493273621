import React, {useMemo, Fragment, memo} from 'react';
import PropTypes from 'prop-types';

import {decodeCopyright} from '@core/utils/processText';

import {TextMuted} from '@phoenix/typography';

import Disclaimer from '../../../../footer/components/Disclaimer';

/**
 * Component for render payment copyright and disclaimer text
 * @property data.copyrightUnifyData {Object} - copyright data
 * @property data.copyrightUnifyData.isPlainText {Boolean} - flag about copyright is encrypted
 * @property data.copyrightUnifyData.unifyCopyrightText {String} - copyright text
 * @property data.disclaimerFrontendData {Object} - disclaimer data
 * @property data.disclaimerFrontendData.message {String} - disclaimer text
 * @property data.disclaimerFrontendData.disclaimerUrl {String} - disclaimer link url
 * @property data.copyrightCompanyName {string} - copyright text, used when copyrightUnifyData is empty
 */
const PaymentCopyright = ({data}) => {
  const {copyrightUnifyData, copyrightCompanyName, disclaimerFrontendData} =
    data;

  const text = useMemo(() => {
    const unifyCopyrightText =
      copyrightUnifyData && copyrightUnifyData.unifyCopyrightText;

    if (unifyCopyrightText) {
      return copyrightUnifyData.isPlainText
        ? unifyCopyrightText
        : decodeCopyright(unifyCopyrightText);
    }

    if (copyrightCompanyName) {
      return `© Copyright ${copyrightCompanyName}`;
    }

    return '';
  }, [copyrightUnifyData, copyrightCompanyName]);

  return (
    <Fragment>
      {Boolean(disclaimerFrontendData && disclaimerFrontendData.message) && (
        <Disclaimer
          href={disclaimerFrontendData.disclaimerUrl}
          text={disclaimerFrontendData.message}
          muted
          underline
          data-test="paymentCopyrightDisclaimer"
        />
      )}
      {Boolean(text) && (
        <TextMuted
          small
          align={TextMuted.ALIGN.CENTER}
          data-test="paymentCopyrightText"
        >
          {text}
        </TextMuted>
      )}
    </Fragment>
  );
};

PaymentCopyright.propTypes /* remove-proptypes */ = {
  data: PropTypes.shape({
    copyrightUnifyData: PropTypes.shape({
      unifyCopyrightText: PropTypes.string,
      isPlainText: PropTypes.bool,
    }),
    disclaimerFrontendData: PropTypes.shape({
      message: PropTypes.string,
      disclaimerUrl: PropTypes.string,
    }),
    copyrightCompanyName: PropTypes.string,
  }).isRequired,
};

export default memo(PaymentCopyright);
