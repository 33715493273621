import React from 'react';

import openGalleryPopup from '@phoenix/gallery/utils/openGalleryPopup';
import getThemeFeature from '@phoenix/theming/utils/getThemeFeature';

import TargetBottomFixedUserActions from '../../target/containers/TargetBottomFixedUserActions';
import ACTION_TYPES, {
  ACTION_TYPES_FIXED_BOTTOM,
} from '../../target/constants/targetUserActionTypes';
import {videoControlsRenderer, useGetVideos} from './openVideoGallery';
import {photoControlsRenderer, useGetPhotos} from './openPhotoGallery';

/**
 * Wrapper component for getting data for gallery
 * @param userId
 * @param my
 * @param children
 * @param props
 * @returns {null|*}
 * @constructor
 */
export const MediaGalleryData = ({userId, my, children, ...props}) => {
  const {
    error: photoError,
    loading: photoLoading,
    media: photos,
  } = useGetPhotos({userId, my});

  const {
    error: videoError,
    loading: videoLoading,
    media: videos,
    showWebCamMotivation,
  } = useGetVideos({
    userId,
    my,
  });

  if (photoError || videoError || photoLoading || videoLoading) return null;

  return children({
    media: [...photos, ...videos],
    userId,
    my,
    showWebCamMotivation,
    ...props,
  });
};

export const currentUserBottomRenderer = (currentMedia) =>
  currentMedia.photo
    ? photoControlsRenderer(currentMedia)
    : videoControlsRenderer(currentMedia);

const targetUserBottomRenderer = (userId, reversedTypes) => (
  <TargetBottomFixedUserActions
    userId={userId}
    actionsTypes={reversedTypes ? ACTION_TYPES_FIXED_BOTTOM : ACTION_TYPES}
  />
);

const openMediaGallery = async ({
  userId,
  my,
  mediaId,
  forceWebCamPartner,
  withShadow,
  needGTMTrack = false,
  showLoadingBackground,
  videoRenderer,
}) => {
  const reversedTypes = await getThemeFeature('gallery', 'appearance');

  return openGalleryPopup({
    showLoadingBackground,
    userId,
    my,
    forceWebCamPartner,
    withShadow,
    needGTMTrack,
    initialMediaId: mediaId,
    showNav: true,
    showCounter: true,
    videoRenderer,
    bottomRenderer: my
      ? currentUserBottomRenderer
      : () => targetUserBottomRenderer(userId, reversedTypes),
    GalleryData: MediaGalleryData,
  });
};

export default openMediaGallery;
