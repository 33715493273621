import LOGOS from '@core/payment/widgets/securityLogos/constants/logos';
import ColorScheme from '@core/theming/constants/ColorScheme';

/**
 * Security logo image list. Some logos are identical for both color schemes, some - not.
 * @see PaymentSecurityLogosLayout.js
 */
export default {
  [LOGOS.AMEX]: require('@core/payment/widgets/securityLogos/images/amEx.svg'),
  [LOGOS.AMIPCI]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/amipci.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/amipciInverse.svg'),
  },
  [LOGOS.BANCONTACT]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/tabs/images/bancontact.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/tabs/images/bancontactInverse.svg'),
  },
  [LOGOS.BCCARD]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/bccard.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/bccardInverse.svg'),
  },
  [LOGOS.CARD]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/card.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/cardInverse.svg'),
  },
  [LOGOS.CARTASI]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/cartasi.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/cartasiInverse.svg'),
  },
  [LOGOS.CARTEBANCAIRE]: require('@core/payment/widgets/securityLogos/images/carteBancaire.svg'),
  [LOGOS.COMODO]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/comodo.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/comodoInverse.svg'),
  },
  [LOGOS.DCI]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/dci.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/dciInverse.svg'),
  },
  [LOGOS.DEUTSCHEQUALITAT]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/deutschequalitat.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/deutschequalitatInverse.svg'),
  },
  [LOGOS.DISCOVER]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/discover.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/discoverInverse.svg'),
  },
  [LOGOS.DIRECTDEBIT]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/directDebit.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/directDebitInverse.svg'),
  },
  [LOGOS.ELECTRONICCASH]: require('@core/payment/widgets/securityLogos/images/electroniccash.svg'),
  [LOGOS.ELO]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/elo.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/eloInverse.svg'),
  },
  [LOGOS.HIPERCARD]: require('@core/payment/widgets/securityLogos/images/hipercard.svg'),
  [LOGOS.JCB]: require('@core/payment/widgets/securityLogos/images/jcb.svg'),
  [LOGOS.LETSENCRYPT]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/letsEncrypt.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/letsEncryptInverse.svg'),
  },
  [LOGOS.KLARNA]: require('@core/payment/widgets/tabs/images/klarna.svg'),
  [LOGOS.LOCK]: require('@core/payment/widgets/securityLogos/images/lock.svg'),
  [LOGOS.MASTERCARD]: require('@core/payment/widgets/securityLogos/images/masterCard.svg'),
  [LOGOS.MASTERCARDCOMPLIANCE]: require('@core/payment/widgets/securityLogos/images/masterCardCompliance.svg'),
  [LOGOS.MONEYBACK]: require('@core/payment/widgets/securityLogos/images/moneyBack.svg'),
  [LOGOS.MYBANK]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/mybank.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/mybankInverse.svg'),
  },
  [LOGOS.POSTEPAY]: require('@core/payment/widgets/securityLogos/images/postepay.svg'),
  [LOGOS.PCICERTIFIED]: require('@core/payment/widgets/securityLogos/images/pciCertified.svg'),
  [LOGOS.SEPALASTSCHRIFT]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/sepalastschrift.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/sepalastschriftInverse.svg'),
  },
  [LOGOS.TRUSTLY]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/tabs/images/trustly.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/tabs/images/trustlyInverse.svg'),
  },
  [LOGOS.VISA]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/visa.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/visaInverse.svg'),
  },
  [LOGOS.VISACARDCOMPLIANCE]: {
    [ColorScheme.LIGHT]: require('@core/payment/widgets/securityLogos/images/visaCardCompliance.svg'),
    [ColorScheme.DARK]: require('@core/payment/widgets/securityLogos/images/visaCardComplianceInverse.svg'),
  },
  [LOGOS.LAWSON]: require('@core/payment/widgets/securityLogos/images/lawson.svg'),
  [LOGOS.SEICOMART]: require('@core/payment/widgets/securityLogos/images/seicomart.svg'),
  [LOGOS.MINISTOP]: require('@core/payment/widgets/securityLogos/images/ministop.svg'),
  [LOGOS.FAMILYMART]: require('@core/payment/widgets/securityLogos/images/familymart.svg'),
};
