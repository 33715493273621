import React from 'react';

import t from '@core/translations/translate';
import withStaticPaymentParamsProvider from '@core/payment/common/utils/withStaticPaymentParamsProvider';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import PAYMENT_SOURCES from '@core/payment/common/constants/paymentSources';
import {setPageEnterTime} from '@core/payment/payProcess/utils/pageViewTime';
import getFormattedPrice from '@core/payment/common/utils/getFormattedPrice';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {TextMuted} from '@phoenix/typography';
import {Align, SpacingDirection, SpacingSize} from '@phoenix/ui/constants';
import {ButtonPay, Group, Spacing} from '@phoenix/ui';
import useRandomUsers from '@phoenix/search/list/utils/useRandomUsers';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import useTrackPaymentVisit from '@phoenix/payment/pages/utils/useTrackPaymentVisit';
import PaymentCardOneClickInfo from '@phoenix/payment/forms/card/components/PaymentCardOneClickInfo';
import PaymentBillingPolicy from '@phoenix/payment/widgets/billingPolicy/containers/PaymentBillingPolicy';

import {OPEN_PP_POPUP_WEEK_SPEAK} from '../../buyCoins/constants/vias';
import useBuyOffer from '../../buyCoins/utils/useBuyOffer';
import WeekSpeakOfferPopupContent from '../components/WeekSpeakOfferPopupContent';
import WeekSpeakOfferPopupPlaceholder from '../components/WeekSpeakOfferPopupPlaceholder';

const WeekSpeakOfferPopup = () => {
  // need for track pay in popup
  setPageEnterTime();
  useTrackPaymentVisit();
  const isPhone = usePhoneBreakpoint();

  const {
    loading,
    handlePay,
    handleClickGotoPP,
    error,
    disabled,
    isOneClickAllowed,
    oneClickCardNumber,
    activePackage,
  } = useBuyOffer();

  const {loading: loadingUser, randomUsers} = useRandomUsers();

  if (loading || loadingUser) {
    return <WeekSpeakOfferPopupPlaceholder isPhone={isPhone} />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  const {packagePricingDetails: {packagePriceData: {fullPrice}} = {}} =
    activePackage;

  const randomUser = randomUsers?.[0]?.profile;
  return (
    <PopupFrame
      spacedContent={false}
      forceFixedActions
      content={
        <PopupLoadTracker>
          <WeekSpeakOfferPopupContent
            price={getFormattedPrice(fullPrice)}
            photo={randomUser ? getPrimaryPhotoData(randomUser) : null}
          />
          <Spacing
            size={isPhone ? SpacingSize.NORMAL : SpacingSize.LARGE}
            onlyTop
          >
            <Spacing direction={SpacingDirection.HORIZONTAL}>
              <Group align={Align.CENTER}>
                <TextMuted small>
                  {t('coins', 'text.special_offer_week_speak_info', {
                    '{amount}': activePackage.quantity,
                  })}
                </TextMuted>
                {isOneClickAllowed && oneClickCardNumber && (
                  <PaymentCardOneClickInfo
                    showChargeInfo={false}
                    cardNumber={oneClickCardNumber}
                    onClick={handleClickGotoPP}
                    oneClickUsed
                    spaced={false}
                    withoutLeft
                    withoutRight
                  />
                )}
                {isOneClickAllowed && <PaymentBillingPolicy />}
              </Group>
            </Spacing>
          </Spacing>
        </PopupLoadTracker>
      }
      actions={
        <ButtonPay
          disabled={disabled}
          adaptive
          fullWidth
          onClick={isOneClickAllowed ? handlePay : handleClickGotoPP}
          trackingName="getDeal"
          data-test="specialOfferPopupAction"
        >
          {t('coins', 'button.get_deal')}
        </ButtonPay>
      }
    />
  );
};

export default withStaticPaymentParamsProvider(WeekSpeakOfferPopup, {
  action: PAYMENT_ACTIONS.CREDIT,
  via: OPEN_PP_POPUP_WEEK_SPEAK,
  source: PAYMENT_SOURCES.POPUP,
});
