import React from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';

import {Group} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {Text, H3} from '@phoenix/typography';
import PhotoWithWidgets from '@phoenix/user/photo/components/PhotoWithWidgets';

import css from '../styles/ConfirmationUserMotivation.css';

const ConfirmationUserMotivationLayout = ({photo, login, userId, online}) => {
  const isPhone = usePhoneBreakpoint();
  const TextWrap = isPhone ? Text : H3;

  return (
    <Group horizontal space={SpacingSize.SHORT} itemWithFlexibleWidth={false}>
      <PhotoWithWidgets
        data-test="confirmationMotivationPhoto"
        className={css.photo}
        photo={photo}
        online={online}
        userId={userId}
        round
        // Since photo is small, we can't display any description on it
        showOverlay={false}
        size={PhotoWithWidgets.SIZE.SMALL}
      />
      <TextWrap className={css.title} data-test="confirmationMotivationText">
        {t('emailValidationBase', 'text.prefer_verified', {
          '{n}': login,
        })}
      </TextWrap>
    </Group>
  );
};

ConfirmationUserMotivationLayout.propTypes /* remove-proptypes */ = {
  online: PropTypes.bool.isRequired,
  login: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  photo: PropTypes.objectOf(PropTypes.any).isRequired, // Don't worry is validated inside 'PhotoWithWidgets'
};

export default ConfirmationUserMotivationLayout;
