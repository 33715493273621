import baseProcessThreeDSecureAnswer from '@core/payment/payProcess/paymentFlow/methods/baseProcessThreeDSecureAnswer';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import PaymentPendingPopupLayout from '../../../paymentPending/components/PaymentPendingPopupLayout';
import ThreeDSecureAbortPopup from '../../../widgets/threeDSecure/components/ThreeDSecureAbortPopup';

const processThreeDSecureAnswer: PaymentProcessStep = (data) => {
  return baseProcessThreeDSecureAnswer({
    PaymentPendingPopupLayout,
    ThreeDSecureAbortPopup,
  })(data);
};

export default processThreeDSecureAnswer;
