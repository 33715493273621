import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import useInverseColorScheme from '@core/utils/styles/useInverseColorScheme';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {
  Bar,
  ButtonLink,
  Card,
  Spacing,
  RowShort,
  SpacingLarge,
} from '@phoenix/ui';
import {H2, Text, TextMuted} from '@phoenix/typography';
import {ButtonType, SpacingSize, SpacingDirection} from '@phoenix/ui/constants';

import css from '../styles/ExtraRMBundlePopupLayout.css';

const DEFAULT_DISCOUNT = 30;

const ExtraRMBundlePopupLayout = ({url, sites, description, textPackage}) => {
  const [ref, inverseColor] = useInverseColorScheme();
  const isPhone = usePhoneBreakpoint();

  return (
    <Card data-test="rmBundlePopupLayout" innerRef={ref}>
      <Spacing size={SpacingSize.LARGE} withoutBottom className={css.banner}>
        <Spacing
          adaptive={false}
          withoutBottom
          direction={SpacingDirection.VERTICAL}
          size={isPhone ? SpacingSize.NORMAL : SpacingSize.NONE}
        >
          <TextMuted>{t('extraPopups', 'text.special_offer')}</TextMuted>
        </Spacing>
        <RowShort>
          <Text bold inverse className={css.percent}>
            {t('extraPopups', 'text.current_discount_off', {
              '{discountamount}': DEFAULT_DISCOUNT,
            })}
          </Text>
        </RowShort>
        <TextMuted>{textPackage}</TextMuted>
        <TextMuted>{description}</TextMuted>
      </Spacing>
      <Spacing direction={SpacingDirection.VERTICAL}>
        <Bar className={css.sites}>
          {sites.map((site, index) => (
            <div key={site.siteId} className={css.wrapLogo}>
              {index === 1 && (
                <H2
                  muted
                  className={css.plus}
                  data-test="rmBundlePopupLayoutLogo"
                >
                  +
                </H2>
              )}
              <div className={css.logo} data-test="rmBundlePopupLayoutLogo">
                <img
                  alt={site.domain}
                  className={css.image}
                  src={inverseColor ? site.logo.default : site.logo.inverse}
                />
              </div>
            </div>
          ))}
        </Bar>
      </Spacing>
      <SpacingLarge adaptive withoutTop>
        <ButtonLink
          className={cn(!isPhone && css.action)}
          href={url}
          type={ButtonType.PAY}
          fullWidth={isPhone}
        >
          {t('extraPopups', 'button.check_out')}
        </ButtonLink>
      </SpacingLarge>
    </Card>
  );
};

ExtraRMBundlePopupLayout.propTypes /* remove-proptypes */ = {
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.string.isRequired,
      domain: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        default: PropTypes.string.isRequired,
        inverse: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  textPackage: PropTypes.string.isRequired,
};

export default ExtraRMBundlePopupLayout;
