import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import ExtraDiscountInteractiveLikePopupLayout from '../components/ExtraDiscountInteractiveLikePopupLayout';

const openExtraDiscountInteractiveLikePopup = ({popupConfig, ...props}) =>
  PopupService.openPopup(
    <ExtraDiscountInteractiveLikePopupLayout {...props} />,
    {
      ...popupConfig,
      trackingName: 'extraDiscountInteractiveLike',
      autoLoadTracking: true,
    },
  );

export default openExtraDiscountInteractiveLikePopup;
