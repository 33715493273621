import type {FC} from 'react';
import React from 'react';

import {Badge} from '@phoenix/ui';
import {BadgeType} from '@phoenix/ui/constants';

type OnlineBadgeProps = {
  className?: string;
  online?: boolean;
  recentlyOnline?: boolean;
  inverse?: boolean;
};

const OnlineBadge: FC<OnlineBadgeProps> = ({
  className,
  online,
  recentlyOnline,
  inverse,
}) => {
  if (!online && !recentlyOnline) {
    return null;
  }

  return (
    <div className={className} data-test={online ? 'online' : 'recentlyOnline'}>
      <Badge
        type={online ? BadgeType.SUCCESS : BadgeType.WARNING}
        inverse={inverse}
      />
    </div>
  );
};

export default OnlineBadge;
