import {useReactiveVar} from '@apollo/client';

import headerPropsVar, {DEFAULT_PROPS} from '../graphql/vars/headerPropsVar';

export const resetHeaderProps = () => headerPropsVar(DEFAULT_PROPS);
export const setHeaderProps = (props) =>
  headerPropsVar({
    ...DEFAULT_PROPS,
    ...props,
  });

const useHeaderProps = () => useReactiveVar(headerPropsVar);

export default useHeaderProps;
