import type {Client} from '@core/graphql/types';
import globalSettingsCacheWatcher from '@core/application/utils/globalSettingsCacheWatcher';

import invalidateSearchResults from './invalidateSearchResults';

let isStarted = false;

/**
 * Update search cache version to fetch new search result.
 */
const startSearchCacheListener = (client: Client): void => {
  if (!isStarted) {
    isStarted = true;
    globalSettingsCacheWatcher(
      client,
      invalidateSearchResults,
      // Skip invalidation if you are increasing naughtyMode via popup on the search page to keep the same users.
      // In naughtyMode decreases - we need this invalidation to avoid blurred livecam profiles.
      true,
    );
  }
};

export default startSearchCacheListener;
