import {combineLatest} from 'rxjs';
import {distinct, filter} from 'rxjs/operators';

import isPayUrl from '@core/utils/url/isPayUrl';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';

import CHANGE_PERSONAL_IDENTITY_SUBSCRIPTION from '../graphql/subscriptions/changePersonalIdentity.gql';
import updatePersonalIdentity from './updatePersonalIdentity';

/**
 * @description Listen socket personalIdentityChange, route change
 *              and change verification status
 * @param {ApolloClient} client
 * @param {function} onPersonalIdentityChanged
 * @param {boolean} applyToAllRoutes
 */
const changePersonalIdentitySubscription = ({
  client,
  onPersonalIdentityChanged,
  applyToAllRoutes = false,
}) =>
  combineLatest(
    /**
     * We should perform action only if user is not on payment page or
     * if user is on payment page only when he lives last one.
     * The same as another such listener:
     * @see startExtraEventListener.js
     */
    client.subscribe({query: CHANGE_PERSONAL_IDENTITY_SUBSCRIPTION}),
    client.watchQuery({query: ROUTE_QUERY}),
  )
    .pipe(
      filter(
        ([, {data: routeData}]) =>
          applyToAllRoutes || !isPayUrl(routeData.route.current),
      ),
      distinct(([{data: subscriptionData}]) => subscriptionData),
    )
    .subscribe(
      ([
        {
          data: {
            personalIdentity: {value},
          },
        },
      ]) => {
        updatePersonalIdentity(value, onPersonalIdentityChanged);
      },
    );

export default changePersonalIdentitySubscription;
