import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {packagePricePropType} from '@core/payment/widgets/package/constants/propTypes';

import {SpacingShort} from '@phoenix/ui';
import {SpacingDirection} from '@phoenix/ui/constants';
import {Text, TextMuted} from '@phoenix/typography';

import css from '../styles/PaymentPackageOldPriceMuted.css';

const PaymentPackageOldPriceMuted = ({
  price: {
    pricePerPart: {
      currency,
      formatted: {hi, lo, shortPrice},
    },
  },
  inverse,
}) => (
  <SpacingShort
    direction={SpacingDirection.HORIZONTAL}
    className={css.price}
    stretch={false}
  >
    {currency.prefix && (
      <TextMuted small inverse={inverse}>
        {currency.prefix}
      </TextMuted>
    )}
    {shortPrice ? (
      <Text inverse={inverse}>{shortPrice}</Text>
    ) : (
      <Fragment>
        {hi && (
          <TextMuted small inverse={inverse}>
            {`${hi}.`}
          </TextMuted>
        )}
        {lo && (
          <TextMuted small inverse={inverse}>
            {lo}
          </TextMuted>
        )}
      </Fragment>
    )}
    {currency.suffix && (
      <Text small inverse={inverse}>
        {currency.suffix}
      </Text>
    )}
  </SpacingShort>
);

PaymentPackageOldPriceMuted.propTypes /* remove-proptypes */ = {
  price: packagePricePropType.isRequired,
  inverse: PropTypes.bool,
};

export default PaymentPackageOldPriceMuted;
