import {OPEN_PP_POPUP_WEEK_SPEAK} from '../../buyCoins/constants/vias';
import {WEEK_SPEAK_PACKAGE_NAME} from '../constants/specialOfferPackageNames';
import {WEEK_SPEAK} from '../constants/specialOfferPopups';

/**
 * Get type of success popup for coins by via
 * @param {string} via
 * @param {string} packageName
 * @param {array} orderIds
 * @returns {string|null}
 */
const getSuccessPopupType = ({via = '', packageName = '', orderIds = []}) => {
  if (orderIds.length > 1) {
    return null;
  }

  return via === OPEN_PP_POPUP_WEEK_SPEAK ||
    packageName === WEEK_SPEAK_PACKAGE_NAME
    ? WEEK_SPEAK
    : null;
};

export default getSuccessPopupType;
