import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {interval, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Progress} from '@phoenix/ui';
import {ProgressType} from '@phoenix/ui/constants';

import {IconSize} from '../constants/IconSize';
import css from '../styles/MicrofeaturesIconLayout.css';

const MicrofeaturesIconLayout = ({
  icon,
  active = false,
  type = MicrofeaturesIconLayout.TYPE.PRIMARY,
  size = MicrofeaturesIconLayout.SIZE.NORMAL,
  inline = false,
  inverse,
  round = true,
  withBorder = true,
  percentage,
  duration,
  className,
  'data-test': dataTest,
}) => {
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    // just one time percentage animation after loading (from 0 to current value)
    setTimeout(() => {
      setCurrentPercentage(percentage);
    }, 300);
  }, [percentage]);

  useEffect(() => {
    let subscription;

    // To avoid too often rerender we can calculate time duration on 1% of progress and update it one`s per this period
    if (active && duration && percentage) {
      // time duration of 1% of progress
      const percentDuration = Math.floor(duration / percentage);
      const progressInterval = interval(percentDuration * 1000); // in milliseconds

      // progress expire time
      const progressTimer = timer(duration * 1000); // in milliseconds
      const source = progressInterval.pipe(takeUntil(progressTimer));

      subscription = source.subscribe((pastProgress) =>
        // initial percentage minus count of iterations (1 iteration === 1%)
        setCurrentPercentage(percentage - pastProgress),
      );
    }

    return () => subscription && subscription.unsubscribe();
  }, [active, duration, percentage]);

  return (
    <div
      className={cn(
        className,
        css.icon,
        css[size],
        withBorder && css.withBorder,
        inline && css.inline,
        round && css.round,
        !active && css[type],
      )}
      data-test={dataTest}
    >
      {active ? (
        <Progress
          percentage={currentPercentage}
          startingPoint={0}
          rotate={false}
          withOverlay={false}
          type={type}
          data-test="microfeaturesIconProgress"
          inverse={inverse}
        >
          {icon}
        </Progress>
      ) : (
        icon
      )}
    </div>
  );
};

MicrofeaturesIconLayout.SIZE = IconSize;

MicrofeaturesIconLayout.TYPE = ProgressType;

MicrofeaturesIconLayout.propTypes /* remove-proptypes */ = {
  icon: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(MicrofeaturesIconLayout.SIZE)),
  type: PropTypes.oneOf(Object.values(MicrofeaturesIconLayout.TYPE)),
  percentage: PropTypes.number,
  inline: PropTypes.bool,
  round: PropTypes.bool,
  withBorder: PropTypes.bool,
  active: PropTypes.bool,
  inverse: PropTypes.bool,
  duration: PropTypes.number, // progress animation time in seconds
  className: PropTypes.string,
  'data-test': PropTypes.string,
};

export default MicrofeaturesIconLayout;
