import {fromEvent} from 'rxjs';
import {filter, share} from 'rxjs/operators';
import every from 'lodash/every';
import find from 'lodash/find';

import logger from '@core/logger';

import SUPPORTED_BANNER_TYPES from '../constants/supportedBannerTypes';

let eventCount = 0;
let timeframe;

const FRAME_INTERVAL = 500;
// Exported for testing purposes
export const MAX_EVENT_PER_FRAME = 500;

/**
 * @param {string} error
 *
 * Exported only for testing
 * @see createPostMessageObservable.test.js
 */
export const logWarning = (error) => {
  logger.sendWarning(`[PostMessageObservable] ${error}`);
};

/**
 * @param {Event} event
 * @param {Object} rules
 * @returns {boolean}
 */
const isEventDataValid = (event, rules) => {
  const {callbackEvent, eventData} = event.data;

  if (!callbackEvent || !rules || !eventData) {
    return false;
  }

  // We have different rules data structure from coreg widgets and rm banners
  const eventRules =
    find(rules, {eventName: callbackEvent}) || rules[callbackEvent];

  if (!eventRules) {
    return false;
  }

  if (!SUPPORTED_BANNER_TYPES.includes(callbackEvent)) {
    logWarning(`There is no action on frontend for event ${callbackEvent}`);
    return false;
  }

  return every(eventRules.rules, (rule, key) => {
    const {name, type} = rule;
    if (
      // eslint-disable-next-line
      typeof event.data[name] !== type &&
      // eslint-disable-next-line
      !(eventData && typeof eventData[name] === type)
    ) {
      logWarning(`Invalid type data in postMessage: ${key}`);
      return false;
    }

    const {regexp} = rule;
    if (
      regexp &&
      !(event.data[name] && event.data[name].match(new RegExp(regexp))) &&
      !(
        eventData &&
        eventData[name] &&
        eventData[name].match(new RegExp(regexp))
      )
    ) {
      logWarning(`Invalid type data in postMessage: ${key}`);
      return false;
    }

    return true;
  });
};

/**
 * Filter events by count per timeframe
 * @returns {boolean}
 */
const checkMaxEventPerFrame = () => {
  if (timeframe && eventCount > MAX_EVENT_PER_FRAME) {
    return false;
  }

  eventCount += 1;
  if (timeframe) {
    return true;
  }

  timeframe = setTimeout(() => {
    eventCount = 0;
    timeframe = null;
  }, FRAME_INTERVAL);

  return true;
};

/**
 * Used only for testing purposes, for creating independent
 * from postmessage and testable entity.
 * @param {Observable} operation
 * @param {string} origin
 * @param {Object} rules
 * @returns {Observable}
 */
export const pipeObservable = (operation, origin, rules) =>
  operation.pipe(
    // Throw away events arrived from non-banner sources
    filter((event) => event.origin === origin),
    // Filter events by banner rules
    filter((event) => isEventDataValid(event, rules)),
    // We disallow to perform LARGE amount of events.
    // This filter is like 'security' check from malefactors
    filter(checkMaxEventPerFrame),
    share(),
  );

/**
 * @param {string} origin
 * @param {Object} rules
 * @returns {Observable}
 */
const createPostMessageObservable = (origin, rules) =>
  pipeObservable(fromEvent(window, 'message'), origin, rules);

export default createPostMessageObservable;
