import React from 'react';
import PropTypes from 'prop-types';

import setPrimaryPhoto from '@core/user/profile/current/utils/setPrimaryPhoto';

import deletePhoto from '@phoenix/user/profile/current/utils/deletePhoto';

import MediaMenu from '../../current/components/MediaMenu';

const PhotoMenu = ({photoId, isPrimary}) => (
  <MediaMenu
    isPrimary={isPrimary}
    onSetPrimary={() => setPrimaryPhoto(photoId)}
    onDeletePhoto={() => deletePhoto(photoId)}
  />
);

PhotoMenu.propTypes /* remove-proptypes */ = {
  photoId: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
};

export default PhotoMenu;
