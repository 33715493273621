import {useEffect} from 'react';
import type {ApolloError} from '@apollo/client';
import {useQuery} from '@apollo/client';

import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import isInstantRepeatCheckedVar from '@core/graphql/vars/isInstantRepeatCheckedVar';

import COINS_AUTOFILL_AGREEMENT_QUERY from '../graphql/queries/coinsAutofillAgreement.gql';
import type {
  CoinsAutofillAgreementQuery,
  CoinsAutofillAgreementQueryVariables,
} from '../graphql/queries/coinsAutofillAgreement';

type UseConsAutofillAgreementResult = {
  loading: boolean;
  error?: ApolloError;
  available: boolean;
  text: string;
};

const useCoinsAutofillAgreement = (): UseConsAutofillAgreementResult => {
  const {action, via, prevVia, source} = usePaymentParams();

  const {data, loading, error} = useQuery<
    CoinsAutofillAgreementQuery,
    CoinsAutofillAgreementQueryVariables
  >(COINS_AUTOFILL_AGREEMENT_QUERY, {
    variables: {action, via, prevVia, source},
  });

  const available =
    data?.payment.templateSettings.additionalTerms.creditAutofillAgreement ||
    false;

  const checkedByDefault =
    data?.payment.templateSettings.additionalTermsStatusMap
      .creditAutofillAgreement || false;

  const text =
    data?.payment.paymentFooter.blockDataMap.textCoinsAutofillAgreement || '';

  useEffect(() => {
    if (available && isInstantRepeatCheckedVar() === null) {
      isInstantRepeatCheckedVar(checkedByDefault);
    }
  }, [available, checkedByDefault]);

  return {
    loading,
    error,
    available,
    text,
  };
};

export default useCoinsAutofillAgreement;
