import React, {Fragment, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';

import css from '../styles/FileSelectWrapper.css';

const FileSelectWrapper = ({
  children,
  onFileSelect,
  multiple,
  accept,
  capture,
  disabled,
}) => {
  const file = useRef();
  const handleClick = useCallback(() => file.current.click(), []);
  const handleChange = useCallback(
    ({target: {files}}) => {
      /**
       * @important Need check on the file length
       * @case User can open modal window of selecting file(-s) and just close it without uploading files
       */
      files.length && onFileSelect([...files]);
    },
    [onFileSelect],
  );

  return (
    <Fragment>
      {children({onClick: handleClick})}
      <input
        className={css.file}
        ref={file}
        type="file"
        onChange={handleChange}
        multiple={multiple}
        accept={accept}
        capture={capture}
        data-test="file"
        disabled={disabled}
      />
    </Fragment>
  );
};

FileSelectWrapper.propTypes /* remove-proptypes */ = {
  onFileSelect: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  capture: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

export default FileSelectWrapper;
