import type {ApolloCache} from '@apollo/client';

/**
 * Cleans up search results. SearchListQuery will re-fetch them if mounted.
 */
const invalidateSearchResults = ({cache}: {cache: ApolloCache<object>}) => {
  cache.evict({id: 'Search:{}', fieldName: 'users'});
};

export default invalidateSearchResults;
