import TARGET_USER_ADD_OPEN_PROFILE_MUTATION from '@core/user/profile/target/graphql/mutations/targetUserAddOpenProfile.gql';
import {getClientInstance} from '@core/graphql/client';

import updateUserViewPermission from '@phoenix/user/profile/target/utils/updateUserViewPermission';

/**
 * Mark target user profile as opened for save permissions for watch photo and video after reload.
 * @param userId
 * @param source
 * @return {Promise<FetchResult<any>|void>}
 */
const markTargetUserProfileAsOpened = async ({userId, source} = {}) => {
  return getClientInstance().mutate({
    mutation: TARGET_USER_ADD_OPEN_PROFILE_MUTATION,
    variables: {userId, source},
    update: (cache, response) => {
      if (response.data.user.addOpenProfile.result) {
        updateUserViewPermission(userId, {photo: true, video: true});
      }
    },
    optimisticResponse: {
      user: {
        addOpenProfile: {
          result: true,
          errors: null,
          __typename: 'BooleanMutationResult',
        },
        __typename: 'UserMutation',
      },
      __typename: 'Mutation',
    },
  });
};

export default markTargetUserProfileAsOpened;
