import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {processCopyright} from '@core/utils/processText';

import css from '../styles/Copyright.css';

const Copyright = ({copyright}) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const elm = document.createElement('div');
    elm.innerHTML = copyright;
    processCopyright(elm);

    setContent(elm.innerHTML);
  }, [copyright]);

  return (
    <div className={css.text} dangerouslySetInnerHTML={{__html: content}} />
  );
};

Copyright.propTypes /* remove-proptypes */ = {
  copyright: PropTypes.string.isRequired,
};

export default Copyright;
