import React from 'react';
import PropTypes from 'prop-types';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import t from '@core/translations/translate';

import {Row, RowShort, ButtonPrimary, ButtonLink} from '@phoenix/ui';
import {ButtonType, Align} from '@phoenix/ui/constants';

import css from '../styles/SepaDebtorActions.css';

const SepaDebtorActions = ({onCloseClick, invoiceUrl}) => {
  const isMobile = usePhoneBreakpoint();

  return (
    <Row data-test="sepaDebtorActions" align={Align.CENTER}>
      {invoiceUrl && (
        <RowShort data-test="sepaDebtorActionsRowPay">
          <ButtonLink
            data-test="sepaDebtorActionsButtonPay"
            type={ButtonType.PRIMARY}
            inline
            fullWidth={isMobile}
            className={css.action}
            href={invoiceUrl}
            target="_self"
          >
            {t('sepaDebtor', 'text.pay_debt_now')}
          </ButtonLink>
        </RowShort>
      )}
      {onCloseClick && !invoiceUrl && (
        <RowShort data-test="sepaDebtorActionsRowDoItLater">
          <ButtonPrimary
            data-test="sepaDebtorActionsButtonDoItLater"
            inline
            fullWidth={isMobile}
            className={css.action}
            onClick={onCloseClick}
            trackingName="close"
          >
            {t('sepaDebtor', 'text.button_text')}
          </ButtonPrimary>
        </RowShort>
      )}
    </Row>
  );
};

SepaDebtorActions.propTypes /* remove-proptypes */ = {
  onCloseClick: PropTypes.func,
  invoiceUrl: PropTypes.string,
};

export default SepaDebtorActions;
