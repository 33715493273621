import {getClientInstance} from '@core/graphql/client';

import PMA_REQUEST_PHOTO from '../graphql/queries/pmaRequestPhoto.gql';
import type {PmaRequestPhotoQuery} from '../graphql/queries/pmaRequestPhoto';

const isEnabledPmaRequestPhoto = (): boolean => {
  const data = getClientInstance().readQuery<PmaRequestPhotoQuery>({
    query: PMA_REQUEST_PHOTO,
  });

  return data?.userFeatures?.pmaRequestPhoto?.available || false;
};

export default isEnabledPmaRequestPhoto;
