import {useQuery} from '@apollo/client';

import useCoinsModel from '@phoenix/coins/utils/useCoinsModel';

import CREDITS_BALANCE_QUERY from '../graphql/queries/creditsBalance.gql';

/**
 * return credits balance
 * @returns {number|*|number}
 */
const useCreditsBalanceQuery = () => {
  const {isCoinsModel, loading: modelLoading} = useCoinsModel();
  const {data, loading} = useQuery(CREDITS_BALANCE_QUERY, {
    skip: !isCoinsModel,
  });

  return {
    balance: data?.credits?.balance ?? 0,
    loading: modelLoading || loading,
  };
};

export default useCreditsBalanceQuery;
