import useEventCallback from '@core/utils/react/useEventCallback';
import {HEADER_ID, TOP_WRAPPER} from '@core/header/constants/header';
import setHeaderHeightVariable from '@core/header/utils/setHeaderHeightVariable';

import useHeaderDisplayerData from './useHeaderDisplayerData';
import getTopWrapperHeight from './getTopWrapperHeight';
import setTopWrapperHeightVariable from './setTopWrapperHeightVariable';

/**
 * We use this hook to update the variable heights of the header and toolbar immediately after some events
 * @see AnimatedLayeredSystemNotifications.js
 * @see AnimatedSlideableFloatingSystemNotifications.js
 */
const useHeaderAndToolbarVariablesUpdater = () => {
  const {topWrapperHeightVariableAvailable} = useHeaderDisplayerData();

  return useEventCallback(() => {
    const toolbar = document.getElementById(TOP_WRAPPER);
    const header = document.getElementById(HEADER_ID);

    /**
     * This condition is necessary due to the fact that the variable needs to be updated when the toolbar is at the bottom
     * @see HeaderDisplayer.js
     */
    if (toolbar && topWrapperHeightVariableAvailable) {
      const height = getTopWrapperHeight(toolbar);

      setTopWrapperHeightVariable(height);
    }
    if (header) {
      setHeaderHeightVariable(header.clientHeight);
    }
  });
};

export default useHeaderAndToolbarVariablesUpdater;
