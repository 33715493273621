import type {FC} from 'react';
import React from 'react';

import {PlaceholderBlock, PlaceholderCircle} from '@phoenix/ui';

import css from '../styles/PhotoLayout.css';

type PhotoLayoutPlaceholderProps = {
  round: boolean;
};

/**
 * Placeholder for abstract photo widget
 */
const PhotoLayoutPlaceholder: FC<PhotoLayoutPlaceholderProps> = ({round}) => (
  <div className={css.photo} data-test="photoPlaceholder">
    {round ? <PlaceholderCircle /> : <PlaceholderBlock />}
  </div>
);

export default PhotoLayoutPlaceholder;
