import {combineLatest, from, fromEvent, merge} from 'rxjs';
import {filter, startWith, map, tap, withLatestFrom} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import isInRouteList from '@core/utils/routing/isInRouteList';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';

import UPSELL_SUBSCRIPTION from '../graphql/subscriptions/upsell.gql';
import DISABLED_ROUTES from '../constants/disabledRoutes';
import openUpsellPopup from './openUpsellPopup';
import {
  cacheData,
  clearCachedData,
  getCacheData,
} from './storageForUpsellPopup';

let startedListeners = false;

/**
 * @param {ApolloClient} client
 * @param {String} userId
 */
const startExtraEventListener = (client, userId) => {
  if (startedListeners) {
    return;
  }

  startedListeners = true;

  /**
   * Observable that emits on every Popup state change (open/close).
   * @type {Observable<{ detail: { hasQueue: boolean } }>}
   */
  const popupStateObservable = fromEvent(
    document,
    POPUP_STATE_CHANGE_EVENT,
  ).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
  );

  const processing$ = combineLatest([
    client.watchQuery({query: ROUTE_QUERY}),
    popupStateObservable,
  ]).pipe(
    map((rest) => [getCacheData(userId), rest]),
    filter(
      ([upsellData, [{data: routeData}, {detail: popupData}]]) =>
        Boolean(upsellData) &&
        !isInRouteList(DISABLED_ROUTES, routeData.route.current) &&
        !popupData.hasQueue,
    ),
    tap(() => clearCachedData(userId)),
    map(([upsellData]) => upsellData.upsell.type),
  );

  const interaction$ = from(
    client.subscribe({query: UPSELL_SUBSCRIPTION}),
  ).pipe(
    withLatestFrom(
      client.watchQuery({query: ROUTE_QUERY}),
      popupStateObservable,
    ),
    filter(([{data: upsellData}, {data: routeData}, {detail: popupData}]) => {
      const canShow =
        !isInRouteList(DISABLED_ROUTES, routeData.route.current) &&
        !popupData.hasQueue;

      if (!canShow) {
        cacheData(userId, upsellData);
      }

      return canShow;
    }),
    map(([upsellData]) => upsellData.data.upsell.type),
  );

  merge(processing$, interaction$).subscribe((type) => {
    openUpsellPopup(client, type);

    client.cache.evict({
      id: 'ROOT_SUBSCRIPTION',
      fieldName: 'upsell',
    });
  });
};

export default startExtraEventListener;
