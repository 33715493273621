import React, {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {H3, TextMuted} from '@phoenix/typography';
import {Button, Row, RowShort, Icon} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {ButtonType, IconSize} from '@phoenix/ui/constants';

import css from '../styles/ExtraBuyOnePlusOnePopupLayout.css';

/**
 * @const {number}
 * export for tests
 */
export const BUBBLE_COUNT = 6;

const ExtraBuyOnePlusOnePopupLayout = ({price, url}) => {
  const history = useHistory();

  const handleClickOnContent = useCallback(() => {
    history.push(url);
  }, [url, history]);

  const handleClick = useCallback(
    (event) => {
      /**
       * We prevent further propagation of the current event in the capturing and bubbling phases.
       * Because we have got action click on parent element and we will have a double click.
       */
      event.stopPropagation();
      history.push(url);
    },
    [history, url],
  );

  const isPhone = usePhoneBreakpoint();

  return (
    <PopupFrame
      className={css.popup}
      data-test="extraBuyOnePlusOnePopupLayout"
      onClick={isPhone ? handleClickOnContent : null}
      trackingName={isPhone ? 'content' : null}
      content={
        <Fragment>
          <Row>
            <div className={css.iconWrap}>
              <div className={css.icon}>
                <Icon type="gift" size={IconSize.SIZE_96} />
              </div>
            </div>
            {!window.IS_INTEGRATION_TEST_ENVIRONMENT && (
              <div className={css.animationHolder}>
                {range(BUBBLE_COUNT).map((index) => (
                  <div
                    className={css.animationBubble}
                    key={index}
                    data-test="extraBuyOnePlusOnePopupBubble"
                  />
                ))}
                <div className={css.animationCircle} />
              </div>
            )}
          </Row>
          <RowShort>
            <H3>{t('extraPopups', 'text.buyOneDay')}</H3>
            <H3>{t('extraPopups', 'text.toGetPlusOne')}</H3>
          </RowShort>
          <RowShort>
            <TextMuted>{t('extraPopups', 'text.dont_skip')}</TextMuted>
          </RowShort>
        </Fragment>
      }
      actions={
        <Button
          onClick={handleClick}
          trackingName="getIt"
          className={cn(!isPhone && css.action)}
          type={ButtonType.PAY}
          fullWidth={isPhone}
          adaptive
          data-test="extraBuyOnePlusOnePopupAction"
        >
          {t('extraPopups', 'text.get_it_for', {'{price}': price})}
        </Button>
      }
    />
  );
};

ExtraBuyOnePlusOnePopupLayout.propTypes /* remove-proptypes */ = {
  price: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ExtraBuyOnePlusOnePopupLayout;
