import React, {useCallback, forwardRef} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t from '@core/translations/translate';
import {PhotoRequestTypeEnum} from '@core/types';
import GalleryCarousel from '@core/gallery/components/GalleryCarousel';
import getCounter from '@core/gallery/utils/getCounter';

import {Text, TextWithIcon} from '@phoenix/typography';
import {Overlay, Spacing} from '@phoenix/ui';
import {
  OverlayType,
  OverlaySaturation,
  SpacingSize,
} from '@phoenix/ui/constants';

import css from '../styles/GalleryLayout.css';

const GalleryPopupLayout = forwardRef(
  (
    {
      className,
      showCounter,
      counterOverContent,
      items,
      showNav,
      startIndex,
      onSlide,
      renderLeftNav,
      renderRightNav,
      renderBottom,
      renderItem,
      disableSwipe,
      isZoomed,
    },
    galleryRef,
  ) => {
    const showBottomContent = Boolean(items.length && renderBottom);

    /** Need render counter wrap already when showCounter prop is active,
     * because counter div add specific margin for layout
     */
    const renderCounter = useCallback(
      (itemIndex) => {
        const currentMedia = items[itemIndex];

        const {isPrivate, isRequested, requestType} =
          currentMedia?.photo?.privateAttributes || {};

        const showPrivatePhotoDetails =
          isPrivate &&
          requestType === PhotoRequestTypeEnum.privateAlbum &&
          isRequested;

        return (
          <div
            className={cn(css.counter, counterOverContent && css.overContent)}
          >
            <Overlay
              saturation={OverlaySaturation.HEAVY}
              type={OverlayType.GRADIENT_FROM_TOP}
            />
            {showPrivatePhotoDetails && (
              <Spacing className={css.details}>
                <TextWithIcon
                  data-test="assistantError"
                  icon="lock"
                  inverse
                  space={SpacingSize.SHORTEST}
                  small
                >
                  {t('privatePhoto', 'title.private_photo')}
                </TextWithIcon>
              </Spacing>
            )}
            {items.length > 1 && (
              <Text data-test="counter" className={css.count}>
                {getCounter(itemIndex + 1, items.length)}
              </Text>
            )}
          </div>
        );
      },
      [counterOverContent, items],
    );

    return (
      <div
        className={cn(css.content, className)}
        data-test="gallery"
        ref={galleryRef}
      >
        <GalleryCarousel
          className={css.gallery}
          items={items}
          startIndex={startIndex}
          showNav={showNav}
          disableSwipe={disableSwipe}
          renderItem={renderItem}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          renderHeader={showCounter ? renderCounter : null}
          renderBottom={showBottomContent ? renderBottom : null}
          onSlide={onSlide}
          isZoomed={isZoomed}
        />
      </div>
    );
  },
);

GalleryPopupLayout.propTypes /* remove-proptypes */ = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  startIndex: PropTypes.number,
  showNav: PropTypes.bool.isRequired,
  showCounter: PropTypes.bool.isRequired,
  onSlide: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderLeftNav: PropTypes.func.isRequired,
  renderRightNav: PropTypes.func.isRequired,
  renderBottom: PropTypes.func,
  counterOverContent: PropTypes.bool,
  isZoomed: PropTypes.bool,
  disableSwipe: PropTypes.bool,
};

export default GalleryPopupLayout;
