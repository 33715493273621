import createTranslationsMap from '@core/translations/createTranslationsMap';

import {MICROFEATURES} from './microfeatures';

export const SUCCESS_TITLE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t('microfeatures', 'success.title.done'),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'success.title.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'success.title.priorityMan',
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'success.title.priorityMan',
    ),
    [MICROFEATURES.TELEGRAM_CONVERSATION]: t('microfeatures', 'title.success'),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'success.title.done'),
    [MICROFEATURES.FLIRT_ALARM]: t('microfeatures', 'success.title.flirtAlarm'),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'success.title.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'success.title.priorityMan',
    ),
  }));

export const SUCCESS_ACTION = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'success.button.lets_go',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'success.button.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'success.button.priorityMan',
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'success.button.priorityMan',
    ),
    [MICROFEATURES.SUPER_BOOST]: t('microfeatures', 'success.button.lets_go'),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'success.button.flirtAlarm',
    ),
    [MICROFEATURES.TELEGRAM_CONVERSATION]: t('microfeatures', 'button.go'),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'success.button.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'success.button.topInChat'),
  }));
export const SUCCESS_ACTION_GAY = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'success.button.microIncognito.gay',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'success.button.moreLikes.gay',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'success.button.priorityMan.gay',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'success.button.superBoost.gay',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'success.button.flirtAlarm.gay',
    ),
    [MICROFEATURES.SEX_ALARM]: t(
      'microfeatures',
      'success.button.sexAlarm.gay',
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'success.button.topInChat.gay',
    ),
  }));

export const SUCCESS_DESCRIPTION = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'congratulation.microIncognito',
    ),
    [MICROFEATURES.MORE_LIKES]: t('microfeatures', 'congratulation.moreLikes'),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'congratulation.priorityMan',
    ),
    [MICROFEATURES.SEARCH_QUEEN]: t(
      'microfeatures',
      'congratulation.priorityMan',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'congratulation.superBoost',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'congratulation.flirtAlarm',
    ),
    [MICROFEATURES.TELEGRAM_CONVERSATION]: t(
      'microfeatures',
      'congratulation.telegram_conversation',
    ),
    [MICROFEATURES.SEX_ALARM]: t('microfeatures', 'congratulation.sexAlarm'),
    [MICROFEATURES.TOP_IN_CHAT]: t('microfeatures', 'congratulation.topInChat'),
  }));
export const SUCCESS_DESCRIPTION_GAY = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'congratulation.microIncognito.gay',
    ),
    [MICROFEATURES.MORE_LIKES]: t(
      'microfeatures',
      'congratulation.moreLikes.gay',
    ),
    [MICROFEATURES.PRIORITY_MAN]: t(
      'microfeatures',
      'congratulation.priorityMan.gay',
    ),
    [MICROFEATURES.SUPER_BOOST]: t(
      'microfeatures',
      'congratulation.superBoost.gay',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'congratulation.flirtAlarm.gay',
    ),
    [MICROFEATURES.SEX_ALARM]: t(
      'microfeatures',
      'congratulation.sexAlarm.gay',
    ),
    [MICROFEATURES.TOP_IN_CHAT]: t(
      'microfeatures',
      'congratulation.topInChat.gay',
    ),
  }));
export const SUCCESS_DESCRIPTION_FEMALE = () =>
  createTranslationsMap((t) => ({
    [MICROFEATURES.MICRO_INCOGNITO]: t(
      'microfeatures',
      'congratulation.microIncognito.heteroFemale',
    ),
    [MICROFEATURES.FLIRT_ALARM]: t(
      'microfeatures',
      'congratulation.flirtAlarm.heteroFemale',
    ),
  }));
