import type {FC} from 'react';
import React from 'react';

import useSystemNotifications from '@core/systemNotifications/utils/useSystemNotifications';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import SystemNotificationAppearance from '@core/theming/constants/features/SystemNotificationAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import AnimatedLayeredSystemNotifications from '../components/AnimatedLayeredSystemNotifications';
import AnimatedSlideableFloatingSystemNotifications from '../components/AnimatedSlideableFloatingSystemNotifications';
import NOTIFICATION_TYPE_TO_COMPONENT_MAP from '../constants/notificationTypeToComponentMap';
import getObserver from '../dataSources';

type SystemNotificationsProps = {
  onTop?: boolean;
};

const SystemNotifications: FC<SystemNotificationsProps> = ({onTop = false}) => {
  const {notifications, expireTime, onClose} =
    useSystemNotifications(getObserver);

  /**
   * @todo
   * If designers will invent new non-adequate types of notifications with very different design
   * discard of usage of such hook and use "ThemedComponentLoader"
   */
  const {data: appearance, loading: appearanceLoading} =
    useThemeFeature<SystemNotificationAppearance>(
      'systemNotification',
      'appearance',
    );
  const {data: inverse, loading: inverseLoading} = useThemeFeature<boolean>(
    'systemNotification',
    'inverse',
  );

  if (appearanceLoading || inverseLoading) {
    return null;
  }

  const floating = appearance === SystemNotificationAppearance.FLOATING;

  const children = notifications
    .slice()
    /**
     * Since first notification will dominate. All new notices go under it in case if they are
     * absolutely positioned.
     * @see AnimatedLayeredSystemNotifications
     */
    .reverse()
    .map(({key, type, ...props}, index) => {
      const Notification = NOTIFICATION_TYPE_TO_COMPONENT_MAP[type];
      const isFirst = index === notifications.length - 1;

      return (
        <BabciaScopedProvider
          key={key}
          trackingLabel={type}
          context="system"
          category={BabciaScopedProvider.CATEGORY.NOTIFICATION}
        >
          <Notification
            {...props}
            data-test="systemNotification"
            onClose={onClose}
            inverse={inverse}
            expire={isFirst ? expireTime : 0}
            mediaType={type}
            floating={floating}
          />
        </BabciaScopedProvider>
      );
    });

  // Based on theme property we should apply different mechanisms for animation and display
  return floating ? (
    <AnimatedSlideableFloatingSystemNotifications>
      {children}
    </AnimatedSlideableFloatingSystemNotifications>
  ) : (
    <AnimatedLayeredSystemNotifications onTop={onTop}>
      {children}
    </AnimatedLayeredSystemNotifications>
  );
};

export default SystemNotifications;
