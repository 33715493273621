import MEDIA_REASONS from '@core/disapprove/constants/mediaReasons';
import ImageGroups from '@core/theming/constants/ImageGroups';

import getThemedImage from '@phoenix/theming/utils/getThemedImage';

export default {
  [MEDIA_REASONS.ADVERTISING]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'advertising',
  ),
  [MEDIA_REASONS.CELEBRITIES]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'celebrities',
  ),
  [MEDIA_REASONS.CORRESPONDENCE]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'correspondence',
  ),
  [MEDIA_REASONS.ESCORT_SERVICE]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'escortService',
  ),
  [MEDIA_REASONS.SCAM]: getThemedImage(ImageGroups.DISAPPROVE_REASONS, 'scam'),
  [MEDIA_REASONS.SPAM]: getThemedImage(ImageGroups.DISAPPROVE_REASONS, 'spam'),
  [MEDIA_REASONS.NO_PEOPLE]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'noPeople',
  ),
  [MEDIA_REASONS.GROUP_PHOTO]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'group',
  ),
  [MEDIA_REASONS.GROUP_VIDEO]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'group',
  ),
  [MEDIA_REASONS.GROUP_MEDIA]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'group',
  ),
  [MEDIA_REASONS.OFFCENSE]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'offense',
  ),
  [MEDIA_REASONS.GENDER_MISMATCH]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'genderMismatch',
  ),
  [MEDIA_REASONS.PERSONAL_DETAILS]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'personalDetails',
  ),
  [MEDIA_REASONS.PERSONAL_DETAILS_JOINED]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'personalDetails',
  ),
  [MEDIA_REASONS.VIOLENCE_PROFILE]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'violence',
  ),
  [MEDIA_REASONS.VIOLENCE_MESSAGES]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'violence',
  ),
  [MEDIA_REASONS.UNDER18]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'under18',
  ),
  [MEDIA_REASONS.SEXUAL]: getThemedImage(
    ImageGroups.DISAPPROVE_REASONS,
    'sexual',
  ),
};
