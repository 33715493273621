import React from 'react';

import {ICONS} from '../../icons/constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesPopupAnimationLayout from './MicrofeaturesPopupAnimationLayout';
import css from '../styles/MicrofeaturesAnimationMoreLikes.css';

const ICONS_COUNT = 8;

const MicrofeaturesAnimationMoreLikes = () => (
  <MicrofeaturesPopupAnimationLayout
    iconsCount={ICONS_COUNT}
    iconType={ICONS[MICROFEATURES.MORE_LIKES]}
    className={css.icon}
  />
);

export default MicrofeaturesAnimationMoreLikes;
