import PAYMENT_ACTIONS, {
  IDEAL_METHODS,
  SEPA_METHODS,
} from '@core/payment/common/constants/paymentMethods';
import type {PaymentMethods} from '@core/payment/common/types/paymentMethods';

const ALLOWED_METHOD_LIST = Object.values({
  [PAYMENT_ACTIONS.CARD]: PAYMENT_ACTIONS.CARD,
  [PAYMENT_ACTIONS.EPS]: PAYMENT_ACTIONS.EPS,
  ...Object.assign(SEPA_METHODS),
  ...Object.assign(IDEAL_METHODS),
});

const isWithPolicyAgreementMethod = (method: PaymentMethods) => {
  return ALLOWED_METHOD_LIST.includes(method);
};

export default isWithPolicyAgreementMethod;
