import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useReactiveVar} from '@apollo/client';

import SmallPhoneBreakpoint from '@core/responsive/SmallPhoneBreakpoint';
import toggleBottomSpace from '@core/messenger/senderForm/utils/toggleBottomSpace';
import isAppLayoutSpacedBottomVar from '@core/graphql/vars/isAppLayoutSpacedBottomVar';

import {FormInput} from '@phoenix/form/input';
import {Button, Group, Loader} from '@phoenix/ui';
import {VerticalAlign} from '@phoenix/ui/constants';

import ActionWithSuccessMessage from './ActionWithSuccessMessage';
import css from '../styles/ConfirmationForm.css';

/**
 * Common editable form for confirmation widget
 * @see ConfirmationChangeEmail.js
 * @see ConfirmationActivationCode.js
 */
const ConfirmationForm = ({
  fieldLabel,
  fieldName,
  onSubmit,
  submitText,
  successMessage,
  showSuccessMessage,
  loading,
  fieldMaxLength,
}) => {
  const ref = useRef();
  const isAppLayoutSpacedBottom = useReactiveVar(isAppLayoutSpacedBottomVar);

  const handleFocus = () => {
    toggleBottomSpace(true, true);
  };

  const handleBlur = () => {
    toggleBottomSpace(false, true);
  };

  useEffect(() => {
    if (isAppLayoutSpacedBottom) {
      ref.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [isAppLayoutSpacedBottom]);

  return (
    <Loader small active={loading}>
      <form data-test="confirmationForm" onSubmit={onSubmit}>
        <SmallPhoneBreakpoint>
          {(matches) => (
            <Group
              horizontal={!matches}
              verticalAlign={VerticalAlign.TOP}
              itemWrapperClassName={!matches ? css.item : null}
            >
              <div ref={ref}>
                <FormInput
                  data-test={`${fieldName}ConfirmationField`}
                  name={fieldName}
                  label={fieldLabel}
                  maxLength={fieldMaxLength}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
              </div>
              <div className={!matches ? css.shiftedAction : css.action}>
                <ActionWithSuccessMessage
                  successMessage={successMessage}
                  showSuccessMessage={showSuccessMessage}
                  actionComponent={Button}
                  submit
                  trackingName={`${fieldName}Submit`}
                  fullWidth
                  data-test={`${fieldName}ConfirmationSubmit`}
                >
                  {submitText}
                </ActionWithSuccessMessage>
              </div>
            </Group>
          )}
        </SmallPhoneBreakpoint>
      </form>
    </Loader>
  );
};

ConfirmationForm.propTypes /* remove-proptypes */ = {
  fieldLabel: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired, // Name, required for 'Formik'
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired, // Text, displayed on submit action
  successMessage: PropTypes.string.isRequired, // Text, displayed in success message after submit
  showSuccessMessage: PropTypes.bool.isRequired, // Used to change appearance between button and success text
  loading: PropTypes.bool.isRequired,
  fieldMaxLength: PropTypes.number,
};

export default ConfirmationForm;
