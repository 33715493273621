import React, {useRef, useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';

import FILE_PREVIEW_QUERY from '@core/mediaUpload/graphql/queries/filePreview.gql';
import useBlobUrl from '@core/mediaUpload/utils/useBlobUrl';
import useVideoPreviewDurationFix from '@core/mediaUpload/utils/useVideoPreviewDurationFix';
import ImageGroups from '@core/theming/constants/ImageGroups';

import {FittingImage, PlaceholderCircle} from '@phoenix/ui';
import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import Video from '@phoenix/user/video/components/Video';
import {ImageScaling} from '@phoenix/ui/constants';
import getThemedImage from '@phoenix/theming/utils/getThemedImage';

import css from '../styles/FilePreview.css';

const FilePreview = ({blob, scaling, controls}) => {
  const {data, loading, error} = useQuery(FILE_PREVIEW_QUERY);
  const url = useBlobUrl(blob);
  const ref = useRef();
  useVideoPreviewDurationFix(ref);
  const [loadError, setLoadError] = useState(false);
  const handleError = useCallback(() => setLoadError(true), []);

  useEffect(() => {
    // Fix IOS safari video preview
    ref.current && ref.current.load();
  }, []);

  if (loading) return <PlaceholderCircle />;
  if (error) return <ErrorBoundary error={error} />;

  const {
    myUser: {
      profile: {gender},
    },
  } = data;

  if (blob.type.match(/image/) || loadError) {
    return (
      <div className={css.image} data-test="filePreviewImage">
        <FittingImage
          className={css.image}
          url={
            loadError ? getThemedImage(ImageGroups.DEFAULT_AVATAR, gender) : url
          }
          scaling={scaling}
          onError={handleError}
        />
      </div>
    );
  }

  if (blob.type.match(/video/)) {
    return (
      <Video
        url={url}
        scaling={scaling}
        controls={controls}
        ref={ref}
        onError={handleError}
        disablePictureInPicture
      />
    );
  }

  return null;
};

FilePreview.SCALING = ImageScaling;

FilePreview.propTypes /* remove-proptypes */ = {
  blob: PropTypes.instanceOf(window.Blob).isRequired,
  scaling: PropTypes.oneOf(Object.values(FilePreview.SCALING)).isRequired,
  controls: PropTypes.bool,
};

export default FilePreview;
