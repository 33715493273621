/**
 * @see TagsDisplayer
 * @see getTagsFromUser
 */
enum Tags {
  DISCREET_PROFILE = 'discreetProfile',
  NEW = 'new',
  LIVECAM = 'livecam',
  PRIVATE_PHOTOS = 'privatePhotos',
  PARTNER_NETWORK = 'partnerNetwork',
  MODERATOR = 'moderator',
  ONLINE = 'online',
  RECENTLY_ONLINE = 'recentlyOnline',
  VIRTUAL_PARTNER = 'virtualPartner',
}

export default Tags;
