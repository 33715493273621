import type {Client} from '@core/graphql/types';
import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';
import getActiveRecipientId from '@core/messenger/common/utils/getActiveRecipientId';
import getPaymentStatus from '@core/payment/common/utils/getPaymentStatus';
import type {GlobalFreeMessagesQuery} from '@core/messenger/common/graphql/queries/globalFreeMessages';
import GLOBAL_FREE_MESSAGES_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessages.gql';

import updateFreeMessageMotivationBanner from './updateFreeMessageMotivationBanner';
import updateGlobalFreeMessagesEndedRecipient from '../../common/utils/updateGlobalFreeMessagesEndedRecipient';

export const WARNING = '[updateGlobalFreeMessagesCount] read data failed';

type UpdateCacheDataParams = {
  client: Client;
  data: GlobalFreeMessagesQuery;
  count: number;
  time: number;
  hideMotivationBanner?: boolean;
};

const updateCacheData = ({
  client,
  data,
  count,
  time,
  hideMotivationBanner = false,
}: UpdateCacheDataParams) => {
  client.writeQuery<GlobalFreeMessagesQuery>({
    query: GLOBAL_FREE_MESSAGES_QUERY,
    data: {
      messenger: {
        ...data.messenger,
        initialData: {
          ...data.messenger.initialData,
          globalFreeMessages: {
            ...data.messenger.initialData.globalFreeMessages,
            count,
            time,
          },
          ...(hideMotivationBanner && {
            freeMessagesBanner: {
              ...data.messenger.initialData.freeMessagesBanner,
              isAvailable: false,
            },
          }),
        },
      },
    },
  });
};

/**
 * Update global free messages count
 */
const updateGlobalFreeMessagesCount = async (
  count: number,
  time: number,
  hideMotivationBanner: boolean = false,
) => {
  const client = getClientInstance();
  const isPaid = await getPaymentStatus();

  const data = client.readQuery<GlobalFreeMessagesQuery>({
    query: GLOBAL_FREE_MESSAGES_QUERY,
  });

  if (!data) {
    logger.sendWarning(WARNING);

    return;
  }

  if (data.messenger.initialData.globalFreeMessages.time < time) {
    const params = {
      client,
      data,
      count,
      time,
      hideMotivationBanner:
        data.messenger.initialData.freeMessagesBanner.isAvailable &&
        hideMotivationBanner,
    };

    if (count === 0 && !isPaid) {
      getActiveRecipientId().then((activeRecipientId) => {
        updateGlobalFreeMessagesEndedRecipient({
          userId: activeRecipientId,
        });

        /**
         * No need to show free messages motivation banner, when user already got them
         * Condition count === 0 is used to avoid multiple updates
         * After payment freeMessageMotivationBanner field will be re-fetched from API
         */
        updateFreeMessageMotivationBanner({
          recipientId: activeRecipientId,
          freeMessageMotivationBanner: false,
        });
        updateCacheData(params);
      });
    } else {
      updateCacheData(params);
    }
  }
};

export default updateGlobalFreeMessagesCount;
