import {getClientInstance} from '@core/graphql/client';
import PHOTO_FRAGMENT from '@core/user/photo/graphql/fragments/photo.gql';
import type {PhotoFragment} from '@core/user/photo/graphql/fragments/photo';

/**
 * Update photo cache after send request message
 */
const updatePhotoPrivateAttributes = (photoId: string): void => {
  const client = getClientInstance();

  const fragment = {
    id: `PhotoType:${photoId}`,
    fragment: PHOTO_FRAGMENT,
  };

  const photo = client.readFragment<PhotoFragment>(fragment);

  if (photo) {
    client.writeFragment<PhotoFragment>({
      ...fragment,
      data: {
        ...photo,
        privateAttributes: {
          ...photo.privateAttributes,
          isRequested: true,
        },
      },
    });
  }
};

export default updatePhotoPrivateAttributes;
