import React from 'react';
import PropTypes from 'prop-types';
import {Field, getIn} from 'formik';

import InputWithMaxLength from '@phoenix/widgets/input/InputWithMaxLength';

const FormInputWithMaxLength = ({validate, name, error, ...props}) => (
  <Field name={name} validate={validate}>
    {({field, form}) => {
      const formError = getIn(form.errors, name);
      const touched = getIn(form.touched, name);
      return (
        <InputWithMaxLength
          {...field}
          {...props}
          error={error || (touched && formError)}
        />
      );
    }}
  </Field>
);

FormInputWithMaxLength.propTypes /* remove-proptypes */ = {
  validate: PropTypes.func,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default FormInputWithMaxLength;
