import type {ReactEventHandler} from 'react';
import React, {forwardRef} from 'react';
import cn from 'classnames';

import PhotoLevel from '@core/user/photo/constants/PhotoLevel';

import {FittingImage, BlurredImage} from '@phoenix/ui';
import {ImageScaling} from '@phoenix/ui/constants';

import css from '../styles/BlurredPhoto.css';

export type BlurredPhotoProps = {
  className?: string;
  url: string;
  fullHeight?: boolean;
  showPhoto?: boolean;
  showLoadingBackground?: boolean;
  scaling?: ImageScaling;
  level?: PhotoLevel;
  onLoad?: ReactEventHandler<HTMLImageElement>;
};

/**
 * Presentational components for image, that, based on level
 * can be displayed with blur, or without it.
 */
const BlurredPhoto = forwardRef<HTMLDivElement, BlurredPhotoProps>(
  (
    {
      className,
      scaling = ImageScaling.COVER,
      url,
      level = PhotoLevel.NORMAL,
      showPhoto = true,
      showLoadingBackground = true,
      fullHeight,
      onLoad,
    },
    ref,
  ) => {
    let image = null;

    if (showPhoto) {
      image =
        level === PhotoLevel.NORMAL ? (
          <FittingImage
            url={url}
            className={css.image}
            onLoad={onLoad}
            scaling={scaling}
          />
        ) : (
          <BlurredImage
            onLoad={onLoad}
            url={url}
            level={level}
            className={css.image}
            scaling={scaling}
          />
        );
    }

    return (
      /**
       * Need for correct rendering in similar users and showing preloaders without page jumping
       */
      <div
        ref={ref}
        className={cn(
          css.container,
          showPhoto && level !== PhotoLevel.NORMAL && css.willChange,
          showLoadingBackground && css.loading,
          className,
          fullHeight && css.fullHeight,
        )}
      >
        {image}
      </div>
    );
  },
);

export default BlurredPhoto;
