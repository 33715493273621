import {getClientInstance} from '@core/graphql/client';

import PERSONAL_IDENTITY_ON_DEMAND_QUERY from '../graphql/queries/personalIdentityByDemand.gql';

/**
 * @params {String} documentStatus
 */
const updatePersonalIdentityDocumentStatus = async (documentStatus) => {
  const client = getClientInstance();

  const {data} = await client.query({
    query: PERSONAL_IDENTITY_ON_DEMAND_QUERY,
  });

  client.writeQuery({
    query: PERSONAL_IDENTITY_ON_DEMAND_QUERY,
    data: {
      ...data,
      userFeatures: {
        ...data.userFeatures,
        personalIdentity: {
          ...data.userFeatures.personalIdentity,
          documentStatus,
        },
      },
    },
  });
};

export default updatePersonalIdentityDocumentStatus;
