import {getClientInstance} from '@core/graphql/client';
import logger from '@core/logger';

import CREDITS_BALANCE_QUERY from '../graphql/queries/creditsBalance.gql';

/**
 * Update credit balance
 * @param {{time: Number, balance: Number, forceUpdate: Boolean}}
 */
const updateCreditsBalance = async ({time, balance, forceUpdate}) => {
  const client = getClientInstance();
  let data = client.readQuery({query: CREDITS_BALANCE_QUERY});
  if (!data) {
    const {data: dataFromServer} = await client.query({
      query: CREDITS_BALANCE_QUERY,
    });
    data = dataFromServer;
  }

  /**
   * Event of update credits balance will be could do a lot (e.g after messages)
   * to we need to use the update time for prevent this
   * @type {number|boolean}
   */
  const needUpdate = data.credits.time < time;

  if (needUpdate || forceUpdate) {
    client.writeQuery({
      query: CREDITS_BALANCE_QUERY,
      data: {
        credits: {
          ...data.credits,
          balance,
          time,
        },
      },
    });
  }
};

/**
 * Update credit balance on client
 * @param {number} messageCost
 * @return {Object}
 * @return {Function} - Object.revertUpdateCreditBalance - if have error can revert balance change on client side
 */
export const updateClientCreditsBalanceByAmount = (messageCost) => {
  if (messageCost) {
    const data = getClientInstance().readQuery({
      query: CREDITS_BALANCE_QUERY,
    });

    if (data) {
      const {credits} = data;

      const balance = credits.balance - messageCost;

      if (balance >= 0) {
        updateCreditsBalance({
          balance,
          time: credits.time,
          forceUpdate: true,
        });
      }

      return {
        revertUpdateCreditBalance: () =>
          updateCreditsBalance({
            time: credits.time,
            balance: credits.balance,
            forceUpdate: true,
          }),
      };
    }

    logger.sendWarning(
      '[updateClientCreditsBalanceByAmount] CreditsBalanceQuery read failed',
    );
  }

  return {
    revertUpdateCreditBalance: () => {},
  };
};

export default updateCreditsBalance;
