import React from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import {PlaceholderCircle} from '@phoenix/ui';

import css from '../styles/HeaderWithImages.css';

const HeaderWithImagesPlaceholder = ({count}) =>
  Boolean(count) && (
    <div className={css.avatars}>
      {range(count).map((index) => (
        <PlaceholderCircle key={`photo${index}`} className={css.photo} />
      ))}
    </div>
  );

HeaderWithImagesPlaceholder.propTypes /* remove-proptypes */ = {
  count: PropTypes.number.isRequired,
};

export default HeaderWithImagesPlaceholder;
