import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

import canOpenMiniMessenger from '@core/messenger/common/utils/canOpenMiniMessenger';

/**
 * Determines if Mini Messenger is allowed to be opened based on the current route and messenger settings.
 *
 * @returns {boolean} - True if Mini Messenger is allowed, false otherwise.
 */
const useIsMiniMessengerAllowed = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const {pathname} = useLocation();

  useEffect(() => {
    if (!isAllowed) {
      canOpenMiniMessenger(pathname).then((canOpen) => {
        setIsAllowed(canOpen);
      });
    }
  }, [pathname, isAllowed]);

  return isAllowed;
};

export default useIsMiniMessengerAllowed;
