import SearchMotivationWidgetAppearance from '@core/theming/constants/features/SearchMotivationWidgetAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change this type with MotivationWidgets after migration all chunks on TS FE-42413
 */
export type MotivationWidgetsSTUB = any;

const resolver: ModuleResolver<
  SearchMotivationWidgetAppearance,
  MotivationWidgetsSTUB
> = (value) => {
  switch (value) {
    case SearchMotivationWidgetAppearance.ALL_IN_CARD:
      return import(
        /* webpackChunkName: 'searchMotivationCard' */ '@phoenix/search/widget/constants/motivationWidgetAllInCardComponents'
      );
    case SearchMotivationWidgetAppearance.SATURATED:
      return import(
        /* webpackChunkName: 'searchMotivationSaturated' */ '@phoenix/search/widget/constants/motivationWidgetSaturatedComponents'
      );
    case SearchMotivationWidgetAppearance.DANGEROUSLY_SATURATED_MINIMIZED:
      return import(
        /* webpackChunkName: 'searchMotivationDangerouslySaturated' */ '@phoenix/search/widget/constants/motivationWidgetDangerouslySaturatedMinimizedComponents'
      );
    case SearchMotivationWidgetAppearance.INLINE:
      return import(
        /* webpackChunkName: 'searchMotivationInline' */ '@phoenix/search/widget/constants/motivationWidgetInlineComponents'
      );
    case SearchMotivationWidgetAppearance.ALL_IN_COLORED_LIGHT_CARD:
      return import(
        /* webpackChunkName: 'searchMotivationAllInColoredLightCard' */ '@phoenix/search/widget/constants/motivationWidgetAllInColoredLightCardComponents'
      );
    case SearchMotivationWidgetAppearance.FLAT:
      return import(
        /* webpackChunkName: 'searchMotivationFlat' */ '@phoenix/search/widget/constants/motivationWidgetFlatComponents'
      );
    case SearchMotivationWidgetAppearance.UNBORDERED_ANIMATED:
      return import(
        /* webpackChunkName: 'searchMotivationUnborderedAnimated' */ '@phoenix/search/widget/constants/motivationWidgetUnborderedAnimatedComponents'
      );
    case SearchMotivationWidgetAppearance.FULLSCREEN:
      return import(
        /* webpackChunkName: 'searchMotivationFullscreen' */ '@phoenix/search/widget/constants/motivationWidgetFullscreenComponents'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable search motivation widget based on theme.
 * Returns object of composite components that are used for theming.
 */
const useThemedSearchMotivationWidget = () =>
  useThemeCompositeFeatureLoader<
    SearchMotivationWidgetAppearance,
    MotivationWidgetsSTUB
  >('search', 'motivationWidget', resolver);

export default useThemedSearchMotivationWidget;
