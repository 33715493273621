import activePackageVar from '@core/graphql/vars/activePackageVar';
import getPriceWithCurrency from '@core/payment/common/utils/getPriceWithCurrency';

/**
 * @returns {string}
 */
const getActivePackageFullPriceWithCurrency = () => {
  const activePackage = activePackageVar();

  if (!activePackage) {
    return '';
  }

  const {fullPrice} =
    activePackage.packagePricingDetails.packageDiscountPriceData ||
    activePackage.packagePricingDetails.packagePriceData;

  const {
    currency: {prefix, suffix},
  } = fullPrice;

  const {amount} = fullPrice.formatted;

  return getPriceWithCurrency(amount, {prefix, suffix});
};

export default getActivePackageFullPriceWithCurrency;
