import {useEffect} from 'react';
import {useApolloClient} from '@apollo/client';
import {BehaviorSubject, combineLatest, fromEvent} from 'rxjs';
import {filter, share} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';

import canShowPopup from '@phoenix/popup/utils/canShowPopup';

import POPUP_ACTIONS from '../popupOnBootstrapActions';

/**
 * Uses shared initial source and creates subscription for each popup logic.
 * @param {Object} client
 */
const startPopupSubscriptions = async (client) => {
  const popupState$ = new BehaviorSubject({detail: {hasQueue: false}});
  // subscribe immediately to don't miss popup events
  fromEvent(document, POPUP_STATE_CHANGE_EVENT).subscribe((event) =>
    popupState$.next(event),
  );

  const routeState$ = client.watchQuery({query: ROUTE_QUERY});

  const source$ = combineLatest([routeState$, popupState$]).pipe(
    // Check if is possible to show something (it's not denied route, or there is no opened popups)
    filter(
      ([
        {
          data: {route},
        },
        {detail},
      ]) => canShowPopup(route, detail),
    ),
    share(),
  );

  POPUP_ACTIONS.forEach((action) => {
    const {operations: actionOperations, subscription: actionSubscription} =
      action(client);

    source$.pipe(actionOperations()).subscribe(actionSubscription);
  });
};

/**
 * Special component, dedicated for displaying different popups on
 * application bootstrap. Be aware that such popups can't have any physical route
 * like some other group have (@see RouteWithPopupDisplayer.js)
 */
const PopupOnBootstrapDisplayer = () => {
  const client = useApolloClient();

  useEffect(() => {
    startPopupSubscriptions(client);
  }, [client]);

  return null;
};

export default PopupOnBootstrapDisplayer;
