import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import ExtraDiscountWithProgressPopup from '../containers/ExtraDiscountWithProgressPopup';

const openExtraDiscountWithProgressPopup = ({popupConfig, ...props}) =>
  PopupService.openPopup(<ExtraDiscountWithProgressPopup {...props} />, {
    ...popupConfig,
    trackingName: 'extraDiscountWithProgress',
    autoLoadTracking: true,
  });

export default openExtraDiscountWithProgressPopup;
