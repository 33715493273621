import {PHOTO_RESTRICTIONS} from '@core/mediaUpload/constants/sources';

import openWaitForApprovePopup from '@phoenix/mediaUpload/utils/openWaitForApprovePopup';
import openPhotoUploadPopup from '@phoenix/mediaUpload/utils/openPhotoUploadPopup';

/**
 * Use to open popup related with photo restriction logic.
 */
class PhotoRestrictionHelper {
  static openNoPhotoPopup = (options = {}) =>
    openPhotoUploadPopup({
      source: PHOTO_RESTRICTIONS,
      ...options,
    });

  static openWaitForApprovePopup = () => {
    openWaitForApprovePopup();
  };
}

export default PhotoRestrictionHelper;
