import {
  NO_ENOUGH_COINS,
  DEBT_CREDITS_USING,
  NO_PHOTO,
} from '@core/messenger/common/constants/reasons';
import NotificationsService from '@core/systemNotifications/utils/NotificationsService';
import MessageType from '@core/messenger/common/constants/messageType';

import {SEND_GIFT_MESSENGER} from '@phoenix/coins/buyCoins/constants/vias';
import goToCoinsPP from '@phoenix/coins/utils/goToCoinsPP';
import getViaForUnsentMessageByType from '@phoenix/messenger/common/utils/getViaForUnsentMessageByType';
import sendMessage from '@phoenix/messenger/senderForm/utils/sendMessage';

import updateGiftFreeAmount from './updateGiftFreeAmount';

/**
 * Send a selected gift
 * @param {string} returnPath
 * @param {string} placementVia - used for sending to PP with specific placement via
 * @param {string} userId
 * @param {string} giftId
 * @param {function} onSendSuccess - call with parameter canClosePopup (can be false, e.g. after coins buying, to show success popup after payment)
 * @param {function} onSendFail
 *
 * @returns {function(*): void}
 */
const sendGift = async ({
  returnPath,
  placementVia = null,
  recipientId,
  giftId,
  onSendSuccess = () => {},
  onSendFail = () => {},
}) => {
  const responseData = await sendMessage({
    recipientId,
    messageType: MessageType.GIFT,
    message: giftId,
  });

  /**
   * For photo restriction errors
   * @see photoRestrictionReasons  core/websocket/resolvers/messenger.js
   */
  if (!responseData) {
    onSendFail();
    return;
  }
  const {data: {error} = {}} = responseData;

  if (error) {
    onSendFail(error);
    /**
     * In some cases we get real error that we must upload Photo
     */
    if (error === NO_PHOTO) {
      return;
    }
    let via = '';

    if (error === NO_ENOUGH_COINS) {
      via = SEND_GIFT_MESSENGER;
    }

    if (error === DEBT_CREDITS_USING) {
      via = getViaForUnsentMessageByType(MessageType.GIFT);
    }

    if (placementVia) {
      via = placementVia;
    }

    Boolean(via) && goToCoinsPP(via, returnPath);
  } else {
    onSendSuccess();

    NotificationsService.addNotification({
      type: NotificationsService.TYPES.SEND_GIFT,
    });

    updateGiftFreeAmount(giftId, -1);
  }
};

export default sendGift;
