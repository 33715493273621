import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import getPeriodTranslation from '@core/payment/widgets/package/utils/getPeriodTranslation';
import {packagePricePropType} from '@core/payment/widgets/package/constants/propTypes';

import {Tag} from '@phoenix/ui';
import {Text, TextMuted} from '@phoenix/typography';
import {TagType} from '@phoenix/ui/constants';

import PaymentPackageRowPrice from './PaymentPackageRowPrice';
import css from '../styles/PaymentPackagePrice.css';
import useIsLayeredPage from '../../../utils/useIsLayeredPage';

const PaymentPackagePrice = ({
  price: {pricePerPart},
  periodKey,
  highlighted,
  withArrow,
  withPeriod,
  withCenteredLoPrice,
  withDiscountLabel,
  percentDiscount,
  roundArrow,
}) => {
  const inverse = !withArrow && highlighted;
  const {
    currency,
    formatted: {hi, lo, shortPrice},
  } = pricePerPart;
  const {isLayered} = useIsLayeredPage();

  return (
    <div
      className={cn(
        css.price,
        !isLayered && css.withSpacing,
        highlighted && css.highlighted,
        roundArrow && css.rounded,
        !highlighted && !roundArrow && !withArrow && css.transparent,
      )}
    >
      {withArrow && !roundArrow && <div className={css.arrow} />}
      <div className={css.inner}>
        {currency.prefix && (
          <Text className={css.currency} inverse={inverse}>
            {currency.prefix}
          </Text>
        )}
        {withPeriod || withCenteredLoPrice ? (
          <Fragment>
            <Text className={css.hi} inverse={inverse}>
              {shortPrice || hi}
            </Text>
            <div
              className={withCenteredLoPrice ? css.suffixCentered : css.suffix}
            >
              <Text className={css.lo} inverse={inverse}>
                {!shortPrice && lo}
                {!withCenteredLoPrice && currency.suffix}
              </Text>
              {!withCenteredLoPrice && (
                <TextMuted className={css.period} small inverse={inverse}>
                  {getPeriodTranslation(periodKey)}
                </TextMuted>
              )}
            </div>
          </Fragment>
        ) : (
          <Text className={css.rowPrice} inverse={inverse}>
            <PaymentPackageRowPrice price={pricePerPart} withPrefix={false} />
          </Text>
        )}
      </div>
      {withDiscountLabel && Boolean(percentDiscount) && (
        <Tag type={TagType.PRIMARY} className={css.discount}>
          {`-${percentDiscount}%`}
        </Tag>
      )}
    </div>
  );
};

PaymentPackagePrice.propTypes /* remove-proptypes */ = {
  price: packagePricePropType.isRequired,
  periodKey: PropTypes.string,
  percentDiscount: PropTypes.number,
  withDiscountLabel: PropTypes.bool,
  highlighted: PropTypes.bool,
  withArrow: PropTypes.bool,
  withPeriod: PropTypes.bool,
  withCenteredLoPrice: PropTypes.bool,
  roundArrow: PropTypes.bool,
};

export default PaymentPackagePrice;
