import {lazy} from 'react';

import logger from '@core/logger';

const CoregistrationPopupYoungs1 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs1' */ '../components/CoregistrationPopupYoungs1'
    ),
);

const CoregistrationPopupYoungs2 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs2' */ '../components/CoregistrationPopupYoungs2'
    ),
);

const CoregistrationPopupYoungs3 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs3' */ '../components/CoregistrationPopupYoungs3'
    ),
);

const CoregistrationPopupYoungs4 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs4' */ '../components/CoregistrationPopupYoungs4'
    ),
);

const CoregistrationPopupYoungs5 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs5' */ '../components/CoregistrationPopupYoungs5'
    ),
);

const CoregistrationPopupYoungs6 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs6' */ '../components/CoregistrationPopupYoungs6'
    ),
);

const CoregistrationPopupYoungs7 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs7' */ '../components/CoregistrationPopupYoungs7'
    ),
);

const CoregistrationPopupYoungs8 = lazy(
  () =>
    import(
      /* webpackChunkName: 'CoregistrationPopupYoungs8' */ '../components/CoregistrationPopupYoungs8'
    ),
);

const CoregistrationPopupSlider1 = lazy(
  () =>
    import(
      /* webpackChunkName: 'coregistrationPopupSlider1' */ '../components/CoregistrationPopupSlider1'
    ),
);

const CoregistrationPopupSlider2 = lazy(
  () =>
    import(
      /* webpackChunkName: 'coregistrationPopupSlider2' */ '../components/CoregistrationPopupSlider2'
    ),
);

const CoregistrationPopupSlider3 = lazy(
  () =>
    import(
      /* webpackChunkName: 'coregistrationPopupSlider3' */ '../components/CoregistrationPopupSlider3'
    ),
);

const CoregistrationPopupSlider4 = lazy(
  () =>
    import(
      /* webpackChunkName: 'coregistrationPopupSlider4' */ '../components/CoregistrationPopupSlider4'
    ),
);

const CoregistrationPopupImages = lazy(
  () =>
    import(
      /* webpackChunkName: 'coregistrationPopupImages' */ '../components/CoregistrationPopupImages'
    ),
);

const CoregistrationPopupList = {
  default: CoregistrationPopupImages,
  slider1: CoregistrationPopupSlider1,
  slider2: CoregistrationPopupSlider2,
  slider3: CoregistrationPopupSlider3,
  slider4: CoregistrationPopupSlider4,
  rm_youngs_1: CoregistrationPopupYoungs1,
  rm_youngs_2: CoregistrationPopupYoungs2,
  rm_youngs_3: CoregistrationPopupYoungs3,
  rm_youngs_4: CoregistrationPopupYoungs4,
  rm_youngs_5: CoregistrationPopupYoungs5,
  rm_youngs_6: CoregistrationPopupYoungs6,
  rm_youngs_7: CoregistrationPopupYoungs7,
  rm_youngs_8: CoregistrationPopupYoungs8,
};

/**
 * @param {string} conceptType
 */
const getCoregistrationPopupConceptLayout = (conceptType) => {
  const popupContent = CoregistrationPopupList[conceptType];

  if (!popupContent) {
    logger.sendError(
      `[getCoregistrationPopupConceptLayout] No popup content layout for "${conceptType}".`,
    );

    return CoregistrationPopupImages;
  }

  return popupContent;
};

export default getCoregistrationPopupConceptLayout;
