import React, {Fragment} from 'react';

import t from '@core/translations/translate';

import {Spacing} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

const PersonalIdentityByDemandWaitForApprovePopup = () => {
  return (
    <PopupNotice
      icon="check"
      title={t('personalIdentityByDemand', 'title.waiting')}
      description={
        <Fragment>
          <Spacing onlyBottom>
            <TextMuted>
              {t('personalIdentityByDemand', 'test.waitingDescription1')}
            </TextMuted>
          </Spacing>
          <TextMuted>
            {t('personalIdentityByDemand', 'test.waitingDescription2')}
          </TextMuted>
        </Fragment>
      }
    />
  );
};

export default PersonalIdentityByDemandWaitForApprovePopup;
