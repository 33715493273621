import {useEffect, useRef, useCallback} from 'react';
import type {InViewHookResponse} from 'react-intersection-observer';
import {useInView} from 'react-intersection-observer';
import {useLocation} from 'react-router-dom';

import type {Placement} from '@core/types';

import EventName from '../constants/EventName';
import {WEB_CAM_PROMO} from '../constants/anchorNames';
import InventoryTrackingService from './InventoryTrackingService';
import isAllowedByRoute from './isAllowedByRoute';
import type {InventoryData} from './prepareInventoryDataBeforeSend';

type UseInventoryTrackingParams = {
  attributes?: Placement['trackingAttributes'];
  partner: Placement['partner'];
  placement: Placement['trackingName'];
  promocode: Placement['promoCode'];
  onClick?: () => void;
};

type UseInventoryTrackingResult = {
  // ref - set this ref to DOM node to track view
  ref: InViewHookResponse['ref'];
  onClick: () => void;
};

/**
 * Two trigger points helps to implement next logic:
 * Track view when element becomes at least 70% visible.
 * Do not track again if visibility then changes in range between 10% and 100%.
 * Track again if visibility changes from <10% to >70%.
 */
const threshold = [0.1, 0.7];

/**
 * Hook to use inventory tracking.
 * Set 'ref' to the DOM node, 'view' will be tracked automatically.
 * Call 'onClick' to track 'click'.
 */
export default function useInventoryTracking({
  partner,
  placement,
  attributes,
  promocode,
  onClick,
}: UseInventoryTrackingParams): UseInventoryTrackingResult {
  const [ref, , entry] = useInView({threshold});
  const inView = Boolean(entry && entry.intersectionRatio > 0.5);
  const {pathname} = useLocation();
  const isAllowed = isAllowedByRoute(pathname);

  // useRef to simplify dependencies
  const trackingData = useRef<InventoryData>(null);
  trackingData.current = {
    placement,
    anchorName: WEB_CAM_PROMO,
    promocode,
    promo: partner,
    /**
     * Attributes is an array of objects of pairs name-value that should be converted
     * to a plain object with key-value.
     */
    ...(attributes
      ? attributes.reduce((accumulator, current) => {
          accumulator[current.name] = current.value;
          return accumulator;
        }, {})
      : {}),
  };

  /**
   * In case when we need change placement tracking data dynamically
   */
  const dataSnapshot = `${partner || ''}${placement || ''}${promocode || ''}`;

  useEffect(() => {
    if (isAllowed && inView && dataSnapshot) {
      InventoryTrackingService.track({
        ...trackingData.current,
        event: EventName.VIEW,
      });
    }
  }, [isAllowed, inView, dataSnapshot]);

  const handleClick = useCallback(() => {
    InventoryTrackingService.track({
      ...trackingData.current,
      event: EventName.CLICK,
    });

    onClick?.();
  }, [onClick]);

  return {ref, onClick: handleClick};
}
