import React from 'react';
import PropTypes from 'prop-types';

import REASONS_TRANSLATION from '@core/disapprove/constants/reasonsTranslation';

import REASONS_IMAGES from '../constants/reasonsImages';
import DisapproveReasonsLayout from './DisapproveReasonsLayout';
import MediaDisapproveReason from './MediaDisapproveReason';

const BaseMediaDisapproveReasons = ({reasons, ...props}) => (
  <DisapproveReasonsLayout {...props}>
    {reasons.map(({value: reason}) => (
      <MediaDisapproveReason key={reason} imageUrl={REASONS_IMAGES[reason]}>
        {REASONS_TRANSLATION[reason]}
      </MediaDisapproveReason>
    ))}
  </DisapproveReasonsLayout>
);

BaseMediaDisapproveReasons.propTypes /* remove-proptypes */ = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BaseMediaDisapproveReasons;
