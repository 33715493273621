import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import {Loader} from '@phoenix/ui';

import css from '../styles/BannerLoader.css';

const BannerLoader = ({
  height,
  loading,
  inline,
  fullsize,
  wrapper: Wrap,
  children,
}) => {
  const props = {
    className: cn(inline && css.inlineBanner, fullsize && css.fullsize),
    children,
  };

  if (height) {
    props.style = {minHeight: height};

    if (Wrap && loading) {
      props.children = (
        <Wrap>
          <Loader active small>
            <div style={{height}} />
          </Loader>
        </Wrap>
      );
    }
  } else if (!children) {
    return null;
  }

  return <div {...props} />;
};

BannerLoader.propTypes /* remove-proptypes */ = {
  loading: PropTypes.bool,
  height: PropTypes.string,
  inline: PropTypes.bool,
  fullsize: PropTypes.bool,
  wrapper: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node,
};

export default BannerLoader;
