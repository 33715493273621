import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyForbidden = lazy(
  () =>
    import(
      /* webpackChunkName: 'forbidden' */ '@phoenix/exceptions/components/Forbidden'
    ),
);

/**
 * Unfortunately, but react.lazy doesn't support ES6 not 'default' exports
 * so we need to address directly on module.
 *
 * @see https://reactjs.org/docs/code-splitting.html#named-exports
 */
export default (props) => <SplitChunk component={LazyForbidden} {...props} />;
