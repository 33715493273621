import {sessionStorage} from '@core/utils/storage';
import resetPaymentPackagesCache from '@core/payment/common/utils/resetPaymentPackagesCache';

import {SHOW_COINS_BUY_OFFER_BANNER_KEY} from '../constants/storageKeys';
import showCoinsBuyOfferBannerVar from '../graphql/vars/showCoinsBuyOfferBannerVar';

/**
 * Update showCoinsBuyOfferBannerVar
 * @param opened
 */
const updateShowCoinsBuyOfferBanner = (opened) => {
  const showCoinsBuyOfferBannerFromCache = sessionStorage.getItem(
    SHOW_COINS_BUY_OFFER_BANNER_KEY,
  );

  if (showCoinsBuyOfferBannerFromCache !== opened) {
    sessionStorage.setItem(SHOW_COINS_BUY_OFFER_BANNER_KEY, opened);
    showCoinsBuyOfferBannerVar(opened);
    if (!showCoinsBuyOfferBannerFromCache) {
      resetPaymentPackagesCache(false);
    }
  }
};

export default updateShowCoinsBuyOfferBanner;
