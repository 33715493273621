import React from 'react';

import WebcamPhotoCaptureDisplayer from '@core/mediaUpload/containers/WebcamPhotoCaptureDisplayer';

import WebcamPhotoCaptureLayout from './WebcamPhotoCaptureLayout';

const WebcamPhotoCapture = (props) => (
  <WebcamPhotoCaptureDisplayer
    webcamPhotoCaptureLayoutComponent={WebcamPhotoCaptureLayout}
    {...props}
  />
);

export default WebcamPhotoCapture;
