import React from 'react';

import t from '@core/translations/translate';

import {
  Align,
  SpacingSize,
  PopperPlacement,
  PopperTrigger,
} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';
import {Popover, Spacing, Row, Icon} from '@phoenix/ui';

import css from '../styles/AgeVerificationUploadInfo.css';

/**
 * ATTENTION !!!!!
 * This component uses in different places. If you change it, be sure that these changes are OK for both logics
 * @see /personalIdentityByDemand/utils/openDocumentUploadPopup.js
 * @see /personalIdentity/utils/openDocumentUploadPopup.js
 * @returns {JSX.Element}
 * @constructor
 */
const AgeVerificationUploadInfo = () => (
  <Spacing>
    <Row
      className={css.container}
      horizontal
      space={SpacingSize.NORMAL}
      align={Align.BETWEEN}
    >
      <TextMuted small>
        {t('personalIdentity', 'text.ensure_readable')}
      </TextMuted>
      <div>
        <Popover
          className={css.popover}
          fullWidth
          usePortal={false}
          showArrow={false}
          spaced={false}
          placement={PopperPlacement.TOP_END}
          trackingName="help"
          trigger={PopperTrigger.HOVER_CLICK}
          content={
            <Spacing>
              <div>{t('personalIdentity', 'text.photo_visible')}</div>
              <div>{t('personalIdentity', 'text.document_types')}</div>
            </Spacing>
          }
        >
          <div className={css.question} data-test="ageVerificationHelp">
            <Icon type="help" muted />
          </div>
        </Popover>
      </div>
    </Row>
  </Spacing>
);

export default AgeVerificationUploadInfo;
