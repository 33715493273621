import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import PopupService from '@core/popup/utils/PopupService';

import {ButtonPrimary, Row} from '@phoenix/ui';
import BoughtPackages from '@phoenix/payment/widgets/boughtPackages/components/BoughtPackages';
import {TextMuted} from '@phoenix/typography';
import ConfettiAnimation from '@phoenix/widgets/animation/ConfettiAnimation';
import {SpacingSize} from '@phoenix/ui/constants';
import COIN_AMOUNT from '@phoenix/credits/constants/coinAmount';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import CoinColorizedIconByAmount from '@phoenix/credits/components/CoinColorizedIconByAmount';
import COIN_ICON_SIZES from '@phoenix/credits/constants/coinColorizedIconSizes';

import css from '../styles/CoinsCommonSuccessPopup.css';

/**
 * Popup with message about success coins receive, with animation
 *
 * @todo
 * We should discard of using subfolder 'buyCoinsPopup' since we have already
 * big part of components there.
 */

/**
 * Use separate function to prevent close all popups
 */
const closePopup = () => PopupService.closePopup();

const CoinsCommonSuccessPopup = ({
  icon,
  iconSpacing = SpacingSize.SHORT,
  heading,
  title,
  description,
  action,
  onButtonClick,
  packages,
}) => (
  <div className={css.container}>
    <PopupNotice
      icon={
        icon || (
          <CoinColorizedIconByAmount
            amount={COIN_AMOUNT.MANY}
            size={COIN_ICON_SIZES.BIG}
            inline
          />
        )
      }
      iconSpacing={iconSpacing}
      heading={heading}
      title={title}
      description={
        <Fragment>
          <TextMuted>{description}</TextMuted>
          {Boolean(packages?.length) && (
            <Row>
              <BoughtPackages
                packages={packages}
                title={t('coins', 'title.you_just_scored')}
              />
            </Row>
          )}
        </Fragment>
      }
      actions={[
        <ButtonPrimary
          adaptive
          fullWidth
          // eslint-disable-next-line react/jsx-handler-names
          onClick={onButtonClick || closePopup}
          trackingName="closePopup"
        >
          {action}
        </ButtonPrimary>,
      ]}
    />
    <ConfettiAnimation waitPopupAnimation />
  </div>
);

CoinsCommonSuccessPopup.propTypes /* remove-proptypes */ = {
  icon: PropTypes.node,
  iconSpacing: PropTypes.oneOf(Object.values(SpacingSize)),
  heading: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  packages: PropTypes.arrayOf(PropTypes.string),
};

export default CoinsCommonSuccessPopup;
