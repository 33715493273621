import type {FC} from 'react';

import type RecipientsAppearance from '@core/theming/constants/features/RecipientsAppearance';

import type {ModuleComponent} from '../types';
import useThemeCompositeFeatureLoader from '../utils/useThemeCompositeFeatureLoader';
import type {ThemedMessengerPageProps} from './ThemedMessengerPage';
import {resolver as messengerPageResolver} from './ThemedMessengerPage';
import useThemedRecipientWidget from '../utils/useThemedRecipientWidget';
import useThemedChatMessage from '../utils/useThemedChatMessage';

/**
 * Component for preload themed components for web
 */
const PreloadThemedComponentsPage: FC = () => {
  useThemeCompositeFeatureLoader<
    RecipientsAppearance,
    ModuleComponent<ThemedMessengerPageProps>
  >('messenger', 'recipients', messengerPageResolver);

  useThemedRecipientWidget();
  useThemedChatMessage();

  return null;
};

export default PreloadThemedComponentsPage;
