import {getOperationName} from '@apollo/client/utilities';

import MESSENGER_QUERY from '@core/messenger/common/graphql/queries/messenger.gql';

import APP_DATA_QUERY from '@phoenix/application/graphql/queries/appData.gql';

import allowedDelayedRenderVar from '../vars/allowedDelayedRenderVar';

/**
 * This constants are taken from the live env (approximate time)
 * Request time of appData
 * @type {{SLOW: number, FAST: number, MIDDLE: number}}
 */
const FIRST_REQUEST_TIME = {
  FAST: 900,
  MIDDLE: 1500,
  SLOW: 2500,
};

/**
 * This constants are taken from the live env (approximate time)
 * Time of the longest request
 * @type {{FASTEST: number, SLOWEST: number, SLOW: number, FAST: number}}
 */
const LONGEST_REQUEST_TIME = {
  FASTEST: 2000,
  FAST: 3000,
  SLOW: 4000,
  SLOWEST: 5000,
};

/**
 * First heavy enough request to check connection speed
 * @type {Array}
 */
const checkOperationNameList = [
  getOperationName(APP_DATA_QUERY),
  getOperationName(MESSENGER_QUERY),
];

/**
 * Check connection speed and check delay after heaviest requests
 * use for ios where there is not requestIdleCallback
 */
class DelayedRenderChecker {
  /**
   * Set start time of first request to check request time
   * @public
   * @param {String} operationName
   */
  beforeRequest(operationName) {
    /**
     * AppDataQuery gets data from cache after returning from payment page, and we need to check another
     * important request which hasn't been cached (MessengerQuery)
     */
    if (
      checkOperationNameList.includes(operationName) &&
      !this.checkOperationName
    ) {
      this.checkOperationName = operationName;
      this.requestStartTime = new Date().getTime();
    }
  }

  /**
   * Stop first request time checking, and check delay time
   * @public
   * @param {String} operationName
   */
  afterRequest(operationName) {
    this.endCheckFirstRequest(operationName);

    this.checkDelay();
  }

  /**
   * Check request time by first request
   * @private
   * @param {string} operationName
   */
  endCheckFirstRequest(operationName) {
    if (this.checkOperationName === operationName) {
      this.firstRequestTime = new Date().getTime() - this.requestStartTime;
    }
  }

  /**
   * Get delay request time by first request
   * @private
   * @return {number}
   */
  getDelayTime() {
    if (this.firstRequestTime <= FIRST_REQUEST_TIME.FAST) {
      return LONGEST_REQUEST_TIME.FASTEST;
    }

    if (
      this.firstRequestTime > FIRST_REQUEST_TIME.FAST &&
      this.firstRequestTime <= FIRST_REQUEST_TIME.MIDDLE
    ) {
      return LONGEST_REQUEST_TIME.FAST;
    }

    if (
      this.firstRequestTime > FIRST_REQUEST_TIME.MIDDLE &&
      this.firstRequestTime <= FIRST_REQUEST_TIME.SLOW
    ) {
      return LONGEST_REQUEST_TIME.SLOW;
    }

    return LONGEST_REQUEST_TIME.SLOWEST;
  }

  /**
   * listen delay time, and if there are not other requests will set var to render delayed component
   * @private
   */
  checkDelay() {
    window.clearTimeout(this.timeout);

    if (!this.firstRequestTime || this.idleChecked) {
      return;
    }

    const time = this.getDelayTime();
    this.timeout = window.setTimeout(() => {
      this.idleChecked = true;

      allowedDelayedRenderVar(true);
    }, time);
  }
}

export default DelayedRenderChecker;
