import {combineLatest, from, fromEvent} from 'rxjs';
import {filter, map, startWith, switchMap, take} from 'rxjs/operators';

import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';
import POPUP_STATE_CHANGE_EVENT from '@core/popup/constants/popupStateChangeEvent';
import isInRouteList from '@core/utils/routing/isInRouteList';
import {localStorage} from '@core/utils/storage';
import isPaymentUrl from '@core/utils/url/isPayUrl';

import TRUSTED_VERIFICATION_POPUP_QUERY from '../graphql/queries/trustedVerificationPopup.gql';
import openTrustedVerificationPopup from './openTrustedVerificationPopup';

const DISABLED_TRUSTED_POPUP_ROUTES = ['/trusted', '/staticPage'];

const getStorageKey = (userId) => `PopupTrustedVerificationCheck::${userId}`;

const getIsPopupChecked = (userId) => {
  return localStorage.getItem(getStorageKey(userId), false);
};

const setIsPopupChecked = (userId) => {
  return localStorage.setItem(getStorageKey(userId), true);
};

/**
 * @returns {listener}
 */
const startTrustedVerificationPopupListener = (client, userId) => {
  if (getIsPopupChecked(userId)) {
    return;
  }

  const popupState$ = fromEvent(document, POPUP_STATE_CHANGE_EVENT).pipe(
    startWith({
      detail: {
        hasQueue: false,
      },
    }),
  );

  const routeState$ = client.watchQuery({query: ROUTE_QUERY});

  combineLatest([routeState$, popupState$])
    .pipe(
      filter(
        ([
          {
            data: {route},
          },
          {detail},
        ]) => {
          return (
            isPaymentUrl(route.previous) &&
            !isInRouteList(DISABLED_TRUSTED_POPUP_ROUTES, route.current) &&
            !detail.hasQueue
          );
        },
      ),
      switchMap(() => {
        return from(
          client.query({
            query: TRUSTED_VERIFICATION_POPUP_QUERY,
          }),
        ).pipe(map(({data}) => data.trusted?.sms?.isPopupAvailable));
      }),
      take(1),
    )
    .subscribe((isPopupAvailable) => {
      if (isPopupAvailable) {
        openTrustedVerificationPopup();
      }
      setIsPopupChecked(userId);
    });
};

export default startTrustedVerificationPopupListener;
