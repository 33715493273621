import once from 'lodash/once';
import {combineLatest} from 'rxjs';
import {filter, takeWhile, delay} from 'rxjs/operators';

import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';
import GLOBAL_FREE_MESSAGES_QUERY from '@core/messenger/common/graphql/queries/globalFreeMessages.gql';
import isNeedOpenPaymentPageInNewTab from '@core/payment/common/utils/isNeedOpenPaymentPageInNewTab';

/**
 * Disable re-fetch for paid user
 * @type {boolean}
 */
let disableRefechForPaid = false;

/**
 * This listener needs to listen changing global free messages allowance and account status
 * to re-fetch new tab flag from backand
 * pp in new tab flag has preloaded before listener will be started (@see appData.gql)
 */
const startOpenPPInNewTabListener = once((client) => {
  combineLatest([
    client.watchQuery({query: PAYMENT_ACCOUNT_STATUS_QUERY}),
    client.watchQuery({query: GLOBAL_FREE_MESSAGES_QUERY}),
  ])
    .pipe(
      takeWhile(() => !disableRefechForPaid),
      filter(
        ([
          ,
          {
            data: {
              messenger: {
                initialData: {
                  globalFreeMessages: {available},
                },
              },
            },
          },
        ]) => {
          return available;
        },
      ),
      /**
       * The payment account status can be changed after payment from the popup.
       * In this case page can be reloaded. Need wait some time from prevent not needed re-fetch
       */
      delay(500),
    )
    .subscribe(
      async ([
        {
          data: {
            payment: {
              accountStatus: {isPaid},
            },
          },
        },
      ]) => {
        // Update value from server
        await isNeedOpenPaymentPageInNewTab();
        disableRefechForPaid = isPaid;
      },
    );
});

export default startOpenPPInNewTabListener;
