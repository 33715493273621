import React, {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';
import Timer from '@core/utils/timer';
import useCompactPhoneBreakpoint from '@core/responsive/useCompactPhoneBreakpoint';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {Button, Row, Progress} from '@phoenix/ui';
import {Align, ButtonType} from '@phoenix/ui/constants';
import {H3, Title} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import ConfettiAnimation from '@phoenix/widgets/animation/ConfettiAnimation';

import css from '../styles/ExtraDiscountWithProgressPopupLayout.css';

const ExtraDiscountWithProgressPopupLayout = ({
  url,
  extra,
  duration,
  percentage,
  expires,
  onTimeUpdate,
  onTimeOver,
}) => {
  const isCompactPhone = useCompactPhoneBreakpoint();
  const history = useHistory();
  const isPhone = usePhoneBreakpoint();

  const handleClickOnContent = useCallback(() => {
    history.push(url);
  }, [url, history]);

  const handleClick = useCallback(
    (event) => {
      /**
       * We prevent further propagation of the current event in the capturing and bubbling phases.
       * Because we have got action click on parent element and we will have a double click.
       */
      event.stopPropagation();
      history.push(url);
    },
    [history, url],
  );

  return (
    <PopupFrame
      className={css.popup}
      onClick={isPhone ? handleClickOnContent : null}
      trackingName={isPhone ? 'content' : null}
      data-test="extraDiscountPopupLayout"
      content={
        <Fragment>
          <div
            className={cn(css.progressContainer, expires && css.expires)}
            data-test="extraDiscountPopupLayoutContent"
          >
            <div className={css.progressMirror}>
              <div className={css.progress}>
                <Progress
                  startingPoint={0}
                  percentage={percentage}
                  rotate={false}
                  withOverlay={false}
                  fixedSize={false}
                  withPercentage={false}
                  strokeWidth={1}
                />
              </div>
            </div>
            <div className={css.timer}>
              <Timer
                backward
                initialTime={duration}
                duration={duration}
                onTimeUpdate={onTimeUpdate}
                onTimeOver={onTimeOver}
              />
            </div>
            {!window.IS_INTEGRATION_TEST_ENVIRONMENT && (
              <div className={css.confetti}>
                <ConfettiAnimation waitPopupAnimation />
              </div>
            )}
          </div>
          <Row align={Align.CENTER}>
            <Title level={isCompactPhone ? 4 : 3} primary className={css.title}>
              {t('extraPopups', 'title.off', {'{extra}': extra})}
            </Title>
          </Row>
          <Row>
            <H3 align={Align.CENTER}>
              {t('extraPopups', 'title.running_special_offer')}
            </H3>
          </Row>
        </Fragment>
      }
      actions={
        <Button
          type={ButtonType.PAY}
          fullWidth
          onClick={handleClick}
          trackingName="grabOffer"
          adaptive
        >
          {t('extraPopups', 'button.grab_offer')}
        </Button>
      }
    />
  );
};

ExtraDiscountWithProgressPopupLayout.propTypes /* remove-proptypes */ = {
  url: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  extra: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  expires: PropTypes.bool.isRequired,
  onTimeUpdate: PropTypes.func.isRequired,
  onTimeOver: PropTypes.func.isRequired,
};

export default ExtraDiscountWithProgressPopupLayout;
