import {useReactiveVar, useQuery} from '@apollo/client';

import activeMethodTabVar from '@core/graphql/vars/activeMethodTabVar';
import isCardPaymentType from '@core/payment/forms/card/utils/isCardPaymentType';
import usePaymentParams from '@core/payment/common/utils/usePaymentParams';
import PaymentOneClickFlows from '@core/payment/common/constants/paymentOneclickFlows';
import ONE_CLICK_PARAMS_QUERY from '@core/payment/forms/card/graphql/queries/oneClickParams.gql';
import {Source} from '@core/types/graphql';
import type {
  OneClickParamsQuery,
  OneClickParamsQueryVariables,
} from '@core/payment/forms/card/graphql/queries/oneClickParams';

import ONE_CLICK_FLOW_DATA_QUERY from '../graphql/queries/paymentOneClickFlowData.gql';
import type {
  PaymentOneClickFlowDataQuery,
  PaymentOneClickFlowDataQueryVariables,
} from '../graphql/queries/paymentOneClickFlowData';

type OneClickFlowParams = {
  source?: Source;
};

type OneClickFlowData = {
  loading: boolean;
  oneClickFlow: PaymentOneClickFlows | string;
  withCustomOneClickFlow: boolean;
  oneClickFlowWith3ds: string;
  oneClickFlowWithCvv: string;
};

const useOneClickFlow = ({
  source = Source.paymentPage,
}: OneClickFlowParams = {}): OneClickFlowData => {
  const {action, via, prevVia} = usePaymentParams();

  const activeMethod = useReactiveVar(activeMethodTabVar);

  const isCardMethod = isCardPaymentType(activeMethod);

  const {data: oneClickParamsData, loading: oneClickParamsLoading} = useQuery<
    OneClickParamsQuery,
    OneClickParamsQueryVariables
  >(ONE_CLICK_PARAMS_QUERY, {
    variables: {
      source,
      via,
    },
  });

  const {data: oneClickFlowData, loading: oneClickFlowLoading} = useQuery<
    PaymentOneClickFlowDataQuery,
    PaymentOneClickFlowDataQueryVariables
  >(ONE_CLICK_FLOW_DATA_QUERY, {
    variables: {
      source,
      action,
      via,
      prevVia,
    },
  });

  const {oneClickFlowWith3ds = '', oneClickFlowWithCvv = ''} =
    oneClickFlowData?.payment.paymentFooter.blockDataMap || {};

  const isAllowedOneClick =
    isCardMethod && oneClickParamsData?.payment.isOneClickAllowed;

  const oneClickFlow = isAllowedOneClick
    ? oneClickParamsData?.payment.card?.oneClickFlow ||
      PaymentOneClickFlows.SIMPLE
    : null;

  return {
    loading: oneClickParamsLoading || oneClickFlowLoading,
    oneClickFlow,
    withCustomOneClickFlow:
      Boolean(oneClickFlow) && oneClickFlow !== PaymentOneClickFlows.SIMPLE,
    oneClickFlowWith3ds,
    oneClickFlowWithCvv,
  };
};

export default useOneClickFlow;
