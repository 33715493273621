import React from 'react';

import {ICONS} from '../../icons/constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesPopupAnimationLayout from './MicrofeaturesPopupAnimationLayout';
import css from '../styles/MicrofeaturesAnimationSuperBoost.css';

const ICONS_COUNT = 6;

const MicrofeaturesAnimationSuperBoost = () => (
  <MicrofeaturesPopupAnimationLayout
    iconsCount={ICONS_COUNT}
    iconType={ICONS[MICROFEATURES.SUPER_BOOST]}
    className={css.icon}
  />
);

export default MicrofeaturesAnimationSuperBoost;
