import React from 'react';

import ThemedSearchLivecamPage from '@phoenix/theming/components/ThemedSearchLivecamPage';

/**
 * The same as search, but on separated route, and with cam models inside.
 * @todo Investigate, maybe we can merge together search and this chunk.
 */
export default (props) => (
  <ThemedSearchLivecamPage placedInMessenger {...props} />
);
