import {WS_ACTIONS} from './actions';

/**
 * Which popup is used before the subscription
 * @const
 */
const POPUP_VIEW = {
  NONE: 'none',
  CUSTOM: 'custom',
  // Interaction popups:
  CUSTOM_DEFAULT: 'custom_default',
  CUSTOM_WITH_USERS: 'custom_with_users',
  CHAT: 'chat',
  LIKE: 'like',
  ...WS_ACTIONS, // actions names correspond to popups names
};

export default POPUP_VIEW;
