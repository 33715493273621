import logger from '@core/logger';
import ROUTES from '@core/application/constants/routes';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

/**
 * Routes where we should skip sending any inventory track
 * since on those pages content is rendered in background and
 * tracks doesn't represent react picture.
 */
export const EXCLUDED_ROUTES = [...ROUTES_WITH_POPUP, ROUTES.PAY];

const isAllowedByRoute = (
  pathname: string = window.location.pathname,
): boolean => {
  return !EXCLUDED_ROUTES.some((route: string): boolean => {
    const matched = pathname.startsWith(route);

    if (matched && route === ROUTES.PAY) {
      logger.sendWarning(
        `[isAllowedByRoute] There is some tracking on "${pathname}" route, which shouldn't be there`,
      );
    }

    return matched;
  });
};

export default isAllowedByRoute;
