/**
 * Reward for continuing to login into site everyday
 * Triggered only on first day.
 */
export const REWARD_FOR_RELOGIN_FIRST_DAY = 'CoreReward:dailyVisitFirst';

/**
 * Triggered on second continuous day when user logins
 */
export const REWARD_FOR_RELOGIN_SECOND_DAY = 'CoreReward:dailyVisitSecond';

/**
 * Triggered on third continuous day when user logins
 */
export const REWARD_FOR_RELOGIN_THIRD_DAY = 'CoreReward:dailyVisitThird';

/**
 * Reward, when user uploads a photo and passes approve on it. Only once.
 */
export const REWARD_FOR_PHOTO_UPLOAD = 'CoreReward:addPhoto';

/**
 * Reward, when user subscribed on telegram bot
 * @type {string}
 */
export const REWARD_FOR_TELEGRAM_BOT_SUBSCRIBED =
  'CoreReward:telegramFirstSubscribed';

/**
 * Reward, when user confirm email
 * @type {string}
 */
export const REWARD_FOR_CONFIRM = 'CoreReward:setConfirmStatus';

/**
 * @const {Array}
 * @see startFreeCoinsListener.js
 * @see FreeCoinsRewardPopup.js
 */
export const REWARD_RELOGIN_REASONS = [
  REWARD_FOR_RELOGIN_FIRST_DAY,
  REWARD_FOR_RELOGIN_SECOND_DAY,
  REWARD_FOR_RELOGIN_THIRD_DAY,
];

/**
 * @const {Array}
 * @see startFreeCoinsListener.js
 */
export const REWARD_REASONS = [
  ...REWARD_RELOGIN_REASONS,
  REWARD_FOR_PHOTO_UPLOAD,
  REWARD_FOR_TELEGRAM_BOT_SUBSCRIBED,
  REWARD_FOR_CONFIRM,
];

export default {
  REWARD_FOR_RELOGIN_FIRST_DAY,
  REWARD_FOR_RELOGIN_SECOND_DAY,
  REWARD_FOR_RELOGIN_THIRD_DAY,
  REWARD_FOR_PHOTO_UPLOAD,
};
