import EMAIL_CONFIRMED_QUERY from '../graphql/queries/emailConfirmed.gql';

/**
 * Email not confirm access rule.
 */
export default {
  errorCode: 'EMAIL_NOT_CONFIRMED',
  query: EMAIL_CONFIRMED_QUERY,
  resolver: (data) =>
    !data.userFeatures ||
    !data.userFeatures.isEmailConfirmRequired ||
    data.userFeatures.emailData.isConfirmed,
};
