import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';

import SystemNotificationLostConnection from '../containers/SystemNotificationLostConnection';
import SystemNotification from '../containers/SystemNotification';
import SystemNotificationVideoLimit from '../containers/SystemNotificationVideoLimit';
import SystemNotificationAgeVerification from '../containers/SystemNotificationAgeVerification';
import SystemNotificationBrowserChange from '../containers/SystemNotificationBrowserChange';
import SystemNotificationPendingDelete from '../containers/SystemNotificationPendingDelete';

const SYSTEM_NOTIFICATION_TYPE_TO_COMPONENT_MAP = {
  [NOTIFICATION_TYPES.LOST_CONNECTION]: SystemNotificationLostConnection,
  [NOTIFICATION_TYPES.VIDEO_LIMIT]: SystemNotificationVideoLimit,
  [NOTIFICATION_TYPES.DISAPPROVE_STATUS]: SystemNotification,
  [NOTIFICATION_TYPES.STATUS_UPDATE]: SystemNotification,
  [NOTIFICATION_TYPES.DISAPPROVE_SCREENNAME]: SystemNotification,
  [NOTIFICATION_TYPES.DISAPPROVE_VIDEO]: SystemNotification,
  [NOTIFICATION_TYPES.DISAPPROVE_PHOTO]: SystemNotification,
  [NOTIFICATION_TYPES.APPROVE_PHOTO]: SystemNotification,
  [NOTIFICATION_TYPES.PHOTO_UPLOAD]: SystemNotification,
  [NOTIFICATION_TYPES.APPROVE_VIDEO]: SystemNotification,
  [NOTIFICATION_TYPES.BROWSER_CHANGE]: SystemNotificationBrowserChange,
  [NOTIFICATION_TYPES.CONVERT_VIDEO_SUCCESS]: SystemNotification,
  [NOTIFICATION_TYPES.CONVERT_VIDEO_ERROR]: SystemNotification,
  [NOTIFICATION_TYPES.VIDEO_MUTE]: SystemNotification,
  [NOTIFICATION_TYPES.CHANGE_EMAIL]: SystemNotification,
  [NOTIFICATION_TYPES.CHANGE_PASSWORD]: SystemNotification,
  [NOTIFICATION_TYPES.CHANGE_TRUSTED]: SystemNotification,
  [NOTIFICATION_TYPES.RESUBSCRIBE]: SystemNotification,
  [NOTIFICATION_TYPES.MATCH]: SystemNotification,
  [NOTIFICATION_TYPES.PENDING_DELETE]: SystemNotificationPendingDelete,
  [NOTIFICATION_TYPES.SUCCESS_PAYMENT]: SystemNotification,
  [NOTIFICATION_TYPES.PERSONAL_IDENTITY]: SystemNotification,
  [NOTIFICATION_TYPES.AGE_VERIFICATION]: SystemNotificationAgeVerification,
  [NOTIFICATION_TYPES.DECLINE_VIDEO_GROUP]: SystemNotification,
  [NOTIFICATION_TYPES.SEND_GIFT]: SystemNotification,
  [NOTIFICATION_TYPES.SENT_MESSAGES_SUCCESS]: SystemNotification,
  [NOTIFICATION_TYPES.PHOTO_REQUEST_IS_SUBMITTED]: SystemNotification,
  [NOTIFICATION_TYPES.UNSENT_MESSAGES]: SystemNotification,
  [NOTIFICATION_TYPES.SEARCH_LIMIT]: SystemNotification,
} as const;

export default SYSTEM_NOTIFICATION_TYPE_TO_COMPONENT_MAP;
