import memoizeOne from 'memoize-one';

import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';

/**
 * We should get original route name, without additional params inside,
 * e.g. if pathname is "/photoUploadMotivation/index/bar/1/baz/2" we should get "/photoUploadMotivation"
 *
 * @param {string} pathname
 * @returns {string|null}
 */
const getPopupBaseRoute = memoizeOne((pathname) => {
  // At first try to find identical coincidence
  if (ROUTES_WITH_POPUP.includes(pathname)) {
    return pathname;
  }

  // If not - try to find something starting with it.
  return ROUTES_WITH_POPUP.find((route) => pathname.startsWith(route)) || null;
});

/**
 * @param {string} pathname
 * @returns {boolean}
 */
export const isPopupRoute = (pathname) => Boolean(getPopupBaseRoute(pathname));

export default getPopupBaseRoute;
