import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const GetTheAppChunk = lazy(
  () =>
    import(
      /* webpackChunkName: 'GetTheAppRoute' */ '@phoenix/getTheApp/GetTheApp'
    ),
);

export default (props) => <SplitChunk component={GetTheAppChunk} {...props} />;
