/**
 * Current concepts uses only data[0], but RM has plans to add concept with the separate terms and links
 * @param {array} data
 */
const parseCoregistrationPopupConceptData = (data) => {
  if (data.length > 1) {
    return data.reduce(
      (memo, {terms, images, text, successText, url}) => {
        memo.terms.push(terms);
        memo.images.push(images[0]);
        memo.text.push(text);
        memo.successText.push(successText);
        memo.url.push(url);

        return memo;
      },
      {terms: [], images: [], text: [], successText: [], url: []},
    );
  }

  const {terms, images, text, successText, url} = data[0];

  return {terms, images, text, successText, url};
};

export default parseCoregistrationPopupConceptData;
