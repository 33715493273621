import once from 'lodash/once';

import {CONTACT_DELETION_SCHEDULE_KEY} from '@core/messenger/common/constants/storageKeys';

import createScheduler from './createScheduler';

const UpgradeReadService = {
  getInstance: once(() =>
    createScheduler({
      storageKey: CONTACT_DELETION_SCHEDULE_KEY,
    }),
  ),
};

export default UpgradeReadService;
