import React from 'react';
import {useQuery} from '@apollo/client';

import {Bar, Button, ButtonPay, Row, RowShort, SpacingShort} from '@phoenix/ui';
import {Align, BarPosition, SpacingSize} from '@phoenix/ui/constants';
import useViaForBalanceRefill from '@phoenix/coins/buyCoins/utils/useViaForBallanceRefill';
import FREE_DAILY_SPIN_AVAILABLE_QUERY from '@phoenix/freeDailySpin/graphql/queries/freeDailySpinAvailable.gql';
import openFortuneWheelForCreditsPopup from '@phoenix/freeDailySpin/utils/openFortuneWheelForCreditsPopup';

import useShowCoinsBalance from '../utils/useShowCoinsBalance';
import COIN_ICON_SIZES from '../constants/coinColorizedIconSizes';
import CreditAmount from '../components/CreditAmount';
import getBalanceButtonText from '../utils/getBalanceButtonText';
import getBalanceText from '../utils/getBalanceText';
import useBalanceData from '../utils/useBalanceData';

/**
 * Show balance for different payment system, refill button
 * and additional widgets, if they are needed.
 *
 * @see Layout.js in header
 * @returns {JSX.Element|null}
 * @constructor
 */
const CreditBalanceRefillBanner = () => {
  const withCoinsBalance = useShowCoinsBalance();
  const via = useViaForBalanceRefill();
  const {balance, show, currency, handleBuyClick} = useBalanceData({via});
  const {data, loading, error} = useQuery(FREE_DAILY_SPIN_AVAILABLE_QUERY);
  if (!show || error || loading) return null;

  return (
    <Bar light position={BarPosition.TOP}>
      <SpacingShort>
        <Row align={Align.BETWEEN}>
          {withCoinsBalance && (
            <RowShort horizontal>
              <CreditAmount
                space={SpacingSize.SHORT}
                align={Align.LEFT}
                iconSize={COIN_ICON_SIZES.MEDIUM}
                heading
              >
                {getBalanceText(currency, balance)}
              </CreditAmount>
            </RowShort>
          )}
          <RowShort horizontal flexibleWidth={false}>
            {data?.spin.available && (
              <Button
                onClick={openFortuneWheelForCreditsPopup}
                trackingName="spin"
                data-test="spinAction"
                icon="spinner"
              />
            )}
          </RowShort>
          <RowShort horizontal flexibleWidth={!withCoinsBalance}>
            <ButtonPay
              fullWidth={!withCoinsBalance}
              onClick={handleBuyClick}
              trackingName="getCoins"
              data-test="coinsAction"
            >
              {getBalanceButtonText(currency)}
            </ButtonPay>
          </RowShort>
        </Row>
      </SpacingShort>
    </Bar>
  );
};

export default CreditBalanceRefillBanner;
