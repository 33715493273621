import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import {getClientInstance} from '@core/graphql/client';

import CoinsSuccessPopup from '@phoenix/coins/buyCoins/containers/CoinsSuccessPopup';

import WeekSpeakOfferSuccessPopup from '../specialOffer/components/WeekSpeakOfferSuccessPopup';
import {WEEK_SPEAK} from '../specialOffer/constants/specialOfferPopups';

/**
 * A function to display a success popup for coins or WeekSpeak offers.
 *
 * @function
 * @param {Object} params - Parameters for configuring the popup.
 * @param {string[]} params.packages - The packages data used in the popup.
 * @param {string} params.type - The type of popup to be displayed. It determines whether it is a WeekSpeak offer success or a coins success popup.
 * @param {Function} params.onClose - Callback function triggered when the popup is closed.
 *
 * @returns {void}
 */
type OpenCoinsSuccessPopupParams = {
  packages?: string[];
  type?: string;
  onClose?: () => void;
};

const openCoinsSuccessPopup = ({
  packages,
  type,
  onClose,
}: OpenCoinsSuccessPopupParams) => {
  const isWeekSpeak = type === WEEK_SPEAK;
  PopupService.openPopup(
    isWeekSpeak ? (
      <WeekSpeakOfferSuccessPopup onButtonClick={onClose} />
    ) : (
      <CoinsSuccessPopup packages={packages} onButtonClick={onClose} />
    ),
    {
      small: true,
      autoLoadTracking: true,
      trackingName: isWeekSpeak ? 'weekSpeakOfferSuccess' : 'coinsSuccess',
      onClose: (sourceEventType) => {
        if (
          sourceEventType === PopupSourceEvent.CLOSE_BUTTON_CLICK &&
          onClose
        ) {
          onClose();
        }
      },
    },
  );

  // Invalidate coins payment banner after success payment when user don`t changing it`s payment status
  invalidateCacheByTypename(getClientInstance(), 'Payment', 'discountData');
};

export default openCoinsSuccessPopup;
