/**
 * Type variables for private photo overlay
 */
enum PrivatePhotoOverlayType {
  /**
   * Show only icon
   */
  ICON = 'onlyIcon',

  /**
   * Show only description
   */
  DESCRIPTION = 'onlyDescription',

  /**
   * Show icon, description and request button
   */
  NORMAL = 'normal',

  /**
   * Show icon, description and request button, and show request button
   */
  WITH_REQUEST_BUTTON = 'withRequestButton',

  /**
   * Show icon, description and request button, and show verify age description
   */
  WITH_VERIFY_AGE_DESCRIPTION = 'withVerifyAgeDescription',

  /**
   * Show icon with small icon
   */
  WITH_SMALL_ICON = 'withSmallIcon',
}

export default PrivatePhotoOverlayType;
