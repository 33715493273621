import {createUrlFor} from '@core/utils/url';
import {
  FUNNEL_PHOTO_UPLOAD,
  FUNNEL_RESALE,
  FUNNEL_INFO_UPLOAD,
  REMARKETING_OFFER,
  FUNNEL_PREFERRED_CONTENT,
  FUNNEL_STATUS_UPLOAD,
  FUNNEL_CONFIRM_WITH_PHOTO_UPLOAD,
  FUNNEL_IN_APP_MOTIVATION,
} from '@core/application/constants/routesWithPopup';
import ROUTES from '@core/application/constants/routes';
import PROFILE_EDIT_CONSTANTS from '@core/user/profile/constants/editConstants';
import {localStorage} from '@core/utils/storage';

import {FULL_FUNNEL_OPEN_CURRENT_PROFILE} from '@phoenix/application/constants/marketingProductConfigurationName';

import {REDIRECT_TO_CURRENT_PROFILE_PUSHED_ON_FUNNEL_STORAGE_KEY} from '../paidFunnel/constants/storageKeys';

/**
 * @param {Object} data - GraphQL API data
 * @returns {Array}
 */
const buildFunnelStack = (data) => {
  if (!data) return [];
  const stack = [];

  /**
   * Fill funnel stack depending on their availability on the server.
   * Order is matter! Funnels will be shown depending on the order of the stack.
   */
  if (data.remarketing?.paymentPopup?.isRedirectAfterConfirmationAvailable) {
    stack.push(`${REMARKETING_OFFER}?redirectToFunnel=true`);
  }
  if (data.funnel.photoUploadAllowed) stack.push(FUNNEL_PHOTO_UPLOAD);
  if (data.funnel.confirmWithPhotoUpload) {
    stack.push(FUNNEL_CONFIRM_WITH_PHOTO_UPLOAD);
  }
  if (data.funnel.statusUpload.enabled) stack.push(FUNNEL_STATUS_UPLOAD);
  if (data.funnel.preferredContent.enabled) {
    stack.push(FUNNEL_PREFERRED_CONTENT);
  }
  if (data.funnel.infoUpload.allowed) stack.push(FUNNEL_INFO_UPLOAD);

  if (data.funnel.userResaleConcept) stack.push(FUNNEL_RESALE);

  if (data.funnel.inAppMotivationAllowed) stack.push(FUNNEL_IN_APP_MOTIVATION);

  // if vas returned userIdAfterFunnel need to set link for user profile in last step
  if (data.userFeatures.userIdAfterFunnel) {
    stack.push(createUrlFor.userProfile(data.userFeatures.userIdAfterFunnel));
  } else {
    const redirectToProfile =
      data.userFeatures.marketingProductConfigurationName ===
        FULL_FUNNEL_OPEN_CURRENT_PROFILE &&
      !localStorage.getItem(
        REDIRECT_TO_CURRENT_PROFILE_PUSHED_ON_FUNNEL_STORAGE_KEY,
      );

    stack.push(
      redirectToProfile
        ? `${ROUTES.PROFILE}/index/edit/${PROFILE_EDIT_CONSTANTS.STATUS}`
        : data.site.defaultRoute,
    );

    localStorage.setItem(
      REDIRECT_TO_CURRENT_PROFILE_PUSHED_ON_FUNNEL_STORAGE_KEY,
      true,
    );
  }

  return stack;
};

export default buildFunnelStack;
