import type {FC, MouseEvent, ReactNode, ReactEventHandler} from 'react';
import React from 'react';
import cn from 'classnames';
import {useInView} from 'react-intersection-observer';

import type {LevelTextsType} from '@core/user/photo/constants/levelTexts';
import {
  LEVEL_TEXTS,
  LEVEL_TEXTS_CLEAN,
} from '@core/user/photo/constants/levelTexts';
import PhotoSize from '@core/user/photo/constants/PhotoSize';
import PhotoLevel from '@core/user/photo/constants/PhotoLevel';
import useEventCallback from '@core/utils/react/useEventCallback';
import AddBabciaUBTracking from '@core/tracking/babcia/containers/AddBabciaUBTracking';

import {Icon} from '@phoenix/ui';
import type {
  ImageScaling,
  OverlaySaturation,
  OverlayType,
} from '@phoenix/ui/constants';
import {Text} from '@phoenix/typography';

import BlurredPhoto from './BlurredPhoto';
import LevelOverlay from './LevelOverlay';
import PrivateOverlay from './PrivateOverlay';
import PhotoOverlay from './PhotoOverlay';
import PendingDeleteOverlay from './PendingDeleteOverlay';
import type PhotoRequestData from '../types/PhotoRequestData';
import css from '../styles/PhotoLayout.css';

export type PhotoLayoutProps = {
  className?: string;
  trackingName?: string;
  /**
   * Lazy load. Image will be inserted into the DOM only when this photo becomes in or near the viewport.
   */
  lazy?: boolean;
  url: string;
  level?: PhotoLevel;
  blurLevel?: PhotoLevel;
  fullHeight?: boolean;
  texts?: LevelTextsType;
  /**
   * Determinate how to show info inside overlays
   */
  size?: PhotoSize;
  mediaCounter?: number;
  pendingDeleteShowDescription?: boolean;
  pendingDelete?: boolean;
  /**
   * Completely disable an overlay. Useful for small user photos
   */
  showOverlay?: boolean;
  pendingDeleteOneColumn?: boolean;
  showPhoto?: boolean;
  scaling?: ImageScaling;
  round?: boolean;
  withBigTextSize?: boolean;
  shadow?: boolean;
  onClick?: (
    event: MouseEvent<HTMLDivElement>,
    options?: {url: string; level: PhotoLevel},
  ) => void;
  onLoad?: ReactEventHandler<HTMLImageElement>;
  withLockOverlay?: boolean;
  showLoadingBackground?: boolean;
  withCleanProductRestrictions?: boolean;
  withBlurAnimation?: boolean;
  lazyLoadMargin?: number;
  photoRequestData?: PhotoRequestData;
  withOverlay?: boolean;
  overlayClassName?: string;
  withDescription?: boolean;
  scrollRoot?: Element;
  customOverlay?: ReactNode;
  overlayFullSize?: boolean;
  overlaySaturation?: OverlaySaturation;
  overlayType?: OverlayType;
};

const PhotoLayout: FC<PhotoLayoutProps> = ({
  className,
  trackingName,
  // For making integration tests more stable
  lazy = !window.IS_INTEGRATION_TEST_ENVIRONMENT,
  url,
  level = PhotoLevel.NORMAL,
  blurLevel = PhotoLevel.NORMAL,
  fullHeight = false,
  texts,
  size = PhotoSize.NORMAL,
  mediaCounter = 0,
  pendingDeleteShowDescription = false,
  pendingDeleteOneColumn = false,
  pendingDelete = false,
  showOverlay = true,
  showPhoto,
  round = false,
  scaling,
  onClick,
  onLoad,
  shadow = false,
  overlaySaturation = null,
  overlayType = null,
  overlayFullSize = true,
  withLockOverlay = false,
  showLoadingBackground,
  withCleanProductRestrictions,
  withBlurAnimation = false,
  lazyLoadMargin = 200,
  photoRequestData,
  withBigTextSize = false,
  scrollRoot,
  overlayClassName,
  withDescription = true,
  customOverlay,
}) => {
  const showMediaCounter = Boolean(mediaCounter);

  /**
   * We need to pass url and level in order to show photo
   * with correct blur level in naughty mode popup if needed
   */
  const handleClick = useEventCallback((event: MouseEvent<HTMLDivElement>) =>
    onClick?.(event, {url, level}),
  );

  /**
   * Don't worry about using useInView for non-lazy photos,
   * because it does almost nothing when ref is unused.
   */
  const [imgRef, inView] = useInView({
    root: scrollRoot,
    // Some space to start loading before the photo appears in the viewport.
    rootMargin: `${lazyLoadMargin}px`,
    threshold: 0,
    triggerOnce: true,
    skip: !lazy,
  });

  const defaultTexts = withCleanProductRestrictions
    ? LEVEL_TEXTS_CLEAN
    : LEVEL_TEXTS;

  const withOverlay = photoRequestData?.withOverlay ?? true;

  const withPrivatePhotoOverlay =
    withOverlay && photoRequestData?.isPrivate && !customOverlay;

  return (
    <AddBabciaUBTracking
      trackingName={trackingName || (onClick ? 'userPhoto' : null)}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        data-test="photoLayout"
        className={cn(
          className,
          css.photo,
          onClick && css.clickable,
          round && css.round,
          shadow && css.shadow,
          withBlurAnimation && css.withBlurAnimation,
        )}
        onClick={onClick && handleClick}
      >
        <BlurredPhoto
          ref={imgRef}
          fullHeight={fullHeight}
          url={url}
          level={showMediaCounter ? PhotoLevel.HIGHLY_BLURRED : blurLevel}
          scaling={scaling}
          onLoad={onLoad}
          // 'showPhoto' need only for legacy code PaymentPageMotivationView when use renderPortal
          showPhoto={showPhoto || inView || !lazy}
          showLoadingBackground={showLoadingBackground}
        />
        {withPrivatePhotoOverlay && (
          <PrivateOverlay size={size} photoRequestData={photoRequestData} />
        )}
        {level !== PhotoLevel.NORMAL &&
          showOverlay &&
          !customOverlay &&
          !withPrivatePhotoOverlay &&
          !showMediaCounter && (
            <LevelOverlay
              level={level}
              size={size}
              overlayClassName={overlayClassName}
              texts={texts || defaultTexts}
              withBigTextSize={withBigTextSize}
              withDescription={withDescription}
            />
          )}
        {!showMediaCounter && pendingDelete && (
          <PendingDeleteOverlay
            oneColumn={pendingDeleteOneColumn}
            showDescription={pendingDeleteShowDescription}
          />
        )}
        <PhotoOverlay
          level={blurLevel}
          overlaySaturation={overlaySaturation}
          overlayType={overlayType}
          overlayFullSize={overlayFullSize}
        />
        {level === PhotoLevel.NORMAL && withLockOverlay && (
          <Icon className={css.centered} inverse muted type="lock" />
        )}
        {showMediaCounter && (
          <Text className={css.centered} inverse>
            {`+${mediaCounter}`}
          </Text>
        )}
        {!showMediaCounter && customOverlay}
      </div>
    </AddBabciaUBTracking>
  );
};

export default PhotoLayout;
