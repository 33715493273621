import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {PHOTO_SETTINGS} from '@core/mediaUpload/constants/mediaSettings';
import {IMAGES} from '@core/mediaUpload/constants/accept';
import {CLOSE_AFTER_SUCCESS} from '@core/mediaUpload/utils/uploadFilesSource';
import {FUNNEL_PMA_NEXT_STEP} from '@core/mediaUpload/constants/sources';
import {getClientInstance} from '@core/graphql/client';

import PmaPhotoUploadPopup from '../containers/PmaPhotoUploadPopup';
import PMA_NEXT_STEP_PHOTO_UPLOAD_QUERY from '../graphql/queries/pmaNextStepPhotoUpload.gql';
import openPhotoUploadPopup from './openPhotoUploadPopup';

/**
 * Open photo upload popup with next step as success photo upload popup with pma
 * @param {function} onClose
 * @param {string} initialSource
 * @param restParams
 */
const openPmaPhotoUploadPopup = async ({
  initialSource,
  onClose,
  ...restParams
} = {}) => {
  const {data: dataPmaNextStep} = await getClientInstance().query({
    query: PMA_NEXT_STEP_PHOTO_UPLOAD_QUERY,
  });
  let source = initialSource;
  if (dataPmaNextStep?.userFeatures?.infinityPhotoUploadAvailable) {
    source = FUNNEL_PMA_NEXT_STEP;
  }

  const handleOnClose = (data) => {
    if (source === FUNNEL_PMA_NEXT_STEP && data === CLOSE_AFTER_SUCCESS) {
      PopupService.openPopup(
        <PmaPhotoUploadPopup
          settings={{
            ...PHOTO_SETTINGS,
            accept: IMAGES,
            notifySuccess: false,
          }}
          onClose={onClose}
        />,
        {
          onClose,
          trackingName: 'pmaPhotoUpload',
          small: true,
        },
      );

      return;
    }

    onClose(data);
  };

  await openPhotoUploadPopup({source, onClose: handleOnClose, ...restParams});
};

export default openPmaPhotoUploadPopup;
