import {useMemo} from 'react';

import PaymentPackageHeaderAppearance from '@core/theming/constants/features/PaymentPackageHeaderAppearance';

import PAYMENT_PACKAGE_HEADER_COLOR from '@phoenix/payment/widgets/package/constants/packageHeaderColor';
import PaymentPackageOldPrice from '@phoenix/payment/widgets/package/components/PaymentPackageOldPrice';
import PaymentPackageOldPriceMuted from '@phoenix/payment/widgets/package/components/PaymentPackageOldPriceMuted';

import useThemeFeature from './useThemeFeature';
import type {ModuleComponent} from '../types';

/**
 * @todo add correct type for PaymentPackageOldPrice and PaymentPackageOldPriceMuted on next iteration of TS migration FE-42379
 */
type PaymentPackageOldPriceProps = any;

type ThemedPackageHeaderData = {
  paymentPackageOldPriceLayout?: ModuleComponent<PaymentPackageOldPriceProps>;
  roundArrow?: boolean;
  withArrow?: boolean;
  color?: string;
};

/**
 * Appearance-changeable payment packages header based on theme.
 * Returns object of component properties that are used for theming.
 * Common custom realization for paid funnel and payment page
 */
const useThemedPackageHeaderProps = (): {
  loading?: boolean;
  data?: ThemedPackageHeaderData;
} => {
  const {data, loading} = useThemeFeature<PaymentPackageHeaderAppearance>(
    'payment',
    'packageHeader',
  );

  const packageProps = useMemo((): ThemedPackageHeaderData => {
    if (loading) return {};

    switch (data) {
      case PaymentPackageHeaderAppearance.WITH_ROUNDED_PRICE:
        return {
          paymentPackageOldPriceLayout: PaymentPackageOldPrice,
          roundArrow: true,
        };
      case PaymentPackageHeaderAppearance.WITH_ROUNDED_AND_MUTED_PRICE:
        return {
          paymentPackageOldPriceLayout: PaymentPackageOldPriceMuted,
          roundArrow: true,
        };
      case PaymentPackageHeaderAppearance.HIGHLIGHTED:
        return {
          paymentPackageOldPriceLayout: PaymentPackageOldPrice,
          withArrow: false,
          color: PAYMENT_PACKAGE_HEADER_COLOR.HIGHLIGHTED,
        };
      default:
        return {
          paymentPackageOldPriceLayout: PaymentPackageOldPrice,
        };
    }
  }, [data, loading]);

  return {data: packageProps, loading};
};

export default useThemedPackageHeaderProps;
