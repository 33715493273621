import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import Timer from '@core/utils/timer';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';

import {ButtonLink, Row, RowShort, Overlay, Tag} from '@phoenix/ui';
import {
  ButtonType,
  OverlaySaturation,
  Align,
  TagType,
} from '@phoenix/ui/constants';
import {H3, TextMuted} from '@phoenix/typography';
import Photo from '@phoenix/user/photo/containers/Photo';
import PopupFrame from '@phoenix/popup/components/PopupFrame';

import css from '../styles/ExtraProfilesViewPopupLayout.css';

const ExtraProfilesViewPopupLayout = ({
  users,
  expired,
  url,
  duration,
  onTimeOver,
  extra,
}) => (
  <PopupFrame
    data-test="extraProfilesViewPopupLayout"
    content={
      <div className={css.content}>
        <Row>
          <div className={css.usersWithTimer}>
            <div className={css.users}>
              {users.slice(0, 3).map(({id, profile}) => (
                <div key={id} className={css.user}>
                  <Photo userId={id} photo={getPrimaryPhotoData(profile)} />
                  <div className={expired ? css.overlayExpired : css.overlay}>
                    <Overlay saturation={OverlaySaturation.HEAVY} />
                  </div>
                </div>
              ))}
            </div>
            <div className={css.timer}>
              <div className={css.timerWrapp}>
                <Tag
                  type={expired ? TagType.DANGER : TagType.TRANSLUCENT}
                  inverse={!expired}
                  icon="clock"
                  large
                  data-test="extraProfilesViewPopupTag"
                >
                  {expired ? (
                    '00:00:00'
                  ) : (
                    <Timer
                      backward
                      initialTime={duration}
                      duration={duration}
                      onTimeOver={onTimeOver}
                    />
                  )}
                </Tag>
              </div>
            </div>
          </div>
        </Row>
        <RowShort align={Align.CENTER}>
          <H3 data-test="extraProfilesViewPopupMotivation1">
            {expired
              ? t('extraPopups', 'title.time_up')
              : t('extraPopups', 'title.view_photos_free')}
          </H3>
        </RowShort>
        <RowShort align={Align.CENTER}>
          <TextMuted data-test="extraProfilesViewPopupMotivation2">
            {expired
              ? t('extraPopups', 'text.free_pics', {'{n}': extra})
              : t('extraPopups', 'text.hidden_pics', {'{n}': extra})}
          </TextMuted>
        </RowShort>
      </div>
    }
    actions={
      <ButtonLink
        type={expired ? ButtonType.PAY : ButtonType.PRIMARY}
        href={expired ? url : SEARCH_PAGE_LINK}
        fullWidth
        adaptive
        data-test={
          expired
            ? 'extraProfilesViewPopupExpiredAction'
            : 'extraProfilesViewPopupAction'
        }
      >
        {expired
          ? t('extraPopups', 'button.get_upgraded')
          : t('extraPopups', 'button.wanna_view')}
      </ButtonLink>
    }
  />
);

ExtraProfilesViewPopupLayout.propTypes /* remove-proptypes */ = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profile: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  expired: PropTypes.bool,
  url: PropTypes.string,
  duration: PropTypes.number,
  onTimeOver: PropTypes.func,
  extra: PropTypes.number,
};

export default ExtraProfilesViewPopupLayout;
