import React, {useCallback, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import PopupService from '@core/popup/utils/PopupService';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';
import {replaceLocation} from '@core/utils/url';
import useOrientationAndGender from '@core/user/profile/current/utils/useOrientationAndGender';

import {ButtonPrimary} from '@phoenix/ui';

import MicrofeaturesSuccessPopupLayout from '../components/MicrofeaturesSuccessPopupLayout';
import {
  MICROFEATURES,
  TRANSLATION_TYPES,
} from '../../common/constants/microfeatures';
import {MICROFEATURES_SUCCESS_ANIMATION} from '../constants/animationComponents';
import {MICROFEATURES_ICON} from '../../icons/constants/iconComponents';
import {IconSize} from '../../icons/constants/IconSize';
import getTelegramConversationAutologinUrl from '../../telegramConversation/utils/getTelegramConversationAutologinUrl';
import useRoundMicrofeatureIcon from '../../common/utils/useRoundMicrofeatureIcon';
import getTranslationByType from '../../common/utils/getTranslationByType';
import useMicrofeaturesAvailabilityData from '../../common/utils/useMicrofeaturesAvailabilityData';

/**
 * Successful microfeature payment popup
 */
const MicrofeaturesSuccessPopup = ({featureName, returnPath}) => {
  const history = useHistory();
  const {orientation, gender} = useOrientationAndGender();
  const {isUseAlternativeTitles} = useMicrofeaturesAvailabilityData();

  const IconComponent = MICROFEATURES_ICON[featureName];
  const isRoundIcon = useRoundMicrofeatureIcon();

  // avoid adding animation in integration tests to avoid instability
  const AnimationComponent = window.IS_INTEGRATION_TEST_ENVIRONMENT
    ? null
    : MICROFEATURES_SUCCESS_ANIMATION[featureName];

  const [popupTitle, popupDescription, popupAction] = useMemo(
    () =>
      [
        TRANSLATION_TYPES.SUCCESS_TITLE,
        TRANSLATION_TYPES.SUCCESS_DESCRIPTION,
        TRANSLATION_TYPES.SUCCESS_ACTION,
      ].map((type) =>
        getTranslationByType({
          type,
          featureName,
          orientation,
          gender,
          isAdult: isUseAlternativeTitles,
        }),
      ),
    [featureName, orientation, gender, isUseAlternativeTitles],
  );

  const handleClick = useCallback(async () => {
    if (featureName === MICROFEATURES.TELEGRAM_CONVERSATION) {
      const telegramConversationAutologinUrl =
        await getTelegramConversationAutologinUrl();

      if (telegramConversationAutologinUrl) {
        replaceLocation(telegramConversationAutologinUrl);
      } else {
        history.push(SEARCH_PAGE_LINK);
        PopupService.closePopup();
      }

      return;
    }

    PopupService.closePopup();

    if (returnPath) {
      history.push(returnPath);
    }
  }, [featureName, history, returnPath]);

  return (
    <MicrofeaturesSuccessPopupLayout
      header={<IconComponent size={IconSize.LARGE} round={isRoundIcon} />}
      title={popupTitle}
      content={popupDescription}
      action={
        <ButtonPrimary
          // data-test is needed for automated tests.
          data-test="microfeaturesSuccessPopupAction"
          onClick={handleClick}
          fullWidth
          adaptive
          trackingName="closePopup"
        >
          {popupAction}
        </ButtonPrimary>
      }
      animationBlock={AnimationComponent ? <AnimationComponent /> : null}
    />
  );
};

MicrofeaturesSuccessPopup.propTypes /* remove-proptypes */ = {
  featureName: PropTypes.oneOf(Object.values(MICROFEATURES)).isRequired,
  returnPath: PropTypes.string,
};

export default MicrofeaturesSuccessPopup;
