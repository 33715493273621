import React from 'react';

import CommonPopupDisplayer from '@core/popup/containers/PopupDisplayer';

import ThemedPopup from '@phoenix/theming/components/ThemedPopup';

import PopupLoadTrackingProvider from './PopupLoadTrackingProvider';

// To track 'Load Start', 'Load Complete' and 'Close'.
const wrapContent = (
  child,
  {key, autoLoadTracking, trackingName, trackingConceptId},
  hidden,
) => (
  <PopupLoadTrackingProvider
    key={key}
    autoLoadTracking={autoLoadTracking}
    trackingName={trackingName}
    trackingConceptId={trackingConceptId}
    skip={hidden}
  >
    {child}
  </PopupLoadTrackingProvider>
);

const PopupDisplayer = () => (
  <CommonPopupDisplayer
    popupComponent={ThemedPopup}
    wrapContent={wrapContent}
  />
);

export default PopupDisplayer;
