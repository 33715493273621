import type {Observable} from 'rxjs';
import {from} from 'rxjs';
import {pairwise, map, filter} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import type {CommonNotification} from '@core/systemNotifications/types';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import type {PendingDeleteQuery} from '@core/user/profile/current/graphql/queries/pendingDelete';
import PENDING_DELETE_QUERY from '@core/user/profile/current/graphql/queries/pendingDelete.gql';

const photoPendingDeleteDataSource = (): Observable<CommonNotification> =>
  from(
    getClientInstance().watchQuery<PendingDeleteQuery>({
      query: PENDING_DELETE_QUERY,
    }),
  ).pipe(
    map(({data}) => data.myUser.profile.photos.allPhotos),
    pairwise(),
    filter(([prev, next]) => {
      let showNotification = false;

      if (prev.length === next.length) {
        const nextPendingDeleteByPhotoId: Record<string, boolean> = {};

        next.forEach(({id, pendingDelete}) => {
          nextPendingDeleteByPhotoId[id] = pendingDelete;
        });

        prev.forEach(({id, pendingDelete}) => {
          if (
            nextPendingDeleteByPhotoId[id] &&
            pendingDelete !== nextPendingDeleteByPhotoId[id]
          ) {
            showNotification = true;
          }
        });
      }

      return showNotification;
    }),
    map(() => ({type: NOTIFICATION_TYPES.PENDING_DELETE})),
  );

export default photoPendingDeleteDataSource;
