/**
 * List of custom actions for CoinsLogic, to show native subscription popup
 * @const
 */
export const CUSTOM_ACTIONS = {
  NOTIFICATION_BANNER: 'notificationBanner',
  FREE_TASK: 'freeTask',
};

/**
 * Experimental actions, incoming via WS. Managed by the backend only.
 * @const
 */
export const WS_ACTIONS = {
  SEND_FIRST_MESSAGE: 'sendFirstMessage',
  ADD_FIRST_FAVORITE: 'addFirstFavorite',
  ADD_LIKE: 'addLike',
  OPEN_PROFILE: 'openProfile',
  FREE_MESSAGE_FINISHED: 'freeMessageFinished',
  SEND_SECOND_MESSAGE: 'sendSecondMessage',
};

/**
 * Actions after which the subscription popup will be shown
 * @const
 */
const ACTIONS = {
  ...WS_ACTIONS,
  ROUTE: 'route',
  CHAT: 'chat',
  LIKE: 'like',
};

export default ACTIONS;
