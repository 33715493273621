import {useEffect, useState, useMemo} from 'react';
import {useQuery} from '@apollo/client';

import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import {MOB_SITE, WEB_SITE} from '@core/responsive/environments';

import REMARKETING_BANNER_BASE_DATA_QUERY from '../graphql/queries/remarketingBannerBaseData.gql';
import RemarketingBannerCollector from './RemarketingBannerCollector';

/**
 * Return all data about banner for zone
 * @param zoneId
 * @return {Object}
 */
const useRemarketingBannerData = (zoneId) => {
  const [bannerData, setBannerData] = useState(null);
  const environment = useSmallTabletBreakpoint() ? MOB_SITE : WEB_SITE;
  const collector = useMemo(() => RemarketingBannerCollector.getInstance(), []);

  useEffect(() => {
    if (zoneId && environment) {
      const zone = {
        zoneId,
        environment,
      };
      collector.subscribe((banner) => {
        setBannerData(banner);
      }, zone);
      collector.fetchBanner(zone);
    }

    return () => {
      zoneId && collector.unsubscribe(zoneId);
    };
  }, [zoneId, environment, collector]);

  const {data, loading} = useQuery(REMARKETING_BANNER_BASE_DATA_QUERY);

  return {
    loading: loading || bannerData === null,
    remarketing: {
      ...(data?.remarketing || {}),
      ...(bannerData
        ? {
            banner: bannerData,
          }
        : {}),
    },
  };
};

export default useRemarketingBannerData;
