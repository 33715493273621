/* eslint-disable consistent-return */

import {useEffect, useCallback, useRef} from 'react';
import {useSubscription, useApolloClient} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import delay from 'lodash/delay';
import isNull from 'lodash/isNull';

import useEventCallback from '@core/utils/react/useEventCallback';
import updateRecipientCache from '@core/messenger/common/utils/updateRecipientCache';
import {createMessage} from '@core/messenger/common/utils/addSendingMessageToCache';

import handlePartnerNetworkError from '@phoenix/partnerNetwork/utils/handlePartnerNetworkError';

import VIDEO_CHAT_STATUSES from '../constants/statusConstants';
import VIDEO_CHAT_ACTIONS from '../constants/actionConstants';
import VIDEO_CALL_SUBSCRIPTION from '../graphql/subscriptions/videoCall.gql';
import VIDEO_CALL_RESPONSE_MUTATION from '../graphql/mutations/videoCallResponse.gql';
import isVideoChatSupported from './isVideoChatSupported';
import useVideoChatTimer from './useVideoChatTimer';
import useRedirectVideoChat from './useRedirectVideoChat';
import VIDEO_CHAT_START_INFO_QUERY from '../graphql/queries/videoChatStartInfo.gql';

/**
 * @const {array.<string>}
 */
const ALLOWED_STATUSES_FOR_STARTING_CALL = [
  VIDEO_CHAT_STATUSES.READY,
  VIDEO_CHAT_STATUSES.MISSED,
  VIDEO_CHAT_STATUSES.STOP,
];

/**
 * @const {number}
 */
const NOTIFICATION_LIFETIME = 5000;
/**
 * @const {number}
 */
const OUTGOING_CALL_TIMEOUT = 32000;

let resetStateTimeout;
let timeoutOutgoingCall;

/**
 * Check if current user already has conversation with other opponent.
 * @param {string} videoChatStatus
 * @param {string} userId - id of user from who we have request
 * @param {string} opponentUserId - id of user with who we might already have conversation
 * @param {string} action - type of request
 * @return {boolean}
 */
const isCurrentUserBusy = (videoChatStatus, userId, opponentUserId, action) =>
  !isVideoChatSupported() ||
  ([
    VIDEO_CHAT_STATUSES.CALLING,
    VIDEO_CHAT_STATUSES.INCOMING,
    VIDEO_CHAT_STATUSES.OUTGOING,
  ].includes(videoChatStatus) &&
    opponentUserId !== userId &&
    action !== VIDEO_CHAT_ACTIONS.BUSY);

/**
 * Send response that current user is busy via interactions.
 * @param {string} userId
 * @param {object} client
 */
const sendBusyResponse = (userId, client) => {
  client.mutate({
    mutation: VIDEO_CALL_RESPONSE_MUTATION,
    variables: {userId, videoAction: VIDEO_CHAT_ACTIONS.BUSY},
  });
};

/**
 * Set default status and user id in VideoChatProvider.
 * @param {function} setVideoChatStatus
 * @param {function} setOpponentUserId
 */
const resetState = (setVideoChatStatus, setOpponentUserId) => {
  setVideoChatStatus(VIDEO_CHAT_STATUSES.READY);
  setOpponentUserId('');
};

/**
 * After receiving interaction, we can get redirectUrl to pp or to trusted page.
 * @param {string || null} redirectUrl
 * @param {object} history
 * @param {function} setVideoChatStatus
 * @param {function} setOpponentUserId
 */
const redirectIfNeeded = (
  redirectUrl,
  history,
  setVideoChatStatus,
  setOpponentUserId,
) => {
  if (redirectUrl) {
    resetState(setVideoChatStatus, setOpponentUserId);
    history.push(redirectUrl);
  }
};

/**
 * Reset state and stop timer.
 * @param {function} setVideoChatStatus
 * @param {function} setOpponentUserId
 * @param {function} stopTimer
 */
const endVideoChat = (setVideoChatStatus, setOpponentUserId, stopTimer) => {
  stopTimer();
  resetState(setVideoChatStatus, setOpponentUserId);
};

/**
 * We need to get unique identifier for each action.
 * For example: "privateVideoInvintation_1593083087"
 * @param {string} actionName
 * @param {number} timestamp
 * @return {string}
 */
const getActionUniqueId = (actionName, timestamp) => {
  return actionName && timestamp ? `${actionName}_${timestamp}` : null;
};

/**
 * Update statuses of video calls in messenger
 * @param {string} userId
 * @param {Object} message
 */
const updateStatusesOfVideoCallsInMessenger = (userId, message) => {
  updateRecipientCache(userId, ({messages}) => ({
    messages: [
      ...(messages || []),
      {
        ...createMessage(),
        ...message,
      },
    ],
  }));
};

/**
 * Hook for listening video chat interactions and sending responses via interaction.
 * @param {string} videoChatStatus
 * @param {function} setVideoChatStatus
 * @param {string} opponentUserId
 * @param {function} setOpponentUserId
 * @param {string} myStreamId
 * @param {function} setMyStreamId
 * @param {string} targetStreamId
 * @param {function} setTargetStreamId
 * @param {boolean} disabled
 */
const useVideoChatControllers = ({
  videoChatStatus,
  setVideoChatStatus,
  opponentUserId,
  setOpponentUserId,
  myStreamId,
  setMyStreamId,
  targetStreamId,
  setTargetStreamId,
  disabled,
}) => {
  const {
    data: {
      videoCall: {
        userId: userIdFromInteraction,
        action,
        timestamp,
        params: {toVideoId, fromVideoId} = {},
      } = {},
    } = {},
  } = useSubscription(VIDEO_CALL_SUBSCRIPTION, {skip: disabled});
  const myStreamIdRef = useRef('');
  const targetStreamIdRef = useRef('');
  const statusRef = useRef('');
  statusRef.current = videoChatStatus;
  const actionIdRef = useRef();

  /**
   * Was added in ref in order to prevent stop of timer in useEffect invalidation.
   */
  const callDurationRef = useRef(0);
  const {callDuration, stopTimer, runTimer} = useVideoChatTimer();
  const client = useApolloClient();
  const history = useHistory();
  const {navigateToVideoChat, navigateToPreviousRoute} = useRedirectVideoChat();

  const onStatusChange = useRef();

  const scheduleStateReset = useEventCallback(() => {
    onStatusChange.current = () => {
      resetStateTimeout = setTimeout(() => {
        resetState(setVideoChatStatus, setOpponentUserId);
      }, NOTIFICATION_LIFETIME);
    };
  });

  /**
   * Run video chat after two users gave all permissions.
   * @param {string} userId
   * @param {function} setVideoChatStatus
   */
  const runVideoChat = useCallback(
    (userId) => {
      if (!userId) return false;

      navigateToVideoChat(userId);
      setVideoChatStatus(VIDEO_CHAT_STATUSES.CALLING);
    },
    [navigateToVideoChat, setVideoChatStatus],
  );

  const sendRejectCall = useCallback(
    async (userId) => {
      /**
       * OpponentUserId sets after initiating call (session was created).
       * userId need in case when call wasn't initiated, but we need opponentUserId.
       * In case when we need reject not started call we use userId from props.
       * Usage example: reject call for inApp browser on outgoing call.
       * @see VideoChatButtonBase.js
       */
      const targetUserId = userId || opponentUserId;

      if (!targetUserId) {
        return false;
      }

      const {
        data: {
          privateVideo: {message},
        },
      } = await client.mutate({
        mutation: VIDEO_CALL_RESPONSE_MUTATION,
        variables: {
          userId: targetUserId,
          videoAction: VIDEO_CHAT_ACTIONS.REJECT,
        },
      });

      // Update statuses of video calls in messenger
      if (message) {
        updateStatusesOfVideoCallsInMessenger(targetUserId, message);
      }

      resetState(setVideoChatStatus, setOpponentUserId);
      setTargetStreamId(null);
      setMyStreamId(null);
    },
    [
      client,
      opponentUserId,
      setMyStreamId,
      setOpponentUserId,
      setTargetStreamId,
      setVideoChatStatus,
    ],
  );

  const sendAcceptCall = useCallback(async () => {
    if (!opponentUserId) return false;

    const response = await client.mutate({
      mutation: VIDEO_CALL_RESPONSE_MUTATION,
      variables: {
        userId: opponentUserId,
        videoAction: VIDEO_CHAT_ACTIONS.ACCEPT,
      },
    });

    const {result, redirectUrl} = response.data.privateVideo;

    if (!result) {
      resetState(setVideoChatStatus, setOpponentUserId);
      return false;
    }

    redirectIfNeeded(
      redirectUrl,
      history,
      setVideoChatStatus,
      setOpponentUserId,
    );

    if (VIDEO_CHAT_STATUSES.INCOMING !== statusRef.current) {
      resetState(setVideoChatStatus, setOpponentUserId);
      return false;
    }
    runVideoChat(opponentUserId, history, setVideoChatStatus);
  }, [
    runVideoChat,
    client,
    history,
    opponentUserId,
    setOpponentUserId,
    setVideoChatStatus,
  ]);

  const sendStopCall = useEventCallback(async () => {
    if (!opponentUserId) return false;

    const videoAction = callDurationRef.current
      ? VIDEO_CHAT_ACTIONS.END
      : VIDEO_CHAT_ACTIONS.STOP;

    const {
      data: {
        privateVideo: {message},
      },
    } = await client.mutate({
      mutation: VIDEO_CALL_RESPONSE_MUTATION,
      variables: {
        userId: opponentUserId,
        videoAction,
        duration: callDurationRef.current,
      },
    });

    // Update statuses of video calls in messenger
    if (message) {
      updateStatusesOfVideoCallsInMessenger(opponentUserId, message);
    }

    setTargetStreamId(null);
    setMyStreamId(null);
    endVideoChat(setVideoChatStatus, setOpponentUserId, stopTimer);
  });

  const sendStopCallByTimeout = useCallback(
    async (userId) => {
      if (!(opponentUserId || userId)) return false;

      const {
        data: {
          privateVideo: {message},
        },
      } = await client.mutate({
        mutation: VIDEO_CALL_RESPONSE_MUTATION,
        variables: {
          userId: userId || opponentUserId,
          videoAction: VIDEO_CHAT_ACTIONS.MISSED,
        },
      });

      // Update statuses of video calls in messenger
      if (message) {
        updateStatusesOfVideoCallsInMessenger(
          userId || opponentUserId,
          message,
        );
      }

      setVideoChatStatus(VIDEO_CHAT_STATUSES.NOANSWER);
      scheduleStateReset();
    },
    [client, opponentUserId, scheduleStateReset, setVideoChatStatus],
  );

  const sendLeaveCall = useCallback(() => {
    if (!opponentUserId) return false;
    setVideoChatStatus(VIDEO_CHAT_STATUSES.STOP);

    sendStopCall();
    navigateToPreviousRoute();
  }, [
    navigateToPreviousRoute,
    opponentUserId,
    sendStopCall,
    setVideoChatStatus,
  ]);

  const sendStartCall = useCallback(
    async (userId) => {
      if (!ALLOWED_STATUSES_FOR_STARTING_CALL.includes(statusRef.current)) {
        return false;
      }

      const {
        data: {user},
      } = await client.query({
        query: VIDEO_CHAT_START_INFO_QUERY,
        variables: {userId},
      });

      if (isNull(user)) return false;

      const {
        videoChat: {streamId, viewedVideoId},
      } = user;

      if (!ALLOWED_STATUSES_FOR_STARTING_CALL.includes(statusRef.current)) {
        return false;
      }

      setVideoChatStatus(VIDEO_CHAT_STATUSES.OUTGOING);
      setOpponentUserId(userId);

      const response = await client.mutate({
        mutation: VIDEO_CALL_RESPONSE_MUTATION,
        variables: {
          userId,
          videoAction: VIDEO_CHAT_ACTIONS.INVITATION,
          fromVideoId: streamId,
          toVideoId: viewedVideoId,
        },
      });

      const {result, redirectUrl, error} = response.data.privateVideo;

      handlePartnerNetworkError({error, userId});

      if (!result || statusRef.current !== VIDEO_CHAT_STATUSES.OUTGOING) {
        resetState(setVideoChatStatus, setOpponentUserId);
        return false;
      }

      redirectIfNeeded(
        redirectUrl,
        history,
        setVideoChatStatus,
        setOpponentUserId,
      );

      navigateToVideoChat(userId);

      if (timeoutOutgoingCall) {
        clearTimeout(timeoutOutgoingCall);
      }

      setMyStreamId(streamId);
      setTargetStreamId(viewedVideoId);

      timeoutOutgoingCall = delay(
        (savedStreamId, savedViewedVideoId, savedUserId) => {
          if (
            savedStreamId === myStreamIdRef.current &&
            savedViewedVideoId === targetStreamIdRef.current &&
            statusRef.current === VIDEO_CHAT_STATUSES.OUTGOING
          ) {
            sendStopCallByTimeout(savedUserId);
          }
        },
        OUTGOING_CALL_TIMEOUT,
        streamId,
        viewedVideoId,
        userId,
      );
    },
    [
      client,
      navigateToVideoChat,
      history,
      sendStopCallByTimeout,
      setMyStreamId,
      setOpponentUserId,
      setTargetStreamId,
      setVideoChatStatus,
    ],
  );

  useEffect(() => {
    myStreamIdRef.current = myStreamId;
  }, [myStreamId]);

  useEffect(() => {
    callDurationRef.current = callDuration;
  }, [callDuration]);

  useEffect(() => {
    targetStreamIdRef.current = targetStreamId;
  }, [targetStreamId]);

  useEffect(() => {
    resetStateTimeout && clearTimeout(resetStateTimeout);
    onStatusChange.current?.();
    onStatusChange.current = null;
  }, [videoChatStatus]);

  useEffect(() => {
    const newUniqueActionId = getActionUniqueId(action, timestamp);

    if (newUniqueActionId && actionIdRef.current === newUniqueActionId) {
      return;
    }

    actionIdRef.current = newUniqueActionId;

    if (
      isCurrentUserBusy(
        videoChatStatus,
        userIdFromInteraction,
        opponentUserId,
        action,
      )
    ) {
      sendBusyResponse(userIdFromInteraction, client);

      return;
    }

    switch (action) {
      case VIDEO_CHAT_ACTIONS.INVITATION:
        setVideoChatStatus(VIDEO_CHAT_STATUSES.INCOMING);
        setOpponentUserId(userIdFromInteraction);
        setMyStreamId(toVideoId);
        setTargetStreamId(fromVideoId);
        break;
      case VIDEO_CHAT_ACTIONS.ACCEPT:
        if (statusRef.current === VIDEO_CHAT_STATUSES.OUTGOING) {
          setOpponentUserId(userIdFromInteraction);
          setVideoChatStatus(VIDEO_CHAT_STATUSES.CALLING);
        }
        break;
      case VIDEO_CHAT_ACTIONS.MISSED:
        setVideoChatStatus(VIDEO_CHAT_STATUSES.MISSED);
        setOpponentUserId(userIdFromInteraction);
        scheduleStateReset();
        setTargetStreamId(null);
        setMyStreamId(null);
        break;
      case VIDEO_CHAT_ACTIONS.REJECT:
        setTargetStreamId(null);
        setMyStreamId(null);
        resetState(setVideoChatStatus, setOpponentUserId);
        break;
      case VIDEO_CHAT_ACTIONS.STOP:
      case VIDEO_CHAT_ACTIONS.END:
        if (videoChatStatus === VIDEO_CHAT_STATUSES.INCOMING) {
          setVideoChatStatus(VIDEO_CHAT_STATUSES.MISSED);
          scheduleStateReset();
        } else {
          setTargetStreamId(null);
          setMyStreamId(null);
          setVideoChatStatus(VIDEO_CHAT_STATUSES.STOP);
          endVideoChat(setVideoChatStatus, setOpponentUserId, stopTimer);
        }

        break;
      case VIDEO_CHAT_ACTIONS.BUSY:
        setVideoChatStatus(VIDEO_CHAT_STATUSES.OPPONENT_BUSY);
        setOpponentUserId(userIdFromInteraction);
        setTargetStreamId(null);
        setMyStreamId(null);
        break;
      default:
        break;
    }
  }, [
    action,
    timestamp,
    client,
    fromVideoId,
    history,
    opponentUserId,
    setMyStreamId,
    setOpponentUserId,
    setTargetStreamId,
    setVideoChatStatus,
    stopTimer,
    toVideoId,
    userIdFromInteraction,
    videoChatStatus,
    runVideoChat,
    scheduleStateReset,
  ]);

  return {
    rejectCall: sendRejectCall,
    acceptCall: sendAcceptCall,
    stopCall: sendStopCall,
    leaveCall: sendLeaveCall,
    startCallWithUser: sendStartCall,
    startVideoCallTimer: runTimer,
  };
};

export default useVideoChatControllers;
