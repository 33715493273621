import once from 'lodash/once';

import openConfirmationCoinsPopup from '@phoenix/confirmation/utils/openConfirmationCoinsPopup';

import COINS_EMAIL_VERIFICATION from '../graphql/queries/coinsEmailVerification.gql';
import OPEN_COINS_CONFIRMATION_POPUP_SUBSCRIPTION from '../graphql/subscriptions/openCoinsComfirmationPopup.gql';

/**
 * Initializes coins confirmation logic for likes.
 * @param client
 */
const startCoinsConfirmationPopupListener = once(async (client) => {
  const {
    data: {
      credits: {
        rewardForTasks: {tasks},
      },
    },
  } = await client.query({query: COINS_EMAIL_VERIFICATION});

  if (
    !tasks?.emailVerification?.enabled ||
    tasks?.emailVerification?.completed
  ) {
    return;
  }

  client
    .subscribe({
      query: OPEN_COINS_CONFIRMATION_POPUP_SUBSCRIPTION,
    })
    .subscribe(() => openConfirmationCoinsPopup());
});

export default startCoinsConfirmationPopupListener;
