import type {ReactElement} from 'react';
import React from 'react';
import type {
  QueryResult,
  QueryHookOptions,
  OperationVariables,
} from '@apollo/client';
import {useQuery} from '@apollo/client';
import type {DocumentNode} from 'graphql';

import type {ErrorBoundarySize} from '@core/graphql/types';

import ErrorBoundary from '../components/ErrorBoundary';

type QueryWithErrorBoundaryProps<TData, TVariables> = {
  children: (result: QueryResult<TData, TVariables>) => ReactElement;
  query: DocumentNode;
  errorTitle?: string;
  errorText?: string;
  fullHeight?: boolean;
  errorSize?: ErrorBoundarySize;
} & QueryHookOptions<TData, TVariables>;

/**
 * @class QueryWithErrorBoundary
 * @classdesc
 * Container for handling graphQl errors
 */
const QueryWithErrorBoundary = <
  TData,
  TVariables extends OperationVariables = OperationVariables,
>({
  query,
  children,
  errorSize,
  fullHeight = false,
  errorTitle,
  errorText,
  ...queryProps
}: QueryWithErrorBoundaryProps<TData, TVariables>): ReactElement => {
  const result = useQuery<TData, TVariables>(query, queryProps);

  if (result.error) {
    return (
      <ErrorBoundary
        error={result.error}
        errorSize={errorSize}
        title={errorTitle}
        text={errorText}
        fullHeight={fullHeight}
      />
    );
  }

  return children(result);
};

QueryWithErrorBoundary.ERROR_SIZE = ErrorBoundary.SIZE;

export default QueryWithErrorBoundary;
