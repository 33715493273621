import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import get from 'lodash/get';

import PopupService from '@core/popup/utils/PopupService';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import t from '@core/translations/translate';
import CURRENT_USER_ID from '@core/user/profile/current/graphql/queries/currentUserId.gql';

import {Button, ButtonPrimary} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import LIKE_GALLERY_ROUTES from '@phoenix/likeGallery/constants/route';
import {IconType} from '@phoenix/ui/constants';

import PERSONAL_IDENTITY_RESET_VALUES from '../graphql/mutations/personalIdentityResetValues.gql';

const PersonalIdentitySuccessPopup = ({onReset}) => {
  const history = useHistory();
  const {data, loading} = useQuery(CURRENT_USER_ID);
  const [resetValues] = useMutation(PERSONAL_IDENTITY_RESET_VALUES, {
    update() {
      onReset();
    },
  });

  useEffect(() => {
    // Reset values isNeedToShowPopup and documentStatus on server
    resetValues({
      variables: {
        userId: get(data, 'myUser.profile.id'),
      },
    });
  }, [data, resetValues]);

  const handleLikeGalleryClick = useCallback(() => {
    PopupService.closePopup();
    history.push(LIKE_GALLERY_ROUTES.LIKE_GALLERY);
  }, [history]);

  const handleSearchClick = useCallback(() => {
    PopupService.closePopup();
    history.push('/search');
  }, [history]);

  if (loading) return null;

  return (
    <PopupNotice
      icon="age-check"
      iconType={IconType.SUCCESS}
      title={t(
        'personalIdentityRestrictions',
        'text.verification_was_successful',
      )}
      actions={[
        <ButtonPrimary
          fullWidth
          onClick={handleLikeGalleryClick}
          trackingName="likeGallery"
        >
          {t('personalIdentityRestrictions', 'text.like_gallery')}
        </ButtonPrimary>,
        <Button fullWidth onClick={handleSearchClick} trackingName="search">
          {t('personalIdentityRestrictions', 'text.starting_activity')}
          <PopupLoadTracker />
        </Button>,
      ]}
      actionsReverse
    />
  );
};

PersonalIdentitySuccessPopup.propTypes /* remove-proptypes */ = {
  onReset: PropTypes.func.isRequired,
};

export default PersonalIdentitySuccessPopup;
