import type {FC} from 'react';
import React, {Fragment} from 'react';
import cn from 'classnames';

import css from '../styles/ViewAllButtonText.css';

type ViewAllButtonTextProps = {
  text?: string;
  subText?: string;
  withOverflow?: boolean;
};

const ViewAllButtonText: FC<ViewAllButtonTextProps> = ({
  text,
  subText,
  withOverflow = true,
}) => (
  <Fragment>
    <div className={cn(css.text, withOverflow && css.withOverflow)}>{text}</div>
    {subText && <div className={css.subText}>{subText}</div>}
  </Fragment>
);

export default ViewAllButtonText;
