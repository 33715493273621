import showRedirectUrl from '@core/payment/payProcess/utils/showRedirectUrl';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

/**
 * Need for output to be sure that isn't server 303 redirect.
 * Mostly for QA purpose.
 */
const showRedirectUrlInConsoleIfNeed: PaymentProcessStep = async (data) => {
  const {paymentResult} = data;

  showRedirectUrl(paymentResult.redirectUrl);

  return data;
};

export default showRedirectUrlInConsoleIfNeed;
