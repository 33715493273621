import React from 'react';

import CommonFormSelect from '@core/form/select';

import {Select} from '@phoenix/ui';

const FormSelect = (props) => (
  <CommonFormSelect {...props} selectComponent={Select} />
);

export default FormSelect;
