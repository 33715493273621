import {useCallback} from 'react';

import processTextMask from '@core/widgets/input/processTextMask';

/**
 * Use text mask for fields like chat up line
 * @param {Function} onChange
 * @return {Function} - new onChange function
 */
const useTextFieldMask = (onChange) =>
  useCallback(
    (event, inputValue = '') => {
      const data = processTextMask(inputValue);
      // Useful for formik because it doesn't know about custom second onChange argument.
      if (data !== inputValue) {
        event.target.value = data;
      }

      onChange && onChange(event, data);
    },
    [onChange],
  );

export default useTextFieldMask;
