import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyPayContract = lazy(
  () =>
    import(
      /* webpackChunkName: 'PayContractMob' */ '../routes/mob/PayContractRoute'
    ),
);

const PayContractChunk = (props) => (
  <SplitChunk component={LazyPayContract} {...props} />
);

export default PayContractChunk;
