import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import {OPEN_PP_POPUP_WEEK_SPEAK} from '@phoenix/coins/buyCoins/constants/vias';
import getCoinsSpecialOfferStepsAfterPayment from '@phoenix/payment/payProcess/utils/getCoinsSpecialOfferStepsAfterPayment';
import getCoinsBuyOfferStepsAfterPayment from '@phoenix/payment/payProcess/utils/getCoinsBuyOfferStepsAfterPayment';

const nextCoinsBuyOfferStepAfterPayment: PaymentProcessStep = async (data) => {
  const {paymentResult, paymentData} = data;

  if (paymentData.prevVia === OPEN_PP_POPUP_WEEK_SPEAK) {
    getCoinsSpecialOfferStepsAfterPayment(paymentResult);
  } else {
    getCoinsBuyOfferStepsAfterPayment(data);
  }

  return data;
};

export default nextCoinsBuyOfferStepAfterPayment;
