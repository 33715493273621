import VerificationStatus from '@phoenix/personalIdentity/constants/verificationStatus';
import PERSONAL_IDENTITY_QUERY from '@phoenix/personalIdentity/graphql/queries/personalIdentity.gql';

/**
 * Personal identity not verified access rule.
 */
export default {
  errorCode: 'PERSONAL_IDENTITY_NOT_VERIFIED',
  query: PERSONAL_IDENTITY_QUERY,
  resolver: (data) => {
    const {personalIdentity} = data.userFeatures || {};
    return (
      !personalIdentity ||
      !personalIdentity.required ||
      personalIdentity.verificationStatus === VerificationStatus.APPROVED
    );
  },
};
