import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Bar, Icon, RowLarge, Spacing} from '@phoenix/ui';
import {Text} from '@phoenix/typography';
import {SpacingDirection, SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/TrustedVerificationPopupHeader.css';

/**
 * Wrapper with static text in the sidebar
 */
const TrustedVerificationPopupHeader = ({
  isPhone,
  spacedMotivation = false,
}) => (
  <Spacing
    direction={SpacingDirection.HORIZONTAL}
    size={spacedMotivation && !isPhone ? SpacingSize.NORMAL : SpacingSize.NONE}
  >
    <Bar withoutBottom asPopupHeading>
      <Spacing
        onlyRight
        adaptive={false}
        size={!spacedMotivation ? SpacingSize.LARGE : SpacingSize.NONE}
      >
        <Spacing className={css.info}>
          <div className={css.infoWrap}>
            <Icon type="trusted" standard />
            <RowLarge horizontal>
              <Text>{t('paymentTrusted', 'text.motivation.popup')}</Text>
            </RowLarge>
          </div>
        </Spacing>
      </Spacing>
    </Bar>
  </Spacing>
);

TrustedVerificationPopupHeader.propTypes /* remove-proptypes */ = {
  isPhone: PropTypes.bool.isRequired,
  spacedMotivation: PropTypes.bool,
};

export default TrustedVerificationPopupHeader;
