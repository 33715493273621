import type {FC} from 'react';
import React, {useRef, useEffect} from 'react';
import cn from 'classnames';

import t from '@core/translations/translate';

import {Overlay, ButtonPay, Spacing} from '@phoenix/ui';
import {OverlaySaturation} from '@phoenix/ui/constants';
import PayButtonLink from '@phoenix/payment/banners/upgradeButton/components/PayButtonLink';
import {Text} from '@phoenix/typography';
import type {ButtonType} from '@phoenix/ui/constants';

import type {VideoScaling} from './Video';
import Video, {VideoPreload} from './Video';
import PlayButton from './PlayButton';
import css from '../styles/VideoPreviewWithOverlayLayout.css';

type VideoPreviewWithOverlayLayoutProps = {
  videoPreviewUrl: string;
  description: string | null;
  scaling: VideoScaling;
  payButtonText?: string;
  payButtonUrl?: string;
  showPlayButton: boolean;
  centered?: boolean;
  buttonType: ButtonType;
  spacedActions?: boolean;
  withAutoplay?: boolean;
};

const VideoPreviewWithOverlayLayout: FC<VideoPreviewWithOverlayLayoutProps> = ({
  videoPreviewUrl,
  scaling,
  description,
  payButtonText,
  payButtonUrl,
  showPlayButton,
  centered,
  buttonType,
  spacedActions,
  withAutoplay,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  let buttonPay = null;
  if (payButtonUrl) {
    buttonPay = (
      <PayButtonLink fullWidth href={payButtonUrl}>
        {payButtonText}
      </PayButtonLink>
    );
  }
  if (payButtonText) {
    buttonPay = <ButtonPay fullWidth>{payButtonText}</ButtonPay>;
  }

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (
        video?.readyState >= 3 && // Enough data to play
        video.paused &&
        !document.hidden &&
        withAutoplay
      ) {
        video.play();
      }
    };

    if (video) {
      // Fix autoplay on IOS and some Android devices
      playVideo();

      // Listen for the canplay event to replay if the video is not playing after 'visibilitychange'.
      video.addEventListener('canplay', playVideo);
    }

    // Fix playing videos after visibilitychange on IOS and some Android devices
    document.addEventListener('visibilitychange', playVideo);

    return () => {
      video?.removeEventListener('canplay', playVideo);
      document.removeEventListener('visibilitychange', playVideo);
    };
  }, [withAutoplay]);

  return (
    <div className={css.wrapper}>
      <div
        className={cn(
          css.videoWrap,
          !showPlayButton && [css[scaling], css.blurred],
        )}
      >
        <Video
          className={css.video}
          url={videoPreviewUrl}
          scaling={scaling}
          autoPlay
          preload={VideoPreload.AUTO}
          loop
          playsInline
          muted
          ref={videoRef}
        />
      </div>
      {!showPlayButton && <Overlay saturation={OverlaySaturation.LITE} />}
      {centered ? (
        <div className={css.contentCentered}>
          {!showPlayButton && (
            <div className={cn(buttonPay && css.titleWrapper)}>
              <Text inverse className={css.title}>
                {buttonPay
                  ? t('photoGallery', 'text.tap_view_all_videos')
                  : description}
              </Text>
            </div>
          )}
          <div className={cn(css.actions, spacedActions && css.spacedActions)}>
            {buttonPay}
            {showPlayButton && <PlayButton type={buttonType} />}
          </div>
        </div>
      ) : (
        <div className={css.content}>
          <Spacing onlyBottom>
            <Text inverse>
              {description || t('messengerMessages', 'text.wanna_see_video')}
            </Text>
          </Spacing>
          {buttonPay}
        </div>
      )}
    </div>
  );
};

export default VideoPreviewWithOverlayLayout;
