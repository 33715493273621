import type {FC, SyntheticEvent} from 'react';
import React from 'react';
import cn from 'classnames';

import {Group, Tag, ButtonFlat} from '@phoenix/ui';
import {TextMuted} from '@phoenix/typography';
import {SpacingSize, TagType} from '@phoenix/ui/constants';

import css from '../styles/MediaCounters.css';

type MediaCountersProps = {
  photoCount?: number;
  videoCount?: number;
  className?: string;
  itemClass?: string;
  onClick?: (event: SyntheticEvent) => void;
  type?: TagType;
  vertical?: boolean;
};

const MediaCounters: FC<MediaCountersProps> = ({
  photoCount,
  videoCount,
  className,
  itemClass,
  vertical,
  onClick,
  type = TagType.TRANSLUCENT,
}) => {
  const hasVideoAndPhoto = Boolean(videoCount && photoCount);

  const showPhotoCounter = photoCount > 1 || hasVideoAndPhoto;

  const showVideoCounter = videoCount > 1 || hasVideoAndPhoto;

  if (!showVideoCounter && !showPhotoCounter) {
    return null;
  }

  if (vertical) {
    return (
      <Group space={SpacingSize.SHORT}>
        {showVideoCounter && (
          <ButtonFlat
            narrow
            inverse
            icon="videochat"
            onClick={onClick}
            trackingName="videoCounter"
            className={itemClass}
          >
            <TextMuted inverse small>
              {videoCount}
            </TextMuted>
          </ButtonFlat>
        )}
        {showPhotoCounter && (
          <ButtonFlat
            narrow
            inverse
            icon="photo"
            onClick={onClick}
            trackingName="photoCounter"
            className={itemClass}
          >
            <TextMuted inverse small>
              {photoCount}
            </TextMuted>
          </ButtonFlat>
        )}
      </Group>
    );
  }

  return (
    <div className={className}>
      {showVideoCounter && (
        <div className={css.item}>
          <Tag
            icon="video"
            type={type}
            className={cn(css.inner, itemClass)}
            data-test="videoCounter"
          >
            {videoCount}
          </Tag>
        </div>
      )}
      {showPhotoCounter && (
        <div className={css.item}>
          <Tag
            icon="photo"
            type={type}
            className={cn(css.inner, itemClass)}
            data-test="photoCounter"
          >
            {photoCount}
          </Tag>
        </div>
      )}
    </div>
  );
};

export default MediaCounters;
