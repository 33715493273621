import React from 'react';

import WebcamVideoCaptureDisplayer from '@core/mediaUpload/containers/WebcamVideoCaptureDisplayer';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import WebcamVideoCaptureLayout from './WebcamVideoCaptureLayout';

const WebcamVideoCapture = (props) => (
  <WebcamVideoCaptureDisplayer
    errorBoundaryComponent={ErrorBoundary}
    webcamVideoCaptureLayoutComponent={WebcamVideoCaptureLayout}
    {...props}
  />
);

export default WebcamVideoCapture;
