import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import decimalAdjust from '@core/utils/number/decimalAdjust';
import shiftDateByDays from '@core/date/utils/shiftDateByDays';
import formatDate from '@core/date/utils/formatDate';

import ThemedBannerWrapper from '@phoenix/theming/components/ThemedBannerWrapper';
import {List, ListItem, H3, TextMuted, Text} from '@phoenix/typography';
import {
  Icon,
  Row,
  RowShort,
  RowLarge,
  Spacing,
  SpacingLarge,
  Label,
} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {
  IconSize,
  SpacingDirection,
  Align,
  SpacingSize,
} from '@phoenix/ui/constants';

import {DAYS_TO_PAY, DATE_FORMAT} from '../constants/debtorConstants';
import css from '../styles/SepaDebtorLayout.css';

const SepaDebtorLayout = ({debtorData, actions}) => {
  const {
    date,
    saleTransactionReference,
    fee: {
      currencyCode: feeCurrencyCode,
      formatted: {amount: feeAmount},
    },
    money: {
      currencyCode,
      formatted: {amount: openAmount},
    },
    debtorContact: {phoneNumber, email},
    bankName,
    iban,
    bic,
  } = debtorData;
  const debtDate = formatDate(date, DATE_FORMAT);
  const payDay = formatDate(shiftDateByDays(date, DAYS_TO_PAY), DATE_FORMAT);
  const amount = decimalAdjust(Number(openAmount) + Number(feeAmount));
  // We have to do it because integration tests run on a different port
  const siteOrigin = `${window.location.protocol}//${window.location.hostname}`;

  return (
    <PopupFrame
      actionWithTopMargin={false}
      spacedContent={false}
      content={
        <div data-test="sepaDebtorLayout">
          <ThemedBannerWrapper shadowLevel={0} danger>
            <Row align={Align.CENTER}>
              <Spacing direction={SpacingDirection.VERTICAL}>
                <SpacingLarge direction={SpacingDirection.HORIZONTAL}>
                  <div className={css.icon}>
                    <Icon danger size={IconSize.SIZE_48} type="alert" />
                  </div>
                  <div className={css.unit}>
                    <Text bold type={Text.TYPE.DANGER}>
                      {t('sepaDebtor', 'text.unpaid_order', {
                        '{date}': debtDate,
                        '{siteName}': siteOrigin,
                      })}
                    </Text>
                  </div>
                </SpacingLarge>
              </Spacing>
            </Row>
          </ThemedBannerWrapper>
          <SpacingLarge>
            <RowLarge>
              <H3>{t('sepaDebtor', 'text.following_payments')}</H3>
            </RowLarge>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.tid')}
              </Label>
              <div>{saleTransactionReference}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.date')}
              </Label>
              <div>{debtDate}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.open_amount')}
              </Label>
              <div>{`${openAmount} ${currencyCode}`}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.fee')}
              </Label>
              <div>{`${feeAmount} ${feeCurrencyCode}`}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.total_amount')}
              </Label>
              <Text bold type={Text.TYPE.DANGER}>
                {`${amount} ${currencyCode}`}
              </Text>
            </RowShort>
            <RowLarge>
              {t('sepaDebtor', 'text.to_avoid', {
                '{amount}': amount,
                '{currency}': currencyCode,
                '{tid}': saleTransactionReference,
                '{date}': payDay,
              })}
            </RowLarge>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.iban')}
              </Label>
              <div>{iban}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.bic')}
              </Label>
              <div>{bic}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.bank')}
              </Label>
              <div>{bankName}</div>
            </RowShort>
            <RowShort className={css.row}>
              <Label className={css.label}>
                {t('sepaDebtor', 'title.reference')}
              </Label>
              <div>
                {`${t('sepaDebtor', 'title.tid')} ${saleTransactionReference}`}
              </div>
            </RowShort>
          </SpacingLarge>
          <SpacingLarge className={css.footer}>
            <RowLarge>
              <H3>{t('sepaDebtor', 'text.important_note')}</H3>
            </RowLarge>
            <RowLarge>
              <div className={css.block}>
                <div className={css.wrapper}>
                  <List spacingSize={SpacingSize.LARGE} bulleted>
                    <ListItem>
                      {t('sepaDebtor', 'text.note_1', {
                        '{mailto}': (
                          <a
                            href={`mailto:${email}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {email}
                          </a>
                        ),
                      })}
                    </ListItem>
                    <ListItem>{t('sepaDebtor', 'text.note_2')}</ListItem>
                    <ListItem>{t('sepaDebtor', 'text.note_3')}</ListItem>
                  </List>
                </div>
              </div>
            </RowLarge>
            <TextMuted small className={css.contacts}>
              <div className={css.block}>
                <div className={css.data}>
                  <div>
                    {t('sepaDebtor', 'text.phoneNumber', {
                      '{telLink}': (
                        <a
                          href={`tel:${phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {phoneNumber}
                        </a>
                      ),
                    })}
                  </div>
                  <div>
                    {t('sepaDebtor', 'text.email', {
                      '{mailto}': (
                        <a
                          href={`mailto:${email}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {email}
                        </a>
                      ),
                    })}
                  </div>
                </div>
              </div>
              <div className={css.spacing}>
                <div>
                  <div>
                    {t('sepaDebtor', 'text.footerNew', {
                      '{telLink}': (
                        <a
                          href={`tel:${phoneNumber}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {phoneNumber}
                        </a>
                      ),
                    })}
                  </div>
                </div>
              </div>
            </TextMuted>
          </SpacingLarge>
        </div>
      }
      actions={actions}
    />
  );
};

SepaDebtorLayout.propTypes /* remove-proptypes */ = {
  debtorData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    fee: PropTypes.shape({
      formatted: PropTypes.shape({
        amount: PropTypes.string.isRequired,
      }),
      currencyCode: PropTypes.string.isRequired,
    }),
    money: PropTypes.shape({
      formatted: PropTypes.shape({
        amount: PropTypes.string.isRequired,
      }),
      currencyCode: PropTypes.string.isRequired,
    }),
    saleTransactionReference: PropTypes.string.isRequired,
    debtorContact: PropTypes.shape({
      email: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string.isRequired,
    }),
    userId: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    iban: PropTypes.string.isRequired,
    bic: PropTypes.string.isRequired,
  }),
  actions: PropTypes.node.isRequired,
};

export default SepaDebtorLayout;
