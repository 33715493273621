import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import SECTION_TEST_NAME from '@core/user/profile/common/constants/SectionTestName';
import useMediaListWithShowMore from '@core/user/profile/common/utils/useMediaListWithShowMore';
import photoPropType from '@core/user/profile/common/utils/photoPropType';
import BabciaScopedProvider from '@core/tracking/babcia/containers/BabciaScopedProvider';
import MEDIA_ITEMS_GRID from '@core/user/profile/common/constants/MediaItemsGrid';

import openPhotoUploadPopup from '@phoenix/mediaUpload/utils/openPhotoUploadPopup';
import DisapproveNotice from '@phoenix/disapprove/components/DisapproveNotice';
import {Text} from '@phoenix/typography';

import SectionLayout from '../../common/components/SectionLayout';
import css from '../styles/CurrentUserPhotoListLayout.css';
import PhotoList from '../../common/containers/PhotoList';
import MediaUploadButton from './MediaUploadButton';

const CurrentUserPhotoMotivationListLayout = ({
  children,
  disapprovePhoto,
  photos,
  ...restProps
}) => {
  const {photoList, mediaCounter, showMorePhoto} = useMediaListWithShowMore({
    photos,
    withLoadMore: true,
    visibleMediaCount: 5,
  });

  return (
    <BabciaScopedProvider context="photoList">
      <SectionLayout
        data-test={SECTION_TEST_NAME.PHOTO_LIST}
        spaced={false}
        withIcon={false}
        disapproveType={disapprovePhoto && DisapproveNotice.TYPE.PHOTO}
        onDisapprovedClick={openPhotoUploadPopup}
      >
        <div className={css.mediaTwoColumnList}>
          <MediaUploadButton
            text={t('profileBase', 'text.add_photos')}
            onClick={() => {}}
            data-test="addPhoto"
            showText
            bold={false}
            type={Text.TYPE.MUTED}
            grid={MEDIA_ITEMS_GRID.THREE_COLUMNS}
          />
          <PhotoList
            my
            withActions={false}
            photos={photoList}
            showMore={showMorePhoto}
            mediaCounter={showMorePhoto && mediaCounter ? mediaCounter : 0}
            grid={MEDIA_ITEMS_GRID.THREE_COLUMNS}
            {...restProps}
          />
          {children}
        </div>
      </SectionLayout>
    </BabciaScopedProvider>
  );
};

CurrentUserPhotoMotivationListLayout.propTypes /* remove-proptypes */ = {
  photos: PropTypes.arrayOf(photoPropType.isRequired),
  disapprovePhoto: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default CurrentUserPhotoMotivationListLayout;
