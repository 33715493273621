import React, {useCallback} from 'react';

import PopupService from '@core/popup/utils/PopupService';
import t from '@core/translations/translate';
import useRedirectToDefaultPage from '@core/utils/routing/useRedirectToDefaultPage';

import {ButtonPrimary} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

const BlockedStreamingForUserPopup = () => {
  const redirectToDefaultPage = useRedirectToDefaultPage({replace: true});
  const handleClick = useCallback(() => {
    PopupService.closePopup();
    redirectToDefaultPage();
  }, [redirectToDefaultPage]);

  return (
    <PopupNotice
      icon="danger"
      data-test="BlockedStreamingForUserPopup"
      title={t('streaming', 'title.blockedUserInfo')}
      subTitle={t('streaming', 'text.blockedUserInfo')}
      actions={[
        <ButtonPrimary
          fullWidth
          onClick={handleClick}
          trackingName="closePopup"
        >
          {t('streaming', 'button.ok')}
        </ButtonPrimary>,
      ]}
    />
  );
};

export default BlockedStreamingForUserPopup;
