import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyStaticPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'staticPage' */
      '@phoenix/staticPages/containers/StaticPage'
    ),
);

export default (props) => <SplitChunk component={LazyStaticPage} {...props} />;
