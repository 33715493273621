import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

import updateLikeGallery from './updateLikeGallery';
import {
  getLikeGalleryUserIds,
  setLikeGalleryUserIds,
} from './likeGalleryStateHelper';

/**
 *
 * @param userId
 * @param removeOnlyUnmarked - if true we can remove only user which wasn't liked/skipped
 * @return {Promise<undefined>}
 */
/* eslint-disable consistent-return */
const removeUserFromLikeGallery = async (
  userId,
  removeOnlyUnmarked = false,
) => {
  if (isEmpty(userId)) return false;

  const {current: currentUserId} = getLikeGalleryUserIds();
  // We must clear id if we remove current user
  currentUserId === userId && setLikeGalleryUserIds(null);

  return updateLikeGallery(({users, currentIndex}) => {
    let removedIndex = null;

    const newUsers = filter(users, (user, index) => {
      const isRemovedUser = userId === user.id;
      if (isRemovedUser) {
        removedIndex = index;
      }
      return !isRemovedUser;
    });

    let newCurrentIndex = currentIndex;

    if (removedIndex !== null) {
      const isMarkedUser = removedIndex < currentIndex;
      if (removeOnlyUnmarked && isMarkedUser) return false;

      // To show same current user if we remove previous one decrease index
      if (isMarkedUser) {
        newCurrentIndex = currentIndex - 1;
      }

      return {users: newUsers, currentIndex: newCurrentIndex};
    }

    return false;
  });
};

export default removeUserFromLikeGallery;
