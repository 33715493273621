import {useEffect} from 'react';
import debounce from 'lodash/debounce';

import {TOP_WRAPPER} from '@core/header/constants/header';
import DEBOUNCE_DELAY from '@core/header/constants/delayKey';

import getTopWrapperHeight from './getTopWrapperHeight';
import setTopWrapperHeightVariable from './setTopWrapperHeightVariable';

/* eslint-disable consistent-return */
const useCSSTopWrapperHeight = () => {
  useEffect(() => {
    /**
     * Need use topWrapper to make correct margin for sticked notification with fixed position.
     * Notification appear in topWrapper, and we can correctly calculate StickyPolyfill height
     * @see StickedPositioner.js
     */
    const topWrapper = document.getElementById(TOP_WRAPPER);

    /**
     * There is a case when mutation was never called during appliction bootstrap.
     * for example on fullscreen routes such as 1-1 video chats, video rooms, etc.
     */
    if (topWrapper) {
      const height = getTopWrapperHeight(topWrapper);

      setTopWrapperHeightVariable(height);

      /**
       * in cases when styles lazy loaded
       * topWrapper rendered without styles and has incorrect height
       */
      const timeout = setTimeout(() => {
        const updatedHeight = getTopWrapperHeight(topWrapper);

        if (height !== updatedHeight) {
          setTopWrapperHeightVariable(updatedHeight);
        }
      }, DEBOUNCE_DELAY);

      const config = {childList: true, subtree: true};
      const callback = debounce(() => {
        setTopWrapperHeightVariable(getTopWrapperHeight(topWrapper));
      }, DEBOUNCE_DELAY);
      const mutationObserver = new MutationObserver(callback);

      mutationObserver.observe(topWrapper, config);

      return () => {
        mutationObserver.disconnect();
        callback.cancel();

        setTopWrapperHeightVariable(0);
        clearTimeout(timeout);
      };
    }
  }, []);
};

export default useCSSTopWrapperHeight;
