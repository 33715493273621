import PAYMENT_ACCOUNT_STATUS from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';

import EXTRA_EVENTS_SPIN_NOW_QUERY from './graphql/queries/extraEventsSpinNow.gql';
import FREE_DAILY_SPIN_QUERY from '../../../freeDailySpin/graphql/queries/freeDailySpin.gql';
import openFreeDailySpinPopup from '../../../freeDailySpin/utils/openFreeDailySpinPopup';

/**
 * Open free daily spin popup
 * @param processId
 * @param client
 * @returns {void}
 */
const spinNowPopupAction = async ({processId, client}) => {
  const {
    data: {
      payment: {
        accountStatus: {isPaid},
      },
    },
  } = await client.query({
    query: PAYMENT_ACCOUNT_STATUS,
  });

  if (isPaid) return;

  const {
    data: {
      extraPopups: {
        spinNow: {enabled, expiredAt},
      },
    },
  } = await client.query({
    query: EXTRA_EVENTS_SPIN_NOW_QUERY,
    variables: {processId},
  });

  if (!enabled) return;

  const {data} = await client.query({
    query: FREE_DAILY_SPIN_QUERY,
    fetchPolicy: 'network-only',
  });

  if (data?.spin?.availableSpinCount) {
    await openFreeDailySpinPopup(expiredAt);
  }
};

export default spinNowPopupAction;
