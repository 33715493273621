import getMicrofeaturesList from './getMicrofeaturesList';

/**
 * @param {String} modelType - one of MODEL_TYPES
 * @param {Object} microfeaturesList - result of MicrofeaturesListQuery
 * @param {String} featureName - one of MICROFEATURES in microfeatures/common/constants/microfeatures
 * @returns {Object}
 */
const getMicrofeatureData = (modelType, microfeaturesList, featureName) => {
  return (
    getMicrofeaturesList(modelType, microfeaturesList).find(
      ({featureName: currentFeatureName}) => currentFeatureName === featureName,
    ) || {}
  );
};

export default getMicrofeatureData;
