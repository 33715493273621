import type {FC, MouseEventHandler} from 'react';
import React from 'react';
import cn from 'classnames';

import {Button} from '@phoenix/ui';
import {ButtonType} from '@phoenix/ui/constants';

import css from '../styles/PlayButton.css';

type PlayButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  centered?: boolean;
  type?: ButtonType;
};

/**
 * Photo level overlay over user photo.
 * Shows info (or not) about changing level to see photo
 */
const PlayButton: FC<PlayButtonProps> = ({
  onClick,
  className,
  centered = true,
  type = ButtonType.FLAT_COVER,
}) => (
  <div className={cn(className, css.play, centered && css.centered)}>
    <Button
      className={css.action}
      type={type}
      data-test="playButton"
      icon="play"
      round
      inverse
      onClick={onClick}
      trackingName={onClick ? 'play' : null}
    />
  </div>
);

export default PlayButton;
