import React from 'react';
import PropTypes from 'prop-types';
import first from 'lodash/first';

import useRandomUsers from '@phoenix/search/list/utils/useRandomUsers';

import InAppMotivationPopup from './InAppMotivationPopup';
import MOTIVATION_TYPES from '../constants/motivationTypes';
import {GOOGLE_TYPE, INTENT_TYPE} from '../utils/formatLink';

const InAppMotivationPopupWithRandomUser = ({type, ...props}) => {
  const {randomUsers: users, loading} = useRandomUsers();

  const user = first(users);

  return (
    <InAppMotivationPopup
      {...props}
      user={user}
      loading={loading}
      type={type}
    />
  );
};

InAppMotivationPopupWithRandomUser.propTypes /* remove-proptypes */ = {
  type: PropTypes.oneOf(Object.values(MOTIVATION_TYPES)).isRequired,
  linkType: PropTypes.oneOf([GOOGLE_TYPE, INTENT_TYPE]),
  alterBtns: PropTypes.bool,
  loading: PropTypes.bool,
};

export default InAppMotivationPopupWithRandomUser;
