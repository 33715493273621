import createTranslationsMap from '@core/translations/createTranslationsMap';
import PHOTO_LEVEL from '@core/user/photo/constants/PhotoLevel';

import {PHOTO, VIDEO, STICKER} from './naughtyModePopupWidgetTypes';

export const LEVEL_TRANSLATIONS = createTranslationsMap((t) => ({
  [PHOTO_LEVEL.BLURRED]: t('popupNaughtyMode', 'text.Sexy'),
  /**
   * Same translate with PHOTO_LEVEL.BLURRED because split logic has only 2 modes for turn on
   */
  [PHOTO_LEVEL.SASSY]: t('popupNaughtyMode', 'text.Sexy'),
  [PHOTO_LEVEL.HIGHLY_BLURRED]: t('popupNaughtyMode', 'text.No Limits'),
}));

export const LEVEL_TRANSLATIONS_CLEAN = createTranslationsMap((t) => ({
  [PHOTO_LEVEL.BLURRED]: t('popupNaughtyMode', 'text.Flirty'),
  /**
   * Same translate with PHOTO_LEVEL.BLURRED because split logic has only 2 modes for turn on
   */
  [PHOTO_LEVEL.SASSY]: t('popupNaughtyMode', 'text.Flirty'),
  [PHOTO_LEVEL.HIGHLY_BLURRED]: t('popupNaughtyMode', 'text.No Limits'),
}));

export const DESCRIPTION_TRANSLATIONS = createTranslationsMap((t) => ({
  [PHOTO]: t('popupNaughtyMode', 'text.description'),
  [VIDEO]: t('popupNaughtyMode', 'text.description_video'),
  [STICKER]: t('popupNaughtyMode', 'text.description_sticker'),
}));
