import type {FC, ChangeEvent} from 'react';
import React from 'react';
import {useReactiveVar} from '@apollo/client';

import isInstantRepeatCheckedVar from '@core/graphql/vars/isInstantRepeatCheckedVar';

import {Checkbox} from '@phoenix/ui';
import {VerticalAlign} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';

import useCoinsAutofillAgreement from '../utils/useCoinsAutofillAgreement';
import FooterRow from '../components/FooterRow';

const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  isInstantRepeatCheckedVar(event.target.checked);
};

const PaymentFooterCoinsAutofillAgreement: FC = () => {
  const isInstantRepeatChecked = useReactiveVar<boolean | null>(
    isInstantRepeatCheckedVar,
  );

  const {available, loading, text} = useCoinsAutofillAgreement();

  if (!available || loading) {
    return null;
  }

  return (
    <FooterRow>
      <Checkbox
        name="isInstantRepeat"
        onChange={handleChange}
        checked={isInstantRepeatChecked || false}
        muted
        inline
        verticalAlign={VerticalAlign.CENTER}
      >
        <TextMuted small align={TextMuted.ALIGN.LEFT}>
          {text}
        </TextMuted>
      </Checkbox>
    </FooterRow>
  );
};

export default PaymentFooterCoinsAutofillAgreement;
