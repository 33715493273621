import once from 'lodash/once';

import {getClientInstance} from '@core/graphql/client';
import FREE_VIEWS_REWARD_FRAGMENT from '@core/messenger/common/graphql/fragments/freeViewsReward.gql';
import type {FreeViewsRewardFragment} from '@core/messenger/common/graphql/fragments/freeViewsReward';

import FREE_VIEWS_REMAINING_SUBSCRIPTION from '../../messages/graphql/subscriptions/freeViewsRemaining.gql';
import type {FreeViewsRemainingSubscription} from '../../messages/graphql/subscriptions/freeViewsRemaining';
import {handlePhotoReward} from '../../messages/utils/photoRewardHandler';

const REWARD = {
  id: 'FreeViewsReward:{"type":"firstPhotoReward"}',
  fragment: FREE_VIEWS_REWARD_FRAGMENT,
  fragmentName: 'FreeViewsReward',
};

let rewardHandled = false;

const startPhotoRewardListener = once(() => {
  const client = getClientInstance();

  getClientInstance()
    .subscribe<FreeViewsRemainingSubscription>({
      query: FREE_VIEWS_REMAINING_SUBSCRIPTION,
    })
    .subscribe(async ({data}) => {
      if (rewardHandled) return;

      rewardHandled = true;

      const reward = client.readFragment<FreeViewsRewardFragment>(REWARD);

      if (!reward || reward.activated) {
        return;
      }

      const {remainingCount} = data.freeViews;

      if (remainingCount) {
        await handlePhotoReward();
      }

      client.writeFragment<FreeViewsRewardFragment>({
        ...REWARD,
        data: {...reward, activated: true},
      });
    });
});

export default startPhotoRewardListener;
