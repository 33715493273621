import {makeVar} from '@apollo/client';

const DEFAULT_STATE = false;

/**
 * The footer has different access in different themes.
 * Also, the indentation can be different depending on the functionality.
 * Therefore, at the moment it is easier to manage through ReactiveVar.
 */
const isSpacedActionVar = makeVar(DEFAULT_STATE);

export const resetActionSpacing = () => isSpacedActionVar(DEFAULT_STATE);

export default isSpacedActionVar;
