import {createContext} from 'react';

/**
 * Provides the value for `rootMargin` option of `useInView` {@see PhotoLayout}.
 * Is used to customize lazy photos loading if needed.
 */
const LazyLoadMarginContext = createContext(
  /**
   * `undefined` to avoid overriding default `lazyLoadMargin` in {@see PhotoLayout} if this context isn't provided.
   * @see Photo
   */
  // eslint-disable-next-line no-undefined
  undefined,
);

export default LazyLoadMarginContext;
