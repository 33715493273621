import type {Client} from '@core/graphql/types';
import {getClientInstance} from '@core/graphql/client';
import type {Direction} from '@core/types/graphql';

import LAST_ACTIVITY_QUERY from '../graphql/queries/lastActivity.gql';
import type {
  LastActivityQuery,
  LastActivityQueryVariables,
} from '../graphql/queries/lastActivity';
import ADDITIONAL_MOTIVATION_ENABLED from '../graphql/queries/additionalMotivationEnabled.gql';
import type {AdditionalMotivationEnabledQuery} from '../graphql/queries/additionalMotivationEnabled';

type LastActivityData = {
  client: Client;
  userId: string;
  activityType: string;
  direction?: Direction;
};

/**
 * Change last activity date
 */
const changeLastActivityData = async ({
  client = getClientInstance(),
  userId,
  activityType,
  direction,
}: LastActivityData) => {
  const {data: additionalMotivationData} =
    await client.query<AdditionalMotivationEnabledQuery>({
      query: ADDITIONAL_MOTIVATION_ENABLED,
    });

  if (
    !additionalMotivationData.messenger.initialData
      .isAdditionalMotivationEnabled
  ) {
    return;
  }

  const {data} = await client.query<
    LastActivityQuery,
    LastActivityQueryVariables
  >({
    query: LAST_ACTIVITY_QUERY,
    variables: {recipientId: userId},
  });

  client.writeQuery<LastActivityQuery>({
    query: LAST_ACTIVITY_QUERY,
    data: {
      ...data,
      messenger: {
        ...data.messenger,
        recipient: {
          ...data.messenger.recipient,
          lastActivity: {
            ...data.messenger.recipient.lastActivity,
            direction,
            type: activityType,
          },
        },
      },
    },
  });
};

export default changeLastActivityData;
