import React from 'react';

import SectionLayoutPlaceholder from './SectionLayoutPlaceholder';
import PhotoItemPlaceholder from './PhotoItemPlaceholder';

const PhotoListPlaceholder = (props) => (
  <SectionLayoutPlaceholder>
    <PhotoItemPlaceholder {...props} />
    <PhotoItemPlaceholder {...props} />
    <PhotoItemPlaceholder {...props} />
  </SectionLayoutPlaceholder>
);

export default PhotoListPlaceholder;
