import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import TYPES from '@core/extraEvent/constants/extraEventTypes';
import PopupPriority from '@core/popup/constants/PopupPriority';

import INTERACTIVE_LIKE_QUERY from './graphql/queries/interactiveLike.gql';
import InteractiveLikePopup from './containers/InteractiveLikePopup';

/**
 * @param processId
 * @param client
 * @param extraEventsSubject
 * @returns {Promise<void>}
 */
const action = async ({processId, client, extraEventsSubject}) => {
  const openDiscountPopup = () => {
    extraEventsSubject.next({
      data: {
        extraEvents: {
          type: TYPES.EXTRA_DISCOUNT_INTERACTIVE_LIKE,
          processId,
        },
      },
    });
  };

  const {
    data: {
      extraPopups: {interactiveLike},
    },
  } = await client.query({
    query: INTERACTIVE_LIKE_QUERY,
    variables: {processId},
  });

  if (!interactiveLike) {
    return;
  }

  if (!interactiveLike.userIdToGuess && interactiveLike.users) {
    openDiscountPopup();

    return;
  }

  PopupService.openPopup(
    <InteractiveLikePopup
      users={interactiveLike.users}
      extra={interactiveLike.extra}
      userIdToGuess={interactiveLike.userIdToGuess}
      client={client}
      processId={processId}
      extraEventsSubject={extraEventsSubject}
      openDiscountPopup={openDiscountPopup}
    />,
    {
      small: true,
      priority: PopupPriority.LOW,
      autoLoadTracking: true,
      trackingName: 'interactiveLike',
    },
  );
};

export default action;
