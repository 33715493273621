import WORLD_WIDE_QUERY from '../../worldWide/graphql/queries/worldWide.gql';

type WorldWideQueryData = {
  worldWideSearch: {
    isAllowed: boolean;
    isActivated: boolean;
  };
};

/**
 * WorldWide access rule.
 */
export default {
  errorCode: 'NOT_ALLOWED',
  query: WORLD_WIDE_QUERY,
  resolver: (data: WorldWideQueryData) =>
    data.worldWideSearch.isAllowed && data.worldWideSearch.isActivated,
};
