import {getClientInstance} from '@core/graphql/client';

import TELEGRAM_BOT_INVITE_URL from '../graphql/queries/telegramBotInviteUrl.gql';

const getTelegramBotInviteUrl = async (via) => {
  const {data} = await getClientInstance().query({
    query: TELEGRAM_BOT_INVITE_URL,
    variables: {via},
  });

  return data?.userFeatures?.telegramBotInviteUrl || '';
};

export default getTelegramBotInviteUrl;
