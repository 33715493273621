import {TRANSLATION_TYPES} from '../constants/microfeatures';
import {
  BANNER_TITLE,
  BANNER_TITLE_ADULT,
  BANNER_TITLE_GAY,
  BANNER_TITLE_LESBIAN,
  BANNER_DESCRIPTION,
  BANNER_DESCRIPTION_ADULT,
  BANNER_DESCRIPTION_GAY,
  BANNER_DESCRIPTION_FEMALE,
  BANNER_DESCRIPTION_LESBIAN,
} from '../constants/translationsBanner';
import {
  POPUP_TITLE,
  POPUP_TITLE_ADULT,
  POPUP_TITLE_FEMALE,
  POPUP_TITLE_LESBIAN,
  POPUP_DESCRIPTION,
  POPUP_DESCRIPTION_ADULT,
  POPUP_DESCRIPTION_GAY,
  POPUP_DESCRIPTION_FEMALE,
  POPUP_ACTION,
  POPUP_DESCRIPTION_LESBIAN,
} from '../constants/translationsPaymentPopup';
import {
  SUCCESS_TITLE,
  SUCCESS_ACTION,
  SUCCESS_ACTION_GAY,
  SUCCESS_DESCRIPTION,
  SUCCESS_DESCRIPTION_GAY,
  SUCCESS_DESCRIPTION_FEMALE,
} from '../constants/translationsSuccessPopup';
import {SPECIAL_OFFER_TITLE} from '../constants/translationsSpecialOffer';

const BASE_TRANSLATIONS = {
  [TRANSLATION_TYPES.BANNER_TITLE]: BANNER_TITLE,
  [TRANSLATION_TYPES.BANNER_DESCRIPTION]: BANNER_DESCRIPTION,
  [TRANSLATION_TYPES.POPUP_TITLE]: POPUP_TITLE,
  [TRANSLATION_TYPES.POPUP_DESCRIPTION]: POPUP_DESCRIPTION,
  [TRANSLATION_TYPES.POPUP_ACTION]: POPUP_ACTION,
  [TRANSLATION_TYPES.SUCCESS_TITLE]: SUCCESS_TITLE,
  [TRANSLATION_TYPES.SUCCESS_ACTION]: SUCCESS_ACTION,
  [TRANSLATION_TYPES.SUCCESS_DESCRIPTION]: SUCCESS_DESCRIPTION,
  [TRANSLATION_TYPES.SPECIAL_OFFER_TITLE]: SPECIAL_OFFER_TITLE,
};
const ADULT_TRANSLATIONS = {
  [TRANSLATION_TYPES.BANNER_TITLE]: BANNER_TITLE_ADULT,
  [TRANSLATION_TYPES.BANNER_DESCRIPTION]: BANNER_DESCRIPTION_ADULT,
  [TRANSLATION_TYPES.POPUP_TITLE]: POPUP_TITLE_ADULT,
  [TRANSLATION_TYPES.POPUP_DESCRIPTION]: POPUP_DESCRIPTION_ADULT,
};
const GAY_TRANSLATIONS = {
  [TRANSLATION_TYPES.BANNER_TITLE]: BANNER_TITLE_GAY,
  [TRANSLATION_TYPES.BANNER_DESCRIPTION]: BANNER_DESCRIPTION_GAY,
  [TRANSLATION_TYPES.POPUP_DESCRIPTION]: POPUP_DESCRIPTION_GAY,
  [TRANSLATION_TYPES.SUCCESS_ACTION]: SUCCESS_ACTION_GAY,
  [TRANSLATION_TYPES.SUCCESS_DESCRIPTION]: SUCCESS_DESCRIPTION_GAY,
};
const LESBIAN_TRANSLATIONS = {
  [TRANSLATION_TYPES.BANNER_TITLE]: BANNER_TITLE_LESBIAN,
  [TRANSLATION_TYPES.BANNER_DESCRIPTION]: BANNER_DESCRIPTION_LESBIAN,
  [TRANSLATION_TYPES.POPUP_TITLE]: POPUP_TITLE_LESBIAN,
  [TRANSLATION_TYPES.POPUP_DESCRIPTION]: POPUP_DESCRIPTION_LESBIAN,
};
const FEMALE_TRANSLATIONS = {
  [TRANSLATION_TYPES.BANNER_DESCRIPTION]: BANNER_DESCRIPTION_FEMALE,
  [TRANSLATION_TYPES.POPUP_TITLE]: POPUP_TITLE_FEMALE,
  [TRANSLATION_TYPES.POPUP_DESCRIPTION]: POPUP_DESCRIPTION_FEMALE,
  [TRANSLATION_TYPES.SUCCESS_DESCRIPTION]: SUCCESS_DESCRIPTION_FEMALE,
};

/**
 * @param {Object} params - one of TRANSLATION_TYPES
 * @param {String} params.type - one of TRANSLATION_TYPES
 * @param {String} params.featureName - one of MICROFEATURES
 * @param {Object} params.orientation - isGay and isLesbian boolean flags
 * @param {Object} params.gender - isFemale and isMale boolean flags
 * @param {Boolean} [params.isAdult]
 * @return {String}
 */
const getTranslationByType = ({
  type,
  featureName,
  orientation,
  gender,
  isAdult,
  options = {},
}) => {
  let translation;

  if (isAdult && gender.isMale) {
    translation =
      ADULT_TRANSLATIONS[type] &&
      ADULT_TRANSLATIONS[type](options)[featureName];
  }

  if (orientation.isGay) {
    translation =
      GAY_TRANSLATIONS[type] && GAY_TRANSLATIONS[type](options)[featureName];
  }

  if (gender.isFemale && !orientation.isLesbian) {
    translation =
      FEMALE_TRANSLATIONS[type] &&
      FEMALE_TRANSLATIONS[type](options)[featureName];
  }

  if (orientation.isLesbian) {
    translation =
      LESBIAN_TRANSLATIONS[type] &&
      LESBIAN_TRANSLATIONS[type](options)[featureName];
  }

  const baseTranslation =
    BASE_TRANSLATIONS[type] && BASE_TRANSLATIONS[type](options)[featureName];
  return translation || baseTranslation;
};

export default getTranslationByType;
