import type {FC} from 'react';
import React, {useMemo} from 'react';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import CurrentUserPageAppearance from '@core/theming/constants/features/CurrentUserPageAppearance';

import createThemedComponentLoader from './createThemedComponentLoader';
import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43672
 */
type ThemedCurrentUserPageProps = {
  [key: string]: any;
};

const mobileResolver: ModuleResolver<
  CurrentUserPageAppearance,
  ModuleComponent<ThemedCurrentUserPageProps>
> = (value) => {
  switch (value) {
    case CurrentUserPageAppearance.AS_LIST_WITH_TOOLBAR:
      return import(
        /* webpackChunkName: 'profileListWithToolbar' */ '@phoenix/user/profile/current/containers/ProfilePageListWithToolbar'
      );
    case CurrentUserPageAppearance.AS_LAYERED_WITH_TABS:
      return import(
        /* webpackChunkName: 'profileLayeredWithTabs' */ '@phoenix/user/profile/current/containers/ProfilePageLayeredWithTabs'
      );
    case CurrentUserPageAppearance.AS_LAYERED_WITH_MEDIA_LOAD_MORE:
      return import(
        /* webpackChunkName: 'profileLayeredWithMediaLoadMore' */ '@phoenix/user/profile/current/containers/ProfilePageListWithMediaLoadMore'
      );
    default:
      return null;
  }
};

const webResolver: ModuleResolver<
  never,
  ModuleComponent<ThemedCurrentUserPageProps>
> = () =>
  import(
    /* webpackChunkName: 'profileWeb' */ '@phoenix/user/profile/current/containers/Profile'
  );

/**
 * Appearance-changeable profile page based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedCurrentUserPage: FC<ThemedCurrentUserPageProps> = (props) => {
  const isPhone = usePhoneBreakpoint();
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedCurrentUserPageProps,
        CurrentUserPageAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="currentUser"
      param="page"
      moduleResolver={isPhone ? mobileResolver : webResolver}
      {...props}
    />
  );
};

export default ThemedCurrentUserPage;
