import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyStory = lazy(
  () =>
    import(
      /* webpackChunkName: 'MyStory' */ '@phoenix/stories/page/containers/MyStoryPage'
    ),
);

const MyStoryChunk = (props) => <SplitChunk component={LazyStory} {...props} />;

export default MyStoryChunk;
