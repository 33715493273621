export const AFTER_LOGIN_PLACEMENT = 'afterLogin';
export const MICROFEATURES_PLACEMENT = 'microfeatures';
export const SEARCH_PLACEMENT = 'search';
export const TOP_SEARCH_PLACEMENT = 'topSearch';
export const CHAT_PLACEMENT = 'chats';
export const CHAT_WITH_PLACEMENT = 'chatWith';
export const USER_PROFILE_PLACEMENT = 'userProfile';
export const ACTIVITY_PLACEMENT = 'activity';
export const NOTIFICATIONS_PLACEMENT = 'notifications';
export const LIKE_GALLERY_PLACEMENT = 'likeGallery';
export const MENU_PLACEMENT = 'menu';

/**
 * Microfeature placements with rounded corners
 */
export const ROUNDED_PLACEMENTS = [
  USER_PROFILE_PLACEMENT,
  TOP_SEARCH_PLACEMENT,
  CHAT_WITH_PLACEMENT,
  SEARCH_PLACEMENT,
];
