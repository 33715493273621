import React, {memo} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';

import useFixedContentHeight from '@phoenix/popup/utils/useFixedContentHeight';

import {CONCEPT_CONTENT_ID} from '../constants/popup';
import css from '../styles/RemarketingOfferPopupLayout.css';

const CONCEPTS_DOES_NOT_FIT_CONTENT_HEIGHT = ['concept21', 'concept22'];

const RemarketingOfferPopupLayout = ({children, conceptId}) => {
  const isSmallTablet = useSmallTabletBreakpoint();

  useFixedContentHeight({
    contentId: CONCEPT_CONTENT_ID,
    fullSize: isSmallTablet,
    fitContentHeight: !CONCEPTS_DOES_NOT_FIT_CONTENT_HEIGHT.includes(conceptId),
  });

  return (
    <div id={CONCEPT_CONTENT_ID} className={cn(isSmallTablet && css.wrapper)}>
      {children}
    </div>
  );
};

RemarketingOfferPopupLayout.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  conceptId: PropTypes.string.isRequired,
};

export default memo(RemarketingOfferPopupLayout);
