import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {IconSize, SpacingSize} from '@phoenix/ui/constants';
import {Icon} from '@phoenix/ui';

import CoinsCommonSuccessPopup from '../../buyCoins/components/CoinsCommonSuccessPopup';

/**
 * Success popup for special offer
 */
const WeekSpeakOfferSuccessPopup = ({onButtonClick}) => {
  return (
    <CoinsCommonSuccessPopup
      icon={<Icon success type="check" size={IconSize.SIZE_96} />}
      iconSpacing={SpacingSize.NORMAL}
      title={t('coins', 'title.success_week_speak')}
      description={t('coins', 'text.success_week_speak_description')}
      action={t('coins', 'button.success_week_speak')}
      onButtonClick={onButtonClick}
    />
  );
};

WeekSpeakOfferSuccessPopup.propTypes /* remove-proptypes */ = {
  onButtonClick: PropTypes.func,
};

export default WeekSpeakOfferSuccessPopup;
