/**
 * types of sizes for pictures with naughty mode
 */
enum NaughtyModeSize {
  // Use in case when need to show the full size of naughty picture. (@see TargetUserMediaWithActions.js)
  FULL = 'full',
  // Use in case when we need to show picture as 50% of full view port size (@see VideoLayout.js)
  MIDDLE = 'middle',
}

export default NaughtyModeSize;
