import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import PackageTypes from '@core/payment/widgets/package/constants/packageTypes';

import {Text, TextMuted, Link} from '@phoenix/typography';

import RemarketingToggleSubscriptionCheckbox from '../components/RemarketingToggleSubscriptionCheckbox';

const RemarketingToggleSubscription = ({paymentPackage, onCheckboxChange}) => {
  const {interval, type} = paymentPackage;
  const isFeatures = type === PackageTypes.FEATURES;
  const [checked, setChecked] = useState(false);

  const handleLinkClick = useCallback(() => {
    setChecked(true);
    onCheckboxChange(true);
  }, [onCheckboxChange]);

  const handleCheckboxChange = useCallback(() => {
    setChecked(false);
    onCheckboxChange(false);
  }, [onCheckboxChange]);

  const optionsSubscriptionText = {
    '{n}': interval,
    '{cancelItLayout}': (
      <Link
        underline={!checked}
        unstyled
        inherit
        trackingName="cancelSubscription"
        onClick={handleLinkClick}
      >
        {isFeatures
          ? t('remarketingPopup', 'text.feature_text_cancel_it')
          : t('remarketingPopup', 'text.cancel_it')}
      </Link>
    ),
  };

  const subscriptionText = isFeatures
    ? t('remarketingPopup', 'text.feature_cancel_it', optionsSubscriptionText)
    : t('remarketingPopup', 'text.please_cancel_it', optionsSubscriptionText);

  return (
    <TextMuted align={Text.ALIGN.CENTER} small>
      {subscriptionText}
      {checked && (
        <RemarketingToggleSubscriptionCheckbox
          onChange={handleCheckboxChange}
        />
      )}
    </TextMuted>
  );
};

RemarketingToggleSubscription.propTypes /* remove-proptypes */ = {
  paymentPackage: PropTypes.objectOf(PropTypes.any).isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
};

export default RemarketingToggleSubscription;
