import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import first from 'lodash/first';

import t from '@core/translations/translate';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {TextMuted, H3} from '@phoenix/typography';
import {Align} from '@phoenix/ui/constants';
import {ButtonPrimary, Row, RowShort} from '@phoenix/ui';

import CancelRepeatPopupPlaceholder from '../components/CancelRepeatPopupPlaceholder';
import CANCEL_REPEAT_EXPIRE_DATE_QUERY from '../graphql/queries/cancelRepeatExpireDate.gql';

const CancelRepeatSuccessfulPopup = ({onCloseClick}) => {
  const {data, loading, error} = useQuery(CANCEL_REPEAT_EXPIRE_DATE_QUERY);

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (loading) {
    return <CancelRepeatPopupPlaceholder />;
  }

  const {expireDate = ''} =
    first(data?.cancelSubscription.subscriptionExpireDate) || {};

  return (
    <PopupFrame
      data-test="cancelRepeatPopup"
      spacedAbove
      content={
        <PopupLoadTracker>
          <RowShort align={Align.CENTER}>
            <H3>
              {t(
                'popup24CancelRepeat',
                'title.cancel-billing.has-been-cancelled-header',
              )}
            </H3>
          </RowShort>
          <RowShort align={Align.CENTER}>
            <TextMuted data-test="expireDate">
              {t(
                'popup24CancelRepeat',
                'title.cancel-billing.has-been-cancelled-body-text-1',
                {
                  '{mm/dd/yy}': expireDate,
                },
              )}
            </TextMuted>
          </RowShort>
          <Row align={Align.CENTER}>
            <TextMuted>
              {t(
                'popup24CancelRepeat',
                'title.cancel-billing.has-been-cancelled-body-text-2',
              )}
            </TextMuted>
          </Row>
        </PopupLoadTracker>
      }
      actions={
        <ButtonPrimary
          fullWidth
          adaptive
          onClick={onCloseClick}
          data-test="cancelRepeatAction"
          trackingName="closePopup"
        >
          {t('popup24CancelRepeat', 'text.ok')}
        </ButtonPrimary>
      }
    />
  );
};

CancelRepeatSuccessfulPopup.propTypes /* remove-proptypes */ = {
  onCloseClick: PropTypes.func.isRequired,
};

export default CancelRepeatSuccessfulPopup;
