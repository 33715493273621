import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import STATIC_PAGES, {
  MERCHANT_POPUP_NAME,
  CANCELLATION_POLICY_POPUP_NAME,
  ANTI_SLAVERY_POLICY_POPUP_NAME,
  POLICY_AGAINST_HUMAN_TRAFFICKING_POPUP_NAME,
  CONSENT_AGREEMENT_POPUP_NAME,
} from '@core/staticPages/constants/pages';

import {Link} from '@phoenix/typography';
import {Actions} from '@phoenix/ui';
import {Align} from '@phoenix/ui/constants';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

const createHandler = (name) => (e) => {
  StaticPagePopupHelper.openPopup(STATIC_PAGES[name], e);
};

/**
 * @param {string} name
 * @return {string|array}
 */
const getTranslation = (name) => {
  const translate = {
    'Privacy Policy': () => t('footer', 'text.Privacy Policy'),
    'Terms & Conditions': () => t('footer', 'text.Terms & Conditions'),
    'How to Meet Safely Online': () =>
      t('footer', 'text.How to Meet Safely Online'),
    Affiliates: () => t('footer', 'text.Affiliates'),
    'Contact us': () => t('footer', 'text.Contact us'),
    'Safety Tips': () => t('footer', 'text.safetyTips'),
    [CANCELLATION_POLICY_POPUP_NAME]: () =>
      t('footer', 'text.cancellationPolicy'),
    [POLICY_AGAINST_HUMAN_TRAFFICKING_POPUP_NAME]: () =>
      t('footer', 'text.policyAgainstHumanTrafficking'),
    [ANTI_SLAVERY_POLICY_POPUP_NAME]: () =>
      t('footer', 'text.antiSlaveryPolicy'),
    [CONSENT_AGREEMENT_POPUP_NAME]: () => t('footer', 'text.consentAgreement'),
    'About us': () => t('footer', 'text.About us'),
    Imprint: () => t('footer', 'text.Imprint'),
    'Legal Info': () => t('footer', 'text.Legal Info'),
    [MERCHANT_POPUP_NAME]: () => t('footer', 'text.Merchant Info'),
    'About company': () => t('footer', 'text.About company'),
    'Community guideline': () => t('footer', 'text.communityGuideline'),
    'Age verification': () => t('footer', 'text.ageVerificationInfo'),
  }[name];

  return translate ? translate() : `text.${name}`;
};

const TEST_NAMES = {
  'Privacy Policy': 'FooterPrivacyPolicy',
  'Terms & Conditions': 'FooterTerms',
  [CANCELLATION_POLICY_POPUP_NAME]: 'FooterCancellationPolicy',
  [CONSENT_AGREEMENT_POPUP_NAME]: () => 'ConsentAgreement',
  [ANTI_SLAVERY_POLICY_POPUP_NAME]: 'FooterAntiSlaveryPolicy',
  [POLICY_AGAINST_HUMAN_TRAFFICKING_POPUP_NAME]:
    'FooterPolicyAgainstHumanTrafficking',
  'How to Meet Safely Online': 'FooterSafeDating',
  'Safety Tips': 'FooterSafetyTips',
};

const SiteMap = ({items}) =>
  items.length > 0 && (
    <Actions align={Align.CENTER}>
      {items.map(({name, url, openThroughNewWindow}) => {
        const testName = TEST_NAMES[name];

        return (
          <Link
            key={name}
            href={url}
            target={openThroughNewWindow ? '_blank' : null}
            onClick={createHandler(name)}
            data-bypass={STATIC_PAGES[name] ? '' : null}
            muted
            small
            data-test={testName && `${testName}Link`}
          >
            {getTranslation(name)}
          </Link>
        );
      })}
    </Actions>
  );

SiteMap.propTypes /* remove-proptypes */ = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SiteMap;
