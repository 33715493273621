import {sessionStorage} from '@core/utils/storage';
import getStorageKey from '@core/graphql/utils/getStorageKey';

const POPUP_OPENED_AFTER_SUCCESS = 'AIAssistantPopupOpenedAfterSuccess';

const DEFAULT_STORAGE_VALUE = {
  type: '',
  isShow: false,
};

const aiAssistantStorage = {
  async setShow(value) {
    await this.updateStorage({isShow: value});
  },

  /**
   * @param {string} type
   */
  async setTypeOfAction(type) {
    await this.updateStorage({type});
  },

  async remove() {
    sessionStorage.removeItem(await getStorageKey(POPUP_OPENED_AFTER_SUCCESS));
  },

  /**
   * @returns {Object}
   */
  async get() {
    const storedData = sessionStorage.getItem(
      await getStorageKey(POPUP_OPENED_AFTER_SUCCESS),
    );

    return storedData || {...DEFAULT_STORAGE_VALUE};
  },

  /**
   * @param {Object} newData
   */
  async updateStorage(newData) {
    const currentData = await this.get();
    const updatedData = {...currentData, ...newData};

    sessionStorage.setItem(
      await getStorageKey(POPUP_OPENED_AFTER_SUCCESS),
      updatedData,
    );
  },
};

export default aiAssistantStorage;
