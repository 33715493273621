import React, {Suspense, useEffect} from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {setExpiringItem} from '@core/utils/storage/expiredStorage';

import {Loader} from '@phoenix/ui';

import CoregistrationPopup from '../containers/CoregistrationPopup';
import parseCoregistrationPopupConceptData from './parseCoregistrationPopupConceptData';
import getCoregistrationPopupConceptLayout from './getCoregistrationPopupConceptLayout';
import {
  INVERSE_CLOSE_BUTTON,
  SPACED_ABOVE_CONCEPT,
  YOUNG_CONCEPTS,
  EXPIRED_INTERVAL,
  STORAGE_EXPIRED_KEYS,
} from '../constants/coregistrationPopup';

/**
 * Shows coregistration popup if zoneData info for it is available
 * @see CoregistrationProvider.js (zones and register)
 * @see CoregistrationPopupDisplayer.js
 *
 * @param {function} register
 * @param {string} zone
 * @param {Object} zoneData
 * @param {function} onPopupClose
 * @param {boolean} useClientStorage - parameter that specifies to store the popup display availability
 * parameter in the local storage on the frontend side
 */
const useCoregistrationPopup = ({
  register,
  zoneData,
  zone,
  onPopupClose,
  useClientStorage,
}) => {
  const zoneInfo = zoneData && !zoneData.outdated && zoneData.zoneInfo;

  useEffect(() => {
    if (!zoneInfo) return;

    const {data, conceptType, openInactiveTab} = zoneInfo;

    const {terms, images, text, url} =
      parseCoregistrationPopupConceptData(data);
    const CoregistrationPopupConceptLayout =
      getCoregistrationPopupConceptLayout(conceptType);

    PopupService.openPopup(
      <CoregistrationPopup
        spacedContent={!Object.values(YOUNG_CONCEPTS).includes(conceptType)}
        spacedAbove={SPACED_ABOVE_CONCEPT === conceptType}
        motivationText={text}
        footerText={terms}
        register={register}
        openInactiveTab={openInactiveTab}
        zone={zone}
        url={url}
      >
        <Suspense fallback={<Loader small />}>
          <CoregistrationPopupConceptLayout
            motivationText={text}
            images={images}
          />
        </Suspense>
      </CoregistrationPopup>,
      {
        small: true,
        trackingName: 'coregistration',
        trackingConceptId: conceptType,
        onClose: onPopupClose,
        inverseCloseButton: INVERSE_CLOSE_BUTTON.includes(conceptType),
      },
    );

    useClientStorage &&
      setExpiringItem(STORAGE_EXPIRED_KEYS[zone], EXPIRED_INTERVAL);
  }, [zone, onPopupClose, register, zoneData, zoneInfo, useClientStorage]);
};

export default useCoregistrationPopup;
