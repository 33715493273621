import React from 'react';

import replaceParams from '@core/translations/utils/replaceParams';
import t from '@core/translations';

import {
  PaymentBillingPolicyLink,
  PaymentPrivacyPolicyLink,
  PaymentTermsOfUseLink,
} from '../../billingPolicy/components/PaymentBillingPolicyLinks';

/**
 * Replace text placeholders by additional package data
 */
const setParamsToAdditionalPackageText = (
  additionalPackageText,
  {
    footerPayButtonText,
    dayInterval,
    externalDomain,
    currency,
    amount,
    billingPolicyLink,
    billingPolicyLinkText,
    siteId,
    isXSaleTerms = false,
    placeholders,
  } = {},
) => {
  let additionalPolicyLinks = [
    additionalPackageText
      .replace(/{payButtonText}/gi, footerPayButtonText)
      .replace(/{dayInterval}/gi, dayInterval)
      .replace(/{targetSite}/gi, externalDomain)
      .replace('{currency}', currency)
      .replace('{amount}', amount),
  ];

  additionalPolicyLinks = replaceParams(
    additionalPolicyLinks.join(', '),
    {
      '{billingPolicyLinkText}': (
        <PaymentBillingPolicyLink
          href={billingPolicyLink}
          text={billingPolicyLinkText}
        />
      ),
      '{privacyPolicyLink}': (
        <PaymentPrivacyPolicyLink
          href="/pay/page/privacypolicy?action=features"
          text={t('popupStaticPage', 'title.privacy_policy')}
        />
      ),
      '{termsOfUseLink}': (
        <PaymentTermsOfUseLink
          href="/pay/page/terms?action=features"
          text={t('popupStaticPage', 'title.terms')}
          isXSaleTerms={isXSaleTerms}
          siteId={siteId}
          placeholders={placeholders}
          externalDomain={externalDomain}
        />
      ),
    },
    true,
  );

  return additionalPolicyLinks;
};

export default setParamsToAdditionalPackageText;
