import {useFragment} from '@apollo/client';

import type {AllowedMyActivityFragment} from '../graphql/fragments/allowedMyActivity';
import ALLOWED_ACTIVITY_FRAGMENT from '../graphql/fragments/allowedMyActivity.gql';

const useAllowedMyActivity = () => {
  const {data} = useFragment<AllowedMyActivityFragment>({
    fragment: ALLOWED_ACTIVITY_FRAGMENT,
    from: {__typename: 'UserFeatures'},
  });

  return data?.allowedMyActivity || false;
};

export default useAllowedMyActivity;
