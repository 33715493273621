import React, {Fragment, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import PopupService from '@core/popup/utils/PopupService';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACCOUNT_STATUS_QUERY from '@core/payment/common/graphql/queries/paymentAccountStatus.gql';
import SEARCH_PAGE_LINK from '@core/search/page/constants/route';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import {TextMuted} from '@phoenix/typography';
import {Button, ButtonLink, Loader} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {ButtonType, SpacingSize} from '@phoenix/ui/constants';

const useQueryData = () => {
  const {
    data: {payment},
    loading,
    error,
  } = useQuery(PAYMENT_ACCOUNT_STATUS_QUERY);

  return {
    data: payment,
    loading,
    error,
  };
};

const PersonalIdentityThanksPopup = ({
  withCreditCard,
  paymentVia,
  verifyError,
}) => {
  const {data, loading, error} = useQueryData();
  const history = useHistory();

  const handleClick = useCallback(() => {
    PopupService.closePopup();
    history.push(SEARCH_PAGE_LINK);
  }, [history]);

  if (loading) {
    return <Loader />;
  }

  if (error || verifyError) {
    return <ErrorBoundary error={error} />;
  }

  const {accountStatus} = data;

  const payUrl = generatePayUrl({
    accountStatus,
    urlParams: {
      via: paymentVia,
      returnPath: '/personalIdentity',
    },
  });

  return (
    <PopupNotice
      icon="check"
      title={t('personalIdentity', 'text.thanks_for_submit_document')}
      subTitle={
        <Fragment>
          <TextMuted>
            {t('personalIdentity', 'text.please_wait_for_auth')}
          </TextMuted>
          <TextMuted>{t('personalIdentity', 'text.you_get_email')}</TextMuted>
        </Fragment>
      }
      description={
        withCreditCard &&
        t('personalIdentity', 'text.complete-age-verification-right-now')
      }
      descriptionSpacing={SpacingSize.NORMAL}
      actions={[
        <Button fullWidth onClick={handleClick} trackingName="search">
          {t('personalIdentity', 'button.go_to_search')}
          {/* To track 'PersonalIdentityThanks' popup load completion */}
          <PopupLoadTracker />
        </Button>,
        withCreditCard && (
          <ButtonLink fullWidth href={payUrl} type={ButtonType.PRIMARY}>
            {t('personalIdentity', 'button.verify_with_card')}
          </ButtonLink>
        ),
      ]}
    />
  );
};

PersonalIdentityThanksPopup.propTypes /* remove-proptypes */ = {
  withCreditCard: PropTypes.bool.isRequired,
  paymentVia: PropTypes.string.isRequired,
  verifyError: PropTypes.string,
};

export default PersonalIdentityThanksPopup;
