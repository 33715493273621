import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyDiscreetProfile = lazy(
  () =>
    import(
      /* webpackChunkName: 'discreetProfiles' */ '@phoenix/discreetProfiles'
    ),
);

const DiscreetProfileChunk = (props) => (
  <SplitChunk component={LazyDiscreetProfile} {...props} />
);

export default DiscreetProfileChunk;
