import type {FC} from 'react';
import React, {useMemo} from 'react';

import BannerWrapperAppearance from '@core/theming/constants/features/BannerWrapperAppearance';

import type {ModuleComponent, ModuleResolver} from '../types';
import createThemedComponentLoader from './createThemedComponentLoader';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43669
 */
type ThemedBannerWrapperProps = {
  [key: string]: any;
};

export const resolver: ModuleResolver<
  BannerWrapperAppearance,
  ModuleComponent<ThemedBannerWrapperProps>
> = (value) => {
  switch (value) {
    case BannerWrapperAppearance.COLORIZED:
      return import(
        /* webpackChunkName: 'bannerWrapperColorized' */ '@phoenix/widgets/bannerWrapper/BannerWrapperColorized'
      );
    case BannerWrapperAppearance.COLORIZED_WITH_SPACING:
      return import(
        /* webpackChunkName: 'bannerWrapperColorizedWithSpacing' */ '@phoenix/widgets/bannerWrapper/BannerWrapperColorizedWithSpacing'
      );
    case BannerWrapperAppearance.TRANSPARENT:
      return import(
        /* webpackChunkName: 'bannerWrapperTransparent' */ '@phoenix/widgets/bannerWrapper/BannerWrapperTransparent'
      );
    case BannerWrapperAppearance.LIGHT:
      return import(
        /* webpackChunkName: 'bannerWrapperLight' */ '@phoenix/widgets/bannerWrapper/BannerWrapperLight'
      );
    case BannerWrapperAppearance.COLORIZED_WITHOUT_SHADOW:
      return import(
        /* webpackChunkName: 'bannerWrapperColorizedWithoutShadow' */ '@phoenix/widgets/bannerWrapper/BannerWrapperColorizedWithoutShadow'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable banner wrapper based on theme.
 * Proxies all props and adds some module-loading dynamic.
 */
const ThemedBannerWrapper: FC<ThemedBannerWrapperProps> = (props) => {
  const ThemedComponentLoader = useMemo(
    () =>
      createThemedComponentLoader<
        ThemedBannerWrapperProps,
        BannerWrapperAppearance
      >(),
    [],
  );

  return (
    <ThemedComponentLoader
      feature="bannerWrapper"
      moduleResolver={resolver}
      {...props}
    />
  );
};

export default ThemedBannerWrapper;
