import React from 'react';

import t from '@core/translations/translate';

import {
  PaymentBillingPolicyLink,
  PaymentTermsOfUseLink,
} from '@phoenix/payment/widgets/billingPolicy/components/PaymentBillingPolicyLinks';

const getDescriptionLinks = () => ({
  '{billingPolicyLinkText}': (
    <PaymentBillingPolicyLink
      text={t('paymentPage', 'text.billing_policy')}
      unstyled
      inherit
    />
  ),
  '{termsOfUse}': (
    <PaymentTermsOfUseLink
      text={t('popupStaticPage', 'title.terms')}
      unstyled
      inherit
    />
  ),
  // Disable cancel link by default
  '{cancelLink}': <span />,
});

export default getDescriptionLinks;
