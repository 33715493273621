import t from '@core/translations/translate';

import animationCss from '@phoenix/widgets/animation/animations.css';
import {IconType} from '@phoenix/ui/constants';

import FreeFeaturePopupTypes from '../constants/freeFeaturePopupTypes';

/**
 * @param {Object} params
 * @param {Number} params.time
 * @param {Number} params.timeInMins
 */
const priorityManStartConfig = ({time, timeInMins}) => ({
  time,
  type: FreeFeaturePopupTypes.START,
  animationClass: animationCss.bounce,
  title: t('microfeatures', 'title.extra_event'),
  text: t('microfeatures', 'text.body_extra_event', {
    '{duration}': timeInMins,
  }),
  actionText: t('microfeatures', 'text.go'),
  icon: 'crown',
  iconType: IconType.PRIMARY,
  benefits: [
    {
      icon: 'eye',
      type: IconType.PRIMARY,
      text: `${t('microfeatures', 'text.more')} ${t(
        'microfeatures',
        'text.views',
      )}`,
    },
    {
      icon: 'heart',
      type: IconType.DANGER,
      text: `${t('microfeatures', 'text.more')} ${t(
        'microfeatures',
        'text.likes',
      )}`,
    },
    {
      icon: 'mail',
      type: IconType.ACCENT,
      text: `${t('microfeatures', 'text.more')} ${t(
        'microfeatures',
        'text.messages',
      )}`,
    },
  ],
});

export default priorityManStartConfig;
