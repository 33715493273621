import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import routeVar from '@core/graphql/vars/routeVar';
import useRedirectToDefaultPage from '@core/utils/routing/useRedirectToDefaultPage';

const useRedirectVideoChat = () => {
  const history = useHistory();
  const redirectToDefaultPage = useRedirectToDefaultPage({replace: true});

  const navigateToVideoChat = useCallback(
    (userId) => {
      const currentRoute = routeVar().current;

      history.replace(`/videoChat/${userId}`, {previousRoute: currentRoute});
    },
    [history],
  );

  const navigateToPreviousRoute = useCallback(() => {
    const {state: {previousRoute} = {}} = history.location;

    if (previousRoute) {
      history.replace(previousRoute);
    } else {
      redirectToDefaultPage();
    }
  }, [redirectToDefaultPage, history]);

  return {
    navigateToVideoChat,
    navigateToPreviousRoute,
  };
};

export default useRedirectVideoChat;
