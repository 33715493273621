import React from 'react';

import t from '@core/translations/translate';
import PopupService from '@core/popup/utils/PopupService';

import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {Button} from '@phoenix/ui';
import {ButtonType} from '@phoenix/ui/constants';
import {TextMuted} from '@phoenix/typography';

const handleClickButton = () => PopupService.closePopup();

const PhotoApproveWaitPopup = () => (
  <PopupNotice
    icon="clock"
    title={t('antiscamPhotoPopup', 'text.pending_approval')}
    description={
      <TextMuted>
        {t('antiscamPhotoPopup', 'text.photoCommunicationRestrictApprove')}
      </TextMuted>
    }
    actions={[
      <Button
        type={ButtonType.PRIMARY}
        onClick={handleClickButton}
        trackingName="closePopup"
        fullWidth
      >
        {t('antiscamPhotoPopup', 'button.ok')}
      </Button>,
    ]}
  />
);

export default PhotoApproveWaitPopup;
