import MessageType from '@core/messenger/common/constants/messageType';

import {
  UNSEND_GIFT,
  UNSEND_MMS_MESSAGE,
  UNSEND_SMS_MESSAGE,
} from '@phoenix/coins/buyCoins/constants/vias';

/**
 * Via for unsent message buy type of message
 * @param {string} type
 * @returns {string|string}
 */
const getViaForUnsentMessageByType = (type) => {
  switch (type) {
    case MessageType.GIFT:
      return UNSEND_GIFT;

    case MessageType.IMB_IMAGE:
    case MessageType.IMB_VIDEO:
      return UNSEND_MMS_MESSAGE;

    // For default use via for a chat type message
    default:
      return UNSEND_SMS_MESSAGE;
  }
};

export default getViaForUnsentMessageByType;
