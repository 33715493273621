import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {PHOTO} from '@core/mediaUpload/constants/uploadTypes';

import UploadErrorsPopup from '../components/UploadErrorsPopup';

/**
 * Runtime require is used to help cyclic imports like this to work properly:
 * {@see VideoUploadPopup} -> MediaUploadPopup -> openErrorsPopup -> openVideoUploadPopup -> VideoUploadPopup
 * TODO: find way to avoid cyclic imports.
 *
 * @param {Object} settings
 * @return {Promise<void>}
 */
const openUpload = (settings) =>
  (settings.type === PHOTO
    ? require('./openPhotoUploadPopup')
    : require('./openVideoUploadPopup')
  ).default(settings);

/**
 * Open errors popup
 *
 * @param {Object} props
 * @param {Object[]} props.errors - Errors array. @see errorsPropType
 * @param {Function} onFileSelect - File select handler
 * @param {Function} onCaptureClick - Capture click handler
 * @param {Object} ...props - Other props for upload popup. @see @settingsPropType.
 */
export default function openErrorsPopup({
  errors,
  onFileSelect,
  onCaptureClick,
  settings,
  uploadRenderer,
}) {
  const handleFileSelect =
    onFileSelect || ((files) => openUpload({...settings, files}));

  const handleCaptureClick =
    onCaptureClick || (() => openUpload({...settings, isWebcamCapture: true}));

  PopupService.openPopup(
    <UploadErrorsPopup
      settings={settings}
      errors={errors}
      onFileSelect={handleFileSelect}
      onCaptureClick={handleCaptureClick}
      uploadRenderer={uploadRenderer}
    />,
    {
      small: true,
      trackingName: `uploadErrors_${settings.type}`,
      autoLoadTracking: true,
    },
  );
}
