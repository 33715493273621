import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyMicroFeature = lazy(
  () =>
    import(
      /* webpackChunkName: 'microFeatures' */ '@phoenix/microfeatures/page/containers/MicrofeaturesPage'
    ),
);

export default (props) => (
  <SplitChunk component={LazyMicroFeature} {...props} />
);
