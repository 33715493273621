import type {FC} from 'react';
import React from 'react';

import type {StaticPageToHashProps} from '@core/application/containers/StaticPageToHash';
import StaticPageToHashCommon from '@core/application/containers/StaticPageToHash';

import StaticPageChunk from '../chunks/StaticPageChunk';

const StaticPageToHash: FC<Omit<StaticPageToHashProps, 'StaticPageChunk'>> = (
  props,
) => <StaticPageToHashCommon {...props} StaticPageChunk={StaticPageChunk} />;

export default StaticPageToHash;
