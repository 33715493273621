import React, {Fragment} from 'react';

import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {Align} from '@phoenix/ui/constants';
import {PlaceholderInput, PlaceholderBar, RowShort, Row} from '@phoenix/ui';

const CancelRepeatPopupPlaceholder = () => (
  <PopupFrame
    spacedAbove
    content={
      <Fragment>
        <RowShort align={Align.CENTER}>
          <PlaceholderInput size={200} inline />
        </RowShort>
        <Row align={Align.CENTER}>
          <PlaceholderBar />
          <PlaceholderBar />
          <PlaceholderBar size={150} inline />
        </Row>
        <Row align={Align.CENTER}>
          <PlaceholderBar />
          <PlaceholderBar />
          <PlaceholderBar size={150} inline />
        </Row>
      </Fragment>
    }
    actions={
      <Row align={Align.CENTER}>
        <PlaceholderInput size={200} inline />
      </Row>
    }
  />
);

export default CancelRepeatPopupPlaceholder;
