import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import PartnerNetworkAlertPopup from '../components/PartnerNetworkAlertPopup';

const openPartnerNetworkAlertPopup = () => {
  PopupService.openPopup(<PartnerNetworkAlertPopup />, {
    small: true,
    trackingName: 'partnerNetworkAlert',
    autoLoadTracking: true,
  });
};

export default openPartnerNetworkAlertPopup;
