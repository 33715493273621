import React, {forwardRef} from 'react';

import useThemeCompositeFeatureLoader from '../utils/useThemeCompositeFeatureLoader';
import type {ThemedComponentLoaderProps, ModuleComponent} from '../types';

/**
 * Standalone component that implements dynamic chunking between themed functionalities.
 * Should be used for big things like widgets, entire functionalities, since we can't
 * afford to bloat resulting bundle with all possible modifications
 */
const createThemedComponentLoader = <P, T>() => {
  const themedComponentLoader = forwardRef<
    HTMLElement,
    ThemedComponentLoaderProps<P, T, ModuleComponent<P>>
  >(({feature, param, moduleResolver, placeholder, ...props}, ref) => {
    const {loading, data} = useThemeCompositeFeatureLoader<
      T,
      ModuleComponent<P>
    >(feature, param, moduleResolver);

    const Component = data;

    if (loading) {
      /**
       * Avoid using any loader since it leads to jumping content or
       * strange appearance since themed component can have its own loaders or placeholders
       */
      return placeholder || null;
    }

    if (!Component) {
      return null;
    }

    return <Component ref={ref} {...(props as P)} />;
  });

  themedComponentLoader.displayName = 'ThemedComponentLoader';

  return themedComponentLoader;
};

export default createThemedComponentLoader;
