import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import get from 'lodash/get';

import isInRouteList from '@core/utils/routing/isInRouteList';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import t from '@core/translations/translate';
import PopupService from '@core/popup/utils/PopupService';
import CURRENT_USER_ID from '@core/user/profile/current/graphql/queries/currentUserId.gql';

import {TextMuted} from '@phoenix/typography';
import {ButtonPrimary} from '@phoenix/ui';
import PopupNotice from '@phoenix/popup/components/PopupNotice';
import {IconType} from '@phoenix/ui/constants';

import PERSONAL_IDENTITY_RESET_VALUES from '../graphql/mutations/personalIdentityResetValues.gql';

/**
 * @const {string}
 */
const PERSONAL_IDENTITY_ROUTE = '/personalIdentity';

const PersonalIdentityFailPopup = ({onReset}) => {
  const history = useHistory();
  const {data, loading} = useQuery(CURRENT_USER_ID);

  const [resetValues] = useMutation(PERSONAL_IDENTITY_RESET_VALUES, {
    update() {
      onReset();
    },
  });

  useEffect(() => {
    // Reset values isNeedToShowPopup and documentStatus on server
    resetValues({
      variables: {
        userId: get(data, 'myUser.profile.id'),
      },
    });
  }, [data, resetValues]);

  const handleClick = useCallback(() => {
    if (!isInRouteList([PERSONAL_IDENTITY_ROUTE], history.location.pathname)) {
      history.push(PERSONAL_IDENTITY_ROUTE);
      return;
    }
    PopupService.closePopup();
  }, [history]);

  if (loading) return null;

  return (
    <PopupNotice
      icon="age-cross"
      iconType={IconType.DANGER}
      title={t(
        'personalIdentityRestrictions',
        'text.document_has_not_been_submitted',
      )}
      description={
        <TextMuted>
          {[
            t(
              'personalIdentityRestrictions',
              'text.document_has_not_been_submitted_note',
            ),
            t(
              'personalIdentityRestrictions',
              'text.necessary_to_submit_document',
            ),
          ].join(' ')}
        </TextMuted>
      }
      actions={[
        <ButtonPrimary fullWidth onClick={handleClick} trackingName="verify">
          {t('personalIdentityRestrictions', 'button.verify_now')}
          <PopupLoadTracker />
        </ButtonPrimary>,
      ]}
    />
  );
};

PersonalIdentityFailPopup.propTypes /* remove-proptypes */ = {
  onReset: PropTypes.func.isRequired,
};

export default PersonalIdentityFailPopup;
