import pick from 'lodash/pick';

import URI, {gotoUrl} from '@core/utils/url';
import {getClientInstance} from '@core/graphql/client';

import SITE_DATA_QUERY from '../graphql/queries/siteData.gql';
import DATEINFORM_RESUBSCRIBE_LOGIC from '../constants/dateinformSubscribeLogic';

/**
 * The handler is used to starting the dateinform subscription
 * @param {Object} options
 * @class
 */
export default class DateinformHandler {
  TRY_SUBSCRIBE_EVENT = 'click.WebPushDateinformHandler';

  BASE_URL = 'https://#.dateinform.com';

  WINDOW_BORDER_LENGTH = 650;

  TOP_OFFSET = 100;

  WINDOW_NAME = 'DateInform';

  REL = '-rel-';

  LOCALHOST = '.localhost';

  options = {};

  /**
   * @param {Object} options
   */
  constructor(options) {
    this.options = options;
  }

  /**
   * @return {boolean}
   * @public
   */
  isPermissionDefault() {
    return this.options.dateInformEnabled;
  }

  /**
   * There is a need a user action to be fired in order to open a new window
   * @public
   */
  trySubscribe() {
    const handleClick = () => {
      window.document.removeEventListener('click', handleClick);

      const isPopunder =
        this.options.dateInformResubscribeLogic ===
        DATEINFORM_RESUBSCRIBE_LOGIC.DATEINFORM_POPUNDER;

      gotoUrl(
        this.getSiteUrl(),
        true,
        this.WINDOW_NAME,
        isPopunder ? '' : this.getWindowParams(),
      ).blur();

      window.focus();
    };

    window.document.addEventListener('click', handleClick);
  }

  /**
   * @private
   * @return {String}
   */
  getSiteUrl() {
    const {name: siteName, siteId} = this.getSiteData();

    const baseUrl = this.isRelStage()
      ? this.BASE_URL.replace('#.', '#-').replace('.com', '-com')
      : this.BASE_URL;

    const params = {
      ...{wpUserId: this.options.userId, wpSiteId: siteId},
      ...pick(this.options, [
        'logic',
        'logicName',
        'logicType',
        'action',
        'popup',
        'timeout',
      ]),
    };

    const uri = URI(
      baseUrl.replace('#', siteName.toLowerCase()) + this.getStageSuffix(),
    );

    uri.addSearch(params);

    return uri.toString();
  }

  /**
   * @private
   * @returns {*}
   */
  getSiteData() {
    return getClientInstance().readQuery({query: SITE_DATA_QUERY}).site;
  }

  /**
   * @public
   * @return {string}
   */
  getWindowParams() {
    return [
      `width=${this.WINDOW_BORDER_LENGTH}`,
      `height=${this.WINDOW_BORDER_LENGTH}`,
      `top=${this.TOP_OFFSET}`,
      `left=${this.getLeftOffset()}`,
    ].join(',');
  }

  /**
   * @private
   * @return {number}
   */
  getLeftOffset() {
    return Math.floor(window.innerWidth / 2 - this.WINDOW_BORDER_LENGTH / 2);
  }

  /**
   * @private
   * @return {string}
   */
  getStageSuffix() {
    if (this.isDevStage()) {
      return this.LOCALHOST;
    }
    if (this.isRelStage()) {
      return `${this.REL}${window.location.hostname.split(this.REL).pop()}`;
    }
    return '';
  }

  /**
   * @public
   * @return {boolean}
   */
  isRelStage() {
    return new RegExp(this.REL).test(window.location.hostname);
  }

  /**
   * @public
   * @return {boolean}
   */
  isDevStage() {
    return new RegExp(this.LOCALHOST).test(window.location.hostname);
  }
}
