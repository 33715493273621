import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import t from '@core/translations/translate';
import PHOTO_SIZE from '@core/user/photo/constants/PhotoSize';
import getPrimaryPhotoData from '@core/user/photo/utils/getPrimaryPhotoData';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';
import createUrlFor from '@core/utils/url/createUrlFor';
import PRESET from '@core/user/photo/constants/PhotoPreset';

import {OverlayPosition, OverlayType, SpacingSize} from '@phoenix/ui/constants';
import {ButtonPrimary, Notice, Overlay} from '@phoenix/ui';
import Photo from '@phoenix/user/photo/containers/Photo';

import css from '../styles/VirtualAssistantPopupLayout.css';

const VirtualAssistantPopup = ({id, profile}) => {
  const history = useHistory();
  const isSmallTablet = useSmallTabletBreakpoint();

  return (
    <div className={css.wrap}>
      <Photo
        userId={id}
        size={PHOTO_SIZE.NONE}
        photo={getPrimaryPhotoData(profile, PRESET.AVATAR_BIG)}
        className={css.image}
        customOverlay={
          <Overlay
            type={OverlayType.GRADIENT_FROM_BOTTOM}
            position={OverlayPosition.BOTTOM}
            className={css.overlay}
          />
        }
      />
      <Notice
        className={css.content}
        title={t('virtualAssistant', 'title.talkToMe')}
        actions={[
          <ButtonPrimary
            onClick={() => history.push(createUrlFor.chatWith(id))}
            trackingName="chat"
            adaptive
            fullWidth
          >
            {t('virtualAssistant', 'button.chatNow')}
          </ButtonPrimary>,
        ]}
        spacing={isSmallTablet ? SpacingSize.NORMAL : SpacingSize.LARGE}
        inverse
      />
    </div>
  );
};

VirtualAssistantPopup.propTypes /* remove-proptypes */ = {
  id: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    gender: PropTypes.string.isRequired,
    photos: PropTypes.shape({
      primaryPhoto: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  }),
};

export default VirtualAssistantPopup;
