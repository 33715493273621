import defer from 'lodash/defer';

import {getClientInstance} from '@core/graphql/client';
import type {PaymentDataResponse} from '@core/payment/payProcess/types/paymentFlow';

const updateIsOneClickAllowedAfterPayment = (
  paymenResponse: PaymentDataResponse,
): PaymentDataResponse => {
  /**
   * 'defer' needs let OneClickParamsQuery has finished request from request batch, and after that the cache will
   * be evicted, and another request with new variables started in new request batch
   */
  defer(() => {
    getClientInstance().cache.evict({
      id: 'Payment:{}',
      fieldName: 'isOneClickAllowed',
      broadcast: !paymenResponse.status,
    });
  });

  return paymenResponse;
};

export default updateIsOneClickAllowedAfterPayment;
