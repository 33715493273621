import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';

import CustomPopupLayout from '../components/CustomPopupLayout';

export default function openCustomPopup({onAccept, onClose}) {
  PopupService.openPopup(
    <CustomPopupLayout
      onButtonClick={() => {
        PopupService.closePopup();
        onAccept();
      }}
    />,
    {
      onClose: (sourceEventType) => {
        if (
          [
            PopupSourceEvent.CLOSE_BUTTON_CLICK,
            PopupSourceEvent.BY_ESCAPE,
          ].includes(sourceEventType) &&
          onClose
        ) {
          onClose();
        }
      },
      small: true,
      trackingName: 'webPushCustom',
      autoLoadTracking: true,
    },
  );
}
