import PropTypes from 'prop-types';

import useRemarketingConcept from '@phoenix/remarketing/offerPopup/utils/useRemarketingConcept';

/**
 * Preload remarkeing concept
 * @param conceptName
 * @return {JSX.Element|null}
 * @constructor
 */
const RemarketingConceptPreload = ({conceptName}) => {
  useRemarketingConcept(conceptName);

  return null;
};

RemarketingConceptPreload.propTypes /* remove-proptypes */ = {
  conceptName: PropTypes.string.isRequired,
};

export default RemarketingConceptPreload;
