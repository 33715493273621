import PRESET from '@core/user/photo/constants/PhotoPreset';

/**
 * Search widget photo preset
 * Used in
 * @see normalizePropsForSearchWidget and TargetUserProfileAsListWithToolbarAndBlurredActions
 *
 */
const SEARCH_WIDGET_PHOTO_PRESET: PRESET = PRESET.AVATAR_BIG;

export default SEARCH_WIDGET_PHOTO_PRESET;
