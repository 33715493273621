import React from 'react';
import PropTypes from 'prop-types';

import {Icon} from '@phoenix/ui';

import {ICONS} from '../constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesIconLayout from './MicrofeaturesIconLayout';
import css from '../styles/MicrofeaturesIconTelegramConversation.css';

const MicrofeaturesIconTelegramConversation = ({active = false, ...props}) => (
  <MicrofeaturesIconLayout
    icon={
      <Icon
        type={ICONS[MICROFEATURES.TELEGRAM_CONVERSATION]}
        inherit={!active}
      />
    }
    className={css.layout}
    active={active}
    {...props}
  />
);

MicrofeaturesIconTelegramConversation.SIZE = MicrofeaturesIconLayout.SIZE;

MicrofeaturesIconTelegramConversation.propTypes /* remove-proptypes */ = {
  active: PropTypes.bool,
};

export default MicrofeaturesIconTelegramConversation;
