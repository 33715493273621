import React from 'react';

import {ICONS} from '../../icons/constants/icons';
import {MICROFEATURES} from '../../common/constants/microfeatures';
import MicrofeaturesPopupAnimationLayout from './MicrofeaturesPopupAnimationLayout';
import css from '../styles/MicrofeaturesAnimationIncognito.css';

const ICONS_COUNT = 5;

const MicrofeaturesAnimationIncognito = () => (
  <MicrofeaturesPopupAnimationLayout
    iconsCount={ICONS_COUNT}
    iconType={ICONS[MICROFEATURES.MICRO_INCOGNITO]}
    className={css.icon}
  />
);

export default MicrofeaturesAnimationIncognito;
