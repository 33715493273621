import {MICROFEATURES} from '../../common/constants/microfeatures';

export const ICONS = {
  [MICROFEATURES.MICRO_INCOGNITO]: 'incognito',
  [MICROFEATURES.MORE_LIKES]: 'heart',
  [MICROFEATURES.PRIORITY_MAN]: 'crown',
  [MICROFEATURES.SEARCH_QUEEN]: 'crown',
  [MICROFEATURES.SUPER_BOOST]: 'lightning',
  [MICROFEATURES.FLIRT_ALARM]: 'sex-alarm',
  [MICROFEATURES.SEX_ALARM]: 'sex-alarm',
  [MICROFEATURES.TOP_IN_CHAT]: 'top-messenger',
  [MICROFEATURES.MICROFEATURE_OFFER]: 'rocket',
  [MICROFEATURES.TELEGRAM_CONVERSATION]: 'send',
};
