import React, {useMemo} from 'react';
import get from 'lodash/get';
import {useQuery} from '@apollo/client';

import CURRENT_USER_PHOTOS_QUERY from '@core/user/profile/current/graphql/queries/currentUserPhotos.gql';
import sortPhotosByCreateDate from '@core/user/profile/current/utils/sortPhotosByCreateDate';
import getSortedUserPhotos from '@core/user/profile/utils/getSortedUserPhotos';

import openGalleryPopup from '@phoenix/gallery/utils/openGalleryPopup';

import ProfileGalleryPhotoControls from '../../current/containers/ProfileGalleryPhotoControls';
import TARGET_USER_PHOTOS_QUERY from '../../target/graphql/queries/targetUserPhotos.gql';
import usePmaRequestPhoto from '../../../photo/utils/usePmaRequestPhoto';

const getMediaDataFromPhotos = (photos) =>
  photos.map((photo) => ({id: photo.id, photo, original: photo.id}));

const getPhotoQuery = (userId, my) =>
  my
    ? [CURRENT_USER_PHOTOS_QUERY]
    : [TARGET_USER_PHOTOS_QUERY, {variables: {id: userId}}];

export const useGetPhotos = ({userId, my}) => {
  const [query, options] = useMemo(
    () => getPhotoQuery(userId, my),
    [userId, my],
  );
  const {data, error, loading: queryLoading} = useQuery(query, options);

  const {loading: pmaPhotoRequestLoading, available} = usePmaRequestPhoto();

  const getUserPhotos = () => {
    const userPhotos = getSortedUserPhotos(
      get(data, 'user.profile.photos.primaryPhoto.id', ''),
      get(data, 'user.profile.photos.allPhotos') || [],
    );

    if (available) {
      return userPhotos.filter((photo) => !photo.privateAttributes?.isPrivate);
    }

    return userPhotos;
  };

  let photos = my
    ? sortPhotosByCreateDate(get(data, 'myUser.profile.photos.allPhotos'))
    : getUserPhotos();

  photos = photos || [];

  const loading = pmaPhotoRequestLoading || queryLoading;

  return useMemo(() => {
    if (error || loading) return {error, loading};

    return {error, loading, media: getMediaDataFromPhotos(photos)};
  }, [error, loading, photos]);
};

/**
 * Wrapper component for getting data for photo gallery
 * @param userId
 * @param my
 * @param children
 * @param props
 * @returns {null|*}
 * @constructor
 */
export const PhotoGalleryData = ({userId, my, children, ...props}) => {
  const {error, loading, media} = useGetPhotos({userId, my});
  if (error || loading) return null;

  return children({media, userId, my, ...props});
};

/**
 * Render prop for render controls for photo
 * @see Gallery.js
 * @param currentMedia
 * @returns {*}
 */
export const photoControlsRenderer = (currentMedia) => (
  <ProfileGalleryPhotoControls currentMedia={currentMedia} />
);

const openPhotoGallery = ({
  userId,
  my,
  photoId,
  needGTMTrack = false,
  withShadow,
  showLoadingBackground,
}) =>
  openGalleryPopup({
    userId,
    showLoadingBackground,
    my,
    needGTMTrack,
    withShadow,
    initialMediaId: photoId,
    showNav: true,
    showCounter: true,
    bottomRenderer: my ? photoControlsRenderer : null,
    GalleryData: PhotoGalleryData,
  });

export default openPhotoGallery;
