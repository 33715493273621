import {writeToSessionStorage} from '@core/utils/storage';

import {RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED} from '../../constants/storageKeys';

/**
 * Update recipient id sessionStorage for global free messages (hybrid model)
 * @param client
 * @param {string|null} userId
 */
const updateGlobalFreeMessagesEndedRecipient = ({userId}) => {
  writeToSessionStorage(RECIPIENT_ID_WHERE_FREE_MESSAGES_ENDED, userId);
};

export default updateGlobalFreeMessagesEndedRecipient;
