import PaymentPageAppearance from '@core/theming/constants/features/PaymentPageAppearance';

import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

const useIsLayeredPage = () => {
  const {data: pageType, loading} = useThemeFeature('payment', 'page');

  return {
    isLayered: pageType === PaymentPageAppearance.LAYERED,
    loading,
  };
};

export default useIsLayeredPage;
