import {from, pipe} from 'rxjs';
import {map, mergeMap, takeWhile} from 'rxjs/operators';
import isNull from 'lodash/isNull';

import IN_APP_MOTIVATION_QUERY from '@phoenix/inAppMotivation/graphql/queries/inAppMotivation.gql';
import openInAppMotivationPopup from '@phoenix/inAppMotivation/utils/openInAppMotivationPopup';
import getMotivationRouteChecker from '@phoenix/inAppMotivation/utils/getMotivationRouteChecker';

/**
 * @param {ApolloClient} client
 *
 * @returns {Object} result
 * @returns {Function} result.operations
 * @returns {Function} result.subscription
 */
const inAppMotivationPopupAction = (client) => ({
  operations: () =>
    pipe(
      mergeMap(
        ([
          {
            data: {
              route: {current, previous},
            },
          },
        ]) => {
          return from(
            client.query({
              query: IN_APP_MOTIVATION_QUERY,
            }),
          ).pipe(
            map(
              ({
                data: {
                  userFeatures: {
                    inAppParams: {motivation},
                  },
                },
              }) => {
                return {
                  motivation,
                  motivationType: getMotivationRouteChecker(current, previous),
                };
              },
            ),
          );
        },
      ),
      takeWhile(({motivation: {allowed}}) => allowed),
    ),
  subscription: ({
    motivation: {linkType, allowed, alterBtns},
    motivationType,
  }) => {
    if (!allowed || isNull(motivationType)) {
      return;
    }

    openInAppMotivationPopup({linkType, motivationType, alterBtns});
  },
});

export default inAppMotivationPopupAction;
