import React, {useEffect} from 'react';

import PopupService from '@core/popup/utils/PopupService';

import GalleryWithMedia from '../containers/GalleryWithMedia';
import css from '../styles/GalleryPopup.css';

const GalleryPopup = (galleryProps) => {
  const {media, bottomRenderer} = galleryProps;

  useEffect(() => {
    if (!media.length) {
      PopupService.closePopup();
    }
  }, [media]);
  return (
    <GalleryWithMedia
      className={!bottomRenderer ? css.spacedBelow : null}
      {...galleryProps}
    />
  );
};

export default GalleryPopup;
