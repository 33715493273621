import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PopupService from '@core/popup/utils/PopupService';
import PopupLoadTracker from '@core/popup/containers/PopupLoadTracker';
import t from '@core/translations/translate';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';

import {Button, Group, Spacing} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';
import {H3} from '@phoenix/typography';

import css from '../styles/ContentRestriction.css';

const closePopup = () => {
  PopupService.closePopup(false);
};

const ContentRestriction = ({siteName}) => {
  const isSmallTablet = useSmallTabletBreakpoint();

  return (
    <Spacing size={SpacingSize.LARGE} adaptive>
      <Group>
        <H3 className={css.restriction}>
          {t('popupNaughtyMode', 'text.restriction-link')}
        </H3>
        <div className={css.restriction}>
          {t('popupNaughtyMode', 'text.rp-restriction-part-1', {
            '{siteName}': siteName,
          })}
        </div>
        <div className={css.restriction}>
          {t('popupNaughtyMode', 'text.rp-restriction-part-2', {
            '{siteName}': siteName,
            // eslint-disable-next-line jsx-a11y/heading-has-content -- `h4` and `span` content will be added by `t`.
            '{h4}': <h4 />,
            '{span}': <span />,
          })}
        </div>
        <Button
          className={cn(!isSmallTablet && css.action)}
          fullWidth={isSmallTablet}
          onClick={closePopup}
          trackingName="closePopup"
        >
          {t('popupNaughtyMode', 'text.go-back')}
        </Button>
      </Group>
      <PopupLoadTracker />
    </Spacing>
  );
};

ContentRestriction.propTypes /* remove-proptypes */ = {
  siteName: PropTypes.string.isRequired,
};

export default ContentRestriction;
