import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import t, {createTranslationsMap} from '@core/translations';
import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';
import Timer from '@core/utils/timer';

import HolidayName from '@phoenix/payment/widgets/motivation/constants/HolidayName';
import {H3, Title1, Title3, TextMuted} from '@phoenix/typography';
import {
  Align,
  ButtonType,
  SpacingSize,
  SpacingDirection,
} from '@phoenix/ui/constants';
import {Icon, ButtonLink, Spacing, InlineGroup, Row} from '@phoenix/ui';

import {
  EXTRA_DISCOUNT,
  INTERACTIVE_LIKE,
  PROFILES_VIEW,
  SEARCH_2X,
} from '../../../constants/comboContextTypes';
import css from '../styles/ExtraDiscountPopup.css';

const DEFAULT = '';

// export for tests
export const getTitleText = (comboContext) => {
  if (comboContext === PROFILES_VIEW) {
    return t('extraPopups', 'text.time_limited.profiles_view_combo', {
      '{br}': <br />,
    });
  }
  if (comboContext === SEARCH_2X) {
    return t('extraPopups', 'text.time_limited.search_view_combo');
  }
  return t('extraPopups', 'text.time_limited');
};

const getSubTitleText = createTranslationsMap((translate) => ({
  [HolidayName.BLACK_FRIDAY]: translate(
    'extraPopups',
    'text.new_discount_blackfriday',
  ),
  [HolidayName.BLACK_FRIDAY_ASIA]: translate(
    'extraPopups',
    'text.new_discount_blackfriday',
  ),
  [DEFAULT]: translate('extraPopups', 'text.new_discount'),
}));

const ExtraDiscountPopupLayout = ({
  comboContext,
  defaultDiscount,
  extra,
  time,
  url,
  onTimeOver,
  holiday,
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <div data-test="extraDiscountPopupLayout">
      <Spacing
        size={SpacingSize.LARGE}
        className={cn(css.banner, holiday && css[holiday])}
      >
        <Spacing
          adaptive={false}
          withoutBottom
          direction={SpacingDirection.VERTICAL}
          size={isPhone ? SpacingSize.LARGE : SpacingSize.NONE}
        >
          <H3 inverse>
            {getTitleText(comboContext)}
            <div>{getSubTitleText[holiday]}</div>
          </H3>
        </Spacing>
        <Row>
          <Title1 inverse className={css.percent}>
            {`${extra}%`}
          </Title1>
          <Title3 inverse className={css.text}>
            {t('extraPopups', 'text.new_discount_off')}
          </Title3>
        </Row>
        <TextMuted inverse className={css.discount}>
          <H3 inverse muted>
            {t('extraPopups', 'text.current_discount')}
            <div>
              {t('extraPopups', 'text.current_discount_off', {
                '{discountamount}': defaultDiscount,
              })}
            </div>
          </H3>
        </TextMuted>
      </Spacing>
      <Spacing>
        {time && (
          <Row align={Align.CENTER}>
            <InlineGroup space={SpacingSize.SHORT}>
              <TextMuted>{t('extraPopups', 'text.time_left')}</TextMuted>
              <Icon type="clock" muted />
              <Timer
                backward
                initialTime={time}
                duration={time}
                onTimeOver={onTimeOver}
              />
            </InlineGroup>
          </Row>
        )}
        <Spacing size={isPhone ? SpacingSize.NONE : SpacingSize.NORMAL}>
          <ButtonLink
            className={cn(!isPhone && css.action)}
            href={url}
            type={ButtonType.PAY}
            fullWidth={isPhone}
          >
            {t('extraPopups', 'text.want_it')}
          </ButtonLink>
        </Spacing>
      </Spacing>
    </div>
  );
};

ExtraDiscountPopupLayout.propTypes /* remove-proptypes */ = {
  comboContext: PropTypes.oneOf([
    EXTRA_DISCOUNT,
    INTERACTIVE_LIKE,
    PROFILES_VIEW,
    SEARCH_2X,
  ]),
  defaultDiscount: PropTypes.number.isRequired,
  extra: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  onTimeOver: PropTypes.func.isRequired,
  holiday: PropTypes.string.isRequired,
};

export default ExtraDiscountPopupLayout;
