import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyDescriptor = lazy(
  () =>
    import(
      /* webpackChunkName: 'descriptor' */ '@phoenix/descriptor/page/containers/DescriptorPageRoute'
    ),
);

const DescriptorChunk = (props) => (
  <SplitChunk component={LazyDescriptor} {...props} />
);

export default DescriptorChunk;
