import type {FC} from 'react';
import React from 'react';

import useSepaCredentialsPolicy from '../utils/useSepaCredentialsPolicy';
import PaymentCredentialsPolicyCommon from './PaymentCredentialsPolicyCommon';

const PaymentSepaCredentialsPolicy: FC = () => {
  const {
    loading,
    isCredentialsPolicyNeeded,
    credentialsPolicyDefaultValue,
    isCredentialsPolicyChecked,
    setIsCredentialsPolicyChecked,
  } = useSepaCredentialsPolicy();

  return (
    <PaymentCredentialsPolicyCommon
      loading={loading}
      isCredentialsPolicyNeeded={isCredentialsPolicyNeeded}
      credentialsPolicyDefaultValue={credentialsPolicyDefaultValue}
      isCredentialsPolicyChecked={isCredentialsPolicyChecked}
      setIsCredentialsPolicyChecked={setIsCredentialsPolicyChecked}
    />
  );
};

export default PaymentSepaCredentialsPolicy;
