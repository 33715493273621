import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyRemoveAccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'removeAccountPage' */ '@phoenix/multiStep/removeAccount/containers/RemoveAccountPage'
    ),
);

export default (props) => {
  return <SplitChunk component={LazyRemoveAccountPage} {...props} />;
};
