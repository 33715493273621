import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Link} from '@phoenix/typography';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

const handleOpenDigitalServiceAct = (e) => {
  StaticPagePopupHelper.openPopup(
    StaticPagePopupHelper.PAGES.DIGITAL_SERVICE_ACT,
    e,
  );
};

const FooterDigitalServiceAct = ({href}) => (
  <div>
    <Link
      small
      muted
      underline
      data-bypass=""
      onClick={handleOpenDigitalServiceAct}
      href={href}
    >
      {t('popupStaticPage', 'title.digitalServiceAct')}
    </Link>
  </div>
);

FooterDigitalServiceAct.propTypes /* remove-proptypes */ = {
  href: PropTypes.string.isRequired,
};

export default FooterDigitalServiceAct;
