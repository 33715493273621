/**
 * Country codes to identify which country document belongs
 * @type {string[]}
 */
export const COUNTRY_CODES = [
  'ALB',
  'AND',
  'ARE',
  'ARG',
  'ARM',
  'ASM',
  'AUS',
  'AUT',
  'AZE',
  'BEL',
  'BEN',
  'BGD',
  'BGR',
  'BHR',
  'BHS',
  'BIH',
  'BLR',
  'BOL',
  'BRA',
  'BRB',
  'CAN',
  'CHE',
  'CHL',
  'CHN',
  'CIV',
  'CMR',
  'COD',
  'COL',
  'CRI',
  'CUB',
  'CYP',
  'CZE',
  'DEU',
  'DJI',
  'DNK',
  'DOM',
  'DZA',
  'ECU',
  'EGY',
  'ESH',
  'ESP',
  'EST',
  'ETH',
  'FIN',
  'FRA',
  'GBR',
  'GEO',
  'GHA',
  'GIB',
  'GLP',
  'GMB',
  'GRC',
  'GRL',
  'GTM',
  'HKG',
  'HND',
  'HRV',
  'HTI',
  'HUN',
  'IDN',
  'IMN',
  'IND',
  'IRL',
  'IRN',
  'ISL',
  'ISR',
  'ITA',
  'JAM',
  'JPN',
  'KEN',
  'KOR',
  'KWT',
  'LBY',
  'LIE',
  'LKA',
  'LTU',
  'LUX',
  'LVA',
  'MAC',
  'MAR',
  'MCO',
  'MDA',
  'MDV',
  'MEX',
  'MKD',
  'MLT',
  'MMR',
  'MNE',
  'MNG',
  'MOZ',
  'MSR',
  'MTQ',
  'MUS',
  'MYS',
  'NGA',
  'NLD',
  'NOR',
  'NPL',
  'NZL',
  'PAK',
  'PAN',
  'PER',
  'PHL',
  'POL',
  'PRI',
  'PRT',
  'PRY',
  'PSE',
  'QAT',
  'REU',
  'ROU',
  'RUS',
  'SAU',
  'SDN',
  'SEN',
  'SGP',
  'SJM',
  'SLV',
  'SMR',
  'SOM',
  'SRB',
  'STP',
  'SUR',
  'SVK',
  'SVN',
  'SWE',
  'SWZ',
  'SYR',
  'TCA',
  'TGO',
  'THA',
  'TJK',
  'TKL',
  'TKM',
  'TON',
  'TTO',
  'TUN',
  'TUR',
  'TUV',
  'TWN',
  'TZA',
  'UGA',
  'UKR',
  'UMI',
  'URY',
  'USA',
  'UZB',
  'VAT',
  'VEN',
  'VIR',
  'VNM',
  'VUT',
  'WLF',
  'YEM',
  'ZAF',
  'ZMB',
  'ZWE',
];
