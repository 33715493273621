import memoize from 'lodash/memoize';

import getUserAgentParser from '@core/utils/getUserAgentParser';

const LAST_UNSUPPORTED_EDGE_VERSION = 78;

/**
 * Checks browser support for webRTC
 * @return {boolean}
 */
export default memoize(
  () =>
    /**
     * WebRTC available for Edge from 79 version
     * @see https://caniuse.com/#search=webrtc
     */
    !(
      getUserAgentParser().getBrowser().name === 'Edge' &&
      getUserAgentParser().getBrowser().major <= LAST_UNSUPPORTED_EDGE_VERSION
    ) &&
    !(
      getUserAgentParser().getBrowser().name === 'Chrome' &&
      getUserAgentParser().getOS().name === 'iOS'
    ) &&
    (Boolean(window.navigator.getUserMedia) ||
      Boolean(window.navigator.webkitGetUserMedia) ||
      Boolean(window.navigator.mozGetUserMedia) ||
      Boolean(window.navigator.msGetUserMedia) ||
      Boolean(window.RTCPeerConnection)),
);
