const STATUS = {
  /**
   * Service is ready for actions
   * Is the default one.
   */
  READY: 'ready',

  /**
   * Incoming call in progress
   */
  INCOMING: 'incoming',

  /**
   * Outgoing call in progress
   */
  OUTGOING: 'outgoing',

  /**
   * Opponent is BUSY (in calling status)
   */
  OPPONENT_BUSY: 'opponentBusy',

  /**
   * Video chat is in progress
   */
  CALLING: 'calling',

  /**
   * Missed call from opponent
   */
  MISSED: 'missed',

  /**
   * Opponent rejected incoming call
   */
  REJECT: 'reject',

  /**
   * Opponent stopped call in progress
   */
  STOP: 'stop',

  /**
   * Outgoing timeout ended, opponent didn't answer
   */
  NOANSWER: 'noAnswer',
};

export default STATUS;
