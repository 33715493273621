import {fromEvent} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import SUBSCRIBE_TO_WEB_PUSH_EVENT from '../constants/subscribeToWebPushEvent';
import NOTIFICATION_EVENT from '../constants/notificationEvent';

/**
 * @returns {Observable<Event>}
 */
const getCustomEventObservable = () =>
  fromEvent(window, SUBSCRIBE_TO_WEB_PUSH_EVENT).pipe(
    takeUntil(fromEvent(window, NOTIFICATION_EVENT.ACCEPTED)),
  );

export default getCustomEventObservable;
