import VERIFY_QUERY from '@phoenix/personalIdentity/graphql/queries/personalIdentity.gql';

/**
 * Personal identity verified access rule.
 */
export default {
  errorCode: 'PERSONAL_IDENTITY_VERIFIED',
  query: VERIFY_QUERY,
  resolver: (data) => {
    return (
      !data.userFeatures ||
      data.userFeatures.personalIdentity.improvesAllowed ||
      data.userFeatures.personalIdentity.required
    );
  },
};
