import {pipe} from 'rxjs';
import {filter, take, map} from 'rxjs/operators';

import parsePathname from '@core/utils/url/parsePathname';

import {MICROFEATURES} from '@phoenix/microfeatures/common/constants/microfeatures';
import openPaymentMicroFeaturePopup from '@phoenix/microfeatures/popups/utils/openPaymentMicroFeaturePopup';
import {AFTER_LOGIN_PLACEMENT} from '@phoenix/microfeatures/banners/constants/bannersPlacements';
import MICROFEATURES_AVAILABILITY_DATA_QUERY from '@phoenix/microfeatures/common/graphql/queries/microfeaturesAvailabilityData.gql';
import {MODEL_TYPES} from '@phoenix/microfeatures/common/constants/modelTypes';
import MICROFEATURES_LIST_QUERY from '@phoenix/microfeatures/common/graphql/queries/microfeaturesList.gql';

/**
 * Display microFeatures popup
 */
const microFeaturesPopupAction = (client) => ({
  operations: () =>
    pipe(
      filter(
        ([
          {
            data: {route},
          },
        ]) => {
          const {options, action, controller} = parsePathname(route.current);

          const microFeature = options?.featurePromo;
          return (
            controller === 'search' &&
            action === 'index' &&
            microFeature &&
            microFeature !== MICROFEATURES.TELEGRAM_CONVERSATION
          );
        },
      ),
      map(
        ([
          {
            data: {route},
          },
        ]) => {
          const {
            options: {featurePromo},
          } = parsePathname(route.current);

          return featurePromo;
        },
      ),
      take(1),
    ),
  subscription: async (microFeatureName) => {
    const {
      data: {
        microFeatures: {
          microFeaturesConfiguration: {modelType},
        },
      },
    } = await client.query({
      query: MICROFEATURES_AVAILABILITY_DATA_QUERY,
    });

    // check available microfeatures
    if (!modelType) {
      return;
    }

    const creditModel = modelType === MODEL_TYPES.CREDIT;
    await client.query({
      query: MICROFEATURES_LIST_QUERY,
      variables: {
        defaultModel: !creditModel,
        creditModelType: creditModel,
      },
    });

    openPaymentMicroFeaturePopup({
      featureName: microFeatureName,
      placement: AFTER_LOGIN_PLACEMENT,
    });
  },
});

export default microFeaturesPopupAction;
