import {BehaviorSubject, combineLatest, from} from 'rxjs';
import {distinctUntilChanged, filter} from 'rxjs/operators';

import isInRouteList from '@core/utils/routing/isInRouteList';
import {REQUIRED_PHOTO} from '@core/mediaUpload/constants/sources';
import PopupPriority from '@core/popup/constants/PopupPriority';
import isPayUrl from '@core/utils/url/isPayUrl';
import ROUTES_WITH_POPUP from '@core/application/constants/routesWithPopup';
import PopupService from '@core/popup/utils/PopupService';

import CURRENT_USER_PHOTOS_QUERY from '@phoenix/user/profile/current/graphql/queries/currentUserAllPhotos.gql';

import openPmaPhotoUploadPopup from './openPmaPhotoUploadPopup';
import ENABLED_REQUIRED_PHOTO_UPLOAD_QUERY from '../graphql/queries/enabledRequiredPhotoUpload.gql';

let startedListeners = false;
/**
 * Motivate upload photo when haven't even one primary photo.
 * @see SubscriptionsStarter.js
 */
const startRequiredPhotoUploadListener = async (client, history) => {
  // Avoiding multiple resubscribes in case of unpredictable cache updates.
  if (startedListeners) {
    return;
  }

  startedListeners = true;

  // Get info about availability for avoiding unneeded subscription.
  const {
    data: {
      userFeatures: {enabledRequiredPhotoUpload},
    },
  } = await client.query({
    query: ENABLED_REQUIRED_PHOTO_UPLOAD_QUERY,
  });

  if (!enabledRequiredPhotoUpload) {
    return;
  }

  const currentUserPhotos$ = from(
    client.watchQuery({
      query: CURRENT_USER_PHOTOS_QUERY,
    }),
  );

  const pathname$ = new BehaviorSubject(history.location.pathname);
  history.listen(({pathname}) => {
    pathname$.next(pathname);
  });

  combineLatest([pathname$.pipe(distinctUntilChanged()), currentUserPhotos$])
    .pipe(
      // Param count
      // When primary photo update interaction changePrimaryPhotoNotice. Sometimes it's not quickly.
      // But photo count update after upload backend in photo/upload?via=update_profile&attachToUser=true,
      // @see src/packages/core/mediaUpload/utils/putPhotoInCache.js. That's why check by count.
      // Param possibleNewPrimaryPhoto
      // When remove current primary photo from profile and mark current primary as pendingDelete quickly,
      // @see src/packages/core/user/profile/current/utils/cacheUpdaters.js const removePhotoFromCache.
      // Before slow interaction changePrimaryPhotoNotice need check exists possibleNewPrimaryPhoto.
      // This photo mark primary with pendingDelete:false later when complete interaction.
      filter(([pathname, {data}]) => {
        if (isPayUrl(pathname) || isInRouteList(ROUTES_WITH_POPUP, pathname)) {
          return false;
        }

        const {photos: {primaryPhoto, count, allPhotos} = {}} =
          data.myUser.profile;

        const possibleNewPrimaryPhoto = Boolean(
          allPhotos.find((photo) => photo.pendingDelete === false),
        );

        return (
          (!primaryPhoto && count === 0) ||
          (primaryPhoto &&
            primaryPhoto.pendingDelete &&
            !possibleNewPrimaryPhoto)
        );
      }),
    )
    .subscribe(() => {
      openPmaPhotoUploadPopup({
        initialSource: REQUIRED_PHOTO,
        onClose: (data) => PopupService.closePopup(data),
        priority: PopupPriority.LOW,
        showCloseButton: false,
      });
    });
};

export default startRequiredPhotoUploadListener;
