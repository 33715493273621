import type {LevelTextsType} from '@core/user/photo/constants/levelTexts';
import LEVEL_TEXTS from '@core/user/photo/constants/levelTexts';
import PhotoLevel from '@core/user/photo/constants/PhotoLevel';
import PhotoSize from '@core/user/photo/constants/PhotoSize';
import createTranslationsMap from '@core/translations/createTranslationsMap';

export const NAUGHTY_MODE_TEXTS: LevelTextsType = {
  [PhotoSize.NORMAL]: createTranslationsMap((t) => ({
    DEFAULT: t('popupNaughtyMode', 'text.description_video'),
  })),
  [PhotoSize.NONE]: LEVEL_TEXTS[PhotoSize.NONE],
  DEFAULT: createTranslationsMap((t) => ({
    [PhotoLevel.BLURRED]: t('userPhotoWidget', 'Naughty video'),
    DEFAULT: t('userPhotoWidget', 'Explicit nudity'),
  })),
};

/**
 * Used for clean product restrictions logic (@see withCleanProductRestrictions in useNaughtyMode.js)
 */
export const NAUGHTY_MODE_TEXTS_CLEAN: LevelTextsType = {
  [PhotoSize.NORMAL]: createTranslationsMap((t) => ({
    DEFAULT: t('popupNaughtyMode', 'text.description_video'),
  })),
  [PhotoSize.NONE]: LEVEL_TEXTS[PhotoSize.NONE],
  DEFAULT: createTranslationsMap((t) => ({
    [PhotoLevel.BLURRED]: t('userPhotoWidget', 'Flirty video'),
    DEFAULT: t('userPhotoWidget', 'Saucy'),
  })),
};

export default NAUGHTY_MODE_TEXTS;
