import MediaUploadAppearance from '@core/theming/constants/features/MediaUploadAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-43653
 */
type MediaUploadWithComponent = any;

const resolver: ModuleResolver<
  MediaUploadAppearance,
  MediaUploadWithComponent
> = (value) => {
  switch (value) {
    case MediaUploadAppearance.WITH_PHOTO_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadWithPhoto' */ '@phoenix/mediaUpload/constants/mediaUploadWithPhotoMotivation'
      );
    case MediaUploadAppearance.WITH_TILE_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadWithTile' */ '@phoenix/mediaUpload/constants/mediaUploadWithTileMotivation'
      );
    case MediaUploadAppearance.WITH_MOTIVATION_AS_NOTICE:
      return import(
        /* webpackChunkName: 'mediaUploadAsNotice' */ '@phoenix/mediaUpload/constants/mediaUploadWithMotivationAsNotice'
      );
    case MediaUploadAppearance.WITH_LARGE_PHOTO_MOTIVATION_AND_LOGO:
      return import(
        /* webpackChunkName: 'mediaUploadLargePhotoLogo' */ '@phoenix/mediaUpload/constants/mediaUploadWithLargePhotoMotivationAndLogo'
      );
    case MediaUploadAppearance.WITH_HIGHLIGHTED_MOTIVATION_AS_NOTICE:
      return import(
        /* webpackChunkName: 'mediaUploadHighlighted' */ '@phoenix/mediaUpload/constants/mediaUploadWithHighlightedMotivationAsNotice'
      );
    case MediaUploadAppearance.WITH_MOTIVATION_AS_UPLOAD_NOTICE:
      return import(
        /* webpackChunkName: 'mediaUploadNoticeUpload' */ '@phoenix/mediaUpload/constants/mediaUploadWithMotivationAsUploadNotice'
      );
    case MediaUploadAppearance.WITH_FULL_SIZE_PHOTO_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadFullSize' */ '@phoenix/mediaUpload/constants/mediaUploadWithFullSizePhotoMotivation'
      );
    case MediaUploadAppearance.WITH_BLURRED_PHOTO_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadBlurred' */ '@phoenix/mediaUpload/constants/mediaUploadWithBlurredPhotoMotivation'
      );
    case MediaUploadAppearance.WITH_INVERSE_BACKGROUND_AND_UPLOAD_ACTION_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadInverse' */ '@phoenix/mediaUpload/constants/mediaUploadWithInverseBackgroundAndUploadActionMotivation'
      );
    case MediaUploadAppearance.WITH_PHOTO_ON_BACKGROUND_AS_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadBackground' */ '@phoenix/mediaUpload/constants/mediaUploadWithPhotoOnBackgroundAsMotivation'
      );
    case MediaUploadAppearance.WITH_GALLERY_LIST_AS_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadGallery' */ '@phoenix/mediaUpload/constants/mediaUploadWithGalleryListAsMotivation'
      );
    case MediaUploadAppearance.WITH_RANDOM_USERS_AND_NOTICE_ICON_AS_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadRandomUsers' */ '@phoenix/mediaUpload/constants/mediaUploadWithRandomUsersAsMotivation'
      );
    case MediaUploadAppearance.WITH_LARGE_PHOTO_MOTIVATION_AND_UPLOAD_ICON:
      return import(
        /* webpackChunkName: 'mediaUploadLargePhotoIconUpload' */ '@phoenix/mediaUpload/constants/mediaUploadWithLargePhotoAndUploadIcon'
      );
    case MediaUploadAppearance.WITH_PROFILE_PROGRESSION_AS_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadProgress' */ '@phoenix/mediaUpload/constants/mediaUploadWithProfileProgressionAsMotivation'
      );
    case MediaUploadAppearance.WITH_HORIZONTAL_ICONS_AS_MOTIVATION:
      return import(
        /* webpackChunkName: 'mediaUploadHorizontalIcons' */ '@phoenix/mediaUpload/constants/mediaUploadWithHorizontalIconsAsMotivation'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable media (photo & video) upload popup based on theme.
 * Returns object of composite components that are used for theming.
 *
 * Also we can't use "ThemedComponentLoader" since it will create additional nesting with few KB chunk
 * and will cause cyclic dependency breaking during build time (see "import/no-cycle" mentions in @phoenix/mediaUpload workspace)
 */
const useThemedMediaUpload = () =>
  useThemeCompositeFeatureLoader<
    MediaUploadAppearance,
    MediaUploadWithComponent
  >('mediaUpload', 'appearance', resolver);

export default useThemedMediaUpload;
