import React from 'react';
import PropTypes from 'prop-types';

import {IconSubstrate, Row, RowShort} from '@phoenix/ui';
import {H3, TextMuted} from '@phoenix/typography';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import {IconType} from '@phoenix/ui/constants';

import css from '../styles/MicrofeaturesSuccessPopupLayout.css';

const MicrofeaturesSuccessPopupLayout = ({
  header,
  title,
  content,
  action,
  animationBlock,
}) => (
  <PopupFrame
    // data-test is needed for automated tests.
    data-test="microfeaturesSuccessPopup"
    content={
      <div className={css.centered}>
        {Boolean(animationBlock) && (
          <div className={css.animation}>{animationBlock}</div>
        )}
        <Row className={css.header}>
          {header}
          <IconSubstrate
            className={css.subIcon}
            icon="check"
            type={IconType.SUCCESS}
          />
        </Row>
        <RowShort>
          <H3>{title}</H3>
        </RowShort>
        <TextMuted>{content}</TextMuted>
      </div>
    }
    actions={action}
  />
);

MicrofeaturesSuccessPopupLayout.propTypes /* remove-proptypes */ = {
  header: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  action: PropTypes.node.isRequired,
  animationBlock: PropTypes.node,
};

export default MicrofeaturesSuccessPopupLayout;
