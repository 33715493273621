/**
 * @const {Object}
 * @see PaymentPackageHeader.js
 * @see useThemedPackageHeaderProps.js
 */
const PAYMENT_PACKAGE_HEADER_COLOR = {
  HIGHLIGHTED: 'highlighted',
  HEADER: 'header',
};

export default PAYMENT_PACKAGE_HEADER_COLOR;
