import React from 'react';
import PropTypes from 'prop-types';

import {TextWithIcon} from '@phoenix/typography';
import {
  SpacingSize,
  VerticalAlign,
  Align,
  IconType,
} from '@phoenix/ui/constants';

import css from '../styles/UploadErrorWrapper.css';

const UploadErrorWrapper = ({children, align}) => (
  <div data-test="uploadErrorWrapper">
    <TextWithIcon
      icon="danger"
      small
      space={SpacingSize.SHORT}
      verticalAlign={VerticalAlign.CENTER}
      align={align}
      iconType={IconType.DANGER}
      textType={TextWithIcon.TYPE.DANGER}
    >
      <div className={css.error}>{children}</div>
    </TextWithIcon>
  </div>
);

UploadErrorWrapper.propTypes /* remove-proptypes */ = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(Object.values(Align)),
};

export default UploadErrorWrapper;
