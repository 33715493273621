enum HolidayName {
  BEST_FRIENDS_DAY = 'bestfriendsday',
  BLACK_FRIDAY = 'blackfriday',
  BLACK_FRIDAY_ASIA = 'blackfridayasia',
  BOURBON_DAY = 'bourbonday',
  HALLOWEEN = 'halloween',
  CANADA_DAY = 'canadaday',
  CHOCOLATE_CHIP_DAY = 'chocolatechipday',
  CHOCOLATE_MILKSHAKE_DAY = 'chocolatemilkshakeday',
  CINCO_DE_MAYO = 'cincodemayo',
  CHRISTMAS_DAY = 'christmasday',
  CHUSEOK = 'chuseok',
  JUNE_SOLSTICE = 'junesolstice',
  COLUMBUS_DAY = 'columbusday',
  HAMBURGER_DAY = 'hamburgerday',
  INDEPENDENCE_DAY = 'independenceday',
  IDAHOT = 'idahot',
  GAY_PRIDE = 'gaypride',
  LABOR_DAY = 'laborday',
  LAFETENATIONALE = 'lafetenationale',
  NATIONAL_ABORIGINAL_DAY = 'nationalaboriginalday',
  NATIONAL_BEER_DAY = 'nationalbeerday',
  NATIONAL_DANCE_DAY = 'nationaldanceday',
  NATIONAL_DONUT_DAY = 'nationaldonutday',
  NATIONAL_WINE_DAY = 'nationalwineday',
  NEW_YEAR_DAY = 'newyearday',
  OCTOBER_FEST = 'octoberfest',
  PIZZA_PARTY_DAY = 'pizzapartyday',
  ST_PATRICS_DAY = 'stpatricsday',
  THANKSGIVING = 'thanksgiving',
  VALENTINE_DAY = 'valentineday',
  QUARANTINE = 'quarantine',
}

export default HolidayName;
