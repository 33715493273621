import {from, fromEvent} from 'rxjs';
import {takeUntil, filter} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';

import INTERACTION_WEB_PUSH_SUBSCRIPTION from '../graphql/subscriptions/interactionWebPush.gql';
import NOTIFICATION_EVENT from '../constants/notificationEvent';

/**
 * @param {String} action
 * @returns {Observable<A>}
 */
const getInteractionObservable = (action) =>
  from(
    getClientInstance().subscribe({query: INTERACTION_WEB_PUSH_SUBSCRIPTION}),
  ).pipe(
    filter(({data: {action: incomingAction}}) => incomingAction === action),
    takeUntil(fromEvent(window, NOTIFICATION_EVENT.ACCEPTED)),
  );

export default getInteractionObservable;
