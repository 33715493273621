import React from 'react';
import PropTypes from 'prop-types';

import replaceParams from '@core/translations/utils/replaceParams';
import setBaseParamsToDescriptionText from '@core/payment/widgets/packageDescription/utils/setParamsToDescriptionText';
import useActivePackage from '@core/payment/widgets/package/utils/useActivePackage';

import {Text} from '@phoenix/typography';
import setParamsToDescriptionText from '@phoenix/payment/widgets/packageDescription/utils/setParamsToDescriptionText';
import getDescriptionLinks from '@phoenix/payment/widgets/packageDescription/utils/getDescriptionLinks';

import RemarketingToggleSubscriptionLink from './RemarketingToggleSubscriptionLink';

const RemarketingPackageDescription = ({
  translateMessages,
  paymentButtonTitle,
  onCancelChange,
  isStrictLegalMode,
}) => {
  const {activePackage} = useActivePackage();
  const {packagePricingDetails, repeatPackage} = activePackage;

  return translateMessages.map((translateMessage) => (
    <Text
      key={translateMessage}
      small
      type={isStrictLegalMode ? Text.TYPE.NORMAL : Text.TYPE.MUTED}
    >
      {replaceParams(
        setParamsToDescriptionText({
          translateMessage: setBaseParamsToDescriptionText({
            translateMessage,
            packagePricingDetails,
            repeatPackage,
          }),
          paymentButtonTitle,
        }),
        {
          ...getDescriptionLinks(),
          '{cancelLink}': (
            <RemarketingToggleSubscriptionLink
              onCancelChange={onCancelChange}
            />
          ),
        },
      )}
    </Text>
  ));
};

RemarketingPackageDescription.propTypes /* remove-proptypes */ = {
  translateMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  paymentButtonTitle: PropTypes.string,
  onCancelChange: PropTypes.func.isRequired,
  isStrictLegalMode: PropTypes.bool,
};

export default RemarketingPackageDescription;
