import type {FC} from 'react';
import React from 'react';

import {PhotoRequestTypeEnum} from '@core/types';
import type PhotoSize from '@core/user/photo/constants/PhotoSize';

import type PhotoRequestData from '../types/PhotoRequestData';
import PmaRequestPhotoOverlay from '../containers/PmaRequestPhotoOverlay';
import PrivateAlbumPhotoOverlay from '../containers/PrivateAlbumPhotoOverlay';

type PrivateOverlayProps = {
  size: PhotoSize;
  photoRequestData: PhotoRequestData;
};

const PrivateOverlay: FC<PrivateOverlayProps> = ({size, photoRequestData}) => {
  switch (photoRequestData?.requestType) {
    case PhotoRequestTypeEnum.pmaPhoto:
      return (
        <PmaRequestPhotoOverlay
          size={size}
          photoRequestData={photoRequestData}
        />
      );
    case PhotoRequestTypeEnum.privateAlbum:
      if (photoRequestData?.isRequested) {
        return null;
      }

      return <PrivateAlbumPhotoOverlay photoRequestData={photoRequestData} />;
    default:
      return null;
  }
};

export default PrivateOverlay;
