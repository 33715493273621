import type {Observable} from 'rxjs';
import {merge} from 'rxjs';

import type {CommonNotification} from '@core/systemNotifications/types';
import connectionDataSource from '@core/systemNotifications/dataSources/connectionDataSource';
import disapproveDataSource from '@core/systemNotifications/dataSources/disapproveDataSource';
import photoApproveAndDisapproveDataSource from '@core/systemNotifications/dataSources/photoApproveAndDisapproveDataSource';
import photoUploadDataSource from '@core/systemNotifications/dataSources/photoUploadDataSource';
import videoApproveAndDisapproveDataSource from '@core/systemNotifications/dataSources/videoApproveAndDisapproveDataSource';
import videoConvertDataSource from '@core/systemNotifications/dataSources/videoConvertDataSource';
import videoMuteDataSource from '@core/systemNotifications/dataSources/videoMuteDataSource';
import getBootstrapParam from '@core/application/utils/getBootstrapParam';
import {IN_APP_NAME} from '@core/application/constants/bootstrapParams';
import InAppBrowserName from '@core/application/constants/inAppBrowserName';

import trustedDataSource from './trustedDataSource';
import storiesDeclineGroupVideoDataSource from './storiesDeclineGroupVideoDataSource';
import ageVerificationDataSource from './ageVerificationDataSource';
import photoPendingDeleteDataSource from './photoPendingDeleteDataSource';
import storiesDisapproveDataSource from './storiesDisapproveDataSource';
import browserChangeMotivationDataSource from './browserChangeMotivationDataSource';
import resentMessagesDataSource from './resentMessagesDataSource';
import successPaymentDataSource from './successPaymentDataSource';

const getObserver = () => {
  const dataSources: Observable<CommonNotification>[] = [
    connectionDataSource(),
    disapproveDataSource(),
    photoApproveAndDisapproveDataSource(),
    photoUploadDataSource(),
    videoApproveAndDisapproveDataSource(),
    videoConvertDataSource(),
    trustedDataSource(),
    ageVerificationDataSource(),
    photoPendingDeleteDataSource(),
    videoMuteDataSource(),
    storiesDisapproveDataSource(),
    resentMessagesDataSource(),
    storiesDeclineGroupVideoDataSource(),
    successPaymentDataSource(),
  ];

  if (getBootstrapParam(IN_APP_NAME) !== InAppBrowserName.NORMAL_BROWSER) {
    dataSources.push(browserChangeMotivationDataSource());
  }

  return merge(...dataSources);
};

export default getObserver;
