import SearchWidgetAppearance from '@core/theming/constants/features/SearchWidgetAppearance';

import useThemeCompositeFeatureLoader from './useThemeCompositeFeatureLoader';
import type {ModuleResolver} from '../types';

/**
 * @todo will be added in refactoring of the search components to typescript FE-42413
 */
type SearchWidgetComponent = any;

const resolver: ModuleResolver<
  SearchWidgetAppearance,
  SearchWidgetComponent
> = (value) => {
  switch (value) {
    case SearchWidgetAppearance.ALL_IN_CARD:
      return import(
        /* webpackChunkName: 'searchWidgetCard' */ '@phoenix/search/widget/constants/searchWidgetAllInCardComponents'
      );
    case SearchWidgetAppearance.ALL_IN_CARD_WITH_BLURRED_ACTIONS:
      return import(
        /* webpackChunkName: 'searchWidgetBlurredCard' */ '@phoenix/search/widget/constants/searchWidgetAllInCardWithBlurredActionsComponents'
      );
    case SearchWidgetAppearance.ALL_IN_CARD_WITH_ROUND_PHOTO:
      return import(
        /* webpackChunkName: 'searchWidgetCardWithRoundPhoto' */ '@phoenix/search/widget/constants/searchWidgetAllInCardWithRoundPhotoComponents'
      );
    case SearchWidgetAppearance.FLOATING_BUTTONS:
      return import(
        /* webpackChunkName: 'searchWidgetSeparated' */ '@phoenix/search/widget/constants/searchWidgetFloatingButtonsComponents'
      );
    case SearchWidgetAppearance.ROUNDED:
      return import(
        /* webpackChunkName: 'searchWidgetRounded' */ '@phoenix/search/widget/constants/searchWidgetRoundedComponents'
      );
    case SearchWidgetAppearance.ROUNDED_WITH_ONLINE_AS_TEXT:
      return import(
        /* webpackChunkName: 'searchWidgetRoundedTextOnline' */ '@phoenix/search/widget/constants/searchWidgetRoundedWithOnlineAsTextComponents'
      );
    case SearchWidgetAppearance.ROUNDED_WITHOUT_ACTIONS:
      return import(
        /* webpackChunkName: 'searchWidgetRoundedWithoutActions' */ '@phoenix/search/widget/constants/searchWidgetRoundedWithoutActionsComponents'
      );
    case SearchWidgetAppearance.FLAT_WITH_HASHTAGS_AND_FLIRT_ACTION:
      return import(
        /* webpackChunkName: 'searchWidgetHashtagsAndFlirt' */ '@phoenix/search/widget/constants/searchWidgetWithHashtagsAndFlirtAction'
      );
    case SearchWidgetAppearance.FULLSCREEN:
      return import(
        /* webpackChunkName: 'searchWidgetFullscreen' */ '@phoenix/search/widget/constants/searchWidgetFullscreenComponents'
      );
    default:
      return null;
  }
};

/**
 * Appearance-changeable search widget based on theme.
 * Returns object of composite components that are used for theming.
 * @returns {{loading: boolean, data: {layout: FC, placeholder: FC}}}
 */
const useThemedSearchWidget = () =>
  useThemeCompositeFeatureLoader<SearchWidgetAppearance, SearchWidgetComponent>(
    'search',
    'widget',
    resolver,
  );

export default useThemedSearchWidget;
