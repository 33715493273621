import createPhotoUrl from './createPhotoUrl';
import type Photo from '../types/Photo';
import type {PhotoDataQuery} from '../graphql/queries/photoData';

/**
 * Get photo URL or null if photo or data is missing.
 */
const getPhotoUrl = (photo: Photo, data: PhotoDataQuery): string | null => {
  if (!photo || !data) {
    return null;
  }

  return createPhotoUrl({
    ...photo,
    ...data.site,
    uniform: data.userFeatures?.uniform,
  });
};

export default getPhotoUrl;
