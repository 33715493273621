import {pipe} from 'rxjs';
import {filter, take} from 'rxjs/operators';

import parsePathname from '@core/utils/url/parsePathname';

import openFreeCoinsEmailConfirmationRewardPopup from '@phoenix/freeCoins/utils/openFreeCoinsEmailConfirmationRewardPopup';

/**
 * Call popup that speaks about the reward for the successful confirmation of the letter
 * if URL has "?confirmationEmailForFreeCoins=1".
 *
 * @returns {Object} result
 * @returns {Function} result.operations
 * @returns {Function} result.subscription
 */
const confirmationForFreeCoinsPopupAction = () => ({
  operations: () =>
    pipe(
      // React only on existing param '?confirmationEmailForFreeCoins=1' in URL
      filter(
        ([
          {
            data: {route},
          },
        ]) => {
          const {options} = parsePathname(route.current);
          /**
           * For free coins
           */
          return options.confirmationEmailForFreeCoins;
        },
      ),
      // Since we can trigger single popup only one time
      take(1),
    ),
  subscription: openFreeCoinsEmailConfirmationRewardPopup,
});

export default confirmationForFreeCoinsPopupAction;
