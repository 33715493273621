import React, {useCallback, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import {object, string} from 'yup';
import {Formik} from 'formik';
import find from 'lodash/find';
import {useQuery, useApolloClient} from '@apollo/client';

import logger from '@core/logger';
import t from '@core/translations/translate';
import normalizeMutationErrors from '@core/graphql/utils/normalizeMutationErrors';

import TrustedVerificationSmsFirstStepPlaceholder from '../components/TrustedVerificationSmsFirstStepPlaceholder';
import TrustedVerificationSmsFirstStepLayout from '../components/TrustedVerificationSmsFirstStepLayout';
import TRUSTED_VERIFICATION_QUERY from '../graphql/queries/turstedVerification.gql';
import SEND_SMS_MUTATION from '../graphql/mutations/sendSms.gql';
import {FIRST_STEP_FIELD_NAMES} from '../contants/fields';

/**
 * @const {String}
 */
const {COUNTRY, COUNTRY_PHONE_CODE, FIRST_NAME, LAST_NAME, LANGUAGE, PHONE} =
  FIRST_STEP_FIELD_NAMES;

/**
 * @const {RegExp}
 */
const PHONE_REGEXP = /^[+]?[0-9]+$/;

/**
 * @returns {Object}
 */
const getValidationSchema = (withUserData) => {
  const validation = {
    [PHONE]: string()
      .required(t('paymentPage', 'error.phone.invalid'))
      .matches(PHONE_REGEXP, t('paymentPage', 'error.phone.invalid')),
  };

  if (withUserData) {
    validation[FIRST_NAME] = string()
      .trim()
      .test(
        FIRST_NAME,
        t('paymentPage', 'error.is_incorrect', {
          '{attribute}': t('paymentTrusted', 'text.firstName'),
        }),
        (value) => new RegExp('^\\p{L}+[\\s-]?\\p{L}+$', 'gu').test(value),
      );

    validation[LAST_NAME] = string()
      .trim()
      .test(
        LAST_NAME,
        t('paymentPage', 'error.is_incorrect', {
          '{attribute}': t('paymentTrusted', 'text.lastName'),
        }),
        (value) => new RegExp('^\\p{L}+[\\s-]?\\p{L}+$', 'gu').test(value),
      );
  }

  return object().shape(validation);
};

/**
 * The first step of SMS verification
 * To get the Reference Id and go to the next step, you need to enter a valid phone number
 * and select the available country and language
 */
const TrustedVerificationSmsFirstStep = ({
  onNextStep,
  onError,
  setFirstStepLoaded,
  userPersonalData,
  inPopup,
  actions,
}) => {
  const {data, loading, error} = useQuery(TRUSTED_VERIFICATION_QUERY);
  const client = useApolloClient();

  /**
   * Need for correct setting height of popup to hide legals
   * We must wait until open popup animation and loading disappear correct
   */
  useLayoutEffect(() => {
    setFirstStepLoaded?.(!loading);

    return () => {
      setFirstStepLoaded?.(false);
    };
  }, [loading, setFirstStepLoaded]);

  const onSubmit = useCallback(
    async (payload) => {
      let response;

      const variables = {
        [FIRST_NAME]: payload[FIRST_NAME],
        [LAST_NAME]: payload[LAST_NAME],
        [COUNTRY_PHONE_CODE]: payload[COUNTRY_PHONE_CODE],
        [LANGUAGE]: payload[LANGUAGE],
        [PHONE]: payload[PHONE],
      };

      userPersonalData.current = {
        ...variables,
        [COUNTRY]: payload[COUNTRY],
      };

      try {
        response = await client.mutate({
          mutation: SEND_SMS_MUTATION,
          variables,
        });
      } catch (err) {
        onError();
        logger.sendError(
          `[TrustedVerificationSmsFirstStep] Error is ${err} for payload: "${JSON.stringify(
            variables,
          )}".`,
        );
        return;
      }

      const errors = normalizeMutationErrors(
        response.data.trusted.sendSms.errors,
      );

      if (errors) {
        onError();
        return;
      }

      onNextStep(response.data.trusted.sendSms.result);
    },
    [client, onError, onNextStep, userPersonalData],
  );

  if (error) {
    onError();
  }

  if (loading) {
    return <TrustedVerificationSmsFirstStepPlaceholder />;
  }

  const {
    trusted: {
      sms: {
        availableUserPersonalData,
        defaultLocale,
        defaultCountry,
        defaultPhone,
        countries,
        locales,
      },
    },
  } = data;

  return (
    <Formik
      initialValues={{
        [COUNTRY]: userPersonalData.current[COUNTRY] || defaultCountry,
        [LANGUAGE]: userPersonalData.current[LANGUAGE] || defaultLocale,
        [COUNTRY_PHONE_CODE]:
          userPersonalData.current[COUNTRY_PHONE_CODE] || defaultPhone,
        [FIRST_NAME]: userPersonalData.current[FIRST_NAME],
        [LAST_NAME]: userPersonalData.current[LAST_NAME],
        [PHONE]: userPersonalData.current[PHONE],
      }}
      validationSchema={getValidationSchema(availableUserPersonalData)}
      onSubmit={onSubmit}
    >
      {({handleSubmit, isSubmitting, values, setFieldValue}) => (
        <TrustedVerificationSmsFirstStepLayout
          availableUserPersonalData={availableUserPersonalData}
          phonePrefix={values[COUNTRY_PHONE_CODE]}
          countries={countries}
          locales={locales}
          actions={actions}
          inPopup={inPopup}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          handleChangeCountry={(_event, value) => {
            setFieldValue(
              COUNTRY_PHONE_CODE,
              find(countries, {country: value}).phone,
              false,
            );
            setFieldValue(COUNTRY, value, false);
          }}
        />
      )}
    </Formik>
  );
};

TrustedVerificationSmsFirstStep.propTypes /* remove-proptypes */ = {
  inPopup: PropTypes.bool,
  actions: PropTypes.node,
  onNextStep: PropTypes.func.isRequired,
  setFirstStepLoaded: PropTypes.func,
  onError: PropTypes.func.isRequired,
  userPersonalData: PropTypes.shape({
    current: PropTypes.shape({
      [COUNTRY]: PropTypes.string,
      [COUNTRY_PHONE_CODE]: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      [FIRST_NAME]: PropTypes.string,
      [LAST_NAME]: PropTypes.string,
      [LANGUAGE]: PropTypes.string,
      [PHONE]: PropTypes.string,
    }),
  }),
};

export default TrustedVerificationSmsFirstStep;
