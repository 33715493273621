import getHistory from '@core/application/utils/getHistory';
import {getClientInstance} from '@core/graphql/client';
import getPaymentStatus from '@core/payment/common/utils/getPaymentStatus';
import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import {VIA_PMA_PHOTO_REQUEST} from '@core/payment/common/constants/vias';
import {createUrlFor} from '@core/utils/url';
import {PhotoRequestTypeEnum} from '@core/types';
import MessageType from '@core/messenger/common/constants/messageType';

import openMessengerWith from '@phoenix/messenger/common/utils/openMessengerWith';
import sendMessage from '@phoenix/messenger/senderForm/utils/sendMessage';
import type PhotoRequestData from '@phoenix/user/photo/types/PhotoRequestData';
import type Photo from '@phoenix/user/photo/types/Photo';

import openPmaRequestPhotoPopup from './openPmaRequestPhotoPopup';
import PRIVATE_PHOTO_OVERLAY_TYPE from '../constants/PrivatePhotoOverlayType';
import PMA_REQUEST_PHOTO from '../graphql/queries/pmaRequestPhoto.gql';
import type {PmaRequestPhotoQuery} from '../graphql/queries/pmaRequestPhoto';

type PmaPhotoRequestParams = {
  userId: string;
  photo: Photo;
  photoRequestData?: PhotoRequestData;
};

/**
 * Common click logic by photo
 * Used for photo request
 */
const pmaPhotoRequest = async ({
  userId,
  photo,
  photoRequestData,
}: PmaPhotoRequestParams): Promise<void> => {
  const {isRequested, requestType} = photo?.privateAttributes || {};

  if (requestType !== PhotoRequestTypeEnum.pmaPhoto) {
    return;
  }

  const {data} = await getClientInstance().query<PmaRequestPhotoQuery>({
    query: PMA_REQUEST_PHOTO,
  });

  if (!data) {
    return;
  }

  const requestCount = data.userFeatures?.pmaRequestPhoto?.allowedRequestsCount;
  const {overlayType} = photoRequestData || {};

  // condition for layout with request photo button
  if (overlayType === PRIVATE_PHOTO_OVERLAY_TYPE.WITH_REQUEST_BUTTON) {
    if (isRequested) {
      openMessengerWith({userId, skipMiniMessenger: true});
      return;
    }

    if (requestCount) {
      sendMessage({
        recipientId: userId,
        messageType: MessageType.PRIVATE_PHOTO_REQUEST,
        message: photo.id,
      });
      return;
    }
  }

  const history = getHistory();

  const isPaid = await getPaymentStatus();

  if (!requestCount && !isRequested) {
    if (isPaid) {
      // Redirect to target user profile page paid users without request count
      history.push(createUrlFor.userProfile(userId));
    } else {
      // Redirect to payment page non-paid users without request count
      history.push(
        generatePayUrl({
          stage: PAYMENT_ACTIONS.MEMBERSHIP,
          urlParams: {
            via: VIA_PMA_PHOTO_REQUEST,
            viaProfileId: userId,
          },
        }),
      );
    }
    return;
  }

  openPmaRequestPhotoPopup({userId, photo});
};

export default pmaPhotoRequest;
