// TODO[TS]: Re-do this file: use correct types after re-writing microfeatures

import type {Context} from 'react';
import {createContext} from 'react';

type FeatureName = string;

type SexAlarmOptions = {
  number: number;
  message: string;
};

type FeatureData = {
  stockId: string;
  packageId: string;
  creditAmount: number;
};

type GetTranslationOptions = {
  type: string;
  featureName: FeatureName;
};

type MicrofeaturesContextData = {
  loading: boolean;
  modelType: string;
  isAdultDescription: boolean;
  microfeaturesAvailable: boolean;
  microfeaturesActive: boolean;
  popularityLevel: null;
  getSexAlarmPopupData(): null | SexAlarmOptions;
  getFeaturesList(): void | FeatureName[];
  getFeatureData({featureName}: {featureName: FeatureName}): null | FeatureData;
  getTranslation({type, featureName}: GetTranslationOptions): null | string;
  getFeatureForPlacement(): null | FeatureName;
  iteratePlacement(placement: string): void;
  checkPlacementEnabled(placement: string): boolean;
};

/**
 * Create context for Microfeatures functionality
 */
const MicrofeaturesContext: Context<MicrofeaturesContextData> = createContext({
  loading: true,
  modelType: '',
  isAdultDescription: false,
  microfeaturesAvailable: false,
  microfeaturesActive: false,
  popularityLevel: null,
  getSexAlarmPopupData: () => null,
  getFeaturesList: () => null,
  getFeatureData: () => null,
  getTranslation: () => null,
  getFeatureForPlacement: () => null,
  iteratePlacement: () => null,
  checkPlacementEnabled: () => null,
});

export const {Provider, Consumer} = MicrofeaturesContext;

export default MicrofeaturesContext;
