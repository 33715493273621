import React, {Fragment} from 'react';
import range from 'lodash/range';

import {PlaceholderInput, Row} from '@phoenix/ui';

const COUNT_INPUTS = 6;

/**
 * @see TrustedVerificationSmsFirstStep.js
 */
const TrustedVerificationSmsFirstStepPlaceholder = () => (
  <Fragment>
    {range(COUNT_INPUTS).map((key) => (
      <Row key={key}>
        <PlaceholderInput />
      </Row>
    ))}
  </Fragment>
);

export default TrustedVerificationSmsFirstStepPlaceholder;
