import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';

import logger from '@core/logger';
import trackRedirectTo from '@core/tracking/babcia/utils/trackRedirectTo';
import openLoadingWindow, {
  closeTab,
  isTabOpen,
} from '@core/utils/routing/openLoadingWindow';
import {gotoUrl} from '@core/utils/url';

import WEB_CAM_REGISTRATION_MUTATION from '../graphql/mutations/webCamRegistration.gql';

const ONLY_SLASH_REGEXP = /^\/$/;

/**
 * Hook for registration webCam placements
 * @param {object} webCamData
 * @param {function|null} onClick
 */
const useWebCamRegister = ({webCamData, onClick, withPaymentRedirect}) => {
  const client = useApolloClient();
  const history = useHistory();

  const goToWebCamUrl = useCallback(
    (target, url) => {
      if (target === '_blank') {
        gotoUrl(url, true);
      } else {
        history.push(url);
      }
    },
    [history],
  );

  /**
   * Open new browser tab and wait interaction with new url
   * in order to insert incoming url in new opened tab.
   * On mutation update check:
   *  1) if new tab is closed - do nothing;
   *  2) if new browser tab is still opened - insert in it a new url from server;
   *  3) if error occurs - just log and close tab.
   */
  return useCallback(() => {
    if (onClick) onClick();

    /**
     * Skip webcam logic if payment redirect is required.
     * Logic handled by onClick callback.
     */
    if (withPaymentRedirect) {
      return;
    }

    const {url, target, useAsyncTransition} = webCamData;

    // Do nothing when user on the required route
    if (ONLY_SLASH_REGEXP.test(url)) return;

    // Functionality works without async webcam registration
    if (!useAsyncTransition) {
      goToWebCamUrl(target, url);
      return;
    }

    const newTab = openLoadingWindow();

    /**
     * Go to url if browser tab is closed.
     * Tab is closed when popup-up blocker is terned on in browser.
     */
    if (!isTabOpen(newTab)) {
      goToWebCamUrl(target, url);
      return;
    }

    client
      .mutate({
        mutation: WEB_CAM_REGISTRATION_MUTATION,
        variables: {url},
      })
      .then(({data: incomingData}) => {
        // If browser tab is closed do nothing, e.x., user closed browser tab
        if (!isTabOpen(newTab)) {
          return;
        }

        // If socket is disconnected and no response url - insert source url
        const redirectUrl = incomingData.webCamRegistrationLink.url || url;

        trackRedirectTo({nextPathname: redirectUrl});

        newTab.location.href = redirectUrl;
      })
      .catch((e) => {
        // Close browser opened blank tab if an error happened
        closeTab(newTab);
        logger.captureException(e);
      });
  }, [withPaymentRedirect, client, goToWebCamUrl, onClick, webCamData]);
};

export default useWebCamRegister;
