import PMA_SERVICE_QUERY from '../graphql/queries/pmaServiceQuery.gql';

/**
 * Pma service access rule.
 */
export default {
  errorCode: 'NOT_ALLOWED',
  query: PMA_SERVICE_QUERY,
  resolver: (data) => !data.userFeatures || data.userFeatures.pma.enabled,
};
