import React from 'react';

import ThemedSearchPage from '@phoenix/theming/components/ThemedSearchPage';

import DeferredPage from '../DeferredPage';
import {isPopupRoute} from '../../utils/getPopupBaseRoute';

/**
 * Themed search component for routes with popups with background rendering delay
 *
 * @constructor DeferredSearchPage
 */
const DeferredSearchPage = (props) => (
  <DeferredPage
    {...props}
    page={ThemedSearchPage}
    available={!isPopupRoute(window.location.pathname)}
  />
);

export default DeferredSearchPage;
