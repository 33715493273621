import t from '@core/translations/translate';

import useProcessingFee from '../../../banners/processingFee/utils/useProcessingFee';

const useYouWillPayText = ({
  price,
  packageName,
  onlyProcessingFeeText = false,
}) => {
  const {loading, fee, amount, financialServicePlatform} = useProcessingFee();
  let text = null;

  if (loading || (onlyProcessingFeeText && !fee)) {
    return {loading, text};
  }

  if (fee) {
    text = t('paymentPage', 'text.youWillPayProcessingFee', {
      '{price}': price,
      '{packageName}': packageName,
      '{appName}': financialServicePlatform || '',
      '{fee}': fee,
      '{amount}': amount,
    });
  } else {
    text = `${t('paymentPage', 'text.you_will_pay')} ${price} ${t(
      'paymentPage',
      'text.for',
    )} ${packageName}`;
  }

  return {loading, text};
};

export default useYouWillPayText;
