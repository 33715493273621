import React, {Fragment, forwardRef} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import MediaUploadActionsAppearance from '@core/theming/constants/features/MediaUploadActionsAppearance';

import {ButtonPrimary} from '@phoenix/ui';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import WebcamWrapper from './WebcamWrapper';
import WebcamError from './WebcamError';
import UploadActions from './UploadActions';

const WebcamPhotoCaptureLayout = forwardRef(
  ({onCapture, isReady, error}, ref) => {
    const {data: actions} = useThemeFeature('mediaUpload', 'actions');

    return (
      <Fragment>
        <WebcamWrapper ref={ref}>
          {error && <WebcamError error={error} />}
        </WebcamWrapper>
        <UploadActions>
          <ButtonPrimary
            icon={
              actions !== MediaUploadActionsAppearance.WITHOUT_ICONS && 'photo'
            }
            onClick={onCapture}
            trackingName="capturePhoto"
            disabled={!isReady}
            data-test="webcamPhotoCapture"
          >
            {t('mediaUpload', 'text.capture_photo')}
          </ButtonPrimary>
        </UploadActions>
      </Fragment>
    );
  },
);

WebcamPhotoCaptureLayout.propTypes /* remove-proptypes */ = {
  onCapture: PropTypes.func.isRequired,
  isReady: PropTypes.bool,
  error: PropTypes.string,
};

export default WebcamPhotoCaptureLayout;
