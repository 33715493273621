import {useState, useEffect} from 'react';

import useThemeFeature from './useThemeFeature';
import {getCachedModule, cacheModule} from './themeCache';
import type {ModuleResolver, FeatureName} from '../types';

/**
 * Another type of loading themed features.
 * Sometimes, in more complex cases, we should load more than one component for describing feature.
 *
 * This component will lazy load object of components (or functions) that should be used
 * for theming.
 *
 * If you want to get only one lazy component and more in 'react way'
 * you should use "ThemedComponentLoader".
 */
const useThemeCompositeFeatureLoader = <Appearance, LoadableComponent>(
  feature: FeatureName,
  param: string,
  moduleResolver: ModuleResolver<Appearance, LoadableComponent>,
): {
  loading: boolean;
  data: LoadableComponent;
} => {
  const {data: value} = useThemeFeature<Appearance>(feature, param);
  const cachedModule = getCachedModule<Appearance, LoadableComponent>(
    moduleResolver,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const module = moduleResolver(value);

    if (!cachedModule && module) {
      module.then((result) => {
        cacheModule<Appearance, LoadableComponent>(
          moduleResolver,
          result?.default,
        );

        // force update
        setLoading({});
      });
    }
  }, [cachedModule, value, moduleResolver]);

  return {
    loading: !cachedModule,
    data: cachedModule,
  };
};

export default useThemeCompositeFeatureLoader;
