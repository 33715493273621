/**
 * Types of content in the stories parts
 */

const PHOTO = 'photo';
const VIDEO = 'video';

export const TYPES_LIST = [PHOTO, VIDEO];

export default {
  PHOTO,
  VIDEO,
};
