import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Link} from '@phoenix/typography';

/**
 * Pattern to detect link text in string like '<a href='https://localhost.com/staticPage/terms'>Terms of use</a>'
 * @type {RegExp}
 */
const tagPattern = /<a[^>]*>([^<]+)<\/a>/g;

/**
 * Pattern to detect link url in string like '<a href='https://localhost.com/staticPage/terms'>Terms of use</a>'
 * @type {RegExp}
 */
const hrefPattern =
  /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w]*)?(\?[^\s]*)?/gi;

/**
 * Footer text is string like "text1 <a href=''>link1</a> text2 <a href=''>link2</a> text3 ...."
 * We need to parse links to add ub tracking and styles
 *
 * stringParts - [text1, link1, text2, link2, text3, ...]
 * linksParts - {link1 text: link1 url, link2 text: link2 url, ...}
 *
 * @param {string} text
 * @return {{stringParts: *, linksParts: *}}
 */
const parseFooterText = (text) => {
  const links = text.match(tagPattern);
  const stringParts = text.split(tagPattern);

  const linksParts = links.reduce((result, link) => {
    const linkText = link.split(tagPattern)[1];
    const linkUrl = link.match(hrefPattern)[0];

    return {...result, [linkText]: linkUrl};
  }, {});

  return {linksParts, stringParts};
};

const CoregistrationPopupFooter = ({text}) => {
  const {linksParts, stringParts} = parseFooterText(text);

  return (
    <Fragment>
      {stringParts.map((part, index) => {
        const url = linksParts[part];

        if (url) {
          return (
            <Link
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              href={url}
              target="_blank"
              small
            >
              {part}
            </Link>
          );
        }

        // eslint-disable-next-line react/no-array-index-key
        return <span key={index}>{part}</span>;
      })}
    </Fragment>
  );
};

CoregistrationPopupFooter.propTypes /* remove-proptypes */ = {
  text: PropTypes.string.isRequired,
};

export default CoregistrationPopupFooter;
