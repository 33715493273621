import React, {useCallback, useEffect, useMemo} from 'react';
import result from 'lodash/result';
import {useHistory, useLocation} from 'react-router-dom';

import t from '@core/translations/translate';
import PhoneBreakpoint from '@core/responsive/PhoneBreakpoint';
import {UNKNOWN_PAGE_STATE_KEY} from '@core/application/constants/history';
import isSiteForApps from '@core/application/utils/isSiteForApps';
import MountTracker from '@core/tracking/babcia/containers/MountTracker';
import useRedirectToDefaultPage from '@core/utils/routing/useRedirectToDefaultPage';

import {TextMuted, Title1} from '@phoenix/typography';
import {ButtonPrimary, Container, Card, Notice} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

import css from '../styles/NotFound.css';

const NotFound = () => {
  const history = useHistory();
  const redirectToDefaultPage = useRedirectToDefaultPage();
  const {state, pathname, search} = useLocation();

  useEffect(() => {
    if (!result(state, UNKNOWN_PAGE_STATE_KEY, false)) {
      history.replace(`${pathname}${search}`, {
        [UNKNOWN_PAGE_STATE_KEY]: true,
      });
    }
  }, [history, pathname, search, state]);
  const forApps = useMemo(() => isSiteForApps(), []);

  const handleClick = useCallback(() => {
    redirectToDefaultPage();
  }, [redirectToDefaultPage]);

  return (
    <PhoneBreakpoint>
      {(matches) => (
        <Container unspaced={matches} data-test="notFound">
          <Card boundless={matches} onPageBackground>
            <Notice
              adaptive={matches}
              spacing={SpacingSize.LARGE}
              icon={<Title1 className={css.error}>404</Title1>}
              title={
                forApps
                  ? t('notFound', 'text.page_not_foundForApp')
                  : t('notFound', 'text.page_not_found')
              }
              description={
                <TextMuted>
                  {forApps
                    ? t('notFound', 'text.the_page_you_are_lookingForApp')
                    : t('notFound', 'text.the_page_you_are_looking')}
                </TextMuted>
              }
              actions={[
                <ButtonPrimary
                  onClick={handleClick}
                  fullWidth
                  trackingName="goToSite"
                >
                  {forApps
                    ? t('notFound', 'text.go_to_the_siteForApp')
                    : t('notFound', 'text.go_to_the_site')}
                </ButtonPrimary>,
              ]}
            />
          </Card>
          <MountTracker name={MountTracker.NAMES.PAGE_LAYOUT} />
        </Container>
      )}
    </PhoneBreakpoint>
  );
};

export default NotFound;
