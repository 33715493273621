/**
 * List of available banners that can be called. If you use
 * any name outisde of this list, most probably, it won't work.
 *
 * But there are cases, when back-end sends names for specific banners,
 * that are not listed here
 */
const PLACEMENT_IDS = {
  // Profile page
  PROFILE_TOP: 'profiletop',
  PROFILE_BOTTOM: 'profilebottom',
  PROFILE_LEFT: 'profileleft',

  // Target user page
  USER_TOP: 'usertop',
  USER_BOTTOM: 'userbottom',
  USER_LEFT: 'userleft',
  USER_OVER: 'banner_user_over',
  USER_UNDER: 'banner_user_under',

  // Account page
  ACCOUNT_TOP: 'accounttop',
  ACCOUNT_BOTTOM: 'accountbottom',
  ACCOUNT_TEXT: 'accounttext',

  // Forbidden page
  ERROR_PAGE_1: 'errorpage1',
  ERROR_PAGE_2: 'errorpage2',
  ERROR_PAGE_3: 'errorpage3',

  // Favorites page
  FAVORITES_TOP: 'myfavoritestop',
  FAVORITES_BOTTOM: 'myfavoritesbottom',

  // Footer
  TEXT_BOTTOM: 'textbottom',

  // Newsfeed (activity) page
  ACTIVITY_TOP: 'activitytop',
  ACTIVITY_BOTTOM: 'activitybottom',

  // Search
  SEARCH_TOP: 'searchtop',
  ADVANCED_SEARCH_TEXT: 'advsearchtext',

  // Recently viewed
  RECENTLY_TOP: 'recentlytop',
  RECENTLY_BOTTOM: 'recentlybottom',

  // Messenger
  BANNER_CHAT_FIRST: 'banner_chatwith_1st',
  BANNER_CHAT_SECOND: 'banner_chatwith_2nd',

  // Photo upload
  BANNER_PHOTO_UPLOAD: 'banner_photo_upload',
};

export default PLACEMENT_IDS;
