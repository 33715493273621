import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import {PHOTO} from '@core/mediaUpload/constants/uploadTypes';

import MediaUploadDisabledPopup from '../components/MediaUploadDisabledPopup';

/**
 * Open media upload disabled popup
 * @param {Object} props
 * @param {String} props.mediaType - Upload type.
 * @param {Function} props.onClose - Click callback.
 */
const openMediaUploadDisabledPopup = ({mediaType = PHOTO, onClose} = {}) => {
  PopupService.openPopup(<MediaUploadDisabledPopup mediaType={mediaType} />, {
    small: true,
    trackingName: `mediaUploadDisabled_${mediaType}`,
    autoLoadTracking: true,
    onClose,
  });
};

export default openMediaUploadDisabledPopup;
