import {
  DEBT_LIMIT_REACHED,
  NO_ENOUGH_COINS,
} from '@core/messenger/common/constants/reasons';

/**
 * Check reason like reasons for limits of credits
 * @see redirectToMessengerWithPermissionCheck updatePermissionsForSendMessage
 * @param {string} reason
 */
const isCreditsLimitReason = (reason) =>
  [NO_ENOUGH_COINS, DEBT_LIMIT_REACHED].includes(reason);

export default isCreditsLimitReason;
