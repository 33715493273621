import createPostMessageObservable from '../../banner/utils/createPostMessageObservable';

/**
 * Iframe data query
 * @TODO Replace it with CORS XHR / Fetch query when corresponding CORS header setup is done.
 * @param {string} systemUrl Iframe origin url
 * @param {string} iframeUrl Iframe url
 * @param {object} validatePostMessageData Validation rules
 * @return {Promise} Data promise
 */
const iframeQuery = (
  systemUrl,
  iframeUrl,
  validatePostMessageData,
  callbackEvent,
) => {
  return new Promise((resolve, reject) => {
    // To avoid emulating iframe logic in integration test, because it is external service
    if (window.IS_INTEGRATION_TEST_ENVIRONMENT) {
      resolve({
        data: {
          eventData: {},
        },
      });
    }

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = iframeUrl;
    document.body.appendChild(iframe);

    let subscription;

    const destroy = () => {
      subscription.unsubscribe();
      document.body.removeChild(iframe);
    };

    subscription = createPostMessageObservable(
      systemUrl,
      validatePostMessageData,
    ).subscribe((data) => {
      destroy();

      if (data.data.callbackEvent === callbackEvent) {
        resolve(data);
      } else {
        reject();
      }
    });
  });
};

export default iframeQuery;
