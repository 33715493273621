import MicrofeaturesAnimationIncognito from '../components/MicrofeaturesAnimationIncognito';
import MicrofeaturesAnimationMoreLikes from '../components/MicrofeaturesAnimationMoreLikes';
import MicrofeaturesAnimationPriorityMan from '../components/MicrofeaturesAnimationPriorityMan';
import MicrofeaturesAnimationSuperBoost from '../components/MicrofeaturesAnimationSuperBoost';
import MicrofeaturesAnimationSexAlarm from '../components/MicrofeaturesAnimationSexAlarm';
import MicrofeaturesAnimationTopInChat from '../components/MicrofeaturesAnimationTopInChat';
import {MICROFEATURES} from '../../common/constants/microfeatures';

/**
 * Separate components to separate specific styles for animation
 */
export const MICROFEATURES_SUCCESS_ANIMATION = {
  [MICROFEATURES.MICRO_INCOGNITO]: MicrofeaturesAnimationIncognito,
  [MICROFEATURES.MORE_LIKES]: MicrofeaturesAnimationMoreLikes,
  [MICROFEATURES.PRIORITY_MAN]: MicrofeaturesAnimationPriorityMan,
  [MICROFEATURES.SEARCH_QUEEN]: MicrofeaturesAnimationPriorityMan,
  [MICROFEATURES.SUPER_BOOST]: MicrofeaturesAnimationSuperBoost,
  [MICROFEATURES.FLIRT_ALARM]: MicrofeaturesAnimationSexAlarm,
  [MICROFEATURES.SEX_ALARM]: MicrofeaturesAnimationSexAlarm,
  [MICROFEATURES.TOP_IN_CHAT]: MicrofeaturesAnimationTopInChat,
};
