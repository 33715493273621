/**
 * Use cache for theme features since without it we should
 * recalculate and re-render continuously components during it's own life-cycle.
 *
 * @see useThemeCompositeFeatureLoader.js
 * @see useThemeFeature.js
 */

import type {ModuleResolver, StoredThemeFeature} from '../types';

type Cache = {
  [key: string]: StoredThemeFeature<unknown>;
};
const cache: Cache = {};

const moduleCache: Map<ModuleResolver<unknown, unknown>, unknown> = new Map();

export const getCachedModule = <Appearance, LoadableComponent>(
  resolver: ModuleResolver<Appearance, LoadableComponent>,
): LoadableComponent => {
  return moduleCache.get(resolver) as LoadableComponent;
};

export const cacheModule = <Appearance, LoadableComponent>(
  resolver: ModuleResolver<Appearance, LoadableComponent>,
  value: LoadableComponent,
) => {
  moduleCache.set(resolver, value);
};

const getCacheKey = (feature: string, param: string): string => {
  return param ? `${feature}.${param}` : feature;
};

export const getCachedValue = <T>(
  feature: string,
  param: string,
): StoredThemeFeature<T> => {
  return cache[getCacheKey(feature, param)] as StoredThemeFeature<T>;
};

export const cacheValue = <T>(
  feature: string,
  param: string,
  value: StoredThemeFeature<T>,
): void => {
  cache[getCacheKey(feature, param)] = value;
};
