import once from 'lodash/once';

import generatePayUrl from '@core/payment/common/utils/generatePayUrl';
import PAYMENT_ACTIONS from '@core/payment/common/constants/paymentActions';
import isInRouteList from '@core/utils/routing/isInRouteList';

import setPopupLaterByRouteChangeSubscription, {
  getLifetimeVia,
  saveLifetimeVia,
  prepareAndOpenPopup,
} from './setPopupLaterByRouteChangeSubscription';
import LIFETIME_OFFER_SUBSCRIPTION from '../graphql/subscriptions/lifetimeOffer.gql';
import DISABLED_ROUTES from '../constants/disabledRoutes';

let subscription = null;

export const unsubscribeLifetimeOffer = () => {
  subscription && subscription.unsubscribe();
};

/**
 * @param {String} via
 * @return {String}
 */
const getPaymentUrl = (via) => {
  return generatePayUrl({
    stage: PAYMENT_ACTIONS.MEMBERSHIP,
    urlParams: {via, returnPath: 'search', skipStepOnBack: true},
  });
};

const startLifetimeOfferListener = once((client) => {
  // Need to show popup if exist stored via for lifetimeOffer popup
  if (!isInRouteList(DISABLED_ROUTES, window.location.pathname)) {
    const via = getLifetimeVia();
    if (via) {
      const paymentUrl = getPaymentUrl(via);
      paymentUrl && prepareAndOpenPopup({paymentUrl});
    }
  }

  subscription = client
    .subscribe({query: LIFETIME_OFFER_SUBSCRIPTION})
    .subscribe(({data: {via}}) => {
      const paymentUrl = getPaymentUrl(via);

      if (!isInRouteList(DISABLED_ROUTES, window.location.pathname)) {
        prepareAndOpenPopup({paymentUrl, withClean: false});
      } else {
        saveLifetimeVia(via);

        setPopupLaterByRouteChangeSubscription(paymentUrl);
      }
    });
});

export default startLifetimeOfferListener;
