import React, {useEffect, useState, useRef} from 'react';
import {Redirect, useLocation, useHistory} from 'react-router-dom';
import {useApolloClient} from '@apollo/client';
import camelCase from 'lodash/camelCase';
import head from 'lodash/head';

import logger from '@core/logger';
import getErrorsObservable from '@core/application/utils/getErrorsObservable';
import getErrorRedirect from '@core/application/utils/getErrorRedirect';
import {FUNNEL} from '@core/application/constants/routesWithPopup';
import routeWithPopupErrorVar from '@core/graphql/vars/routeWithPopupErrorVar';

import ERROR_CODE_MAP from '@phoenix/application/constants/accessErrorCodeMap';
import getPopupBaseRoute from '@phoenix/application/utils/getPopupBaseRoute';
import useFunnelSteps from '@phoenix/funnel/utils/useFunnelSteps';

import COMMON_ACL_RULES from '../constants/commonACLRules';
import useDeferredPageAvailable from '../deferredPage/useDeferredPageAvailable';

/**
 * @const
 */
const NO_ERROR = null;

/**
 * Global component used to track route changes and show different
 * funnel-related popups on some pages that are not "pages" in exact meaning.
 * It means, that all those pages should open popup on entering inside, but display
 * some "default content" on background.
 *
 * About too much async operations without any unmount logic - this component will
 * never unmount.
 *
 * @see App.js
 */
const RouteWithPopupDisplayer = () => {
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const {setDeferredPageAvailable} = useDeferredPageAvailable();

  const params = useRef({});
  const [error, setError] = useState(NO_ERROR);

  const baseRoute = getPopupBaseRoute(location.pathname);

  const onUncertainFunnel = location.pathname === FUNNEL;

  const {
    goToNextStep: goToNextFunnelStep,
    nextStep: nextFunnelStep,
    stack,
    loading,
  } = useFunnelSteps(
    // Avoid starting any calculations if "baseRoute" isn't defined
    Boolean(baseRoute) || onUncertainFunnel,
  );
  const lastFunnelStep = stack[stack.length - 1];

  /**
   * Used since we don't need to invalidate effect below on such params change.
   */
  params.current = {
    location,
    history,
    goToNextFunnelStep,
    nextFunnelStep,
    lastFunnelStep,
  };

  useEffect(() => {
    // Means, that current route should avoid opening any popup
    if (!baseRoute || error || loading) {
      return;
    }

    const actionPrefix = camelCase(baseRoute);

    /**
     * Can't move to variable or getter function since webpack performs static analyze at build time
     * and variable can be "anything". But in this case - it is only "anything" inside "popupActions" directory
     *
     * Be aware that those popups should be checked with ACL rules like routing is checked too,
     * so, any 'action' module can "export" special array of rules. Otherwise common rules
     * will be applied to it.
     *
     * @see commonACLRules.js
     * @see confirmationAction.js (for customized ACL rules)
     */
    import(`../popupActions/${actionPrefix}Action`)
      .then(({default: performAction, ACLRules = COMMON_ACL_RULES}) => {
        // Just for avoiding creating 'unsubscribe' logic here.
        // @see https://www.learnrxjs.io/learn-rxjs/operators/utility/topromise
        getErrorsObservable(ACLRules, client)
          .toPromise()
          .then((errors) => {
            // If we have errors - deny any further action
            if (errors.length) {
              /**
               * If popup with route not allowed by error this var lets loading search page
               */
              setDeferredPageAvailable(true);

              setError(errors[0]);
              return;
            }

            /**
             * Let some time for imported css to be applied.
             * It's the fix of incorrect `isPopupOverflowing` in {@see PopupFrame}
             * caused by non-styled content rendering at the first time.
             */
            setTimeout(() => {
              /**
               * Note that popup opening is included inside action it was done since
               * we have different actions for "Backbone" and "React" popups.
               *
               * @todo When backbone will be eradicated - we can replace "actions" with "component + params" getters.
               */
              performAction(params.current);
            }, 0);
          });
      })
      .catch((importError) => {
        logger.sendError(
          `[RouteWithPopupDisplayer] No popup open action for route "${baseRoute}".`,
        );

        /**
         * Set error to display global ErrorBoundary instead of
         * disabled search page if js chunk absent or fetch failed
         * */
        routeWithPopupErrorVar(importError);
      });
  }, [baseRoute, client, error, loading, setDeferredPageAvailable]);

  // When we displayed one time error - we can revert it instantly after mount.
  useEffect(() => {
    if (error) setError(NO_ERROR);
  }, [error]);

  // If we got uncertain funnel - need redirect to the first funnel of the stack
  if (onUncertainFunnel && head(stack)) {
    return <Redirect to={head(stack)} />;
  }

  return error ? getErrorRedirect(error, ERROR_CODE_MAP)() : null;
};

export default RouteWithPopupDisplayer;
