import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import {Loader} from '@phoenix/ui';

/**
 * Small utility component used for dynamic chunks splitting.
 * From visual point of view it just shows loader until
 * passed module name finish loading.
 */
const SplitChunk = ({component: Component, ...props}) => (
  <Suspense fallback={<Loader fullscreen />}>
    <Component {...props} />
  </Suspense>
);

SplitChunk.propTypes /* remove-proptypes */ = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};

export default SplitChunk;
