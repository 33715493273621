import PopupAppearance from '@core/theming/constants/features/PopupAppearance';

import useThemeCompositeFeatureLoader from '@phoenix/theming/utils/useThemeCompositeFeatureLoader';

import type {ModuleComponent, ModuleResolver} from '../types';

/**
 * @todo change this type with actual type after migration all chunks on TS FE-42338
 */
export type ThemedPopupProps = {
  [key: string]: any;
};

/**
 * Exported for pre-loading in slow browsers.
 * @see ThemeFeaturesPreloader.js
 */
export const resolver: ModuleResolver<
  PopupAppearance,
  ModuleComponent<ThemedPopupProps>
> = (value) => {
  switch (value) {
    case PopupAppearance.CENTERED:
      return import(
        /* webpackChunkName: 'popupCentered' */ '@phoenix/popup/components/PopupCentered'
      );
    case PopupAppearance.CENTERED_WITH_DARK_OVERLAY:
      return import(
        /* webpackChunkName: 'popupCenteredDarkOverlay' */ '@phoenix/popup/components/PopupCenteredWithDarkOverlay'
      );
    case PopupAppearance.AS_LAYER_FROM_BOTTOM:
      return import(
        /* webpackChunkName: 'popupLayerFromBottom' */ '@phoenix/popup/components/PopupAsLayerFromBottom'
      );
    default:
      return null;
  }
};

const useThemedPopup = () =>
  useThemeCompositeFeatureLoader('popup', null, resolver);

export default useThemedPopup;
