import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import PhotoApproveWaitPopup from '../components/PhotoApproveWaitPopup';

const openWaitForApprovePopup = () => {
  PopupService.openPopup(<PhotoApproveWaitPopup />, {
    small: true,
    trackingName: 'photoApproveWait',
    autoLoadTracking: true,
  });
};

export default openWaitForApprovePopup;
