import {getClientInstance} from '@core/graphql/client';
import {MOB_SITE, WEB_SITE} from '@core/responsive/environments';
import isSmallTabletViewport from '@core/responsive/isSmallTabletViewport';
import {
  EXTRA_COINS,
  SPECIAL_OFFER_WEEK_SPEAK,
} from '@core/search/motivation/constants/motivationBannerNames';
import MOTIVATION_BANNERS_QUERY from '@core/search/motivation/graphql/queries/motivationBanners.gql';

/**
 * Need update banners after pay
 */
const updateSearchBannersAfterPay = (enabled = false) => {
  const client = getClientInstance();
  const queryOptions = {
    query: MOTIVATION_BANNERS_QUERY,
    variables: {environment: isSmallTabletViewport() ? MOB_SITE : WEB_SITE},
  };

  const bannerData = client.readQuery(queryOptions);

  if (!bannerData) {
    return;
  }

  const {list} = bannerData.search.settings.banners;

  const updatedList = list.map((banner) => {
    if (banner.id === SPECIAL_OFFER_WEEK_SPEAK) {
      return {...banner, enabled};
    }

    if (banner.id === EXTRA_COINS) {
      return {...banner, enabled: !enabled};
    }

    return banner;
  });

  client.writeQuery({
    ...queryOptions,
    data: {
      ...bannerData,
      search: {
        ...bannerData.search,
        settings: {
          ...bannerData.search.settings,
          banners: {
            ...bannerData.search.settings.banners,
            list: updatedList,
          },
        },
      },
    },
  });
};

export default updateSearchBannersAfterPay;
