let localCache;

/**
 * Use to keep updateQuery function for current like gallery query.
 * This way allows update query without fetching variables for real like gallery query.
 * @param {Function} updateQuery
 */
export const setLikeGalleryUpdateQuery = (updateQuery) => {
  localCache = updateQuery;
};

export const getLikeGalleryUpdateQuery = () => localCache;
