import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import PopupService from '@core/popup/utils/PopupService';
import useCleanProductRestrictions from '@core/application/utils/useCleanProductRestrictions';
import useEventCallback from '@core/utils/react/useEventCallback';

import ContentRestrictionPopup from './ContentRestrictionPopup';
import NaughtyModeSwitchConfirmationLayout from '../components/NaughtyModeSwitchConfirmationLayout';
import NaughtyModeService from '../NaughtyModeService';

const NaughtyModeSwitchConfirmationPopup = ({
  level,
  onNaughtyModeChange,
  ...props
}) => {
  const [disabledButton, setDisableButton] = useState(false);

  const {withCleanProductRestrictions} = useCleanProductRestrictions();

  const handleLinkClick = useCallback(() => {
    PopupService.openPopup(<ContentRestrictionPopup />, {
      trackingName: 'contentRestriction',
    });
  }, []);

  const handleButtonClick = useEventCallback(() => {
    const naughtyMode = NaughtyModeService.getNaughtyModeByPhotoLevel(level);

    setDisableButton(true);

    NaughtyModeService.updateNaughtyMode({naughtyMode})
      .then(() => {
        PopupService.closePopup();

        if (onNaughtyModeChange) {
          onNaughtyModeChange();
        }
      })
      .catch(() => setDisableButton(false));
  });

  return (
    <NaughtyModeSwitchConfirmationLayout
      {...props}
      level={level}
      disabledButton={disabledButton}
      onButtonClick={handleButtonClick}
      onLinkClick={handleLinkClick}
      withCleanProductRestrictions={withCleanProductRestrictions}
    />
  );
};

NaughtyModeSwitchConfirmationPopup.propTypes /* remove-proptypes */ = {
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onNaughtyModeChange: PropTypes.func,
};

export default NaughtyModeSwitchConfirmationPopup;
