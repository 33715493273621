import {useEffect} from 'react';
import {useApolloClient} from '@apollo/client';

import CHANGE_TIME_ZONE_MUTATION from '../graphql/mutations/timeZoneUpdater.gql';

/**
 * Hook for update timezone on backend
 */
const useTimezoneUpdate = (allowUpdate) => {
  const client = useApolloClient();

  useEffect(() => {
    allowUpdate &&
      client.mutate({
        mutation: CHANGE_TIME_ZONE_MUTATION,
        variables: {
          offset: -new Date().getTimezoneOffset() * 60,
        },
      });
  }, [allowUpdate, client]);
};

export default useTimezoneUpdate;
