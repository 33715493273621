import PAYMENT_MODELS from '@core/payment/common/constants/paymentModels';
import usePaymentModel from '@core/payment/common/utils/usePaymentModel';

/**
 * Return is coins model flag
 */
const useCoinsModel = (): {isCoinsModel: boolean; loading: boolean} => {
  const {model, loading} = usePaymentModel();

  return {
    isCoinsModel: model === PAYMENT_MODELS.COINS,
    loading,
  };
};

export default useCoinsModel;
