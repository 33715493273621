import type {Observable} from 'rxjs';
import {from, EMPTY} from 'rxjs';
import {map, filter} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import NOTIFICATION_TYPES from '@core/systemNotifications/constants/notificationTypes';
import type {CommonNotification} from '@core/systemNotifications/types';
import {sessionStorage} from '@core/utils/storage';

import type {BrowserChangeMotivationQuery} from '@phoenix/mediaUpload/graphql/queries/browserChangeMotivation';
import BROWSER_CHANGE_MOTIVATION_QUERY from '@phoenix/mediaUpload/graphql/queries/browserChangeMotivation.gql';

import {ALLOWED_NOTIFICATION_STORAGE_KEY} from '../constants/allowedNotificationStorageKey';

const browserChangeMotivationDataSource =
  (): Observable<CommonNotification> => {
    const allowed = sessionStorage.getItem(
      ALLOWED_NOTIFICATION_STORAGE_KEY,
      true,
    );

    if (!allowed) {
      return EMPTY;
    }

    return from(
      getClientInstance().query<BrowserChangeMotivationQuery>({
        query: BROWSER_CHANGE_MOTIVATION_QUERY,
      }),
    ).pipe(
      filter(({data}) => data.userFeatures.changeBrowserMotivationEnabled),
      map(() => ({type: NOTIFICATION_TYPES.BROWSER_CHANGE})),
    );
  };

export default browserChangeMotivationDataSource;
