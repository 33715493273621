import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import PopupSourceEvent from '@core/popup/constants/PopupSourceEvent';

import CoinsRewardPopup from '../containers/CoinsRewardPopup';

export default function openCoinsRewardPopup({onAccept, onClose, onDeny}) {
  PopupService.openPopup(
    <CoinsRewardPopup
      onAccept={() => {
        PopupService.closePopup();
        onAccept();
      }}
      onDeny={() => {
        PopupService.closePopup();
        onDeny();
      }}
    />,
    {
      onClose: (sourceEventType) => {
        if (
          [
            PopupSourceEvent.CLOSE_BUTTON_CLICK,
            PopupSourceEvent.BY_ESCAPE,
          ].includes(sourceEventType) &&
          onClose
        ) {
          onClose();
        }
      },
      small: true,
      trackingName: 'webPushCoinsReward',
    },
  );
}
