import type {Subject, Observable} from 'rxjs';
import {race} from 'rxjs';
import {filter, bufferTime, bufferCount} from 'rxjs/operators';

/**
 * Such observable implements racing logic between max count of tracks
 * and max waiting time
 * @see InventoryTrackingService
 */
const getInventoryTrackingObservable = <T>(
  subject: Subject<T>,
  count: number,
  delay: number,
): Observable<T[]> =>
  race(subject.pipe(bufferCount(count)), subject.pipe(bufferTime(delay))).pipe(
    // Just skip emitted empty arrays of tracks if they were excluded before
    filter((data) => Boolean(data?.length)),
  );

export default getInventoryTrackingObservable;
