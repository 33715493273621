import getWalletPay from '@core/payment/payProcess/paymentFlow/methods/getWalletPay';
import {WALLET_METHODS} from '@core/payment/common/constants/paymentMethods';
import applePay from '@core/payment/payProcess/paymentFlow/methods/applePay';
import applePayLegacy from '@core/payment/payProcess/paymentFlow/methods/applePayLegacy';
import getActiveSplitGroup from '@core/utils/split/getActiveSplitGroup';
import logger from '@core/logger';
import {SPLITS} from '@core/utils/split';
import type {PaymentProcessStep} from '@core/payment/payProcess/types/paymentProcess';

import googlePay from '@phoenix/payment/payProcess/paymentFlow/methods/googlePay';
import googlePayLegacy from '@phoenix/payment/payProcess/paymentFlow/methods/googlePayLegacy';
import paypalPay from '@phoenix/payment/payProcess/paymentFlow/methods/paypalPay';
import paypalPayLegacy from '@phoenix/payment/payProcess/paymentFlow/methods/paypalPayLegacy';

const getWalletPayByMethod: (method: WALLET_METHODS) => PaymentProcessStep = (
  method,
) => {
  return async (data) => {
    let realPay = null;

    const isFetchApi = await getActiveSplitGroup(
      SPLITS.PAYMENT_PROCESSING_FETCH_API.ID,
      SPLITS.PAYMENT_PROCESSING_FETCH_API.GROUP.ACTIVE,
    );

    switch (method) {
      case WALLET_METHODS.APPLE_PAY:
        realPay = isFetchApi ? applePay : applePayLegacy;
        break;
      case WALLET_METHODS.GOOGLE_PAY:
        realPay = isFetchApi ? googlePay : googlePayLegacy;
        break;
      case WALLET_METHODS.PAY_PAL:
      case WALLET_METHODS.PAY_PAL_V2:
        realPay = isFetchApi ? paypalPay : paypalPayLegacy;
        break;
      default:
        logger.sendError(
          `[getWalletPayByMethod] Not provided method: ${method}`,
        );
    }

    const result = await getWalletPay(method, realPay, isFetchApi)(data);

    return result;
  };
};

export default getWalletPayByMethod;
