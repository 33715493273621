import React, {Fragment, useCallback} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import {MESSENGER} from '@core/mediaUpload/constants/sources';
import {settingsPropType} from '@core/mediaUpload/constants/propTypes';
import SOURCES_WITH_MOTIVATION from '@core/mediaUpload/constants/sourcesWithMotivation';
import useUploadFilesMultiple from '@core/mediaUpload/utils/useUploadFilesMultiple';
import MediaUploadPopupDisplayer from '@core/mediaUpload/containers/MediaUploadPopupDisplayer';
import MediaUploadAppearance from '@core/theming/constants/features/MediaUploadAppearance';

import {Text} from '@phoenix/typography';
import {Loader, Row} from '@phoenix/ui';
import useThemedMediaUpload from '@phoenix/theming/utils/useThemedMediaUpload';
import VideoDisapproveReasons from '@phoenix/disapprove/containers/VideoDisapproveReasons';
import MessengerDisapproveReasons from '@phoenix/disapprove/containers/MessengerDisapproveReasons';
import RemarketingBanner from '@phoenix/remarketing/banner';
import PLACEMENT_IDS from '@phoenix/remarketing/banner/constants/placementIds';
import CreditAmount from '@phoenix/credits/components/CreditAmount';
import COIN_ICON_SIZES from '@phoenix/credits/constants/coinColorizedIconSizes';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import MediaUploadPopupLayout from '../components/MediaUploadPopupLayout';
import openErrorsPopup from '../utils/openErrorsPopup';
import MediaPreview from '../components/MediaPreview';
import VideoUploadActions from '../components/VideoUploadActions';
import WebcamVideoCapture from '../components/WebcamVideoCapture';
import VideoRequirements from './VideoRequirements';
import WebcamVideoRequirements from './WebcamVideoRequirements';
import DisapproveReasonsWrapper from '../components/DisapproveReasonsWrapper';
import UploadErrorsInline from '../components/UploadErrorsInline';

const HIDE_ICON = [
  MediaUploadAppearance.WITH_INVERSE_BACKGROUND_AND_UPLOAD_ACTION_MOTIVATION,
  MediaUploadAppearance.WITH_PHOTO_ON_BACKGROUND_AS_MOTIVATION,
];
const VideoUploadPopup = ({spacedFooter, settings}) => {
  const {source, accept, uploadCost} = settings;

  const {data: mediaAppearance, loading: appearanceLoading} = useThemeFeature(
    'mediaUpload',
    'appearance',
  );

  const {
    loading,
    data: {motivation: MediaUploadMotivation, layout: MediaUploadLayout} = {},
  } = useThemedMediaUpload();

  const uploadRenderer = useCallback(
    (props) => (
      <Fragment>
        <VideoUploadActions accept={accept} {...props} />

        {/* TODO: Need to add component for upload video cost, which will be based on UploadCost */}
        {Boolean(uploadCost) && (
          <Row>
            <CreditAmount
              textType={Text.TYPE.MUTED}
              iconSize={COIN_ICON_SIZES.SMALLEST}
              small
            >
              {uploadCost}
            </CreditAmount>
          </Row>
        )}
      </Fragment>
    ),
    [accept, uploadCost],
  );

  const {isUploading, setFiles, errors, clearErrors} = useUploadFilesMultiple({
    settings,
    uploadRenderer,
    openErrorsPopup,
  });

  const webcamRenderer = useCallback(
    (props) => <WebcamVideoCapture {...props} />,
    [],
  );
  const previewRenderer = useCallback(
    (props) => (
      <MediaPreview
        sendText={
          source === MESSENGER
            ? t('mediaUpload', 'text.send')
            : t('mediaUpload', 'text.add')
        }
        cancelText={t('mediaUpload', 'text.retake_video')}
        {...props}
      />
    ),
    [source],
  );

  /**
   * Chunk with layout components load too quickly and
   * there is no need to render placeholders
   */
  if (loading || appearanceLoading) return null;

  const withMotivation = SOURCES_WITH_MOTIVATION.includes(source);

  return (
    <MediaUploadPopupDisplayer
      loaderComponent={Loader}
      uploadErrorsInlineComponent={UploadErrorsInline}
      openErrorsPopup={openErrorsPopup}
      layoutComponent={
        withMotivation ? MediaUploadLayout : MediaUploadPopupLayout
      }
      settings={settings}
      isUploading={isUploading}
      onFileSelect={setFiles}
      errors={errors}
      clearErrors={clearErrors}
      uploadRenderer={uploadRenderer}
      webcamRenderer={webcamRenderer}
      previewRenderer={previewRenderer}
      spacedFooter={spacedFooter}
      motivation={
        withMotivation && (
          <MediaUploadMotivation
            type="video"
            title={t('funnelPhotoUploadMotivation', 'text.add_videos')}
            onFileSelect={setFiles}
            accept={accept}
          />
        )
      }
      requirements={<VideoRequirements />}
      webcamRequirements={<WebcamVideoRequirements />}
      footer={({wrappedFooter}) => (
        <Fragment>
          <RemarketingBanner placementId={PLACEMENT_IDS.BANNER_PHOTO_UPLOAD} />
          <DisapproveReasonsWrapper
            icon="video"
            withIcon={!HIDE_ICON.includes(mediaAppearance)}
            unbordered
            component={
              source === MESSENGER
                ? MessengerDisapproveReasons
                : VideoDisapproveReasons
            }
            wrapped={wrappedFooter}
          />
        </Fragment>
      )}
    />
  );
};

VideoUploadPopup.propTypes /* remove-proptypes */ = {
  settings: settingsPropType.isRequired,
  spacedFooter: PropTypes.bool,
};

export default VideoUploadPopup;
