import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyChat = lazy(
  () => import(/* webpackChunkName: 'chat' */ '../routes/ChatRoute'),
);

const ChatChunk = (props) => <SplitChunk component={LazyChat} {...props} />;

export default ChatChunk;
