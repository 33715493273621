import type {FC} from 'react';
import React from 'react';

import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';

import type {NoticeProps} from '@phoenix/ui';
import {Spacing, Notice} from '@phoenix/ui';
import {SpacingSize} from '@phoenix/ui/constants';

/**
 * Wrap around Notice component, that adds top padding for Notice content.
 */
const PopupNotice: FC<NoticeProps> = (props) => {
  const {'data-test': dataTest, heading, ...otherProps} = props;

  return useSmallTabletBreakpoint() ? (
    <Spacing
      onlyTop
      size={heading ? SpacingSize.LARGE : SpacingSize.NORMAL}
      adaptive={false}
      data-test={dataTest}
    >
      <Notice heading={heading} {...otherProps} />
    </Spacing>
  ) : (
    <Notice
      data-test={dataTest}
      spacing={SpacingSize.LARGE}
      heading={heading}
      {...otherProps}
    />
  );
};

export default PopupNotice;
