import React from 'react';
import PropTypes from 'prop-types';

import {Input} from '@phoenix/ui';

import useRemainingCharacters from './useRemainingCharacters';

/**
 * Input which counts symbols number and limit them
 */
const InputWithMaxLength = ({
  name,
  value = '',
  maxLength = 100,
  onChange,
  ...props
}) => {
  const {onChange: onSpiedChange, description} = useRemainingCharacters(
    value,
    maxLength,
    onChange,
  );

  return (
    <Input
      {...props}
      name={name}
      value={value}
      maxLength={maxLength}
      onChange={onSpiedChange}
      description={description}
    />
  );
};

InputWithMaxLength.propTypes /* remove-proptypes */ = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  value: PropTypes.string,
};

export default InputWithMaxLength;
