import type {FC} from 'react';

import useThemeColorScheme from '../utils/useThemeColorScheme';
import useThemedSearchWidget from '../utils/useThemedSearchWidget';
import useThemedSearchMotivationWidget from '../utils/useThemedSearchMotivationWidget';
import useThemedHorizontalWidget from '../utils/useThemedHorizontalWidget';
import useThemedUserActions from '../utils/useThemedUserActions';
import useThemedPopup from '../utils/useThemedPopup';

/**
 * Avoid adding all theme-related modification here since it will
 * cause "all app loading" on application bootstrap. Add here only
 * things that only requires that and user can see slowdowns during rendering.
 */
const ThemeFeaturesPreloader: FC = () => {
  /**
   * Load basic variables for light\dark branding color modifications until entire
   * application is still loading.
   */
  useThemeColorScheme();

  /**
   * Pleload search widgets since it calls horrible "jumping" content.
   * Motivation widgets start rendering, between them search widgets are added, after that - RM banners are injected.
   * It's a mess. :)
   */
  useThemedUserActions();
  useThemedSearchWidget();
  useThemedSearchMotivationWidget();
  useThemedHorizontalWidget();

  // Preload the popup chunk to prevent multiple popups from opening
  // when the same button is clicked multiple times while the popup chunk is still loading.
  useThemedPopup();

  return null;
};

export default ThemeFeaturesPreloader;
