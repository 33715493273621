import {from, fromEvent, merge} from 'rxjs';
import {filter, takeUntil, startWith, pairwise, map} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';
import ROUTE_QUERY from '@core/application/graphql/queries/route.gql';

import NOTIFICATION_EVENT from '../constants/notificationEvent';

/**
 * @param client
 * @returns {Observable<*>}
 */
const getRouteObservable = (client = getClientInstance()) =>
  from(client.watchQuery({query: ROUTE_QUERY})).pipe(
    startWith(null),
    pairwise(),
    filter(([prev, current]) => {
      if (!prev) return true;

      /**
       * Check navigation action to avoid double proposing in some cases.
       * For example, when going to /search which redirects to /search?gender=...
       */
      return current.data.route.current.indexOf(prev.data.route.current) === -1;
    }),
    map(([, current]) => current),
    takeUntil(
      merge(
        fromEvent(window, NOTIFICATION_EVENT.ACCEPTED),
        fromEvent(window, NOTIFICATION_EVENT.PROPOSED),
      ),
    ),
  );

export default getRouteObservable;
