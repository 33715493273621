import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations';

import {ButtonPrimary} from '@phoenix/ui';

/**
 * @see TrustedVerificationSmsFirstStepLayout.js
 */
const TrustedVerificationSmsFirstStepLayoutAction = ({isSubmitting}) => {
  return (
    <ButtonPrimary
      submit
      trackingName="submit"
      loading={isSubmitting}
      fullWidth
      adaptive
      inline
      data-test="submitButton"
      form="firstStepForm"
    >
      {t('paymentTrusted', 'text.verify')}
    </ButtonPrimary>
  );
};

TrustedVerificationSmsFirstStepLayoutAction.propTypes /* remove-proptypes */ = {
  isSubmitting: PropTypes.bool,
};

export default TrustedVerificationSmsFirstStepLayoutAction;
