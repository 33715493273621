/**
 * When something goes wrong on a server-side with the dateinform subscription
 * there is a need to resubscribe
 * @const
 */
const DATEINFORM_RESUBSCRIBE_LOGIC = {
  CUSTOM_POPUP: 'custom_popup',
  DATEINFORM_POPUP: 'dateinform_popup',
  DATEINFORM_POPUNDER: 'dateinform_popunder',
  DISABLED: 'disabled',
};

export default DATEINFORM_RESUBSCRIBE_LOGIC;
