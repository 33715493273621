import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {Button} from '@phoenix/ui';
import {H3, Title1} from '@phoenix/typography';
import {ButtonType, SpacingSize} from '@phoenix/ui/constants';
import PopupNotice from '@phoenix/popup/components/PopupNotice';

import css from '../styles/ExtraCoinsPopup.css';

const ExtraCoinsPopup = ({discount, onClick}) => (
  <div className={css.wrap}>
    <PopupNotice
      description={
        <Fragment>
          <H3 muted>{t('coins', 'title.exclusive_offer')}</H3>
          <Title1>{`+${discount}%`}</Title1>
          <H3 muted>{t('coins', 'text.bonus_coins_next_purchase')}</H3>
        </Fragment>
      }
      actions={[
        <Button
          type={ButtonType.PAY}
          onClick={onClick}
          trackingName="getExtraCoins"
          fullWidth
        >
          {t('coins', 'button.get_extra_coins')}
        </Button>,
      ]}
      actionSpacing={SpacingSize.LARGE}
    />
  </div>
);

ExtraCoinsPopup.propTypes /* remove-proptypes */ = {
  discount: PropTypes.number,
  onClick: PropTypes.func,
};

export default ExtraCoinsPopup;
