import EMAIL_CONFIRMED_QUERY from '../graphql/queries/emailConfirmed.gql';

/**
 * Email confirm access rule.
 */
export default {
  errorCode: 'NOT_ALLOWED',
  query: EMAIL_CONFIRMED_QUERY,
  resolver: (data) =>
    !data.userFeatures ||
    (!data.userFeatures.emailData.isConfirmed &&
      data.userFeatures.isEmailConfirmRequired),
};
