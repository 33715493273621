import {useEffect} from 'react';

import translationsLoadedVar from '@core/graphql/vars/translationsLoadedVar';

const TranslationsLoadedSetter = () => {
  useEffect(() => {
    if (!translationsLoadedVar()) {
      translationsLoadedVar(true);
    }
  }, []);

  return null;
};

export default TranslationsLoadedSetter;
