import {merge, from} from 'rxjs';
import {map} from 'rxjs/operators';
import once from 'lodash/once';

import GLOBAL_FREE_MESSAGE_INCREASE_SUBSCRIPTION from '../graphql/subscriptions/globalFreeMessageCountIncrease.gql';
import GLOBAL_FREE_MESSAGE_DECREASE_SUBSCRIPTION from '../graphql/subscriptions/globalFreeMessageCountDecrease.gql';
import updateGlobalFreeMessagesCount from './updateGlobalFreeMessagesCount';

const TYPES = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
};

/**
 * @param client
 */
const startUpdateGlobalMessageCountListener = once((client) => {
  merge(
    from(
      client.subscribe({query: GLOBAL_FREE_MESSAGE_INCREASE_SUBSCRIPTION}),
    ).pipe(map((data) => ({source: TYPES.INCREASE, data}))),
    from(
      client.subscribe({query: GLOBAL_FREE_MESSAGE_DECREASE_SUBSCRIPTION}),
    ).pipe(map((data) => ({source: TYPES.DECREASE, data}))),
  ).subscribe(
    ({
      source,
      data: {
        data: {
          globalFreeMessage: {count, time},
        },
      },
    }) => {
      const hideMotivationBanner = source === TYPES.INCREASE;

      updateGlobalFreeMessagesCount(count, time, hideMotivationBanner);
    },
  );
});

export default startUpdateGlobalMessageCountListener;
