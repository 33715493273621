import React from 'react';

import useSiteName from '@core/application/utils/useSiteName';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import ContentRestriction from '../components/ContentRestriction';

/**
 * Popup with content restrictions info about naughty mode
 * and used only for naughty mode popup
 */
const ContentRestrictionPopup = () => {
  const {siteName, loading, error} = useSiteName();

  if (error) return <ErrorBoundary error={error} />;
  if (loading) return null;
  return <ContentRestriction siteName={siteName} />;
};

export default ContentRestrictionPopup;
