import React from 'react';

import CommonLogo from '@core/logo/containers/Logo';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';

import LogoLayout from './LogoLayout';
import LogoPlaceholder from './LogoPlaceholder';

const Logo = (props) => {
  return (
    <CommonLogo
      placeholder={LogoPlaceholder}
      layout={LogoLayout}
      errorBoundary={ErrorBoundary}
      {...props}
    />
  );
};

export default Logo;
