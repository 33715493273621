import React from 'react';
import PropTypes from 'prop-types';

import {Align} from '@phoenix/ui/constants';
import {Group, PlaceholderBar, PlaceholderCircle} from '@phoenix/ui';
import PopupFrame from '@phoenix/popup/components/PopupFrame';
import PhotosListAsRoulettePlaceholder from '@phoenix/user/photo/components/PhotosListAsRoulettePlaceholder';

const PopupWithUserPhotosPlaceholder = ({withOnePhoto}) => (
  <PopupFrame
    content={
      <Group align={Align.AROUND}>
        {withOnePhoto ? (
          <PlaceholderCircle size={120} />
        ) : (
          <PhotosListAsRoulettePlaceholder />
        )}
        <PlaceholderBar size={12} />
        <PlaceholderBar size={12} />
      </Group>
    }
  />
);

PopupWithUserPhotosPlaceholder.propTypes /* remove-proptypes */ = {
  withOnePhoto: PropTypes.bool.isRequired,
};

export default PopupWithUserPhotosPlaceholder;
