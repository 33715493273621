import payFromPopup from '@core/payment/payProcess/paymentFlow/methods/payFromPopup';
import cachePurchasedPackage from '@core/payment/payProcess/paymentFlow/methods/cachePurchasedPackage';
import trackPayClick from '@core/payment/payProcess/paymentFlow/methods/trackPayClick';
import reportStartPayment from '@core/payment/payProcess/paymentFlow/methods/reportStartPayment';
import reportEndPayment from '@core/payment/payProcess/paymentFlow/methods/reportEndPayment';
import processPaymentAnswer from '@core/payment/payProcess/paymentFlow/methods/processPaymentAnswer';
import addPaymentData from '@core/payment/payProcess/paymentFlow/methods/addPaymentData';
import resetSelectedAdditionalPackages from '@core/payment/payProcess/paymentFlow/methods/resetSelectedAdditionalPackages';
import addBrowserDetails from '@core/payment/payProcess/paymentFlow/methods/addBrowserDetails';
import updateCSRFToken from '@core/payment/payProcess/paymentFlow/methods/updateCSRFToken';
import externalThreeDSRedirect from '@core/payment/payProcess/paymentFlow/methods/externalThreeDSRedirect';

const getMicroFeaturesCoinsPopupFlow = () => {
  return [
    reportStartPayment,
    trackPayClick,
    cachePurchasedPackage,
    addPaymentData,
    addBrowserDetails,
    payFromPopup,
    updateCSRFToken,
    externalThreeDSRedirect,
    reportEndPayment,
    resetSelectedAdditionalPackages,
    processPaymentAnswer,
  ];
};

export default getMicroFeaturesCoinsPopupFlow;
