import type {FC, ReactNode} from 'react';
import React, {Children} from 'react';

type FooterGroupProps = {
  children: ReactNode;
};

const FooterGroup: FC<FooterGroupProps> = ({children, ...props}) => {
  const childrenArray = Children.toArray(children);

  if (!childrenArray.length) {
    return null;
  }

  return <div {...props}>{children}</div>;
};

export default FooterGroup;
