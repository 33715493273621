import freeTrialSuccessStatisticConfig from './freeTrialSuccessStatisticConfig';
import freeFeatureNames from '../constants/freeFeatureNames';
import priorityManStartConfig from './priorityManStartConfig';
import priorityManStatisticConfig from './priorityManStatisticConfig';
import priorityManUpsellConfig from './priorityManUpsellConfig';
import sexAlarmUpsellConfig from './sexAlarmUpsellConfig';
import boostUpsellConfig from './boostUpsellConfig';

export default {
  [freeFeatureNames.PRIORITY_MAN_START]: priorityManStartConfig,
  [freeFeatureNames.PRIORITY_MAN_STATISTIC]: priorityManStatisticConfig,
  [freeFeatureNames.PRIORITY_MAN_UPSELL]: priorityManUpsellConfig,
  [freeFeatureNames.SEX_ALARM_UPSELL]: sexAlarmUpsellConfig,
  [freeFeatureNames.BOOST_UPSELL]: boostUpsellConfig,
  [freeFeatureNames.FREE_TRIAL_SUCCESS]: freeTrialSuccessStatisticConfig,
};
