import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';
import useSmallTabletBreakpoint from '@core/responsive/useSmallTabletBreakpoint';

import {
  Actions,
  Button,
  ButtonPrimary,
  ClosablePopover,
  Row,
  RowShort,
} from '@phoenix/ui';
import {
  Align,
  PopperPlacement,
  PopperTrigger,
  SpacingSize,
} from '@phoenix/ui/constants';
import {H3, Link, Text, TextMuted} from '@phoenix/typography';
import StaticPagePopupHelper from '@phoenix/staticPages/utils/StaticPagePopupHelper';

const MediaUploadMotivationPma = ({onAgree, onClose}) => {
  const isSmallTablet = useSmallTabletBreakpoint();

  return (
    <Fragment>
      <RowShort align={Align.CENTER}>
        <H3>{t('funnelPhotoUploadMotivation', 'title.try_out')}</H3>
      </RowShort>
      <RowShort align={Align.CENTER}>
        <TextMuted>
          {t('funnelPhotoUploadMotivation', 'text.want_to_activate', {
            '{popover}': (
              <ClosablePopover
                trackingName="promoteMyAccount"
                trigger={PopperTrigger.CLICK}
                placement={PopperPlacement.TOP}
                usePortal={false}
                fullWidth
                content={
                  <Text align={Text.ALIGN.LEFT} type={Text.TYPE.INHERIT}>
                    {`${t(
                      'funnelPhotoUploadMotivation',
                      'text.info_promote_my_account',
                    )} `}
                    <Link
                      trackingName="moreDetails"
                      onClick={(event) => {
                        StaticPagePopupHelper.openPopup(
                          StaticPagePopupHelper.PAGES.TERMS,
                          event,
                          {
                            scrollToId: 'promotedMessages',
                          },
                        );
                      }}
                      inline
                      underline
                      inherit
                    >
                      {t('funnelPhotoUploadMotivation', 'link.more_details')}
                    </Link>
                  </Text>
                }
              >
                <span>
                  <Link underline muted>
                    {t(
                      'funnelPhotoUploadMotivation',
                      'text.promote_my_account',
                    )}
                  </Link>
                </span>
              </ClosablePopover>
            ),
          })}
        </TextMuted>
      </RowShort>
      <Row>
        <Actions
          itemSpacing={SpacingSize.SHORT}
          fullWidth
          align={Align.STRETCH}
          vertical={isSmallTablet}
          reverse={isSmallTablet}
        >
          <Button fullWidth onClick={onClose} trackingName="tryLater">
            {t('funnelPhotoUploadMotivation', 'button.try_it_later')}
          </Button>
          <ButtonPrimary fullWidth onClick={onAgree} trackingName="agree">
            {t('funnelPhotoUploadMotivation', 'button.looks_good')}
          </ButtonPrimary>
        </Actions>
      </Row>
    </Fragment>
  );
};

MediaUploadMotivationPma.propTypes /* remove-proptypes */ = {
  onAgree: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MediaUploadMotivationPma;
