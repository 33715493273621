import React from 'react';
import {useQuery} from '@apollo/client';

import getSortedDisapproveList from '@core/disapprove/utils/getSortedDisapproveList';
import PHOTO_DISAPPROVE_REASONS from '@core/disapprove/graphql/queries/photoDisapproveReasons.gql';

import ErrorBoundary from '@phoenix/graphql/components/ErrorBoundary';
import useThemeFeature from '@phoenix/theming/utils/useThemeFeature';

import BaseMediaDisapproveReasons from '../components/BaseMediaDisapproveReasons';
import MediaDisapproveReasonsPlaceholder from '../components/MediaDisapproveReasonsPlaceholder';

const PhotoDisapproveReasons = (props) => {
  const {data: sortedDisapproveReasons, loading: themeLoading} =
    useThemeFeature('mediaUpload', 'sortedDisapproveReasons');
  const {data, loading, error} = useQuery(PHOTO_DISAPPROVE_REASONS);

  if (loading || themeLoading) {
    return <MediaDisapproveReasonsPlaceholder icon="photo" {...props} />;
  }

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  const reasons = data.disapprove.reasons.profilePhoto;

  return (
    <BaseMediaDisapproveReasons
      icon="photo"
      reasons={
        sortedDisapproveReasons ? getSortedDisapproveList(reasons) : reasons
      }
      {...props}
    />
  );
};

export default PhotoDisapproveReasons;
