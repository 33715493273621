import find from 'lodash/find';

import {getClientInstance} from '@core/graphql/client';
import invalidateCacheByTypename from '@core/graphql/utils/invalidateCacheByTypename';
import SEPARATED_DIALOGS_QUERY from '@core/messenger/common/graphql/queries/separatedDialogs.gql';
import {COUNT_USERS_COMPLETION} from '@core/messenger/common/constants/userCountForActiveDialogsCompletion';

import CONVERSATION_USER from '../graphql/queries/conversationUser.gql';
import CONVERSATION_LIST_QUERY from '../graphql/queries/conversationalist.gql';
/**
 * Change active dialogs completion user
 * @param {object} client
 * @param {string} userId
 * @param {boolean} addUser - when true add user to conversation list, when false - remove user from it.
 */
const changeActiveDialogsCompletionUser = async (
  client = getClientInstance(),
  userId,
  addUser,
) => {
  // check on available separatedDialogs, when disable - don't add user on page chat/activeDialogs, because
  // hidden this link.
  const {
    data: {
      userFeatures: {separatedDialogs},
    },
  } = await client.query({query: SEPARATED_DIALOGS_QUERY});

  if (!separatedDialogs) {
    return;
  }

  const data = client.readQuery({query: CONVERSATION_LIST_QUERY});

  if (!data) {
    // in case when completion user list isn't in cache
    return;
  }

  const usersForDialogue = [...data.conversationalist.usersForDialogue];

  const conversationListQueryData = {
    query: CONVERSATION_LIST_QUERY,
    data: {
      ...data,
      conversationalist: {
        ...data.conversationalist,
        usersForDialogue,
      },
    },
  };

  if (addUser) {
    const {
      data: {user},
    } = await client.query({query: CONVERSATION_USER, variables: {id: userId}});

    // protect from double add user, e.g. src/packages/dating/user/actions/utils/blockUser.js call twice
    // changeActiveDialogsCompletionUser it's feature method update of client graphql.
    if (find(usersForDialogue, {id: user.id})) {
      return;
    }

    // add user to the beginning of the list
    usersForDialogue.unshift(user);

    if (usersForDialogue.length >= COUNT_USERS_COMPLETION) {
      usersForDialogue.pop();
    }

    client.writeQuery(conversationListQueryData);
  } else {
    // when block or report user invalidate all cache, when return to chat/activeDialogs - refetch.
    // Because must COUNT_USERS_COMPLETION users all time
    invalidateCacheByTypename(client, 'ROOT_QUERY', 'conversationalist');
  }
};

export default changeActiveDialogsCompletionUser;
