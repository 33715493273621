import getTheme from '@core/application/utils/getTheme';
import type ImageGroups from '@core/theming/constants/ImageGroups';

import EXTENSION_BY_IMAGE_GROUP from '../constants/extensionByImageGroup';

const theme = getTheme();

const getThemedImageUrl = (group: ImageGroups, identifier: string) => {
  return `./${theme?.images[group]}/${group}/${identifier}.${
    EXTENSION_BY_IMAGE_GROUP[group]
  }`;
};

export default getThemedImageUrl;
