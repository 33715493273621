import type {FC} from 'react';
import React from 'react';

import useTabletBreakpoint from '@core/responsive/useTabletBreakpoint';
import t from '@core/translations/translate';

import {Text, Title4, TextWithIcon} from '@phoenix/typography';
import {
  Card,
  Spacing,
  Container,
  Row,
  ButtonPay,
  ButtonFlat,
  Icon,
} from '@phoenix/ui';
import {IconSize, SpacingSize, SpacingDirection} from '@phoenix/ui/constants';

import css from '../styles/PaymentBottomNotice.css';

/**
 * Placed on bottom of page payment notification.
 */
type PaymentBottomNoticeLayoutProps = {
  title: string;
  description: string;
  action: string;
  phoneNumber: string;
  handleCloseClick: () => void;
  handleActionClick: () => void;
};

const PaymentBottomNoticeLayout: FC<PaymentBottomNoticeLayoutProps> = ({
  title,
  description,
  action,
  phoneNumber,
  handleCloseClick,
  handleActionClick,
}) => {
  const isTablet = useTabletBreakpoint();
  const space = isTablet ? SpacingSize.NORMAL : SpacingSize.LARGE;
  const closeButton = (
    <ButtonFlat
      round
      inverse
      icon="cross"
      onClick={handleCloseClick}
      trackingName="bottomNoticeClose"
      data-test="bottomNoticeClose"
    />
  );

  return (
    <Card
      inverse
      boundless
      shadowLevel={3}
      className={css.notice}
      data-test="bottomNotice"
    >
      {!isTablet && <div className={css.close}>{closeButton}</div>}
      <Spacing direction={SpacingDirection.VERTICAL}>
        <Container className={css.content}>
          <Row space={space} horizontal noShrink className={css.title}>
            <TextWithIcon
              icon={<Icon type="alert" warning size={IconSize.SIZE_36} />}
            >
              <Title4 warning uppercase>
                {title}
              </Title4>
            </TextWithIcon>
          </Row>
          <Row
            space={space}
            horizontal
            flexibleWidth
            className={css.description}
          >
            <Text inverse>{description}</Text>
          </Row>
          <Row space={space} horizontal noShrink>
            <ButtonPay
              onClick={handleActionClick}
              trackingName="bottomNoticePay"
              fixedHeight
              data-test="bottomNoticeAction"
            >
              {action}
            </ButtonPay>
          </Row>
          <Row space={space} horizontal>
            <Text inverse>{t('bottomNotice', 'text.call')}</Text>
            <Text inverse className={css.phone}>
              {phoneNumber}
            </Text>
          </Row>
          {isTablet && (
            <Row space={space} horizontal noShrink>
              {closeButton}
            </Row>
          )}
        </Container>
      </Spacing>
    </Card>
  );
};

export default PaymentBottomNoticeLayout;
