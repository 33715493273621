/**
 * Possible types of widget for NaughtyModeSwitchConfirmationPopup
 * PHOTO is selected dy default
 */

export const PHOTO = 'photo';

export const VIDEO = 'video';

export const STICKER = 'sticker';
