import React from 'react';

import PopupService from '@core/popup/utils/PopupService';
import getUserAgentParser from '@core/utils/getUserAgentParser';
import PopupPriority from '@core/popup/constants/PopupPriority';

import ProgressiveWebApplicationMotivation from '../containers/ProgressiveWebApplicationMotivation';
import isStandalone from './isStandalone';
import {MOTIVATION_ALLOWED_OS_NAME} from '../constants/motivationAllowedOsName';

/**
 * @return {boolean} - is popup open flag
 */
const openProgressiveWebApplicationMotivationPopup = () => {
  if (
    isStandalone() ||
    getUserAgentParser().getOS().name !== MOTIVATION_ALLOWED_OS_NAME
  ) {
    return false;
  }

  PopupService.openPopup(
    <ProgressiveWebApplicationMotivation
      closePopup={() => {
        PopupService.closePopup();
      }}
    />,
    {
      small: true,
      trackingName: 'pwaMotivation',
      priority: PopupPriority.LOW,
    },
  );

  return true;
};

export default openProgressiveWebApplicationMotivationPopup;
