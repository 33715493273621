import React from 'react';
import PropTypes from 'prop-types';

import t from '@core/translations/translate';

import {ButtonFlat} from '@phoenix/ui';

import css from '../styles/VideoControls.css';

const VideoControls = ({onDeleteClick}) => (
  <div className={css.container}>
    <ButtonFlat
      className={css.button}
      icon="trash"
      data-test="videoControlsAction"
      inverse
      onClick={onDeleteClick}
      trackingName="deleteVideo"
    >
      {t('profile', 'text.delete_video')}
    </ButtonFlat>
  </div>
);

VideoControls.propTypes /* remove-proptypes */ = {
  onDeleteClick: PropTypes.func.isRequired,
};

export default VideoControls;
