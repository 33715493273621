import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {footerDataPropTypes} from '@core/payment/widgets/footer/constants/propTypes';
import useIsGooglePayCvvForm from '@core/payment/forms/googlePay/utils/useIsGooglePayCvvForm';
import usePaymentPackageDescriptionRule from '@core/payment/widgets/packageDescription/utils/usePaymentPackageDescriptionRule';

import {TextMuted} from '@phoenix/typography';
import {Row, RowShort} from '@phoenix/ui';
import {Align, SpacingSize} from '@phoenix/ui/constants';
import ContactsForCommunication from '@phoenix/support/common/containers/ContactsForCommunication';

import PaymentPackageDescription from '../../packageDescription/containers/PaymentPackageDescription';
import PaymentAdditionalPackage from '../../additionalPackage/containers/PaymentAdditionalPackage';
import PaymentBillingPolicy from '../../billingPolicy/containers/PaymentBillingPolicy';
import PaymentCredentialsPolicyFactory from '../../billingPolicy/components/PaymentCredentialsPolicyFactory';
import PaymentSecurityLogos from '../../securityLogos/containers/PaymentSecurityLogos';
import PaymentDescriptor from '../../descriptor/containers/PaymentDescriptor';
import PaymentDNSMPI from '../../dnsmpi/containers/PaymentDNSMPI';
import PaymentDetails from '../../details/components/PaymentDetails';
import PaymentAdditionalDetails from '../../details/containers/PaymentAdditionalDetails';
import PaymentCopyright from '../containers/PaymentCopyright';
import PaymentFooterGuaranteesAndReturnsStatement from '../containers/PaymentFooterGuaranteesAndReturnsStatement';
import PaymentFooterCoinsAutofillAgreement from '../containers/PaymentFooterCoinsAutofillAgreement';
import useActivationFeeText from '../utils/useActivationFeeText';
import FooterGroup from './FooterGroup';
import FooterRow from './FooterRow';
import PaymentHotLine from './PaymentHotLine';
import PaymentUserPublicId from './PaymentUserPublicId';
import PaymentLicense from './PaymentLicense';
import PaymentKonbiniInfo from '../containers/PaymentKonbiniInfo';
import css from '../styles/PaymentFooterLayout.css';

const PaymentFooterLayout = ({
  data,
  isAdditionalPackageFirst,
  packageDescriptionOnTop,
  subscriptionInfo,
  withAdditionalPackage,
  withActivationFee,
  withBillingPolicy: withBillingPolicyFromProps,
  withCredentialsPolicy,
  withCopyright,
  withDescriptor,
  withDetails,
  withContacts,
  withAdditionalDetails,
  isAutoRenewalAgree,
  withDnsmpi,
  withGuarantees,
  withKonbiniInfo,
  isSliderFooter,
  withPackageDescription: withPackageDescriptionFromProps,
  billingPolicyRenderer,
  packageDescription: packageDescriptionFromProps,
  withSecurityLogos,
  withHotLine,
  withUserPublicId,
  withCurrencyConvertStatement,
  withCoinsAutofillAgreement,
  paymentButtonTitle,
  onlyProcessingFeeText,
}) => {
  const isGooglePayCvvForm = useIsGooglePayCvvForm();
  const paymentActivationFee = useActivationFeeText();

  const {
    payment: {
      paymentFooter: {
        blockDataMap: {
          copyright,
          hotlineCreditCard,
          footerLicense,
          currencyConvertStatement,
        },
        displaySettingData: {userPublicId} = {},
      },
    },
  } = data;

  const {isWithBillingPolicy: isPackageDescriptionWithBillingPolicy} =
    usePaymentPackageDescriptionRule();

  const withBillingPolicy =
    withBillingPolicyFromProps &&
    !isPackageDescriptionWithBillingPolicy &&
    !isGooglePayCvvForm;

  const withPackageDescription = isPackageDescriptionWithBillingPolicy
    ? withBillingPolicyFromProps
    : withPackageDescriptionFromProps;

  const packageDescription = packageDescriptionFromProps ? (
    <FooterRow>{packageDescriptionFromProps}</FooterRow>
  ) : (
    <PaymentPackageDescription
      withCheckbox={isAutoRenewalAgree}
      paymentButtonTitle={paymentButtonTitle}
    />
  );

  return (
    <TextMuted small align={Align.CENTER} className={css.wrap}>
      <FooterGroup>
        {withDetails && (
          <PaymentDetails onlyProcessingFeeText={onlyProcessingFeeText} />
        )}
        {withAdditionalDetails && <PaymentAdditionalDetails />}
        {withPackageDescription &&
          packageDescriptionOnTop &&
          packageDescription}
        {billingPolicyRenderer &&
          billingPolicyRenderer(copyright?.copyrightUnifyData)}
        {withSecurityLogos && <PaymentSecurityLogos />}
        {withKonbiniInfo && <PaymentKonbiniInfo />}
        {withGuarantees && <PaymentFooterGuaranteesAndReturnsStatement />}
        {withPackageDescription &&
          !packageDescriptionOnTop &&
          packageDescription}
        {withDescriptor && (
          <PaymentDescriptor align={Align.CENTER} muted small />
        )}
        {withCurrencyConvertStatement && currencyConvertStatement && (
          <FooterRow>{currencyConvertStatement}</FooterRow>
        )}
        {isAdditionalPackageFirst && withAdditionalPackage && (
          <PaymentAdditionalPackage paymentButtonTitle={paymentButtonTitle} />
        )}
        {withBillingPolicy && (
          <PaymentBillingPolicy paymentButtonTitle={paymentButtonTitle} />
        )}
        {withContacts && <ContactsForCommunication align={Align.CENTER} />}
        {withCredentialsPolicy && <PaymentCredentialsPolicyFactory />}
        {subscriptionInfo && <FooterRow>{subscriptionInfo}</FooterRow>}
        {!isAdditionalPackageFirst && withAdditionalPackage && (
          <PaymentAdditionalPackage paymentButtonTitle={paymentButtonTitle} />
        )}
        {withActivationFee && paymentActivationFee && (
          <FooterRow>{paymentActivationFee}</FooterRow>
        )}
        {withHotLine && hotlineCreditCard && (
          <PaymentHotLine {...hotlineCreditCard} />
        )}
        {withUserPublicId && userPublicId && (
          <PaymentUserPublicId {...userPublicId} />
        )}
        {withCoinsAutofillAgreement && <PaymentFooterCoinsAutofillAgreement />}
      </FooterGroup>
      <Row
        flexibleWidth={false}
        space={isSliderFooter ? SpacingSize.NONE : SpacingSize.NORMAL}
      >
        {withDnsmpi && <PaymentDNSMPI muted underline />}
        {withCopyright && (
          <Fragment>
            <RowShort>
              <PaymentCopyright data={copyright} />
            </RowShort>
            {Boolean(footerLicense?.formattedList?.length) && (
              <Row>
                <PaymentLicense list={footerLicense.formattedList} />
              </Row>
            )}
          </Fragment>
        )}
      </Row>
    </TextMuted>
  );
};

PaymentFooterLayout.propTypes /* remove-proptypes */ = {
  data: footerDataPropTypes,
  isAdditionalPackageFirst: PropTypes.bool,
  packageDescriptionOnTop: PropTypes.bool,
  subscriptionInfo: PropTypes.node,
  billingPolicyRenderer: PropTypes.func,
  withAdditionalPackage: PropTypes.bool,
  withActivationFee: PropTypes.bool,
  withBillingPolicy: PropTypes.bool,
  withContacts: PropTypes.bool,
  withCredentialsPolicy: PropTypes.bool,
  withCopyright: PropTypes.bool,
  withDescriptor: PropTypes.bool,
  withDetails: PropTypes.bool,
  withAdditionalDetails: PropTypes.bool,
  withKonbiniInfo: PropTypes.bool,
  isAutoRenewalAgree: PropTypes.bool,
  withGuarantees: PropTypes.bool,
  withDnsmpi: PropTypes.bool,
  isSliderFooter: PropTypes.bool,
  withPackageDescription: PropTypes.bool,
  packageDescription: PropTypes.node,
  withSecurityLogos: PropTypes.bool,
  withHotLine: PropTypes.bool,
  withUserPublicId: PropTypes.bool,
  withToggleSubscribe: PropTypes.bool,
  withCurrencyConvertStatement: PropTypes.bool,
  withCoinsAutofillAgreement: PropTypes.bool,
  paymentButtonTitle: PropTypes.string,
  onlyProcessingFeeText: PropTypes.bool,
};

export default PaymentFooterLayout;
