import {useMemo, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import generatePayUrlForCoins from '@core/payment/common/utils/generatePayUrlForCoins';

import useCoinsModel from '@phoenix/coins/utils/useCoinsModel';

import CreditsCurrency from '../constants/CreditsCurrency';
import useCreditsBalanceQuery from './useCreditsBalanceQuery';

/**
 * Return data for create BuyBalance
 * @returns {{show: boolean}|{handleBuyClick: handleBuyClick, balance: number, show: boolean, currency: string}}
 */
const useBalanceData = ({via}) => {
  const {balance} = useCreditsBalanceQuery();
  const {loading, isCoinsModel} = useCoinsModel();
  const history = useHistory();

  // TODO: now we use component only for coins model. In the future need to spread of this logic
  const handleBuyClick = useCallback(() => {
    history.push(generatePayUrlForCoins({via}));
  }, [via, history]);

  return useMemo(() => {
    // TODO: now we use component only for coins model. In the future need to spread of this logic
    const show = !loading && isCoinsModel;
    if (!show) return {show};

    return {currency: CreditsCurrency.COINS, show, handleBuyClick, balance};
  }, [balance, loading, isCoinsModel, handleBuyClick]);
};

export default useBalanceData;
