import React from 'react';

import PopupService from '@core/popup/utils/PopupService';

import TrustedVerificationExceedStepLayout from '../components/TrustedVerificationExceedStepLayout';

const openTrustedVerificationExceedStepPopup = () => {
  PopupService.openPopup(<TrustedVerificationExceedStepLayout />, {
    small: true,
    trackingName: 'trustedVerificationExceedStep',
    autoLoadTracking: true,
  });
};

export default openTrustedVerificationExceedStepPopup;
