import React from 'react';

import {PlaceholderInput} from '@phoenix/ui';

import DisapproveReasonsLayout from './DisapproveReasonsLayout';

const MediaDisapproveReasonsPlaceholder = (props) => (
  <DisapproveReasonsLayout {...props}>
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
    <PlaceholderInput />
  </DisapproveReasonsLayout>
);

export default MediaDisapproveReasonsPlaceholder;
