import React, {lazy} from 'react';

import SplitChunk from '../components/SplitChunk';

const LazyPartnerNetworkChunk = lazy(
  () =>
    import(
      /* webpackChunkName: 'partnerNetwork' */ '@phoenix/partnerNetwork/containers/PartnerNetworkPage'
    ),
);

const PartnerNetworkChunk = (props) => (
  <SplitChunk component={LazyPartnerNetworkChunk} {...props} />
);

export default PartnerNetworkChunk;
