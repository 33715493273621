import {useQuery} from '@apollo/client';

import CURRENT_USER_TRIBES_DICTIONARY_QUERY from '@phoenix/user/widget/graphql/queries/currentUserTribesDictionary.gql';

import USER_ATTRIBUTES_QUERY from '../graphql/queries/userAttributes.gql';
import getExtraUserAttributesFromData from './getExtraUserAttributesFromData';

const useUserAttributes = (skip = false) => {
  const {data, loading} = useQuery(USER_ATTRIBUTES_QUERY, {skip});

  const {withProfession, withTribe, distanceUnits} =
    getExtraUserAttributesFromData(data);

  /**
   * If tribes are allowed, we should query data about their dictionary
   * @see getTagsFromUser.js
   */
  const {loading: tribeDictionaryLoading, data: tribeDictionary} = useQuery(
    CURRENT_USER_TRIBES_DICTIONARY_QUERY,
    {
      skip: skip || !withTribe,
    },
  );

  return {
    loading: skip || loading || tribeDictionaryLoading,
    dictionaryTribes: tribeDictionary?.myUserDictionary?.attributes?.tribe,
    distanceUnits,
    withProfession,
    withTribe,
  };
};

export default useUserAttributes;
