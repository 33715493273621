import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {pricePropType} from '@core/payment/widgets/package/constants/propTypes';

const PaymentPackageRowPrice = ({withPrefix = true, price}) => {
  const {
    formatted: {hi, lo, shortPrice},
    currency: {prefix, suffix},
  } = price;
  return (
    <Fragment>
      {withPrefix && prefix}
      {shortPrice || hi}
      {Boolean(!shortPrice && lo) && (
        <Fragment>
          <span>.</span>
          {lo}
        </Fragment>
      )}
      {suffix}
    </Fragment>
  );
};

PaymentPackageRowPrice.propTypes /* remove-proptypes */ = {
  withPrefix: PropTypes.bool,
  price: pricePropType.isRequired,
};

export default PaymentPackageRowPrice;
