import first from 'lodash/first';
import find from 'lodash/find';
import remove from 'lodash/remove';
import {from, Subject} from 'rxjs';
import {
  bufferTime,
  concatMap,
  map,
  mergeMap,
  groupBy,
  toArray,
  share,
} from 'rxjs/operators';

import {getClientInstance} from '@core/graphql/client';

import REMARKETING_BANNER_QUERY from '../graphql/queries/remarketingBanner.gql';

let instance = null;

/**
 * Collects rm banners for batching them in request by interval and environment
 * @class RemarketingBannerCollector
 */
class RemarketingBannerCollector {
  /**
   * @public
   * @static
   * @return {RemarketingBannerCollector}
   */
  static getInstance(options) {
    if (!instance) {
      instance = new this(options);
    }

    return instance;
  }

  /**
   * @param {Number} batchInterval
   */
  constructor({batchInterval = 200} = {}) {
    /**
     * @type {ApolloClient}
     */
    const client = getClientInstance();

    this.zones = [];

    this.source$ = new Subject().pipe(
      bufferTime(batchInterval),
      mergeMap((zoneList) => {
        return from(zoneList).pipe(
          groupBy((zone) => zone.environment),
          mergeMap((zone) => zone.pipe(toArray())),
        );
      }),
      concatMap((data) => {
        const zoneList = data.map((zone) => zone.zoneId);
        const {environment} = first(data);

        return from(
          client.query({
            query: REMARKETING_BANNER_QUERY,
            variables: {
              zone: zoneList,
              environment,
            },
            fetchPolicy: 'network-only',
          }),
        ).pipe(
          map((result) => {
            return {
              banners: result.data?.remarketing?.banner || null,
              zoneList,
              environment,
            };
          }),
        );
      }),
      share(),
    );
  }

  /**
   * @public
   * @param {Function} resolver
   * @param {String} zoneId
   * @param {String} environment
   */
  subscribe(resolver, {zoneId, environment}) {
    if (resolver && zoneId && !this.getZone(zoneId)) {
      this.zones.push({
        zoneId,
        environment,
        subscription: this.source$.subscribe((data) =>
          resolver(find(data.banners, {zoneId})),
        ),
      });
    }
  }

  /**
   * @public
   * @param {String} zoneId
   */
  unsubscribe(zoneId) {
    const {subscription} = this.getZone(zoneId) || {};

    if (subscription) {
      subscription.unsubscribe();
      remove(this.zones, (zone) => zone.zoneId === zoneId);
    }
  }

  /**
   * @public
   * @param {String} zoneId
   * @param {String} environment
   */
  fetchBanner({zoneId, environment}) {
    this.source$.next({zoneId, environment});
  }

  /**
   * @public
   */
  refetchBanners() {
    const {cache} = getClientInstance();

    // remove banners list
    cache.evict({
      id: 'Remarketing:{}',
      fieldName: 'banner',
    });

    this.zones.forEach((zone) => {
      // remove certain banner data
      cache.evict({
        id: `Banner:{"zoneId":"${zone.zoneId}"}`,
      });

      this.fetchBanner(zone);
    });
  }

  /**
   * @private
   * @param {String} zoneId
   * @returns {{subscription: Object, zoneId: String, environment: String} | undefined}
   */
  getZone(zoneId) {
    return find(this.zones, {zoneId});
  }
}

export default RemarketingBannerCollector;
