import {getClientInstance} from '@core/graphql/client';

import GIFTS_QUERY from '../graphql/queries/gifts.gql';

/**
 * @param {string} giftId
 * @param {number} amount Positive or negative number for how many we should increase/decrease number of free gift sendings
 * @returns {boolean}
 */
const updateGiftFreeAmount = (giftId, amount) => {
  const client = getClientInstance();

  const data = client.readQuery({
    query: GIFTS_QUERY,
  });

  if (!data) {
    return false;
  }

  client.writeQuery({
    query: GIFTS_QUERY,
    data: {
      ...data,
      gifts: {
        ...data.gifts,
        list: data.gifts.list.map((entry) => {
          if (entry.id === giftId) {
            const freeAmount = entry.freeAmount + amount;

            return {...entry, freeAmount: freeAmount <= 0 ? 0 : freeAmount};
          }

          return entry;
        }),
      },
    },
  });

  return true;
};

export default updateGiftFreeAmount;
