/**
 * Types of possible tasks with rewards.
 * @see useFreeCoinsReward.js
 */

export const WEB_PUSH = 'webPush';

export const MEDIA_UPLOAD = 'mediaUpload';

export const RELOGINS = 'relogins';

export const EMAIL_VERIFICATION = 'emailVerification';

export const TELEGRAM_SUBSCRIPTION = 'telegramSubscription';
