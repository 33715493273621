import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import usePhoneBreakpoint from '@core/responsive/usePhoneBreakpoint';

import {TextMuted} from '@phoenix/typography';
import {Checkbox, Group} from '@phoenix/ui';

import css from '../styles/PaymentCommonPolicyLayout.css';

const PaymentCommonPolicyLayout = ({
  animated = false,
  checked,
  onCheckboxChange,
  withCheckbox,
  children,
  'data-test': dataTest,
}) => {
  const isPhone = usePhoneBreakpoint();

  return (
    <div className={css.wrap} data-test={dataTest}>
      {withCheckbox ? (
        <Group horizontal itemWithFlexibleWidth={false}>
          <div
            className={cn(css.checkbox, animated && !checked && css.animated)}
          >
            <Checkbox
              onChange={onCheckboxChange}
              trackingName="policy"
              checked={checked}
              muted
            />
          </div>
          <TextMuted
            small
            align={isPhone ? TextMuted.ALIGN.LEFT : TextMuted.ALIGN.CENTER}
          >
            {children}
          </TextMuted>
        </Group>
      ) : (
        <TextMuted small align={TextMuted.ALIGN.CENTER}>
          {children}
        </TextMuted>
      )}
    </div>
  );
};

PaymentCommonPolicyLayout.propTypes /* remove-proptypes */ = {
  animated: PropTypes.bool,
  checked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  withCheckbox: PropTypes.bool,
  children: PropTypes.node.isRequired,
  'data-test': PropTypes.string,
};

export default PaymentCommonPolicyLayout;
