import getHistory from '@core/application/utils/getHistory';

import LegacyRouter from '@legacy/application/components/application/LegacyRouter';

/**
 * @param {Object} application (points to Backbone applicaiton)
 * @param {Object} history
 */
const createLegacyRouter = (application) => {
  application.router = new LegacyRouter(getHistory());
};

export default createLegacyRouter;
